import React, { useRef, useState } from 'react'
import { Row, Col, Button, InputGroup, Form } from 'reactstrap'
import { Link } from 'react-router-dom'
import logo from "assets/images/logo-white.png";
import verifyMobileImg from 'assets/images/otp-flow/verifyMobile.svg'

import google_icon from "assets/images/google-icon.png";
// import CommonLoader from "common/CommonLoader";
import './style.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple } from '@fortawesome/free-brands-svg-icons';
import MobileNumberForm from './components/MobileNumberForm';
import { Field, Formik } from 'formik';
import EmailSignup from './components/EmailSignup';
import VerifyMobileNumber from './VerifyMobileNumber';
import FormikMobileField from 'components/forms/formikFields/FormikMobileField';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import FormikInputField from 'components/forms/formikFields/FormikInputField';
import { emailNonNumericRegEx } from "utils/Constants";
import "react-phone-input-2/lib/style.css";
import * as Yup from "yup";
import FormikCheckboxField from 'components/forms/formikFields/FormikCheckboxField';
import ReactCodeInput from 'react-verification-code-input';

const validationSchemaEmailPassord = Yup.object({
    email: Yup.string()
        .max(100)
        .matches(emailNonNumericRegEx, "Please Enter an valid Email")
        .required("Please Enter an valid Email"),
    password: Yup.string()
        .required("Please Enter Password")
});

const SocialClaimForm = ({ onEmailPasswordSignUp, onGoogleSignUp, onAppleSignUp, onSignUp, onVerifyOtp, otpSent, isActive }) => {
    const [showPassword, onShowPassword] = useState(false)
    const [verifyCode, setVerifyCode] = useState("");
    const otpRef = useRef(null);
    const toggleShowPassword = () => onShowPassword(!showPassword)

    const handleOTPVerification = (e) => {
        e.preventDefault();
        onVerifyOtp(verifyCode)
    };

    return (
        <>
            <div className="nex-social-login-btns">
                <div>
                    <div id="g-signin2"></div> {/* Google Sign-in button */}
                </div>
                <Button className="nex-google-btn" onClick={onGoogleSignUp}>Sign Up with Google <img src={google_icon} alt="" width='25' /></Button>
                <Button className="nex-facebook-btn" onClick={onAppleSignUp}>Sign Up with Apple
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none" viewBox="0 0 50 50" x="0px" y="0px">
                        <path d="M 44.527344 34.75 C 43.449219 37.144531 42.929688 38.214844 41.542969 40.328125 C 39.601563 43.28125 36.863281 46.96875 33.480469 46.992188 C 30.46875 47.019531 29.691406 45.027344 25.601563 45.0625 C 21.515625 45.082031 20.664063 47.03125 17.648438 47 C 14.261719 46.96875 11.671875 43.648438 9.730469 40.699219 C 4.300781 32.429688 3.726563 22.734375 7.082031 17.578125 C 9.457031 13.921875 13.210938 11.773438 16.738281 11.773438 C 20.332031 11.773438 22.589844 13.746094 25.558594 13.746094 C 28.441406 13.746094 30.195313 11.769531 34.351563 11.769531 C 37.492188 11.769531 40.8125 13.480469 43.1875 16.433594 C 35.421875 20.691406 36.683594 31.78125 44.527344 34.75 Z M 31.195313 8.46875 C 32.707031 6.527344 33.855469 3.789063 33.4375 1 C 30.972656 1.167969 28.089844 2.742188 26.40625 4.78125 C 24.878906 6.640625 23.613281 9.398438 24.105469 12.066406 C 26.796875 12.152344 29.582031 10.546875 31.195313 8.46875 Z" fill="white"></path>
                    </svg>
                </Button>
                {/* </div> */}
            </div>
            <p className="form-or-text text-secondary font-weight-bold pb-0">Or</p>
            <Formik
                initialValues={{ email: '', password: '' }}
                validationSchema={validationSchemaEmailPassord}
                onSubmit={(values, { setSubmitting }) => {
                    onEmailPasswordSignUp(values, setSubmitting);
                    setSubmitting(false);
                }}
            >
                {({ handleSubmit, isSubmitting, errors }) => (
                    <Form onSubmit={handleSubmit}>
                        <InputGroup className="mt-3" size="medium">
                            <Field
                                id="email"
                                name="email"
                                type="email"
                                component={FormikInputField}
                                // label="Email Address"
                                placeholder="Email Address" />
                        </InputGroup>
                        <InputGroup className="" size="medium">
                            <div className="nexogic-password-field-wrap password">
                                <Field
                                    id="password"
                                    name="password"
                                    type={showPassword ? "text " : "password"}
                                    component={FormikInputField}
                                    placeholder="Password" />
                                {!!errors.password === false &&
                                    <FontAwesomeIcon onClick={toggleShowPassword} className="show-password " color="#ccc" icon={!showPassword ? faEyeSlash : faEye} />
                                }
                            </div>
                        </InputGroup>
                        <Button id="btn_sign_in" color="primary" size="lg" block className="nexogic_primary_button" type="submit" disabled={isSubmitting}>Sign Up with Email/Password</Button>
                    </Form>
                )}
            </Formik>
            <p className="form-or-text text-secondary font-weight-bold pb-0">Or</p>

            {/* Mobile OTP Form */}
            {!otpSent ? (
                <Formik
                    initialValues={{ phoneNumber: '' }}
                    onSubmit={({ phoneNumber }, { setSubmitting }) => onSignUp(phoneNumber, setSubmitting)}
                >
                    {({ handleSubmit, isSubmitting, errors }) => (
                        <Form onSubmit={handleSubmit}>
                            <div className="nexogic-phone-field-wrap">
                                <InputGroup className="mt-3" size="large">
                                    <Field
                                        id="mnf_phon"
                                        name="phoneNumber"
                                        type="text"
                                        component={FormikMobileField}
                                        placeholder="Phone Number" />
                                </InputGroup>
                            </div>
                            <Button id="btn_sign_in" color="primary" size="lg" block className="nexogic_primary_button" type="submit" disabled={isSubmitting}>Sign Up with OTP</Button>
                        </Form>
                    )}
                </Formik>
            ) : (
                <form onSubmit={handleOTPVerification}>
                    <div className="nexogic-otp-field-wrap">
                        <ReactCodeInput
                            id="vo_fields"
                            ref={otpRef}
                            type="number"
                            placeholder={[" ", " ", " ", " ", " ", " "]}
                            fields={6}
                            fieldWidth={42}
                            fieldHeight={38}
                            onChange={(val) => setVerifyCode(val)}
                            className="verification-code-masked-input mb-3"
                        />
                    </div>
                    <Button id="btn_verify_otp" color="primary" size="lg" block className="nexogic_primary_button" type="submit" disabled={isActive}>Verify OTP</Button>
                </form>
            )}
            <div id="recaptcha-container"></div>
        </>
    )
}

export default SocialClaimForm