import React from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { Row, Col, CardBody, Card } from "reactstrap";
import BackIcon from "assets/images/backIcon.png";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import referals_icon from "assets/images/svgs/referals-icon.svg";
import { useState } from "react";
import { withAlert } from "react-alert";
import PatientDetailsInfo from "pages/Referals/Components/CompleteForm/Components/PatientDetailsInfo";
import ResponseInfo from "./ResponseInfo";
import { FAILED_TO_FETCH_DATA } from "utils/Constants";
import { useEffect } from "react";
import { PatientService } from "utils/PatientService";
import CommonLoader from "common/CommonLoader";
import ResponseDocs from "./ResponseDocs";
import { OpinionService } from "utils/OpinionService";

const ReceivedOpinionResponse = (props) => {
    const [pannel_one_on, setPannel_one_on] = useState(true);
    let { opinionId } = useParams();
    const [patientInfo, setPatientInfo] = useState({});
    const [isPatientInfoLoading, setIsPatientInfoLoading] = useState(true);

    const getPatientInfo = (opinion) => {
        if (opinion?.requestedBy === opinion?.requestedFor) {
            PatientService.getPatientById(opinion?.requestedBy)
                .then((response) => {
                    const { status, data } = response;
                    if (status === 200) {
                        setPatientInfo(data);
                        setIsPatientInfoLoading(false);
                        // console.log(data);
                    } else {
                        console.error(FAILED_TO_FETCH_DATA);
                    }
                })
        } else {
            PatientService.getRelatedPersonById(opinion?.requestedBy, opinion?.requestedFor)
                .then((response) => {
                    const { status, data } = response;
                    if (status === 200) {
                        setPatientInfo(data);
                        setIsPatientInfoLoading(false);
                        // console.log(data);
                    } else {
                        console.error(FAILED_TO_FETCH_DATA);
                    }
                })
        }
    }

    const getOpinion = (opinionId) => {
        OpinionService.getOpinion(opinionId)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    // setOpinion(data);
                    getPatientInfo(data);
                } else {
                    console.error(FAILED_TO_FETCH_DATA)
                }
            })
    }

    useEffect(() => {
        getOpinion(opinionId);
    }, [opinionId])

    const history = useHistory();
    const handleBack = () => {
        history.goBack()
    }

    return (
        <div className="complete-form">
            <div className="routing-header d-flex justify-content-between align-items-center mb-3">
                <div className="nexogic-top-section-title">
                    <span><Link onClick={handleBack}><img src={BackIcon} alt="back" /></Link></span>
                    <h2 className="h2">Opinion Response <span>{!isPatientInfoLoading && `(${patientInfo?.firstName} ${patientInfo?.lastName})`}</span></h2>
                </div>
            </div>
            <Card className="nexogic-form-text-display-w border-0 bg-transparent">
                <CardBody className="px-0">
                    <Row className="need-margin">
                        <Col xl={9}>
                            {/** Patient Info  */}
                            <div className="position-relative mb-3">
                                <Card className={`nexogic-card-color-header ${pannel_one_on ? 'expanded' : ''}`}>
                                    <div className="card-header">
                                        <h2>Patient Details</h2>
                                        <button className="collapse-btn" type="button" onClick={() => setPannel_one_on(!pannel_one_on)}>
                                            <img src={pannel_arrow} alt="" />
                                        </button>
                                    </div>
                                    {isPatientInfoLoading ?
                                        <div className="text-center mt-3">
                                            <CommonLoader style={{ width: "2rem", height: "2rem" }} />
                                        </div>
                                        :
                                        <div className="collapse-div">
                                            <CardBody>
                                                <PatientDetailsInfo
                                                    patient={patientInfo}
                                                    patientType={"InBound"}
                                                />
                                            </CardBody>
                                        </div>
                                    }
                                </Card>
                            </div>

                            {/** Opinion Response  */}
                            <div className="position-relative mb-3">
                                <ResponseInfo opinionId={opinionId} />
                            </div>
                        </Col>

                        {/** Side Widget  */}
                        <Col xl={3} className="position-relative ">
                            <Card className="nexogic-widget-sidebar-card card nexogic-refaral-widget-card">
                                {/** Opinion Response Docs*/}
                                <div className="position-relative mb-3">
                                    <ResponseDocs opinionId={opinionId} />
                                </div>
                            </Card>

                            <Card className="nexogic-widget-sidebar-card card nexogic-refaral-widget-card">
                                <h2 className="card-title h2">Refer a Patient</h2>
                                <CardBody>
                                    <div className="graphic nex-avatar xl mx-auto">
                                        <img src={referals_icon} alt="" width={90} />
                                    </div>
                                    <h3 className="nex-sub-aside-title">Want to Refer a Patient ?</h3>
                                    <p className="card-text">Ensure smooth care transitions. Provide medical records, reason for referral. Collaborate with colleagues for better patient outcomes. Let's enhance patient care together!.</p>
                                    <p className="action pt-3 pb-3">
                                        <Link to="/referrals/refer-patient" href="" className="nexogic_primary_button_outline">Refer a Patient</Link>
                                    </p>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    )
}
export default withAlert()(ReceivedOpinionResponse);