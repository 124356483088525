import CommonLoader from "common/CommonLoader";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { withAlert } from "react-alert";
import { Card, CardBody } from "reactstrap";
import { FAILTOGETRECOED, FAILTOSAVERECOED } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import { isAdmin } from "utils/UserHelper";
import { useSelector } from "react-redux";
import ResumeInfo from "./ResumeInfo";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const PractitionerResumes = (props) => {
    const { practitionerGuid } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [listResumes, setListResumes] = useState([]);
    const [isFileUploaing, setIsFileUploaing] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const currentUser = useSelector((state) => state.auth.currentUser);
    const fileInputRef = useRef(null);

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const getProfileResumes = (guid) => {
        try {
            PractitionerService.getResumesProfile(guid).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setListResumes(response.data);
                    setIsLoading(false);
                } else {
                    showErrorMessage(FAILTOGETRECOED);
                    setIsLoading(false);
                }
            });
        } catch (e) {
            console.log("Error while getting Practitioner getProfileResumes: ", e);
        }
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {

            const allowedFileTypes = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "text/plain"];

            if (!allowedFileTypes.includes(selectedFile.type)) {
                // Issue a warning if the file type is not allowed
                showErrorMessage("Please select a valid file type (PDF, DOC, DOCX, TXT).");
                return;
            }

            // Perform any necessary validation or processing of the selected file here
            setIsFileUploaing(true);

            // Submit the file to the API using your preferred method (e.g., fetch, axios)
            PractitionerService.uploadResumePractitioner(practitionerGuid, selectedFile)
                .then((response) => {
                    const { status } = response;
                    if (status === 201) {
                        showSuccessMessage("Resume uploaded Successfully");
                        getProfileResumes(practitionerGuid);
                        setIsFileUploaing(false);
                    }
                    else {
                        showErrorMessage(FAILTOSAVERECOED);
                        setIsFileUploaing(false);
                    }
                })
        }
    };

    useEffect(() => {
        if (isAdmin() || practitionerGuid === currentUser?.guid)
            setIsEditable(true);
        getProfileResumes(practitionerGuid);
    }, [practitionerGuid])

    return (
        <>
            <Card className="nexogic-widget-sidebar-card document-sidebar-widget">
                <h2 className="card-title h2 d-flex flex-wrap">Resumes
                    {isEditable &&
                        <>
                            {isFileUploaing ?
                                <div className="text-center mb-5" style={{ height: "10px" }} >
                                    <CommonLoader />
                                </div>
                                :
                                <button className="btn nexogic-download-btn nexogic_primary_button"
                                    onClick={handleUploadClick}
                                >
                                    <span className="icon"><svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="none"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            stroke="#fff"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M15 10.333v3.111A1.556 1.556 0 0113.444 15H2.556A1.556 1.556 0 011 13.444v-3.11m10.889-5.445L8 1m0 0L4.111 4.889M8 1v9.333"
                                        ></path>
                                    </svg>
                                    </span> Upload Resume
                                </button>
                            }

                            <input
                                name="uploadDocument"
                                type="file"
                                id="uploadDocument"
                                accept=".pdf,.doc,.docx,.rtf,.txt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                title="Upload Document"
                                className="nexogic-file-upload-input"
                                style={{ display: 'none' }}
                                ref={fileInputRef}
                                onChange={handleFileChange}
                            />
                        </>
                    }
                </h2>
                <CardBody>
                    {isLoading ?
                        <div className="text-center mb-5" style={{ height: "10px" }} >
                            <CommonLoader />
                        </div>
                        :
                        <>
                            {(listResumes && listResumes.length > 0) ?
                                <div className="nexogic-custom-scroll">
                                    <div className="nexogic-document-lists">
                                        <ul>
                                            {listResumes.map((doc, index) => (
                                                <ResumeInfo docId={doc?.id} doc={doc} practitionerGuid={practitionerGuid} getProfileResumes={getProfileResumes} />
                                            ))}
                                        </ul>

                                    </div>
                                </div>
                                :
                                <div className="nexogic-not-found-text">No Resume</div>
                            }
                        </>
                    }
                </CardBody>
            </Card>

        </>
    );
}

export default withAlert()(PractitionerResumes);