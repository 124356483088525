import React, { useState } from "react";
import { Button, CardBody, FormGroup, Input } from "reactstrap";
import check_icon from 'assets/svgIcon/check_icon.svg';
import { OpinionService, getOpinion } from "utils/OpinionService";
import { FAILTOSAVERECOED } from "utils/Constants";
import { withAlert } from "react-alert";
import { useEffect } from "react";
import { MetadataService } from "utils/MetadataService";
import CommonLoader from "common/CommonLoader";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const PersonalDetailsForm3 = (props) => {

    const { isSelf, healthIssueValue, currentUserId, requestedForId, getOpinionInfo, setCurrentStep, setCurrentStepInMainForm, setOpinionId, forUpdate, setForUpdate, opinionInfo } = props;
    const [selectedSpeciality, setSelectedSpeciality] = useState("");
    const [specialties, setSpecialties] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filterText, setFilterText] = useState("");
    const [filteredSpecialties, setFilteredSpecialties] = useState([]);


    const getSpecialities = () => {
        MetadataService.getPrimarySpecialities()
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    setSpecialties(data);
                    setIsLoading(false);
                }
            })
    }

    const createOpinion = (e) => {
        setSelectedSpeciality(e);
        let payload = {
            "speciality": e,
            "title": healthIssueValue,
            "status": "DRAFT",
            "requestedBy": currentUserId,
            "requestedFor": isSelf ? currentUserId : requestedForId,
        }
        OpinionService.createOpinion(payload)
            .then((response) => {
                const { status, data, message } = response;;
                if (status === 201) {
                    showSuccessMessage("Opinion created successfully.");
                    setOpinionId(data?.id);
                    getOpinionInfo(data?.id);
                    setForUpdate(true);
                    setCurrentStepInMainForm('2');
                } else {
                    showErrorMessage(message);
                }
            })
    }

    const updateOpinion = (e) => {
        setSelectedSpeciality(e);
        let payload = {
            "speciality": e,
            "title": opinionInfo?.title,
            "status": "DRAFT",
            "requestedBy": opinionInfo?.requestedBy,
            "requestedFor": opinionInfo?.requestedFor,
        }
        OpinionService.updateOpinion(opinionInfo?.id, payload)
            .then((response) => {
                const { status, data } = response;;
                if (status === 200) {
                    showSuccessMessage("Opinion updated successfully.");
                    getOpinionInfo(data?.id);
                    setOpinionId(opinionInfo?.id);
                    setCurrentStepInMainForm('2');
                } else {
                    console.log(FAILTOSAVERECOED)
                }
            })
    }
    const handleClick = (e) => {
        props.setSelectedSpeciality(e);

        if (forUpdate)
            updateOpinion(e);
        else
            createOpinion(e);

    }

    useEffect(() => {
        getSpecialities();
    }, [])

    useEffect(() => {
        setSelectedSpeciality(opinionInfo?.speciality);
    }, [opinionInfo?.id])

    useEffect(() => {
        setFilteredSpecialties(
            specialties.filter((specialty) =>
                specialty.toLowerCase().includes(filterText.toLowerCase())
            )
        );
    }, [filterText, specialties]);



    return (
        <>
            <CardBody className={"nex-form-step active"}>
                <br />
                <div className="nex-form-field-container">
                    <div className="nex-form-group nex-content-center">
                        <div className="nex-form-group-c">
                            <label htmlFor="" className="nex-big-label mb-3">Please Select Specialty and Practitioner from the Below Options </label>
                            <FormGroup>
                                <Input
                                    className="nex-form-control-blue"
                                    placeholder="Filter Specialty"
                                    value={filterText}
                                    onChange={(e) => setFilterText(e.target.value)}
                                />
                            </FormGroup>


                            <div className="nexogic-custom-scroll">
                                <ul className="nex-check-lists">
                                    {
                                        isLoading ?
                                            <div className="loading-spiner text-center  mt-2">
                                                <CommonLoader style={{ width: "2rem", height: "2rem" }} />
                                            </div>
                                            :
                                            <>
                                                {filteredSpecialties.map((specialty, index) => (
                                                    <li key={index} onClick={() => handleClick(specialty)} className={`${selectedSpeciality === specialty ? 'active' : ''}`}>
                                                        <i className="icon"><img src={check_icon} alt="" /></i>
                                                        <a>{specialty}</a>
                                                    </li>
                                                ))}

                                            </>
                                    }
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="nex-form-group-c">
                    <div className="nex-form-action">
                        {forUpdate &&
                            <Button
                                color="primary"
                                className="nexogic_primary_button_outline"
                                onClick={(e) => setCurrentStepInMainForm('2')}
                            >
                                Next
                            </Button>
                        }
                        <Button
                            color="primary"
                            className="nexogic_primary_button_outline"
                            onClick={(e) => setCurrentStep('2')}
                        >
                            Back
                        </Button>
                    </div>
                </div>
            </CardBody>
        </>
    );
}

export default withAlert()(PersonalDetailsForm3);
