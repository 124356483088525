import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap";

//import registration_icon from "assets/images/svgs/registration_icon.svg";
import classnames from "classnames";
import AlertDialog from "common/AlertDialog";
import { withAlert } from "react-alert";
import {
  profileAddEvent,
  updateProfileCompletness
} from "redux-helpers/ActionCreator";
import { UserStatusConstants } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import RegistrationForm from "./components/RegistrationForm";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import registration_icon from "assets/svgIcon/registration_icon.svg";
import registration_person_icon from "assets/images/registration_person_icon.png";
import plusIcon from "assets/images/plus-icon-lg.png";
import editIcon from "assets/images/edit-blue-icon.png";
import edit_blue_icon from "assets/images/edit-blue-icon.png";
import del_red_icon from "assets/images/del-red-icon.png";
import { getEmptySectionShowHide } from "utils/Utils";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const initialValues = {
  registrationNumber: "",
  registrationCouncil: "",
  yearOfRegistration: "",
};

const RenderRegistrationDetails = (props) => {
  /*   const { registrationNumber, registrationCouncil, yearOfRegistration } = props.details */
  const {
    isAdmin,
    details,
    handleEditItem,
    handleRemoveItem,
    isLoggedinUser,
    status,
  } = props;

  if (details && details.length > 0) {
    return details.map((detail, index) => {
      const profileIdLink = detail?.institutionDetail?.profileId
        ? detail?.institutionDetail?.profileId
        : detail?.institutionDetail?.guid;
      return (
        <li
          className="list-group-item"
          key={`registration_${detail.id}`}
        >
          <div className="row g-0">

            <div className="col-auto">
              <img src={registration_icon} alt="" />
            </div>
            <div className="col">
              <h3 className="card-title">{detail.registrationCouncil}</h3>
              <p className="card-text"><small className="text-muted"><img src={registration_person_icon} alt="" /> {`Registration No: ${detail.registrationNumber}`}</small></p>
              <p className="card-text"><small className="text-muted">{`Year: ${detail.yearOfRegistration}`}</small></p>
            </div>

            <div className="flex-grow-1 d-none">
              <div className="d-flex">
                <div className="prize-icon badgeshape">
                  <img src={registration_icon} width="15" alt="#" />
                </div>
                <div className="ml-2">
                  {/*  <Link to={`/institution/${profileIdLink}`} className="mb-2 color-accent"> */}
                  <h6 className="section-item-title item-blue-title f-14 m-0">
                    {detail.registrationCouncil}
                  </h6>
                  {/*  </Link> */}
                  <div className="section-item-shortdes mt-2">{`Registration No: ${detail.registrationNumber}`}</div>
                  <div className="section-item-shortdes mt-2">{`Year: ${detail.yearOfRegistration}`}</div>
                </div>
              </div>
              {/*   <div className="d-flex flex-wrap">
                {props.documents.map((item, index) => {
                  const fileName = item.documentTitle ? item.documentTitle : ''
                  return (
                    <div className="position-relative document-card" key={`docs_${item.id}`}>
                      {
                        item.documentType === "application/pdf" ?
                          <embed src={decodeFileUrl(item.documentUrl)} className="img-fluid" />
                          :
                          <img src={decodeFileUrl(item.documentUrl)} className="img-fluid" />
                      }
                      <div className="document-name"><a href={decodeFileUrl(item.documentUrl)} target="_blank" className="text-truncate">{fileName}</a> </div>
                    </div>
                  )
                }
                )}
                {props.loadingDocuments &&
                  <p className="txt-mute">{'loading documents...'}</p>
                }
              </div> */}
            </div>
            <div className="ml-auto col-auto d-flex align-items-start">
              {/** (isLoggedinUser || isAdmin) */}
              {(isAdmin) &&
                status === "APPROVED" &&
                index != 0 && (
                  <div className="delete-action">
                    <Button
                      color="link"
                      className="nex-rs-registration-edit-btn"
                      onClick={() => handleEditItem(detail)}
                    >
                      {/* <img src={grayPencilIcon} /> */}
                      <img src={edit_blue_icon} alt="" />
                    </Button>

                    <Button
                      color="link"
                      className="nex-rs-registration-del-btn"
                      onClick={() => handleRemoveItem(detail)}
                    >
                      {/* <img src={grayTrashIcon} /> */}
                      <img src={del_red_icon} alt="" />
                    </Button>
                  </div>
                )}
            </div>
          </div>
        </li>
      );
    });
  } else {
    return "";
  }
};

const Registration = (props) => {
  const [pannel_on, setPannel_on] = useState(true);
  const { alert } = props;
  const eventName = useSelector((state) => state.profileComplete.eventName);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [registrationDetails, setRegistrationDetails] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [isOpenAlert, setAlertModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const toggle = () => setModal(!modal);
  const { practitioner, isAdmin, isLoggedinUser, setShowRegistrationOnTabScroll } = props;
  const { guid, status } = practitioner;

  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );

  useEffect(() => {
    getDocuments();
  }, [guid]);

  useEffect(() => {
    dispatch(updateProfileCompletness(13, !!registrationDetails.length));

    if (registrationDetails.length > 0 || isLoggedinUser || isAdmin) {
      setShowRegistrationOnTabScroll(true);
    }else{
      setShowRegistrationOnTabScroll(false);
    }
  }, [registrationDetails]);

  useEffect(() => {
    if (eventName === "ADD_Registration") {
      registrationModal(initialValues);
      dispatch(profileAddEvent(null));
    }
  }, [eventName]);

  const handleEditItem = (selectedItem) => {
    setSelectedItem(selectedItem);
    registrationModal();
  };

  const toggleAlert = () => {
    setLoading(false);
    setAlertModal(!isOpenAlert);
  };

  const handleRemoveItem = (selectedItem) => {
    setSelectedItem(selectedItem);
    toggleAlert();
  };

  const getDocuments = async () => {
    setLoadingDocuments(true);
    PractitionerService.getPractitionerRegistration(guid).then(
      (response) => {
        let { data, status } = response;
        if (status === 200) {
          //const { id, registrationNumber, registrationCouncil, yearOfRegistration } = data
          setRegistrationDetails(data);
          // console.log(registrationDetails, id, registrationNumber, registrationCouncil, yearOfRegistration);

          // Array.isArray(practitionerDocument) ? setDocuments(practitionerDocument) : setDocuments([])
        }
        setLoadingDocuments(false);
      },
      (err) => {
        console.log(err);
        setDocuments([]);
        setLoadingDocuments(false);
      }
    );
  };

  const handleClickOnYes = async () => {
    setLoading(true);
    try {
      // const response = await API.delete(Url + selectedItem.id);
      const response = PractitionerService.deletePractitionerRegistration(
        guid,
        selectedItem.id
      );
      response.then(
        (response) => {
          const { status } = response;
          if (status === 204) {
            setTimeout(() => {
              showSuccessMessage("Registration deleted successfully.");
              // setRegistrationDetails(data)
              getDocuments();
              setLoading(false);
              toggleAlert();
            }, 1000);
          } else {
            showErrorMessage("Cannot delete Registration.");
            setLoading(false);
            toggleAlert();
          }
        },
        (e) => {
          setLoading(false);
          console.log("error", e.message);
          let errmsg = e.message;
          if (e.response) {
            errmsg =
              e.response.data && !!e.response.data.message
                ? e.response.data.message
                : errmsg;
          }
          showErrorMessage(errmsg);
        }
      );
    } catch (e) {
      showErrorMessage(e.message);
      setLoading(false);
      toggleAlert();
    }
  };

  const registrationModal = () => {
    toggle();
  };

  const canAddRegDetails =
    isAdmin ||
    (isLoggedinUser && currentUser.status === UserStatusConstants.APPROVED);

  if (
    getEmptySectionShowHide(
      registrationDetails ?? [],
      isAdmin,
      currentUser,
      globalProfileInfo,
      guid
    )
  )
    return null;

  return (
    <Card className={`card nexogic-card-color-header ${pannel_on ? 'expanded' : ''}`} id="registrationSection">
      <div className="card-header"><h2>Registration</h2>

        {canAddRegDetails && (

          <Button
            id="nex-rs-registration-add-btn"
            color="link"
            className="add-button"
            onClick={() => handleEditItem(initialValues)}
          >
            {registrationDetails.registrationNumber === "" ?
              <img src={editIcon} alt="" />
              :
              <img src={plusIcon} alt="" />
            }


          </Button>

        )}
        <button className="collapse-btn" type="button" onClick={() => setPannel_on(!pannel_on)}>
          <img src={pannel_arrow} alt="" />
        </button>
      </div>
      <div className={`collapse-div`}>
        <CardBody className={
          registrationDetails && registrationDetails.length > 0
            ? ""
            : "align-items-center"
        }>

          {registrationDetails.registrationNumber !== ""
            ? ""
            : canAddRegDetails && (
              <Button
                id="nex-rs-registration-add-btn2"
                className="add-button"
                color="link"
                onClick={() => handleEditItem(initialValues)}
              >
                {"Add your registration"}
              </Button>
            )}
          {/** 
        <Row
          
        >
          <Col>
            <h3 className="section-title m-0 mb-3">{"Registration"}</h3>
            {registrationDetails.registrationNumber !== ""
              ? ""
              : canAddRegDetails && (
                  <div className="mt-3">
                    <Row className="align-items-center">
                      <Col className="add-infomation-part">
                        <Button
                          className="add-infomation p-0"
                          color="link"
                          onClick={() => handleEditItem(initialValues)}
                        >
                          {"Add your registration"}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                )}
          </Col>

          {canAddRegDetails && (
            <Col xs="auto">
              <Button
                color="link"
                className="round-icon-btn rounded-circle"
                onClick={() => handleEditItem(initialValues)}
              >
                 {registrationDetails.registrationNumber === "" ?
                  <FontAwesomeIcon icon={faPlus} />
                  :
                  <img src={pencilIcon} />
                } 
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </Col>
          )}
        </Row>
*/}

          <ul className="list-group list-group-flush" id="nex-rs-registration-lists">
            <RenderRegistrationDetails
              details={registrationDetails}
              handleEditItem={handleEditItem}
              handleRemoveItem={handleRemoveItem}
              status={status}
              documents={documents}
              loadingDocuments={loadingDocuments}
              isAdmin={isAdmin}
              isLoggedinUser={isLoggedinUser}
            />
          </ul>
        </CardBody>
      </div>
      <Modal
        isOpen={modal}
        id="profile-modal"
        toggle={registrationModal}
        className="modal-dialog-centered"
        backdrop="static"
      >
        <ModalHeader toggle={registrationModal}>Add Registration</ModalHeader>
        <ModalBody>
          <RegistrationForm
            {...props}
            details={registrationDetails}
            setDetails={getDocuments}
            toggle={toggle}
            //uuid={guid}
            selectedItem={selectedItem}
            documents={documents}
            loadingDocuments={loadingDocuments}
            getDocuments={getDocuments}
            isAdmin={isAdmin}
          />
        </ModalBody>
      </Modal>
      <AlertDialog
        toggle={toggleAlert}
        isOpen={isOpenAlert}
        isLoading={isLoading}
        handleClickOnYes={handleClickOnYes}
        yesLabel={"Yes"}
        noLabel={"No"}
      />
    </Card>
  );
};
export default withAlert()(Registration);
