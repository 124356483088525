import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap'
import { encrypt } from 'utils/EncryptDecrypt';
import { createImageFromInitials, getGenderDetails, getRandomColor } from 'utils/Utils';

export default function PatientPersonal(props) {
    const { patient } = props;

    return (
        <>
            {patient &&
                <div>
                    <Card className='mb-4'>
                        <CardBody>
                            <div className="row">

                                <h3 className='nexogic-subsection-title nexogic-personal-details-title'>Personal Information</h3>
                                <div className="col-lg-6 nexogic-patient-quick-info">
                                    <div className="graphic">
                                        <div className="card-graphic"><img src={createImageFromInitials(100, patient.firstName + " " + patient.lastName, getRandomColor(encrypt(21).toString()))} className="rounded-circle" width="70" height="70" /></div>
                                    </div>
                                    <div className="info">
                                        <h2 className='card-title big'>{patient?.title} {" "}{patient?.firstName} {" "}{patient?.lastName} </h2>
                                        <p className='card-text'>Gender : {patient?.gender ? getGenderDetails(patient?.gender) : "N/A"}</p>
                                        <p className='card-text'>Dob : {patient?.dateOfBirth ? patient?.dateOfBirth : "N/A"}</p>
                                    </div>
                                </div>
                                <div className="col-lg-6 nexogic-key-val-column">
                                    <ul className='full_list left-border'>
                                        <li><span>Phone Number</span>{patient?.phoneNumber ? patient?.phoneNumber : "N/A"}</li>
                                        <li><span>Email Address</span> {patient?.emailAddress ? patient?.emailAddress : "N/A"}</li>
                                    </ul>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                    <Card className='mb-4'>
                        <CardBody>
                            <div className="nexogic-key-val-column">
                                <h3 className='nexogic-subsection-title'>Basic Health Information</h3>
                                <ul>
                                    <li><span>Height</span>{patient?.height ? patient?.height : "N/A"}</li>
                                    <li><span>Weight</span>{patient?.weight ? patient?.weight : "N/A"}</li>
                                    <li><span>Blood Group</span>{patient?.bloodGroup ? patient?.bloodGroup : "N/A"}</li>
                                    <li><span>Language Spoken</span>{(patient?.languages && patient?.languages.length > 0) ? patient?.languages : "N/A"}</li>
                                </ul>
                            </div>
                        </CardBody>
                    </Card>
                    {patient?.address &&
                        <Card className='mb-4'>
                            <CardBody>
                                <div className="nexogic-key-val-column">
                                    <h3 className='nexogic-subsection-title'>Address</h3>
                                    <ul>
                                        <li><span>Address Line 1</span>{patient?.address?.addressLine1}</li>
                                        <li><span>Address Line 2</span>{patient?.address?.addressLine2}</li>
                                        <li><span>City</span> {patient?.address?.city}</li>
                                        <li><span>State</span> {patient?.address?.state}</li>
                                        <li><span>Country</span>{patient?.address?.country}</li>
                                        <li><span>Zip Code</span> {patient?.address?.zipCode}</li>
                                    </ul>
                                </div>
                            </CardBody>
                        </Card>
                    }
                </div>
            }
        </>
    )
}
