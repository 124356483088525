import CommonLoader from "common/CommonLoader";
import LoadMoreButton from "common/LoadMoreButton/LoadMoreButton";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import confirm from "reactstrap-confirm";
import { setConnectionInfo } from "redux-helpers/ActionCreator";
import { ConnectionService } from "utils/ConnectionService";
import { encrypt } from "utils/EncryptDecrypt";
import { createImageFromInitials, getFullAddressWithLocationCityAndState, getRandomColor } from "utils/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faIdCard, faListAlt, faTable } from "@fortawesome/free-solid-svg-icons";
import ConnectionsTabularView from "components/tabularView/ConnectionsTabularView";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import { FAILTOSAVERECOED } from "utils/Constants";

const ConnectionCard = (props) => {
  const { getConnections, connectionsDetails, setConnectionsDetails, hasMore, isLoadingMore, setIsLoadingMore } = props;
  const [submittingId, setSubmittingId] = useState("0");
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [viewMode, setViewMode] = useState("card");
  const connectionInfo = useSelector(
    (state) => state.connectionsInfo.connections
  );
  const dispatch = useDispatch();
  let guid = currentUser.guid;

  const confirmWithdraw = async (status, connectionId, index) => {
    let result = await confirm({
      title: <>Confirm</>,
      message: "Are you sure to withdraw connection request ?",
      confirmText: "Yes",
      confirmColor: "primary",
      cancelColor: "link text-danger",
      modalProps: {
        backdrop: "static",
      },
    });
    if (result) {
      updateConnection(status, connectionId, index);
    }
  };

  const OnIgnoreOrAccept = async (status, connectionId, index, label) => {
    let result = await confirm({
      title: <>Confirm</>,
      message: `Are you sure to ${label} connection request ?`,
      confirmText: "Yes",
      confirmColor: "primary",
      cancelColor: "link text-danger",
      modalProps: {
        backdrop: "static",
      },
    });
    if (result) {
      updateConnection(status, connectionId, index);
    }
  };

  const updateConnection = (status, connectionId, index) => {
    const payload = {
      action: status,
    };
    setSubmittingId(connectionId);
    ConnectionService.updateConnection(payload, connectionId, guid)
      .then((response) => {
        let { data } = response;
        if (response.status === 200 || response.status === 201) {
          showSuccessMessage("Connection request updated successfully.")
          connectionsDetails.contents.splice(index, 1);
          setConnectionsDetails({
            ...connectionsDetails,
            contents: connectionsDetails.contents,
          });
          setSubmittingId("0");
          if (connectionsDetails.contents.length === 0) {
            getConnections(0);
          }
          if (data.status === "ACCEPTED") {
            getConnectionsCount();
          }
        } else {
          setSubmittingId("0");
        }
      })
      .catch((error) => {
        setSubmittingId("0");
        showErrorMessage(FAILTOSAVERECOED);
        console.log("Error while updating connection info: ", error);
      });
  };

  useEffect(() => {
    getConnectionsCount();
  }, []);

  const getConnectionsCount = () => {
    ConnectionService.getConnectionsCount(guid)
      .then((response) => {
        let { data } = response;
        if (response.status === 200 || response.status === 201) {
          dispatch(
            setConnectionInfo({ ...connectionInfo, count: { all: data } })
          );
        }
      })
      .catch((error) => {
        console.log("Error while getting connection: ", error);
      });
  };

  const fetchData = () => {
    setIsLoadingMore(true);
    getConnections(
      connectionsDetails.currentPage + 1,
      connectionsDetails.contents
    );
  };

  useEffect(() => {
    document.body.classList.add('body-network-page');
    document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "NetWork";
    return () => {
      document.body.classList.remove('body-network-page');
      document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "";
    }
  }, [])

  const renderProfiles = () => {
    if (viewMode === 'card') {
      return (
        <>
          {
            connectionsDetails.contents.map((connection, index) => {
              const conDetails = connection.practitionerDetails;
              let imageUrl = "";
              if (
                conDetails !== undefined &&
                conDetails.avatarId !== undefined &&
                conDetails.avatarId !== null
              ) {
                imageUrl =
                  process.env.REACT_APP_IMAGE_BASE_URL +
                  "/" +
                  conDetails.profileGuid +
                  "/" +
                  conDetails.avatarId;
              } else if (conDetails !== undefined) {
                imageUrl = createImageFromInitials(
                  69,
                  conDetails.name.replace("Dr. ", ""),
                  getRandomColor(encrypt(conDetails.profileGuid).toString())
                );
              }

              return (
                <div key={index} className="nexogic-list-item-card bg-white mb-3">
                  {conDetails && (
                    <div className="card-body nex-network-list-item">
                      <div className="nexogic-graphic">
                        <img className="nexogic-avatar lg border-0" src={imageUrl} alt="" />
                      </div>
                      <div className="nexogic-info">
                        <h2 className="card-title">
                          <Link to={`/profile/` + conDetails.profileId}> {conDetails.name} </Link>
                        </h2>
                        <p className="card-text">{conDetails.primarySpeciality},{" "} {conDetails.superSpeciality}{" "}</p>
                        <p className="card-text">{getFullAddressWithLocationCityAndState({ location: conDetails?.location ?? "", city: conDetails?.city ?? "", state: conDetails?.state ?? "" })}</p>
                      </div>
                      <div className="action">
                        {connection.status === "PENDING" &&
                          guid === connection.receiverId && (
                            <div className="nexogic-action-part">
                              <Button
                                outline
                                className="nexogic_primary_button_outline"
                                disabled={submittingId == connection.id}
                                onClick={
                                  () =>
                                    OnIgnoreOrAccept(
                                      "REJECTED",
                                      connection.id,
                                      index,
                                      "Rejected"
                                    )
                                  //  updateConnection("REJECTED", connection.id, index)
                                }
                              >
                                Ignore
                              </Button>
                              <Button
                                color="info"
                                outline
                                className="nexogic_primary_button"
                                disabled={submittingId == connection.id}
                                onClick={
                                  () =>
                                    OnIgnoreOrAccept(
                                      "ACCEPTED",
                                      connection.id,
                                      index,
                                      "Accepted"
                                    )
                                  // updateConnection("ACCEPTED", connection.id, index)
                                }
                              >
                                Accept
                              </Button>
                            </div>
                          )}

                        {connection.status === "PENDING" &&
                          guid === connection.senderId && (
                            <div className="nexogic-action-part nex-app-width-full">
                              <Button
                                outline
                                className="nexogic_primary_button_outline nex-app-width-full"
                                disabled={submittingId == connection.id}
                                onClick={() =>
                                  confirmWithdraw(
                                    "WITHDRAWN",
                                    connection.id,
                                    index
                                  )
                                }
                              >
                                Withdraw
                              </Button>
                            </div>
                          )}
                        {connection.status === "REJECTED" &&
                          guid === connection.senderId && (
                            <div className="nexogic-action-part">
                              <Button outline className="nexogic_primary_button_outline" disabled>
                                Rejected
                              </Button>
                            </div>
                          )}
                      </div>
                      {/* <div className="row no-gutters ">
                        <div className="col-auto col-img">
                          <img className="card-img rounded card-img-md" src={imageUrl} alt="" />
                        </div>
                        <div className="col col-content-right-btn">
                          <div className="nexogic-content-details">
                            <h3 className="card-title">
                              <Link
                                to={`/profile/` + conDetails.profileId}
                              >
                                {conDetails.name}
                              </Link>
                            </h3>
                            <div className="card-text">
                              <small className="text-muted">
                                {conDetails.primarySpeciality},{" "}
                                {conDetails.superSpeciality}{" "}
                              </small>
                            </div>
                            <div className="card-text">
                              <small className="text-muted fw-semibold">
                                {" "}
                                {getFullAddressWithLocationCityAndState({ location: conDetails?.location ?? "", city: conDetails?.city ?? "", state: conDetails?.state ?? "" })}
                              </small>
                            </div>
                          </div>
                          {connection.status === "PENDING" &&
                            guid === connection.receiverId && (
                              <div className="nexogic-action-part">
                                <Button
                                  outline
                                  className="nexogic_primary_button_outline"
                                  disabled={submittingId == connection.id}
                                  onClick={
                                    () =>
                                      OnIgnoreOrAccept(
                                        "REJECTED",
                                        connection.id,
                                        index,
                                        "Rejected"
                                      )
                                    //  updateConnection("REJECTED", connection.id, index)
                                  }
                                >
                                  Ignore
                                </Button>
                                <Button
                                  color="info"
                                  outline
                                  className="nexogic_primary_button"
                                  disabled={submittingId == connection.id}
                                  onClick={
                                    () =>
                                      OnIgnoreOrAccept(
                                        "ACCEPTED",
                                        connection.id,
                                        index,
                                        "Accepted"
                                      )
                                    // updateConnection("ACCEPTED", connection.id, index)
                                  }
                                >
                                  Accept
                                </Button>
                              </div>
                            )}

                          {connection.status === "PENDING" &&
                            guid === connection.senderId && (
                              <div className="nexogic-action-part nex-app-width-full">
                                <Button
                                  outline
                                  className="nexogic_primary_button_outline nex-app-width-full"
                                  disabled={submittingId == connection.id}
                                  onClick={() =>
                                    confirmWithdraw(
                                      "WITHDRAWN",
                                      connection.id,
                                      index
                                    )
                                  }
                                >
                                  Withdraw
                                </Button>
                              </div>
                            )}
                          {connection.status === "REJECTED" &&
                            guid === connection.senderId && (
                              <div className="nexogic-action-part">
                                <Button outline className="nexogic_primary_button_outline" disabled>
                                  Rejected
                                </Button>
                              </div>
                            )}
                        </div>
                      </div> */}
                    </div>
                  )}
                </div>
              );
            }
            )

          }
        </>
      );
    } else {
      return (
        <ConnectionsTabularView theader={['Name', 'Qualification', 'Speciality', 'Experience', 'City']} tdata={connectionsDetails.contents} viewmode={viewMode} />
      );
    }
  };


  return (
    <>
      <div className="view-toggle nexogic-view-toggle">
        <div className="btns">
          <FontAwesomeIcon title="Card View" color={viewMode === "card" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faAddressCard} onClick={() => setViewMode("card")} /> | <FontAwesomeIcon title="Table View" color={viewMode === "table" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faListAlt} onClick={() => setViewMode("table")} />
        </div>
      </div>
      <div className="card-group nexogic-card-group-list card nexogic-connection-card nexogic-custom-scroll" id="connection-card-list" style={{ maxHeight: '650px' }}>
        <div>
          {submittingId &&
            connectionsDetails &&
            <>{renderProfiles()}</>
          }

          {hasMore &&
            <div className="text-center mt-3">
              {isLoadingMore
                ?
                <CommonLoader style={{ width: "2rem", height: "2rem" }} />
                :
                <LoadMoreButton loadMore={fetchData} />
              }
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default ConnectionCard;
