import React from "react";
import { Container, Row, Col } from "reactstrap";
import { useLocation } from "react-router-dom";

import "./jobs.scss";
// import JobsContent from "./JobsContent";
import JobSidebar from "./JobSidebar";
import ManageJobSideBar from "./ManageJobSideBar";
import JobsNavContent from "./JobsNavContent";
import jobsIcon from "../../assets/images/svgs/job-icon.svg";
import { useEffect } from "react";

const Jobs = (props) => {
  let location = useLocation();
  useEffect(() => {
    document.body.classList.add('body-nex-jobs-page');
    document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "Jobs";
    return () => {
      document.body.classList.remove('body-nex-jobs-page');
      document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "";
    }
}, [])

  return (
    <>
      <section className="jobs-managements pb-4">
        {/** 
        <div className="nexogic-heading-sec nexogic-card">
        <h2 className="nexogix-section-title"><img src={jobsIcon} className="icon" /> Jobs Board</h2>
        </div>
        */}
        <main>
          <Row className="container-jobs px-0">
            <Col md={4} lg={3}>
              <div className="nexogic-sidebar-stiky nexogic-custom-scroll">
              <Row>
                <Col>
                  <JobSidebar />
                </Col>
              </Row>
              <Row>
                <Col>
                  <ManageJobSideBar />
                </Col>
              </Row>
              </div>
              
            </Col>
            <Col md={8} lg={9} className="nex-app-no-pad">
              <div className="">
                <JobsNavContent />
              </div>
            </Col>
          </Row>
        </main>
      </section>
    </>
  );
};

export default Jobs;
