import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import RenderServicesModal from "./ServicesModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import blue_bullets from "assets/images/blue-bullets.png";
import plusIcon from "assets/images/plus-icon-lg.png";

export const ServicesUI = (props) => {
  const history = useHistory();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const dispatch = useDispatch();
  // const { institution, isEditable, isAdmin, getInstitutionProfile, setShowServicesOnTabScroll } = props;
  const {institution, getInstitutionProfile,themeDetails,previewFlag,alert, isEditable, isAdmin } = props;
  const { id } = props.institution;

  const [count, setCount] = useState(12);
  const [counta, setCounta] = useState(12);
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [services, setServices] = useState([]);
  // const [amenities, setAmenities] = useState([]);
  const toggle = () => setIsOpen(!isOpen);
  const modalToggle = () => setIsModalOpen(!isModalOpen);
  const [pannel_on, setPannel_on] = useState(true);
  const [pannel_on_2, setPannel_on_2] = useState(true);
  const [addInBulk, setAddInBulk] = useState(false);
  const handleClick = () => {
    setAddInBulk(!addInBulk);
  };

  const sectionColors = (sThemeColor) => {
    // const frmDoc = document.documentElement.style.setProperty;
    document.documentElement.style.setProperty('--section-bg-color', sThemeColor[3]);
    document.documentElement.style.setProperty('--section-headerbg-color', sThemeColor[4]);
    document.documentElement.style.setProperty('--section-headerfont-color', sThemeColor[5]);
    document.documentElement.style.setProperty('--section-font-color', sThemeColor[2]);
    document.documentElement.style.setProperty('--section-heading-color', sThemeColor[1]);
  }
  useEffect(()=>{
    if(themeDetails.length >0){
      sectionColors(themeDetails)
    }
  },[themeDetails])
  useEffect(() => {
    if (institution?.guid) {
      getInstitutionServices();
    }
  }, [institution]);


  const getInstitutionServices = () => {
    setServices(institution?.services);
    // setAmenities(institution?.amenities);
  };

  const RenderServices = (props) => {
    const { services, count } = props;
    if (services && services.length) {
      return services?.slice(0, count).map((service, index) => {
        if (service.length > 0) {
          return (
            <li className="list-group-item bg-color" key={`${service}_${index}`}>
              <div className="row g-0">
                <div className="col-auto">
                  <img src={blue_bullets} alt="" />
                </div>
                <div className="col">
                  <div className="card-text fw-semibold text-left mb-0">{service}{" "}</div>
                </div>
              </div>
            </li>
          );
        }
      });
    }
    return "";
  };

  const ShowMoreServices = (props) => {
    const { services, count, setCount } = props;
    if (services && services.length > count) {
      const remainingCount = services.length - count;
      return (
        <Button
          id="nex-ip-service-more-btn"
          color="link"
          onClick={() => setCount(services.length)}
          className="d-inline-block f-14"
        >
          {`+ ${remainingCount} more`}
        </Button>
      );
    }
    return null;
  };

  return (
    <Row>
      <Col lg={12} className="text-center px-3">
        <div id="section-services" className="m-v1">
      {((services.length > 0 && !isAdmin) || isAdmin) && (
        <Card className={`card nexogic-card-color-header ${pannel_on ? 'expanded' : ''} micro-section-card-vs1`} id="services">
          <div className="card-header section-header"><h2>Services</h2>
            <button className="collapse-btn" type="button" onClick={() => setPannel_on(!pannel_on)}>
              <img src={pannel_arrow} alt="" />
            </button>
            {isEditable && 
              isAdmin && 
                !previewFlag && (
              <Button
                id="nex-ip-service-plus-btn"
                color="link"
                className="add-button"
                onClick={toggle}
              >
                <img src={plusIcon} alt="" />
                {/*<FontAwesomeIcon icon={faPlus} />*/}
              </Button>

            )}


          </div>
          <div className={`collapse-div`}>
            <CardBody className="bg-color">
              {services && services.length
                ? ""
                : (isEditable || isAdmin) && (
                  <div className="card-text">
                    <Button
                      id="nex-ip-service-add-info-btn"
                      className="readmore p-0"
                      color="link"
                      onClick={toggle}
                    >
                      {"Add information"}
                    </Button>
                  </div>
                )}

              <div className="nexogic-card-list">
                <ul className="list-group list-group-flush">
                  <RenderServices services={services} count={count} />
                </ul>
                <ShowMoreServices
                  services={services}
                  count={count}
                  setCount={setCount}
                />

              </div>


              {!isEditable && isEmpty(currentUser) && (
                <Row className="py-5 my-4">
                  <Col xs="12">
                    <p className="text-primary font-weight-bold font-italic">
                      {" "}
                      Please Sign In to access the full profile.
                    </p>
                    <Button
                      id="nex-ip-service-login-btn"
                      color="primary"
                      onClick={() => history.push("/login")}
                    >
                      {"Login"}
                    </Button>
                  </Col>
                </Row>
              )}
            </CardBody>
          </div>
          <RenderServicesModal
            isOpen={isOpen}
            toggle={toggle}
            services={services}
            isAdmin={isAdmin}
            institution={institution}
            practitionerId={currentUser?.guid}
            institutionId={institution?.guid}
            getInstitutionProfile={getInstitutionProfile}
          />
        </Card>
      )}
    </div>
    </Col>
    </Row>
  );
};
