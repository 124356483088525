import React from 'react'
import ReactDOMServer from 'react-dom/server';
import { Col, Row, Table } from 'reactstrap'
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCross, faEye, faFileImage, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import { MedialService } from 'utils/MediaService'
import Swal from 'sweetalert2'
import { FAILED_TO_DELETE_DATA, FAILTOSAVERECOED } from 'utils/Constants'
import { InstitutionServices } from 'utils/InstitutionServices'
import { useState } from 'react';
import CommonLoader from 'common/CommonLoader';

const ManageWidgets = (props) => {
    const { eThemeDetails, institution, setEThemeDetails,setRefreshFlag, setModalIndex, toggle } = props
    const [loading, setLoading] = useState(false);
    const [uThemeDetails, setUThemeDetails] = useState(eThemeDetails);
    const [imgPathSrc, setImgPathSrc] = useState('');
    const [imgType, setImgType] = useState('');
    // console.log("ManageWidgets----------------------------------", eThemeDetails,uThemeDetails, institution, eThemeDetails.draggables, eThemeDetails.draggables.length);

    const previewAds = (adsDetails) => {
        setImgPathSrc(adsDetails.adspath);
        setImgType(adsDetails.advtype);
    }

    const removeAds = async (adsid) => {
        const { isConfirmed } = await Swal.fire({
            title: ' ',
            text: "Are you sure you want to remove this Advertisement ?",
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Yes',
        });

        if (isConfirmed) {
            setLoading(true);
            MedialService.deleteMedia(adsid)
                .then((response) => {
                    const { status } = response;
                    if (status === 204) {
                        const updatedDraggables = eThemeDetails.draggables.filter(draggable => draggable.imgid !== adsid);
                        const updatedThemeDetails = { ...eThemeDetails, draggables: updatedDraggables };
                        InstitutionServices.updateDisplayPreferences(institution?.guid, updatedThemeDetails)
                            .then((saveresponse) => {
                                const { status, data } = saveresponse;
                                if (status === 200) {
                                    setUThemeDetails(data);
                                    setEThemeDetails(data);
                                    setRefreshFlag(true);
                                    props.alert.success("Advertisement removed successfully");
                                    setModalIndex(Math.random())
                                }
                                else {
                                    props.alert.error(FAILTOSAVERECOED);
                                    // props.alert.error("Something went wrong, Please try again later");
                                }
                            })
                    } else {
                        props.alert.error(FAILED_TO_DELETE_DATA);
                    }
                })
            setLoading(false);
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    {/* <!-- First Column with Scroll --> */}
                    <div className="col-md-8 m-0 p-0 border">
                        <div className="scrollable-content m-0 p-0 content-box">
                            {uThemeDetails && uThemeDetails?.draggables &&
                                uThemeDetails?.draggables?.length > 0 &&
                                uThemeDetails?.draggables.map((widgets, index) => (
                                    <Row key={index} className={`py-3 ${index % 2 === 0 ? 'bg-gray' : 'bg-light'}`}>
                                        <Col className='w-25 text-center pt-1'>{index + 1}</Col>
                                        <Col lg={8} sm={8} className='w-50 pt-1 fsize'>{widgets.name}</Col>
                                        <Col lg={2} sm={2} className='w-25 p-0'>
                                            <FontAwesomeIcon
                                                icon={faFileImage}
                                                id={widgets.adspath}
                                                className={`text-primary m-2 cursor-pointer ${imgPathSrc=== widgets.adspath ? 'bg-dark fsize':'fs1'}`}
                                                onClick={() => previewAds(widgets)}
                                            />
                                            <FontAwesomeIcon
                                                icon={faTimes}
                                                id={widgets.imgid}
                                                style={{ fontSize: '18px' }}
                                                className="text-danger m-2 cursor-pointer"
                                                onClick={() => removeAds(widgets.imgid)}
                                            />
                                        </Col>
                                    </Row>
                                ))
                            }
                        </div>
                    </div>
                    <div className="col-md-4 view-box">
                        <div className="pl-1 border h-100 d-flex justify-content-center align-items-center">
                            {imgPathSrc === '' ?
                                <p>Ads Preview</p> :
                                <img src={`${process.env.REACT_APP_IMAGE_BASE_URL}/micro_site_${institution?.guid}/${imgPathSrc.replace(/\s+/g, '_')}`} className={`${imgType === 'Portrait' ? 'h-100' : 'w-100'}`} alt='Ads preview' />
                            }
                        </div>
                    </div>
                </div>
            </div>        

        </>

    )
}

export default ManageWidgets