import JobWidget from "common/JobWidget";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Card, Col, Row } from "reactstrap";
import { About } from "./components/About";
import Gallery from "./components/Gallery";
import Branches from "./components/Branches";
import HospitalTimings from "./components/HospitalTimings";
import InstitutionsAwards from "./components/InstitutionsAwards";
import { Services } from "./components/Services";
import SocialMedia from "./components/SocialMedia";
import Specialities from "./components/Specialities";

import website_link_icon from "assets/svgIcon/website-link-icon.svg";
import direction_icon from "assets/svgIcon/direction-icon.svg";
import phone_call_icon from "assets/svgIcon/phone-call-icon.svg";
import PractitionersContent from "./components/Practitioners";
import { useRef } from "react";
import scroller_arrow from "assets/svgIcon/scroller-arrow-right.svg";
import { isAdmin } from "utils/UserHelper";

const InstitutionsDetail = (props) => {
  const history = useHistory();
  const { isEditable, institution } = props;
  const currentUser = useSelector((state) => state.auth.currentUser);
  const globalInstituteProfileInfo = useSelector(
    (state) => state.GlobalInstituteProfileReducer.globalInstituteInfo
  );


  /* tabs */
  const [showAboutOnTabScroll, setShowAboutOnTabScroll] = useState(false);
  const [showSpecialitiesOnTabScroll, setShowSpecialitiesOnTabScroll] = useState(false);
  const [showGalleryOnTabScroll, setShowGalleryOnTabScroll] = useState(false);
  const [showHospitalTimingsOnTabScroll, setShowHospitalTimingsOnTabScroll] = useState(false);
  const [showServicesOnTabScroll, setShowServicesOnTabScroll] = useState(false);
  const [showBranchesOnTabScroll, setShowBranchesOnTabScroll] = useState(false);
  const [showPractitionersContentOnTabScroll, setShowPractitionersContentOnTabScroll] = useState(false);
  const [showAwardsOnTabScroll, setShowAwardsOnTabScroll] = useState(false);
  const [tabScrolCus, setTabScrolCus] = useState(0);
  const [tabScrolhas, setTabScrolhas] = useState(false);
  const tabscrollRef = useRef(null);
  const [scrollelm, setScrollelm] = useState(false);

  const [activeTab, setActiveTab] = useState('aboutSection');
  const [tabScrollIsEnd, setTabScrollIsEnd] = useState(false);
  const [tabScrollIsStart, setTabScrollIsStart] = useState(true);


  function handleTabScroll__(label) {
    const cw = tabscrollRef.current.clientWidth;
    const sw = tabscrollRef.current.scrollWidth;
    let cal_w = tabScrolCus;

    if (label == 'next') {
      cal_w = cal_w > sw ? cal_w : cal_w + cw;
    } else {
      cal_w = cal_w <= 0 ? 0 : cal_w - cw;
    }

    setTabScrolCus(cal_w);
    if ((sw - cal_w) > (cw - 1)) {
      setTabScrolhas(true);
    } else {
      setTabScrolhas(false);
    }

  }

  function handleTabScroll(label) {
    let scroll_elm = document.querySelectorAll('.nexogic-profile-quick-link .profile-quick-scroller')[0];
    if (label == 'next') {
      scroll_elm.scrollLeft += 200;
    } else {
      if (scroll_elm.scrollLeft !== 0) {
        scroll_elm.scrollLeft -= 200;
      }
    }

  }

  useEffect(() => {
    tabScrollInit();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollelm(window.scrollY > 10);
    });
    tabScrollInit();

  }, [showAboutOnTabScroll,
    showSpecialitiesOnTabScroll,
    showGalleryOnTabScroll,
    showHospitalTimingsOnTabScroll,
    showServicesOnTabScroll,
    showBranchesOnTabScroll,
    showPractitionersContentOnTabScroll,
    showAwardsOnTabScroll,

  ]);

  function tabScrollInit() {
    const cw = tabscrollRef.current.clientWidth;
    const sw = tabscrollRef.current.scrollWidth;
    setTabScrolhas(sw > cw);
  }


  /* get header height  */
  useEffect(() => {
    let scroll_elm = document.querySelectorAll('.nexogic-profile-quick-link .profile-quick-scroller')[0];
    setTimeout(() => {
      scroll_elm.scrollLeft = 0;
    }, 100);
    //console.log(scroll_elm);
    tabHandleResize();
    tabScrollHandle();
    window.addEventListener("resize", tabHandleResize);
    scroll_elm.addEventListener("scroll", tabScrollHandle);
    return () => {
      window.removeEventListener("resize", tabHandleResize);
      scroll_elm.removeEventListener("scroll", tabScrollHandle);
    };

  }, [showAboutOnTabScroll,
    showSpecialitiesOnTabScroll,
    showGalleryOnTabScroll,
    showHospitalTimingsOnTabScroll,
    showServicesOnTabScroll,
    showBranchesOnTabScroll,
    showPractitionersContentOnTabScroll,
    showAwardsOnTabScroll
  ]);

  const tabHandleResize = () => {
    let header = document.querySelectorAll('.nexogic-header-card')[0];
    tabscrollRef.current.style.setProperty('--top_gap', header.offsetHeight + 'px');
  };


  const tabScrollHandle = () => {
    let scroll_elm = document.querySelectorAll('.nexogic-profile-quick-link .profile-quick-scroller')[0];
    let end_scroll_val = scroll_elm.scrollWidth - scroll_elm.clientWidth;
    let scroll_val = scroll_elm.scrollLeft;
    setTabScrollIsEnd(scroll_val < end_scroll_val);
    setTabScrollIsStart(scroll_val > 1);

    //console.log(scroll_val < end_scroll_val, scroll_val > 1);

  };









  if (!isEditable && isEmpty(currentUser)) {
    return (
      <Card className="rounded-0 border-0 shadow p-3">
        <Row className="py-5 my-4">
          <Col xs="12">
            <p className="text-primary font-weight-bold font-italic">
              {" "}
              Please Sign In to access the full profile.
            </p>
            <Button color="primary" onClick={() => history.push("/login")}>
              {"Login"}
            </Button>
          </Col>
        </Row>
      </Card>
    );
  }

  return (
    <>
      {/** profile-quick-link */}
      <div className={`nexogic-profile-quick-link ${tabScrollIsStart ? 'prev_on' : ''} ${tabScrollIsEnd ? 'next_on' : ''}`} ref={tabscrollRef}>
        <div className={`scroller-arrow-left ${tabScrollIsStart ? 'on' : ''}`} ><img src={scroller_arrow} alt="" onClick={(e) => handleTabScroll('prev')} /></div>
        <div className={`scroller-arrow-right ${tabScrollIsEnd ? 'on' : ''}`} ><img src={scroller_arrow} alt="" onClick={(e) => handleTabScroll('next')} /></div>

        {/** style={{ 'transform': 'translateX(-' + tabScrolCus + 'px)' }} */}
        <ul className="profile-quick-scroller" >
          {showAboutOnTabScroll && <li><a href="#aboutSection" className={activeTab === 'aboutSection' ? 'active' : ''} onClick={() => setActiveTab('aboutSection')}>About </a></li>}
          {showGalleryOnTabScroll && <li><a href="#gallerySection" className={activeTab === 'gallerySection' ? 'active' : ''} onClick={() => setActiveTab('gallerySection')}>Gallery </a></li>}
          {showHospitalTimingsOnTabScroll && <li><a href="#hospitalTimingsSection" className={activeTab === 'hospitalTimingsSection' ? 'active' : ''} onClick={() => setActiveTab('hospitalTimingsSection')}>Business Hours </a></li>}
          {showSpecialitiesOnTabScroll && <li><a href="#specialitiesSection" className={activeTab === 'specialitiesSection' ? 'active' : ''} onClick={() => setActiveTab('specialitiesSection')}>Specialities </a></li>}
          {showServicesOnTabScroll && <li><a href="#servicesSection" className={activeTab === 'servicesSection' ? 'active' : ''} onClick={() => setActiveTab('servicesSection')}>Services </a></li>}
          {showBranchesOnTabScroll && <li><a href="#branchesSection" className={activeTab === 'branchesSection' ? 'active' : ''} onClick={() => setActiveTab('branchesSection')}>Branches </a></li>}
          {showPractitionersContentOnTabScroll && <li><a href="#practitionersContentSection" className={activeTab === 'practitionersContentSection' ? 'active' : ''} onClick={() => setActiveTab('practitionersContentSection')}>Practitioners </a></li>}
          {showAwardsOnTabScroll && <li><a href="#awardsSection" className={activeTab === 'awardsSection' ? 'active' : ''} onClick={() => setActiveTab('awardsSection')}>Awards </a></li>}
        </ul>
      </div>
      <Row>
        <Col xl={"8"} >
          {/* <div className="position-relative mb-3 mt-4">
        <ProfileCompleteness {...props} />
      </div> */}
          {/* <pre>{JSON.stringify(jobListInfo, null, 4)}</pre> */}
          <div className="position-relative" id="aboutSection">
            <About {...props} setShowAboutOnTabScroll={setShowAboutOnTabScroll} />
          </div>
          <div className="position-relative" id="gallerySection">
            <Gallery {...props} setShowGalleryOnTabScroll={setShowGalleryOnTabScroll} />
          </div>
          <div className="position-relative " id="hospitalTimingsSection">
            <HospitalTimings {...props} setShowHospitalTimingsOnTabScroll={setShowHospitalTimingsOnTabScroll} />
          </div>
          {/*  <div className="position-relative ">
        <HospitalInformation {...props} />
      </div> */}
          {/*  <div className="position-relative ">
        <InstitutionsContatcts {...props} />
      </div> */}
          <div className="position-relative" id="specialitiesSection">
            <Specialities {...props} setShowSpecialitiesOnTabScroll={setShowSpecialitiesOnTabScroll} />
          </div>
          <div className="position-relative" id="servicesSection">
            <Services {...props} setShowServicesOnTabScroll={setShowServicesOnTabScroll} />
          </div>
          <div className="position-relative" id="branchesSection">
            <Branches {...props} setShowBranchesOnTabScroll={setShowBranchesOnTabScroll} />
          </div>
          <div className="position-relative " id="practitionersContentSection">
            <PractitionersContent {...props} setShowPractitionersContentOnTabScroll={setShowPractitionersContentOnTabScroll} />
          </div>
          <div className="position-relative " id="awardsSection">
            <InstitutionsAwards {...props} setShowAwardsOnTabScroll={setShowAwardsOnTabScroll} />
          </div>
          {/* <div className="position-relative ">
        <News {...props} />
      </div> */}
        </Col>
        <Col xl={4}>
          <div className="card nexogic-widget-sidebar-card contact-card">
            <div className="card-body">
              {institution?.websiteURL &&
                <div className="item">
                  <a href={institution?.websiteURL} target="_blank" className="text-ellipsis">{institution?.websiteURL}</a>
                  <a href={institution?.websiteURL} target="_blank" className="icon"><img src={website_link_icon} alt="" /></a>
                </div>
              }
              {institution?.phoneContacts.length > 0 &&
                <div className="item phone">
                  <div >
                    {institution?.phoneContacts.map((phone_info, ind) => (

                      <a key={'rs-phone-key-' + ind} href={`tel:${phone_info.value}`}>{phone_info.value} <span>{phone_info.type}</span></a>
                    ))}
                    <span className="icon"><img src={phone_call_icon} alt="" /></span>
                  </div>

                </div>
              }
              {institution?.address &&

                <div className="item ">
                  <div className="address">
                    <p><a target="_blank" href={`https://maps.google.com/?q=${institution?.address?.addressLine1 ? institution?.address?.addressLine1 : ''}${institution?.address?.addressLine2 ? ", " + institution?.address?.addressLine2 : ''}${institution?.address?.city ? ", " + institution?.address?.city : ''}${institution?.address?.country ? ", " + institution?.address?.country : ''}${institution?.address?.postalCode ? ", " + institution?.address?.postalCode : ''}`} className="">Get Directions</a></p>
                    <p> {institution?.address?.addressLine1 && <span>{institution?.address?.addressLine1}</span>}
                      {institution?.address?.addressLine2 && <span>{institution?.address?.addressLine2}</span>}
                      {institution?.address?.city && <span>{institution?.address?.city}</span>}
                      {institution?.address?.country && <span>{institution?.address?.country}</span>}
                      {institution?.address?.postalCode && <span>{institution?.address?.postalCode}</span>}
                    </p></div>
                  <span className="icon"><img src={direction_icon} alt="" /></span>
                </div>
              }
              <div className="card-group nexogic-card-group-list">
                <SocialMedia {...props} />
              </div>

            </div>
          </div>

          <JobWidget widgetTitle="Jobs" {...props} />
        </Col>
      </Row>
    </>
  );
};

export default InstitutionsDetail;
