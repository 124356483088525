import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Col, Row, Spinner } from "reactstrap";
import { setGlobalprofile } from "redux-helpers/ActionCreator";
import { IdentityService } from "utils/IdentityService";
import { PractitionerService } from "utils/PractitionerService";
import { getToken, isAdmin, setOAuthUser } from "utils/UserHelper";
import { DoctorsDetails } from "./components/DoctorsDetails";
import DoctorsProfile from "./components/DoctorsProfile";
import InCompleteNotify from "common/Header/components/InCompleteNotify";
import CommonLoader from "common/CommonLoader";
// import RightSidebar from "./components/RightSidebar";
//import "./profile.css";

const Profile = (props) => {
  const dispatch = useDispatch();
  let {
    match: {
      params: { profileId },
    },
  } = props;

  const currentUser = useSelector((state) => state.auth.currentUser);
  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );

  const isAdminUser = isAdmin();
  const [isLoggedinUser, setUser] = useState(false);
  const [practitioner, setPractitioner] = useState();
  const [regDocuments, setRegDocuments] = useState([]);
  const [regContinue, setRegContinue] = useState(false);
  const [loading, setLoading] = useState(false);
  const guid = currentUser?.guid;

  const getProfile = useCallback(
    (guid) => {
      try {
        PractitionerService.gePractitionersDetails(guid).then((response) => {
          if (response.status === 200 || response.status === 201) {
            dispatch(setGlobalprofile(response.data));
            setPractitioner(response.data);
          }
        });
      } catch (e) {
        console.log("Error while getting user getProfile: ", e);
      }
    },
    [dispatch]
  );

  const getUserDetails = async (guid) => {
    try {
      let token = getToken();
      IdentityService.GetUserDetails(token, guid).then((response) => {
        if (response.status === 200 || response.status === 201) {
          setOAuthUser(response.data);
        }
      });
    } catch (e) {
      console.log("Error while getting user details: ", e);
    }
  };

  const getDocumentData = async () => {
    setLoading(true);
    const { data, status } = await PractitionerService.getDocumentsProfile(guid);
    if (status === 200) {
      setRegDocuments(data);      
    }
    setLoading(false);
  };
  useEffect(() => {
    getDocumentData();
  }, [guid, regDocuments.length]);


  useEffect(() => {
    // window.scrollTo(0, 0);
    if (
      currentUser &&
      profileId &&
      guid &&
      profileId === currentUser?.profileId
    ) {
      setUser(true);
      getProfile(guid);
      getUserDetails(currentUser?.id);
    } else {
      setUser(false);
      getProfile(profileId);
    }
  }, [profileId, currentUser, guid, getProfile]);

  return (
    <React.Fragment>
      {loading ?
        <div className="loading-spiner text-center">
          <CommonLoader />
        </div>
        :
        <InCompleteNotify currentUser={currentUser} getDocs={regDocuments} setContinue={setRegContinue} />
      }       
      {practitioner && (
        <section className="profile-wrapper---">
          <Row>
            <Col lg={12}>
              <DoctorsProfile
                getProfile={getProfile}
                isAdmin={isAdminUser}
                practitioner={globalProfileInfo}
                isLoggedinUser={isLoggedinUser}
                setPractitioner={setPractitioner}
              />
              <DoctorsDetails
                practitioner={globalProfileInfo}
                isLoggedinUser={isLoggedinUser}
                isAdmin={isAdminUser}
                setPractitioner={setPractitioner}
                getProfile={getProfile}
                getDocs={regDocuments}
                docUpload={regContinue}
                setDocs={setRegDocuments}
              />
            </Col>
            {/* <Col lg={3} className="mt-2 pr-2 pl-2 d-none">
              <RightSidebar practitionerId={practitioner.guid} />
            </Col> */}
          </Row>
        </section>
      )}
    </React.Fragment>
  );
};

export default connect(null, null)(Profile);
