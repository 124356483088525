import React from "react";
import { useState } from "react";
import { Button, CardBody, FormGroup, Input } from "reactstrap";
import { createImageFromInitials, getAgeFromDOB, getFullNameOfPerson, getGenderDetails, getRandomColor } from "utils/Utils";
import { useEffect } from "react";
import { OpinionService } from "utils/OpinionService";
import { FAILTOSAVERECOED } from "utils/Constants";
import { encrypt } from "utils/EncryptDecrypt";
import { withAlert } from "react-alert";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const PersonalDetailsForm2 = (props) => {
    const [currentStepInMainForm, setCurrentStepInMainForm] = useState(0);
    const { currentUserDetails, forUpdate, opinionInfo, isSelf, currentUserId, getOpinionInfo } = props;
    const { healthIssueValue, setHealthIssueValue } = props;

    const handleInputChange = (e) => {
        // console.log(e.target.value);
        setHealthIssueValue(e.target.value);
    };

    const updateOpinion = () => {
        let payload = {
            "speciality": opinionInfo?.speciality,
            "title": healthIssueValue,
            "status": opinionInfo?.status,
            "requestedBy": currentUserId?.requestedBy,
            "requestedFor": currentUserId?.requestedFor,
        }

        OpinionService.updateOpinion(opinionInfo?.id, payload)
            .then((response) => {
                const { status, data } = response;;
                if (status === 200) {
                    showSuccessMessage("Opinion updated successfully.");
                    // console.log(data);
                    getOpinionInfo(opinionInfo?.id);
                    setCurrentStepInMainForm('2');
                } else {
                    console.log(FAILTOSAVERECOED)
                }
            })


    }

    const handleSelectSpeciality = () => {
        if (forUpdate) {
            updateOpinion();
        }
        props.setCurrentStep('3')
    }

    useEffect(() => {
        if (opinionInfo && opinionInfo?.id)
            setHealthIssueValue(opinionInfo?.title);
    }, [forUpdate])


    return (
        <>
            <CardBody className={`nex-form-step ${currentStepInMainForm === 0 ? 'active' : ''}`}>
                <div className="nex-form-field-container">
                    <div className="nex-form-group">
                        <div className="nex-form-group-c mt-mobile-0">
                            <label htmlFor="" className="nex-big-label mb-3">Second opinion for </label>

                            <div className="patient-small-info-card mb-4">
                                <div className="graphic">
                                    <img
                                        src={createImageFromInitials(
                                            50,
                                            `${getFullNameOfPerson(currentUserDetails?.firstName, currentUserDetails?.middleName, currentUserDetails?.lastName)}`,
                                            getRandomColor(
                                                encrypt(
                                                    currentUserDetails?.id
                                                ).toString()
                                            )
                                        )}
                                        className="card-img nex-avatar"
                                        alt="#"
                                    />
                                </div>
                                <div className="info">
                                    <h2 className="card-title nex-text-wrap">{getFullNameOfPerson(currentUserDetails?.firstName, currentUserDetails?.middleName, currentUserDetails?.lastName)}<span className="nex-meta-txt">({getAgeFromDOB(currentUserDetails?.dateOfBirth)}), {getGenderDetails(currentUserDetails?.gender)}</span></h2>
                                    <div className="nex-inline-item-flex">
                                        {currentUserDetails?.emailAddress &&
                                            <p className="card-text d-flex gap-2 nex-text-wrap"><i className="icon"><svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M21 3C21 1.9 20.1 1 19 1H3C1.9 1 1 1.9 1 3M21 3V15C21 16.1 20.1 17 19 17H3C1.9 17 1 16.1 1 15V3M21 3L11 10L1 3" stroke="#3EABCA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            </i> {currentUserDetails?.emailAddress}</p>
                                        }
                                        {currentUserDetails?.cellularPhoneNumber &&
                                            <p className="card-text d-flex gap-2"><i className="icon"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18.9994 14.4765V17.1862C19.0005 17.4377 18.9488 17.6867 18.8479 17.9172C18.7469 18.1477 18.5988 18.3546 18.413 18.5247C18.2273 18.6947 18.008 18.8242 17.7693 18.9048C17.5305 18.9854 17.2775 19.0153 17.0265 18.9927C14.2415 18.6907 11.5664 17.7409 9.21601 16.2197C7.02929 14.8329 5.17534 12.9827 3.7858 10.8003C2.25627 8.44389 1.30442 5.76107 1.00735 2.96915C0.98473 2.71938 1.01447 2.46764 1.09468 2.22996C1.17489 1.99229 1.30381 1.77389 1.47323 1.58866C1.64265 1.40343 1.84885 1.25544 2.07872 1.15411C2.30858 1.05278 2.55707 1.00032 2.80837 1.00009H5.52347C5.96269 0.995773 6.3885 1.151 6.72152 1.43683C7.05455 1.72267 7.27207 2.11961 7.33354 2.55366C7.44814 3.42084 7.66067 4.27229 7.96707 5.09177C8.08883 5.41507 8.11519 5.76642 8.043 6.1042C7.97082 6.44198 7.80313 6.75203 7.5598 6.99761L6.41041 8.14473C7.69877 10.406 9.57482 12.2784 11.8406 13.5642L12.99 12.4171C13.2361 12.1742 13.5467 12.0069 13.8852 11.9348C14.2236 11.8628 14.5757 11.8891 14.8996 12.0106C15.7207 12.3164 16.5739 12.5285 17.4428 12.6429C17.8824 12.7048 18.2839 12.9258 18.5709 13.2639C18.858 13.6019 19.0105 14.0335 18.9994 14.4765Z" stroke="#3EABCA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                            </i> {currentUserDetails?.cellularPhoneNumber}</p>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div>
                                {forUpdate ?
                                    <>
                                        <span>Health Issue:</span><span style={{ fontWeight: "700", margin: "5px" }}>{opinionInfo?.title}</span>
                                    </>
                                    :
                                    <>
                                        <label htmlFor="" className="nex-big-label mb-3">Describe your health issue in brief ?</label>
                                        <FormGroup>
                                            <Input
                                                type="textarea"
                                                id="sq_health_issue"
                                                placeholder="Enter Your health issue..."
                                                value={healthIssueValue}
                                                onChange={handleInputChange}
                                            />
                                        </FormGroup>
                                    </>
                                }
                            </div>

                            <div>
                                {forUpdate ?
                                    <>
                                        <span>Selected Speciality:</span><span style={{ fontWeight: "700", margin: "5px" }}>{opinionInfo?.speciality}</span>
                                    </>
                                    :
                                    <>

                                        <label htmlFor="" className="nex-big-label mb-3">Choose Doctor Specialty</label>
                                        <Button
                                            color="primary"
                                            className="nexogic_primary_button"
                                            onClick={(e) => handleSelectSpeciality()}
                                            disabled={healthIssueValue === null || healthIssueValue === '' || healthIssueValue.length <= 0}
                                        >
                                            Click here to select doctor specialty
                                        </Button>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div >

                <div className="nex-form-group-c mb-2">
                    <div className="nex-form-action">
                        <Button
                            color="primary"
                            className="nexogic_primary_button_outline"
                            onClick={(e) => props.setCurrentStep('1')}
                        >
                            Back
                        </Button>
                        {opinionInfo?.speciality &&
                            <Button
                                color="primary"
                                className="nexogic_primary_button"
                                onClick={(e) => props.setCurrentStepInMainForm('2')}
                            >
                                Next
                            </Button>
                        }
                    </div>
                </div>
            </CardBody >
        </>
    );
};

export default withAlert()(PersonalDetailsForm2);
