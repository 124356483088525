import { Field, Form, Formik, ErrorMessage } from "formik";
import React, { useState, useEffect } from 'react'
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Label, Modal, ModalHeader, Row } from 'reactstrap'
import ThemeOne from 'assets/images/ui-icons/layout1.jpg'
import ThemeTwo from 'assets/images/ui-icons/layout2.jpg'
import ThemeThree from 'assets/images/ui-icons/layout3.jpg'
import * as Yup from "yup";
import ChooseTheme from "./Components/Themes/Components/ChooseTheme";
import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import secureLocalStorage from "react-secure-storage";
import './style.scss'

const initialValue = {
    theme: 'layouttwo',
    color0: '#b4e1fd', color1: '#f56e00', color2: '#000000', color3: '#dfedfb', color4: '#027dca', color5: '#ffffff',
}
const CreateInstitution = (props) => {
    // const [isOpen, setModal] = useState(false);
    // const toggle = () => setModal(!isOpen);
    const [selectedTheme, setSelectedTheme] = useState(initialValue);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const history = useHistory();
    const validationSchema = Yup.object().shape({
        theme: Yup.string().required("Please select a Theme"),
    });

    const { passToPage } = history.location.state;
    const { institution, currentUserId, getCreatedUser, otherBranches,awards, practitioners, eThemeDetails } = passToPage;


    const onSubmit = (values) => {
        const pageData = { themecolors: [`${values.color0}`, `${values.color1}`, `${values.color2}`, `${values.color3}`, `${values.color4}`, `${values.color5}`], institutedetails: institution, otherBranches: otherBranches, practitioners: practitioners,awards:awards, layouttype: values.theme, eThemeDetails:eThemeDetails };
        secureLocalStorage.setItem("nexogic.temp_admin_inst_data", JSON.stringify(pageData))
        let themeTemplate = '';
        // if (values.theme === 'THEME2')
        //     themeTemplate = '/microsite/create-template/11'
        if (values.theme !== '')
            themeTemplate = '/microsite/custom-theme'
        window.location.href = `${themeTemplate}`;
        // history.push(`${themeTemplate}`, {
        // themename: values.theme,
        // data: ["About", "Services", "Specialities", "Amenities", "Business Hour", "Gallery", "Awards", "Other Branches", "Practitioners"],
        //     themecolors: values.color,
        //     institutedetails: institution,
        // });
    }
    return (
        <div className='container-fluid microsite-side-main'>
            <main>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader><h3 className="text-dark">Create Your Website</h3></CardHeader>
                            <CardBody>
                                <Formik
                                    initialValues={initialValue}
                                    validationSchema={validationSchema}
                                    onSubmit={onSubmit}
                                    children={(formProps) => {
                                        return <Form>
                                            {/* <h2>Select your Theme</h2> */}
                                            <div role="group" aria-labelledby="my-radio-group" className="w-75">
                                                <h3 className="text-dark">Select Your Layout</h3>
                                                <div className="theme-list justify-content-space-between">
                                                    <label>
                                                        <div className="d-flex flex-row align-items-baseline pl-3">
                                                            <Field type="radio" name="theme" id="layout-2" value="layouttwo" checked />
                                                            <div className="d-flex flex-column pl-3 lh-lg"><h3 className="text-dark font-weight-normal">Lightening</h3>
                                                                <img src={ThemeTwo} width="200" height="119" alt="layoutTwo" />
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <label>
                                                        <div className="d-flex flex-row align-items-baseline pl-3">
                                                            <Field type="radio" name="theme" id="layout-1" value="layoutone" />
                                                            <div className="d-flex flex-column pl-3 lh-lg"><h3 className="text-dark font-weight-normal">Lightening with Left Sidebar</h3>
                                                                <img src={ThemeOne} width="200" height="119" alt="layoutOne" />
                                                            </div>
                                                        </div>
                                                    </label>
                                                    <label>
                                                        <div className="d-flex flex-row align-items-baseline pl-3">
                                                            <Field type="radio" name="theme" id="layout-3" value="layoutthree" />
                                                            <div className="d-flex flex-column pl-3 lh-lg"><h3 className="text-dark font-weight-normal">Lightening with Right Sidebar</h3>
                                                                <img src={ThemeThree} width="200" height="119" alt="layoutThree" />
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div><br />
                                                {/* {errorMsg !== "" && <RenderErrorMessage errorMsg={errorMsg} />} */}
                                            </div>
                                            <div className="d-flex justify-content-end pl-3" style={{ fontSize: "1.2rem" }}>
                                                <div className='text-danger d-inline-block  mt-1'>
                                                    <ErrorMessage name="theme">
                                                        {errorMsg => (errorMsg ? <RenderErrorMessage errorMsg={errorMsg} /> : null)}
                                                    </ErrorMessage>
                                                </div>
                                            </div>
                                            {/* <div className="pt-4"> */}
                                            <div className="d-flex flex-column pl-0" style={{ fontSize: "1.2rem" }}>
                                                <h3 className="text-dark">Choose Colors</h3>
                                                <div className="colorbox-container">
                                                    <div className="w75 mb-0">
                                                        <ChooseTheme setSelectedTheme={setSelectedTheme} {...props} {...formProps} />
                                                    </div>
                                                    <div className="w25 colorbox-btns">
                                                        {/* <div> */}
                                                            <Button
                                                                id="nex_cancel_edit"
                                                                className="nexogic_primary_button_outline next-btn mr-3"
                                                                type="button"
                                                                onClick={() => history.goBack()}
                                                            >
                                                                Cancel
                                                            </Button>
                                                            <Button
                                                                id="nex_next_edit"
                                                                className="nexogic_primary_button next-btn"
                                                                type="submit"
                                                            // disabled={isSubmitting}
                                                            >
                                                                Next
                                                            </Button>
                                                        {/* </div> */}
                                                    </div>
                                                </div>
                                            </div>

                                        </Form>
                                    }}
                                >
                                </Formik>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </main>
        </div>
    )
}

export default CreateInstitution