import CommonLoader from "common/CommonLoader";
import moment from "moment";
import DisplayCreatedTime from "pages/FeedHomePage/components/DisplayCreatedTime";
import NoJobs from "pages/Jobs/NoJobs";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import { TalentService } from "utils/TalentService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, FormGroup, Input, Row } from "reactstrap";
import { faAddressCard, faIdCard, faListAlt, faTable } from "@fortawesome/free-solid-svg-icons";
import { createImageFromInitials, getRandomColor } from "utils/Utils";
import { encrypt } from "utils/EncryptDecrypt";
import LoadMoreButton from "common/LoadMoreButton/LoadMoreButton";
import AppliedJobsTabularView from "components/tabularView/AppliedJobsTabularView";

const AppliedJobsList = forwardRef((props, ref) => {
  const [list_of_data, setList_of_data] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [viewMode, setViewMode] = useState("card");

  const getData = async (pageNo, feedlist) => {
    const request = TalentService.getMyAppliedJobs(pageNo);
    request
      .then(async (response) => {
        let { data } = response;
        if (data?.currentPage < (data?.totalPages - 1))
          setHasMore(true);
        else
          setHasMore(false);
        setIsLoadingMore(false);
        if (pageNo === 0) {
          const allData = { ...data };
          const resolved = await Promise.all(
            allData.contents.map(async (item) => {
              const each = { ...item };
              return each;
            })
          );
          allData.contents = resolved;
          setList_of_data(allData);
          setIsLoading(false);
        } else {
          const resolved = await Promise.all(
            data.contents.map(async (item) => {
              const each = { ...item };
              return each;
            })
          );
          feedlist = feedlist.concat(resolved);
          setList_of_data({ ...data, contents: feedlist });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("Error while getting job list: ", error);
        setIsLoadingMore(false);
      });
  };

  const fetchData = () => {
    setIsLoadingMore(true);
    getData(list_of_data.currentPage + 1, list_of_data.contents);
  };

  useImperativeHandle(ref, () => ({
    getJobListData() {
      getData(0, []);
    },
  }));

  useEffect(() => {
    getData(0, []);
  }, []);

  if (isLoading) {
    return (
      <div className="loading-spiner text-center  mt-2">
        <CommonLoader style={{ width: "2rem", height: "2rem" }} />
      </div>
    );
  }

  const renderProfiles = () => {
    if (viewMode === 'card') {
      return (
        <>
          {list_of_data &&
            list_of_data.contents.map((e, id) => (
              <>
                <div className="nexogic-job-list-card card nexogic-applied-jobs" key={id}>
                  <div className="card-head align-items-center">
                    <div className="card-thumb">
                      <Link
                        to={`/institution/${e?.institutionDetails?.profileId}`}
                      >
                        {e?.institutionDetails?.avatarId ?
                          <img
                            src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${e?.institutionDetails?.guid}/${e?.institutionDetails?.avatarId}`}
                            alt="institution profile pic" className="card-img" />
                          :
                          <img
                            src={createImageFromInitials(50, `${e?.institutionDetails?.name}`, getRandomColor(encrypt(e?.id).toString()))}
                            alt="institution profile pic" className="card-img" />
                        }
                      </Link>

                    </div>
                    <div className="information">
                      <h3 className="card-title"><Link
                        className="job-link "
                        to={`/institution/${e?.institutionDetails?.profileId}`}
                      >
                        {e?.institutionDetails?.name}
                      </Link> <span className="card-text">(
                          {e?.institutionDetails?.address?.city}
                          {" , "}
                          {e?.institutionDetails?.address?.state} {" , "}
                          {e?.institutionDetails?.address?.country}
                          )</span></h3>

                      <p className="fw-semibold card-text"><Link to={`/jobs/${e?.profileId}`}>{e?.title}</Link></p>
                      <p className="card-text">{e?.location}
                        {", "}
                        {e?.city}</p>
                    </div>
                  </div>
                </div>
              </>
            ))}
        </>
      );
    } else { // For table view
      return (
        <AppliedJobsTabularView theader={['Job Title', 'Institution Name', 'Speciality', 'Job Location']} tdata={list_of_data.contents} viewmode={viewMode} />
      );
    }
  };

  return list_of_data && list_of_data.contents.length > 0 ? (
    <>
      {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && (
        <div className="search-tab-data card border-0 bg-transparent nex-app-mt-0">
          <div className="card-body p-0">
            <h1 className="card-title mb-2 nex-app-sec-title">My Applied Jobs</h1>
            <Row>
              <Col sm={5} className="pl-0 nex-app-no-pad">
                <FormGroup>
                  <Input type="select" id="ajl_select" name="ajl_select">
                    <option>Location</option>
                    <option>Seach option 1</option>
                    <option>Seach option 2</option>
                    <option>Seach option 3</option>
                    <option>Seach option 4</option>
                    <option>Seach option 5</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col sm={5} className="nex-app-no-pad">
                <FormGroup>
                  <Input type="text" placeholder="Enter Job Title " id="ajl_job_title" name="ajl_job_title" />
                </FormGroup>
              </Col>
              <Col sm={2} className="pr-0 nex-app-no-pad">
                <FormGroup className="filter-job-action mt-0">
                  <Button color="primmary" className="nexogic_primary_button" id="ajl_job_search" >
                    Search
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </div>
        </div>
      )}
      <div>
        <div className="view-toggle nexogic-view-toggle mb-2">
          <div className="btns">
            <FontAwesomeIcon title="Card View" color={viewMode === "card" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faAddressCard} onClick={() => setViewMode("card")} /> | <FontAwesomeIcon title="Table View" color={viewMode === "table" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faListAlt} onClick={() => setViewMode("table")} />
          </div>
        </div>
        {renderProfiles()}
        {hasMore &&
          <div className="text-center mt-3">
            {isLoadingMore
              ?
              <CommonLoader style={{ width: "2rem", height: "2rem" }} />
              :
              <LoadMoreButton loadMore={fetchData} />
            }
          </div>
        }
      </div>
    </>
  ) : (
    <NoJobs content={"No Applied Job"} />
  );
});

export default AppliedJobsList;
