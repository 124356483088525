import React, { useEffect, useState } from 'react';
import './css/style.css'
import { Col,Row } from 'reactstrap';
import location_icon from "assets/svgIcon/location_icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContactsList from './ContactsList';
import InstitutionImageUploaderUI from 'pages/Institutions/components/InstitutionsProfile/components/InstitutionImageUploaderUI';
import RenderInstitutionCoverImageUI from 'pages/Institutions/components/InstitutionsProfile/components/RenderInstitutionCoverImageUI';
import { faLocationArrow, faMapMarked, faMapMarker, faSearchLocation } from '@fortawesome/free-solid-svg-icons';

const SectionBlank = (props) => {
  const { themeDetails, institution, isEditable, isAdmin } =props;
  const { name, address, phoneContacts, emailContacts } = institution;
  const sectionColors = (sThemeColor) => {
    document.documentElement.style.setProperty('--section-bg-color', sThemeColor[3]);
    document.documentElement.style.setProperty('--section-headerbg-color', sThemeColor[4]);
    document.documentElement.style.setProperty('--section-headerfont-color', sThemeColor[5]);
    document.documentElement.style.setProperty('--section-font-color', sThemeColor[2]);
    document.documentElement.style.setProperty('--section-heading-color', sThemeColor[1]);
  }
  
  useEffect(() => {
    if (themeDetails.length > 0) {
      sectionColors(themeDetails)
    }
  }, [themeDetails])

  return (
    <>
      <div className="nexogic-card-banner card border-0 nexogic-banner-admin-card mb-0 ">
      <RenderInstitutionCoverImageUI
        bannerUrl={institution.bannerUrl}
        isEditable={isEditable??true}
        institution={institution}
        isAdmin={isAdmin}
      />
       <div className="card-body mb-0 bg-transparent position-absolute w-auto border-0">
      <div className="card-user-img-w">
        <InstitutionImageUploaderUI
          profilePicUrl={null}
          institution={institution}
          isEditable={isEditable??true}
          isAdmin={isAdmin}
          institutionName={name}
        />
        </div>
        </div>
      </div>
      <Row className='micro-section-card-v1 bg-color rounded-bottom pb-4 pt-3'>
        <Col lg={9}>
          <h2 className="card-titile mb-2">{name}</h2>
          <font>
            {address && (
              <>
                <FontAwesomeIcon icon={faMapMarked} className="ml-1" style={{fontSize:'20px'}} />
                <span className="pl-2 m-2">
                  {(address?.location) ? `${address?.location},` : ""} {(address?.city) ? `${address?.city},` : ""} {(address?.state) ? `${address?.state},` : ""} {(address?.country) ? `${address?.country}` : ""}
                </span>
              </>
            )}
          </font>
          <div className="card-text banner-phone-email">
            {(phoneContacts?.length > 0 || emailContacts?.length > 0) &&
              <ContactsList phoneContacts={phoneContacts} emailContacts={emailContacts} />
            }
          </div>
        </Col>
        <Col lg={3} >
          <div className="button-groups">
            <a href={institution.websiteURL} className="nexogic_primary_button" target="_blank" rel="noreferrer">Visit Website</a>
            {/* <button type="button" className="nexogic_primary_button_outline  btn btn-primary">Follow</button> */}
          </div>
        </Col>
      </Row>
    </>
  )
}

export default SectionBlank