import classnames from "classnames";
import { useState } from "react";

import { Card, CardBody, Nav, NavItem, NavLink } from "reactstrap";
import AllConnectionsTab from "./components/AllConnectionsTab";
import Invitations from "./components/Invitations";
import Pending from "./components/Pending";

const AllConnections = (props) => {
  const [activeTab, setActiveTab] = useState("All connections");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <>
      <Card className="border-0 bg-transparent">
        <CardBody className="p-0">
          <Nav tabs className="nexogic-tabs">
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "All connections",
                })}
                onClick={() => {
                  toggle("All connections");
                }}
              >
                All Network
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "Pending" })}
                onClick={() => {
                  toggle("Pending");
                }}
              >
                Pending
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "Invitations" })}
                onClick={() => {
                  toggle("Invitations");
                }}
              >
                Invitations
              </NavLink>
            </NavItem>
          </Nav>
          {activeTab === "All connections" && <AllConnectionsTab />}
          {activeTab === "Pending" && <Pending />}
          {activeTab === "Invitations" && <Invitations />}
        </CardBody>
      </Card>
    </>
  );
};

export default AllConnections;
