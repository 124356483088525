import addIcon from "assets/images/svgs/add_icon.svg";
import {faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "assets/styles/typeahead.css";
import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import { useFormik } from "formik";
import { findIndex } from "lodash";
import { useEffect, useRef, useState, useMemo } from "react";
import { withAlert } from "react-alert";
import { Typeahead } from "react-bootstrap-typeahead";
import { Button, Col, FormGroup, Row, Spinner } from "reactstrap";
import { MetadataService } from "utils/MetadataService";
import plusIcon from "assets/images/add-icon.png";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  expert: Yup.string().required("This field is required"),
});

const MoreSkills = (props) => {
  const { toggle, alert,values, allskillsList,primarySpeciality, setSkillsList,setFieldValue } = props;
  const [servicesData, setServicesData] = useState([]);
  const [skills, setSkills] = useState(values.skills);
  const [skillsLists, setSkillsLists] = useState(allskillsList);
  const [primarySpecialityList, setPrimarySpecialityList] = useState(primarySpeciality);
  const [suggestedSkills, setSuggestedSkills] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [loadMore,setLoadMore] = useState(10);
  const [isCompleted, setIsCompleted] = useState(false);
  const [errorMsg, setErrorMessage] = useState("");
  const pskillsRef = useRef(null);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        MetadataService.getAllSkillSet().then((response) => {
          const { status, data } = response;
          if (status === 200 || status === 201) {
            setLoading(false);
            setSkillsLists(data);
            // setSuggestedSkills(data);
          }
        });
      } catch (e) {
        setErrorMessage(e.message);
        setLoading(false);
      }
    })();
    getSuggestedSkills();
  }, []);

  const getSuggestedSkills = () => {
    try {
      MetadataService.getSpecialitySkillSet(
        primarySpeciality
      ).then((response) => {
        const { status, data } = response;
        if (status === 200 || status === 201) {
            setSuggestedSkills(data);
        }
      });
    } catch (e) {
      setErrorMessage(e.message);
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: { pskills: values.skills },
    validationSchema: validationSchema,
  });

  const addSelectedSkills = (value) => {
    setSkillsList(value);
    setFieldValue("skills",value,true);
    toggle();
  }
  const handleLoadMore = () => {
    setLoadMore(loadMore + 550);
    // if (loadMore >= skillsLists.length) {
    if (loadMore >= suggestedSkills.length) {
      setIsCompleted(true)
    } else {
      setIsCompleted(false)
    }
  }

  const handleSkillsList = (skill) => {
    setSkills([...skills, skill]);
  };

  const servicesSuggestion = useMemo(() => {
    if (suggestedSkills && suggestedSkills.length > 0) {
      const uniqueSuggestedSkills = suggestedSkills.filter(
        (s) => findIndex(skills, (o) => o === s) < 0
      );
      return uniqueSuggestedSkills?.slice(0, loadMore)?.map((data, ind) => {
        return (
          <div
            key={`nex-sk-moreskill-s-${ind}`}
            id={`nex-sk-moreskill-suggest-${ind}`}
            className="nexogic-suggested-skill d-flex align-items-center"
            onClick={() => handleSkillsList(data)}
          >
            <div className="card-text">{data}</div>
            <div className="add-icon ml-1">
              <img src={addIcon} alt="" />
            </div>
          </div>
        );
      });
    }
    return null;
  }, [skills, suggestedSkills,loadMore]);

  const onChangeHandler = (campaign) => {
    const preData = campaign.map((item) => {
      let clone = item;
      let custom = item?.customOption ? true : false;
      if (custom) {
        clone = item?.name;
      }
      return clone;
    });
    preData.length ? setSkills(preData) : setSkills([]);
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit} id="nex-pskills-form">
        {errorMsg !== "" && <RenderErrorMessage errorMsg={errorMsg} />}
        <Row form>
          <Col xs={12}>
            <FormGroup className="searchbox-input-chips mb-1" id={'nex-pskills'}>
            <Typeahead
                ref={pskillsRef}
                allowNew
                clearButton
                newSelectionPrefix="Add Skills: "
                selected={skills}
                id="pskills"
                multiple
                options={skillsLists}
                labelKey={"name"}
                placeholder="Add skills "
                onBlur={() => {
                  return pskillsRef !== null
                    ? pskillsRef.current.hideMenu()
                    : null;
                }}
                onChange={onChangeHandler}
              />
            </FormGroup>
            <p className="mb-2 card-text"><small>You can add upto 50 Skills</small></p>
            <div className="nexogic-suggested-skills d-flex flex-wrap mt-3" id={'nex-pskills-options'}>
              {servicesSuggestion}
            </div>
            <div className="nexogic-suggested-skill d-flex flex-wrap my-2 justify-content-center">
            {!isCompleted && suggestedSkills.length >10 &&(
              <Button
                id="load_more"
                className="nexogic_primary_button_outline small mt-2"
                color="link"
                onClick={handleLoadMore}
              >
                <img src={plusIcon} alt=""/>  Load more
              </Button>
            )}
            </div>
          </Col>
        </Row>
        {/** <hr className="profile-info-modal-hr" />*/}
        <div className="justify-content-end  modal-footer">
          <Col xs="auto" className="action-group">
            {isLoading ? (
              <div className="loading-spiner">
                <Spinner />
              </div>
            ) : (
              <>
                
                <Button
                  id="ms_btn_submit"
                  className="nexogic_primary_button"
                  color="primary"
                  type="submit"
                  onClick={() => addSelectedSkills(skills)}
                  disabled={skills.length === 0}
                >
                  Save
                </Button>
                <Button
                  id="ms_btn_Cancel"
                  className="nexogic_primary_button_outline"
                  color="primary"
                  type="submit"
                  onClick={toggle}
                >
                  Cancel
                </Button>
              </>
            )}
          </Col>
        </div>
      </form>
    </>
  );
};

export default withAlert()(MoreSkills);
