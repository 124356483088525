import { faCheckCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import SpecialitiesModal from "./SpecialitiesModal";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import blue_bullets from "assets/images/blue-bullets.png";
import plusIcon from "assets/images/plus-icon-lg.png";


const Specialities = (props) => {
  const {
    isEditable,
    setPractitioner,
    isAdmin,
    institution,
    getInstitutionProfile,
    setShowSpecialitiesOnTabScroll
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [count, setCount] = useState(12);
  const [specialities, setSpecialities] = useState([]);
  const [pannel_on, setPannel_on] = useState(true);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setSpecialities(institution?.specialities);
  }, [institution]);

  useEffect(() => {
    if (specialities && specialities?.length) {
      setShowSpecialitiesOnTabScroll(true);
    }else{
      setShowSpecialitiesOnTabScroll(false);
    }
  }, [specialities]);

  const RenderSpecialities = (props) => {
    const { specialities,count } = props;
    if (specialities && specialities?.length) {
      return (
        <>
          {specialities.slice(0, count).map((speciality, index) => {
            if (speciality !== "") {
              return (
                <li className="list-group-item" key={index}>
                  <div className="row g-0">
                    <div className="col-auto">
                        <img src={blue_bullets} alt="" />
                    </div>
                    <div className="col"><h3 className="card-title fw-semibold">{speciality}</h3></div>
                  
                    {/*<FontAwesomeIcon icon={faCheckCircle} className="icon-check" />*/}
                    
                  </div>
                      
                </li>
                    )
                  }
          })}
        </>
          )
    }
    return "";
  };

  const ShowMoreServices = (props) => {
    const { services, count, setCount } = props;
    if (services && services.length > count) {
      const remainingCount = services.length - count;
      return (
        <Button
          id="nex-specialities-more-btn"
          color="link"
          onClick={() => setCount(services.length)}
          className="readmore"
        >
          {`+ ${remainingCount} more`}
        </Button>
      );
    }
    return null;
  };

  return (
    <>
      {((specialities.length > 0 && !isAdmin) || isAdmin) && (
        <Card className={`card nexogic-card-color-header ${pannel_on ? 'expanded' : ''}`}  id="specialities">
          <div className="card-header"><h2>Specialities</h2>
                            <button className="collapse-btn" type="button" onClick={()=>setPannel_on(!pannel_on)}>
                              <img src={pannel_arrow} alt="" />
                          </button>
                          {(isEditable || isAdmin) && (
                          
                            <Button
                              id="nex-specialities-add-btn"
                              color="link"
                              className="add-button"
                              onClick={toggle}
                            >
                              <img src={plusIcon} alt="" />
                              {/*<FontAwesomeIcon icon={faPlus} />*/}
                            </Button>
                          
                        )}
          </div>
          <div className={`collapse-div`}>
          <CardBody>
            
              
              {specialities && specialities.length > 0
                  ? ""
                  : (isEditable || isAdmin) && (
                    <p className="card-text">
                    <Button
                    id="nex-specialities-add-btn2"
                    className="readmore"
                    color="link"
                    onClick={toggle}
                  >
                    {"Add information"}
                  </Button>
                  </p>
                    )}
     
            
              <div className="nexogic-card-list">
              <ul className="list-group list-group-flush">
                
                <RenderSpecialities specialities={institution?.specialities} count={count} />
                </ul>
                <ShowMoreServices
                  services={specialities}
                  count={count}
                  setCount={setCount}
                />
                </div>
                

          </CardBody>
          </div>
        </Card>
      )}
      <SpecialitiesModal
        isOpen={isOpen}
        toggle={toggle}
        specialities={institution.specialities}
        institution={institution}
        getInstitutionProfile={getInstitutionProfile}
        practitionerId={637}
        setPractitioner={setPractitioner}
        isAdmin={isAdmin}
      />
    </>
  );
};

export default Specialities;
