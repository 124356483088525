import expandArrow from "assets/images/expand_arrow.png";
import { useMemo, useState } from "react";
import { useAlert } from "react-alert";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { connect, useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { profileAddEvent } from "redux-helpers/ActionCreator";
import { UserStatusConstants } from "utils/Constants";
import { isAdmin } from "utils/UserHelper";
import tip_icon from "assets/images/tip_icon.png";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const ProfileCompleteness = (props) => {
  const { profileComplete } = props;
  const dispatch = useDispatch();
  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );
  const isAdminUser = useMemo(() => isAdmin());
  const alert = useAlert();
  const [currentTip, setCurrentTip] = useState(0);
  const percentage = useMemo(() => {
    let total = profileComplete.data.length;
    let addedSections = profileComplete.data.filter((item) => item.filled);
    return Math.round((addedSections.length / total) * 100);
  }, [profileComplete.data]);

  const pendingItems = useMemo(() => {
    let sectionsNotFilled = profileComplete.data.filter(
      (item) => item.filled === false
    );
    return sectionsNotFilled.length > 0 ? sectionsNotFilled : null;
  }, [profileComplete.data]);

  const moveToNextTip = () => {
    setCurrentTip(currentTip + 1);
  };

  if (percentage >= 100) return null;

  return (
    <div id="profileCompleteness" className="">
      <div className="card nexogic-profile-complete">
                        <div className="row no-gutters">
                          <div className="col">
                              <div className="card-body">
                                  <h5 className="card-title">Profile completeness <span className="percentage">{percentage}%</span></h5>
                                  <div className="percentage-indicator"><span style={{width:`${percentage}%`}}></span></div>
                              </div>
                          </div>
                        </div>
                      </div>

                      <div className="card nexogic-profile-tip">
                        <div className="row no-gutters">
                          <div className="col-auto">
                              <img src={tip_icon} alt="" />
                          </div>
                          <div className="col">
                              <div className="card-body">
                                  <div className="tip-info">
                                  <h5 className="card-title">{`Next Tip :`}</h5>
                                  <p className="card-text"><small className="text-muted">{" "}
                        {pendingItems[currentTip].tip}</small></p>
                        </div>
                        <Button
                        className="nexogic_primary_button"
                      color="link"
                      onClick={() => {
                        if (
                          isAdminUser || globalProfileInfo.status ===
                          UserStatusConstants.APPROVED
                        ) {
                          dispatch(
                            profileAddEvent(pendingItems[currentTip].event)
                          );
                        } else {
                          alert.removeAll();
                          showErrorMessage(
                            "Permission denied. Please contact admin for approval."
                          );
                        }
                      }}
                    >
                      {pendingItems[currentTip].name}
                    </Button>
                              </div>
                          </div>
                        </div>
                      </div>
      {/*<CardBody>
        <Row className="">
          <Col>
            <Row>
              
              <Col md={5}>
                <div className="d-flex align-items-center">
                  <CircularProgressbar
                    value={percentage}
                    text={`${percentage}%`}
                    styles={{
                      root: {
                        maxHeight: "65px",
                        maxWidth: "65px",
                      },
                      path: {
                        strokeWidth: 8,
                        strokeLinecap: "butt",
                        stroke: "#3EABCA",
                      },
                      trail: {
                        strokeWidth: 4,
                        color: "#E5F2F6",
                        stroke: "#ddd",
                      },
                      text: {
                        fontSize: "24px",
                        fontWeight: "bold",
                        fill: "#22246d",
                      },
                    }}
                  />
                  <h4 className="mx-4 pro-title mr-5 ">Profile completeness</h4>
                </div>
              </Col>


              <Col md={7} className="d-flex align-items-center pc-tips">
                {!!pendingItems && (
                  <div>
                    <div className="mb-0">
                      <span className="pro-title">{`Next Tip :`}</span>
                      <span className="tip-hints">
                        {" "}
                        {pendingItems[currentTip].tip}
                      </span>
                    </div>
                    <Button
                      color="link"
                      onClick={() => {
                        if (
                          isAdminUser || globalProfileInfo.status ===
                          UserStatusConstants.APPROVED
                        ) {
                          dispatch(
                            profileAddEvent(pendingItems[currentTip].event)
                          );
                        } else {
                          alert.removeAll();
                          showErrorMessage(
                            "Permission denied. Please contact admin for approval."
                          );
                        }
                      }}
                    >
                      {pendingItems[currentTip].name}
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          <Col xs="auto" className="d-flex align-items-center">
            {!!pendingItems && pendingItems.length - 1 > currentTip && (
              <div className="expand-btn ml-2" onClick={moveToNextTip}>
                <img src={expandArrow} alt={"..."} />
              </div>
            )}
          </Col>
        </Row>
      </CardBody>
      */}
    </div>
  );
};

const mapStateToProps = ({ profileComplete }) => ({
  profileComplete,
});

export default connect(mapStateToProps, null)(ProfileCompleteness);
