import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Close from "assets/images/close.png";
import { createBrowserHistory } from "history";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Col,
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import { isAdmin, isInstitutionAdmin, isPatient } from "utils/UserHelper";
import { UserService } from "utils/UserService";
import HeaderMenu from "./components/headerMenu/HeaderMenu";
import HeaderMenuPatient from "./components/headerMenuPatient/HeaderMenuPatient";
import Hospital from "./components/Hospital";
import InCompleteNotify from "./components/InCompleteNotify";
import Logo from "./components/Logo";
import ProfileMenu from "./components/ProfileMenu";
import ResponsiveNotification from "./components/ResponsiveFolders/ResponsiveNotification";
import ResponsiveProfileInfo from "./components/ResponsiveFolders/ResponsiveProfileInfo";
import ResponsiveSearch from "./components/ResponsiveFolders/ResponsiveSearch";
import Search from "./components/Search";
import "./header.css";
import FooterMobileMenu from "common/FooterMobileMenu";
import AppHeaderTop from "common/AppHeaderTop";
import { useEffect } from "react";
import { UserStatusConstants } from "utils/Constants";

const Header = () => {

  const [isActive, setIsActive] = useState(false);
  const handleToggle = () => {
    setIsActive(!isActive);
  }

  const history = createBrowserHistory();
  const pathname = history.location.pathname;
  const [toggler, setToggler] = useState(false);
  const [token, setToken] = useState(UserService.verifyToken());
  const isAdminUser = useMemo(() => isAdmin());
  const isPatientUser = useMemo(() => isPatient());
  const currentUser = useSelector((state) => state.auth.currentUser);
  const toggle = () => setToggler(!toggler);

  const logout = () => {
    UserService.logout();
    window.location.reload();
  };

  const [isApp, setIsAPP] = useState(false);
  const [isSearchOn, setIsSearchOn] = useState(false);
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get('appreset') === '1') {
      localStorage.removeItem('app_status');
    }
    const ls_app_val = localStorage.getItem('app_status') || null;

    const inApp = queryParams.get('inapp');
    //const is_browser = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Win/i.test(navigator.userAgent);
    //const is_react_native = /ReactNativeWebView|WebView/i.test(navigator.userAgent);

    if (inApp === '1' || ls_app_val == 'inapp') {
      setIsAPP(true);
      localStorage.setItem('app_status', 'inapp');
      document.body.classList.add('view_in_app');
    }
  }, []);

  return (
    <>
      <header className="nexogic-header-card sticky-top">
        <div className="bg-white main-header">
          <Container fluid className="nexogic-container-gap">
            <Row className="align-items-center justify-content-between">
              <Col lg={'auto'} className="hide-on-app">
                {token ? (
                  <Link to="/" className={`m-0 ${isPatientUser ? "" : "navbar-brand"}`}>
                    <div className="icon show-on-app"><svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 21V11H13V21M1 8L10 1L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V8Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    </div>
                    <span className="label show-on-app">Home</span>
                    <div className={`${isPatientUser ? 'nexogoc-logo-with-text' : 'nexogoc-logo-icon'}`}>
                      <Logo className="hide-on-app" role={isPatientUser} />
                    </div>
                  </Link>
                ) : (
                  <Link to={process.env.REACT_APP_WEBSITE_URL}
                    className={`m-0 ${isPatientUser ? "" : "navbar-brand"}`}
                  >
                    <div className="icon show-on-app"><svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 21V11H13V21M1 8L10 1L19 8V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V8Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    </div>
                    <span className="label show-on-app">Home</span>
                    <Logo className="hide-on-app" width="60" role={isPatientUser} />
                  </Link>
                )}
              </Col>
              {token && !isPatientUser && (
                <>
                  <div className={`col header-search-switch show-on-app ${isSearchOn ? 'on' : ''}`} onClick={(e) => { setIsSearchOn(!isSearchOn) }}>
                    <div className="icon">
                      <svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 15L11.6167 11.6167M13.4444 7.22222C13.4444 10.6587 10.6587 13.4444 7.22222 13.4444C3.78578 13.4444 1 10.6587 1 7.22222C1 3.78578 3.78578 1 7.22222 1C10.6587 1 13.4444 3.78578 13.4444 7.22222Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </div>
                    <span className="label">Search</span>
                    <div className="app-top-label">
                      Search <a href="" className="nex-cross" onClick={(e) => { e.preventDefault(); setIsSearchOn(false); }}>&times;</a>
                    </div>
                  </div>
                  <Col className={`header-search-wrap ${isSearchOn ? 'on' : ''}`}>
                    <Row>
                      {/* <Col xs={2} className="d-flex align-items-center">
                      {!isAdminUser && <Hospital />}
                    </Col> */}
                      <Col className="d-flex align-items-center">
                        {/* Search */}
                        <Search />
                      </Col>

                    </Row>
                  </Col>
                </>
              )}
              {token && (
                <div className="ml-auto my-auto text-right col col-md-auto nexogic-menu-btn-wrap">
                  <div className="col-auto nexogic-header-menu-w">
                    {
                      <HeaderMenu />
                    }
                  </div>
                </div>
              )}

              {token && currentUser ? (
                <Col md={'auto'} className="header-profile">
                  {/* Profile menu */}
                  <ProfileMenu key={token} currentUser={currentUser} />
                </Col>
              ) : (
                <Col md={"auto"} xs={7} className="ml-auto my-auto text-right">
                  <div className="d-flex justify-content-end">
                    <Nav>
                      {pathname.includes("/register") ? (
                        <NavItem className="mx-2">
                          <Link
                            to="/login"
                            className={`nexogic_primary_button btn  ${pathname === "/login" ? "btn-primary" : "btn-link"
                              } `}
                          >
                            Sign In
                          </Link>
                        </NavItem>
                      ) : null}
                      {pathname.includes("/login") ? (
                        <NavItem className="mx-2">
                          <Link
                            to={'/register'}
                            className={`nexogic_primary_button btn   ${pathname === "/register"
                              ? "btn-primary"
                              : "btn-link"
                              }`}
                          >
                            Sign Up
                          </Link>
                        </NavItem>
                      ) : null}
                    </Nav>
                  </div>
                </Col>
              )}
            </Row>
          </Container>
        </div>
        {/* {<InCompleteNotify currentUser={currentUser} />} */}
      </header>

      <section className="header-w-notification sticky-top--- d-none">
        <header className="bg-white main-header  shadow-sm d-none d-md-inline-flex w-100">
          <Container
            className={`${token ? "dashnew-container" : "container-dashboard"}`}
          >
            <Row className="align-items-center justify-content-between">
              <Col md={2}>
                {token ? (
                  <Link to="/" className="m-0 navbar-brand">
                    <Logo width="136" role={isPatientUser} />
                  </Link>
                ) : (
                  <Link to={process.env.REACT_APP_WEBSITE_URL}
                    className="m-0 navbar-brand"
                  >
                    <Logo width="136" role={isPatientUser} />
                  </Link>
                )}
              </Col>
              {token && !isPatientUser && (
                <Col xs={3} md={8}>
                  <Row>
                    <Col xs={11}>
                      <Search />
                    </Col>
                  </Row>
                </Col>
              )}
              {token ? (
                <Col xs={3} md={2} className="ml-auto my-auto text-right">
                  <ProfileMenu key={token} currentUser={currentUser} />
                </Col>
              ) : (
                <Col md={"auto"} xs={7} className="ml-auto my-auto text-right">
                  <div className="d-flex justify-content-end">
                    <Nav>
                      {pathname.includes("/register") ? (
                        <NavItem>
                          <Link
                            to="/login"
                            className={`nav-link btn  ${pathname === "/login" ? "btn-primary" : "btn-link"
                              } `}
                          >
                            Sign In
                          </Link>
                        </NavItem>
                      ) : null}
                      {pathname.includes("/login") ? (
                        <NavItem className="mx-2">
                          <a
                            href={`${process.env.REACT_APP_WEBSITE_URL}/profiles/`}
                            className={`nav-link btn  ${pathname === "/register"
                              ? "btn-primary"
                              : "btn-link"
                              }`}
                          >
                            Sign Up
                          </a>
                        </NavItem>
                      ) : null}
                    </Nav>
                  </div>
                </Col>
              )}
            </Row>
          </Container>
        </header>
        {/* <InCompleteNotify currentUser={currentUser} /> */}
      </section>

      <header className="bg-white sticky-top-- shadow-sm d-md-none mobile-header">
        <Navbar color="faded" light className="px-1 py-1">
          <div className="me-auto py-0 pl-2 pr-0">
            {token ? (
              <Link to="/" className="m-0 navbar-brand px-1">
                <Logo width="136" role={isPatientUser} />
              </Link>
            ) : (
              <a
                href={process.env.REACT_APP_WEBSITE_URL}
                className="m-0 navbar-brand"
              >
                <Logo width="136" role={isPatientUser} />
              </a>
            )}
          </div>
          <div className="d-flex justify-content-center align-items-center pr-2">
            {token ? (
              <>
                <Nav>
                  <NavItem>
                    <ResponsiveSearch />
                  </NavItem>
                  {!isAdmin() && (
                    <NavItem>
                      <ResponsiveNotification />
                    </NavItem>
                  )}
                </Nav>
                {toggler ? (
                  <span className="ml-2" onClick={() => setToggler(!toggler)}>
                    {" "}
                    <img src={Close} alt="" width="32" />
                  </span>
                ) : (
                  <NavbarToggler className="ml-3 px-0" onClick={toggle} />
                )}
              </>
            ) : (
              <div className="d-flex justify-content-end">
                <Nav>
                  <NavItem className="mx-2">
                    <Link
                      to="/login"
                      className={`nav-link btn text-white ${pathname === "/login" ? "btn-primary" : "btn-link"
                        } `}
                    >
                      Sign In
                    </Link>
                  </NavItem>
                  <NavItem className="mx-2">
                    <Link
                      to={`/profiles`}
                      className={`nav-link btn text-white ${pathname === "/register" ? "btn-primary" : "btn-link"
                        }`}
                    >
                      Sign Up
                    </Link>
                  </NavItem>
                </Nav>
              </div>
            )}
          </div>
          {toggler && <hr className="text-secondary w-100 my-1" />}
          <Collapse navbar isOpen={toggler} className="pl-3 pr-2 pt-3 pb-2">
            <Nav navbar>
              <NavItem>
                <ResponsiveProfileInfo
                  currentUser={currentUser}
                  toggle={toggle}
                  uploadImage={""}
                />
              </NavItem>
              {!isAdmin() && (
                <>
                  <NavItem>
                    <Hospital />
                  </NavItem>
                </>
              )}
              <NavItem onClick={logout}>
                <div className="d-flex justify-content-start align-items-center">
                  <FontAwesomeIcon
                    icon={faSignOutAlt}
                    style={{ color: "#FF6347" }}
                    className="ml-1"
                  />
                  <NavLink
                    href="/login"
                    className="text-primary d-block text-decoration-none mx-2"
                  >
                    Logout
                  </NavLink>
                </div>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </header>
      {token ? <FooterMobileMenu /> : <></>}
      <AppHeaderTop />
    </>
  );
};

export default Header;