import React, { useState } from 'react';
import filter_icon from "assets/images/svgs/filter-blue-icon.svg";
import { Button, PopoverBody, UncontrolledPopover } from "reactstrap";

 function ReferalsFilter(props) {

    const { filter, setFilter } = props;
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(""); // State to hold the selected filter value

    const toggle = () => setPopoverOpen(!popoverOpen);

    const handleFilterChange = (event) => {
        setSelectedFilter(event.target.value); // Update the selected filter value
    };

    const applyFilter = () => {
        setFilter(selectedFilter); // Set the selected filter value using the setFilter function
        toggle(); // Close the popover after applying the filter
    };

    return (

        <>
            <div className="nexogic-page-flter-title-w">
                {props.isTitle &&
                    <h1 className="nexogic-page-title w-100 nexogic-page-flter-title mb-0">
                        {`My ${filter === 'SENT' ? 'Sent' : 'Received'} Referrals`}
                    </h1>
                }
                <div className='nexogic-home-filter-w'>
                    <Button
                        id="ReferralPopoverFocus"
                        type="button"
                        className="filter-btn-wrp"
                        onClick={toggle}
                    >
                        <img src={filter_icon} alt="#" className="ml-2" />
                    </Button>
                    <UncontrolledPopover
                        placement="bottom"
                        target="ReferralPopoverFocus"
                        trigger="legacy"
                        isOpen={popoverOpen}
                        toggle={toggle}
                    >
                        <PopoverBody>
                            <div className="form-group mb-2">
                                <label>Filter</label>
                                <select className='form-control' onChange={handleFilterChange} value={selectedFilter}>
                                    <option value="">Select an option</option>
                                    <option value="RECEIVED">Received Referral</option>
                                    <option value="SENT">Sent Referral</option>
                                </select>
                            </div>
                            <button className="btn nexogic_primary_button_outline  w-100" onClick={applyFilter}>Apply Filter</button>
                        </PopoverBody>
                    </UncontrolledPopover>
                </div>
            </div>
        </>
    )
}


ReferalsFilter.defaultProps = {
    isTitle: true
}

export default ReferalsFilter;