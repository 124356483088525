import { useEffect } from "react";
import { useState } from "react";
import { memo } from "react";
import Slider from "react-slick";
import { Spinner } from "reactstrap";



const GallerySlider = (props) => {
  const {
    items,
    Loader,
    isEditable,
    isAdmin,
    deleteGalleryImage,
    institutionId,
    getGalleryImages,
    totalPages,
    currentPage
  } = props;
  const [lastItemIndexInSlice, setlastItemIndexInSlice] = useState();

  const settings = {
    afterChange: function (index) {
      // console.log(
      //   `Index of Last Element : ${index + 4}`,
      //   "Length: ", items.length
      // );
      setlastItemIndexInSlice(index + 3);
      if ((currentPage < totalPages) && ((index + 4) % 10 > 0)) {
        getGalleryImages(currentPage + 1);
      }
    },
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  // useEffect
  return Loader ? (
    <div className="d-flex justify-content-center mt-3">
      <div className="loading-spiner">
        <Spinner style={{ width: "2rem", height: "2rem" }} />
      </div>
    </div>
  ) : (
    items && items.length > 0 ? (
      <div className="gallery-slider">
        <Slider {...settings}>
          {items.map((image, index) => (
            <div className="gallery-images" key={index}>
              {(isEditable || isAdmin) && (
                <span
                  className="gallery-delete-icon"
                  onClick={() => deleteGalleryImage(image)}
                >
                  &times;
                </span>
              )}
              <img
                className="rounded single-img"
                src={`${process.env.REACT_APP_API_BASE_URL}institution-service/institutions/${institutionId}/medias/${image.id}/download`}
                alt="#"
              />
            </div>
          ))}
        </Slider>
      </div>
    ) : (null)
  );
};

GallerySlider.defaultProps = {
  items: [],
  loading: false,
  isEditable: false,
  isAdmin: false,
  deleteGalleryImage: () => { },
  institutionId: "",
};

export default GallerySlider;
