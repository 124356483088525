import { faPlus, faUniversity } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AlertDialog from "common/AlertDialog";
import { first } from "lodash";
import { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import {
  profileAddEvent,
  setGlobalprofile,
  set_Recent_Education_User,
  updateProfileCompletness,
} from "redux-helpers/ActionCreator";
import { UserStatusConstants } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import { getUser } from "utils/UserHelper";
import {
  dateDifferenceInYears,
  getEmptySectionShowHide,
  getFullAddress,
  makeFullNameWithAddressWhenAddressIsNested,
  renderDateRange,
} from "utils/Utils";
import { RenderEducationModal } from "./components/RenderEducationModal";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import educations_icon from "assets/svgIcon/educations-icon.svg";
import small_marker from "assets/svgIcon/marker-small.svg";
import plusIcon from "assets/images/plus-icon-lg.png";
import edit_blue_icon from "assets/images/edit-blue-icon.png";
import del_red_icon from "assets/images/del-red-icon.png";
import NoData from "../NoData";


const initialValue = {
  institutionDetail: {
    institutionName: "",
    guid: "",
    onSelectNewMedicalCollege: 0,
  },
  addressLine2: "",
  city: [],
  country: [],
  location: [],
  postalCode: "",
  state: [],
  addressType: "BILLING_ADDRESS",
  degree: "",
  fieldOfStudy: [],
  startYear: "",
  endYear: "",
  additionalInfo: "",
  websiteAddress: "",
  mainItemName: "",
  category: "",
  isStudying: false,
};

const RenderEducation = (props) => {
  const {
    isAdmin,
    educations,
    handleEditItem,
    handleRemoveItem,
    isLoggedinUser,
  } = props;
  const currentUser = useSelector((state) => state.auth.currentUser);
  if (educations.length > 0) {
    const sortedEducations = educations.sort((a, b) => {
      return b.startYear - a.startYear;
    });
    return sortedEducations.map((item, index) => {
      const { startYear, passingYear, isStudying } = item;
      const timeDuration = renderDateRange(startYear, passingYear, isStudying);
      const profileIdLink = item?.institutionDetail?.profileId
        ? item?.institutionDetail?.profileId
        : item?.institutionDetail?.guid;
      const institutionName = getFullAddress({
        name: item?.institutionDetail?.name ?? "",
        abbreviation: item?.institutionDetail?.abbreviation ?? "",
        location: item?.institutionDetail?.address?.location ?? "",
        city: item?.institutionDetail?.address?.city ?? "",
        state: item?.institutionDetail?.address?.state ?? "",
      });
      const avatarId = item?.institutionDetail?.avatarId ?? null;
      const instGuid = item?.institutionDetail?.guid ?? null;

      return (
        <li
          className="list-group-item"
          key={`education_${index}`}
        >
          <div className="row g-0">
            <div className="col-auto">
              {avatarId ?
                <img
                  src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${instGuid}/${avatarId}`}
                  style={{ height: "40px" }}
                  alt="" />
                :
                <img src={educations_icon} alt="" />
              }
            </div>
            <div className="col">
              <h3 className="card-title"><Link
                to={`/institution/${profileIdLink}`}
                className="mb-2 color-accent"
              >{institutionName}</Link></h3>

              <p className="card-text"><small>{item.courseTitle} - {item.specialization}</small></p>
              <p className="card-text"><small className="text-muted">{item.startYear} -{" "}{item.isStudying ? <span>{"Present | "}{dateDifferenceInYears(item?.startYear)}</span> : item.passingYear}</small></p>
              <p className="card-text"><small>{timeDuration}</small></p>
            </div>
            {/** 
            <div className="education flex-grow-1">
              <div className="d-flex">
                <div className="img-32px">
                  <FontAwesomeIcon
                    icon={faUniversity}
                    className="fa-fw"
                    size="2x"
                  />
                </div>
                <div className="pl-2">
                  <Link
                    to={`/institution/${profileIdLink}`}
                    className="mb-2 color-accent"
                  >
                    <h6 className="section-item-title item-blue-title m-0">
                      {institutionName}
                    </h6>
                  </Link>
                  <div className="section-item-des mt-2">
                    {item.courseTitle} - {item.specialization}
                  </div>
                  <div className="section-item-shortdes mt-2 text-capitalize">
                    {item.startYear} -{" "}
                    {item.isStudying ? "Present" : item.passingYear}
                  </div>
                  <div className="section-item-shortdes mt-2">
                    {timeDuration}
                  </div>
                </div>
              </div>
            </div>
            */}
            <div className="col-auto d-flex align-items-start">
              {(isAdmin ||
                (isLoggedinUser &&
                  currentUser.status === UserStatusConstants.APPROVED)) && (
                  <>
                    <div className="delete-action">
                      <Button
                        color="link"
                        className="visible-hover py-0 nex-edu-loop-edit-btn"
                        onClick={() => handleEditItem(item)}
                      >
                        <img src={edit_blue_icon} alt="" />
                      </Button>

                      <Button
                        color="link"
                        className="visible-hover py-0 delete nex-edu-loop-del-btn"
                        onClick={() => handleRemoveItem(item)}
                      >
                        {/* <img src={grayTrashIcon} alt="" /> */}
                        <img src={del_red_icon} alt="" />
                      </Button>
                    </div>
                  </>
                )}
            </div>
          </div>
        </li>
      );
    });
  }
  return null;
};

const Education = (props) => {
  const [pannel_on, setPannel_on] = useState(true);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { guid } = props.practitioner;
  const eventName = useSelector((state) => state.profileComplete.eventName);
  const dispatch = useDispatch();
  const practitionerGuid = props.practitioner.guid;
  const { alert, isLoggedinUser, isAdmin, setShowEducationsOnTabScroll } = props;
  const [educations, setEducation] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [isOpen, setModal] = useState(false);
  const toggle = () => setModal(!isOpen);
  const [isOpenAlert, setAlertModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const toggleAlert = () => {
    setLoading(false);
    setAlertModal(!isOpenAlert);
  };

  useEffect(() => {
    if (practitionerGuid) {
      getData();
    }
  }, [practitionerGuid]);

  useEffect(() => {
    dispatch(updateProfileCompletness(5, !!educations.length));
    if (educations.length > 0 || isLoggedinUser || isAdmin) {
      setShowEducationsOnTabScroll(true);
    }else{
      setShowEducationsOnTabScroll(false);
    }
  }, [educations]);

  useEffect(() => {
    if (eventName === "ADD_EDUCATION") {
      handleEditItem(initialValue);
      dispatch(profileAddEvent(null));
    }
  }, [eventName]);

  const formatEditItemData = (item) => {
    return {
      institutionDetail: {
        institutionName: item?.institutionDetail ? makeFullNameWithAddressWhenAddressIsNested(item?.institutionDetail) : "",
        guid: item?.institutionDetail.guid ?? "",
        onSelectNewMedicalCollege: 0,
      },
      addressLine2: "",
      city: [],
      country: [],
      location: [],
      postalCode: "",
      state: [],
      addressType: "BILLING_ADDRESS",
      degree: item?.courseTitle,
      fieldOfStudy: item?.specialization,
      startYear: item?.startYear,
      endYear: item?.passingYear,
      additionalInfo: item?.additionalInfo,
      websiteAddress: item?.institutionDetail.websiteURL,
      mainItemName: "",
      category: "",
      id: item?.guid ?? "",
      isStudying: item?.isStudying ?? false,
    };
  };

  const handleEditItem = (selectedItem) => {
    setSelectedItem(formatEditItemData(selectedItem));
    toggle();
  };

  const handleRemoveItem = (selectedItem) => {
    setSelectedItem(formatEditItemData(selectedItem));
    toggleAlert();
  };

  const updateGlobalprofileStorage = (guid) => {
    PractitionerService.gePractitionersDetails(guid).then((response) => {
      if (response.status === 200 || response.status === 201) {
        dispatch(setGlobalprofile(response.data));
      }
    });
  };

  const handleClickOnYes = async () => {
    const userGUID = globalProfileInfo.guid;
    setLoading(true);
    try {
      const request = PractitionerService.deleteEducationsDetails(
        userGUID,
        selectedItem.id
      );
      request.then(
        async (response) => {
          const { data, status } = response;
          if (status === 204) {
            await getData();
            setLoading(false);
            updateGlobalprofileStorage(globalProfileInfo?.guid);
            showSuccessMessage("Education deleted successfully");
            toggleAlert();
          } else {
            showErrorMessage(data.status.message);
            setLoading(false);
            toggleAlert();
          }
        },
        (e) => {
          setLoading(false);
          let errmsg = e.message;
          if (e.response) {
            errmsg =
              e.response.data && !!e.response.data.message
                ? e.response.data.message
                : errmsg;
          }
          showErrorMessage(errmsg);
        }
      );
    } catch (e) {
      showErrorMessage(e.message);
      setLoading(false);
      toggleAlert();
    }
  };

  const getData = async () => {
    const request = PractitionerService.getEducation(practitionerGuid);
    request.then((response) => {
      let { data } = response;
      if (data && data.length > 0) {
        setEducation(data);
        const incoming_data = first(data) ? [first(data)] : [];
        dispatch(set_Recent_Education_User(incoming_data));
      } else {
        setEducation([]);
        dispatch(set_Recent_Education_User([]));
      }
    });
  };

  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );

  if (
    getEmptySectionShowHide(
      educations ?? [],
      isAdmin,
      currentUser,
      globalProfileInfo,
      guid
    )
  )
    return null;

  return (
    <>
      <Card className={`card nexogic-card-color-header ${pannel_on ? 'expanded' : ''}`} id="educationSection">
        <div className="card-header"><h2>Education</h2>



          {(isAdmin ||
            (isLoggedinUser &&
              currentUser.status === UserStatusConstants.APPROVED)) && (
              <Button
                id="nex-edu-add-button"
                color="link"
                className="add-button"
                onClick={() => handleEditItem(initialValue)}
              >
                <img src={plusIcon} alt="" />
              </Button>
            )}

          <button className="collapse-btn" type="button" onClick={() => setPannel_on(!pannel_on)}>
            <img src={pannel_arrow} alt="" />
          </button>
        </div>
        <div className={`collapse-div`}>
          <CardBody className={`${educations && educations.length > 0 ? "" : "align-items-center"}`}>
            {educations && educations.length
              ? ""
              : (isLoggedinUser || isAdmin) &&
              currentUser.status === UserStatusConstants.APPROVED && (
                <Button
                  id="nex-edu-add-button2"
                  className="readmore"
                  color="link"
                  onClick={() => handleEditItem(initialValue)}
                >
                  {"Add education"}
                </Button>
              )}

            <ul className="list-group list-group-flush" id="nex-edu-lists">
              <RenderEducation
                educations={educations}
                handleEditItem={handleEditItem}
                handleRemoveItem={handleRemoveItem}
                isLoggedinUser={isLoggedinUser}
                isAdmin={isAdmin}
              />
            </ul>
            {educations.length < 1 && 
            <NoData>No Data</NoData>
            }
            <RenderEducationModal
              selectedItem={selectedItem}
              toggle={toggle}
              isOpen={isOpen}
              getData={getData}
              isAdmin={isAdmin}
            />
            <AlertDialog
              toggle={toggleAlert}
              isOpen={isOpenAlert}
              isLoading={isLoading}
              handleClickOnYes={handleClickOnYes}
              yesLabel={"Yes"}
              noLabel={"No"}
            />
          </CardBody>
        </div>
      </Card>
    </>
  );
};

export default withAlert()(Education);
