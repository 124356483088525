import FormikMobileField from "components/forms/formikFields/FormikMobileField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import { getOAuthUser } from "utils/UserHelper";
import { Field } from "formik";
import React from "react";
import { Button, Col, Input, Label, Row } from "reactstrap";

import del_icon from "assets/images/del-red-icon.png";

const phonesType = [
  {
    label: "Home",
    value: "HOME",
  },
  {
    label: "Mobile",
    value: "MOBILE",
  },
  {
    label: "Work",
    value: "WORK",
  },
  {
    label: "Home Fax",
    value: "HOME_FAX",
  },
  {
    label: "Work Fax",
    value: "WORK_FAX",
  },
  {
    label: "Other",
    value: "OTHER",
  },
];

const removeSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    className="bi bi-trash"
    viewBox="0 0 16 16"
  >
    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
    <path
      fillrule="evenodd"
      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
    />
  </svg>
);

export const RenderPhoneFormFields = (props) => {
  const { index, values, remove, setFieldValue } = props;
  const oAuthUser = getOAuthUser();
  const regPhone = oAuthUser.mobileNo;

  const isDisabledCheck = (inputPhone, userRegPhone) => {
    if (inputPhone) {
      return inputPhone.replace(/ /g, "") === userRegPhone;
    }
    return false;
  };

  return (
    <Row className="">
      <Col md={3} >
        <label className="">Type</label>
        <Field
          id={`phones[${index}].type`}
          name={`phones[${index}].type`}
          type="text"
          component={FormikSelectField}
          value={values.phones[index].type}
          inputprops={{
            name: `phones[${index}].type`,
            options: phonesType,
            keys: {
              id: "value",
              label: "label",
            },
          }}
          disabled={values.phones[index].primary}
        />
      </Col>
      <Col md={6}>
       <label className="">Phone Number</label>
        <Field
          id={`phones[${index}].value`}
          name={`phones[${index}].value`}
          type="text"
          component={FormikMobileField}
          placeholder="Enter number"
          value={values.phones[index].value}
          disabled={values.phones[index].primary}
          className={`${values.phones[index].primary ? "disabled-cont" : ""}`}
        />
      </Col>
      <Col md={3}  >
        <label className="d-xs-none">&nbsp;</label>
        <div className="form-group form-check">
        <Label check className="mb-0">
          <Input
            type="radio"
            id={`primaryItemPhone`}
            name={`primaryItemPhone`}
            onChange={() => {
              const fullItem = values.phones.map((node, kIndex) => {
                return {
                  ...node,
                  primary: kIndex === index,
                };
              });
              setFieldValue(`phones`, fullItem);
              setFieldValue(`primaryItemPhone`, index.toString(), true);
            }}
            checked={values.primaryItemPhone.toString() === index.toString()}
          /> <span>Primary</span>
        </Label>
        {!values.phones[index].primary && (
        
          <Button
            color="danger"
            className=""
            onClick={() => {
              const fullItem = values.phones.filter(
                (_, indexItem) => indexItem !== index
              );
              const primaryIndex = fullItem.findIndex(
                (node) => node.primary === true
              );
              remove(index);
              setFieldValue(`phones`, fullItem);
              setFieldValue(`primaryItemPhone`, primaryIndex.toString());
            }}
            disabled={isDisabledCheck(values.phones[index].value, regPhone)}
          >
            {/*removeSVG()*/}
            <img src={del_icon} alt="" />
          </Button>
        
      )}
        </div>

      </Col>
      {/** 
      {!values.phones[index].primary && (
        <Col md={1} xs={6} className="contact-form-radio">
          <Button
            color="danger"
            className="deletebtn"
            onClick={() => {
              const fullItem = values.phones.filter(
                (_, indexItem) => indexItem !== index
              );
              const primaryIndex = fullItem.findIndex(
                (node) => node.primary === true
              );
              remove(index);
              setFieldValue(`phones`, fullItem);
              setFieldValue(`primaryItemPhone`, primaryIndex.toString());
            }}
            disabled={isDisabledCheck(values.phones[index].value, regPhone)}
          >
            {removeSVG()}
          </Button>
        </Col>
      )}
      */}
    </Row>
  );
};
