import {
    APPLY_FILTER,
    HANDLE_REMOVE_FILTER
} from "./Actions";

export const applyFilter = (data) => {
    return (dispatch) => {
        dispatch({
            type: APPLY_FILTER,
            payload: data,
        });
    };
};

export const handleRemoveFilter = (data) => {
    return (dispatch) => {
        dispatch({
            type: HANDLE_REMOVE_FILTER,
            payload: data,
        });
    };
};