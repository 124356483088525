import small_marker from "assets/svgIcon/marker-small.svg";
import { withAlert } from "react-alert";
import React from "react";

const AddressInstitution = (props) => {
  const { location, city, state, country } = props;
  return (
    <p className="card-text address-text">
      <span className="icon-w">
      <img
        src={small_marker}
        className="icon marker-icon mr-1"
        alt="#"
      /> 
      </span>
      <small className="text-muted">
      

          {" "}{location && <span>{location}, </span>}
          {city && <span>{city}, </span>}
          {state && <span>{state}, </span>}
          {country && <span>{country}</span>}

      </small>
    </p>
  );
};

AddressInstitution.defaultProps = {
  location: "",
  city: "",
  state: "",
  country: "",
};

export default withAlert()(AddressInstitution);
