import React, { useState, useEffect } from "react";
import { withAlert } from "react-alert";
import { useHistory } from 'react-router-dom';
import NoConnection from "../NoConnection"
import PatientInfo from "./PatientInfo.";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import { PatientService } from "utils/PatientService";
import CommonLoader from "common/CommonLoader";
import AlertDialog from "common/AlertDialog";
import { FAILTOSAVERECOED } from "utils/Constants";
import LoadMoreButton from "common/LoadMoreButton/LoadMoreButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faIdCard, faListAlt, faTable } from "@fortawesome/free-solid-svg-icons";
import MyPatientsTabularView from "components/tabularView/MyPatientsTabularView";

export const MyPatients = (props) => {
    const { alert } = props;
    const [isLoading, setIsLoading] = useState(true);
    const [list_of_data, setList_of_data] = useState(null);
    const [isOpenAlertModal, setIsOpenAlertModal] = useState(false);
    const [patientIdToDelete, setPatientIdToDelete] = useState(null);
    const [hasMore, setHasMore] = useState(false);
    const [viewMode, setViewMode] = useState("card");
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const history = useHistory();

    const getData = async (pageNo, feedlist) => {
        const request = PatientService.getMyPatients(pageNo);
        request
            .then(async (response) => {
                let { data } = response;
                if (data?.currentPage < (data?.totalPages - 1))
                    setHasMore(true);
                else
                    setHasMore(false);
                setIsLoadingMore(false);
                if (pageNo === 0) {
                    const allData = { ...data };
                    setList_of_data(allData);
                    setIsLoading(false);
                } else {
                    const resolved = await Promise.all(
                        data.contents.map(async (item) => {
                            const each = { ...item };
                            return each;
                        })
                    );
                    feedlist = feedlist.concat(resolved);
                    setList_of_data({ ...data, contents: feedlist });
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                console.log("Error while getting groups list: ", error);
                setIsLoadingMore(false);
            });
    };

    const toggleAlert = () => {
        setIsOpenAlertModal(!isOpenAlertModal);
    };

    const removePatientonConfirm = () => {
        window.scrollTo(0, 0);
        setIsLoading(true);
        PatientService.removeMyPatient(patientIdToDelete)
            .then((response) => {
                const { status } = response;
                if (status === 204) {
                    showSuccessMessage("Patient has been removed Successfully...");
                    getData(0, []);
                }
                else {
                    showErrorMessage(FAILTOSAVERECOED);
                }
            })
        toggleAlert();
    }

    useEffect(() => {
        getData(0, []);
    }, []);

    const fetchData = () => {
        setIsLoadingMore(true);
        getData(list_of_data.currentPage + 1, list_of_data.contents);
    };

    if (isLoading) {
        return (
            <div className="loading-spiner text-center  mt-2">
                <CommonLoader style={{ width: "2rem", height: "2rem" }} />
            </div>
        );
    }

    const pageRedirect = () => {
        history.push("/referrals/add-patient");
    }

    const renderProfiles = () => {
        if (viewMode === 'card') {
            return (
                <>
                    {(list_of_data && list_of_data.contents.length > 0)
                        ?
                        <>
                            <div>
                                <div className="nexogic-my-patient-lists">

                                    {list_of_data &&
                                        list_of_data.contents.map((patientDet, index) => (
                                            <PatientInfo key={index} patient={patientDet} toggleAlert={toggleAlert} setPatientIdToDelete={setPatientIdToDelete} />
                                        ))}

                                </div>
                            </div>
                            <AlertDialog
                                // ref={alertBoxRef}
                                bodyText={"Are you sure, You want to remove the Patient ?"}
                                toggle={toggleAlert}
                                isOpen={isOpenAlertModal}
                                handleClickOnYes={removePatientonConfirm}
                                yesLabel={"Yes"}
                                noLabel={"No"}
                            />
                        </>
                        :
                        <div className="loading-spiner text-center mt-5">
                            <NoConnection content={"You don't any patient"} />
                        </div>
                    }
                </>
            );
        } else { // For table view
            return (
                <MyPatientsTabularView theader={['Name', 'Gender', 'Age', 'Phone Number', 'Email Address']} tdata={list_of_data.contents} viewmode={viewMode} />
            );
        }
    };

    return (
        <>
            <div className="nexogic-top-section-title nexogic-my-patient-heading">
                <h2 className="mb-0 nexogic-page-title">My Patient Lists </h2>
                <span className="btn nexogic_primary_button" onClick={pageRedirect}>Add Patient</span>
            </div>
            <div className="view-toggle nexogic-view-toggle">
                <div className="btns">
                    <FontAwesomeIcon title="Card View" color={viewMode === "card" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faAddressCard} onClick={() => setViewMode("card")} /> | <FontAwesomeIcon title="Table View" color={viewMode === "table" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faListAlt} onClick={() => setViewMode("table")} />
                </div>
            </div>
            {renderProfiles()}

            {hasMore &&
                <div className="text-center mt-3">
                    {isLoadingMore
                        ?
                        <CommonLoader style={{ width: "2rem", height: "2rem" }} />
                        :
                        <LoadMoreButton loadMore={fetchData} />
                    }
                </div>
            }
        </>
    )
}

export default withAlert()(MyPatients)