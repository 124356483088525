import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const AllergiesModalForm = (props) => {
    const multiSelectRef = useRef(null);

    const allergies_options = [
        { "label": "Pollen", "value": "Pollen" },
        { "label": "Dust mites", "value": "Dust mites" },
        { "label": "Pet dander", "value": "Pet dander" },
        { "label": "Mold", "value": "Mold" },
        { "label": "Food (specify in notes)", "value": "Food" },
        { "label": "Insect stings", "value": "Insect stings" },
        { "label": "Medications (specify in notes)", "value": "Medications" },
        { "label": "Latex", "value": "Latex" },
        { "label": "Chemicals (specify in notes)", "value": "Chemicals" },
        { "label": "Cosmetics", "value": "Cosmetics" },
        { "label": "Metals (e.g., nickel)", "value": "Metals" },
        { "label": "Plants (e.g., poison ivy)", "value": "Plants" },
        { "label": "Smoke (e.g., cigarette smoke)", "value": "Smoke" },
        { "label": "Sunlight (e.g., sun allergy)", "value": "Sunlight" },
        { "label": "Cold (e.g., cold urticaria)", "value": "Cold" },
        { "label": "Heat (e.g., heat rash)", "value": "Heat" },
        { "label": "Exercise-induced", "value": "Exercise-induced" },
        { "label": "Environmental factors (specify in notes)", "value": "Environmental factors" },
        { "label": "Perfumes", "value": "Perfumes" },
        { "label": "Dyes", "value": "Dyes" },
        { "label": "Fabrics (e.g., wool)", "value": "Fabrics" },
        { "label": "Pesticides", "value": "Pesticides" },
        { "label": "Plastics", "value": "Plastics" },
        { "label": "Shellfish", "value": "Shellfish" },
        { "label": "Nuts", "value": "Nuts" },
        { "label": "Fungi (e.g., athlete's foot)", "value": "Fungi" },
        { "label": "Bee products (e.g., propolis)", "value": "Bee products" },
        { "label": "Anesthetics", "value": "Anesthetics" },
        { "label": "Vaccines", "value": "Vaccines" },
        { "label": "Sunscreen", "value": "Sunscreen" },
        { "label": "Air pollutants", "value": "Air pollutants" }
    ];



    return (
        <Modal isOpen={props.modal} toggle={props.toggle} {...props} className="so_mh_modal_popup">
            <ModalHeader toggle={props.toggle}>Allergies</ModalHeader>
            <ModalBody>
                <div className="nex-modal-select-drops-wrap nex-symptoms-sdrops-w" ref={multiSelectRef} >

                    <MultiSelect

                        options={allergies_options}
                        value={props.allergies_selected}
                        onChange={props.setAllergies_selected}
                        labelledBy="Select"
                    />
                </div>
                <div className="nex-modal-actions mt-3 mb-2 d-flex justify-content-end">
                    <a href="#" className="nexogic_primary_button" onClick={(e) => {
                        props.toggle();
                    }}>Close</a>
                </div>
            </ModalBody>

        </Modal>
    )
}
export default AllergiesModalForm;