import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody } from "reactstrap";
import deg_icon from "../../../../assets/images/svgs/deg-icon.svg"
import year_icon from "../../../../assets/svgIcon/year-icon.svg"
import address_icon from "../../../../assets/svgIcon/address-icon.svg"
import treatment_icon from "../../../../assets/svgIcon/treatment-icon.svg"
import { createImageFromInitials, getFullAddress, getRandomColor } from "utils/Utils";
import { encrypt } from "utils/EncryptDecrypt";
import ConnectButton from "./ConnectButton";
import { useSelector } from "react-redux";
import { useState } from "react";

const ProfileCardItem = (props) => {
  const {
    name,
    profileGuid,
    qualification,
    primarySpeciality,
    superSpeciality,
    avatarId,
    practicingSince,
    location,
    city,
    state,
    profileId,
    profileStatus,
    viewmode
  } = props;
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [isExpand, setIsExpand] = useState(false);

  return (
    <Card className={`result-card mb-3 nexogic-card-no-header nex-profile-card-item ${isExpand ? 'expand-on' : ''}`}>
      <span className="extended-btn" onClick={(e) => { setIsExpand(!isExpand) }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="14"
          fill="none"
          viewBox="0 0 8 14"
        >
          <path
            stroke="#2D4552"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M1 13l6-6-6-6"
          ></path>
        </svg>

      </span>
      <CardBody>
        <div className="graphic">
          {avatarId ?
            <img
              src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${profileGuid}/${avatarId}`}
              className="card-img"
              alt="#"
              style={{ height: "120px", width: "120px" }}
            />
            :
            <img
              src={createImageFromInitials(
                50,
                `${name.replace('Dr. ', '')}`,
                getRandomColor(encrypt(profileGuid).toString())
              )}
              className="card-img"
              alt="#"
              style={{ height: "50px", width: "50px" }}
            />
          }
        </div>
        <div className="info">
          {name && (
            <h3 className="card-title">
              <Link to={`/profile/${profileId}`} onClick={() => window.scrollTo(0, 0)}>
                {name}
              </Link>
              {profileStatus === 'APPROVED' &&
                <span className="customized-badge"></span>
              }
            </h3>
          )}

          {typeof qualification != "undefined" &&
            <div className="card-text"><i className="icon"><img src={deg_icon} alt="deg_icon" /></i> {qualification}</div>
          }
          {typeof practicingSince != "undefined" &&
            <div className="card-text experience-text"><i className="icon"><img src={year_icon} alt="year_icon" /></i> {!isNaN(parseInt(practicingSince)) &&
              parseInt(new Date().getFullYear()) - parseInt(practicingSince) > 0 ? (
              <p className="m-0 lh-base lh-lg">
                {`${parseInt(new Date().getFullYear()) -
                  parseInt(practicingSince)
                  }+ years experience`}
              </p>
            ) : '0 year experience'} </div>
          }

          {typeof primarySpeciality != "undefined" &&
            <div className="card-text speciality-text"><i className="icon"><img src={treatment_icon} alt="treatment_icon" /></i>{primarySpeciality} {superSpeciality && superSpeciality != "undefined" && <span>{", "} {superSpeciality}</span>} </div>
          }

          <div className="card-text address-text"><i className="icon"><img src={address_icon} alt="address_icon" /></i>{getFullAddress({
            name: " ",
            location: location ?? "",
            city: city ?? "",
            state: state ?? "",
          }).replace("-", "")}</div>

        </div>
        <div className="actions">
          <Link to={`/profile/${profileId}`}>
            <Button color="primary" className="nexogic_primary_button_outline " onClick={() => window.scrollTo(0, 0)}>
              View Profile
            </Button>
          </Link>
          {(currentUser.guid !== profileGuid) &&
            < ConnectButton practitionerGuid={profileGuid} viewmode={viewmode} />
          }
        </div>
      </CardBody>
    </Card>
  );
};

export default memo(ProfileCardItem);
