import {faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "assets/styles/typeahead.css";
import { useFormik } from "formik";
import { findIndex } from "lodash";
import { memo, useEffect, useRef, useState,useMemo } from "react";
import { withAlert } from "react-alert";
import { Typeahead } from "react-bootstrap-typeahead";
import { Button, Col, FormGroup, Row, Spinner } from "reactstrap";
import * as Yup from "yup";
import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import { InstitutionServices } from "utils/InstitutionServices";
import { MetadataService } from "utils/MetadataService";
import plusIcon from "assets/images/add-icon.png";
import addIcon from "assets/images/svgs/add_icon.svg";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const validationSchema = Yup.object().shape({
  Specialities: Yup.string().required("This field is required"),
});

const dummySuggestedSkill = [
  "Child care",
  "Surgery",
  "Dermatology",
  "Pharmacy",
  "Infectiouse Desease",
  "Neurology",
  "Acuouncturis",
  "Allergy",
  "Nurse",
  "Chemycal",
  "Facial Cosmetic Surgery",
];

const SpecialitiesForm = (props) => {
  const { toggle, alert, institution, getInstitutionProfile } = props;
  const [specialitiesData, setSpecialitiesData] = useState([]);
  const [specialities, setSpecialities] = useState([]);  
  const [sugSpecialities, setSugSpecialities] = useState([]);
  const [loadMore,setLoadMore] = useState(10);
  const [isLoading, setLoading] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [errorMsg, setErrorMessage] = useState("");
  const specialitiesRef = useRef(null);

  const formik = useFormik({
    initialValues: { specialities: specialities },
    validationSchema,
  });

  useEffect(() => {
    setSpecialities(props.specialities);
    setSpecialitiesData(dummySuggestedSkill);
  }, []);

  const updateItems = async () => {
    setLoading(true);
    let payload = [
      {
        op: "add",
        path: "/specialities",
        value: specialities,
      },
    ];
    alert.removeAll();
    InstitutionServices.patchInstitutionDetails(payload, institution?.guid)
      .then((response) => {
        setLoading(false);
        const { status } = response;
        if (status === 200) {
          toggle();
          getInstitutionProfile(institution?.guid);
          showSuccessMessage("Institution details updated successfully");
        } else {
          showErrorMessage("Institution details failed to update");
        }
      })
      .catch((err) => {
        setLoading(false);
        showErrorMessage(err.message);
      });
  };

  const handleSuggestedSkills = (speciality) => {
    setSpecialities([...specialities, speciality]);
  };

  const handleLoadMore = () => {
    setLoadMore(loadMore + 15);
    if (loadMore >= specialitiesData.length) {
      setIsCompleted(true)
    } else {
      setIsCompleted(false)
    }
  }
  const specialitiesSuggestions = useMemo(() => {
    if (sugSpecialities && sugSpecialities.length > 0) {
      const uniqueSpecialities = sugSpecialities.filter(
        (s) => findIndex(specialities, (o) => o === s) < 0
      );
      return uniqueSpecialities?.slice(0, loadMore)?.map((data,index) => {
        return (
          <div key={index}
            id={`nex-sk-spaf-suggest-${index}`}
            className="nexogic-suggested-skill d-flex align-items-center"
            onClick={() => handleSuggestedSkills(data)}
          >
            <div className="card-text">{data}</div>
            <div className="add-icon ml-1">
             {/*<FontAwesomeIcon icon={faPlus} />*/}
             <img src={addIcon} alt="" />
            </div>
          </div>
        );
      });
    }
    return null;
  }, [specialities, sugSpecialities,loadMore]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        MetadataService.getPrimarySpecialities().then((response) => {
          const { status, data } = response;
          if (status === 200 || status === 201) {
            setLoading(false);
            setSpecialitiesData(data);
            setSugSpecialities(data);
          }
        });
      } catch (e) {
        setErrorMessage(e.message);
        setLoading(false);
      }
    })();
  }, []);
  return (
    <>
      <form onSubmit={formik.handleSubmit} id="nex-isf-specialities-form">
        {errorMsg !== "" && <RenderErrorMessage errorMsg={errorMsg} />}
        <Row>
          <Col xs={12}>
            <FormGroup className="searchbox-input-chips mb-1" id="nex-isf-specialities">
              <Typeahead
                ref={specialitiesRef}
                clearButton
                newSelectionPrefix="Add Specialities: "
                selected={specialities}
                id="specialities"
                multiple
                options={specialitiesData}
                labelKey="speciality"
                placeholder="Add your specialities ex. Cardiology"
                onBlur={() => {
                  return specialitiesRef !== null
                    ? specialitiesRef.current.hideMenu()
                    : null;
                }}
                onChange={(selected) => {
                  const removedItems = specialities.filter(
                    (s) => !selected.includes(s)
                  );
                  let selectedAry = selected.map(function (el) {
                    if (el.label) {
                      return el.label;
                    } else {
                      return el;
                    }
                  });
                  setSpecialities(selectedAry);
                }}
              />
            </FormGroup>
            <p className="mb-2 card-text">
              <small>Suggested Specialities:</small>
            </p>
            <div className="nexogic-suggested-skills d-flex flex-wrap mt-3" id="nex-isf-specialities-suggestions">
            {specialitiesSuggestions}
            </div>
            <div className="nexogic-suggested-skill d-flex flex-wrap my-2 justify-content-center">
            {!isCompleted && (
              <Button
                id="sf_btn_load_more"
                className="nexogic_primary_button_outline small mt-2"
                color="link"
                onClick={handleLoadMore}
              >
                <img src={plusIcon} alt=""/> Load more
              </Button>
            )}
            </div>            
          </Col>
        </Row>
        {/*<hr className="profile-info-modal-hr mt-0" />*/}
        <div className="justify-content-end  modal-footer ">
          {isLoading ? (
            <div className="loading-spiner">
              <Spinner style={{ width: "2rem", height: "2rem" }} />
            </div>
          ) : (
            <>
              <div className="action-group">
              <Button
                  id="sf_btn_submit"
                  className="nexogic_primary_button"
                  color="primary"
                  type="submit"
                  onClick={() => updateItems(specialities)}
                  // disabled={specialities.length === 0}
                >
                  Save
                </Button>
                <Button
                  id="sf_btn_cancel"
                  className="nexogic_primary_button_outline"
                  color="primary"
                  outline
                  type="button"
                  onClick={toggle}
                >
                  Cancel
                </Button>
                
              </div>
            </>
          )}
        </div>
      </form>
    </>
  );
};

export default withAlert()(memo(SpecialitiesForm));
