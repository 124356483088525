import { memo } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { Card, CardBody, CardHeader, Spinner } from "reactstrap";
import { getFullAddressWithLocationCityAndState } from "utils/Utils";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 2,
  initialSlide: 0,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      },
    },
  ],
};

const OtherLocationSlider = (props) => {
  const {
    items,
    loading
  } = props;

  return loading ? (
    <div className="d-flex justify-content-center mt-3">
      <div className="loading-spiner">
        <Spinner style={{ width: "2rem", height: "2rem" }} />
      </div>
    </div>
  ) : (
    items.length > 0 && (
      <div className="nexogic-location-slider">
        <Slider style={{ width: "100%" }} {...settings}>
          {items.filter(location => location.profileId !== props.profileId).map((location, index) => (
            <div className="location-slider-item" key={index}>
              <div className="location-slider-content">
                <div className="card-title"><a href={`./${location.profileId}`} target="_blank"> {location?.name}</a></div>
                <div className="card-text">{location?.type.replaceAll("_", " ")}</div>
                <div className="card-text">{getFullAddressWithLocationCityAndState(
                  { location: location?.location ?? "", city: location?.city ?? "", state: location?.state ?? "" }
                )}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    )
  );
};

OtherLocationSlider.defaultProps = {
  items: [],
  loading: false,
};

export default memo(OtherLocationSlider);
