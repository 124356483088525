import classnames from "classnames";
import { useState } from "react";
import { Button, Card, CardBody, Nav, NavItem, NavLink } from "reactstrap";
import GroupFollow from "./components/GroupFollow";
import GroupInvitation from "./components/GroupInvitation";
import GroupOwned from "./components/GroupOwned";

const AllGroups = () => {
  const [activeTab, setActiveTab] = useState("0");
  const [isCGOpen, setIsCGOpen] = useState(false);
  const toggleCGModal = () => setIsCGOpen(!isCGOpen);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      <Card className="border-0 bg-transparent">
        <CardBody className="p-0">
          <Nav tabs className="nexogic-tabs">
            <NavItem>
              <NavLink
                id="nex-gv-top-gio"
                className={classnames({ active: activeTab === "0" })}
                onClick={() => {
                  toggle("0");
                }}
              >
                {" "}
                Group I owned
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                id="nex-gv-top-gif"
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggle("1");
                }}
              >
                {" "}
                Group I follow{" "}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                id="nex-gv-top-ginvi"
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggle("2");
                }}
              >
                {" "}
                Group Invitation{" "}
              </NavLink>
            </NavItem>
          </Nav>
          {activeTab === "0" && (
            <GroupOwned
              activeTab={activeTab}
              toggleCGModal={toggleCGModal}
              isCGOpen={isCGOpen}
            />
          )}
          {activeTab === "1" && <GroupFollow activeTab={activeTab} />}
          {activeTab === "2" && <GroupInvitation activeTab={activeTab} />}
        </CardBody>
      </Card>
    </>
  );
};

export default AllGroups;
