import Admin from "pages/Admin";
import App from "pages/App";
import ClaimProfile from "pages/ClaimProfile";
import ClaimOtpVarification from "pages/ClaimProfile/components/ClaimOtpVerification";
import ClaimProfileSignup from "pages/ClaimProfile/components/ClaimProfileSignup";
import ClaimProfileNew from "pages/ClaimProfileNew";
import ClaimVerifyOTP from "pages/ClaimProfileNew/ClaimVerifyOTP";
import ClaimProfileEmailVerification from "pages/ClaimProfileNew/EmailVerification";
import ClaimProfileSocial from "pages/ClaimProfileSocial";
import ForgotPasswordNew from "pages/ForgotPasswordNew";
import Home from "pages/Home";
import Index from "pages/Index";
import Institutions from "pages/Institutions";
import LoginNew from "pages/LoginNew";
import LoginSocial from "pages/LoginSocial";
import Onboarding from "pages/Onboarding";
import OtpVarification from "pages/Register/components/OtpVarification";
import RegisterNewWithEmail from "pages/RegisterNew/WithEmail";
import RegisterPatient from "pages/RegisterPatient";
import ResetPassword from "pages/ResetPassword";
import UserEntry from "components/OTPFlow/UserEntry";
import SocialOAuth from "pages/SocialOAuth";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { authSetUser } from "redux-helpers/ActionCreator";
import { getToken, getUser, isAdmin, setOAuthTokens, setOAuthUser, setUser } from "utils/UserHelper";
import OnboardingProcess from "pages/OnboardingProcess";
import NewDoctorRegister from "pages/NewDoctorRegister";
import NewDoctorProfile from "pages/NewDoctorRegister/NewDoctorProfile";
import NewDoctorProfileV2 from "pages/NewDoctorRegister/NewDoctorProfileV2";
import NewDoctorRegisterV2 from "pages/NewDoctorRegister/NewDoctorRegisterV2";
import NewProfileClaim from "pages/NewDoctorRegister/NewProfileClaim";
import NewOtpVarify from "pages/NewDoctorRegister/NewOtpVarify";
import NewRegisterStep from "pages/NewDoctorRegister/NewRegisterStep";
import NewPasswordDoctor from "pages/NewDoctorRegister/NewPasswordDoctor";
import NewPasswordRecovery from "pages/NewDoctorRegister/NewPasswordDoctor/NewPasswordRecovery";
import NewCreateRegister from "pages/NewDoctorRegister/NewPasswordDoctor/NewCreateRegister";
import { useEffect } from "react";
import { useState } from "react";
import NewRegisterStepThree from "pages/NewDoctorRegister/NewRegisterStep/NewRegisterStepThree";
import NewPatientRegister from "pages/NewDoctorRegister/NewPatientRegister";
import PatientMobileValidation from "pages/NewDoctorRegister/PatientMobileValidation";
import PatientOtpVarify from "pages/NewDoctorRegister/NewOtpVarify/PatientOtpVarify";
import PatientSignup from "pages/NewDoctorRegister/NewPasswordDoctor/PatientSignup";
import SearchDoctorProfile from "pages/ClaimSocialProfile/Components/SearchDoctorProfile";
import ProfileClaimNew from "pages/ClaimSocialProfile/Components/ProfileClaimNew";
import ClaimSocialProfile from "pages/ClaimSocialProfile";
import OnboardingProvider from "pages/OnboardingProvider";
import LoginSocialProfile from "pages/LoginSocialProfile";
import PatientRegister from "pages/ClaimSocialProfile/Components/PatientRegister";
import OtpVerify from "pages/ClaimSocialProfile/Components/OtpVerify";
import PatientRegisterAccount from "pages/ClaimSocialProfile/Components/PatientRegister/PatientRegisterAccount";
import ProviderNewRegister from "pages/ClaimSocialProfile/Components/ProviderNewRegister";
import LoginScreen from "pages/NewDoctorRegister/LoginScreen";
import ForgotPassword from "pages/LoginSocialProfile/ForgotPassword";
import { IdentityService } from "utils/IdentityService";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { FAILED_TO_FETCH_DATA } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import DummyLoginScreen from "pages/DummyLoginScreen";
import SearchDoctorProfileClaim from "pages/ClaimSocialProfile/Components/SearchDoctorProfileClaim";

const AuthGuard = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const jobDetailsPageUrl = new URLSearchParams(rest.location.search).get('jobdetails') ?? null;

  useEffect(() => {
    if (!isAdmin() && getToken() && !currentUser?.guid) {
      const user = getUser();
      dispatch(authSetUser(user));
    }
  }, [dispatch, currentUser]);

  if (!getToken()) {
    return <Redirect to="/login" />;
  }

  if (isAdmin()) {
    return <Redirect to={`/admin`} />;
  }

  if (jobDetailsPageUrl !== null) {
    return <Redirect to={`/jobs/${jobDetailsPageUrl}`} />;
  }

  return <Redirect to={`/home`} />;
};

// export default AuthGuard;

const AppContainer = () => {
  let ls_app_val = localStorage.getItem('app_status') || null;
  const [isApp, setIsAPP] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();


  const refreshImpersnoateTokenAndSet = (adminToken, urlToRedirect) => {
    const payload = {
      "token": adminToken,
      "returnSecureToken": true
    }
    IdentityService.RefreshImpersonateUserToken(payload)
      .then((response) => {
        const { data, status } = response;
        setOAuthTokens(data.idToken, data.refreshToken);

        IdentityService.UserAuthentication(data?.idToken)
          .then((response) => {
            const { status, data } = response;
            if (status === 200) {
              setOAuthUser(data);

              if (data.types.includes("ADMIN")) {
                setUser(data);
                dispatch(authSetUser(data));
                history.push(urlToRedirect);
                window.location.reload();
              } else if (data.types.includes("PRACTITIONER")) {
                PractitionerService.gePractitionersDetails(data.profileId)
                  .then((practResp) => {
                    if (practResp && practResp.data) {
                      const data1 = practResp.data;
                      setUser(data1);
                      dispatch(authSetUser(data1));
                      history.push(`/profile/${data1?.profileId}`);
                      window.location.reload();
                    }
                  })
              } else if (data.types.includes("PATIENT")) {
                setUser(data);
                dispatch(authSetUser(data));
                history.push(urlToRedirect);
                window.location.reload();
              } else {
                setUser(data);
                dispatch(authSetUser(data));
                history.push(`/home`);
                window.location.reload();
              }

            } else {
              console.log(FAILED_TO_FETCH_DATA);
              history.push(`/login`);
            }
          })
      })
  }

  const removeAdminPrefix = (str) => {
    const prefix = "/au";
    if (str.startsWith(prefix)) {
      return str.slice(prefix.length);
    }
    return str;
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const adminToken = new URLSearchParams(window.location.search).get('token') ?? null;
    if (queryParams.get('appreset') === '1') {
      localStorage.removeItem('app_status');
      ls_app_val = null;
    }
    const inApp = queryParams.get('inapp');
    if (inApp === '1' || ls_app_val == 'inapp') {
      setIsAPP(true);
      localStorage.setItem('app_status', 'inapp');
      document.body.classList.add('view_in_app');
    }

    if (adminToken !== null) {
      const urlToRedirect = removeAdminPrefix(location.pathname);
      refreshImpersnoateTokenAndSet(adminToken, urlToRedirect);
    }

  }, []);
  return (
    <BrowserRouter>
      <React.Suspense fallback={<div />}>
        <Switch>
          {/* new login register page */}
          <Route exact path="/new-register" component={NewDoctorRegister} />
          {/* <Route exact path="/login/screen" component={LoginScreen} /> */}
          <Route exact path="/new-doctor-profile" component={NewDoctorProfile} />
          <Route exact path="/new-doctor-profile-v2" component={NewDoctorProfileV2} />
          <Route exact path="/new-register-v2" component={NewDoctorRegisterV2} />
          <Route exact path="/new-claim-profile" component={NewProfileClaim} />
          <Route exact path="/new-otp-varification" component={NewOtpVarify} />
          <Route exact path="/new-register-step" component={NewRegisterStep} />
          <Route exact path="/new-register-step3" component={NewRegisterStepThree} />
          <Route exact path="/new-doctor-password" component={NewPasswordDoctor} />
          <Route exact path="/new-password-recovery" component={NewPasswordRecovery} />
          <Route exact path="/new-doctor-create" component={NewCreateRegister} />

          {/* patient profile */}
          <Route exact path="/patient-register" component={NewPatientRegister} />
          <Route exact path="/patient-mobile-verify" component={PatientMobileValidation} />
          <Route exact path="/patient-varify-otp" component={PatientOtpVarify} />
          <Route exact path="/patient-signup" component={PatientSignup} />
          <Route exact path="/au" component={DummyLoginScreen} />
          <Route path="/au/*" component={DummyLoginScreen} />

          <Route path="/claim-search-profiles/:name" component={SearchDoctorProfileClaim} />
          <Route path="/search-profiles/:name" component={SearchDoctorProfile} />
          <Route exact path="/claim-profile/:profileId" component={ProfileClaimNew} />
          {/* <AuthGuard exact path="/" component={Index} /> */}
          <Route exact path="/login" component={LoginSocialProfile} />
          <Route exact path="/reset-password" component={ForgotPassword} />
          {/* <AuthGuard
            exact
            path="/user-info-validate"
            component={Home}
          /> */}
          <Route exact path="/register" component={ClaimSocialProfile} />
          <Route exact path="/new/register" component={ProviderNewRegister} />
          <AuthGuard exact path="/register/verify-otp" component={OtpVerify} />
          <Route exact path="/u/register" component={PatientRegister} />
          <Route exact path="/u/create-account" component={PatientRegisterAccount} />
          <AuthGuard exact path="/account-info-entry" component={UserEntry} />
          <Route exact path="/register/onboarding" component={OnboardingProvider} />

          <Route exact path="/new-register" component={NewDoctorRegister} />
          <Route exact path="/new-doctor-profile" component={NewDoctorProfile} />
          <Route exact path="/new-doctor-profile-v2" component={NewDoctorProfileV2} />
          <Route exact path="/new-register-v2" component={NewDoctorRegisterV2} />
          <AuthGuard
            exact
            path="/reset-password/:token"
            component={ResetPassword}
          />
          <AuthGuard exact path="/oauth2/redirect" component={SocialOAuth} />
          {/* <Route path="/institution/:institutionID" component={Institutions} /> */}
          <Route path="/admin" component={Admin} />
          <Route render={() => <App />} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
};
export default AppContainer;
