import React, { useState } from "react";
import { Button, CardBody, FormGroup } from "reactstrap";
import ReactQuill from "react-quill";
import { OpinionService } from "utils/OpinionService";
import { FAILTOSAVERECOED } from "utils/Constants";
import { useEffect } from "react";
import { withAlert } from "react-alert";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const MedicalHistoryForm2 = (props) => {
    const { opinionInfo, setOpinionInfo, getOpinionInfo } = props;
    const [description, setDescription] = useState('');
    const [familyHistory, setFamilyHistory] = useState('');

    const handleDescriptionChange = (value) => {
        // console.log("Description:", value);
        setDescription(value);
    };

    const handleFamilyHistoryChange = (value) => {
        // console.log("Family History:", value);
        setFamilyHistory(value);
    };

    useEffect(() => {
        setDescription(opinionInfo?.description);
        setFamilyHistory(opinionInfo?.familyHistory);
    }, [opinionInfo?.id])


    const handleSubmit = () => {
        let payload = {
            "speciality": opinionInfo.speciality,
            "title": opinionInfo.title,
            "status": opinionInfo.status,
            "requestedBy": opinionInfo.requestedBy,
            "requestedFor": opinionInfo.requestedFor,
            "symptoms": opinionInfo.symptoms,
            "allergies": opinionInfo.allergies,
            "surgeries": opinionInfo.surgeries,
            "familyHistory": familyHistory,
            "description": description
        }
        // console.log(payload);

        OpinionService.updateOpinion(opinionInfo?.id, payload)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    showSuccessMessage("Opinion updated successfully.");
                    setOpinionInfo(data);
                    props.setCurrentStep('3')
                }
                else {
                    console.log(FAILTOSAVERECOED);
                }
            })
    }

    return (
        <CardBody className={`nex-form-step active`}>
            <div className="nex-form-field-container">
                <div className="nex-form-group">
                    <div className="nex-form-group-c">
                        <label htmlFor="" className="nex-big-label mb-3">Describe your Medical History in brief ? </label>
                        <FormGroup>
                            <ReactQuill
                                id="sq_health_issue"
                                placeholder="Enter Your Medical History in brief..."
                                value={description}
                                onChange={handleDescriptionChange}
                                bounds={".app"}
                            />
                        </FormGroup>

                        <label htmlFor="" className="nex-big-label mb-3">Do you have a strong family medical history of any serious illnesses
                            (cancer, heart disease etc) ? </label>
                        <FormGroup>
                            <ReactQuill
                                id="sq_health_issue"
                                placeholder="Enter Your Family Medical History in brief..."
                                value={familyHistory}
                                onChange={handleFamilyHistoryChange}
                                bounds={".app"}
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="nex-form-group-c mt-0">
                <div className="nex-form-action">
                    <Button
                        color="primary"
                        className="nexogic_primary_button_outline"
                        onClick={(e) => props.setCurrentStep('1')}
                    >
                        Back
                    </Button>
                    <Button
                        color="primary"
                        className="nexogic_primary_button"
                        onClick={(e) => handleSubmit()}
                    >
                        Next
                    </Button>
                </div>
            </div>
        </CardBody>
    )
}

export default withAlert()(MedicalHistoryForm2);
