import React from 'react'
import classnames from "classnames";
import secondOpinionImg from 'assets/images/second-opinion.png'
import { Card, CardBody, Nav, NavItem, NavLink } from 'reactstrap'
import { useState } from 'react';
import PersonalSettingForm from './components/PersonalSettingForm';
import Personal from '../PatientDashboard/components/Personal';
import Health from '../PatientDashboard/components/Health';
import Lifestyle from '../PatientDashboard/components/Lifestyle';
import { useEffect } from 'react';

const ProfileDetails = () => {
  const [activeTab, setActiveTab] = useState("Personal");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    document.querySelectorAll('.nexogic-dashboard-card')[0].classList.remove('v2');
  }, [])



  return (
    <>
      <div className="nexogic-connections-container px-0 row">
        <div className="col-md-4 col-lg-3">
          <div className="nexogic-sidebar-stiky mb-3">
            <div className="tabs-border-radius card">
              <ul className='top-links nexogic-aside-menu'>
                <li className='nav-item active'><a href="" className='nav-link'>Profile</a></li>
                {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
                  <>
                    <li className='nav-item'><a href="" className='nav-link'>Notifications & Password Settings</a></li>
                    <li className='nav-item'><a href="" className='nav-link'>Insurance</a></li>
                    <li className='nav-item'><a href="" className='nav-link'>Demographic Info</a></li>
                  </>
                }
              </ul>
            </div>
          </div>
        </div>
        <div className='col-md-8 col-lg-9'>
          <div className="border-0 bg-transparent">
            <div className="card-body p-0">
              <Nav tabs className="nexogic-tabs">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "Personal",
                    })}
                    onClick={() => {
                      toggle("Personal");
                    }}
                  >
                    Personal
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "Health",
                    })}
                    onClick={() => {
                      toggle("Health");
                    }}
                  >
                    Health
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "Lifestyle",
                    })}
                    onClick={() => {
                      toggle("Lifestyle");
                    }}
                  >
                    Lifestyle
                  </NavLink>
                </NavItem>
              </Nav>
              <Card>
                <CardBody>


                  {activeTab === "Personal" && (<>
                    {/*<PersonalSettingForm />*/}
                    <Personal />
                  </>
                  )}
                  {activeTab === "Health" && <Health />}
                  {activeTab === "Lifestyle" && <Lifestyle />}
                </CardBody>
              </Card>
            </div>
          </div>
        </div>
      </div>

      {/* please remove below code */}
      <div className='nexogic-patient-wrap d-none'>

        <div className="tabs-border-radius  card">

        </div>

        <div className="nexogic-patient-content">

          <div className="border-0 bg-transparent">
            <div className="card-body p-0">
              <Nav tabs className="nexogic-tabs">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "Personal",
                    })}
                    onClick={() => {
                      toggle("Personal");
                    }}
                  >
                    Personal
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "Health",
                    })}
                    onClick={() => {
                      toggle("Health");
                    }}
                  >
                    Health
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "Lifestyle",
                    })}
                    onClick={() => {
                      toggle("Lifestyle");
                    }}
                  >
                    Lifestyle
                  </NavLink>
                </NavItem>
              </Nav>
              {activeTab === "Personal" && (<>
                {/*<PersonalSettingForm />*/}
                <Personal />
              </>
              )}
              {activeTab === "Health" && <Health />}
              {activeTab === "Lifestyle" && <Lifestyle />}
            </div>
          </div>

          {/** 
      <div className="card ">
        <div className="row  card-body">
        <div className="col-md-7">
          <img src={secondOpinionImg} alt="Image" width="100%" />
        </div>
        <div className="col-md-5 nexogic-form-content">
          <div>
          <h3 className="nexogic-form-title mb-2 text-success">Profile Details</h3>
          <h1 className="nexogic-form-title mb-2">UNDER CONSTRUCTION</h1>         
          </div>
        </div>
      </div>
      </div>
*/}
        </div>
      </div>


    </>
  )
}

export default ProfileDetails