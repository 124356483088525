import FormikInputField from "components/forms/formikFields/FormikInputField";
import { Field, FieldArray } from "formik";
import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Label,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  Badge,
} from "reactstrap";
import { faRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import banner_bg from "assets/images/defaultBannerPage.png";
import logo from "assets/images/logo-nexogic.png";
import { useState } from "react";
import { ScreeningQuestion } from "../ScreeningQuestion";
import { JOB_Work_Shift_TypesList } from "utils/Constants";
import { CustomQuestions } from "../ScreeningQuestion/CustomQuestions";
import "../create-jobs.scss";

const dmmyQuestions = [
  {
    question: "How many years of Experience do you have in OPD ?",
    renderType: "NUMBER",
    options: [],
  },
  {
    question: "Do you like to work Shift basis ?",
    renderType: "SELECT",
    options: ["Yes", "No"],
  },
  {
    question: "Are you available for ICU ?",
    renderType: "SELECT",
    options: ["Yes", "No"],
  },
];

const FormStep2 = (props) => {
  return (
    <Row>
      <Col md={12}>
        <Card className="form-step2-wrap bg-white">
          <CardBody>
            <RenderForm {...props} />
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default FormStep2;

const RenderForm = (props) => {
  const { isSubmitting, handleSubmit, onBack, values, setFieldValue } = props;
  const [modal, setModal] = useState(false);
  const [qFlag, setQFlag] = useState(false);
  const [cQuestions, setCQuestions] = useState(false);
  const openModal = () => setModal(!modal);
  const addOwnQuestion2 = (arrayHelpers, evt) => {
    setQFlag(true);
    setCQuestions(true);
  };
  const addOwnQuestion = (qitem, arrayHelpers, evt) => {
    setQFlag(false);
    arrayHelpers.push({
      question: qitem.question,
      renderType: qitem.renderType,
      options: qitem.options,
    });
  };
  const addQuestion = (qitem, arrayHelpers, evt) => {
    setQFlag(false);
    arrayHelpers.push({
      question: qitem.question,
      renderType: qitem.renderType,
      options: qitem.options,
    });
    evt.target.disabled = true;
    evt.target.innerHTML =
      evt.target.innerText +
      `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" class="svg-inline--fa fa-check fa-w-16 ml-1 fagreen" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>`;
  };

  const removeQuestion = (index, sq, remove) => {
    const fullItem = values.screeningQuestions.filter(
      (_, indexItem) => indexItem !== index
    );
    remove(index);
    setFieldValue(`screeningQuestions`, fullItem);
    var header = document.getElementById("btnQGroup");
    var btns = header.getElementsByClassName("btnItem");
    for (var i = 0; i < btns.length; i++) {
      if (sq === btns[i].innerText) {
        btns[i].disabled = false;
        btns[i].innerHTML =
          btns[i].innerText +
          `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus fa-w-14 ml-1" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path></svg>`;
      }
    }
  };

  return (
    <form className="job-desc-form" onSubmit={handleSubmit}>
      <>
        <Row>
          <Col md={12}>
            <Label className="bold-label" for="Experience">
              Experience
            </Label>
            <Row>
              <Col md={6}>
                <FormGroup>
            <label htmlFor="minExperience">Minimum Experience</label>
                  <Field
                    id="minExperience"
                    name="minExperience"
                    autoComplete="minExperience"
                    type="number"
                    min="0"
                    component={FormikInputField}
                    placeholder="Minimum Experience"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
            <label htmlFor="maxExperience">Maximum Experience</label>
                  <Field
                    id="maxExperience"
                    name="maxExperience"
                    autoComplete="maxExperience"
                    type="number"
                    min="0"
                    component={FormikInputField}
                    placeholder="Maximum Experience"
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col md={12}>
            <Label className="bold-label" for="Salary">
              Salary
            </Label>
            <Row>
              <Col md={6}>
                <FormGroup>
            <label htmlFor="minSalary">Minimum Salary</label>
                  <Field
                    id="minSalary"
                    name="minSalary"
                    autoComplete="minSalary"
                    type="number"
                    min="0"
                    component={FormikInputField}
                    placeholder="Minimum Salary"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
            <label htmlFor="maxSalary">Maximum Salary</label>
                  <Field
                    id="maxSalary"
                    name="maxSalary"
                    autoComplete="maxSalary"
                    type="number"
                    min="0"
                    component={FormikInputField}
                    placeholder="Maximum Salary"
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg={12} className="d-flex">
            <FieldArray name="screeningQuestions" render={(arrayHelpers) => (
              <div className="contact-card border-0 p-0">
                <Label className="bold-label" for="PrimarySpecialtlity">
                  Screening Questions
                </Label>
                <div className="cont-head px-0">
                  <span className="con-title">
                    {typeof props?.errors?.screeningQuestions === "string" &&
                      props?.errors?.screeningQuestions && (
                        <p>
                          <RenderErrorMessage
                            errorMsg={props.errors.screeningQuestions}
                          />
                        </p>
                      )}
                  </span>
                </div>
                <div className="num-card-container px-0">
                  {values.screeningQuestions?.map((_, index) => (
                      <ScreeningQuestion {...arrayHelpers} {...props} key={index} index={index} removeQuestion={removeQuestion} />
                  ))}
                  {cQuestions===true && (
                    <CustomQuestions arrayHelpers={arrayHelpers} {...props} addOwnQuestion={addOwnQuestion} setCQuestions={setCQuestions} setQFlag={setQFlag}/>
                    )}
                  </div>
                  <div id="btnQGroup">
                    {dmmyQuestions.map((squestion, index) => {
                      return (
                        <div key={index}>
                          <Button
                            color="secondary"
                            name={`btnQadd${index}`}
                            id={`btnQadd${index}`}
                            outline
                            className="btnItem mr-2 mb-2"
                            type="button"
                            onClick={(evt) => {
                              addQuestion(squestion, arrayHelpers, evt);
                            }}
                          >
                            {squestion.question}
                            <FontAwesomeIcon className="ml-1" icon={faPlus} />
                          </Button>
                        </div>
                      );
                    })}
                    <div className="">
                    <Button
                      outline
                      color="success"
                      id="addOwnQ"
                      className="mr-2 nexogic_primary_button_outline "
                      disabled={qFlag}
                      type="button"
                      onClick={(evt) => {
                        addOwnQuestion2(arrayHelpers, evt);
                      }}
                    >
                      Add your custom question{" "}
                      {qFlag ? (
                        <FontAwesomeIcon className="ml-1" icon={faCheck} />
                      ) : (
                        <FontAwesomeIcon className="ml-1" icon={faPlus} />
                      )}
                    </Button>
                    </div>
                  </div>
                </div>
              )}
            />
          </Col>
        </Row>
        <hr className="mt-3" />
        <Row>
          <Col className="d-flex mt-3">
            <div style={{ flex: "0 1 0%" }}>
              <Button
                id="btn_preview"
                className="nexogic_primary_button_outline "
                // disabled={isSubmitting}
                color="link"
                type="button"
                onClick={openModal}
              >
                Preview
              </Button>
            </div>
            <div style={{ flex: "0 1 0%", marginLeft: "auto" }}>
              <Button className="m-auto d-block nexogic_primary_button" type="submit" color="primary" id="nex-jf-submit-2">
                Submit
              </Button>
            </div>
          </Col>
        </Row>
        <Modal
          backdrop="static"
          centered
          scrollable
          isOpen={modal}
          openModal={openModal}
          className="preview-wrapper"
        >
          <ModalHeader toggle={openModal}>Job Preview</ModalHeader>

          <ModalBody className="p-0">
            <div className="prev-banner-main">
              <div
                className="preview-banner"
                style={{
                  backgroundImage: `url(${banner_bg})`,
                  color: "white",
                  fontSize: "1.6rem",
                  padding: "0 10px 0px 10px",
                }}
              >
                {/* <img src={banner_bg} alt="#" /> */}
                <h3 className="ins-names">
                  {" "}
                  {values?.institutionDetail?.institutionName}
                </h3>
              </div>
              <div className="preview-job-provider text-center">
                <div>
                  <img src={logo} className="job-provider-img" alt="#" />
                </div>
                <div className="prev-content-part">
                  <div className="txt-content">
                    <h3 className="sales-title mb-0">{values?.title}</h3>
                    <span style={{ fontWeight: "lighter", fontSize: "14px" }}>
                      {values?.primarySpeciality}
                    </span>
                    <p className="sales-desc">
                      {values?.location} ,{" "}
                      {values?.city[0]?.value.split(",").shift() ?? ""}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="preview-body-content">
              <Row>
                <Col md={8}>
                  <h6>
                    <strong>Job Description</strong>
                  </h6>
                  <div className="descrption-txt mb-3">
                    <div
                      dangerouslySetInnerHTML={{ __html: values.description }}
                    ></div>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="job-type-right">
                    <h6>Job Type</h6>
                    <p>{values?.position}</p>
                  </div>
                  <div className="job-type-right">
                    <h6>Experience</h6>
                    <p>
                      {values?.minExperience || 0} -{" "}
                      {values?.maxExperience || 0} Years
                    </p>
                  </div>
                  <div className="job-type-right">
                    <h6>Work Shift</h6>
                    <p>
                      {JOB_Work_Shift_TypesList.filter(
                        (node) => node?.value === values?.shift
                      ).length > 0
                        ? JOB_Work_Shift_TypesList.filter(
                            (node) => node?.value === values?.shift
                          )[0].label
                        : ""}
                    </p>
                  </div>
                  <div className="job-type-right">
                    <h6>Salary</h6>
                    <p>
                      <span>
                        <FontAwesomeIcon
                          icon={faRupeeSign}
                          className="ml-2 mr-1 text-gray f-13"
                        />
                        {values?.minSalary || 0} -
                      </span>
                      <span>
                        <FontAwesomeIcon
                          icon={faRupeeSign}
                          className="ml-1 mr-0 text-gray f-13"
                        />{" "}
                        {values?.maxSalary || 0}
                      </span>
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6 className="mt-2">
                    <strong>Required Skills</strong>
                  </h6>
                  <div className="mt-1">
                    {values?.skills.map((node, i) => (
                      <Badge
                        className=" mr-1"
                        key={i}
                        color="light"
                        pill
                        style={{ fontSize: "14px", fontWeight: "normal" }}
                      >
                        {node}
                      </Badge>
                    ))}
                  </div>
                  <h6 className="mt-2">
                    <strong>Qualification</strong>
                  </h6>
                  <div className="mt-1">
                    {values?.qualifications.map((node, i) => (
                      <Badge
                        className=" mr-1"
                        key={i}
                        color="light"
                        pill
                        style={{ fontSize: "14px", fontWeight: "normal" }}
                      >
                        {node}
                      </Badge>
                    ))}
                  </div>
                </Col>
              </Row>
            </div>
            <div className="footer-action">
              <Button color="primary" onClick={openModal} className="nexogic_primary_button_outline">
                Close
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </>
    </form>
  );
};
