import React from 'react'
import { useState } from 'react';

import { Bar, Pie } from 'react-chartjs-2';
import { array } from 'yup';



function ChartsComponent(props) {



  const [chartGen, setChartGen] = useState(false);
  const [chartGenData, setChartGenData] = useState({});

  const [chartType, setChartType] = useState('Bar');
  const [chartDataSet, setChartDataSet] = useState([
    { label: "", value: "", bg: "#000" }
  ]);

  function UpdateChartDataHandler(e, index) {
    let data = [...chartDataSet];
    data[index][e.target.name] = e.target.value;
    setChartDataSet(data);

  }

  function AddNewData(e) {
    e.preventDefault();
    let new_data = { label: "", value: "", bg: "#000" };
    setChartDataSet(data => [...data, new_data]);
  }

  function generateChart(e) {
    e.preventDefault();
    console.log(chartDataSet);
    setChartGen(true);
    let getLabels = chartDataSet.map(item => item.label);
    let getValues = chartDataSet.map(item => item.value);
    let getbg = chartDataSet.map(item => item.bg);
    let reObj = {};
    reObj['labels'] = getLabels;
    let datasetObj = [{
      data: getValues,
      backgroundColor: getbg
    }];
    reObj['datasets'] = datasetObj;
    setChartGenData(reObj);
    console.log(reObj)
  }


  const options = {
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    }
  }

  return (
    <div className='charts-wrapper card mb-3'>
      <div className="card-body">
      <div className="row">
        <div className="col-lg-6">
          <div className='chart-form-section'>
            <h2 className='nex-form-title'>Chats</h2>
            <div className="form-group mb-3">
              <label htmlFor="">Chart Type</label>
              <select className='form-control' value={chartType} onChange={(e) => setChartType(e.target.value)}>
                <option value="Pie" >Pie</option>
                <option value="Bar" >Bar</option>
              </select>
            </div>
            <div className='nex-chart-data-lists'>
              {chartDataSet &&
                chartDataSet.map((item, ind) => {
                  return (
                    <>
                      <div className='nex-chart-data-lists-i'>
                        <div className="number">{ind + 1}</div>
                        <div className="info">
                          <div className="form-group mb-3">
                            <label htmlFor="">Field Name</label>
                            <input type="text" name='label' value={item.label} onChange={(e) => UpdateChartDataHandler(e, ind)} className='form-control' />
                          </div>
                          <div className="form-group mb-3">
                            <label htmlFor="">Field value</label>
                            <input type="number" name='value' value={item.value} onChange={(e) => UpdateChartDataHandler(e, ind)} className='form-control' />
                          </div>
                          <div className="form-group mb-3">
                            <label htmlFor="">Field color</label>
                            <input type="color" name="bg" value={item.bg} onChange={(e) => UpdateChartDataHandler(e, ind)} className='form-control' />
                          </div>
                        </div>
                      </div>

                    </>
                  )
                })
              }
              <div className="form-group mb-3">
                <a className='btn nexogic_primary_button_outline' onClick={(e) => AddNewData(e)}>+ Add New</a>
              </div>
            </div>



            <div className="form-group mb-3 text-right">
              <button className='btn nexogic_primary_button' onClick={(e) => generateChart(e)} >Generate Report</button>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          {chartType == 'Pie' && 
          <Pie data={chartGenData}
          options={options}
        ></Pie>
          }
          {chartType == 'Bar' && 
          <Bar data={chartGenData}
          options={options}
        ></Bar>
        }
        

        </div>
      </div>

</div>

      
    </div>
  )
}

ChartsComponent.defaultProps = {
  name: "Rahul",
  eyeColor: "deepblue",
  age: "45"
}

export default ChartsComponent;
