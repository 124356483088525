import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import edit_icon from 'assets/images/edit-blue-icon.png';
import UpdateStatusModal from './UpdateStatusModal';
import { dateFormatter } from 'utils/Utils'

export default function PatientStatus(props) {
    const { referral, referralResponse, getReferralResponse, isResponseSent, isEditable } = props;
    const { patientStatuses } = referralResponse;
    const [sortedPatientStatuses, setSortedPatientStatuses] = useState([]);
    const [showAll, setShowAll] = useState(false);


    //status to Update
    const [statusId, setStatusId] = useState(null);
    const [info, setInfo] = useState('');

    const [statusModal, setStatusModal] = useState(false);
    const toggleUpdateStatus = () => { setStatusModal(!statusModal) };

    const [showMoreStatus, setShowMoreStatus] = useState({});

    // Function to handle "Show More" click for a specific status
    const handleShowMoreClick = (statusId) => {
        setShowMoreStatus((prevState) => ({
            ...prevState,
            [statusId]: true,
        }));
    };

    // Function to handle "Hide More" click for a specific status
    const handleHideMoreClick = (statusId) => {
        setShowMoreStatus((prevState) => ({
            ...prevState,
            [statusId]: false,
        }));
    };


    const sortPatientStatuses = (patientStatuses) => {
        const sorted = patientStatuses.slice().sort((a, b) => new Date(b.updatedOn) - new Date(a.updatedOn));
        setSortedPatientStatuses(sorted);
    };


    useEffect(() => {
        patientStatuses && sortPatientStatuses(patientStatuses);
    }, [patientStatuses]);

    const handleViewHistoryClick = () => {
        setShowAll(true);
    };

    const handleHideHistoryClick = () => {
        setShowAll(false);
    };

    return (
        <>
            {sortedPatientStatuses && (
                <div className='nexogic-patient-status '>
                    {sortedPatientStatuses && sortedPatientStatuses.length > 0 &&
                        <div className="card-head">
                            <Row className='item'>
                                <Col className='col-date'>Date</Col>
                                <Col className='col-status'>Status</Col>
                            </Row>
                        </div>
                    }
                    <div className="card-body px-0 nexogic-custom-scroll">
                        {sortedPatientStatuses.slice(0, showAll ? sortedPatientStatuses.length : 3).map((patientStatus, index) => (
                            <Row key={patientStatus.id} className={`item ${showMoreStatus[patientStatus.id] ? 'show-full' : ''}`}>
                                {/* <Col className='col-date'>{patientStatus?.updatedOn}</Col> */}
                                <Col className='col-date'>{dateFormatter(patientStatus?.updatedOn)}</Col>
                                <Col className='col-status'>
                                    <span className='d-inline-block nexogic-status-content'>
                                        {(showMoreStatus[patientStatus.id] || patientStatus?.info.length <= 55)
                                            ? patientStatus?.info
                                            : patientStatus?.info.slice(0, 55) + "..."}
                                    </span>
                                    <div className='actions'>
                                        {patientStatus?.info.length > 55 && (

                                            <span
                                                className='btn-link'
                                                onClick={() =>
                                                    showMoreStatus[patientStatus.id]
                                                        ? handleHideMoreClick(patientStatus.id)
                                                        : handleShowMoreClick(patientStatus.id)
                                                }
                                            >
                                                {showMoreStatus[patientStatus.id] ? 'Hide' : 'Show More'}
                                            </span>

                                        )}
                                        {index === 0 && (
                                            <span
                                                style={{ cursor: 'pointer' }} onClick={() => {
                                                    setStatusId(patientStatus?.id)
                                                    setInfo(patientStatus?.info)
                                                    toggleUpdateStatus()
                                                }}>
                                                {isResponseSent && isEditable &&
                                                    <>
                                                        <span className='nexogic-small-icon'>
                                                            <img src={edit_icon} alt="edit" />
                                                        </span>
                                                        <UpdateStatusModal
                                                            toggle={toggleUpdateStatus}
                                                            modal={statusModal}
                                                            setModal={setStatusModal}
                                                            referralId={referral?.id}
                                                            responseId={referralResponse?.id}
                                                            getReferralResponse={getReferralResponse}
                                                            statusId={statusId}
                                                            info={info}
                                                        />
                                                    </>
                                                }
                                            </span>
                                        )}
                                    </div>
                                </Col>
                            </Row>
                        ))}
                    </div>
                    {sortedPatientStatuses && sortedPatientStatuses.length > 3 &&
                        <div className="card-footer nexogic-bot-card-btn-separate">
                            {showAll ? (
                                <button onClick={handleHideHistoryClick} className='btn nexogic_primary_button_outline'>
                                    Hide Status History
                                </button>
                            ) : (
                                <button onClick={handleViewHistoryClick} className='btn nexogic_primary_button_outline'>
                                    View Status History
                                </button>
                            )}
                        </div>
                    }
                </div >
            )
            }
        </>
    );


}
