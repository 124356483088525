import contactsActiveIcon from "assets/images/action-groups-16.png";
import approvalIcon from "assets/images/approval_icon.png";
import marker_icon from "assets/images/marker_icon.png";
import plus_icon from "assets/images/plus-icon.png";
import degree_icon from "assets/svgIcon/degree_icon.svg";
import varified_icon from "assets/svgIcon/varified_icon.svg";
import spasalist_icon from "assets/svgIcon/spasalist_icon.svg";
import file_icon from "assets/svgIcon/file_icon.svg";
import location_icon from "assets/svgIcon/location_icon.svg";
import connection_icon from "assets/svgIcon/connection_icon.svg";
import language_icon from "assets/svgIcon/language-icon.svg";

import { isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { withAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import { profileAddEvent } from "redux-helpers/ActionCreator";
import { ConnectionService } from "utils/ConnectionService";
import { UserStatusConstants } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import ContainerRecentExperience from "./components/ContainerRecentExperience";
import ImageUploader from "./components/ImageUploader";
import RenderConnctBtn from "./components/RenderConnctBtn";
import RenderCoverImage from "./components/RenderCoverImage";
import SmartCard from "./components/SmartCard";
import RenderEditProfileForm from "./components/RenderEditProfileForm";
import { RenderRatingPopover } from "./components/RenderRatingPopover";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const DoctorsProfile = (props) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );
  const [isOpen, setModal] = useState(false);
  const toggle = () => setModal(!isOpen);
  const [dropdownOpen, setDdropdownOpen] = useState(false);
  const reviews = [];
  const [currentProfileUser, setCurrentProfileUser] = useState(null);
  const [connectionsCount, setConnectionsCount] = useState(0);
  const { practitioner, isAdmin, isLoggedinUser, setPractitioner, alert } =
    props;
  const {
    headline,
    profilePicUrl,
    reviewCount,
    guid,
    bannerUrl,
    firstName,
    middleName,
    lastName,
    primarySpeciality,
    superSpeciality,
    qualification,
    title,
    address,
    languages
  } = practitioner;
  // const location = useLocation();
  const username = `${firstName} ${lastName}`;
  const dispatchEventName = useDispatch();
  const profileComplete = useSelector((state) => state.profileComplete);
  const sectionList = [
    { name: "About", event_name: "ADD_ABOUT", section_name: "aboutSection" },
    {
      name: "Skills & Expertise",
      event_name: "ADD_SKILL",
      section_name: "skillSection",
    },
    {
      name: "Services offered",
      event_name: "ADD_SERVICE",
      section_name: "serviceSection",
    },
    {
      name: "Affiliation",
      event_name: "ADD_Affiliation",
      section_name: "affiliationSection",
    },
    {
      name: "Experience",
      event_name: "ADD_EXPERIENCE",
      section_name: "experienceSection",
    },
    
    {
      name: "Education",
      event_name: "ADD_EDUCATION",
      section_name: "educationSection",
    },
    {
      name: "Certifications & Licensure",
      event_name: "ADD_CERTIFICATIONS",
      section_name: "certificationsSection",
    },
    {
      name: "Professional Memberships",
      event_name: "ADD_MEMBERSHIPS",
      section_name: "professionalSection",
    },
    {
      name: "Publications & Presentations",
      event_name: "ADD_PUB",
      section_name: "publicationsSection",
    },
    {
      name: "Awards & Recognitions",
      event_name: "ADD_AWARDS",
      section_name: "awardsSection",
    },
    {
      name: "Clinical Trials",
      event_name: "ADD_CLINICAL",
      section_name: "clinicalTrialsSection",
    },
    {
      name: "Committees",
      event_name: "ADD_COMMITTEES",
      section_name: "committeesSection",
    },
    {
      name: "Registration",
      event_name: "ADD_Registration",
      section_name: "registrationSection",
    },
    {
      name: "Registration Documents",
      event_name: "ADD_Registration",
      section_name: "registration_documents",
    },
    { name: "Social", event_name: "ADD_SOCIAL", section_name: "socialSection" },
  ];

  useEffect(() => {
    getConnectedCount();
  }, [guid]);

  // useEffect(() => {
  //   console.log(practitioner);
  // }, [])

  const getConnectedCount = async () => {
    ConnectionService.getConnectionsCount(currentUser.guid)
      .then((response) => {
        let { data } = response;
        if (response.status === 200 || response.status === 201) {
          setConnectionsCount(data);
        }
      })
      .catch((error) => {
        console.log("Error while getting connection status: ", error);
      });
  };

  const onHandleAddSection = (section) => {
    if (practitioner.status !== UserStatusConstants.APPROVED) {
      dropClick();
      showErrorMessage("Permission denied. Please contact admin for approval.");
    } else {
      dropClick();
      dispatchEventName(profileAddEvent(section));
    }
  };

  const onHandleScrollToSection = (sectionName) => {
    var element = document.getElementById(`${sectionName}`);
    var headerOffset = 45;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };

  const connectionCount = useMemo(() => {
    return connectionsCount > 1
      ? `${connectionsCount} connections`
      : `${connectionsCount} connection`;
  }, [connectionsCount]);

  const dropClick = () => {
    if(dropdownOpen){
      document.querySelectorAll('body')[0].classList.remove('add-section-on');
    }else{
      document.querySelectorAll('body')[0].classList.add('add-section-on');
    }
    setDdropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    if (isAdmin && globalProfileInfo?.guid && practitioner) {
      PractitionerService.getPractitionersPersonalDetails(
        globalProfileInfo?.guid
      )
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            if (response?.data) {
              const { emails, phones, address, dateOfBirth, headline } =
                response?.data;
              setCurrentProfileUser(() => {
                const clone = { ...practitioner };
                clone.emails = emails && emails.length > 0 ? emails : [];
                clone.phones = phones && phones.length > 0 ? phones : [];
                clone.address = address ?? null;
                clone.dateOfBirth = dateOfBirth ?? "";
                clone.headline = headline ?? "";
                return clone;
              });
            }
          }
        })
        .catch((error) => {
          console.log(
            "PractitionerService.getPractitionersPersonalDetails error",
            error
          );
        });
    } else {
      setCurrentProfileUser(() => {
        const clone = { ...practitioner };
        return clone;
      });
    }
  }, [isAdmin, globalProfileInfo, setPractitioner, practitioner]);

  useEffect(() => {
    document.body.classList.add('nex-screen-profile-page');
    document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "Profile";
    return () => {
      document.body.classList.remove('nex-screen-profile-page');
      document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "";
    }
}, [])

  return (
    <section className="nexogic-profile-main" id="nexogic-doctor-main-profile">
      <div className="nexogic-card-banner card border-0 nexogic-banner-admin-card">
        <div className=" card-cover">
          <RenderCoverImage
            bannerUrl={bannerUrl}
            setPractitioner={setPractitioner}
            isLoggedinUser={isLoggedinUser}
            isAdmin={isAdmin}
            practitionerId={guid}
            practitioner={practitioner}
          />

        </div>
        <div className=" card-body">
          <div className="card-user-img-w">
            <ImageUploader
              profilePicUrl={profilePicUrl}
              practitionerId={guid}
              setPractitioner={setPractitioner}
              isLoggedinUser={isLoggedinUser}
              isAdmin={isAdmin}
              username={username}
              practitioner={practitioner}
            />
          </div>
          <div className="info">

            <Card className="nexogic-profile-info-card">
              <CardBody>

                

                <Row className="align-items-center">
                  <Col lg={12}>
                    <h3 className="card-title">
                      {title} {firstName} {middleName} {lastName} {" "} {qualification && (
                        <span className="card-text"><small>
                          {", " + qualification + " "} </small>
                        </span>
                      )}

                      {practitioner.status === UserStatusConstants.APPROVED ? (
                        <img src={varified_icon} alt="verified icon" className="varify-img" />
                      ) : (
                        ""
                      )}
                      <div className="nexogic-profile-edit-icon">
                  {currentProfileUser && (
                    <RenderEditProfileForm
                      isLoggedinUser={isLoggedinUser}
                      practitioner={currentProfileUser}
                      setPractitioner={setPractitioner}
                      isAdmin={isAdmin}
                    />
                  )}
                </div>
                      {headline && (
                        <small className="text-muted"><br />{"" + headline + " "}</small>
                      )}
                    </h3>
                  </Col>
                  <Col lg={6} className="nexogic-info-left">


                    <p className="card-text">
                      <img src={spasalist_icon} alt="" className="icon" />
                      {primarySpeciality && (
                        <span className="">
                          {primarySpeciality + " "}
                        </span>
                      )}

                      {superSpeciality && (
                        <>{"| "} <span className="">
                          {superSpeciality}
                        </span></>
                      )}
                    </p>

                    <p className="card-text">
                      <img src={file_icon} alt="" className="icon" />
                      <RenderExpYear practitioner={globalProfileInfo} />
                    </p>

                    {address && address.length > 0 && (

                      <p className="card-text">
                        <img src={location_icon} alt="#" className="icon" />
                        {address[0].location && (
                          <span className="">
                            {address[0].location},
                          </span>
                        )}
                        {address[0].city && (
                          <span className="">
                            {address[0].city}
                          </span>
                        )}
                      </p>

                    )}
                    {languages.length > 0 &&
                      <p className="card-text">
                        <img
                          src={language_icon}
                          className="icon" width={18}

                          alt="contactsActiveIcon"
                        />
                        {languages.map((item, i) => <span key={'lang' + i}>{i == 0 ? '' : ', '}{item}</span>)}
                      </p>
                    }

                    {isLoggedinUser && (
                      <p className="card-text">
                        <img
                          src={connection_icon}
                          className="icon"
                          alt="contactsActiveIcon"
                        />
                        {practitioner.status === UserStatusConstants.APPROVED ? (
                          <Link
                            to={"/connections"}
                            className="text-decoration-none"
                          >
                            <span className="">
                              {connectionCount}
                            </span>
                          </Link>
                        ) : (
                          <span className="">
                            {connectionCount}
                          </span>
                        )}
                      </p>
                    )}

                    <div className="d-flex">
                      <div className="info-icons d-flex align-items-center">
                        {reviews && reviews.length > 0 && (
                          <React.Fragment>
                            <div className="reviews d-flex align-items-center">
                              <RenderRatingPopover
                                practitionerId={practitioner.guid}
                                reviews={reviews}
                                reviewCount={reviewCount}
                              />
                            </div>
                            <div className="dot"></div>
                          </React.Fragment>
                        )}

                      </div>
                    </div>
                    {/* <div className="profile-info-detail">
                      {!isLoggedinUser &&
                        !isAdmin &&
                        !isEmpty(currentUser) &&
                        globalProfileInfo && (
                          <Row className="mx-0">
                            <RenderConnctBtn getConnectedCount={getConnectedCount} />
                            <div className="pl-3">
                              <SmartCard toggle={toggle} isOpen={isOpen} practitioner={practitioner} />
                            </div>
                          </Row>
                        )}
                    </div> */}
                  </Col>
                  <Col lg={6} className="nexogic-info-right">
                    <ContainerRecentExperience />
                  </Col>
                </Row>
                <Row className="">
                  <Col md={12}>
                  {/* <div className="profile-info-detail"> */}
                      {!isLoggedinUser &&
                        !isAdmin &&
                        !isEmpty(currentUser) &&
                        globalProfileInfo && (
                          <Row className="nexogic-btn-group mx-0">
                            <RenderConnctBtn getConnectedCount={getConnectedCount} />
                            <div className="edit-icon">
                              <SmartCard toggle={toggle} isOpen={isOpen} practitioner={practitioner} />
                            </div>
                          </Row>
                        )}
                    {/* </div> */}
                    {isLoggedinUser &&
                      !isAdmin &&
                      currentUser.status === UserStatusConstants.APPROVED && (
                        <Row className="nexogic-btn-group mx-0">
                          <div className="edit-icon">
                            <SmartCard
                              toggle={toggle}
                              isOpen={isOpen}
                              practitioner={practitioner}
                            />
                          </div>
                          <Dropdown
                            id="nex-dp-add-setion-menu-btn"
                            isOpen={dropdownOpen}
                            toggle={dropClick}
                            className="nexogic_add_section_menu"
                          >
                            <DropdownToggle caret className="nexogic_primary_button">Add Sections</DropdownToggle>
                            <DropdownMenu>
                              {sectionList.map((section, index) => {
                                let currentItem = profileComplete.data.filter(
                                  (v) => v.event === section.event_name
                                )[0];
                                let isContentBold = currentItem.filled
                                  ? "has-data"
                                  : "";
                                return (
                                  <div className="nexogic_add_section_item" key={`${index}`}>
                                    <DropdownItem title={`Go to ${section.name} Section`}
                                      className={isContentBold}
                                      onClick={() =>
                                        onHandleScrollToSection(section.section_name)
                                      }
                                    >
                                      {section.name}
                                    </DropdownItem>
                                    <Button
                                      className="nexogic_add_section_plus_btn"
                                      onClick={() =>
                                        onHandleAddSection(section.event_name)
                                      }
                                      title={`Add ${section.name}`}
                                    >
                                      <img src={plus_icon} alt="plus" />
                                    </Button>
                                  </div>
                                );
                              })}
                            </DropdownMenu>
                          </Dropdown>
                          
                        </Row>
                      )}
                  </Col>
                </Row>
              </CardBody>
            </Card>













          </div>
        </div>
      </div>



    </section>
  );
};

const RenderExpYear = (props) => {
  let { practicingSince } = props.practitioner;
  let currentYear = new Date().getFullYear();
  const expYears = currentYear - practicingSince;
  let exHTML;
  if (isNaN(expYears)) {
    exHTML = "1 year experience";
  } else if (expYears === 0) {
    exHTML = "1 year experience";
  } else {
    exHTML = `${expYears}+ years experience`;
  }
  return <span className="speciality-category">{exHTML}</span>;
};

export default withAlert()(DoctorsProfile);
