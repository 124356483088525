import { faCheckCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import pencilIcon from "assets/images/pencil_icon.png";
import { isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import {
  profileAddEvent,
  updateProfileCompletness,
} from "redux-helpers/ActionCreator";
import { UserStatusConstants } from "utils/Constants";
import { getEmptySectionShowHide } from "utils/Utils";
import RenderSkillsAndExpertiseForm from "./RenderSkillsAndExpertiseForm";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import blue_bullets from "assets/images/blue-bullets.png";
import plusIcon from "assets/images/plus-icon-lg.png";
import editIcon from "assets/images/edit-blue-icon.png";
import NoData from "../NoData";


const RenderSkill = (props) => {
  const { skills, count } = props;
  if (skills && skills.length) {
    return skills.slice(0, count).map((skill, index) => {
      if (!!skill) {
        return (
          <li className="list-group-item bullet-icon-item" key={skill}>
            <div className="row g-0">
              <div className="col-auto"><img src={blue_bullets} alt="" /></div>
              <div className="col">
                <h3 className="card-title fw-semibold">{skill}</h3>
              </div>
              {/** <FontAwesomeIcon icon={faCheckCircle} className="icon-check" />*/}
            </div>

          </li>
        );
      }
    });
  }
  return "";
};

const ShowMoreSkills = (props) => {
  const { skills, count, setCount } = props;
  if (skills && skills.length > count) {
    const remainingCount = skills.length - count;
    return (
      <Button
        id="nex-ps-skill-more-btn"
        color="link"
        onClick={() => setCount(skills.length)}
        className="d-inline-block f-14"
      >
        {`+ ${remainingCount} more`}
      </Button>
    );
  }

  return null;
};

export const SkillsAndExpertise = (props) => {
  const [pannel_on, setPannel_on] = useState(true);
  const history = useHistory();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const eventName = useSelector((state) => state.profileComplete.eventName);
  const dispatch = useDispatch();
  const { practitioner, isLoggedinUser, setPractitioner, isAdmin, setShowSkillsOnTabScroll } = props;

  const [count, setCount] = useState(14);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { skills, guid } = practitioner;

  useEffect(() => {
    dispatch(updateProfileCompletness(3, !!skills.length));
    if (skills.length > 0 || isLoggedinUser || isAdmin) {
      setShowSkillsOnTabScroll(true);
    }else{
      setShowSkillsOnTabScroll(false);
    }
  }, [skills]);

  useEffect(() => {
    if (eventName === "ADD_SKILL") {
      toggle();
      dispatch(profileAddEvent(null));
    }
  }, [eventName]);

  const isNotEmpty = useMemo(() => {
    return skills && skills.length > 0;
  }, [skills]);

  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );

  if (
    getEmptySectionShowHide(
      skills,
      isAdmin,
      currentUser,
      globalProfileInfo,
      guid
    )
  )
    return null;

  return (
    <>
      <div className={`card nexogic-card-color-header ${pannel_on ? 'expanded' : ''}`} id="skillSection">
        <div className="card-header"><h2>Skills & Expertise</h2>



          {(isAdmin ||
            (isLoggedinUser &&
              currentUser.status === UserStatusConstants.APPROVED)) && (

              <Button
                id="nex-ps-skill-add-btn"
                color="link"
                className="add-button"
                onClick={toggle}
              >
                {isNotEmpty ? (
                  <><img src={editIcon} alt="" />
                    {/** <img src={pencilIcon} alt="" />*/}
                  </>
                ) : (
                  <><img src={plusIcon} alt="" />
                    {/*<FontAwesomeIcon icon={faPlus} />*/}
                  </>
                )}
              </Button>
            )}


          <button className="collapse-btn" type="button" onClick={() => setPannel_on(!pannel_on)}>
            <img src={pannel_arrow} alt="" />
          </button>
        </div>
        <div className={`collapse-div`}>
          <div className={`card-body`}>
            {skills && skills.length
              ? ""
              : (isLoggedinUser || isAdmin) &&
              currentUser.status === UserStatusConstants.APPROVED && (

                <Button
                  id="nex-ps-skill-add-btn2"
                  className="readmore"
                  color="link"
                  onClick={toggle}
                >
                  {"Add Skills & Expertise"}
                </Button>

              )}
            <ul className="list-group list-group-flush" id="nex-ps-skill-lists">
              <RenderSkill skills={skills} count={count} />
            </ul>
            <ShowMoreSkills
              skills={skills}
              count={count}
              setCount={setCount}
            />

            {!isLoggedinUser && isEmpty(currentUser) && (
              <Row className="py-5 my-4">
                <Col xs="12">
                  <p className="text-primary font-weight-bold font-italic">
                    {" "}
                    Please Sign In to access the full profile.
                  </p>
                  <Button color="primary" id="nex-ps-skill-login-btn" onClick={() => history.push("/login")}>
                    {"Login"}
                  </Button>
                </Col>
              </Row>
            )}
            {!isNotEmpty &&
            <NoData>No Data</NoData>
            }
          </div>
        </div>
      </div>



      {/**  
      <Card className="doctors-details bg-white" id="skillSection">
        <CardBody>
          <Row
            className={skills && skills.length > 0 ? "" : "align-items-center"}
          >
            <Col>
              <h3 className="section-title m-0 mb-3">Skills & Expertise </h3>
              {skills && skills.length
                ? ""
                : (isLoggedinUser || isAdmin) &&
                  currentUser.status === UserStatusConstants.APPROVED && (
                    <div className="mt-3">
                      <Row className="align-items-center">
                        <Col className="add-infomation-part">
                          <Button
                            className="add-infomation p-0"
                            color="link"
                            onClick={toggle}
                          >
                            {"Add Skills & Expertise"}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  )}
            </Col>
            {(isAdmin ||
              (isLoggedinUser &&
                currentUser.status === UserStatusConstants.APPROVED)) && (
              <Col xs="auto">
                <Button
                  color="link"
                  className="round-icon-btn rounded-circle"
                  onClick={toggle}
                >
                  {isNotEmpty ? (
                    <img src={pencilIcon} alt="" />
                  ) : (
                    <FontAwesomeIcon icon={faPlus} />
                  )}
                </Button>
              </Col>
            )}
          </Row>
          <Row>
            <Col sm="12">
              <div className="chip-wrapper">
                <RenderSkill skills={skills} count={count} />
                <ShowMoreSkills
                  skills={skills}
                  count={count}
                  setCount={setCount}
                />
              </div>
            </Col>
          </Row>

          {!isLoggedinUser && isEmpty(currentUser) && (
            <Row className="py-5 my-4">
              <Col xs="12">
                <p className="text-primary font-weight-bold font-italic">
                  {" "}
                  Please Sign In to access the full profile.
                </p>
                <Button color="primary" onClick={() => history.push("/login")}>
                  {"Login"}
                </Button>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
*/}
      <RenderSkillsAndExpertiseForm
        isOpen={isOpen}
        toggle={toggle}
        skills={skills}
        setPractitioner={setPractitioner}
        practitioner={practitioner}
        isAdmin={isAdmin}
      />
    </>
  );
};
