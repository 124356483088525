import React from 'react'
import { useState, useEffect } from 'react'
import FormSteps from './FormSteps'
import MedicalHistory from './MedicalHistory'
import PersonalDetails from './PersonalDetails'
import secondOpinionImg from 'assets/images/second-opi-circle-img.png'
import UploadDocuments from './UploadDocuments'
import Payment from './Payment'
import secondOpinionright from '../../../../assets/images/second-opinion.png'



export default function SecondOpinionForm() {
  const [formSteps, setFormSteps] = useState([
    { name: "Personal Details", status: "In Progress", active: true, icon: "icon-personal-tab", current: true },
    { name: "Medical History", status: "", active: false, icon: "icon-medical-history-tab", current: false },
    { name: "Upload Documents", status: "", active: false, icon: "icon-upload-tab", current: false },
    { name: "Payment", status: "", active: false, icon: "icon-payment-tab", current: false },
  ]);

  const [opinionId, setOpinionId] = useState("");

  function handleStepSwitch(index) {
    setFormSteps(prevItems => {
      return prevItems.map((item, i) => {
        if (i <= index) {
          if (i === index) {
            return { ...item, active: true, current: true };
          } else {
            return { ...item, active: true, current: false };
          }

        } else {
          return { ...item, active: false, current: false };
        }
      })
    })
  }

  return (
    <div className="nexogic-second-option container-fluid">
      <div className="row">
        <div className="col-lg-9 nexogic-second-option-left">
          <div className="card ">

            <div className="row nexogic-form-header">
              <div className="col-lg-8">
                <h2 className="nexogic-form-title mb-2">Second Opinion Service</h2>
                <p className='nexogic-form-title-sub'>Medical consultation leader Providing online consultation for all areas of medicine </p>
              </div>
              <div className="col-lg-4 graphic">
                <img src={secondOpinionImg} alt="Image" width="100%" />
              </div>
            </div>

            <div className='card-body pb-0'>
              <div className="nexogic-second-option-form-wrap">
                <FormSteps data={formSteps} stepSwitch={handleStepSwitch} />
                { /* Personal form */}
                {formSteps[0].current &&
                  <PersonalDetails setOpinionId={setOpinionId} nextClick={() => handleStepSwitch(1)} handleStepSwitch={handleStepSwitch} />
                }
                { /* Medical form */}
                {formSteps[1].current &&
                  <MedicalHistory opinionId={opinionId} backClick={() => handleStepSwitch(0)} nextClick={() => handleStepSwitch(2)} handleStepSwitch={handleStepSwitch} />
                }
                {formSteps[2].current &&
                  <UploadDocuments opinionId={opinionId} backClick={() => handleStepSwitch(1)} nextClick={() => handleStepSwitch(3)} />
                }
                {formSteps[3].current &&
                  <Payment backClick={() => handleStepSwitch(2)} />
                }
              </div>
            </div>
          </div>

        </div>
        <div className="col-lg-3">
          <div className="nexogic-widget-sidebar-card card border-0">
            <h3 className="card-title h2">Second Opinion</h3>
            <div className="card-body">
              <ul className="nexogic-lists nexogic-custom-scroll">
                <li>
                  <img src={secondOpinionright} alt="" className="img-fluid" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>




    </div>

  )
}
