import { PractitionerService } from "utils/PractitionerService";
import { useState, useEffect } from "react";
import { Fade, Modal, ModalBody, ModalHeader } from "reactstrap";
import pdf_icon from "assets/images/pdf_icon.png";
import image_icon from "assets/images/image_icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import CommonLoader from "common/CommonLoader";
import swal from "sweetalert";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import { MedialService } from "utils/MediaService";
import { withAlert } from "react-alert";
import { FAILED_TO_DELETE_DATA, UserStatusConstants } from "utils/Constants";
import { isAdmin } from "utils/UserHelper";
import del_red_icon from "assets/images/del-red-icon.png";

const DocumentInfo = ({ guid, item, index, extension, fileName, getDocumentData, alert, isLoggedinUser, currentUser }) => {
    const ls_app_val = localStorage.getItem('app_status') || null;
    const [file, setFile] = useState(null);
    const [headers, setHeaders] = useState({});
    const [documentData, setDocumentData] = useState(null);
    const [documentType, setDocumentType] = useState(null);
    const [modal, setModal] = useState(false);
    const toggleForm = () => setModal(!modal);
    const [isPDF, setIsPDF] = useState(false);
    const [url, setUrl] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const { data, headers } = await PractitionerService.displayPractionerDocument(guid, item.id);
            setFile(data);
            setHeaders(headers);

            const createContentType = headers["content-type"] === "application/octet-stream"
                ? "application/pdf"
                : headers["content-type"];
            setIsPDF(createContentType === "application/pdf");

            if (createContentType === "application/pdf") {
                const blob = new Blob([data], { type: createContentType });
                const url = URL.createObjectURL(blob); // Create a URL for the Blob object
                setUrl(url); // Set the URL to the state
            }
            let generateURL = `data:${createContentType};base64,${Buffer.from(data).toString("base64")}`;
            setDocumentData(generateURL);
            let docArray = [data, createContentType];
            setDocumentType(docArray);
        };

        fetchData();
    }, [guid, item.id]);


    const getPractionerDocument = async () => {
        try {
            if (ls_app_val === 'inapp') {
                handleDownload();
                return;
            }
            toggleForm();
        } catch (error) {
            console.error("Error retrieving response document:", error);
        }
    };

    // const onOpenBase64 = (base64URL) => {
    //     try {
    //         const iframe = document.getElementById('pdfViewer');
    //         if (iframe) {
    //             iframe.src = base64URL;
    //         } else {
    //             console.error("PDF viewer iframe not found");
    //         }
    //     } catch (error) {
    //         console.error("Error opening base64 URL:", error);
    //     }
    // };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = documentData;
        let ext = documentType[1].split('/').pop();
        link.download = 'reports.' + ext;
        link.target = '_self';
        link.rel = 'noopener noreferrer';
        link.click();
    };

    function getDocIcon(extension) {
        switch (extension) {
            case 'pdf':
                return pdf_icon;
            default:
                return image_icon;
        }
    }

    const handleDocDelete = (id) => {
        swal("Are you sure you want to delete this Document?", {
            buttons: ["cancel", "yes"],
        })
            .then((yesRemove) => {
                if (yesRemove)
                    PractitionerService.deleteDocumentsProfile(guid, id)
                        .then((response) => {
                            const { status } = response;
                            if (status === 204) {
                                showSuccessMessage("Document deleted successfully.");
                                getDocumentData(guid);
                            }
                            else {
                                showErrorMessage(FAILED_TO_DELETE_DATA);
                            }
                        })
            })
    }

    return (
        <>
            <li className="list-group-item" key={`docs_${index}`}>
                <div className="row g-0 align-center">
                    {file ?
                        <>
                            <div className="col-auto">
                                <img src={getDocIcon(extension)} alt="" style={{ height: '50px' }} />
                            </div>
                            <div className="col">
                                <h3 className="card-title d-flex">
                                    <a href="#!" onClick={getPractionerDocument}>
                                        {item.documentCategory} - {fileName}
                                    </a>
                                    {(isAdmin() || (isLoggedinUser && currentUser.status != UserStatusConstants.APPROVED)) &&
                                        <span className="ml-4 nex-del-btn ml-auto" onClick={() => handleDocDelete(item?.id)}><img width={18} src={del_red_icon} alt="" /></span>
                                    }
                                </h3>
                            </div>
                        </>
                        :
                        <div className="text-center nexogic-loader-center">
                            <CommonLoader />
                        </div>
                    }
                </div>
            </li>
            <Modal isOpen={modal} toggle={toggleForm} backdrop="static" scrollable centered>
                <ModalHeader toggle={toggleForm}><span style={{ color: "gray" }}>Document Viewer</span></ModalHeader>
                <ModalBody>
                    {documentData &&
                        <>
                            {isPDF
                                ?
                                <div>
                                    <div className="modalbody" style={{ height: "800px" }}>
                                        <embed src={url} style={{ width: "100%", height: "100%" }} />
                                    </div>
                                    <div className="downloadbtn">
                                        <FontAwesomeIcon color="#0087b9" size="xl" style={{ cursor: "pointer" }} icon={faDownload} onClick={handleDownload} />
                                    </div>
                                </div>
                                :
                                <>
                                    <div className="modalbody">
                                        <img src={documentData} alt="document preview" />
                                    </div>
                                    <div className="downloadbtn">
                                        <FontAwesomeIcon color="#0087b9" size="xl" style={{ cursor: "pointer" }} icon={faDownload} onClick={handleDownload} />
                                    </div>
                                </>
                            }
                        </>
                    }
                </ModalBody>
            </Modal >
        </>
    )
}

export default withAlert()(DocumentInfo);
