import { faCheckCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import pencilIcon from "assets/images/pencil_icon.png";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import {
  profileAddEvent,
  updateProfileCompletness
} from "redux-helpers/ActionCreator";
import { UserStatusConstants } from "utils/Constants";
import { getEmptySectionShowHide } from "utils/Utils";
import RenderServicesOfferedForm from "./RenderServiceOfferedForm";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import blue_bullets from "assets/images/blue-bullets.png";
import plusIcon from "assets/images/plus-icon-lg.png";
import editIcon from "assets/images/edit-blue-icon.png";
import NoData from "../NoData";

const RenderService = (props) => {
  const { services, count } = props;
  if (services && services.length) {
    return services.slice(0, count).map((service, index) => {
      if (!!service) {
        return (
          <li className="list-group-item" key={service}>
            <div className="row g-0">
              <div className="col-auto"><img src={blue_bullets} alt="" /></div>
              <div className="col">
                <h3 className="card-title fw-semibold">{service}</h3>
              </div>
              {/** <FontAwesomeIcon icon={faCheckCircle} className="icon-check" />*/}
            </div>

          </li>
        );
      }
    });
  }
  return "";
};

const ShowMoreServices = (props) => {
  const { services, count, setCount } = props;
  if (services && services.length > count) {
    const remainingCount = services.length - count;
    return (
      <Button
        color="link"
        onClick={() => setCount(services.length)}
        className="readmore"
      >
        {`+ ${remainingCount} more`}
      </Button>
    );
  }
  return null;
};

export const ServicesOffered = (props) => {
  const [pannel_on, setPannel_on] = useState(true);
  const history = useHistory();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const eventName = useSelector((state) => state.profileComplete.eventName);
  const dispatch = useDispatch();
  const { practitioner, isLoggedinUser, setPractitioner, isAdmin, setShowServicesOnTabScroll } = props;

  const [count, setCount] = useState(14);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const { services, guid } = practitioner;

  useEffect(() => {
    dispatch(updateProfileCompletness(14, !!services.length));
    if (services.length > 0 || isLoggedinUser || isAdmin) {
      setShowServicesOnTabScroll(true);
    }else{
      setShowServicesOnTabScroll(false);
    }
  }, [services]);

  useEffect(() => {
    if (eventName === "ADD_SERVICE") {
      toggle();
      dispatch(profileAddEvent(null));
    }
  }, [eventName]);

  const isNotEmpty = useMemo(() => {
    return services && services.length > 0;
  }, [services]);

  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );

  if (
    getEmptySectionShowHide(
      services,
      isAdmin,
      currentUser,
      globalProfileInfo,
      guid
    )
  )
    return null;

  return (
    <>
      <Card className={`nexogic-card-color-header ${pannel_on ? 'expanded' : ''}`} id="serviceSection">
        <div className="card-header"><h2>Services</h2>


          {(isAdmin ||
            (isLoggedinUser &&
              currentUser.status === UserStatusConstants.APPROVED)) && (

              <Button
                id="nex-ps-serviced-offer-add-btn"
                color="link"
                className="add-button"
                onClick={toggle}
              >
                {isNotEmpty ? (
                  <>{/** 
                    <img src={pencilIcon} alt="" />*/}
                    <img src={editIcon} alt="" />
                  </>
                ) : (
                  <>{/** 
                    <FontAwesomeIcon icon={faPlus} />*/}
                    <img src={plusIcon} alt="" />
                  </>
                )}
              </Button>

            )}

          <button className="collapse-btn" type="button" onClick={() => setPannel_on(!pannel_on)}>
            <img src={pannel_arrow} alt="" />
          </button>
        </div>
        <div className={`collapse-div`}>
          <CardBody className={services && services.length > 0 ? "" : "align-items-center"}>
            {services && services.length
              ? ""
              : (isLoggedinUser || isAdmin) &&
              currentUser.status === UserStatusConstants.APPROVED && (

                <Button
                  className="readmore"
                  color="link"
                  onClick={toggle}
                >
                  {"Add services"}
                </Button>

              )}
            <ul className="list-group list-group-flush">
              <RenderService services={services} count={count} />
              <ShowMoreServices
                services={services}
                count={count}
                setCount={setCount}
              />
            </ul>


            {!isLoggedinUser && isEmpty(currentUser) && (
              <Row className="py-5 my-4">
                <Col xs="12">
                  <p className="text-primary font-weight-bold font-italic">
                    {" "}
                    Please Sign In to access the full profile.
                  </p>
                  <Button color="primary" onClick={() => history.push("/login")}>
                    {"Login"}
                  </Button>
                </Col>
              </Row>
            )}
            {!isNotEmpty &&
        <NoData>No Data</NoData>
      }
          </CardBody>
        </div>
      </Card>
      <RenderServicesOfferedForm
        isOpen={isOpen}
        toggle={toggle}
        services={services}
        setPractitioner={setPractitioner}
        practitioner={practitioner}
        isAdmin={isAdmin}
      />
      
    </>
  );
};
