import React from "react";
import formHeaderImg from "../../../assets/images/second-opi-circle-img.png";
import { useState, useRef } from "react";
import { Button, Card, CardBody, Col, FormGroup, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import PrimarySpecialitiesReferralTypeheadField from "components/forms/formikFields/PrimarySpecialitiesReferralTypeheadField";
import { useEffect } from "react";
import { MetadataService } from "utils/MetadataService";
import FormikRadioField from "components/forms/formikFields/FormikRadioField";
import InstitutesReferralTypehead from "pages/Onboarding/components/FormComponents/InstitutesReferralTypehead";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import ReactQuill from "react-quill";
import SecondOpinionImg from "../../../assets/images/second-opinion.png";
import { isInstitutionAdmin, isPractitioner } from "utils/UserHelper";
import { PatientService } from "utils/PatientService";
import MyPatientsTypeaheadField from "components/forms/formikFields/MyPatientsTypeaheadField";
import AssociatedPractitionersTypeaheadField from "components/forms/formikFields/AssociatedPractitionersTypeaheadField";
import { PractitionerService } from "utils/PractitionerService";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import CommonLoader from "common/CommonLoader";
// import ToolTip from "common/ToolTip";
import ToolTip from "common/ToolTipPopup/ToolTip";
import BackIcon from "assets/images/backIcon.png";

import {
  makeFullNameWithAddress,
  makeFullNameWithAddressWhenAddressIsNested,
} from "utils/Utils";
import './styles.css';
import { InstitutionServices } from "utils/InstitutionServices";
// import PractitionerSearchInputForRefer from "components/forms/formikFields/PractitionerSearchInputForRefer";
import { ReferralService } from "utils/ReferralService";
import { FAILTOSAVERECOED, FAILTOGETRECOED, Patient_Type } from "utils/Constants";
import { withAlert } from "react-alert";
import AddPatientForm from "../Components/AddPatient/Components/AddPatientForm";
import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import AsyncGlobalSearchInput from "components/forms/formikFields/AsyncGlobalSearchInput";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
// import { useAlert } from 'react-alert';

const initialValues = {
  patientType: "DOMESTIC",
  patient: [],
  referByInstitution: "",
  specialityRequested: [],
  // specialityRequested: {name:''},
  // referToInstitution: {},
  referToInstitution: [],
  referToPractitioner: [],
  typeOfDisease: "",
  diagnosis: "",
  reasonOfRefer: "",
  additionalInfo: "",
  treatmentInfo: "",
  // btnValue:"DRAFT",
};


const validationSchema = Yup.object().shape({
  patientType: Yup.string().required("Patient type is required"),
  patient: Yup.array().min(1, "This field is required"),
  // primarySpeciality: Yup.array().min(1, "This field is required"),
  specialityRequested: Yup.array().min(1, "This field is required"),
  // referToInstitution: Yup.array().min(1, "This field is required"),
  referToInstitution: Yup.lazy((value) => {
    if (value.length === 0 || !value.guid) {
      if (value.length === 0 && !value.guid) {
        return Yup.array().test(
          "custom",
          "This field is required. You must select from options",
          (value) => {
            return false
          }
        );
      } else {
        return Yup.object().test(
          "custom",
          "This field is required. You must select from options",
          (value) => {
            return false
          }
        );
      }
    }
    return Yup.mixed();
  }),
  referByInstitution: Yup.string()
    .required("This field is required"),
});


// const ReferPatientNew = (props) =>{
export default withAlert()(function ReferPatientNew(props) {
  const [selectedReferToPractitioner, setSelectedReferToPractitioner] = useState(null);
  const [showAssociatedPractitioners, setShowAssociatedPractitioners] = useState(false);
  const [btnStatus, setBtnStatus] = useState('DRAFT');
  const [initialValuesForm, setInitialValuesForm] = useState(initialValues);
  const [fetchLoading, setfetchLoading] = useState(false);
  const [loading, setloading] = useState(false);
  // const { alert } = props;
  // const alert = useAlert();
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const patientData = location.state;

  const getReferralDetails = (patientId) => {
    if (patientId) {
      (async () => {
        setfetchLoading(true);
        ReferralService.getReferralById(patientId)
          .then(async (response) => {
            if (response.status === 200 || response.status === 201) {
              let { data } = response;
              return data;
            }
            else if (response.status === 404) {
              showErrorMessage(FAILTOGETRECOED)
              history.goBack();
            }
            return null;
          })
          .then((response) => {
            if (response) {
              const {
                id,
                status,
                patient,
                specialityRequested,
                patientType,
                referralCaseNo,
                referralCaseDetails,
                diagnosis,
                additionalInformation,
                referralReason,
                treatmentMedications,
                referredToPhysician,
                referredToInstitution,
                referredByInstitution,
                referredByPhysician,
              } = response;
              setInitialValuesForm((preState) => ({
                ...preState,
                ...(referredByInstitution && {
                  referByInstitution: referredByInstitution?.guid
                }),
                ...(patientType && {
                  patientType: patientType,
                }),
                ...(diagnosis && {
                  diagnosis: diagnosis,
                }),
                ...(referralReason && {
                  reasonOfRefer: referralReason,
                }),
                ...(additionalInformation && {
                  additionalInfo: additionalInformation,
                }),
                ...(treatmentMedications && {
                  treatmentInfo: treatmentMedications,
                }),
                ...(patient && {
                  patient: [patient],
                }),
                ...(specialityRequested && {
                  specialityRequested: [specialityRequested],
                }),
                ...(referralCaseNo && {
                  referralCaseNo: referralCaseNo,
                }),
                ...(referredToInstitution && {
                  referToInstitution: referredToInstitution,
                }),
                ...(referredToPhysician && {
                  referToPractitioner: [referredToPhysician],
                }),
                ...(referralCaseDetails && {
                  referralCaseDetails: referralCaseDetails,
                }),
                ...(status && {
                  status: status,
                }),
                ...(id && {
                  id: id,
                }),

              }));
              setfetchLoading(false);
            }
          })
          .catch((error) => {
            setfetchLoading(false);
            console.log("Error while getting connection status: ", error);
          });
      })();
    }
  };

  useEffect(() => {
    if (params.id) {
      getReferralDetails(params.id);
    }
  }, [params.id]);

  useEffect(() => {
    if (patientData?.id) {
      setInitialValuesForm((preState) => ({
        ...preState,
        ...(patientData && {
          patient: [patientData],
        })
      }));
    }
  }, [patientData?.id]);

  const updateReferral = (values) => {
    let payload = {
      status: btnStatus,
      patient: {
        id: values?.patient[0]?.id,
      },
      specialityRequested: values?.specialityRequested[0],
      patientType: values?.patientType,
      // referredToPhysician: {
      //   profileGuid: values?.referToPractitioner[0]?.profileGuid
      // },
      referredToPhysician: values?.referToPractitioner[0]?.profileGuid ? { profileGuid: values?.referToPractitioner[0]?.profileGuid } : null,
      referredToInstitution: {
        guid: values?.referToInstitution?.guid,
      },
      referredByInstitution: {
        guid: values?.referByInstitution,
      },
      diagnosis: values?.diagnosis,
      additionalInformation: values?.additionalInfo,
      referralReason: values?.reasonOfRefer,
      treatmentMedications: values?.treatmentInfo,
    };

    ReferralService.updateReferral(values.id, payload).then((response) => {
      const { status } = response;
      if (status === 200) {
        showSuccessMessage("Referral details updated Successfully");
        history.push("/referrals/sent-referrals");
      } else {
        showErrorMessage(FAILTOSAVERECOED);
      }
    });
  }

  const submitReferral = (values) => {
    let payload = {
      status: btnStatus,
      patient: {
        id: values?.patient[0]?.id,
      },
      specialityRequested: values?.specialityRequested[0],
      patientType: values?.patientType,
      referredToPhysician: values?.referToPractitioner[0]?.profileGuid ? { profileGuid: values?.referToPractitioner[0]?.profileGuid } : null,
      referredToInstitution: {
        guid: values?.referToInstitution?.guid,
      },
      referredByInstitution: {
        guid: values?.referByInstitution,
      },
      diagnosis: values?.diagnosis,
      additionalInformation: values?.additionalInfo,
      referralReason: values?.reasonOfRefer,
      treatmentMedications: values?.treatmentInfo,
    };

    ReferralService.addReferral(payload).then((response) => {
      const { status, data } = response;
      if (status === 201) {
        showSuccessMessage("Referral created Successfully");
        history.push("/referrals/sent-referrals");
      } else {
        showErrorMessage(FAILTOSAVERECOED);
      }
    });
  };

  const handleBack = () => {
    setTimeout(() => {
      history.push('/referrals/sent-referrals');
    }, 0);
  }

  const addNewPatient = () => {
    history.push('/referrals/add-patient');
  }

  return (
    <div className="nexogic-reffer-patient-w">
      <div className="routing-header d-flex justify-content-between align-items-center mb-3 nex-app-patient-ref-title">
        <div className="nexogic-top-section-title">
          <span><Link to="" onClick={handleBack}><img src={BackIcon} alt="back" /></Link></span>
          <h2 className="h2">Patient referral
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-8 col-lg-9 nex-app-no-pad">
          <div className="card">
            <div className="row nexogic-form-header">
              <div className="col-lg-8">
                <h2 className="nexogic-form-title mb-2">Refer a Patient</h2>
                <p className="nexogic-form-title-sub">
                  Please fill out below form details to refer your Patient.
                </p>
              </div>
              <div className="col-lg-4 graphic">
                <img src={formHeaderImg} alt="Image" width="100%" />
              </div>
            </div>
            <div className="p-3">
              <Formik
                enableReinitialize={true}
                validationSchema={validationSchema}
                initialValues={initialValuesForm}
                onSubmit={(values, { setSubmitting }) => {
                  if (values?.referralCaseNo) {
                    updateReferral(values)
                  } else {
                    submitReferral(values)
                  }
                  // submitReferral(values);
                }}
              >
                {(formProps) => {
                  return (
                    // formProps?.values?.status === 'DRAFT' ?                     
                    <Row>
                      <Col md={12} className="nex-app-no-pad">
                        <Card className="form-step1-wrapper bg-white p-0 border-0">
                          <CardBody className="formWrap px-1">
                            {fetchLoading && (
                              <div className="loaderWrap">
                                <CommonLoader />
                              </div>
                            )}
                            <RenderReferralForm
                              {...props}
                              editFlag={params.id ?? ''}
                              {...formProps}
                              loading={loading}
                              fetchLoading={fetchLoading}
                              addNewPatient={addNewPatient}
                              showAssociatedPractitioners={showAssociatedPractitioners}
                              setShowAssociatedPractitioners={setShowAssociatedPractitioners}
                              setSelectedReferToPractitioner={setSelectedReferToPractitioner}
                              setBtnStatus={setBtnStatus}
                            />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>

        <div className="col-md-4 col-lg-3 nex-app-no-pad">
          <div className="nexogic-widget-sidebar-card card border-0 nex-app-block">
            <h3 className="card-title h2 nex-app-no-pad">Second Opinion</h3>
            <div className="card-body">
              <ul className="nexogic-lists nexogic-custom-scroll">
                <li>
                  <img src={SecondOpinionImg} alt="" className="img-fluid" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

// export default ReferPatientNew;
// export default withAlert()(ReferPatientNew);

export const RenderReferralForm = (props) => {

  /* patient modal hooks and functions */
  const [patientModal, setPatientModal] = useState(false);
  const patientModalToggle = () => setPatientModal(!patientModal);
  const [errorMsg, setErrorMessage] = useState("");
  const typeaheadRef = useRef(null);
  const globalSearchTypek = useSelector(
    (state) => state.GlobalSearchTypeReducer.globalSearchType
  );

  const {
    handleSubmit,
    initialValues,
    setFieldValue,
    showAssociatedPractitioners,
    setShowAssociatedPractitioners,
    setSelectedReferToPractitioner,
    values,
    setBtnStatus,
    editFlag,
    fetchLoading
  } = props;
  // const patientRef = useRef();
  const tooltipRefs = {
    patientType: useRef(null),
    patient: useRef(null),
    referByInstitution: useRef(null),
    specialityRequested: useRef(null),
    referToInstitution: useRef([]),
    referToPractitioner: useRef(null),
    diagnosis: useRef(null),
    reasonOfRefer: useRef(null),
    treatmentInfo: useRef(null),
    additionalInfo: useRef(null),
  };
  // const inputRef = tooltipRefs[0];
  // const tooltipRefs = Array.from({ length: 10 }, () => useRef(null));

  // const patientTypeRef, patient1Ref, referByInstitutionRef, specialityRequestedRef,referToInstitutionRef,referToPractitionerRef
  const [tPatient, setTPatient] = useState("");
  const [speciality, setSpeciality] = useState([]);
  const [myPatients, setMyPatients] = useState([]);
  const [practitioners, setPractitioners] = useState([]);
  const [hospitalList, setHospitalList] = useState([]);
  const [addPatient, setAddPatient] = useState(false);
  const isPractitionerUser = useMemo(() => isPractitioner());
  const currentUser = useSelector((state) => state.auth.currentUser);
  const isInstitutionAdminUser = useMemo(() => isInstitutionAdmin());

  const selectdPatient = (patientName) => {
    if (patientName.length >= 3)
      setTPatient(patientName);
  };

  const getPrimarySpeciality = () => {
    MetadataService.getPrimarySpecialities()
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setSpeciality(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getPrimarySpeciality error", error);
      });
  };

  const getMyPatientDetails = (patientQuery) => {
    let searchedPatient = '';
    let squery = patientQuery ?? '';
    searchedPatient = `sq=ea=="*${squery}*",fn=="*${squery}*",ln=="*${squery}*"`
    PatientService.getMyPatientsList(searchedPatient)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setMyPatients(response.data);
        }
      })
      .catch((error) => {
        console.log("PatientService.getMyPatientDetails error", error);
      });
  };


  const getPractitioners = async (inst) => {
    let sqDefault = `sq=st=="Approved";re==true`;
    let qSearch = '';
    if (inst !== null && inst !== undefined && inst.length !== 0 && inst.name !== '') {
      qSearch = `w_for=${inst?.guid}&sq=st=="Approved";re==true`
    } else {
      qSearch = sqDefault
    }
    if (values.specialityRequested.length !== 0) {
      qSearch = qSearch + `;ps=='${values.specialityRequested[0]}'`
    }
    if (qSearch !== sqDefault) {
      await PractitionerService.getPractitionersListToInstitutionSpeciality(qSearch
      ).then((response) => {
        const { status, data } = response;
        const filteredPractitionersData = data.filter(item => item.profileGuid !== currentUser?.guid);
        if (status === 200) {
          setPractitioners(() => filteredPractitionersData);
        } else {
          console.log("Something went wrong");
        }
      });
    }
  };

  const getHospitalList = () => {
    if (isPractitionerUser)
      PractitionerService.listExperiences(currentUser?.guid)
        .then((response) => {
          if (response.status === 200) {
            setHospitalList(
              [...response.data]
                .map((node, index) => {
                  return {
                    ...node,
                    institutionName: makeFullNameWithAddressWhenAddressIsNested(
                      node?.institutionDetail
                    ),
                    name: node?.institutionDetail?.name,
                    institutionId: node?.institutionDetail?.guid,
                    id: index
                  };
                })
                .filter((nodeItem) => nodeItem?.presentlyWorking)
            );
          }
        })
        .catch((err) => {
          console.log(err.message);
          showErrorMessage(err.message);
        });
    if (isInstitutionAdminUser) {
      InstitutionServices.getAssociatedInstitutionsForCurrentUser()
        .then((response) => {
          if (response.status === 200) {
            setHospitalList(
              [...response.data].map((node, index) => {
                return {
                  ...node,
                  institutionName: makeFullNameWithAddress(node),
                  name: node?.name,
                  institutionId: node?.guid,
                  id: index
                };
              })
            );
          }
        })
        .catch((err) => {
          console.log(err.message);
          showErrorMessage(err.message);
        });
    }
  };
  // const addANewPatient = () =>{
  //   history.push('/referrals/add-patient');
  // }
  useEffect(() => {
    getPrimarySpeciality();
    // getMyPatients();
    getHospitalList();
  }, []);

  useEffect(() => {
    getMyPatientDetails(tPatient);
  }, [tPatient]);

  useEffect(() => {
    if ((values.referToInstitution?.name?.length >= 3) && (values?.specialityRequested?.length > 0)) {
      getPractitioners(values.referToInstitution);
      // setShowAssociatedPractitioners(true);
    }
    if ((values.referToInstitution?.name === '') && (values?.specialityRequested?.length === 0)) {
      setFieldValue(`referToPractitioner`, []);
      setShowAssociatedPractitioners(false);
    }
  }, [values.referToInstitution, values.specialityRequested])
  return (
    <>
      <form className="my-form" onSubmit={handleSubmit}>
        <div className="nexogic-light-border-fields">
          <Row>
            <Col lg={6} md={12} sm={12}>
              <Label className="d-block">Patient Type
                <ToolTip id="patientType" place="right" targetRef={tooltipRefs.patientType}>Please Select Patient Type</ToolTip>
              </Label>
              <Field
                id="patient_type"
                name="patientType"
                type="text"
                component={FormikSelectField}
                inputprops={{
                  name: `patientType`,
                  options: Patient_Type,
                  defaultOption: "Select",
                  keys: {
                    id: "value",
                    label: "label",
                  },
                }}
              />
            </Col>
            <Col lg={6} md={12} sm={12}>
              <div className="form-group" id="nex-rpn-patient-name-inp">
                <Label className="d-block">Patient Name <ToolTip id="patient" place="right" targetRef={tooltipRefs.patient}>Select a Patient from your patient list</ToolTip></Label>
                <Field
                  id="patient-name"
                  name="patient"
                  type="text"
                  component={MyPatientsTypeaheadField}
                  placeholder="Select a Patient"
                  value={initialValues.patient}
                  spellCheck={false}
                  patients={myPatients}
                  activeFlag={editFlag === "" ? false : true}
                  autoComplete="patient"
                  selectdPatient={selectdPatient}
                  setAddPatient={setAddPatient}
                />{/*props.addNewPatient*/}
                {addPatient ? <button className="add-patient-btn" onClick={props.addNewPatient}>Add Patient</button> : ''}
              </div>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <Label className="d-block">Refer by Institution <ToolTip id="referByInstitution" place="right" targetRef={tooltipRefs.referByInstitution}>Select Refer by Institution</ToolTip></Label>
              <Field
                name="referByInstitution"
                id="referByInstitution"
                type="text"
                // label="Refer by Institution"
                component={FormikSelectField}
                inputprops={{
                  name: `referByInstitution`,
                  options: hospitalList,
                  defaultOption: "Select a Hospital/Clinic",
                  // defaultOption: hospitalList[0]?.guid,
                  keys: {
                    id: "institutionId",
                    label: "institutionName",
                  },
                }}
              />
            </Col>
            <Col lg={6} md={12} sm={12} id="nex-rpn-speciality-requested-inp">
              <Label className="d-block">Speciality Requested <ToolTip id="specialityRequested" place="right" targetRef={tooltipRefs.specialityRequested}>Search or Select a Primary Speciality</ToolTip></Label>
              <div className="form-group">
                <Field
                  id="specialityRequested"
                  name="specialityRequested"
                  // label="Speciality Requested"
                  type="text"
                  component={PrimarySpecialitiesReferralTypeheadField}
                  placeholder="Select Speciality"
                  value={initialValues.specialityRequested}
                  spellCheck={false}
                  specialit={speciality}
                  autoComplete="primarySpeciality"
                  // formValues={values}
                  getPractitionersList={getPractitioners}
                  // activeFlag={showAssociatedPractitioners ? false : true}
                  setShowAssociatedPractitioners={setShowAssociatedPractitioners}
                />
              </div>
            </Col>
            <Col lg={6} md={12} sm={12} className="nexogic-no-label" id="nex-refer-to-institution">
              <Label className="d-block">Refer to Institution <ToolTip id="referToInstitution" place="right" targetRef={tooltipRefs.referToInstitution}>Search or select the desired Institution</ToolTip></Label>
              <Field
                className="nex-refer-to-institution"
                id="referToInstitution"
                name="referToInstitution"
                // label="Refer to Institution"
                type="text"
                component={InstitutesReferralTypehead}
                value={initialValues.referToInstitution}
                placeholder="Institution"
                getSelectedInstitution={getPractitioners}
                setShowAssociatedPractitioners={setShowAssociatedPractitioners}
                selectedReferByInstitution={values?.referByInstitution}
              />
            </Col>
            <Col lg={6} md={12} sm={12} id="nex-rpn-refer-to-practitioner">
              {/* {showAssociatedPractitioners ? (
              <> */}
              <Label className="d-block mb-2">Refer to Practitioner <ToolTip id="referToPractitioner" place="right" targetRef={tooltipRefs.referToPractitioner}>Select the Practitioner to whom you want to refer</ToolTip></Label>
              <div className="form-group">
                <Field
                  className="nex-refer-to-practitioner"
                  id="referToPractitioner"
                  name="referToPractitioner"
                  type="text"
                  practitioners={practitioners}
                  activeFlag={showAssociatedPractitioners || editFlag !== "" ? false : true}
                  component={AssociatedPractitionersTypeaheadField}
                  placeholder="Select Practitioner"
                  value={initialValues.referToPractitioner}
                />
              </div>
            </Col>
            <Col lg={12} md={12} sm={12} className="mb-0" id="nex-rpn-diagnosis-inp-w">
              <label htmlFor="diagnosis">Indication or Diagnosis<ToolTip id="diagnosis" place="right" targetRef={tooltipRefs.diagnosis}>Explain in brief your indications or diagnosis</ToolTip></label>
              <ReactQuill
                className="nex-rpn-diagnosis-inp"
                theme="snow"
                id="refp_diagnosis"
                onChange={(html) => {
                  setFieldValue("diagnosis", html, true);
                }}
                name="diagnosis"
                value={values.diagnosis || ""}
                placeholder="Enter Indication or Diagnosis ..."
              />
            </Col>
            <Col lg={12} md={12} sm={12} className="mt-3" id="nex-rpn-refp_reason-w">
              <label htmlFor="reasonOfRefer">Reason<ToolTip id="reasonOfRefer" place="right" targetRef={tooltipRefs.reasonOfRefer}>Explain in brief of your reason of refer</ToolTip></label>
              <ReactQuill
                className="nex-rpn-refp_reason"
                theme="snow"
                id="refp_reason"
                onChange={(html) => {
                  setFieldValue("reasonOfRefer", html, true);
                }}
                name="reasonOfRefer"
                value={values.reasonOfRefer || ""}
                placeholder="Enter your Reason For Refer..."
              />
            </Col>
            <Col lg={12} md={12} sm={12} className="mt-3" id="nex-rpn-treatmentInfo">
              <label htmlFor="treatmentInfo">Treatment / Medications<ToolTip id="treatmentInfo" place="right" targetRef={tooltipRefs.treatmentInfo}>Explain in brief your Treatments and medications currently following or followed</ToolTip></label>
              <ReactQuill
                className="nex-rpn-treatmentInfo"
                theme="snow"
                id="treatment_info"
                onChange={(html) => {
                  setFieldValue("treatmentInfo", html, true);
                }}
                name="treatmentInfo"
                value={values.treatmentInfo || ""}
                placeholder="Enter Treatment / Medications..."
              />
            </Col>
            <Col lg={12} md={12} sm={12} className="mt-3" id="nex-rpn-additionalInfo">
              <label htmlFor="additionalInfo">Additional Information<ToolTip id="additionalInfo" place="right" targetRef={tooltipRefs.additionalInfo}>Any other information specific to Patient Treatment / Advice</ToolTip></label>
              <ReactQuill
                className="nex-rpn-additionalInfo"
                theme="snow"
                id="refp_info"
                onChange={(html) => {
                  setFieldValue("additionalInfo", html, true);
                }}
                name="additionalInfo"
                value={values.additionalInfo || ""}
                placeholder="Enter Additional Information..."
              />
            </Col>
          </Row>
          <Row form className="justify-content-end  pt-3 mx-0 action-group ">
            <Col xs="auto" className="px-0 nex-btn-full">
              <Button
                id="draft-btn-submit"
                className="nexogic_primary_button nex-app-no-mar nex-btn-full"
                color="primary"
                type="submit"
                onClick={() => setBtnStatus('DRAFT')}
              >
                Save in Draft
              </Button>
            </Col>
            <Col xs="auto" className="px-0 nex-btn-full">
              <Button
                id="send-btn-submit"
                className="nexogic_primary_button nex-app-no-mar nex-btn-full"
                color="primary"
                type="submit"
                onClick={() => setBtnStatus('SUBMITTED')}
              >
                Send Referral
              </Button>
            </Col>
          </Row>
        </div>
      </form>
      {/* add patient modal */}
      <Modal isOpen={patientModal} toggle={patientModalToggle} >
        <ModalHeader toggle={patientModalToggle}>Add Patient</ModalHeader>
        <ModalBody className="nexogic-modal-form-w nexogic-add-patient-modal">
          {errorMsg !== "" && <RenderErrorMessage errorMsg={errorMsg} />}
          <div className="row">
            <div className="col-lg-12">
              <div className="form-group nexogic-global-search-patient">
                <label>Search Patient</label>
                <AsyncGlobalSearchInput
                  type="search"
                  placeholder="Search..."
                  className="border-0"
                  //searchType={globalSearchTypek}
                  handleResponsiveModal={() => { console.log('') }}
                  typeaheadRef={typeaheadRef}
                />
              </div>
              <hr className="w-100 mb-3" />
            </div>
          </div>
          <AddPatientForm />
        </ModalBody>
      </Modal>
    </>
  );
};


