import React, { useMemo, useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Button, ButtonGroup, Nav, NavItem } from "reactstrap";
import { FAILTOSAVERECOED } from "utils/Constants";
import CommonLoader from "common/CommonLoader";
import notfoundImg from 'assets/images/not-found-img.png'
import { ReferralService } from "utils/ReferralService";
import MyReferralCard from "./MyReferralCard";
import patientsIcon from 'assets/images/profile-p.jpg';
import LoadMoreButton from "common/LoadMoreButton/LoadMoreButton";

const MyReferrals = (props) => {
    const { patient } = props;
    const [referrals, setReferrals] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pageNo, setPageNo] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);

    const getReceivedReferrals = (pageNo) => {
        return ReferralService.getMyReferrals(pageNo)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    const newReferrals = data.contents.filter(
                        (newReferral) =>
                            !referrals.some((existingReferral) => existingReferral.id === newReferral.id)
                    );
                    setReferrals((prevReferrals) => [...prevReferrals, ...newReferrals]);
                    setPageNo(pageNo + 1);
                    return data;
                } else {
                    console.log(FAILTOSAVERECOED);
                    return null;
                }
            })
            .catch((error) => {
                console.error("Error retrieving referrals:", error);
                return null;
            });
    };


    // Function to load more referrals on scroll
    const fetchMoreReferrals = () => {
        setIsLoadingMore(true);
        getReceivedReferrals(pageNo)
            .then((data) => {
                if (data?.currentPage < (data?.totalPages - 1))
                    setHasMore(true);
                else
                    setHasMore(false);
                setIsLoadingMore(false);
                if (data && data.contents.length > 0) {
                    const filteredReferrals = data.contents.filter(referral => referral.status !== 'DRAFT');
                    setReferrals((prevReferrals) => [...prevReferrals, ...filteredReferrals]);
                    setPageNo(pageNo + 1);
                } else {
                    setHasMore(false);
                }
            })
            .catch((error) => {
                console.error("Error fetching more referrals:", error);
                setIsLoadingMore(false);
            });
    };


    useEffect(() => {
        setIsLoading(true);
        // Fetch initial referrals when component mounts
        const fetchReferrals = async () => {
            const data = await getReceivedReferrals(0);
            if (data && data.contents.length > 0) {

                const filteredReferrals = data.contents.filter(referral => referral.status !== 'DRAFT');

                setReferrals(filteredReferrals);
                setPageNo(pageNo + 1);
                setIsLoading(false);
                if (data.contents.length < 10) {
                    setHasMore(false);
                }
            } else {
                setReferrals([]);
                setHasMore(false);
                setIsLoading(false);
            }
        };

        fetchReferrals();
    }, [patient]);


    return (
        <>
            <>
                <Row>
                    <Col lg={`${process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" ? 8 : 12}`} xl={`${process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" ? 9 : 12}`}>
                        <Card className="bg-transparent border-0">
                            <CardBody className="p-0">
                                <Row className="mb-5 nexogic-sidebar-cont-wrap">
                                    <Col lg={12} md={12} sm={12} className="nexogic-content-col ">
                                        {/*<h1 className="nexogic-page-title">My Referrals</h1>*/}
                                        {
                                            isLoading ?
                                                <div className="loading-spiner text-center  mt-2">
                                                    <CommonLoader />
                                                </div>
                                                :
                                                <div>
                                                    {referrals && referrals.length > 0 ?
                                                        <>
                                                            {referrals.map((referral, index) => (
                                                                <MyReferralCard key={index} referral={referral} />
                                                            ))}
                                                        </>
                                                        :
                                                        <div className="nexogic-not-found-card">
                                                            <div className="nexogic-not-found-img-w">
                                                                <img src={notfoundImg} alt="" />
                                                            </div>
                                                            <h2 className="card-title">Referral Not Found</h2>
                                                        </div>
                                                    }
                                                    {hasMore &&
                                                        <div className="text-center mt-3">
                                                            {isLoadingMore
                                                                ?
                                                                <CommonLoader style={{ width: "2rem", height: "2rem" }} />
                                                                :
                                                                <LoadMoreButton loadMore={fetchMoreReferrals} />
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
                        <Col lg={4} xl={3}>
                            <div className="nexogic-sidebar-stiky">
                                <div className="nexogic-widget-sidebar-card card nexogic-approved-physic">
                                    <h2 className="card-title h2 mb-2">Approved Physicians</h2>
                                    <div className="card-body">
                                        <div className="nexogic-custom-scroll">
                                            <div className="nexogic-list-card card">
                                                <div className="card-head">
                                                    <div className="card-thumb">
                                                        <img src={patientsIcon} alt="" className="card-img" />
                                                    </div>
                                                    <div className="info">
                                                        <h3 className="card-title">Dr. Anna Sotelo </h3>
                                                        <p className="card-text">MBBS, MS - Orthopedic Surgeon (Orthopedist)</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* row 1 */}
                                            <div className="nexogic-list-card card ">
                                                <div className="card-head">
                                                    <div className="card-thumb">
                                                        <img src={patientsIcon} alt="" className="card-img" />
                                                    </div>
                                                    <div className="info">
                                                        <h3 className="card-title">Dr. Anna Sotelo </h3>
                                                        <p className="card-text">MBBS, MS - Orthopedic Surgeon (Orthopedist)</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* row 2 */}
                                            <div className="nexogic-list-card card ">
                                                <div className="card-head">
                                                    <div className="card-thumb">
                                                        <img src={patientsIcon} alt="" className="card-img" />
                                                    </div>
                                                    <div className="info">
                                                        <h3 className="card-title">Dr. Anna Sotelo </h3>
                                                        <p className="card-text">MBBS, MS - Orthopedic Surgeon (Orthopedist)</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* row 3 */}
                                            <div className="nexogic-list-card card ">
                                                <div className="card-head">
                                                    <div className="card-thumb">
                                                        <img src={patientsIcon} alt="" className="card-img" />
                                                    </div>
                                                    <div className="info">
                                                        <h3 className="card-title">Dr. Anna Sotelo </h3>
                                                        <p className="card-text">MBBS, MS - Orthopedic Surgeon (Orthopedist)</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* row 3 */}
                                        </div>

                                        <div className="pt-2 text-right"><button className="readmore btn btn-link" id='nex-pd-view-more-btn'>View More</button></div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    }
                </Row>
            </>
        </>
    );

}

export default MyReferrals;