import React, { useState, useRef } from 'react'
import { Tooltip } from 'reactstrap';

const ToolTip = React.forwardRef((props, target) => {
  const [isOpen, setIsOpen] = useState(false);
  const targetRef = useRef(null);

  const handleToggle = () => {
    if (targetRef.current) {
      setIsOpen(!isOpen)
    }
  };
  return (
    <>
      <span className="tooltip_icon" id={props.id} ref={targetRef}></span>
      <Tooltip placement={props.place} isOpen={isOpen}
        target={targetRef} toggle={handleToggle} 
      >
        {props.children}
      </Tooltip>
    </>
  )
});

export default ToolTip;
