import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { createImageFromInitials, getRandomColor } from "utils/Utils";
import { encrypt } from "utils/EncryptDecrypt";
import DisplayCreatedTime from "pages/FeedHomePage/components/DisplayCreatedTime";
import Description from "pages/FeedHomePage/components/FeedItem/Description";
import { TalentService } from "utils/TalentService";
import { FAILTOSAVERECOED } from "utils/Constants";
const JobListSingle = ({ jobStatus, job, toggleAlert, setJobIdToDelete, setJobIdToClose, updateJobStatus }) => {
  const ShowExperience = ({ min, max }) => {
    if ((!min && !max) || min === '0' && max === '0')
      return (
        <span>Any Experience</span>
      )
    else if (min && !max) {
      return (
        <span>{min || 0} Years</span>
      );
    }
    else if (!min && max) {
      return (
        <span>{max || 0} Years</span>
      );
    }
    else {
      return (
        <span>{min || 0} -{" "}
          {max || 0} Years</span>
      );
    }
  }

  function getPosition(positionType) {

    if (positionType === "FULL_TIME")
      return "Full Time";
    else if (positionType === "PART_TIME")
      return "Part Time";
    else if (positionType === "FLEXIBLE_TIME")
      return "Flexible Time";
    else
      return "Consultant"
  }


  return (
    <>
      {job &&
        <div className="nexogic-job-list-card card nex-has-right-three-dot">
          <div className="card-head">
            <div className="card-thumb">
              <Link to={`/institution/${job?.institutionDetails?.profileId}`} >
                {job?.institutionDetails?.avatarId ?
                  <img
                    src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${job?.institutionDetails?.guid}/${job?.institutionDetails?.avatarId}`}
                    alt="institution profile pic" className="card-img" />
                  :
                  <img
                    src={createImageFromInitials(50, `${job?.institutionDetails?.name}`, getRandomColor(encrypt(job.id).toString()))}
                    alt="institution profile pic" className="card-img" />
                }
              </Link>
            </div>
            <div className="info">
              <h3 className="card-title">
                <Link to={`/jobs/${job.profileId}`}
                  onClick={() => window.scrollTo(0, 0)}
                  className="title-txt">
                  {job.title}
                </Link>
                <div
                  className={
                    jobStatus === "Draft"
                      ? "ml-2 mr-2 badge badge-primary bg-secondary badge-pill"
                      : jobStatus === "Submitted"
                        ? "ml-2 mr-2 badge badge-primary bg-primary badge-pill"
                        : jobStatus === "Published"
                          ? "ml-2 mr-2 badge badge-primary bg-success badge-pill"
                          : jobStatus === "Closed"
                            ? "ml-2 mr-2 badge badge-primary bg-danger badge-pill"
                            : ""
                  } >
                  <span>
                    {job.status}
                  </span>
                </div>
              </h3>
              <p className="card-text" style={{ fontWeight: "600" }}>{job?.institutionDetails?.name}</p>
              <p className="card-text">{job?.primarySpeciality}</p>
              <p className="card-text">Created {" "} <DisplayCreatedTime createdOn={job?.createdOn} /></p>
            </div>
            <div className="action nex-app-width-full">
              {job.status !== 'CLOSED' &&
                <div className="single-act">
                  <UncontrolledDropdown className="nexogic-dot-btn">
                    <DropdownToggle size="sm" color="link">
                      <span className="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                          <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                        </svg>
                      </span>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem tag={"button"} onClick={() => {
                        if (job.status === 'PUBLISHED')
                          setJobIdToClose(job.id)
                        else
                          setJobIdToDelete(job.id);
                        toggleAlert();
                      }}>
                        <FontAwesomeIcon className="mr-2" icon={faTrash} />
                        {`${job.status === 'PUBLISHED' ? "Close" : "Delete"}`} Job
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <div className="nex-app-width-full">
                    {jobStatus === "Draft" && (
                      <Link to={`/jobsboard/update-a-job/${job.profileId}`} className="nexogic_primary_button d-flex align-items-center nex-app-width-full">Complete {jobStatus}</Link>
                    )}
                    {jobStatus === "Submitted" && (
                      <span className="nexogic_primary_button d-flex align-items-center nex-app-width-full" onClick={() => updateJobStatus(job.id, "PUBLISHED")}>Publish this Job</span>
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
          <div className="card-body">


            <div className="description">
              <Description description={job?.description} />
            </div>
            <div className="nexogic-meta-description">
              <p><span className="icon"><svg
                width={20}
                height={22}
                viewBox="0 0 20 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 8V17C1 19.2091 2.79086 21 5 21H15C17.2091 21 19 19.2091 19 17V8M1 8V6.5C1 4.29086 2.79086 2.5 5 2.5H15C17.2091 2.5 19 4.29086 19 6.5V8M1 8H19M14 1V4M6 1V4"
                  stroke="#5EA9C6"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg></span> {getPosition(job?.position)}</p>
              <p><span className="icon">
                <svg
                  width={22}
                  height={22}
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11 8V10.382C11 10.7607 11.214 11.107 11.5528 11.2764L13 12M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11ZM18 11C18 14.866 14.866 18 11 18C7.13401 18 4 14.866 4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11Z"
                    stroke="#5EA9C6"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </span> <ShowExperience min={job?.minExperience} max={job?.maxExperience} /></p>
              {/* <p>
                <span className="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022ZM6 8.694 1 10.36V15h5V8.694ZM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15Z" />
                    <path d="M2 11h1v1H2v-1Zm2 0h1v1H4v-1Zm-2 2h1v1H2v-1Zm2 0h1v1H4v-1Zm4-4h1v1H8V9Zm2 0h1v1h-1V9Zm-2 2h1v1H8v-1Zm2 0h1v1h-1v-1Zm2-2h1v1h-1V9Zm0 2h1v1h-1v-1ZM8 7h1v1H8V7Zm2 0h1v1h-1V7Zm2 0h1v1h-1V7ZM8 5h1v1H8V5Zm2 0h1v1h-1V5Zm2 0h1v1h-1V5Zm0-2h1v1h-1V3Z" />
                  </svg>
                </span>
                {job?.location} 
              </p>*/}
              <p>
                <span className="icon">
                  <svg
                    width={20}
                    height={22}
                    viewBox="0 0 20 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19 9.88889C19 14.7981 13.375 21 10 21C6.625 21 1 14.7981 1 9.88889C1 4.97969 5.02944 1 10 1C14.9706 1 19 4.97969 19 9.88889Z"
                      stroke="#5EA9C6"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M13 10C13 11.6569 11.6569 13 10 13C8.34315 13 7 11.6569 7 10C7 8.34315 8.34315 7 10 7C11.6569 7 13 8.34315 13 10Z"
                      stroke="#5EA9C6"
                      strokeWidth="1.5"
                    />
                  </svg>
                </span>
                {job.location}, {job.city}
              </p>
            </div>
            {/** 
          <div className="actions">
              <a href="" className="btn"><span className="icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart-fill" viewBox="0 0 16 16">
  <path fillRule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"/>
</svg></span> Like</a>
              <a href="" className="btn"><span className="icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-left-fill" viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                </svg></span>Comment</a>
              <a href="" className="btn"><span className="icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-share" viewBox="0 0 16 16">
  <path d="M13.5 1a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5zm-8.5 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm11 5.5a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"/>
</svg></span> Share</a>
              <a href="" className="btn"><span className="icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark" viewBox="0 0 16 16">
  <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z"/>
</svg></span>Save</a>
              <a href="" className="btn"><span className="icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard2-check" viewBox="0 0 16 16">
  <path d="M9.5 0a.5.5 0 0 1 .5.5.5.5 0 0 0 .5.5.5.5 0 0 1 .5.5V2a.5.5 0 0 1-.5.5h-5A.5.5 0 0 1 5 2v-.5a.5.5 0 0 1 .5-.5.5.5 0 0 0 .5-.5.5.5 0 0 1 .5-.5h3Z"/>
  <path d="M3 2.5a.5.5 0 0 1 .5-.5H4a.5.5 0 0 0 0-1h-.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1H12a.5.5 0 0 0 0 1h.5a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5v-12Z"/>
  <path d="M10.854 7.854a.5.5 0 0 0-.708-.708L7.5 9.793 6.354 8.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3Z"/>
</svg></span>Apply</a>
          </div>
          */}

            {/** 
            <div className="post-content-details">
              <div className="txt-wrap">
                <Link to={`/jobs/${job.profileId}`}
                  onClick={() => window.scrollTo(0, 0)}
                  className="title-txt">
                  {job.title}
                  <div 
                  className={ 
                    jobStatus === "Draft"
                      ? "ml-2 mr-2 badge badge-primary bg-secondary badge-pill"
                      : jobStatus === "Submitted"
                        ? "ml-2 mr-2 badge badge-primary bg-primary badge-pill"
                        : jobStatus === "Published"
                          ? "ml-2 mr-2 badge badge-primary bg-success badge-pill"
                          : jobStatus === "Closed"
                            ? "ml-2 mr-2 badge badge-primary bg-danger badge-pill"
                            : ""
                  } >
                  <span>
                  {job.status}
                </span>
                  </div>
                </Link>
                <h3 className="speciality">
              <img
                      src={graduateSmallIcon}
                      className="img-fluid mt-1 mr-1"
                      alt="speciality"
                    />
                {job.primarySpeciality}</h3>
                <h4>
              <img
                      src={specialityIcon}
                      className="img-fluid mt-1 mr-1"
                      alt="speciality"
                    />
                {job?.institutionDetails?.name}</h4>
                <h4 className="address">
                <img
                      src={locationIcon}
                      className="img-fluid mt-1 mr-1"
                      alt="address"
                    />
                  {job.location}, {job.city}
                  </h4>
                  <h5>
              <img
                      src={calenderIcon}
                      className="img-fluid mt-1 mr-1"
                      alt="address"
                    /> Created {" "}
                <DisplayCreatedTime createdOn={job?.createdOn} />
              </h5>
              </div>
            </div>
*/}

          </div>
          <div className="visible-in-mobile">
            <div className="action">
              {job.status !== 'CLOSED' &&
                <div className="single-act">
                  <UncontrolledDropdown className="nexogic-dot-btn">
                    <DropdownToggle size="sm" color="link">
                      <span className="icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                          <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                        </svg>
                      </span>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem tag={"button"} onClick={() => {
                        if (job.status === 'PUBLISHED')
                          setJobIdToClose(job.id)
                        else
                          setJobIdToDelete(job.id);
                        toggleAlert();
                      }}>
                        <FontAwesomeIcon className="mr-2" icon={faTrash} />
                        {`${job.status === 'PUBLISHED' ? "Close" : "Delete"}`} Job
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <div className="">
                    {jobStatus === "Draft" && (
                      <Link to={`/jobsboard/update-a-job/${job.profileId}`} className="nexogic_primary_button d-flex align-items-center">Complete {jobStatus}</Link>
                    )}
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default JobListSingle;
