import { Link } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";

const JobVisitors = (props) => {
  const { jobProfileId, jobId, jobTitle } = props;
  return (
    <>
      <Card className="similar-jobs bg-white">
        <CardBody className="h5 application-views mb-0">
          <Row className="justify-content-center">
            <Col lg={12} className="p-0">
              <div className="view-number">
                <Link to={{
                  pathname: `/job-tracking/${jobId}`,
                  state: { params: { jobProfileId: jobProfileId, jobId: jobId, jobTitle: jobTitle } }
                }} onClick={()=>window.scrollTo(0,0)} className="nexogic_primary_button btn"> View Applicants</Link>
              </div>
            </Col>
            {/* {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
              <Col lg={6} sm={12}>
                <div className="view-number">1.5K</div>
                <div className="view-label">Views</div>
              </Col>
            } */}
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default JobVisitors;
