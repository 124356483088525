import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import markerIcon from "assets/images/marker_icon.png";
import pencilIcon from "assets/images/pencil_icon.png";
import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import AddressTypeaheadField from "components/forms/formikFields/AddressTypeaheadField";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import LocationTypeaheadField from "components/forms/formikFields/LocationTypeaheadField";
import { Field, FieldArray, Formik } from "formik";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { withAlert } from "react-alert";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap";
import {
  AllInstitutionTypesList,
} from "utils/Constants";
import { InstitutionServices } from "utils/InstitutionServices";
import { MetadataService } from "utils/MetadataService";
import { isInstitutionAdmin, isPractitioner } from "utils/UserHelper";
import { getLocationName } from "utils/Utils";
import InstitutionImageUploader from "./components/InstitutionImageUploader";
import RenderEditInstituteForm from "./components/RenderEditInstituteForm";
import { RenderInstituationEmailFormFields } from "./components/RenderInstituationEmailFormFields";
import { RenderInstituationPhoneFormFields } from "./components/RenderInstituationPhoneFormFields";
import RenderInstitutionCoverImage from "./components/RenderInstitutionImage";
import FollowUnfollow from "./FollowUnfollow";
import {
  validationSchema,
  validationSchemaAdmin
} from "./InstitutionsProfileValidationSchema";
import ParentInstitutesTypeaheadField from "./ParentInstitutesTypeaheadField";

import location_icon from "assets/svgIcon/location-icon.svg";
import phone_icon from "assets/svgIcon/phone-icon.svg";
import email_icon from "assets/svgIcon/email-icon.svg";
import verified_icon from "assets/svgIcon/verified-icon.svg";
import edit_blue_icon from "assets/images/edit-blue-icon.png";
import plusIconModal from "assets/images/modal-card-plus.png";

const RenderInstituteFormFields = (props) => {
  const { setFieldValue, values, handleSubmit, toggle } = props;
  const [countryList, setCountryList] = useState([]);
  const [statesList, setSatesList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [address, setAddress] = useState({});
  const stateRef = useRef();
  const cityRef = useRef();
  const countryRef = useRef();
  const locRef = useRef();

  const setDefaultAddress = () => {
    new Promise(function (resolve, reject) {
      MetadataService.getCountry().then((response) => {
        if (response.status === 200 || response.status === 201) {
          setCountryList(response.data);
          resolve(response.data);
        }
      });
    })
      .then(function (result) {
        return new Promise(function (resolve, reject) {
          if (result !== undefined && result.length > 0) {
            result.forEach((obj, index, array) => {
              if (obj.value === values.country[0]) {
                setFieldValue("country", [obj]);
                MetadataService.getStates(obj.id).then((response) => {
                  if (response.status === 200 || response.status === 201) {
                    setSatesList(response.data);
                    resolve(response.data);
                  }
                });
              }
            });
          }
        });
      })
      .then(function (result) {
        return new Promise(function (resolve, reject) {
          if (result !== undefined && result.length > 0) {
            result.forEach((obj, index, array) => {
              if (obj.value === values.state[0]) {
                setFieldValue("state", [obj]);
                MetadataService.getCities(obj.id).then((response) => {
                  if (response.status === 200 || response.status === 201) {
                    setCityList(response.data);
                    resolve(response.data);
                  }
                });
              }
            });
          }
        });
      })
      .then(function (result) {
        return new Promise(function (resolve, reject) {
          if (result !== undefined && result.length > 0) {
            result.forEach((obj, index, array) => {
              if (obj.value === values.city[0]) {
                setFieldValue("city", [obj]);
                MetadataService.getLocations(obj.id).then((response) => {
                  if (response.status === 200 || response.status === 201) {
                    setLocationList(response.data);
                    resolve(response.data);
                  }
                });
              }
            });
          }
        });
      });
  };

  const getselectedAddress = (array, id, type) => {
    let filterValue = "";
    if (!isEmpty(values.country)) {
      array.map((obj) => {
        if (obj.id === id) {
          filterValue = obj.value;
        }
        return true;
      });
    }
    if (type === "COUNTRY") {
      setAddress({
        ...address,
        country: filterValue,
        state: "",
        city: "",
        location: "",
      });
      values.state = [];
      values.city = [];
      values.location = [];
    } else if (type === "STATE") {
      setAddress({
        ...address,
        state: filterValue,
        city: "",
        location: "",
      });
      values.city = [];
      values.location = [];
    } else if (type === "CITY") {
      setAddress({
        ...address,
        city: filterValue,
        location: "",
      });
      values.location = [];
    }
  };

  const getStates = (countryId) => {
    setSatesList([]);
    setCityList([]);
    setLocationList([]);
    getselectedAddress(countryList, countryId, "COUNTRY");
    if (countryId === "") {
      return false;
    }
    MetadataService.getStates(countryId)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setSatesList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getStates error", error);
      });
  };

  const getCities = (stateId) => {
    setCityList([]);
    setLocationList([]);
    getselectedAddress(statesList, stateId, "STATE");
    if (stateId === "") {
      return false;
    }
    MetadataService.getCities(stateId)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setCityList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getCities error", error);
      });
  };

  const getLocations = (cityId) => {
    setLocationList([]);
    getselectedAddress(cityList, cityId, "CITY");
    if (cityId === "") {
      return false;
    }
    MetadataService.getLocations(cityId)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setLocationList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getCities error", error);
      });
  };

  const selectdLocation = (locationName) => {
    setAddress({
      ...address,
      location: locationName,
    });
  };

  useEffect(() => {
    setDefaultAddress();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <fieldset className="">
        {/*<legend>Instituation Information</legend>*/}
        <Row form>
          <Col md={6}>
            <Field
              id="name"
              name="name"
              type="text"
              placeholder="Instituation Name"
              label="Instituation Name"
              value={values?.name}
              component={FormikInputField}
            />
          </Col>
          <Col md={6}>
            <Field
              id="abbreviation"
              name="abbreviation"
              type="text"
              label="Abbreviation"
              value={values?.abbreviation}
              placeholder="e.g AIIMS"
              component={FormikInputField}
            />
          </Col>
        </Row>
        <Row form>
          <Col md={4}>
            <Field
              id="type"
              name="type"
              type="text"
              label="Type"
              value={values?.type}
              component={FormikSelectField}
              inputprops={{
                options: AllInstitutionTypesList,
                defaultOption: "Choose",
                keys: {
                  id: "id",
                  label: "label",
                },
              }}
            />
          </Col>
          <Col md={8}>
            <Field
              id="websiteURL"
              name="websiteURL"
              type="text"
              label="Website"
              value={values?.websiteURL}
              placeholder="e.g. https://www.google.com"
              component={FormikInputField}
            />
          </Col>
        </Row>
        <Row form>
          <Col md={12} id="nex-ipf-parent-inp-w">
            <Field
              id="parent"
              name="parent"
              type="text"
              label="Parent instituation"
              placeholder="Parent instituation"
              component={ParentInstitutesTypeaheadField}
            />
          </Col>

        </Row>
      </fieldset>
      <fieldset className=" mb-3">
        {/** <legend>Contact Information</legend>*/}
        <Row form>
          <Col lg={12} className="d-flex" >
            <FieldArray
              id="ip_phones"
              name="phones"
              render={(arrayHelpers) => (
                <div className="nexogic-modal-contact-card card" >
                  <div className="card-header">
                    <span className="card-title">
                      Phone Number(s)
                      {typeof props?.errors?.phones === "string" &&
                        props?.errors?.phones && (
                          <p>
                            <RenderErrorMessage
                              errorMsg={props.errors.phones}
                            />
                          </p>
                        )}
                      <Button
                        id="ip_btn_plus"
                        className="plus-btn"
                        onClick={() => {
                          arrayHelpers.push({
                            primary: false,
                            type: "APPOINTMENT",
                            value: "",
                          });
                        }}
                      >
                        <img src={plusIconModal} alt="" />
                        {/*<FontAwesomeIcon icon={faPlus} />*/}
                      </Button>
                    </span>

                  </div>
                  <div className="card-body">
                    {/** 
                    <Row>
                      <Col md={3} className="d-flex align-items-center">
                        <label >Type</label>
                      </Col>
                      <Col md={9} className="d-flex align-items-center">
                        <label >Phone Number</label>
                      </Col>
                    </Row>
                    */}
                    {props?.values?.phones.map((_, index) => (
                      <RenderInstituationPhoneFormFields
                        {...arrayHelpers}
                        {...props}
                        index={index}
                      />
                    ))}
                  </div>
                </div>
              )}
            />
          </Col>
          <Col lg={12} className="d-flex mt-3 ip_emails_w">
            <FieldArray
              id="ip_emails"
              name="emails"
              render={(arrayHelpers) => (
                <div className="nexogic-modal-contact-card card">
                  <div className="card-header">
                    <span className="card-title">
                      Email Address(s)
                      {typeof props?.errors?.emails === "string" &&
                        props?.errors?.emails && (
                          <p>
                            <RenderErrorMessage
                              errorMsg={props.errors.emails}
                            />
                          </p>
                        )}
                      <Button
                        id="nex-ipf-plus-btn-appo"
                        className="plus-btn"
                        onClick={() => {
                          arrayHelpers.push({
                            primary: false,
                            type: "APPOINTMENT",
                            value: "",
                          });
                        }}
                      >
                        <img src={plusIconModal} alt="" />
                      </Button>
                    </span>

                  </div>
                  <div className="card-body">
                    {/** 
                    <Row>
                      <Col md={3} className="d-flex align-items-center">
                        <label className="label-color">Type</label>
                      </Col>
                      <Col md={9} className="d-flex align-items-center">
                        <label className="label-color">Email</label>
                      </Col>
                    </Row>
                    */}
                    {props.values.emails.map((_, index) => (
                      <RenderInstituationEmailFormFields
                        {...arrayHelpers}
                        {...props}
                        index={index}
                      />
                    ))}
                  </div>
                </div>
              )}
            />
          </Col>
        </Row>
      </fieldset>
      <fieldset className=" mt-4">
        {/**<legend>Mailing Address</legend> */}
        <Row form>
          <Col md={12}>
            <Field
              id="addressLine1"
              name="addressLine1"
              type="text"
              label="Address Line 1"
              placeholder="address line 1 "
              component={FormikInputField}
              autoComplete="addressLine1"
            />
          </Col>
        </Row>
        <Row form>
          <Col md={12}>
            <Field
              id="addressLine2"
              name="addressLine2"
              label="Address Line 2"
              type="text"
              placeholder="address line 2"
              component={FormikInputField}
              autoComplete="addressLine2"
            />
          </Col>
        </Row>
        <Row form>
          <Col lg={6}>
            <div className="form-group" id="nex-ipf-country-inp-w">
              <Field
                id="country"
                name="country"
                type="text"
                component={AddressTypeaheadField}
                label="Country"
                autocomplete="off"
                value={
                  typeof values?.country === "String" ? [] : values?.country
                }
                selected={"test"}
                placeholder="Select country"
                spellCheck={false}
                inputList={countryList}
                getAddressById={(contryId) => getStates(contryId)}
                locationRef={countryRef}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="form-group" id="nex-ipf-state-inp-w">
              <Field
                id="state"
                name="state"
                type="text"
                component={AddressTypeaheadField}
                label="State"
                autocomplete="off"
                value={values?.state}
                placeholder="Select state"
                spellCheck={false}
                inputList={statesList}
                getAddressById={(stateId) => getCities(stateId)}
                locationRef={stateRef}
              />
            </div>
          </Col>
        </Row>
        <Row form>
          <Col lg={4}>
            <div className="form-group" id="nex-ipf-city-inp-w">
              <Field
                id="city"
                name="city"
                type="text"
                component={AddressTypeaheadField}
                label="City"
                autoComplete="off"
                value={values?.city}
                placeholder="Select city"
                spellCheck={false}
                inputList={cityList}
                getAddressById={(cityId) => getLocations(cityId)}
                locationRef={cityRef}
              />
            </div>
          </Col>
          <Col lg={4}>
            <Field
              id="postalCode"
              name="postalCode"
              type="text"
              component={FormikInputField}
              label="Zip Code"
              placeholder="Enter code"
            />
          </Col>
          <Col lg={4} id="nex-ipf-location-inp-w">
            <Field
              id="location"
              name="location"
              type="text"
              component={LocationTypeaheadField}
              label="Location"
              value={values?.location}
              placeholder="Select Location"
              spellCheck={false}
              locations={locationList}
              selectdLocation={selectdLocation}
              locationRef={locRef}
            />
          </Col>
        </Row>
      </fieldset>
      <div className="justify-content-end  modal-footer">
        <div className="action-group">


          <Button id="ip_btn_submit" className="nexogic_primary_button" color="primary " type="submit">
            Save
          </Button>
          <Button
            id="ip_btn_cancel"
            className="nexogic_primary_button_outline"
            color="primary"
            type="button"
            outline
            onClick={toggle}
          >
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
};

const InstitutionsProfile = (props) => {
  // const globalInstituteProfileInfo = useSelector(
  //   (state) => state.GlobalInstituteProfileReducer.globalInstituteInfo
  // );
  const {
    institution,
    isAdmin,
    isEditable,
    setPractitioner,
    practitionerId,
    alert,
    getInstitutionProfile,
  } = props;
  const { name, bannerUrl, heading, address, phoneContacts, emailContacts } = props.institution;
  const [modalisOpen, setmodalIsOpen] = useState(false);
  const modalToggle = () => setmodalIsOpen(!modalisOpen);

  const initialValues = {
    country: institution?.address?.country ? [institution?.address?.country] : "",
    state: institution?.address?.state ? [institution?.address?.state] : "",
    city: institution?.address?.city ? [institution?.address?.city] : "",
    location: institution?.address?.location
      ? [institution?.address?.location]
      : "",
    postalCode: institution?.address?.postalCode ? institution?.address?.postalCode : "111111",
    abbreviation: institution?.abbreviation,
    websiteURL: institution?.websiteURL,
    name: institution?.name,
    type: institution?.type,
    addressLine1: institution?.address?.addressLine1,
    addressLine2: institution?.address?.addressLine2,
    phones:
      institution?.phoneContacts?.length > 0 ? institution?.phoneContacts : [],
    emails:
      institution?.emailContacts?.length > 0 ? institution?.emailContacts : [],
    parent: institution?.parent ? institution?.parent : "",

  };

  const updateInstitutionDetails = async (values, setSubmitting) => {
    const {
      name,
      country,
      state,
      city,
      postalCode,
      abbreviation,
      websiteURL,
      addressLine1,
      addressLine2,
      location,
      type,
      emails,
      phones,
      parent,
    } = values;
    setSubmitting(true);
    const payload = { ...institution };
    payload.address = {
      country: country[0]?.value ?? "",
      state: state[0]?.value ?? "",
      city: city[0]?.value ?? "",
      postalCode: postalCode,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      location: getLocationName(location),
    };
    payload.name = name;
    payload.websiteURL = websiteURL ? websiteURL : null;
    payload.abbreviation = abbreviation ? abbreviation : null;
    payload.type = type;
    payload.emailContacts = emails;
    payload.phoneContacts = phones;
    payload.parent = parent ? parent?.guid : "";
    InstitutionServices.updateInstitution(payload, institution?.guid)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          showSuccessMessage("Institution updated successfully");
          modalToggle();
          getInstitutionProfile(institution?.guid);
        } else {
          showErrorMessage("Institution update failed");
        }
      })
      .catch((err) => {
        showErrorMessage(err?.message);
      });
    setSubmitting(false);
  };

  return (
    <section className="nexogic-profile-main">
      <div className="nexogic-card-banner card border-0 nexogic-banner-admin-card ">
        <RenderInstitutionCoverImage
          bannerUrl={bannerUrl}
          setPractitioner={setPractitioner}
          isEditable={isEditable}
          institution={institution}
          isAdmin={isAdmin}
          practitionerId={practitionerId}
        />
        <div className="card-body mb-0">
          <div className="card-user-img-w">
            <InstitutionImageUploader
              profilePicUrl={null}
              practitionerId={practitionerId}
              setPractitioner={setPractitioner}
              institution={institution}
              isEditable={isEditable}
              isAdmin={isAdmin}
              institutionName={name}
            />
          </div>
          <Card className="institute-info">
            <CardBody className="">

              <RenderEditInstituteForm
                isEditable={isEditable}
                setPractitioner={setPractitioner}
                isAdmin={isAdmin}
                institution={institution}
                getInstitutionProfile={getInstitutionProfile}
                practitionerId={practitionerId}
              />

              <div className="d-flex">
                <h2 className="card-title extra-left-padd">
                  {name} {/**  <span className="Verified"><i className="icon"><img src={verified_icon} alt=""></img></i></span>*/}
                  {/* <img src={approvalIcon} alt="#" /> */}
                  <div className="nexogic-profile-edit-icon">
                    {(isEditable || isAdmin) && (
                      <button
                        id="nex-profile-edit-btn"
                        className="btn btn-secondary"
                        onClick={modalToggle}
                      >
                        <svg
                          width={15}
                          height={19}
                          viewBox="0 0 15 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M9.41904 1.17708C10.3218 0.274305 11.7855 0.274305 12.6883 1.17708L14.3229 2.81171C15.2257 3.71449 15.2257 5.17818 14.3229 6.08096L12.8736 7.53028C12.7598 7.47394 12.6401 7.41302 12.516 7.34767C11.6806 6.90786 10.6892 6.29057 9.94934 5.55069C9.20947 4.81082 8.59218 3.81945 8.15237 2.98403C8.08701 2.85989 8.02608 2.74018 7.96974 2.62639L9.41904 1.17708ZM8.88868 6.61135C9.7642 7.48687 10.8777 8.17594 11.7595 8.64441L6.06004 14.3438C5.70635 14.6975 5.2475 14.9269 4.75234 14.9977L1.31963 15.4881C0.556801 15.597 -0.0970492 14.9432 0.0119258 14.1804L0.502313 10.7477C0.57305 10.2525 0.802479 9.79364 1.15616 9.43996L6.8556 3.74053C7.32407 4.62234 8.01315 5.73582 8.88868 6.61135ZM5.5 17C5.08579 17 4.75 17.3358 4.75 17.75C4.75 18.1642 5.08579 18.5 5.5 18.5H14.25C14.6642 18.5 15 18.1642 15 17.75C15 17.3358 14.6642 17 14.25 17H5.5Z"
                            fill="#5EA9C6"
                          />
                        </svg>


                      </button>
                    )}
                  </div>
                  <small className="text-muted"><br />{heading} </small>

                </h2>
              </div>
              <Row>
                <Col md={12} className="nexogic-institution-info">
                  {/** 
                  <div className="location-text f-14 line-h-16 mb-2">
                    {heading}
                  </div>
                  */}
                  {/* <div className="location-text f-14 line-h-16 ">..{heading}</div> */}
                  <div className="info">
                    <p className="card-text">
                      {address && (
                        <>
                          <img src={location_icon} alt="#" /> {" "}
                          <span className="">
                            {(address?.location) ? `${address?.location},` : ""} {(address?.city) ? `${address?.city},` : ""} {(address?.state) ? `${address?.state},` : ""} {(address?.country) ? `${address?.country}` : ""}
                          </span>
                        </>
                      )}
                      {/* 
                    <span className="ml-1 text-complimentary f-14 line-h-16 d-none">
                      , 1260 Employee[static]
                    </span>
                    */}
                    </p>
                    <div className="card-text banner-phone-email">
                      {phoneContacts?.length > 0 &&
                        <div className="phone-col">
                          <span className="icon"><img src={phone_icon} alt="" /></span>

                          {phoneContacts.map((node, index) => (
                            <span key={index} className="pt-1">
                              <a key={index} href={"tel:" + node?.value} >{node?.value}</a>
                            </span>
                          ))}
                        </div>
                      }
                      {emailContacts?.length > 0 &&
                        <div className="email-col">
                          <span className="icon"><img src={email_icon} alt="" /></span>
                          {emailContacts.map((node, index) => (
                            <span key={index} className="pt-1"> {node?.value}</span>
                          ))}

                        </div>


                      }
                    </div>
                  </div>
                  {/* (phoneContacts?.length > 0 || emailContacts?.length > 0) && (
              
                <div className="ins-contact">
                  <p className="black-color-accent">
                    Contact Details
                  </p>
                  <div className="itm-wrp">
                    <div className="items d-flex pt-0"> {
                      (phoneContacts.length > 0) ? (
                        <>
                          <div className="col-2 pl-0">
                            <span className="icon-circle">
                              <FontAwesomeIcon className="icon-class" icon={faPhone} />
                            </span>
                          </div>
                          <div className="col-10 pl-0">
                            {phoneContacts.map((node, index) => (
                              <div key={index} className="pt-1">
                                <a key={index} href={"tel:" + node?.value} >{node?.value}</a>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : ""}
                    </div>
                    <div className="items d-flex pt-0">
                      {
                        (emailContacts.length > 0) ? (
                          <>
                            <div className="col-2">
                              <span className="icon-circle">
                                <FontAwesomeIcon className="icon-class" icon={faEnvelope} />
                              </span>
                            </div>
                            <div className="col-10">
                              {emailContacts.map((node, index) => (
                                <div key={index} className="pt-1"> {node?.value}</div>
                              ))}
                            </div>
                          </>
                        ) : ""}
                    </div>



                     {phoneContacts.map((node, index) => (
                      <div className="items" key={index}>
                        <h5>
                          <span className="icon-circle">
                            <FontAwesomeIcon
                              className="icon-class"
                              icon={faPhone}
                            />
                          </span>
                          {
                            InstituationphonesType.find(
                              (each) => each.value === node?.type
                            ).label
                          }{" "}
                          number
                        </h5>
                        <p>
                          <a href={"tel:" + node?.value}>{node?.value}</a>
                        </p>
                      </div>
                    ))}
                    {emailContacts.map((node, index) => (
                      <div className="items" key={index}>
                        <h5>
                          <span className="icon-circle">
                            <FontAwesomeIcon
                              className="icon-class"
                              icon={faPhone}
                            />
                          </span>
                          {
                            InstituationEmailType.find(
                              (each) => each.value === node?.type
                            ).label
                          }{" "}
                          Email
                        </h5>
                        <p>{node?.value}</p>
                      </div>
                    ))} 
                  </div>
                </div>
              
                        ) */}

                  <div className="button-actions">
                    {institution.websiteURL && (
                      <a
                        id="nex-ip-visit-website-btn"
                        href={`${institution.websiteURL}`}
                        className="nexogic_primary_button"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Visit website
                      </a>
                    )}
                    {isPractitioner() && (
                      <FollowUnfollow className=""
                        practitionerId={practitionerId}
                        instituationId={institution?.guid}
                      />
                    )}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Modal
            backdrop="static"
            isOpen={modalisOpen}
            toggle={modalToggle}
            className="institute-modal"
            scrollable
          >
            <ModalHeader
              close={
                <button className="close" onClick={modalToggle}>
                  ×
                </button>
              }
              toggle={modalToggle}
            >
              Instituation Details
            </ModalHeader>
            <ModalBody>
              <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={
                  isAdmin ? validationSchemaAdmin : validationSchema
                }
                onSubmit={(values, { setSubmitting }) => {
                  updateInstitutionDetails(values, setSubmitting);
                }}
                children={(props) => {
                  return (
                    <>
                      <RenderInstituteFormFields
                        {...props}
                        toggle={modalToggle}
                        institution={institution}
                      />
                    </>
                  );
                }}
              />
            </ModalBody>
          </Modal>
        </div>
      </div>
    </section>
  );
};

export default withAlert()(InstitutionsProfile);
