import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { isInstitutionAdmin, isPractitioner } from "utils/UserHelper";
import AllConnections from "./components/AllConnections";
import Alumni from "./components/Alumni";
import Colleagues from "./components/Colleagues";
import AllGroups from "./components/Groups";
import AboutGroupDetails from "./components/Groups/components/AboutGroupDetails";
import GroupView from "./components/Groups/components/GroupView";
import GroupMembersListDetails from "./components/Groups/components/GroupviewSide/GroupMembersListDetails";
import MyPatients from "./components/MyPatients";
import PatientInfo from "./components/MyPatients/PatientInfo.";
import Pages from "./components/Pages";

const ConnectionsContent = () => {
  let { path, url } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route path={`${url}/all-connections`} component={AllConnections} />
        <Route path={`${url}/alumni`} component={Alumni} />
        <Route path={`${url}/my-colleagues`} component={Colleagues} />
        {/* <Route path={`${url}/people-I-follow`} component={PeopleIFollow}/> */}
        <Route exact path={`${url}/my-patients`} component={MyPatients} />
        <Route path={`${url}/my-patients`} component={MyPatients} />
        <Route exact path={`${url}/groups`} component={AllGroups} />
        <Route path={`${url}/groups/:groupID`} component={GroupView} />
        <Route path={`${url}/pages`} component={Pages} />
        <Route
          exact
          path={`${url}/group/group-members/:groupId`}
          component={GroupMembersListDetails}
        />
        <Route
          exact
          path={`${url}/group/group-admins/:groupId`}
          component={GroupMembersListDetails}
        />
        <Route
          exact
          path={`${url}/group/group-details/:groupId`}
          component={AboutGroupDetails}
        />
        <Redirect exact to={`${url}/${isPractitioner() ? "all-connections" : "pages"}`} />
      </Switch>
    </>
  );
};
export default ConnectionsContent;
