import { useState, useEffect } from "react";
import PersonalDetailsForm1 from "./PersonalDetailsForm1";
import PersonalDetailsForm2 from "./PersonalDetailsForm2";
import PersonalDetailsForm3 from "./PersonalDetailsForm3";
import CreateNewRelatedPerson from "./CreateNewRelatedPerson";
import { PatientService } from "utils/PatientService";
import { FAILED_TO_FETCH_DATA } from "utils/Constants";
import { useSelector } from "react-redux";
import { withAlert } from "react-alert";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const PersonalDetailsForm = (props) => {
    const { currentStepInMainForm, setCurrentStepInMainForm, setOpinionId, isSelf, setIsSelf, forUpdate, setForUpdate, opinionInfo, setSelectedSpeciality, getOpinionInfo } = props;
    const [currentStep, setCurrentStep] = useState('1');

    const currentUser = useSelector((state) => state.auth.currentUser);
    const [currentUserDetails, setCurrentUserDetails] = useState({});

    const [requestedForId, setRequestedForId] = useState(null);
    const [healthIssueValue, setHealthIssueValue] = useState(null);

    const getLoggedInPatientDetails = (id) => {
        PatientService.getPatientById(id)
            .then((response) => {
                const { status, data } = response;
                if (status == 200) {
                    setCurrentUserDetails(data);
                }
                else {
                    showErrorMessage(FAILED_TO_FETCH_DATA);
                }
            })
    }

    useEffect(() => {
        getLoggedInPatientDetails(currentUser?.profileId);
    }, [])

    useEffect(() => {
        setCurrentStep('1');
    }, [currentStepInMainForm])

    return (
        <>
            {currentStep === '1' &&
                <PersonalDetailsForm1
                    forUpdate={forUpdate}
                    setCurrentStep={setCurrentStep}
                    isSelf={isSelf}
                    setIsSelf={setIsSelf}
                    setCurrentStepInMainForm={setCurrentStepInMainForm}
                    currentUser={currentUser}
                    setCurrentUserDetails={setCurrentUserDetails}
                    getOpinionInfo={getOpinionInfo}
                />}

            {currentStep === '2' &&
                <PersonalDetailsForm2
                    isSelf={isSelf}
                    currentUserId={currentUser?.profileId}
                    forUpdate={forUpdate}
                    opinionInfo={opinionInfo}
                    healthIssueValue={healthIssueValue}
                    setHealthIssueValue={setHealthIssueValue}
                    setCurrentStep={setCurrentStep}
                    currentUserDetails={currentUserDetails}
                    getOpinionInfo={getOpinionInfo}
                    setCurrentStepInMainForm={setCurrentStepInMainForm}
                />}

            {currentStep === '3' &&
                <PersonalDetailsForm3
                    isSelf={isSelf}
                    forUpdate={forUpdate}
                    setForUpdate={setForUpdate}
                    opinionInfo={opinionInfo}
                    healthIssueValue={healthIssueValue}
                    currentUserId={currentUser?.profileId}
                    requestedForId={requestedForId}
                    setCurrentStep={setCurrentStep}
                    setCurrentStepInMainForm={setCurrentStepInMainForm}
                    setOpinionId={setOpinionId}
                    setSelectedSpeciality={setSelectedSpeciality}
                    getOpinionInfo={getOpinionInfo}
                />}

            {currentStep === '2b' &&
                <CreateNewRelatedPerson
                    currentUserId={currentUser?.profileId}
                    setRequestedForId={setRequestedForId}
                    setCurrentStep={setCurrentStep} setCurrentUserDetails={setCurrentUserDetails}
                    getOpinionInfo={getOpinionInfo}
                />}
        </>
    )
}

export default withAlert()(PersonalDetailsForm);