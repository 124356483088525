import React from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import { useState } from "react";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import secondOpinionright from "assets/images/second-opinion.png";
import profile_sample from "assets/images/user-profile-sample.png";
import { useEffect } from "react";
import AssignModal from "../SubElements/Modals/AssignModal";
import { OpinionService } from "utils/OpinionService";
import { FAILED_TO_FETCH_DATA, FAILTOSAVERECOED } from "utils/Constants";
import { MedialService } from "utils/MediaService";
import { isPractitioner } from "utils/UserHelper";
import CommonLoader from "common/CommonLoader";
import { createImageFromInitials, getRandomColor } from "utils/Utils";
import { encrypt } from "utils/EncryptDecrypt";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { PatientService } from "utils/PatientService";
import { PractitionerService } from "utils/PractitionerService";
import { useRef } from "react";
import { withAlert } from "react-alert";
import { toast } from "react-toastify";

export default withAlert()(function MyOpinionDetailPage(props) {
  const { opinionId } = props.match.params;
  const [opinionDetails, setOpinionDetails] = useState({});
  const [docs, setDocs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [documentType, setDocumentType] = useState(null);
  const [documentData, setDocumentData] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [patientInfo, setPatientInfo] = useState({});
  const [practitionerInfo, setPractitionerInfo] = useState({});
  const [isFileUploaing, setIsFileUploaing] = useState(false);
  const fileInputRef = useRef(null);

  const [dropDownItem, setDropDownItem] = useState();
  const [actions, setActions] = useState([])

  function getStatusClass(inp) {
    let classes = '';
    switch (inp) {
      case "closed":
        classes += ' accepted';
        break;
      case "submitted":
        classes += ' accepted';
        break;
      case "accept":
        classes += ' accepted';
        break;
      case "reject":
        classes += ' rejected';
        break;
      default:
        classes += '';
    }
    return classes;
  }

  function setActionsForTheStatus(status) {
    if (status === "DRAFT")
      setActions(["Submit"])
    else
      setActions([])
  }

  const handleUpdateReferralStatus = (status) => {
    let act = "SUBMITTED";
    // switch (status) {
    //   case "Draft":
    //     act = "DRAFT";
    //     break;
    //   case "Submit":
    //     act = "SUBMITTED";
    //     break;
    //   default:
    //     act = "ASSIGNED";
    // }

    var payload = {
      "speciality": opinionDetails?.speciality,
      "title": opinionDetails?.title,
      "status": act,
      "requestedBy": opinionDetails?.requestedBy,
      "requestedFor": opinionDetails?.requestedFor
    }

    OpinionService.updateOpinion(opinionDetails?.id, payload)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          getOpinionDetails(opinionDetails?.id);
          if (data.status === 'ASSIGNED')
            showSuccessMessage(`Opinion submitted and assigned to the practitioner Successfully.`);
          else
            showSuccessMessage(`Opinion submitted Successfully.`);
        }
        else {
          showErrorMessage(FAILTOSAVERECOED);
        }
      })
  }

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Perform any necessary validation or processing of the selected file here
      setIsFileUploaing(true);
      // Create a FormData object to send the file data
      const formData = new FormData();
      formData.append('files', selectedFile);

      // Submit the file to the API using your preferred method (e.g., fetch, axios)
      MedialService.uploadMedia("SECOND_OPINION", opinionId, formData)
        .then((response) => {
          const { status } = response;
          if (status === 200) {
            showSuccessMessage("Document uploaded Successfully");
            getAllDocs(opinionId);
            setIsFileUploaing(false);
          } else if (status === 409) {
            toast.info("Can't upload the document with the same name again");
            setIsFileUploaing(false);
          }
          else {
            showErrorMessage(FAILTOSAVERECOED);
            setIsFileUploaing(false);
          }
        })
    }
  };

  const getPatientInfo = (opinion) => {
    if (opinion?.requestedBy === opinion?.requestedFor) {
      PatientService.getPatientById(opinion?.requestedBy)
        .then((response) => {
          const { status, data } = response;
          if (status === 200) {
            setPatientInfo(data);
            // console.log(data);
          } else {
            console.log(FAILED_TO_FETCH_DATA);
          }
        })
    } else {
      PatientService.getRelatedPersonById(opinion?.requestedBy, opinion?.requestedFor)
        .then((response) => {
          const { status, data } = response;
          if (status === 200) {
            setPatientInfo(data);
            // console.log(data);
          } else {
            console.log(FAILED_TO_FETCH_DATA);
          }
        })
    }
  }

  const getPractitionerInfo = (practitionerAssignedTo) => {
    PractitionerService.getPractitionerProfileById(practitionerAssignedTo)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          setPractitionerInfo(data);
          // console.log(data);
        } else {
          console.log(FAILED_TO_FETCH_DATA);
        }
      })
  }

  const getAllDocs = (opinionId) => {
    MedialService.getMedias("SECOND_OPINION", opinionId)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          // console.log(data);
          setDocs(data);
        }
        else {
          console.log(FAILED_TO_FETCH_DATA);
        }
      })
  }

  const history = useHistory();
  const handleBack = () => {
    history.goBack();
  };
  const [pannel_one_on, setPannel_one_on] = useState(true);
  const [pannel_two_on, setPannel_two_on] = useState(true);
  const [pannel_three_on, setPannel_three_on] = useState(true);
  const [pannel_four_on, setPannel_four_on] = useState(true);
  const [reqAssign, setReqAssign] = useState(false);
  const [isAssignModal, setIsAssignModal] = useState(false);

  function openAssignModal(e) {
    e.preventDefault();
    setIsAssignModal(!isAssignModal);
    console.log(isAssignModal);
  }

  function haldleAssign(data) {
    if (data === true) {
      setIsAssignModal(false);
      setReqAssign(data);
    }
  }

  function haldleUnassign(e) {
    e.preventDefault();
    setReqAssign(false);
  }

  useEffect(() => {
    document.body.classList.add("nexogic-so-page");
    return () => document.body.classList.remove("nexogic-so-page");
  }, []);

  const getOpinionDetails = (id) => {
    OpinionService.getOpinion(opinionId)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          setOpinionDetails(data);
          // console.log(data);
          setActionsForTheStatus(data?.status)
          getPatientInfo(data);
          if (data?.practitionerAssignedTo)
            getPractitionerInfo(data?.practitionerAssignedTo)
          setIsLoading(false);
        } else {
          history.push("/404");
          console.log(FAILED_TO_FETCH_DATA);
          setIsLoading(false);
        }
      })
  }

  useEffect(() => {
    // console.log(opinionId);
    getOpinionDetails(opinionId);
    getAllDocs(opinionId);
  }, [opinionId])

  const getReferralDoc = async (id) => {
    try {
      setDataLoading(true);
      const { data, headers } = await MedialService.getMediaFile(id);
      const createContentType = headers["content-type"] === "application/octet-stream"
        ? "application/pdf"
        : headers["content-type"];
      let generateURL = `data:${createContentType};base64,${Buffer.from(data).toString("base64")}`;
      setDocumentData(generateURL);
      let docArray = [data, createContentType];
      setDocumentType(docArray);
      if (createContentType === "application/pdf") {
        setDataLoading(false);
        onOpenBase64(generateURL);
      }
      else {
        setDataLoading(false);
        toggleForm();
      }
    } catch (error) {
      console.error("Error retrieving referral document:", error);
    }
  };

  const onOpenBase64 = (base64URL) => {
    // const onOpenBase64 = () => {
    try {
      var win = window.open();
      win.document.write(`
        <iframe class="nexogic-iframe" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" 
          src="${base64URL}" frameborder="0" allowfullscreen></iframe>
      `);
    } catch (error) {
      console.error("Error opening base64 URL:", error);
      // Handle error, show error message, etc.
    }
  };

  const toggleForm = () => setModal(!modal);

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = documentData;
    let ext = documentType[1].split('/').pop();
    link.download = 'reports.' + ext;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.click();
  };

  if (isLoading) {
    return (
      <div className="loading-spiner text-center">
        <CommonLoader />
      </div>
    )
  }

  return (
    <div className="nexogic-second-view-details">
      <Card className="nexogic-form-text-display-w border-0 bg-transparent">
        <CardBody className="">
          <Row className="need-margin">
            <Col xl={9}>
              <div className="position-relative mb-3">


                <Card
                  className={`nexogic-card-color-header ${pannel_one_on ? "expanded" : ""
                    }`}
                >
                  <div className="card-header">
                    <h2>Patient Information</h2>
                    <button
                      className="collapse-btn"
                      type="button"
                      onClick={() => setPannel_one_on(!pannel_one_on)}
                    >
                      <img src={pannel_arrow} alt="" />
                    </button>
                  </div>
                  <div className="collapse-div nexogic-patient-info nex-second-opt-details">
                    <CardBody>
                      <div className="row">
                        <div className="col-md-4 col-sm-6 nexogic-flex-col">
                          <div className="graphic nexogic-avatar size-m">
                            <div className="graphic">
                              <div className="card-graphic"><img src={createImageFromInitials(100, patientInfo?.firstName + " " + patientInfo?.lastName, getRandomColor(encrypt(21).toString()))} className="rounded-circle" width="70" height="70" /></div>
                            </div>
                          </div>
                          <div className="info">
                            <p className="card-text">

                              <b>Name</b> <span className="nex-colon">:</span>
                              {patientInfo?.id ?
                                <Link to={`/patient-profile/${patientInfo?.id}`}>{patientInfo?.firstName}{" "}{patientInfo?.lastName}</Link>
                                :
                                <span>{patientInfo?.firstName}{" "}{patientInfo?.lastName}</span>
                              }
                            </p>
                            <p className="card-text">
                              <b>Phone</b> <span className="nex-colon">:</span> {patientInfo?.cellularPhoneNumber}
                            </p>
                            <p className="card-text">
                              <b>Blood group</b> <span className="nex-colon">:</span> {patientInfo?.bloodGroup}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-6 nexogic-flex-col">
                          <div className="info">
                            <p className="card-text">
                              <b>Specialty</b> <span className="nex-colon">:</span> {opinionDetails?.speciality}
                            </p>
                            <p className="card-text">
                              <b>E-mail</b> <span className="nex-colon">:</span> {patientInfo?.emailAddress}
                            </p>
                            <p className="card-text">
                              <b>Height</b> <span className="nex-colon">:</span> {patientInfo?.height}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-4 nexogic-flex-col">
                          <div className="info">
                            <p className="card-text">
                              <b>Weight</b> <span className="nex-colon">:</span> {patientInfo?.weight}
                            </p>
                            <p className="card-text">
                              <b>Date of birth</b> <span className="nex-colon">:</span> {patientInfo?.dateOfBirth}
                            </p>
                            <p className="card-text">
                              <b>Marital status</b> <span className="nex-colon">:</span> Married
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <h3 className="card-title">Address</h3>
                        <div className="col-lg-12 nexogic-bg-light address-sec">
                          <div className="row">

                            <div className="col-md-4 nexogic-flex-col">
                              <div className="info">
                                <p className="card-text">
                                  <b>City</b> : {patientInfo?.address?.city ? patientInfo?.address?.city : "N/A"}
                                </p>
                              </div>
                            </div>

                            <div className="col-md-4 nexogic-flex-col">
                              <div className="info">
                                <p className="card-text">
                                  <b>State</b> : {patientInfo?.address?.state ? patientInfo?.address?.state : "N/A"}
                                </p>
                              </div>
                            </div>

                            <div className="col-md-4 nexogic-flex-col">
                              <div className="info">
                                <p className="card-text">
                                  <b>Zip code</b> : {patientInfo?.address?.zipCode ? patientInfo?.address?.zipCode : "N/A"}
                                </p>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </div>
                </Card>

                <Card
                  className={`nexogic-card-color-header nex-second-opt-details ${pannel_four_on ? "expanded" : ""
                    }`}
                >
                  <div className="card-header">
                    <h2>Practitioner Information(to Whom Requested)</h2>
                    <button
                      className="collapse-btn"
                      type="button"
                      onClick={() => setPannel_four_on(!pannel_four_on)}
                    >
                      <img src={pannel_arrow} alt="" />
                    </button>
                  </div>
                  {(practitionerInfo && practitionerInfo?.profileGuid) ?
                    <div className="collapse-div nexogic-patient-info nex-second-opt-details">
                      <CardBody>
                        <div className="row">
                          <div className="col-md-5 col-sm-6 nexogic-flex-col">
                            <div className="graphic nexogic-avatar size-m">
                              <div className="graphic">
                                <div className="card-graphic"><img src={createImageFromInitials(100, practitionerInfo?.name.replace('Dr. ', ''), getRandomColor(encrypt(21).toString()))} className="rounded-circle" width="70" height="70" /></div>
                              </div>
                            </div>
                            <div className="info">
                              <p className="card-text">

                                <b>Name</b> <span className="nex-colon">:</span>
                                {practitionerInfo?.profileGuid &&
                                  <Link to={`/patient-profile/${practitionerInfo?.profileId}`}>{practitionerInfo?.name}</Link>
                                }
                              </p>
                              <p className="card-text">
                                <b>Location</b> <span className="nex-colon">:</span> {practitionerInfo?.location}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-6 nexogic-flex-col">
                            <div className="info">
                              <p className="card-text">
                                <b>Specialty</b> <span className="nex-colon">:</span> {opinionDetails?.speciality}
                              </p>
                              <p className="card-text">
                                <b>City</b> <span className="nex-colon">:</span> {practitionerInfo?.city}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-3 nexogic-flex-col">
                            <div className="info">
                              <p className="card-text">
                                <b>Qualification</b> <span className="nex-colon">:</span> {practitionerInfo?.qualification}
                              </p>
                              <p className="card-text">
                                <b>State</b> <span className="nex-colon">:</span> {practitionerInfo?.state}
                              </p>
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </div>
                    :
                    <div className="m-2 nexogic-not-found-text">
                      Not Assigned
                    </div>
                  }
                </Card>

                <Card
                  className={`nexogic-card-color-header ${pannel_two_on ? "expanded" : ""
                    }`}
                >
                  <div className="card-header">
                    <h2>Medical History</h2>
                    <button
                      className="collapse-btn"
                      type="button"
                      onClick={() => setPannel_two_on(!pannel_two_on)}
                    >
                      <img src={pannel_arrow} alt="" />
                    </button>
                  </div>
                  <div className="collapse-div">
                    <CardBody>
                      <ul className="nexogic-bullets-lists">
                        <li>
                          <p className="card-text">
                            What are your current concerns: Are you presently
                            suffering from any acute illness?
                          </p>
                          <p className="card-text light">{opinionDetails?.title ? opinionDetails?.title : "N/A"}</p>
                        </li>
                        <li>
                          <p className="card-text">
                            Describe your Medical History in brief ?
                          </p>
                          <p className="card-text light">{opinionDetails?.description ?
                            (<div dangerouslySetInnerHTML={{ __html: opinionDetails?.description }}></div>)
                            : "N/A"}
                          </p>
                        </li>
                        <li>
                          <p className="card-text">Symptoms</p>
                          <p className="card-text light">{opinionDetails?.symptoms ? opinionDetails?.symptoms : "N/A"}</p>
                        </li>
                        <li>
                          <p className="card-text">Allergies</p>
                          <p className="card-text light">{opinionDetails?.allergies ? opinionDetails?.allergies : "N/A"}</p>
                        </li>
                        <li>
                          <p className="card-text">Surgeries</p>
                          <p className="card-text light">{opinionDetails?.surgeries ? opinionDetails?.surgeries : "N/A"}</p>
                        </li>
                        <li>
                          <p className="card-text">
                            Do you have a strong family history of any serious
                            illnesses? (cancer, heart disease etc)
                          </p>
                          <p className="card-text light">
                            {opinionDetails?.familyHistory ?
                              (<div dangerouslySetInnerHTML={{ __html: opinionDetails?.familyHistory }}></div>)
                              : "N/A"}
                          </p>
                        </li>
                        <li>
                          <p className="card-text">
                            Any past hospitalizations?
                          </p>
                          <p className="card-text light">
                            {opinionDetails?.pastHospitalization ?
                              (<div dangerouslySetInnerHTML={{ __html: opinionDetails?.pastHospitalization }}></div>)
                              : "N/A"}
                          </p>
                        </li>
                        <li>
                          <p className="card-text">Any current medication?</p>
                          <p className="card-text light">{opinionDetails?.currentMedications ?
                            (<div dangerouslySetInnerHTML={{ __html: opinionDetails?.currentMedications }}></div>)
                            : "N/A"} </p>
                        </li>
                      </ul>
                    </CardBody>
                  </div>
                </Card>
              </div>
            </Col>

            <Col xl={3} className="position-relative">

              <Card>
                <div className="nexogic-widget-sidebar-card card border-0">
                  <div className="card-body">
                    {opinionDetails?.status === 'DRAFT' ? (
                      <Dropdown
                        id="nex-rccf-status-btn"
                        isOpen={dropDownItem == opinionDetails.id}
                        toggle={(e) =>
                          dropDownItem === opinionDetails?.id
                            ? setDropDownItem(null)
                            : setDropDownItem(opinionDetails?.id)
                        }
                      >
                        <DropdownToggle
                          id="nex-rccf-status-btn1"
                          className={`w-100 nexogic_primary_button ${getStatusClass(opinionDetails?.status?.toLowerCase())}`} caret color="primary">
                          {opinionDetails?.status
                            .toLowerCase()
                            .split("_")
                            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                            .join(" ")
                          }
                        </DropdownToggle>
                        {(opinionDetails?.status === 'DRAFT') && (
                          <DropdownMenu right className={`${getStatusClass(opinionDetails?.status?.toLowerCase())}`}>
                            {actions.map((action, index) => (
                              <DropdownItem
                                id={`nex-rccf-status-dynamic-${index}`}
                                key={index}
                                onClick={(e) => {
                                  handleUpdateReferralStatus(action);
                                }}
                              >
                                {opinionDetails?.status != action && action}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        )}
                      </Dropdown>
                    ) : (
                      <span className={`nexogic_primary_button d-block w-100 ${getStatusClass(opinionDetails?.status?.toLowerCase())}`}>
                        {opinionDetails?.status
                          .toLowerCase()
                          .split("_")
                          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                          .join(" ")
                        }
                      </span>
                    )}
                  </div>
                </div>
              </Card>

              <Card className="mt-3 mb-3">
                <div className="nexogic-widget-sidebar-card card border-0">
                  <div className="card-body">
                    <div className="nexogic-h-btn-groups">
                      <Link to={`/u/medicalcases/my-opinions/${opinionId}/response`}
                        className="btn d-block nexogic_primary_button">
                        View Response
                      </Link>
                    </div>
                  </div>
                </div>
              </Card>

              <Card className="mb-3">
                <h2 className="card-title h2 d-flex flex-wrap">
                  Documents

                  {opinionDetails?.status === 'DRAFT' &&
                    <>
                      {isFileUploaing ?
                        <div className="text-center mb-5" style={{ height: "10px" }} >
                          <CommonLoader />
                        </div>
                        :
                        <button
                          className="btn nexogic-download-btn nexogic_primary_button"
                          onClick={handleUploadClick}
                        >
                          <span className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 16 16"
                            >
                              <path
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M15 10.333v3.111A1.556 1.556 0 0113.444 15H2.556A1.556 1.556 0 011 13.444v-3.11m10.889-5.445L8 1m0 0L4.111 4.889M8 1v9.333"
                              ></path>
                            </svg>
                          </span>
                          Uploads
                        </button>
                      }
                      <input
                        name="uploadDocument"
                        type="file"
                        id="uploadDocument"
                        accept="image/jpeg,image/jpg,image/png,application/pdf"
                        title="Upload Document"
                        className="nexogic-file-upload-input"
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                        onChange={handleFileChange}
                      />
                    </>
                  }
                </h2>
                <div className="collapse-div nexogic-so-documents">
                  <CardBody>
                    {(docs && docs.length > 0) ?
                      <div className="nexogic-custom-scroll">
                        <div className="nexogic-document-lists">
                          <ul>
                            {docs.map((doc, index) => (
                              <li key={index} >
                                <div className="nexogic-document-item" style={{ cursor: "pointer" }} onClick={() => getReferralDoc(doc?.id)}>
                                  <div className="graphic">
                                    <svg className='icon'
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="26"
                                      height="32"
                                      fill="none"
                                      viewBox="0 0 26 32"
                                    >
                                      <path
                                        stroke="#3EABCA"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M16 1H4a3 3 0 00-3 3v24a3 3 0 003 3h18a3 3 0 003-3V10m-9-9l9 9m-9-9v9h9m-6 7.5H7m12 6H7m3-12H7"
                                      >
                                      </path>
                                    </svg>
                                  </div>
                                  <div className="card-text">{doc?.name}</div>
                                </div>
                              </li>
                            ))}
                          </ul>

                        </div>
                      </div>
                      :
                      <div className="nexogic-not-found-text">No Documents</div>
                    }
                  </CardBody>
                </div>
                {
                  dataLoading ?
                    <div className="" style={{ zIndex: "111", position: "absolute", top: "0", left: "0", backgroundColor: "#edeff0b0", width: "100%", height: "100%" }}>
                      <div className="component-wrapper"><CommonLoader /></div>
                    </div>
                    :
                    <Modal isOpen={modal} toggle={toggleForm} backdrop="static" scrollable centered >
                      <ModalHeader toggle={toggleForm}><span style={{ color: "gray" }}>Document Viewer</span></ModalHeader>
                      <ModalBody>
                        <div className="modalbody">
                          <img src={documentData} alt="document preview" />
                        </div>
                        <div className="downloadbtn">
                          <FontAwesomeIcon color="#0087b9" size="xl" style={{ cursor: "pointer" }} icon={faDownload} onClick={handleDownload} />
                        </div>
                      </ModalBody>
                    </Modal>
                }
              </Card>

              {opinionDetails?.status === 'DRAFT' &&
                <Card className="mt-3 mb-3">
                  <div className="nexogic-widget-sidebar-card card border-0">
                    <div className="card-body">
                      <div className="nexogic-h-btn-groups">
                        <Link to={`/u/medicalcases/second-opinion/update/${opinionId}`}
                          className="btn d-block nexogic_primary_button">
                          Edit
                        </Link>
                      </div>
                    </div>
                  </div>
                </Card>
              }

              {isPractitioner() &&
                <div className="nexogic-widget-sidebar-card card border-0">
                  <div className="card-body">
                    <div className="nexogic-h-btn-groups">
                      {reqAssign === false && (
                        <a
                          href=""
                          className="btn d-block nexogic_primary_button"
                          onClick={(e) => openAssignModal(e)}
                        >
                          Assign
                        </a>
                      )}

                      {reqAssign === true && (
                        <Link
                          to="/u/medicalcases/details/consultlation/add"
                          className="btn d-block nexogic_primary_button"
                        >
                          Provide  Consultation
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              }

              {/** Selected assign patitioner or groups */}
              {reqAssign === true && (
                <div className="nexogic-widget-sidebar-card card border-0 nexogic-widget-assign">
                  <h3 className="card-title h2">Currently Assign</h3>
                  <div className="card-body">
                    <div className="nexogic-assign-pratitioner">
                      <div className="nexogic-practitioner-search-lists">
                        <div className="item selected">
                          <div className="graphic">
                            <img src={profile_sample} alt="" className="avatar" />
                          </div>
                          <div className="info">
                            <h3 className="card-title">Dr. Anamica Mitra (MBBs)</h3>
                            <p className="card-text light">Amri hospital</p>
                          </div>
                          <span className="active-check">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="9"
                              fill="none"
                              viewBox="0 0 21 14"
                            >
                              <path
                                stroke="#fff"
                                strokeWidth="2"
                                d="M1 4.781L7.88 12 20 1"
                              ></path>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="actions">

                      <a
                        href=""
                        className="btn d-block nexogic_primary_button_red"
                        onClick={(e) => haldleUnassign(e)}
                      >
                        Unassign
                      </a>

                    </div>
                  </div>
                </div>
              )}

              <div className="nexogic-widget-sidebar-card card border-0 nexogic-sidebar-payment-card">
                <h3 className="card-title h2">Payment</h3>
                <div className="card-body">
                  <div className="info">
                    <p>
                      Second Opinion Allergy and Immunology Second Opinion -
                      Full Chart Review
                    </p>
                    <div className="price-sec">
                      <p>Total Payment : $100</p>
                      <p>Paid</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nexogic-widget-sidebar-card card border-0">
                <h3 className="card-title h2">Second Opinion</h3>
                <div className="card-body">
                  <ul className="nexogic-lists nexogic-custom-scroll">
                    <li>
                      <img
                        src={secondOpinionright}
                        alt=""
                        className="img-fluid"
                      />
                    </li>
                  </ul>
                </div>
              </div>

            </Col>
          </Row>

          {/* assign modal */}
          <AssignModal open={isAssignModal} setToggle={setIsAssignModal} onAssign={haldleAssign} />
        </CardBody>
      </Card>
    </div >
  );
}
)