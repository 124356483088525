import CommonLoader from "common/CommonLoader";
import NoJobs from "pages/Jobs/NoJobs";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import FeedItem from "pages/FeedHomePage/components/FeedItem";
import LoadMoreButton from "common/LoadMoreButton/LoadMoreButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faIdCard, faListAlt, faTable } from "@fortawesome/free-solid-svg-icons";
import AllJobsTabularView from "components/tabularView/AllJobsTabularView";

const JobsView = forwardRef((props, ref) => {
  const { city, primarySpeciality, opportunityType, getData, list_of_data, isLoading, loadingMore, setLoadingMore } = props;
  const [currentPage, setCurrentPage] = useState(0);
  const [viewMode, setViewMode] = useState("card");


  const fetchData = () => {
    getData(currentPage + 1, list_of_data.contents, makeQuery(opportunityType || "", city || "", primarySpeciality || ""));
  };

  function makeQuery(sqOpportunity, sqCity, sqPrimary) {
    let query = "";
    if (sqOpportunity !== "") {
      query = `(op=="${encodeURIComponent(sqOpportunity)}")`;
    }
    if (sqCity !== "") {
      if (query === "") query = `(cy=="${encodeURIComponent(sqCity)}")`;
      else query += `;(cy=="${encodeURIComponent(sqCity)}")`;
    }
    if (sqPrimary !== "") {
      if (query === "") query = `(ps=="${encodeURIComponent(sqPrimary)}")`;
      else query += `;(ps=="${encodeURIComponent(sqPrimary)}")`;
    }
    return query;
  }

  useImperativeHandle(ref, () => ({
    getJobListData() {
      getData(0, []);
    },
  }));

  useEffect(() => {
    getData(0, []);
  }, []);

  const loadMore = () => {
    setLoadingMore(true);
    setCurrentPage(currentPage + 1);
    fetchData();
  };

  if (isLoading) {
    return (
      <div className="loading-spiner text-center mt-2">
        <CommonLoader style={{ width: "2rem", height: "2rem" }} />
      </div>
    );
  }

  const style = {
    minHeight: 30,
    border: "1px solid green",
    margin: 6,
    padding: 8,
  };

  const renderProfiles = () => {
    if (viewMode === 'card') {
      return (
        <>
          {list_of_data.contents.map((e, id) => (
            <FeedItem style={style} {...e} key={id} />
          ))}
        </>
      );
    } else { // For table view
      return (
        // <JobsTabularView theader={['Title', 'Speciality', 'Position', 'Experience', 'Location']} tdata={list_of_data.contents} viewmode={viewMode} />
        <AllJobsTabularView theader={['Job Title', 'Specialty', 'Institution', 'Experience', 'City', 'Position', 'Status']} tdata={list_of_data.contents} viewmode={viewMode} updateGrid={fetchData} />
      );
    }
  };

  return (
    <>
      <div className="view-toggle nexogic-view-toggle nex-job-view-toggle">
        <div className="btns">
          <FontAwesomeIcon title="Card View" color={viewMode === "card" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faAddressCard} onClick={() => setViewMode("card")} /> | <FontAwesomeIcon title="Table View" color={viewMode === "table" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faListAlt} onClick={() => setViewMode("table")} />
        </div>
      </div>
      {list_of_data && list_of_data.contents.length > 0 ? (
        <>
          {renderProfiles()}
          {currentPage < list_of_data.totalPages - 1 && (
            <div className="text-center mt-3">
              {loadingMore ?
                <CommonLoader style={{ width: "2rem", height: "2rem" }} />
                :
                <LoadMoreButton loadMore={loadMore} />
              }
            </div>
          )}
        </>
      ) : (
        <NoJobs content={"No Jobs"} />
      )
      }
    </>
  );
});

export default JobsView;
