import React, { useEffect, useState, useRef, useCallback } from 'react'
import { ButtonDropdown, Col, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import './css/style.scss'
import CustomThemeModalForm from './CustomThemeModalForm';
import FormikInputField from 'components/forms/formikFields/FormikInputField';
import { Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeDropper, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const ChooseTheme = (props) => {
  const { setSelectedTheme, values, setFieldValue } = props;
  const [isOpen, setModal] = useState(false);
  const toggleModal = () => setModal(!isOpen);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [colorFlag, setColorFlag] = useState(false);
  // const [currentSelectedTheme, setCurrentSelectedTheme] = useState({ label:values.theme, color:values.color });
  const [themeDropdowItem, setThemeDropdowItem] = useState([
    // { label: "Theme Light", color: ['#e9c46a', '#264653', '#ce9305', '#ffffff', '#f4a261', '#029b9b'], },
    // { label: "Theme Light0", color: ['#609966', '#EDF1D6', '#9DC08B', '#609966', '#40513B', '#9DC08B'] },
    // { label: "Theme Light1", color: ['#e9c46a', '#264653', '#2a9d8f', '#e9c46a', '#f4a261', '#2a9d8f'] },
    // { label: "Theme Light2", color: ['#a8dadc', '#f1faee', '#e63946', '#a8dadc', '#457b9d', '#219ebc'] },
    // { label: "Theme Light3", color: ['#ffb703', '#fefae0', '#219ebc', '#ffb703', '#fb8500', '#219ebc'] },
    // { label: "Theme Light4", color: ['#d62828', '#eae2b7', '#f77f00', '#d62828', '#003049', '#f77f00'] },
    { label: "Custom Theme", color: [`${values.color0}`, `${values.color1}`, `${values.color2}`, `${values.color3}`, `${values.color4}`, `${values.color5}`] },
  ]);

  const colorPalateTitle = ["Background ", "Page Heading", "Page Text", "Background ", "Header Background", "Heading "];

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const selectTheme = (item) => {
    if (item.label === "Custom Theme") {
      toggleModal();
    }
    // setCurrentSelectedTheme(item);
    // setSelectedTheme(item);
    // setFieldValue('color',item.color);
  };

  const themeRefresh = () => {
    setColorFlag(true)
  }

  useEffect(() => {
    themeRefresh()
  }, [values.color0, values.color1])

  // console.log("ChooseTheme==============selectTheme======================", themeDropdowItem,values);   
  return (
    <div className='micro-choose-theme p-2'>
      {/* <div className='choose-theme-wrap'> */}
        {/* <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} animation="false">
                    <DropdownToggle caret>
                        <div className='theme-color-items'>
                            {currentSelectedTheme.color.map((coloritem, index) => (
                                <span style={{ backgroundColor: coloritem }} key={index} title={`${colorPalateTitle[index]}`}></span>
                            ))}
                            <div className='dropdown-custom'>{currentSelectedTheme.label === "Custom Theme" ? currentSelectedTheme.label : ''}</div>
                        </div>
                    </DropdownToggle>
                    <DropdownMenu className='custom-dropdown-toggle'>
                        {themeDropdowItem.map((item, ind) => (
                            <DropdownItem key={'drops_' + ind}>
                                <div className='theme-color-items' onClick={(e) => selectTheme(item)}>
                                    {item.color.map((coloritem, index) => (
                                        <span style={{ backgroundColor: coloritem }} key={index} title={`${colorPalateTitle[index]}`}></span>))}
                                    {item.label === "Custom Theme" ? <div className='dropdown-custom'>{item.label}</div> : ""}
                                </div>
                            </DropdownItem>
))}
                    </DropdownMenu>
                </ButtonDropdown> */}
        {/* {colorFlag && (
                themeDropdowItem.map((item, ind) => (
                    <div key={'drops_' + ind}>
                        <div className='theme-color-items cursor-pointer' role="button" onClick={(e) => selectTheme(item)}>
                            {item.color.map((coloritem, index) => (
                                <span style={{ backgroundColor: coloritem }} key={index} title={`${colorPalateTitle[index]}`}></span>))}
                            {item.label === "Custom Theme" ? <div className='dropdown-custom text-primary font-weight-bold mt-1 ' style={{fontSize:'1rem',padding:'2px 10px',backgroundColor:'#ffffffcc'}}>Choose Colors</div> : ""}
                        </div>
                    </div>
                ))
                 )}            */}
        {/* <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} animation="false">
                    <DropdownToggle caret>
                        <div className='theme-color-items'>
                            {currentSelectedTheme.color.map((coloritem, index) => (
                                <span style={{ backgroundColor: coloritem }} key={index} title={`${colorPalateTitle[index]}`}></span>
                            ))}
                            <div className='dropdown-custom'>{currentSelectedTheme.label === "Custom Theme" ? currentSelectedTheme.label : ''}</div>
                        </div>
                    </DropdownToggle>
                    <DropdownMenu className='custom-dropdown-toggle'>
                        {themeDropdowItem.map((item, ind) => (
                            <DropdownItem key={'drops_' + ind}>
                                <div className='theme-color-items' onClick={(e) => selectTheme(item)}>
                                    {item.color.map((coloritem, index) => (
                                        <span style={{ backgroundColor: coloritem }} key={index} title={`${colorPalateTitle[index]}`}></span>))}
                                    {item.label === "Custom Theme" ? <div className='dropdown-custom'>{item.label}</div> : ""}
                                </div>
                            </DropdownItem>
))}
                    </DropdownMenu>
                </ButtonDropdown> */}
      {/* </div> */}

      {/* <form className="my-form"> */}
      <div className='d-flex flex-wrap justify-content-between w-100'>
        <div className='theme-outer-container'>
          <fieldset className="border border-dark rounded m-1 pl-3 pr-5 pb-3 mb-3">
            <legend className='w-75 text-black-50'>Page Colors</legend>
            <div className='d-flex flex-wrap justify-content-between w-100 flex-column'>
              <div className='d-flex align-items-center justify-content-between mb-2 mb-2'>
                <div>{colorPalateTitle[0]} :</div>
                <div>
                  <div className='choose-color-icon gradient-text'><FontAwesomeIcon icon={faEyeDropper} className="mr-2" /></div>
                  <Field name="color0" type="color" title={colorPalateTitle[0]} component={FormikInputField} placeholder={colorPalateTitle[0]} className='cthemecolor m-0 p-0' />
                </div>
              </div>
              <div className='d-flex align-items-center justify-content-between mb-2'>
                <div>{colorPalateTitle[1]} :</div>
                <div>
                  <div className='choose-color-icon gradient-text'><FontAwesomeIcon icon={faEyeDropper} className="mr-2" /></div>
                  <Field name="color1" type="color" title={colorPalateTitle[1]} component={FormikInputField} placeholder={colorPalateTitle[1]} className='cthemecolor' />
                </div>
              </div>
              <div className='d-flex align-items-center justify-content-between mb-2'>
                <div>{colorPalateTitle[2]} :</div>
                <div>
                  <div className='choose-color-icon gradient-text'><FontAwesomeIcon icon={faEyeDropper} className="mr-2" /></div>
                  <Field name="color2" type="color" title={colorPalateTitle[2]} component={FormikInputField} placeholder={colorPalateTitle[2]} className='cthemecolor' />
                </div>
              </div>
              {/* <legend>Sections</legend>                
                <div className='d-flex width33 align-items-center justify-content-between mb-2'>
                    <div>{colorPalateTitle[1]} :</div>
                    <div>
                    <div className='choose-color-icon text-dark'><FontAwesomeIcon icon={faEyeDropper} className="mr-2"/></div>
                        <Field name="color1" type="color" title={colorPalateTitle[1]} component={FormikInputField} placeholder={colorPalateTitle[1]} className='cthemecolor' />
                    </div>
                </div> */}
              {/* <div className='d-flex width33 align-items-center justify-content-between mb-2'>
                    <div>{colorPalateTitle[3]} :</div>
                    <div>
                        <Field name="color3" type="color" title={colorPalateTitle[3]} component={FormikInputField} placeholder={colorPalateTitle[3]} className='cthemecolor' />
                    </div>
                </div> */}
              {/* <div className='d-flex width33 align-items-center justify-content-between mb-2'>
                    <div>{colorPalateTitle[3]} :</div>
                    <div style={{width:'40%'}}>
                      <div className='choose-color-icon text-dark'><FontAwesomeIcon icon={faEyeDropper} className="mr-2"/></div>
                        <div class="color-input-container mt-1"><Field name="color3" type="color" title={colorPalateTitle[3]} style={{fontSize:'0.8rem'}} component={FormikInputField} placeholder={colorPalateTitle[3]} className='cthemecolor' /></div>
                    </div>
                </div>
                <div className='d-flex width33 align-items-center justify-content-between mb-2'>
                    <div>{colorPalateTitle[2]} :</div>
                    <div style={{width:'40%'}}>
                      <div className='choose-color-icon text-dark'><FontAwesomeIcon icon={faEyeDropper} className="mr-2"/></div>
                        <div class="color-input-container mt-1"><Field name="color2" type="color" title={colorPalateTitle[2]} component={FormikInputField} placeholder={colorPalateTitle[2]} className='cthemecolor' /></div>
                    </div>
                </div>                 */}

              {/* <Row className="border">
            <Col xs={10}>{colorPalateTitle[0]}</Col>
            <Col xs={2}>
              <Field
                name="color0"
                type="color"
                title={colorPalateTitle[0]}
                component={FormikInputField}
                placeholder={colorPalateTitle[0]} 
                // defaultValue={values.color0}               
                className='cthemecolor' />
            </Col>
          </Row> */}
              {/* <Row className="border">
            <Col xs={10}>{colorPalateTitle[2]}</Col>
            <Col xs={2}>
              <Field
                name="color1"
                type="color"
                title={colorPalateTitle[2]}
                component={FormikInputField}
                placeholder={colorPalateTitle[2]}
                className='cthemecolor' />
            </Col>
          </Row>
          <Row className="border">
            <Col xs={10}>{colorPalateTitle[2]}</Col>
            <Col xs={2}>
              <Field
                name="color2"
                type="color"
                title={colorPalateTitle[2]}
                component={FormikInputField}
                placeholder={colorPalateTitle[2]}
                className='cthemecolor' />
            </Col>
          </Row>
          <Row className="border">
            <Col xs={10}>{colorPalateTitle[3]}</Col>
            <Col xs={2}>
              <Field
                name="color3"
                type="color"
                title={colorPalateTitle[3]}
                component={FormikInputField}
                placeholder={colorPalateTitle[3]}
                className='cthemecolor' />
            </Col>
          </Row>
          <Row className="border">
            <Col xs={10}>{colorPalateTitle[4]}</Col>
            <Col xs={2}>
              <Field
                name="color4"
                type="color"
                title={colorPalateTitle[4]}
                component={FormikInputField}
                placeholder={colorPalateTitle[4]}
                className='cthemecolor' />
            </Col>
          </Row>
          <Row className="border">
            <Col xs={10}>{colorPalateTitle[5]}</Col>
            <Col xs={2}>
              <Field
                name="color5"
                type="color"
                title={colorPalateTitle[5]}
                component={FormikInputField}
                placeholder={colorPalateTitle[5]}
                className='cthemecolor' />
            </Col>
          </Row> */}
              {/* <div className="justify-content-end  modal-footer mx-0">
            <Col lg="auto" className="action-group">
              {isLoading ? (
                <div className="loading-spiner">
                  <Spinner />
                </div>
              ) : (
                <>
                  <Button
                    className="nexogic_primary_button"
                    disabled={!isValid || !dirty}
                    color="primary"
                    type="button"
                    onClick={(e) => handleThemeColor()}
                  >
                    Save
                  </Button>
                  <Button className="nexogic_primary_button_outline" onClick={toggle}>
                    Cancel
                  </Button>
                </>
              )}
            </Col>
          </div> */}
            </div>
          </fieldset>
        </div>
        <div className='theme-outer-container'>
          {/* <legend>Sections</legend>                 */}
          {/* <div className='d-flex width33 align-items-center justify-content-between mb-2'>
                    <div>{colorPalateTitle[1]} :</div>
                    <div>
                    <div className='choose-color-icon text-dark'><FontAwesomeIcon icon={faEyeDropper} className="mr-2"/></div>
                        <Field name="color1" type="color" title={colorPalateTitle[1]} component={FormikInputField} placeholder={colorPalateTitle[1]} className='cthemecolor' />
                    </div>
                </div> */}
          <fieldset className="border border-dark rounded m-1 pl-3 pr-5 pb-3 mb-3">
            <legend className='w-75 text-black-50'>Section Colors</legend>
            <div className='d-flex align-items-center justify-content-between mb-2'>
              <div>{colorPalateTitle[3]} :</div>
              <div>
                <div className='choose-color-icon gradient-text'><FontAwesomeIcon icon={faEyeDropper} className="mr-2" /></div>
                <Field name="color3" type="color" title={colorPalateTitle[3]} component={FormikInputField} placeholder={colorPalateTitle[3]} className='cthemecolor m-0 p-0' />
              </div>
              {/* <div>
                      <div className='choose-color-icon text-dark'><FontAwesomeIcon icon={faEyeDropper} className="mr-2"/></div>
                        <div class="color-input-container mt-1"><Field name="color1" type="color" title={colorPalateTitle[1]} style={{fontSize:'0.8rem'}} component={FormikInputField} placeholder={colorPalateTitle[1]} className='cthemecolor' /></div>
                    </div> */}
            </div>
            <div className='d-flex align-items-center justify-content-between mb-2'>
              <div>{colorPalateTitle[4]} :</div>
              <div>
                <div className='choose-color-icon gradient-text'><FontAwesomeIcon icon={faEyeDropper} className="mr-2" /></div>
                <Field name="color4" type="color" title={colorPalateTitle[4]} component={FormikInputField} placeholder={colorPalateTitle[4]} className='cthemecolor m-0 p-0' />
              </div>
              {/* <div>
                      <div className='choose-color-icon text-dark'><FontAwesomeIcon icon={faEyeDropper} className="mr-2"/></div>
                        <div class="color-input-container mt-1"><Field name="color3" type="color" title={colorPalateTitle[3]} style={{fontSize:'0.8rem'}} component={FormikInputField} placeholder={colorPalateTitle[3]} className='cthemecolor' /></div>
                    </div> */}
            </div>
            <div className='d-flex align-items-center justify-content-between mb-2'>
              <div>{colorPalateTitle[5]} :</div>
              <div>
                <div className='choose-color-icon text-body'><FontAwesomeIcon icon={faEyeDropper} className="mr-2" /></div>
                <Field name="color5" type="color" title={colorPalateTitle[5]} component={FormikInputField} placeholder={colorPalateTitle[5]} className='cthemecolor m-0 p-0' />
              </div>
              {/* <div>
                      <div className='choose-color-icon text-dark'><FontAwesomeIcon icon={faEyeDropper} className="mr-2"/></div>
                        <div class="color-input-container mt-1"><Field name="color2" type="color" title={colorPalateTitle[2]} component={FormikInputField} placeholder={colorPalateTitle[2]} className='cthemecolor' /></div>
                    </div> */}
            </div>
          </fieldset>
        </div>
      </div>
      {/* </form> */}
      {/* <CustomThemeModal
              isOpen={isOpen}
              setModal={setModal}
              toggleModal={toggleModal}
              {...props}
            /> */}
      {/* <Modal backdrop="static" toggle={toggleModal} isOpen={isOpen} animation="false">
                <ModalHeader toggle={toggleModal}>Custom Theme</ModalHeader>
                <ModalBody>
                    <CustomThemeModalForm {...props} colortitle={colorPalateTitle}  toggle={toggleModal} setCurrentSelectedTheme={setCurrentSelectedTheme}/>
                    <CustomThemeModalForm {...props} colortitle={colorPalateTitle}  toggle={toggleModal} setDropdownOpen={setDropdownOpen}/>
                </ModalBody>
            </Modal> */}
    </div>
  )
}
export default ChooseTheme;
