import PropTypes from "prop-types";
import { memo, useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import { Button, Spinner } from "reactstrap";
import { ConnectionService } from "utils/ConnectionService";

const FollowUnfollow = (props) => {
  const { practitionerId, instituationId } = props;
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState("");

  const PostFollowingAction = () => {
    setLoading(true);
    ConnectionService.PostFollowing(instituationId)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setLoading(false);
          setShow(response?.data?.id);
          showSuccessMessage("Group followed Successfully.")
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("IdentityService.RegisterNewUser error", error);
      });
  };

  const UnFollowingAction = () => {
    setLoading(true);
    ConnectionService.UnFollowing(show)
      .then((response) => {
        if (response.status === 204) {
          setLoading(false);
          setShow("");
          showSuccessMessage("Group unfollowed Successfully.")
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("IdentityService.RegisterNewUser error", error);
      });
  };

  const checkFollowingForInstitution = () => {
    setLoading(true);
    ConnectionService.checkFollowing(practitionerId, instituationId)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setLoading(false);
          if (response?.data?.id) {
            setShow(response?.data?.id);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("IdentityService.RegisterNewUser error", error);
      });
  };

  useEffect(() => {
    if (practitionerId && instituationId) {
      checkFollowingForInstitution();
    }
  }, [practitionerId, instituationId]);

  return (
    <>
      {loading ? (
        <div className="loading-spiner">
          <Spinner style={{ width: "2rem", height: "2rem" }} />
        </div>
      ) : show ? (
        <>
          <Button
            id="nex-ip-visit-un-follow-btn"
            color="primary"
            className="nexogic_primary_button_outline "
            onClick={UnFollowingAction}
          >
            Un-Follow
          </Button>
        </>
      ) : (
        <Button
          id="nex-ip-visit-follow-btn"
          color="primary"
          className="nexogic_primary_button_outline "
          onClick={PostFollowingAction}
        >
          Follow
        </Button>
      )}
    </>
  );
};

FollowUnfollow.propTypes = {
  practitionerId: PropTypes.string.isRequired,
  instituationId: PropTypes.string.isRequired,
};

export default withAlert()(memo(FollowUnfollow));
