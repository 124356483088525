import { Badge } from "reactstrap";

const ShowStatus = ({ status }) => {   

    switch (status) {
      case "APPLIED":
        return <Badge className="badgetext" color="dark">{status}</Badge>;
      case "SHORTLISTED":
        return <Badge className="badgetext" color="primary">{status}</Badge>;
      case "INTERVIEW":
        return <Badge className="badgetext interview" color="">{status}</Badge>;
      case "ACCEPTED":
        return <Badge className="badgetext accepted"color="">{status}</Badge>;
      case "OFFERED":
        return <Badge className="badgetext" color="warning">{status}</Badge>;
      case "HIRED":
        return <Badge className="badgetext" color="success">{status}</Badge>;
      case "CLOSED":
        return <Badge className="badgetext" color="secondary">{status}</Badge>;
      case "ON_HOLD":
        return <Badge className="badgetext" color="info">{status}</Badge>;
      default:
        return <Badge className="badgetext" color="danger">{status}</Badge>;
    }
  };

  export default ShowStatus;