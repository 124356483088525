import {
  faAngleDown,
  faAngleUp,
  faTimes,
  faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormikDatePicker from "components/forms/formikFields/FormikDatePicker";
import { Field, FieldArray } from "formik";
import { useState } from "react";
import {
  Button,
  Col,
  Collapse,
  FormFeedback,
  InputGroup,
  Label,
  Row
} from "reactstrap";
import "./style.scss";
import drop_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import add_icon from "assets/images/add-icon.png";
import del_icon from "assets/images/del-red-icon.png";

const defaultSchedules = {
  startTime: "",
  endTime: "",
  mon: false,
  tue: false,
  wed: false,
  thu: false,
  fri: false,
  sat: false,
  sun: false,
};

const RenderFieldArray = (props) => {
  const { arrayHelpers } = props;
  const { name } = arrayHelpers;
  const { schedulesHospital } = arrayHelpers.form.values;
  if (schedulesHospital && schedulesHospital.length) {
    return schedulesHospital.map((schedule, indexKey) => {
      return (
        <div className="" key={indexKey}>
          <Row>
            <Col lg={5} className="pl-0 nexogic-date-fields">
              <Row>
                <Col>
                  <InputGroup>
                    <Field
                      id="rs_starttime"
                      name={`${name}[${indexKey}].startTime`}
                      type="text"
                      placeholder="Start Time"
                      component={FormikDatePicker}
                      inputprops={{
                        dateFormat: "h:mm aa",
                        showTimeSelect: true,
                        showTimeSelectOnly: true,
                        calendarClassName: "mycustomcal",
                        autoComplete: "off",
                        showTwoColumnMonthYearPicker: true,
                        timeIntervals: 30,
                      }}
                    />
                  </InputGroup>
                </Col>
                <Col>
                  <InputGroup>
                    <Field
                      id="rs_endtime"
                      name={`${name}[${indexKey}].endTime`}
                      type="text"
                      placeholder="End Time"
                      component={FormikDatePicker}
                      inputprops={{
                        dateFormat: "h:mm aa",
                        showTimeSelect: true,
                        showTimeSelectOnly: true,
                        autoComplete: "off",
                        calendarClassName: "mycustomcal",
                        timeIntervals: 30,
                      }}
                    />
                  </InputGroup>
                </Col>
              </Row>
            </Col>
            <Col lg={6} className="nexogic-date-checkboxes-w">
              <div className="row nexogic-date-checkboxes">
                <div className="col">
                  <Field
                    className="nexogic-day-checkbox"
                    type="checkbox"
                    id={`${name}[${indexKey}].mon`}
                    name={`${name}[${indexKey}].mon`}
                  />
                  <Label htmlFor={`${name}[${indexKey}].mon`}></Label>
                  <Label>Mon</Label>
                </div>
                <div className="col">
                  <Field
                    className="nexogic-day-checkbox"
                    type="checkbox"
                    id={`${name}[${indexKey}].tue`}
                    name={`${name}[${indexKey}].tue`}
                  />
                  <Label htmlFor={`${name}[${indexKey}].tue`}></Label>
                  <Label>Tue</Label>
                </div>
                <div className="col">
                  <Field
                    className="nexogic-day-checkbox"
                    type="checkbox"
                    id={`${name}[${indexKey}].wed`}
                    name={`${name}[${indexKey}].wed`}
                  />
                  <Label htmlFor={`${name}[${indexKey}].wed`}></Label>
                  <Label>Wed</Label>
                </div>
                <div className="col">
                  <Field
                    className="nexogic-day-checkbox"
                    type="checkbox"
                    id={`${name}[${indexKey}].thu`}
                    name={`${name}[${indexKey}].thu`}
                  />
                  <Label htmlFor={`${name}[${indexKey}].thu`}></Label>
                  <Label>Thu</Label>
                </div>
                <div className="col">
                  <Field
                    className="nexogic-day-checkbox"
                    type="checkbox"
                    id={`${name}[${indexKey}].fri`}
                    name={`${name}[${indexKey}].fri`}
                  />
                  <Label htmlFor={`${name}[${indexKey}].fri`}></Label>
                  <Label>Fri</Label>
                </div>
                <div className="col">
                  <Field
                    className="nexogic-day-checkbox"
                    type="checkbox"
                    id={`${name}[${indexKey}].sat`}
                    name={`${name}[${indexKey}].sat`}
                  />
                  <Label htmlFor={`${name}[${indexKey}].sat`}></Label>
                  <Label>Sat</Label>
                </div>
                <div className="col">
                  <Field
                    className="nexogic-day-checkbox"
                    type="checkbox"
                    id={`${name}[${indexKey}].sun`}
                    name={`${name}[${indexKey}].sun`}
                  />
                  <Label htmlFor={`${name}[${indexKey}].sun`}></Label>
                  <Label>Sun</Label>
                </div>
              </div>
              {/* {!!props?.touched &&
                  !!props?.touched?.schedulesHospital &&
                  !!props?.errors &&
                  !!props?.errors?.schedulesHospital &&
                  typeof props.errors.schedulesHospital === "string" && (
                    <div className="d-inline">
                    <FormFeedback
                      className="d-flex"
                      invalid={!!props.errors.schedulesHospital}
                    >
                      {props.errors.schedulesHospital}
                    </FormFeedback>
                    </div>
                  )} */}
            </Col>
            <Col lg={1} className="pr-0 d-flex justify-content-end ml-0 col-auto">
              <Button
                id="rs_btn_del"
                color="link"
                className="cancel-btn"
                onClick={() => arrayHelpers.remove(indexKey)}
              >
                {/*<FontAwesomeIcon
                  icon={faTrashAlt}
                  className="text-danger"
                  style={{ marginTop: "5px", fontSize: "15px" }}
                />*/}
                <img src={del_icon} alt="" />
              </Button>
            </Col>
          </Row>
          {indexKey === schedulesHospital.length - 1 && (
            <Row>
              <Col className="pl-0">
                <Button
                  id="rs_btn_add"
                  color="primary"
                  className="nexogic_primary_button_outline small nexogic-hospita-add-btn"
                  outline
                  onClick={() =>
                    arrayHelpers.insert(indexKey + 1, defaultSchedules)
                  }
                >
                  <img src={add_icon} alt="" />{" Add"}
                </Button>
              </Col>
            </Row>
          )}
        </div>
      );
    });
  }
  return (
    <Row>
      <Col className="mt-2">
        <Button
          id="rs_btn_add_2"
          color="link"
          className="nexogic_primary_button_outline small nexogic-hospita-add-btn"
          onClick={() => arrayHelpers.push({ ...defaultSchedules })}
        >
          <img src={add_icon} alt="" />{"Add"}
        </Button>
      </Col>
    </Row>
  );
};

export const RenderSchedules = (props) => {
  const { values } = props;
  const { schedulesHospital } = values;
  const [openOfficeTimings, setOfficeTimingsOpen] = useState(true);
  const toggleOfficeTimings = () => setOfficeTimingsOpen(!openOfficeTimings);

  return (
    <Col lg="12">
      <Row className="nexogic-form-inner-card card nexogic-hospital-inner-card">
        <Col
          className={`p-0 ${
            !!props?.touched &&
            !!props?.touched?.schedulesHospital &&
            !!props?.errors &&
            !!props?.errors?.schedulesHospital &&
            typeof props.errors.schedulesHospital === "string"
              ? ``
              : ``
          }`}
        >
          <h5 className="card-title">
            Work days and hours
            <img src={drop_arrow} alt="" />
            {/** <FontAwesomeIcon
              icon={openOfficeTimings ? faAngleUp : faAngleDown}
              onClick={toggleOfficeTimings}
              className="fa-fw text-dark"
            />*/}
          </h5>
          {!!props?.touched &&
            !!props?.touched?.schedulesHospital &&
            !!props?.errors &&
            !!props?.errors?.schedulesHospital &&
            typeof props.errors.schedulesHospital === "string" && (
              <FormFeedback
                className="d-flex"
                invalid={!!props.errors.schedulesHospital}
              >
                {props.errors.schedulesHospital}
              </FormFeedback>
            )}
        </Col>
        {/** 
        <Col xs={12} md={6} className="pl-0">
          {schedulesHospital.length > 0 && (
            <Row>
              <Col className="d-none d-md-block">
                <div className="d-flex text-center">
                  <div className="flex-grow-1 mx-1">
                    <Label>Mon</Label>
                  </div>
                  <div className="flex-grow-1 mx-1">
                    <Label>Tue</Label>
                  </div>
                  <div className="flex-grow-1 mx-1">
                    <Label>Wed</Label>
                  </div>
                  <div className="flex-grow-1 mx-1">
                    <Label>Thu</Label>
                  </div>
                  <div className="flex-grow-1 mx-1">
                    <Label>Fri</Label>
                  </div>
                  <div className="flex-grow-1 mx-1">
                    <Label>Sat</Label>
                  </div>
                  <div className="flex-grow-1 mx-1">
                    <Label>Sun</Label>
                  </div>
                </div>
              </Col>
              <Col xs="auto" className="px-1 d-none d-md-block">
                <Button color="link">
                  <FontAwesomeIcon
                    icon={faTimes}
                    className="fa-fw text-white"
                  />
                </Button>
              </Col>
            </Row>
          )}
        </Col>
        */}
        <Collapse isOpen={openOfficeTimings}>
        <FieldArray
          name={`schedulesHospital`}
          render={(arrayHelpers) => (
            <RenderFieldArray arrayHelpers={arrayHelpers} {...props} />
          )}
        />
      </Collapse>
      </Row>
      
    </Col>
  );
};
