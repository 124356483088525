import React, { useEffect, useState, useMemo } from "react";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import Slider from "react-slick";
import { PractitionerService } from "utils/PractitionerService";
// import { createImageFromInitials, getFullAddressWithLocationCityAndState, getRandomColor } from "utils/Utils";
import { encrypt } from "utils/EncryptDecrypt";
import { createImageFromInitials, getFullAddressWithLocationCityAndState, getRandomColor } from "utils/Utils";
import { RenderAllPractitionersModal } from "./RenderAllPractitionersModal";

let settings = {
  dots: false,
  arrows: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      }
    },
  ],
};

const PractitionersContent = (props) => {
  const [pannel_on, setPannel_on] = useState(true);
  const { institution, setShowPractitionersContentOnTabScroll } = props;
  const [practitioners, setPractitioners] = useState([]);
  const [isAllPractitionersOpen, setIsAllPractitionersOpen] = useState(false);
  const toggleAllPractitioners = () =>
    setIsAllPractitionersOpen(!isAllPractitionersOpen);

  useEffect(() => {
    PractitionerService.getPractitionersAssociatedtoInstitutionWithPagination(
      institution?.guid
    ).then((response) => {
      const { status, data } = response;
      if (status === 200) {
        setPractitioners(data.contents);
      } else {
        console.log("Something went wrong");
      }
    });
  }, [institution]);

  useEffect(()=>{
    if(practitioners.length > 0){
      setShowPractitionersContentOnTabScroll(true);
    }else{
      setShowPractitionersContentOnTabScroll(false);
    }
  }, [practitioners])




  return (
    practitioners.length > 0 ?
      <div
        className={`card nexogic-card-color-header practitioners-slider-card  ${pannel_on ? "expanded" : ""
          }`}
        id="practitioners_pannel"
      >
        <div className="card-header">
          <h2>Practitioners who work here</h2>
          <button
            className="collapse-btn"
            type="button"
            onClick={() => setPannel_on(!pannel_on)}
          >
            <img src={pannel_arrow} alt="" />
          </button>
          {practitioners.length >= 10 && (
            <>
              <span
                id="nex-ip-practitioners-view-all-btn"
                className="view_all_text"
                onClick={() => toggleAllPractitioners()}
              >
                View All
              </span>
              {institution && (
                <RenderAllPractitionersModal
                  isOpen={isAllPractitionersOpen}
                  toggle={toggleAllPractitioners}
                  institutionGuid={institution?.guid}
                />
              )}
            </>
          )}
        </div>
        <div className={`collapse-div`}>
          <div className={`card-body`}>
            <Slider {...settings}>
              {practitioners.map((practitioner, idx) => (
                  <div className="slider-item" key={idx}>
                    <div className="card-img">
                      {practitioner?.avatarId ? (
                        <img
                          src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${practitioner?.profileGuid}/${practitioner?.avatarId}`}
                          alt="#"
                        />
                      ) : (
                        <img
                          src={createImageFromInitials(
                            50,
                            practitioner?.name.replace("Dr. ", ""),
                            getRandomColor(
                              encrypt(practitioner?.profileGuid).toString()
                            )
                          )}
                          alt="#"
                        />
                      )}
                    </div>
                    <h3 className="card-title h3 text-truncate">
                      <a
                        href={`../profile/${practitioner?.profileId}`}
                        target="_blank"
                      >
                        {practitioner?.name}
                      </a>
                    </h3>
                    <p className="card-text text-truncate">
                      {practitioner?.primarySpeciality} |{" "}
                      {practitioner?.superSpeciality}
                    </p>
                    <p className="card-text fw-bold ">
                      <strong className="d-block text-truncate">
                        {new Date().getFullYear() -
                          practitioner?.practicingSince >
                          1 ? (
                          <>
                            {" "}
                            {new Date().getFullYear() -
                              practitioner?.practicingSince}
                            {"+ years experience"}
                          </>
                        ) : (
                          "1 year experience"
                        )}
                      </strong>
                    </p>
                    <p className="card-text text-truncate">
                      {getFullAddressWithLocationCityAndState({ location: practitioner?.location ?? "", city: practitioner?.city ?? "", state: practitioner?.state ?? "" })}
                    </p>
                  </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      : ""
  );
};

export default PractitionersContent;
