import { Card } from "reactstrap";
import { encrypt } from "utils/EncryptDecrypt";
import { createImageFromInitials, getFullAddressWithLocationCityAndState, getRandomColor, getGenderDetails, capitalizeFirstLetter } from "utils/Utils";
import { Link, useRouteMatch } from "react-router-dom";
import RefaralComment from "pages/Referals/Components/AddPatient/Components/RefaralComment";
import RefaralDocument from "pages/Referals/Components/AddPatient/Components/RefaralDocument";
import response_icon from 'assets/images/svgs/response-icon.svg';

const MyReferralCard = (props) => {
    const { referral } = props;

    return (
        <Card className="nexogic-card-list-custom nexogic-reffer-card">
            <div className="card-body">
                <div className="row need-margin">
                    <div className="col-lg-12 col-status nexogic-status-right">

                        {/* {referral.status.toLowerCase() === 'draft' && (
                  <Link to={`/referrals/update/${referral?.referralCaseNo}`}>
                    <span className="status-pill cursor-pointer">
                            <img src={edit_blue_icon} alt="" width="18" className="mx-2"/>
                        </span>
                  </Link>
                )} */}


                        {/* {referral.status.toLowerCase() === 'draft' ?
                        <span className="status-pill cursor-pointer">
                            <img src={edit_blue_icon} alt="" width="18" className="mx-2"/>
                        </span>
                        :
                        ''
                    } */}

                        <span className={`status-pill ${referral.status.toLowerCase() === 'closed' ? 'accepted ' : ''}            
                    ${referral.status.toLowerCase() === 'submitted' ? 'accepted ' : ''}`}>
                            {referral?.status
                                .toLowerCase()
                                .split("_")
                                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(" ")
                            }
                        </span>
                    </div>


                    <div className="col Referring-physician-col">
                        <h3 className=" card-title big"> Referring Physician</h3>
                        <div className="head">
                            {
                                referral?.referredByPhysician?.avatarId ?
                                    <img
                                        src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.referredByPhysician?.profileGuid}/${referral?.referredByPhysician?.avatarId}`}
                                        className="card-img"
                                        alt="#"
                                    />
                                    :
                                    <>
                                        <img
                                            src={createImageFromInitials(
                                                50,
                                                `${referral?.referredByPhysician?.name.replace("Dr. ", "")}`,
                                                getRandomColor(
                                                    encrypt(
                                                        referral?.referredByPhysician?.profileGuid
                                                    ).toString()
                                                )
                                            )}
                                            className="card-img"
                                            alt="#"
                                        />
                                    </>
                            }
                            <div className="info">
                                <p className="card-text"><b>Name  </b>  <Link to={`/profile/` + referral?.referredByPhysician?.profileId} >{referral?.referredByPhysician?.name}</Link></p>
                                <p className="card-text"><b>Address  </b>
                                    {getFullAddressWithLocationCityAndState({ location: referral?.referredByPhysician?.location ?? "", city: referral?.referredByPhysician?.city ?? "", state: referral?.referredByPhysician?.state ?? "" })}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col Referring-to-col">
                        <h3 className=" card-title big"> Receiving Physician </h3>
                        <div className="head">
                            <div className="card-graphic">
                                {
                                    referral?.referredToPhysician?.avatarId ?
                                        <img
                                            src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.referredToPhysician?.profileGuid}/${referral?.referredToPhysician?.avatarId}`}
                                            className="card-img"
                                            alt="#"
                                        />
                                        :
                                        <>
                                            <img src={createImageFromInitials(50, `${referral?.referredToPhysician?.name.replace("Dr. ", "")}`, getRandomColor(encrypt(referral?.referredToPhysician?.profileGuid).toString()))}
                                                className="card-img"
                                                alt="#"
                                            />
                                        </>
                                }

                            </div>
                            <div className="info">
                                <p className="card-text"><b>Name  </b>  <Link to={`/profile/` + referral?.referredToPhysician?.profileId} >{referral?.referredToPhysician?.name}</Link></p>
                                <p className="card-text"><b>Address  </b>
                                    {getFullAddressWithLocationCityAndState({ location: referral?.referredToPhysician?.location ?? "", city: referral?.referredToPhysician?.city ?? "", state: referral?.referredToPhysician?.state ?? "" })}
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="row need-margin reason-sec">
                    <div className="col-lg-12">
                        {referral?.referralReason &&
                            <>
                                <hr />
                                <h3 className="card-title"> Reason:</h3>
                                <div className="card-text">
                                    <div dangerouslySetInnerHTML={{ __html: referral?.referralReason }}></div>
                                </div>
                            </>
                        }

                        <div className="card-text d-flex"><div className="ml-auto mt-2"> <Link to={`/referrals/my-referrals/${referral?.id}`} className="nexogic_primary_button_outline ">Show Details</Link></div></div>
                    </div>
                </div>
                <div className="nexogic-meta-description">
                    {referral &&
                        <div className="row need-margin">
                            {(referral && referral?.responseId) ?
                                <div className="col-auto">
                                    <a href={`/referrals/my-response/${referral?.id}`} className="nex-link no-underline">
                                        <span className="icon" ><img width={28} src={response_icon} />
                                        </span>
                                        View Response
                                    </a>
                                </div>
                                :
                                <div className="col-auto">
                                    <span className="nex-link no-underline">
                                        <span className="icon" ><img width={28} src={response_icon} />
                                        </span>
                                        No Response
                                    </span>
                                </div>
                            }
                            {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
                                <div className="col-auto ml-auto"><RefaralComment id={'ref_connemt_' + referral.id} /></div>
                            }
                            <div className="col-auto ml-auto"><RefaralDocument id={'ref_document_' + referral.id} referral={referral} /></div>
                        </div>
                    }
                </div>

            </div>
        </Card >
    );
}

export default MyReferralCard;