import verifyMobileImg from "assets/images/otp-flow/verifyMobile.svg";
import { Formik } from "formik";
import { useState } from "react";
import { withAlert } from "react-alert";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { emailNonNumericRegEx, FAILTOSAVERECOED } from "utils/Constants";
import { IdentityService } from "utils/IdentityService";
import * as Yup from "yup";
import Header from "../../common/Header";
import ForgetPassForm from "./components/ForgetPassForm";
import { useEffect } from "react";

const validationSchema = Yup.object({
  email: Yup.string()
    .max(50)
    .matches(emailNonNumericRegEx, "Please Enter an valid Email")
    .required("This field is required"),
});

const ForgotPasswordNew = (props) => {
  const initialValues = {
    app: "practitioner",
    email: "",
  };
  const [isLoading, setLoading] = useState(false);
  const [showInput, setShowInput] = useState(true);

  const onSubmit = async (values, action) => {
    setLoading(true);
    IdentityService.ForgotPassword(values).then((response) => {
      if (response.status === 200) {
        props.alert.success(
          "Reset password Link has been sent successfully in your email"
        );
        action.resetForm();
        setShowInput(false);
      } else {
        if (response.validationErrors) {
          response.validationErrors.map((err) => {
            props.alert.error(FAILTOSAVERECOED);
            return true;
          });
        } else if (response.message) {
          const { status } = response;
          if (status === 404) {
            props.alert.error(
              "The username you entered is not valid, please enter the correct username"
            );
          } else {
            props.alert.error(FAILTOSAVERECOED);
          }
        }
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    document.body.classList.add('register-screen-page');
    document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "Password Assistance";
    return () => {
      document.body.classList.remove('register-screen-page');
      document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "";
    }
}, [])

  return (
    <section>
      <div className="nexogic-bg-light">
        <Header />
        <div className="nexogic-main-content-wrap nexogic-py-lg">
          <Container className="nexogic-container-gap">
            <div className="nexogic-login-register-card card border-0 bg-transparent">
              <Row>
                <Col md={6} lg={7} xs={12} className="nexogic-form-side-content text-center">
                  <div className="nexogic-form-info">
                    <img
                      src={verifyMobileImg}
                      alt="nexogic"
                      className="img-fluid"
                    />
                  </div>
                </Col>
                <Col md={6} lg={5} xs={12} className="nexogic-form-content card-body p-0">
                  <div className="nexogic-form-container">
                    {showInput ?
                      <div className="mb-3">
                        <h3 className="nexogic-form-title">
                          {" "}
                          Password Assistance{" "}
                        </h3>
                        <p className="nexogic-form-title-sub">
                          Enter your email to recover your password. You will receive
                          an email with instructions. If you are having problems
                          recovering your password{" "}
                          <a
                            href={`${process.env.REACT_APP_WEBSITE_URL}/contact-us/`}
                            target="_blank"
                          >
                            contact
                          </a>
                        </p>
                        <Formik
                          component={(props) => {
                            return (
                              <ForgetPassForm {...props} isLoading={isLoading} />
                            );
                          }}
                          initialValues={initialValues}
                          onSubmit={onSubmit}
                          validationSchema={validationSchema}
                        />
                      </div>
                      :
                      <>
                        <div className="nexogic-form-title-sub">
                          <h2>
                            {" "}
                            Create a new password{" "}
                          </h2>
                          <span className="card-text">If a Nexogic account exists with that email address, you’ll receive an email shortly. </span>
                          <span className="card-text"> If you are having problems recovering your password <a
                              href={`${process.env.REACT_APP_WEBSITE_URL}/contact-us/`}
                              target="_blank"
                              className=""
                            >
                              contact us
                            </a></span>
                          <div className="mt-3 d-flex btn-groups">
                            <span
                              className="nexogic_primary_button_outline title-case"
                              onClick={() => setShowInput(true)}
                            >
                              try again
                            </span>
                            
                          </div>
                          
                        </div>
                      </>
                    }

                    <Row className="">
                      <Col xs={12} className="card-text fw-semibold">
                        <Link to="/login" className="nexogic_primary_button btn btn-block mb-3">Sign in</Link>
                      </Col>
                      <Col xs={12} className="card-text fw-semibold text-center">
                        <span className="d-block">
                          Not a member?{" "}
                          <Link
                            to={`/register`}
                          >
                            Sign Up
                          </Link>
                        </span>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </section>
  );
};
export default withAlert()(ForgotPasswordNew);
