import { useState } from "react";
import MedicalHistoryForm1 from "./MedicalHistoryForm1";
import MedicalHistoryForm2 from "./MedicalHistoryForm2";
import MedicalHistoryForm3 from "./MedicalHistoryForm3";
import { useEffect } from "react";
import { OpinionService } from "utils/OpinionService";
import { withAlert } from "react-alert";

const MedicalHistoryForm = ({ currentStepInMainForm, setCurrentStepInMainForm, opinionId, forUpdate }) => {
    const [currentStep, setCurrentStep] = useState('1');

    const [opinionInfo, setOpinionInfo] = useState({});

    const getOpinionInfo = (id) => {
        OpinionService.getOpinion(id)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    // console.log(data);
                    setOpinionInfo(data);
                }
            })
    }


    useEffect(() => {
        setCurrentStep('1');
    }, [currentStepInMainForm])

    useEffect(() => {
        if (opinionId)
            getOpinionInfo(opinionId);
    }, [opinionId])


    return (
        <>
            {currentStep === '1' && <MedicalHistoryForm1
                forUpdate={forUpdate}
                opinionInfo={opinionInfo}
                setOpinionInfo={setOpinionInfo}
                setCurrentStep={setCurrentStep}
                setCurrentStepInMainForm={setCurrentStepInMainForm}
                getOpinionInfo={getOpinionInfo}
            />}

            {currentStep === '2' && <MedicalHistoryForm2
                forUpdate={forUpdate}
                opinionInfo={opinionInfo}
                setOpinionInfo={setOpinionInfo}
                setCurrentStep={setCurrentStep}
                setCurrentStepInMainForm={setCurrentStepInMainForm}
                getOpinionInfo={getOpinionInfo}
            />}

            {currentStep === '3' && <MedicalHistoryForm3
                opinionInfo={opinionInfo}
                setOpinionInfo={setOpinionInfo}
                setCurrentStep={setCurrentStep}
                setCurrentStepInMainForm={setCurrentStepInMainForm}
                getOpinionInfo={getOpinionInfo}
            />}
        </>
    )
}

export default withAlert()(MedicalHistoryForm);