import React from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import phone_icon from "assets/svgIcon/phone-call-icon.svg";
import email_icon from "assets/svgIcon/email-icon.svg";
import { useEffect } from "react";
import { useState } from "react";

const ContactDetails = (props) => {
    const { practitioner } = props;
    const [phones, setPhones] = useState([]);
    const [emails, setEmails] = useState([]);

    useEffect(() => {
        setEmails(practitioner.emails);
        setPhones(practitioner.phones);
    }, [practitioner.guid]);

    return (

        <>
            {((phones && phones.length > 0) || (emails && emails.length > 0)) &&
                <div className="card nexogic-widget-sidebar-card">
                    <div className="card-body">
                        <div className="card-group nexogic-card-group-list">
                            <Card className="nexogic-social-card" id="socialSection">
                                <CardBody>
                                    <h5 className="card-title">Contact Details</h5>
                                    {phones.map((phone, index) => (
                                        <Row key={index} className="py-1">
                                            <Col xs="auto" className="px-0">
                                                <img src={phone_icon} width="15" alt="phicon" />
                                            </Col>
                                            <Col>
                                                {phone.value}
                                            </Col>
                                        </Row>
                                    ))}
                                    {emails.map((email, index) => (
                                        <Row key={index} className="py-1">
                                            <Col xs="auto" className="px-0">
                                                <img src={email_icon} width="15" alt="emailicon" />
                                            </Col>
                                            <Col>
                                                {email.value}
                                            </Col>
                                        </Row>
                                    ))}
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div >
            }
        </>
    );
};

export default ContactDetails;
