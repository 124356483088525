import { Formik } from "formik";
import { memo, useCallback, useMemo, useState, useEffect } from "react";
import { withAlert } from "react-alert";
import { useHistory, useParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { emailNonNumericRegEx } from "utils/Constants";
import { TalentService } from "utils/TalentService";
import { MetadataService } from "utils/MetadataService";
import { getAddressName, getLocationName, makeFullNameWithAddressWhenAddressIsNested } from "utils/Utils";
import { isAdmin } from "utils/UserHelper";
import * as Yup from "yup";
import FormStep1 from "./components/FormStep1";
import FormStep2 from "./components/FormStep2";
import Stepper from "./components/Stepper";
import "./style.scss";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

// status: INPROGRESS, COMPLETED
const jobSteps = [
  {
    key: "job_details",
    name: "Job Details",
    status: "INPROGRESS",
  },
  {
    key: "additional_details",
    name: "Additional Information",
    status: "",
  },
];

const validationSchema = Yup.object().shape({
  // institutionDetail: Yup.lazy((value) => {
  //   if (value || (value?.guid === "" || value?.guid === undefined)) {      
  //   console.log("value==============================",value?.guid);
  //     return Yup.object().test(
  //       "",
  //       "This field is required. You must select from options or add new",
  //       () => false
  //     );
  //   } else {
  //     return Yup.mixed().test(
  //       "",
  //       "This field is required. You must select from options or add new",
  //       (value) => value !== null
  //     );
  //   }
  // }),
  // institutionDetail: Yup.string().required("Please select Hospital/Clinic"),
  opportunity: Yup.string().required("This field is required"),
  position: Yup.string().required("This field is required"),
  noOfPosition: Yup.number()
    .required("This field is required")
    .min(1, "The number of positions must be at least 1")
    .max(100, "The number of positions cannot exceed 100"),
  qualifications: Yup.array()
    .min(1, "This field is required")
    .required("This field is required"),
  title: Yup.string().required("This field is required"),
  description: Yup.string().required("This field is required"),
  primarySpeciality: Yup.array().min(1, "This field is required"),
  skills: Yup.array().min(1, "This field is required")
    .required("This field is required"),
  shift: Yup.string().required("This field is required"),
  role: Yup.string(),
  city: Yup.array()
    .min(1, "This field is required")
    .required("This field is required")
    .nullable(),
  location: Yup.array()
    .min(1, "This field is required")
    .required("This field is required")
    .nullable(),
  applyMode: Yup.string().required("This field is required"),
  linkToApply: Yup.string()
    .when("applyMode", {
      is: (applyMode) => applyMode === "EMAIL",
      then: Yup.string()
        .max(100)
        .matches(emailNonNumericRegEx, "Please Enter an valid Email")
        .required("This field is required"),
      otherwise: Yup.string().url("Please enter a valid URL"),
    })
    .required("This field is required"),
  id: Yup.string(),
  minExperience: Yup.number(),
  maxExperience: Yup.number().when("minExperience", (minExperience, schema) => {
    return schema.test({
      test: (maxExperience) => {
        if (!maxExperience) return true;
        return maxExperience >= minExperience;
      },
      message: "Maximum Experience must be greater than Minimum Experience",
    });
  }),
  minSalary: Yup.number(),
  maxSalary: Yup.number().when("minSalary", (minSalary, schema) => {
    return schema.test({
      test: (maxSalary) => {
        if (!maxSalary) return true;
        return maxSalary >= minSalary;
      },
      message: "Maximum Salary must be greater than Minimum Salary",
    });
  }),
  screeningQuestions: Yup.array()
    .of(
      Yup.object().shape({
        question: Yup.string().required("Please type a question"),
        renderType: Yup.string().required("Please select answer type"),
        // value: Yup.string().required("This field is required"),
      })
    ),
  // .unique("question", "Please type a different question."),
  // .unique("question", "Please type a different question."),
});

const initialValues = {
  institutionDetail: '',
  opportunity: "JOB",
  position: "",
  title: "",
  description: "",
  qualifications: [],
  primarySpeciality: [],
  skills: [],
  shift: "",
  role: "",
  city: [],
  location: [],
  id: "",
  linkToApply: "",
  minExperience: "",
  maxExperience: "",
  minSalary: "",
  maxSalary: "",
  applyMode: "",
  screeningQuestions: [],
};

const CreateJob = (props) => {
  const { alert } = props;
  const [steps, setSteps] = useState(jobSteps);
  const [loading, setloading] = useState(false);
  const history = useHistory();
  const [isOpenAlert, setAlertModal] = useState(false);
  const [fetchLoading, setfetchLoading] = useState(false);
  const isAdminUser = useMemo(() => isAdmin());
  const [initialValuesForm, setInitialValuesForm] = useState(initialValues);
  const params = useParams();
  const { jobId } = params;

  const activeStep = useMemo(() => {
    return steps.length > 0 && steps.find((s) => s.status === "INPROGRESS");
  }, [steps]);

  const onNext = useCallback(() => {
    const nextIndex = steps.findIndex((s) => s.key === activeStep.key) + 1;
    setSteps((preState) => {
      return preState.map((s, i) => {
        if (i < nextIndex) return { ...s, status: "COMPLETED" };
        if (i === nextIndex) return { ...s, status: "INPROGRESS" };
        return { ...s };
      });
    });
  }, [steps, activeStep]);

  const onBack = useCallback(() => {
    const prevIndex = steps.findIndex((s) => s.key === activeStep.key) - 1;
    setSteps(
      steps.map((s, i) => {
        if (i === prevIndex) return { ...s, status: "INPROGRESS" };
        if (s.status === "INPROGRESS") return { ...s, status: "" };
        return { ...s };
      })
    );
  }, [steps, activeStep]);

  const addItem = async (value, actions) => {
    setloading(true);
    let payload = { ...value };
    try {
      const formData = isAdminUser ? {
        ...payload,
        ...(payload.institutionDetail.guid && {
          institutionId: payload.institutionDetail.guid,
        }),
        ...(payload.primarySpeciality.length > 0 && {
          primarySpeciality: payload.primarySpeciality[0],
        }),
        city: getAddressName(payload?.city).split(",")[0],
        location: getLocationName(payload?.location),
        ...(payload.minSalary && {
          minSalary: payload.minSalary,
        }),
        ...(payload.maxSalary && {
          maxSalary: payload.maxSalary,
        }),
        qualifications:
          payload?.qualifications.length > 0
            ? payload?.qualifications.join(",")
            : "",
      } : {
        ...payload,
        ...(payload.institutionDetail && {
          institutionId: payload.institutionDetail,
        }),
        ...(payload.primarySpeciality.length > 0 && {
          primarySpeciality: payload.primarySpeciality[0],
        }),
        city: getAddressName(payload?.city).split(",")[0],
        location: getLocationName(payload?.location),
        ...(payload.minSalary && {
          minSalary: payload.minSalary,
        }),
        ...(payload.maxSalary && {
          maxSalary: payload.maxSalary,
        }),
        qualifications:
          payload?.qualifications.length > 0
            ? payload?.qualifications.join(",")
            : "",
      };

      if (formData.minExperience === "") {
        formData.minExperience = null;
      }

      if (formData.maxExperience === "") {
        formData.maxExperience = null;
      }

      const request = TalentService.registerJob(formData);
      request
        .then(async (response) => {
          const { status, data } = response;
          if (status === 201) {
            setloading(false);
            actions.setFieldValue("id", data?.id);
            onNext();
          } else {
            setloading(false);
            let errmsg =
              response.data && !!response.data.message
                ? response.data.message
                : "Failed to save. Please try again";
            showErrorMessage(errmsg);
          }
        })
        .catch((error) => {
          setloading(false);
          console.log(error);
        });
    } catch (e) {
      setloading(false);
      console.log(e);
    }
  };

  const updateItem = async (value) => {
    setloading(true);
    let payload = { ...value };
    try {
      const formData = isAdminUser ? {
        ...payload,
        ...(payload.institutionDetail.guid && {
          institutionId: payload.institutionDetail.guid,
        }),
        ...(payload.primarySpeciality.length > 0 && {
          primarySpeciality: payload.primarySpeciality[0],
        }),
        city: getAddressName(payload?.city).split(",")[0],
        location: getLocationName(payload?.location),
        ...(payload.minSalary && {
          minSalary: payload.minSalary,
        }),
        ...(payload.maxSalary && {
          maxSalary: payload.maxSalary,
        }),
        qualifications:
          payload?.qualifications.length > 0
            ? payload?.qualifications.join(",")
            : "",
        ...(payload.maxSalary && {
          maxSalary: payload.maxSalary,
        }),
        ...(activeStep.key === "additional_details" && {
          status: "SUBMITTED",
        }),
      } : {
        ...payload,
        ...(payload.institutionDetail && {
          institutionId: payload.institutionDetail,
        }),
        ...(payload.primarySpeciality.length > 0 && {
          primarySpeciality: payload.primarySpeciality[0],
        }),
        city: getAddressName(payload?.city).split(",")[0],
        location: getLocationName(payload?.location),
        ...(payload.minSalary && {
          minSalary: payload.minSalary,
        }),
        ...(payload.maxSalary && {
          maxSalary: payload.maxSalary,
        }),
        qualifications:
          payload?.qualifications.length > 0
            ? payload?.qualifications.join(",")
            : "",
        ...(payload.maxSalary && {
          maxSalary: payload.maxSalary,
        }),
        ...(activeStep.key === "additional_details" && {
          status: "SUBMITTED",
        }),
      };


      if (formData.minExperience === "") {
        formData.minExperience = null;
      }

      if (formData.maxExperience === "") {
        formData.maxExperience = null;
      }

      const request = TalentService.updateJob(formData, formData?.id);
      request
        .then(async (response) => {
          const { status } = response;
          if (status === 200) {
            showSuccessMessage("Job Updated Successfully...");
            setloading(false);
            if (activeStep.key === "job_details") {
              onNext();
            } else {
              history.push("/jobsboard/posted-jobs");
            }
          } else {
            setloading(false);
            let errmsg =
              response.data && !!response.data.message
                ? response.data.message
                : "Failed to save. Please try again";
            showErrorMessage(errmsg);
          }
        })
        .catch((error) => {
          setloading(false);
          console.log(error);
        });
    } catch (e) {
      setloading(false);
      console.log(e);
    }
  };

  const getJob = (jobId) => {
    if (jobId) {
      (async () => {
        setfetchLoading(true);
        TalentService.getJob(jobId)
          .then(async (response) => {
            if (response.status === 200 || response.status === 201) {
              let { data } = response;
              let clone = { ...data };
              let { institutionDetails } = clone;
              if (institutionDetails?.address?.city) {
                const inputCity = institutionDetails?.address?.city;
                const cityObj = await MetadataService.getCitiesWithoutStateId(
                  inputCity
                );
                const { status, data } = cityObj;
                if (
                  (status === 200 || status === 201) &&
                  data &&
                  data.length > 0
                ) {
                  const citylist = data.map((node) => {
                    const { cityId, description } = node;
                    const nodeCityName =
                      description.split(",")[0] ?? description;
                    const cityName = nodeCityName;
                    return {
                      value: cityName,
                      id: cityId,
                    };
                  });
                  const calCity = citylist.filter(
                    (node) =>
                      node.value.toLowerCase() === inputCity.toLowerCase()
                  );
                  if (calCity.length > 0) {
                    clone.institutionDetails.address.cityId = calCity[0].id;
                  }
                  return {
                    ...clone,
                  };
                }
              }
            } else if (response.status === 400 || response.status === 404) {
              history.goBack();
            }
            return null;
          })
          .then((response) => {
            if (response) {
              const {
                opportunity,
                institutionDetails,
                position,
                noOfPosition,
                title,
                description,
                qualifications,
                primarySpeciality,
                skills,
                shift,
                role,
                id,
                location,
                city,
                linkToApply,
                minExperience,
                maxExperience,
                minSalary,
                maxSalary,
                applyMode,
              } = response;
              setInitialValuesForm((preState) => ({
                ...preState,
                ...(institutionDetails.guid && {
                  institutionDetail: isAdminUser ? {
                    guid: institutionDetails.guid,
                    institutionName: makeFullNameWithAddressWhenAddressIsNested(institutionDetails),
                  } : institutionDetails.guid,
                }),
                ...(opportunity && {
                  opportunity: opportunity,
                }),
                ...(noOfPosition && {
                  noOfPosition: noOfPosition,
                }),
                ...(position && {
                  position: position,
                }),
                ...(title && {
                  title: title,
                }),
                ...(description && {
                  description: description,
                }),
                ...(qualifications && {
                  qualifications: qualifications.split(","),
                }),
                ...(primarySpeciality && {
                  primarySpeciality: primarySpeciality.split(","),
                }),
                ...(skills &&
                  skills.length > 0 && {
                  skills: skills,
                }),
                ...(shift && {
                  shift: shift,
                }),
                ...(role && {
                  role: role,
                }),
                // ...(city &&{
                //   city:[
                //     {
                //       id:response.cityId,
                //       value: city,
                //     },
                //   ]
                // }),
                // ...(location &&{
                //   location:[
                //     {
                //       value: location,
                //     },
                //   ]
                // }),
                ...(institutionDetails.address.city && {
                  city: [
                    {
                      id: institutionDetails.address.cityId,
                      value: institutionDetails.address.city,
                    },
                  ],
                }),
                ...(institutionDetails.address.location && {
                  location: [institutionDetails.address.location],
                }),
                ...(id && {
                  id: id,
                }),
                ...(linkToApply && {
                  linkToApply: linkToApply,
                }),
                ...(minExperience && {
                  minExperience: minExperience,
                }),
                ...(maxExperience && {
                  maxExperience: maxExperience,
                }),
                ...(minSalary && {
                  minSalary: minSalary,
                }),
                ...(maxSalary && {
                  maxSalary: maxSalary,
                }),
                ...(applyMode && {
                  applyMode: applyMode,
                }),
              }));
              setfetchLoading(false);
            }
          })
          .catch((error) => {
            setfetchLoading(false);
            console.log("Error while getting connection status: ", error);
          });
      })();
    }
  };

  useEffect(() => {
    if (jobId) {
      getJob(jobId);
    }
  }, [jobId]);
  return (
    <section id="jobCreatePage">
      <div className="bg-light-blue">
        <div className=" content">
          <Row className="nex-app-no-mar">
            <Col className="nex-app-no-pad">
              <div className="page-wrapper">
                <Row>
                  <Col lg={12} md={12} sm={12} className="pt-2 pb-3">
                    <Stepper steps={steps} />
                  </Col>
                  <Col lg={12} md={12} sm={12}>
                    <Formik
                      initialValues={initialValuesForm}
                      validationSchema={validationSchema}
                      onSubmit={(values, actions) => {
                        if (isAdminUser && values?.institutionDetail === '' || values?.institutionDetail.guid === '') {
                          actions.setErrors({ institutionDetail: 'Please select Hospital/Clinic ' });
                          return false;
                        }
                        if (!isAdminUser && values?.institutionDetail === '') {
                          actions.setErrors({ institutionDetail: 'Please select Hospital/Clinic ' });
                          return false;
                        }
                        if (values?.id) {
                          updateItem(values);
                        } else {
                          addItem(values, actions);
                        }
                      }}
                      enableReinitialize
                      children={(formProps) => {
                        return (
                          <>
                            {activeStep.key === "job_details" && (
                              <FormStep1
                                {...props}
                                {...formProps}
                                loading={loading}
                                fetchLoading={fetchLoading}
                              />
                            )}
                            {activeStep.key === "additional_details" && (
                              <FormStep2
                                onBack={onBack}
                                {...props}
                                {...formProps}
                                loading={loading}
                              />
                            )}
                          </>
                        );
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default withAlert()(memo(CreateJob));
