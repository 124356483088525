import { useState } from "react";
import marker_icon from "assets/images/marker_icon.png";
import { PractitionerService } from "utils/PractitionerService";
import { Link } from "react-router-dom";
import { useRef } from "react";
import { Button, Card, CardBody, Collapse } from "reactstrap";
import { useEffect } from "react";
import { createImageFromInitials, getRandomColor } from "utils/Utils";
import { encrypt } from "utils/EncryptDecrypt";
import NoConnection from "pages/Connections/components/NoConnection";
import CommonLoader from "common/CommonLoader";

const PeopleKnowWidget = (props) => {
    const { speciality } = props;
    const [practitioners, setPractitioners] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const currentPage = useRef(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(true);
    const toggle = () => setIsOpen(!isOpen);

    const fetchData = () => {
        try {
            const pageNo = currentPage.current + 1;
            currentPage.current = pageNo;

            PractitionerService.getPractitionerProfiles(`ps == '*${encodeURIComponent(speciality)}*'`, pageNo)
                .then((response) => {
                    if (response.status === 200) {
                        const { currentPage, totalPages, contents } = response.data;
                        setPractitioners((prevPractitioners) => [...prevPractitioners, ...contents]);
                        setHasMore(totalPages - currentPage !== 1);
                    }
                })
                .catch((err) => {
                    console.log(err.message);
                });
        } catch (e) {
            if (alert) alert.error(e.message);
        }
    };

    useEffect(() => {
        PractitionerService.getPractitionerProfiles(`ps == '*${encodeURIComponent(speciality)}*'`, 0)
            .then((response) => {
                if (response.status === 200) {
                    const { currentPage, totalPages, contents } = response.data;
                    setPractitioners(contents);
                    setIsLoading(false);
                    setHasMore(totalPages - currentPage !== 1);
                }
            })
            .catch((err) => {
                setIsLoading(false);
                console.log(err.message);
            });
    }, [alert]);


    return (
        <Card className={`nexogic-widget-sidebar-card job-card bg-white ${isOpen ? 'content-colapse-open' : 'content-colapse-close'}`}>
            <h3 className="nex-aside-title" onClick={toggle}>People you may Know <span className="collapse-arrow"></span></h3>
            <Collapse isOpen={isOpen}>
                <CardBody>
                    {isLoading ?
                        <div className="text-center">
                            <CommonLoader />
                        </div>
                        :
                        <>
                            <div className="nexogic-custom-scroll" style={{ height: "500px", overflow: "auto" }}>
                                <div className="card-group nexogic-card-group-list">
                                    {practitioners.map((item, index) => (
                                        <div className="card" key={`job_list${index} `}>
                                            <div className="row no-gutters align-items-start">
                                                {
                                                    <div className="col-auto icon">
                                                        <Link to={`/profile/${item?.profileId}`}>
                                                            {item && item.avatarId ? (
                                                                <img
                                                                    src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${item?.profileGuid}/${item?.avatarId}`}
                                                                    className="card-img"
                                                                    alt="#"
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={createImageFromInitials(
                                                                        50,
                                                                        `${item?.name} `,
                                                                        getRandomColor(encrypt(item?.profileGuid).toString())
                                                                    )}
                                                                    className="card-img"
                                                                    alt="#"
                                                                />
                                                            )}
                                                        </Link>
                                                    </div>
                                                }
                                                <div className="col">
                                                    <h4 className="card-title">
                                                        {item?.name && (
                                                            <Link to={`/profile/${item?.profileId}`}>
                                                                {item?.name}
                                                                {", "}
                                                                {item?.qualification}
                                                            </Link>
                                                        )}
                                                    </h4>
                                                    <p className="card-text">
                                                        {item?.primarySpeciality && (
                                                            <small className="text-muted">
                                                                {item?.primarySpeciality}
                                                            </small>
                                                        )}
                                                    </p>
                                                    <p className="card-text">
                                                        <small className="text-muted"><img src={marker_icon} alt="#" /> {item?.location}{" "}
                                                            {item?.city}</small>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    {practitioners.length === 0 && <NoConnection content={"No Candidate"} />}

                                </div>
                            </div>

                            {practitioners.length > 0 && hasMore && (
                                <div className="mt-2">
                                    <Button color="link" className="readmore btn-link" onClick={fetchData}>
                                        View More
                                    </Button>
                                </div>
                            )}
                        </>
                    }
                </CardBody>
            </Collapse>
        </Card>
    )
}

export default PeopleKnowWidget;