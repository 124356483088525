import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import { Field } from "formik";
import { Button, Col, Input, Label, Row } from "reactstrap";
import { getOAuthUser } from "utils/UserHelper";
import del_icon from "assets/images/del-red-icon.png";

const emailType = [
  {
    label: "Work",
    value: "WORK",
  },
  {
    label: "Personal",
    value: "PERSONAL",
  },
  {
    label: "Alternate",
    value: "ALTERNATE",
  },
];

const removeSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-trash"
    viewBox="0 0 16 16"
  >
    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
    <path
      fillRule="evenodd"
      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
    />
  </svg>
);

export const RenderEmailFormFields = (props) => {
  const { index, values, remove, setFieldValue } = props;
  const oAuthUser = getOAuthUser();
  const regEmailAddress = oAuthUser.emailAddress;

  return (
    <Row>
      <Col md={3}>
      <label className="">Type</label>
        <Field
          id={`emails[${index}].type`}
          name={`emails[${index}].type`}
          type="text"
          component={FormikSelectField}
          value={values.emails[index].type}
          inputprops={{
            name: `emails[${index}].type`,
            options: emailType,
            keys: {
              id: "value",
              label: "label",
            },
          }}
          disabled={values.emails[index].primary}
        />
      </Col>
      <Col md={6}>
      <label className="">Email</label>
        <Field
          id={`emails[${index}].value`}
          name={`emails[${index}].value`}
          type="text"
          component={FormikInputField}
          placeholder="Enter Email"
          value={values.emails[index].value}
          disabled={values.emails[index].primary}
        />
      </Col>
      <Col
        md={3}
       
      >
        <label className="d-sm-node">&nbsp;</label>
        <div className="form-group form-check">
          <Label check>
            <Input
              type="radio"
              id={`primaryItemEmails`}
              name={`primaryItemEmails`}
              onChange={() => {
                const fullItem = values.emails.map((node, kIndex) => {
                  return {
                    ...node,
                    primary: kIndex === index,
                  };
                });
                setFieldValue(`emails`, fullItem);
                setFieldValue(`primaryItemEmails`, index.toString(), true);
              }}
              checked={values.primaryItemEmails.toString() === index.toString()}
            /> <span>Primary</span>
          </Label>
          {!values.emails[index].primary && (
  
          <Button
            color="danger"
            className=""
            onClick={() => {
              const fullItem = values.emails.filter(
                (_, indexItem) => indexItem !== index
              );
              const primaryIndex = fullItem.findIndex(
                (node) => node.primary === true
              );
              remove(index);
              setFieldValue(`emails`, fullItem);
              setFieldValue(`primaryItemEmails`, primaryIndex.toString());
            }}
            disabled={regEmailAddress === values.emails[index].value}
          >
            {/*removeSVG()*/}
            <img src={del_icon} alt="" />
          </Button>

      )}
        </div>
      </Col>
      
    </Row>
  );
};
