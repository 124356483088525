import AlertDialog from "common/AlertDialog";
import { first, groupBy } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import {
  profileAddEvent,
  set_Recent_Experience_user,
  updateProfileCompletness,
} from "redux-helpers/ActionCreator";
import { UserStatusConstants } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import {
  convertToMMMYYYYFormat,
  dateDifferenceInYearsAndMonths,
  getEmptySectionShowHide,
  getFullAddress,
  makeFullNameWithAddressWhenAddressIsNested,
} from "utils/Utils";
import { RenderExperienceModal } from "./components/RenderExperienceModal";
import "./style.scss";

import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import collage_icon from "assets/svgIcon/collage-icon.svg";
import small_marker from "assets/svgIcon/marker-small.svg";
import plusIcon from "assets/images/plus-icon-lg.png";
import edit_blue_icon from "assets/images/edit-blue-icon.png";
import del_red_icon from "assets/images/del-red-icon.png";
import NoData from "../NoData";

const initialValue = {
  id: "",
  institutionProfile: {
    institutionName: "",
    guid: "",
    onSelectNewInstitutionCollege: 0,
  },
  position: "",
  description: "",
  fromDate: "",
  toDate: "",
  expires: false,
  mainItemName: "",
  city: "",
  state: "",
  country: "",
  location: "",
  phone: "",
  postalCode: "",
  addressLine1: "",
  websiteAddress: "",
  category: "",
};

const RenderExpCard = (props) => {
  const {
    sortedExperience,
    keyValue,
    isAdmin,
    handleEditItem,
    handleRemoveItem,
    isLoggedinUser,
  } = props;
  const isMultipleExp = sortedExperience[keyValue].length > 1 ? true : false;
  const currentUser = useSelector((state) => state.auth.currentUser);
  return sortedExperience[keyValue].map((item, index) => {
    const location = item.institutionProfile?.address?.city || item.location;
    return (
      <Row className={`experience-position-item ${isMultipleExp ? 'multiple-items' : 'single-item'}`} key={`exp_posi_${index}`}>
        <Col xs="auto" className="experience-position-icon auto">
          {isMultipleExp ? (
            item.expires ? (
              <>
                <span className="bullet-icon active"></span>
                {/*<FontAwesomeIcon
                icon={faCircle}
                className="fa-fw f-8 experience-icon active"
            />*/}
              </>
            ) : (
              <>
                <span className="bullet-icon"></span>
                {/** <FontAwesomeIcon
                icon={faCircle}
                className="fa-fw f-8 experience-icon"
              />*/}
              </>
            )
          ) : null}
        </Col>
        <Col>
          {location && <p className="card-text"><small className="text-muted"><span className="icon"><img src={small_marker} alt="" /></span>{location}</small></p>}
          <p className="card-text "><small><strong>{item?.positionName}</strong></small> </p>
          {item?.startYear &&
            <p className="card-text">
              <small>
                {convertToMMMYYYYFormat(item?.startYear)} {" - "}
                {item?.presentlyWorking
                  ? <span><span>{"Present | "}</span><span>{dateDifferenceInYearsAndMonths(item?.startYear)}</span></span>
                  : <span>{convertToMMMYYYYFormat(item?.endYear)}</span>}
              </small>
            </p>
          }

        </Col>
        <Col className="col-auto d-flex align-items-start">
          <div className="delete-action">
            {(isAdmin ||
              (isLoggedinUser &&
                currentUser.status === UserStatusConstants.APPROVED)) && (
                <>
                  <Button
                    color="link"
                    className="visible-hover py-0 nex-e-exp-edit-btn"
                    onClick={() => handleEditItem(item)}
                  >

                    <img src={edit_blue_icon} alt="" />

                  </Button>

                  <Button
                    color="link"
                    className="visible-hover py-0 delete nex-e-exp-del-btn"
                    onClick={() => handleRemoveItem(item)}
                  >
                    <img src={del_red_icon} alt="" />
                  </Button>
                </>
              )}
          </div>
        </Col>
      </Row>
    );
  });
};

const RenderExperience = (props) => {
  const { experience, isLoggedinUser, isAdmin, handleEditItem } = props;
  const currentUser = useSelector((state) => state.auth.currentUser);
  if (experience && experience.length) {
    let sortedExperience = experience.sort((a, b) => {
      return b.fromYear - a.fromYear;
    });

    sortedExperience = experience.sort((a, b) => {
      return b.expires - a.expires;
    });

    sortedExperience = sortedExperience.map((exp) => {
      if (exp?.institutionDetail?.guid) exp.name = exp?.institutionDetail?.name;
      return exp;
    });
    sortedExperience = groupBy(sortedExperience, "name");
    return Object.keys(sortedExperience).map((key, index) => {
      const profileIdLink = sortedExperience[key][0]?.institutionDetail
        ?.profileId
        ? sortedExperience[key][0]?.institutionDetail?.profileId
        : sortedExperience[key][0]?.institutionDetail?.guid;
      const infoInsObj = {
        name: key ?? "",
        abbreviation:
          sortedExperience[key][0]?.institutionDetail?.abbreviation ?? "",
        location:
          sortedExperience[key][0]?.institutionDetail?.address?.location ?? "",
        city: sortedExperience[key][0]?.institutionDetail?.address?.city ?? "",
        state:
          sortedExperience[key][0]?.institutionDetail?.address?.state ?? "",
      };
      const institutionName = getFullAddress(infoInsObj);
      const avatarId = sortedExperience[key][0]?.institutionDetail?.avatarId ?? null;
      const instGuid = sortedExperience[key][0]?.institutionDetail?.guid ?? null;
      return (
        <li
          className="list-group-item"
          key={`keyName_${index}`}
        >
          <Row className="g-0">
            {avatarId ?
              <div className="col-auto">
                <img
                  src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${instGuid}/${avatarId}`}
                  alt=""
                  style={{ height: "40px" }}
                />
              </div>
              :
              <div className="col-auto">
                <img src={collage_icon} alt="" />
              </div>
            }
            <Col>

              <h3 className="card-title"><Link
                to={`/institution/${profileIdLink}`}
                className=""
              >{institutionName}</Link></h3>

              <div className="editDelBtn-section d-none">
                <div className="px-0">
                  <button
                    type="button"
                    className="visible-hover py-0 btn btn-link nex-e-exp-edit-btn"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-pencil"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"></path>
                    </svg>
                  </button>
                  <button
                    type="button"
                    className="visible-hover py-0 delete btn btn-link nex-e-exp-del-btn"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-trash"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"></path>
                      <path
                        fillRule="evenodd"
                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                      ></path>
                    </svg>
                  </button>
                </div>
              </div>

              <RenderExpCard
                keyValue={key}
                sortedExperience={sortedExperience}
                {...props}
              />
            </Col>
          </Row>
        </li>
      );
    });
  }

  return (
    <div className="py-2">
      <Row className="align-items-center">
        <Col>
          {(isLoggedinUser || isAdmin) &&
            currentUser.status === UserStatusConstants.APPROVED && (
              <Button
                id="nex-e-exp-add-btn"
                className="add-infomation p-0"
                color="link"
                onClick={() => handleEditItem(initialValue, "add")}
              >
                {"Add experience"}
              </Button>
            )}
        </Col>
      </Row>
    </div>
  );
};

const Experience = (props) => {
  const [pannel_on, setPannel_on] = useState(true);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const eventName = useSelector((state) => state.profileComplete.eventName);
  const dispatch = useDispatch();
  const [experience, setExperience] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const { guid } = props.practitioner;
  const userGUID = props.practitioner.guid;
  const { alert, isLoggedinUser, isAdmin, setShowExperienceOnTabScroll } = props;
  const [isOpen, setModal] = useState(false);
  const toggle = () => setModal(!isOpen);
  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );
  const [isOpenAlert, setAlertModal] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const toggleAlert = () => {
    setLoading(false);
    setAlertModal(!isOpenAlert);
  };

  useEffect(() => {
    if (userGUID) {
      getData();
    }
  }, [userGUID]);

  useEffect(() => {
    dispatch(updateProfileCompletness(0, !!experience.length));
    if (experience.length > 0 || isLoggedinUser || isAdmin) {
      setShowExperienceOnTabScroll(true);
    }else{
      setShowExperienceOnTabScroll(false);
    }
  }, [experience]);

  useEffect(() => {
    if (eventName === "ADD_EXPERIENCE") {
      handleEditItem(initialValue);
      dispatch(profileAddEvent(null));
    }
  }, [eventName]);

  const handleEditItem = (selectedItem) => {
    const formData = {
      institutionProfile: {
        institutionName: selectedItem?.institutionDetail ? makeFullNameWithAddressWhenAddressIsNested(selectedItem?.institutionDetail) : "",
        guid: selectedItem?.institutionDetail?.guid ?? "",
        onSelectNewInstitutionCollege: selectedItem?.institutionDetail?.guid
          ? 1
          : 0,
      },
      position: selectedItem?.positionName ?? "",
      description: selectedItem?.description ?? "",
      fromDate: selectedItem?.startYear ?? "",
      toDate: selectedItem?.endYear ?? "",
      expires: selectedItem?.expires ?? false,
      mainItemName: selectedItem?.institutionDetail?.name ?? "",
      id: selectedItem?.guid ?? "",
      city: "",
      state: "",
      country: "",
      location: "",
      phone: "",
      postalCode: "",
      addressLine1: "",
      websiteAddress: "",
      category: "",
    };
    setSelectedItem(formData);
    toggle();
  };

  const handleRemoveItem = (selectedItem) => {
    const formData = {
      institutionProfile: {
        institutionName: selectedItem?.institutionDetail?.name ?? "",
        guid: selectedItem?.institutionDetail?.guid ?? "",
        onSelectNewInstitutionCollege: selectedItem?.institutionDetail?.guid
          ? 1
          : 0,
      },
      position: selectedItem?.positionName ?? "",
      description: selectedItem?.description ?? "",
      fromDate: selectedItem?.startYear ?? "",
      toDate: selectedItem?.endYear ?? "",
      expires: selectedItem?.expires ?? false,
      mainItemName: selectedItem?.institutionDetail?.name ?? "",
      id: selectedItem?.guid ?? "",
      city: "",
      state: "",
      country: "",
      location: "",
      phone: "",
      postalCode: "",
      addressLine1: "",
      websiteAddress: "",
      category: "",
    };
    setSelectedItem(formData);
    toggleAlert();
  };

  const handleClickOnYes = async () => {
    const userGUID = globalProfileInfo.guid;
    setLoading(true);
    try {
      const request = PractitionerService.deleteExperiences(
        userGUID,
        selectedItem?.id
      );
      request
        .then(async (response) => {
          const { status } = response;
          if (status === 204) {
            await getData();
          } else {
            setLoading(false);
            let errmsg =
              response.data && !!response.data.message
                ? response.data.message
                : "Failed to save. Please try again";
            showErrorMessage(errmsg);
          }
        })
        .then(() => {
          setLoading(false);
          toggleAlert();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getData = async () => {

    const request = PractitionerService.listExperiences(userGUID);
    request
      .then((response) => {
        let { data } = response;
        if (data && data.length > 0) {
          const list_of_data = data.map((node) => {
            const [fromMonth, fromYear] = node?.startYear
              ? node?.startYear.split("-")
              : "";
            const [toMonth, toYear] = node?.endYear
              ? node?.endYear.split("-")
              : "";
            return {
              ...node,
              id: node?.guid ?? "",
              expires: node.presentlyWorking,
              fromMonth:
                fromMonth && fromYear
                  ? moment(fromMonth + "/01/" + fromYear).format("MMMM")
                  : "",
              fromYear:
                fromMonth && fromYear
                  ? moment(fromMonth + "/01/" + fromYear).format("YYYY")
                  : "",
              toMonth:
                toMonth && toYear
                  ? moment(toMonth + "/01/" + toYear).format("MMMM")
                  : "",
              toYear:
                toMonth && toYear
                  ? moment(toMonth + "/01/" + toYear).format("YYYY")
                  : "",
              toDate: node?.endYear,
            };
          });
          setExperience(list_of_data);
          const incoming_data = first(list_of_data)
            ? [first(list_of_data)]
            : [];
          dispatch(set_Recent_Experience_user(incoming_data));
        } else {
          setExperience([]);
          dispatch(set_Recent_Experience_user([]));
        }
      })
      .catch((e) => {
        console.log("PractitionerService.listExperiences", e);
      });
  };

  if (
    getEmptySectionShowHide(
      experience ?? [],
      isAdmin,
      currentUser,
      globalProfileInfo,
      guid
    )
  )
    return null;

  return (
    <>
      <Card
        className={`nexogic-card-color-header ${pannel_on ? 'expanded' : ''}`}
        id="experienceSection"
      >
        <div className="card-header"><h2>Experience</h2>
          {(isAdmin ||
            (isLoggedinUser &&
              currentUser.status === UserStatusConstants.APPROVED)) && (

              <Button
                id="nex-es-experience-add-btn"
                color="link"
                className="add-button"
                onClick={() => handleEditItem(initialValue)}
              >
                <img src={plusIcon} alt="" />
              </Button>

            )}

          <button className="collapse-btn" type="button" onClick={() => setPannel_on(!pannel_on)}>
            <img src={pannel_arrow} alt="" />
          </button>

        </div>
        <div className={`collapse-div`}>
          <CardBody>
            <ul className="list-group list-group-flush" id="nex-es-experience-lists">
              <RenderExperience
                experience={experience}
                handleEditItem={handleEditItem}
                handleRemoveItem={handleRemoveItem}
                isLoggedinUser={isLoggedinUser}
                isAdmin={isAdmin}
              />
            </ul>
            {experience.length < 1 && 
            <NoData>No Data</NoData>
            }
          </CardBody>
        </div>
      </Card>
      <RenderExperienceModal
        selectedItem={selectedItem}
        toggle={toggle}
        isOpen={isOpen}
        guid={guid}
        getData={getData}
        isAdmin={isAdmin}
      />
      <AlertDialog
        toggle={toggleAlert}
        isOpen={isOpenAlert}
        isLoading={isLoading}
        handleClickOnYes={handleClickOnYes}
        yesLabel={"Yes"}
        noLabel={"No"}
      />
    </>
  );
};

export default withAlert()(Experience);
