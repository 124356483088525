import React, { useState, useEffect, useRef } from "react";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  Col,
  Row,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import { Field, Formik } from "formik";
import { PractitionerService } from "utils/PractitionerService";
import * as Yup from "yup";
import { map } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import filterIcon from "assets/images/svgs/filter_icon.svg";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-datepicker/dist/react-datepicker.css";
import { FORM_CONSTANTS, doctorQualifications } from "utils/Constants";
import InstitutesTypeaheadField from "components/forms/formikFields/InstitutesTypeaheadField";
import { connect } from "react-redux";
import {
  applyFilter,
  handleRemoveFilter,
} from "../../../../redux-helpers/ConnectionsFilterActionCreator";
import { useDispatch, useSelector } from "react-redux";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const validationSchema = Yup.object().shape({
  location: Yup.string(),
  country: Yup.string(),
  hospital: Yup.string(),
  specialty: Yup.string(),
  university: Yup.string(),
  field_of_study: Yup.string(),
});

const Filters = (props) => {
  let { selectedFilter } = props;
  let dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState();
  // const [selectedFilter, setSelectedFilter] = useState(initialValue);
  const specialtyRef = useRef(null);
  const fieldOfStudyRef = useRef(null);
  const [specialty, setspecialty] = useState([]);
  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        PractitionerService.getPractitionersSkills().then((response) => {
          if (response && response.data && response.data.status === "SUCCESS") {
            const { data } = response.data;
            setspecialty(map(data.skill, "name"));
          }
        });
      } catch (e) {
        showErrorMessage(e.message);
      }
    };

    getData();
  }, []);

  const handleSumbit = (formValues) => {
    let filters = [];
    // setSelectedFilter(formValues);
    dispatch(applyFilter(formValues));
    toggle();
    // for (var key in formValues) {
    //     if (formValues.hasOwnProperty(key)) {
    //       var val = formValues[key];
    //       console.log(val);
    //     }
    // }
  };

  const handleRemoveFilterData = (type) => {
    let data = selectedFilter;
    data[type] = "";
    dispatch(handleRemoveFilter(data));
  };

  return (
    <div className="ml-auto  d-flex align-items-center">
      {selectedFilter.location != "" && (
        <div className="filter-chips d-flex align-items-center">
          <div className="mr-2 black-color-accent f-14 line-h-14">
            {selectedFilter.location}
          </div>
          <div
            className="ml-auto cursor-pointer"
            onClick={() => handleRemoveFilterData("location")}
          >
            <FontAwesomeIcon color="#5E6D82" size="sm" icon={faTimes} />
          </div>
        </div>
      )}
      {selectedFilter.country != "" && (
        <div className=" ml-2 filter-chips d-flex align-items-center">
          <div className="mr-2 black-color-accent f-14 line-h-14">
            {selectedFilter.country}
          </div>
          <div
            className="ml-auto cursor-pointer"
            onClick={() => handleRemoveFilterData("country")}
          >
            <FontAwesomeIcon color="#5E6D82" size="sm" icon={faTimes} />
          </div>
        </div>
      )}
      {selectedFilter.hospital != "" && (
        <div className=" ml-2 filter-chips d-flex align-items-center">
          <div className="mr-2 black-color-accent f-14 line-h-14">
            {selectedFilter.hospital.institutionName}
          </div>
          <div
            className="ml-auto cursor-pointer"
            onClick={() => handleRemoveFilterData("hospital")}
          >
            <FontAwesomeIcon color="#5E6D82" size="sm" icon={faTimes} />
          </div>
        </div>
      )}
      {selectedFilter.specialty != "" && (
        <div className=" ml-2 filter-chips d-flex align-items-center">
          <div className="mr-2 black-color-accent f-14 line-h-14">
            {selectedFilter.specialty}
          </div>
          <div
            className="ml-auto cursor-pointer"
            onClick={() => handleRemoveFilterData("specialty")}
          >
            <FontAwesomeIcon color="#5E6D82" size="sm" icon={faTimes} />
          </div>
        </div>
      )}
      {selectedFilter.university != "" && (
        <div className=" ml-2 filter-chips d-flex align-items-center">
          <div className="mr-2 black-color-accent f-14 line-h-14">
            {selectedFilter.university}
          </div>
          <div
            className="ml-auto cursor-pointer"
            onClick={() => handleRemoveFilterData("university")}
          >
            <FontAwesomeIcon color="#5E6D82" size="sm" icon={faTimes} />
          </div>
        </div>
      )}
      {selectedFilter.field_of_study != "" && (
        <div className=" ml-2 filter-chips d-flex align-items-center">
          <div className="mr-2 black-color-accent f-14 line-h-14">
            {selectedFilter.field_of_study}
          </div>
          <div
            className="ml-auto cursor-pointer"
            onClick={() => handleRemoveFilterData("field_of_study")}
          >
            <FontAwesomeIcon color="#5E6D82" size="sm" icon={faTimes} />
          </div>
        </div>
      )}
      <ButtonDropdown
        className="mb-2 nexogic-link-dropdown-group"
        isOpen={dropdownOpen}
        toggle={toggle}
      >
        <DropdownToggle color="link" className="nexogic-link-button" >
          <span className="">
            Filter
          </span>
          <img src={filterIcon} />
        </DropdownToggle>
        <DropdownMenu className="nexogic-filter-menu modal-content ">
          <Formik
            enableReinitialize={true}
            validationSchema={validationSchema}
            initialValues={selectedFilter}
            // onSubmit={(values) => {
            //     if (values.id) {

            //     } else {

            //     }
            // }}
            children={(props) => {
              return (
                <form>
                  <Row>
                    <Col md={6}>
                      <Field
                        id="location"
                        name="location"
                        type={"text"}
                        label="Location"
                        component={FormikInputField}
                        placeholder="Location"
                      />
                    </Col>
                    <Col md={6}>
                      <Field
                        id="country"
                        name="country"
                        type={"text"}
                        label="Country"
                        component={FormikInputField}
                        placeholder="Country"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Field
                        id="hospital"
                        name="hospital"
                        type={"text"}
                        label="Hospital"
                        component={InstitutesTypeaheadField}
                        placeholder="Hospital"
                      />
                    </Col>
                    <Col md={6}>
                      {/* <Field
                                                id="specialty"
                                                name="specialty"
                                                type={'text'}
                                                label="Specialty"
                                                component={FormikInputField}
                                                placeholder="Specialty"
                                            />     */}
                      <FormGroup className="searchbox-input-chips">
                        <Label>Specialty</Label>
                        <Typeahead
                          ref={specialtyRef}
                          allowNew
                          clearButton
                          newSelectionPrefix="specialty"
                          selected={props.values.specialty}
                          id="specialty"
                          placeholder="Specialty"
                          options={specialty}
                          onBlur={() => {
                            return specialtyRef !== null
                              ? specialtyRef.current.hideMenu()
                              : null;
                          }}
                          onChange={(selected) => {
                            let selectedAry = selected.map(function (el) {
                              if (el.label) {
                                return el.label;
                              } else {
                                return el;
                              }
                            });
                            props.setFieldValue("specialty", selectedAry, true);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Field
                        id="university"
                        name="university"
                        type={"text"}
                        label="University"
                        component={FormikInputField}
                        placeholder="University"
                      />
                    </Col>
                    <Col md={6}>
                      {/* <Field
                                                id="field_of_study"
                                                name="field_of_study"
                                                type={'text'}
                                                label="Field of study"
                                                component={FormikInputField}
                                                placeholder="Field of study"
                                            />     */}
                      <FormGroup className="searchbox-input-chips">
                        <Label>Field of study</Label>
                        <Typeahead
                          ref={fieldOfStudyRef}
                          allowNew
                          clearButton
                          newSelectionPrefix="Field of study: "
                          selected={props.values.field_of_study}
                          id="field_of_study"
                          placeholder="Field of study"
                          options={doctorQualifications}
                          onBlur={() => {
                            return fieldOfStudyRef !== null
                              ? fieldOfStudyRef.current.hideMenu()
                              : null;
                          }}
                          onChange={(selected) => {
                            let selectedAry = selected.map(function (el) {
                              if (el.label) {
                                return el.label;
                              } else {
                                return el;
                              }
                            });
                            props.setFieldValue(
                              "field_of_study",
                              selectedAry,
                              true
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="">
                    <Col
                      
                      className="justify-content-end  modal-footer "
                    >
                      <div className="action-group">
                      <Button
                        className="nexogic_primary_button "
                        onClick={(e) => handleSumbit(props.values)}
                      >
                        Apply
                      </Button>
                      </div>
                    </Col>
                  </Row>
                </form>
              );
            }}
          ></Formik>
        </DropdownMenu>
      </ButtonDropdown>
    </div>
  );
};

const mapStateToProps = ({ connectionsFilter }) => ({
  selectedFilter: connectionsFilter,
});

export default connect(mapStateToProps, null)(Filters);
