import CommonLoader from "common/CommonLoader";
import NoJobs from "pages/Jobs/NoJobs";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { TalentService } from "utils/TalentService";
import FeedItem from "pages/FeedHomePage/components/FeedItem";
import FilterForAllJobs from "./Components/FilterForAllJobs";
import LoadMoreButton from "common/LoadMoreButton/LoadMoreButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faListAlt } from "@fortawesome/free-solid-svg-icons";
import AllJobsTabularView from "components/tabularView/AllJobsTabularView";
import { isPractitioner } from "utils/UserHelper";

const AllJobs = forwardRef((props, ref) => {
    const [list_of_data, setList_of_data] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [primarySpeciality, setPrimarySpeciality] = useState("");
    const [city, setCity] = useState("");
    const [opportunityType, setOpportunityType] = useState("");
    const [hasMore, setHasMore] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [viewMode, setViewMode] = useState("card");

    const getApplicantStatus = (jobId) => {
        return new Promise((resolve, reject) => {
            TalentService.getJobApplicantStatus(jobId)
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        resolve(response.data?.status);
                    } else {
                        reject(new Error('Failed to fetch status'));
                    }
                })
                .catch((error) => {
                    console.log("Error while getting applicant status: ", error);
                    reject(error); // Reject on error
                });
        });
    };

    // const getApplicantStatus = (jobId) => {
    //     console.log("getApplicantStatus-------------------------------",jobId);
    //     TalentService.getJobApplicantStatus(jobId)
    //       .then((response) => {
    //         let { data } = response;
    //         if (response.status === 200 || response.status === 201) {
    //           console.log("getApplicantStatus------------------------data-------",data,data.status);
    //           setApplicantStatus(data?.status);
    //         }
    //       })
    //       .catch((error) => {
    //         console.log("Error while getting connection status: ", error);
    //       });

    //   };

    const getData = async (pageNo, feedlist, sq1 = "") => {
        const request = TalentService.listJob({ cp: pageNo, sq: sq1 });
        request
            .then(async (response) => {
                let { data } = response;
                if (data?.currentPage < (data?.totalPages - 1))
                    setHasMore(true);
                else
                    setHasMore(false);
                setIsLoadingMore(false);
                if (pageNo === 0) {
                    const allData = { ...data };
                    const resolved = await Promise.all(
                        allData.contents.map(async (item) => {
                            const each = { ...item };
                            if (isPractitioner()) {
                                const status = await getApplicantStatus(item.id);
                                each.applystatus = status;
                            }
                            return each;
                        })
                    );
                    allData.contents = resolved;
                    setList_of_data(allData);
                    setIsLoading(false);
                } else {
                    const resolved = await Promise.all(
                        data.contents.map(async (item) => {
                            const each = { ...item };
                            return each;
                        })
                    );
                    feedlist = feedlist.concat(resolved);
                    setList_of_data({ ...data, contents: feedlist });
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                console.log("Error while getting job list: ", error);
                setIsLoadingMore(false);
            });
    };

    const fetchData = () => {
        setIsLoadingMore(true);
        getData(list_of_data.currentPage + 1, list_of_data.contents, makeQuery(opportunityType ? opportunityType : "", city ? city : "", primarySpeciality ? primarySpeciality : ""));
    };

    function makeQuery(sqOpportunity, sqCity, sqPrimary) {
        let query = "";
        if (sqOpportunity !== "") {
            query = `(op=="${encodeURIComponent(sqOpportunity)}")`;
        }

        if (sqCity !== "") {
            if (query === "")
                query = `(cy=="${encodeURIComponent(sqCity)}")`;
            else
                query += `;(cy=="${encodeURIComponent(sqCity)}")`;
        }

        if (sqPrimary !== "") {
            if (query === "")
                query = `(ps=="${encodeURIComponent(sqPrimary)}")`;
            else
                query += `;(ps=="${encodeURIComponent(sqPrimary)}")`;
        }

        return query;
    }

    useImperativeHandle(ref, () => ({
        getJobListData() {
            getData(0, []);
        },
    }));

    useEffect(() => {
        getData(0, []);
    }, []);

    if (isLoading) {
        return (
            <div className="loading-spiner text-center  mt-2">
                <CommonLoader style={{ width: "2rem", height: "2rem" }} />
            </div>
        );
    }

    const style = {
        minHeight: 30,
        border: "1px solid green",
        margin: 6,
        padding: 8,
    };

    return (
        <>
            <FilterForAllJobs setPrimarySpeciality={setPrimarySpeciality} setCity={setCity} setOpportunityType={setOpportunityType} getData={getData} />
            <div className="view-toggle nexogic-view-toggle">
                <div className="btns">
                    <FontAwesomeIcon title="Card View" color={viewMode === "card" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faAddressCard} onClick={() => setViewMode("card")} /> | <FontAwesomeIcon title="Table View" color={viewMode === "table" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faListAlt} onClick={() => setViewMode("table")} />
                </div>
            </div>
            <div id="nex-job-list-card-w">
                {(list_of_data && list_of_data.contents.length > 0) ?
                    <div>
                        {viewMode === 'card' ?
                            list_of_data &&
                            list_of_data.contents.map((e, id) => (
                                <FeedItem
                                    style={style}
                                    {...e}
                                    key={id}
                                />
                            ))
                            : <AllJobsTabularView theader={['Job Title', 'Specialty', 'Institution', 'Experience', 'City', 'Position', 'Status']} tdata={list_of_data.contents} viewmode={viewMode} updateGrid={fetchData} />
                        }
                    </div>
                    :
                    <NoJobs content={"No Jobs"} />
                }
                {hasMore &&
                    <div className="text-center mt-3">
                        {isLoadingMore
                            ?
                            <CommonLoader style={{ width: "2rem", height: "2rem" }} />
                            :
                            <LoadMoreButton loadMore={fetchData} />
                        }
                    </div>
                }
            </div>
        </>
    )
});

export default AllJobs;
