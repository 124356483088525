import {
  faAlignCenter,
  faBriefcase,
  faComment,
  faDownload,
  faEnvelope,
  faPhone,
  faShare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import marker_icon from "assets/images/marker_icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Fade,
  Row,
  Toast,
  ToastBody,
  ToastHeader,
} from "reactstrap";
import { useEffect } from "react";
import { TalentService } from "utils/TalentService";
import CommonLoader from "common/CommonLoader";
import { createImageFromInitials, getFullAddressWithLocationCityAndState, getRandomColor } from "utils/Utils";
import { FAILTOSAVERECOED } from "utils/Constants";
import AlertDialog from "common/AlertDialog";
import { withAlert } from "react-alert";
import ShowStatus from "../../ShowStatus";
import SendEmail from "../SendEmail";
import EmailBody from "../SendEmail/EmailBody";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const ApplicationDetails = (props) => {
  const location = useLocation();
  const { state } = location;
  const { alert } = props;
  const params = useParams();
  const { jobApplicationId } = params;
  const [isOpen, setIsopen] = useState(true);
  const [isOpenResume, setisOpenResume] = useState(false);
  const [jobApplication, setJobApplication] = useState({});
  const [jobDetails, setJobDetails] = useState({});
  const [viewDoc, setViewDoc] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [dropDownItem, setDropDownItem] = useState();
  const [jobApplicationIdToReject, setJobApplicationIdToReject] =
    useState(null);
  const [isOpenAlert, setAlertModal] = useState(false);
  const [isContactOpen, setisContactOpen] = useState(true);
  const [actions, setActions] = useState([])
  let history = useHistory();

  function ContactOpen() {
    setisContactOpen(!isContactOpen);
  }
  function covLttrFunc() {
    setIsopen(!isOpen);
  }

  function ResumeFunc() {
    // setResumeLink(true);
    setisOpenResume(!isOpenResume);
  }

  function ResumeDownload() {
    fetch(viewDoc).then((response) => {
      response.blob().then((blob) => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.target = "_blank";
        alink.click();
      });
    });
  }

  function setActionsForTheStatus(status) {
    if (status === "APPLIED") {
      setActions(["Shortlist", "Reject", "Hold"])
    } else if (status === "SHORTLISTED") {
      setActions(["Interview", "Hold"])
    } else if (status === "INTERVIEW") {
      setActions(["Reject", "Offer", "Hold"])
    } else if (status === "OFFERED") {
      setActions(["Accept", "Reject", "Hold"])
    } else if (status === "ACCEPTED") {
      setActions(["Hire"])
    } else if (status === "HIRED") {
      setActions(["Close"])
    } else if (status === "ON_HOLD") {
      setActions(["Shortlist", "Interview", "Offer", "Accept", "Hire"]);
    } else {
      setActions([])
    }
  }

  const getJob = (jobId) => {
    if (jobId) {
      (async () => {
        TalentService.getJob(jobId)
          .then((response) => {
            let { data } = response;
            if (response.status === 200 || response.status === 201) {
              setJobDetails(data);
            }
          })
          .catch((error) => {
            console.log("Error while getting connection status: ", error);
          });
      })();
    }
  };

  useEffect(() => {
    getJob(state?.params?.jobProfileId);
  }, [state?.params?.jobProfileId]);

  const getJobApplication = () => {
    TalentService.getJobApplicationById(jobApplicationId).then((response) => {
      const { status, data } = response;
      if (status === 200) {
        setJobApplication(data);
        const viewDocument = `${process.env.REACT_APP_API_BASE_URL}practitioner-service/practitioners/${data.applicant.profileGuid}/resumes/${data.cvID}`;
        setIsLoading(false);
        setViewDoc(viewDocument);
        setActionsForTheStatus(data?.status)
      } else {
        console.log("Loading Failed...Please try again");
        setIsLoading(false);
      }
    });
  };
  useEffect(() => {
    getJobApplication();
  }, []);

  const RenderExpYear = ({ practiceStartYear }) => {
    let currentYear = new Date().getFullYear();
    if (isNaN(practiceStartYear) || currentYear - practiceStartYear < 1) {
      return "1 year experience";
    } else {
      return `${currentYear - practiceStartYear}+ years experience`;
    }
  };

  const toggleAlert = () => {
    setAlertModal(!isOpenAlert);
  };

  const handleUpdateJobStatus = (status) => {
    if (status === "Reject") {
      setJobApplicationIdToReject(jobApplicationId);
      toggleAlert();
      return;
    }
    let act = "";
    switch (status) {
      case "Shortlist":
        act = "SHORTLISTED";
        break;
      case "Reject":
        act = "REJECTED";
        break;
      case "Interview":
        act = "INTERVIEW";
        break;
      case "Offer":
        act = "OFFERED";
        break;
      case "Accept":
        act = "ACCEPTED";
        break;
      case "Hire":
        act = "HIRED";
        break;
      case "Hold":
        act = "ON_HOLD";
        break;
      default:
        act = "CLOSED";
    }

    const payload = [
      {
        op: "replace",
        path: "/status",
        value: act,
      },
    ];
    TalentService.patchJobApplication(jobApplicationId, payload).then(
      (response) => {
        const { status } = response;
        if (status === 200) {
          showSuccessMessage("Jobs Application has been updated Successfully...");
          getJobApplication();
        } else {
          showErrorMessage(FAILTOSAVERECOED);
        }
      }
    );
  };

  const handleClickOnYes = () => {
    const payload = [
      {
        op: "replace",
        path: "/status",
        value: "REJECTED",
      },
    ];

    TalentService.patchJobApplication(jobApplicationIdToReject, payload).then(
      (response) => {
        const { status } = response;
        if (status === 200) {
          showSuccessMessage("Jobs Application has been rejected Successfully...");
          getJobApplication();
        } else {
          showErrorMessage(FAILTOSAVERECOED);
        }
      }
    );
    toggleAlert();
  };

  // const ShowStatus = ({ status }) => {
  //   switch (status) {
  //     case "APPLIED":
  //       return (
  //         <p className="badge badge-pill bg-secondary text-white">{status}</p>
  //       );
  //     case "ACCEPTED":
  //       return (
  //         <p className="badge badge-pill bg-success text-white">{status}</p>
  //       );
  //     default:
  //       return (
  //         <p className="badge badge-pill bg-danger text-white">{status}</p>
  //       );
  //   }
  // };

  const getFullSpeciality = (primarySpeciality, superSpeciality) => {
    let fullSpeciality = "";
    if (primarySpeciality !== undefined && primarySpeciality !== "") {
      fullSpeciality += primarySpeciality;
    }

    if (superSpeciality !== undefined && superSpeciality !== "" && superSpeciality !== null) {
      fullSpeciality += "(";
      fullSpeciality += superSpeciality;
      fullSpeciality += ")";
    }

    return fullSpeciality;
  };

  if (isLoading) {
    return (
      <div className="loading-spiner text-center  mt-2">
        <CommonLoader style={{ width: "2rem", height: "2rem" }} />
      </div>
    );
  }

  return (
    <div className="applic-details">
      <Card className="mt-2 mb-2 bg-white">
        <CardBody className="applic-breadcumb-wrap">
          <>
            <Breadcrumb className="job-bread">
              <BreadcrumbItem>
                <Link to={`/jobs/${state?.params?.jobProfileId}`} className={`${state?.params?.jobProfileId === undefined ? "disable-go-back" : ""}`}>
                  <FontAwesomeIcon icon={faBriefcase}
                  />{" "}
                  {state?.params?.jobTitle ? state?.params?.jobTitle : "Job"}
                </Link>
              </BreadcrumbItem>
              <BreadcrumbItem>
                <Link onClick={() => history.goBack()} className={`${history.length <= 2 ? "disable-go-back" : ""}`}>applications</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                {jobApplication?.applicant?.name}
              </BreadcrumbItem>
              <div className="application-status">
                <ShowStatus status={jobApplication?.status} />
              </div>
            </Breadcrumb>
            <div className="button-action">

              <>
                <SendEmail email="" subject={`Job Opportunity – ${state?.params?.jobTitle} Position at ${jobDetails?.institutionDetails?.name}`}
                  body={`%0D%0A%0D%0A${jobDetails?.institutionDetails?.name} is looking for  ${state?.params?.jobTitle} position%0D%0APlease click link below to check the Complete Job details%0D%0A${process.env.REACT_APP_LB_API_ENDPOINT}jobs/${state?.params?.jobProfileId}%0D%0A%0D%0A%0D%0A`}>
                  <Button color="info" outline className="mr-2 nexogic_primary_button_outline">
                    <FontAwesomeIcon icon={faEnvelope} /> Email
                  </Button></SendEmail>

                {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
                  <Button color="info" outline className="mr-2 nexogic_primary_button_outline">
                    <FontAwesomeIcon icon={faShare} /> Share
                  </Button>
                }

                {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
                  <Button color="info" outline className="mr-2 nexogic_primary_button_outline">
                    <FontAwesomeIcon icon={faDownload} /> Download
                  </Button>
                }

                {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && jobApplication?.status !== "OFFERED" && jobApplication?.status !== "ACCEPTED" &&
                  jobApplication?.status !== "HIRED" && jobApplication?.status !== "REJECTED" &&
                  jobApplication?.status !== "CLOSED" &&
                  <Button color="info" outline className="mr-2 nexogic_primary_button_outline">
                    Shortlist
                  </Button>
                }
              </>

              {/* <Button color="danger" outline className="mr-2">
                <FontAwesomeIcon icon={faTrash} /> Delete
              </Button> */}
              <div className="stat-change-box">
                {jobApplication?.status !== "REJECTED" && jobApplication?.status !== "CLOSED" && (
                  <Dropdown
                    isOpen={dropDownItem == jobApplication.id}
                    toggle={(e) =>
                      dropDownItem === jobApplication?.id
                        ? setDropDownItem(null)
                        : setDropDownItem(jobApplication?.id)
                    }
                  >
                    <DropdownToggle className="nexogic_primary_button"
                      caret
                      color="primary"
                    >
                      Status
                    </DropdownToggle>
                    <DropdownMenu right>
                      {actions.map((action) => (
                        <DropdownItem
                          key={action}
                          onClick={(e) => {
                            handleUpdateJobStatus(action);
                          }}
                        >
                          {jobApplication?.status != action && action}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
                )}
              </div>
            </div>
          </>
        </CardBody>
      </Card>

      <Card className="bg-white mb-2">
        <CardBody>
          {/* <Row>
            <Col md={12}>
              <div className="text-center my-4">
                <img src={NexogicLogo} alt="Nexogic" width="250" className="img-fluid" />
              </div>
              <div className="text-center py-5 my-5">
             <p className="mb-4" style={{fontSize:"2rem"}}><strong className="text-info">{`${jobDetails?.institutionDetails?.name}`}</strong>{` is looking for `}<strong className="text-info">{`${state?.params?.jobTitle}`}</strong> position</p>
             <p className="mb-3" >Please click link below to check the Complete Job details</p> 
             <Link to={`${process.env.REACT_APP_LB_API_ENDPOINT}jobs/${state?.params?.jobProfileId}`}>{`${process.env.REACT_APP_LB_API_ENDPOINT}jobs/${state?.params?.jobProfileId}`}</Link>                
              </div>
            </Col>
          </Row> */}
          <Row>
            <Col md={8}>
              <div className="name-action">
                <Link to={`/profile/${jobApplication?.applicant?.profileId}`}>
                  {jobApplication.applicant?.avatarId ? (
                    <img
                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${jobApplication.applicant?.profileGuid}/${jobApplication.applicant?.avatarId}`}
                      alt="#"
                      className="applicant-img"
                    />
                  ) : (
                    <img
                      src={createImageFromInitials(
                        40,
                        jobApplication.applicant?.name,
                        getRandomColor(null)
                      )}
                      alt="profilePic"
                      width={64}
                      className="applicant-img"
                    />
                  )}
                </Link>
                <div className="applicant-content">
                  <span className="senedr-name">
                    <h3 className="card-title">
                      <Link
                        to={`/profile/${jobApplication?.applicant?.profileId}`}
                      >
                        {jobApplication?.applicant?.name}
                      </Link>
                    </h3>

                    <p className="card-text mb-2">{jobApplication?.applicant?.qualification}</p>
                  </span>
                  {/* <span className="senedr-subtxt">{jobApplication?.applicant?.qualifications}</span> */}
                  {/* <span className="senedr-subtxt">13+ Years Experience</span> */}
                  <div className="applicant-speciality">
                    {getFullSpeciality(
                      jobApplication?.applicant?.primarySpeciality,
                      jobApplication?.applicant?.superSpeciality
                    )}
                  </div>
                  <div className="applicant-location">
                    {getFullAddressWithLocationCityAndState({
                      location: jobApplication?.applicant?.location ?? "",
                      city: jobApplication?.applicant?.city ?? "",
                      state: jobApplication?.applicant?.state ?? ""
                    }) !== "" &&
                      < img src={marker_icon} alt="#" />
                    }{" "}
                    {getFullAddressWithLocationCityAndState({
                      location: jobApplication?.applicant?.location ?? "",
                      city: jobApplication?.applicant?.city ?? "",
                      state: jobApplication?.applicant?.state ?? ""
                    })}
                  </div>
                  <div className="applicant-speciality">
                    <RenderExpYear
                      practiceStartYear={
                        jobApplication?.applicant?.practicingSince
                      }
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="contact-card">
                <div>
                  <h2 className="card-title">
                    Contact Details
                    <span className=" ml-auto btn btn-small" onClick={ContactOpen}>
                      {isContactOpen ? "Hide" : "Show"}
                    </span>
                  </h2>
                  {isContactOpen && (
                    <>
                      <div className="contact-item email">
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          className="icn-cls"
                        />
                        {jobApplication?.contactDetails?.emailAddress}
                      </div>
                      <div className="contact-item">
                        <FontAwesomeIcon icon={faPhone} className="icn-cls" />
                        {jobApplication?.contactDetails?.phoneNumber}
                      </div>
                      <div className="contact-item">
                        <FontAwesomeIcon icon={faComment} className="icn-cls" />
                        <Link to="/messages">Chat with Candidate</Link>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="bg-white mb-2 cov-letter-wrap">
        <CardBody>
          <h3 className="card-title">
            Cover Letter
            <span onClick={covLttrFunc} className="ml-2 btn btn-small">
              {isOpen ? "Hide" : "Show"}
            </span>
          </h3>
          {isOpen && (
            <div
              className="list-para mt-1"
              dangerouslySetInnerHTML={{
                __html: jobApplication?.coverLetter,
              }}
            />
          )}
        </CardBody>
      </Card>
      {jobApplication?.answers?.length > 0 &&
        <Card className="mb-2 bg-white questions-answers">
          <CardBody>
            <Row><h3 className="card-title">Applicant's Answers</h3></Row>
            {jobApplication?.answers && jobApplication?.answers.map((e, index) => (
              <div className="q-a" key={index}>
                <Row >
                  <Col className="card-text" lg={2} md={2} sm={2} xs={12}><b>Question:</b> </Col>
                  <Col className="card-text" lg={10} md={10} sm={10} xs={12}>{e?.question}?</Col>
                </Row>
                <Row className="mt-2">
                  <Col className="card-text" lg={2} md={2} sm={2} xs={12}><b>Answer:</b> </Col>
                  <Col className="card-text" lg={10} md={10} sm={10} xs={12}>{e?.answer}</Col>
                </Row>
              </div>
            ))
            }
          </CardBody>
        </Card>
      }
      <Card className="mb-2 bg-white cand-details">
        <CardBody>
          {/* <h4 className="page-title-fnt">Candidate Details</h4>
          <Row>
            <Col md={6}>
              <div className="ques-items">
                <label>Current Designation</label>
                <span className="lbl-value">Software Developer</span>
              </div>
              <div className="ques-items">
                <label>Current Company</label>
                <span className="lbl-value">Google</span>
              </div>
              <div className="ques-items">
                <label>Current Location</label>
                <span className="lbl-value">Hyderabad</span>
              </div>
              <div className="ques-items">
                <label>Preffered Location</label>
                <span className="lbl-value">Mumbai, Pune</span>
              </div>
            </Col>
            <Col md={6}>
              <div className="ques-items">
                <label>Current Salary</label>
                <span className="lbl-value">Lorem</span>
              </div>
              <div className="ques-items">
                <label>Highest Qualifications</label>
                <span className="lbl-value">M.Tech</span>
              </div>
            </Col>
          </Row> */}
          <div className="cov-letter-wrap">
            <Button
              className="nexogic_primary_button mr-3"
              color="primary"
              outline
              onClick={ResumeFunc}
            >
              View Resume
            </Button>
            <Button
              color="primary"
              outline
              className="nexogic_primary_button_outline"
              onClick={ResumeDownload}
            >
              Download Resume
            </Button>
            
          </div>
          <div className={`cov-letter-preview ${isOpenResume ? 'show' : ''}`}>
          <Fade className="mt-3">
              <Toast isOpen={isOpenResume}>
                <ToastHeader toggle={ResumeFunc}>View Resume</ToastHeader>
                <ToastBody style={{ height: "1000px" }}>
                  <embed
                    src={`https://docs.google.com/viewer?url=${viewDoc}&embedded=true`}
                    style={{ width: "100%", height: "100%" }}
                  />
                </ToastBody>
              </Toast>
            </Fade>
          </div>
        </CardBody>
      </Card>

      <AlertDialog
        bodyText={"Are you sure, you want to Reject this candidate?"}
        toggle={toggleAlert}
        isOpen={isOpenAlert}
        handleClickOnYes={handleClickOnYes}
        yesLabel={"Yes"}
        noLabel={"No"}
      />
    </div >
  );
};

export default withAlert()(ApplicationDetails);
