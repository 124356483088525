import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input } from 'reactstrap';
import React from 'react'
import { useState } from 'react';
import { PractitionerService } from 'utils/PractitionerService';
import { FAILED_TO_FETCH_DATA } from 'utils/Constants';
import { IdentityService } from 'utils/IdentityService';
import { withAlert } from 'react-alert';
import { getMeaningFullErrorMessage } from 'utils/Utils';
import CommonLoader from 'common/CommonLoader';
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

export default withAlert()(function UserAddButton({ institution, alert, getAllInstitutionAdmin, setIsLoading }) {
    const [modal, setModal] = useState(false);
    const [currentPractitioners, setCurrentPractitioners] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);
    const [isLoadingUsersToPromote, setIsLoadingUsersToPromote] = useState(true);

    async function getCurrentAdmins(instId) {
        return IdentityService.GetInstitutionUsers(instId, "INSTITUTION_ADMIN", 0)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    return data.contents;
                } else {
                    console.log(FAILED_TO_FETCH_DATA);
                    return [];
                }
            });
    }

    const fetchAssociatedPractitioners = async (institutionId) => {
        try {
            let currentAdmins = await getCurrentAdmins(institutionId); // Wait for getCurrentAdmins to resolve
            const currentAdminsProfileuuids = currentAdmins.map(item => item.id).filter(Boolean);
            

            PractitionerService.getPractitionersAssociatedtoInstitutionNew(institutionId)
                .then((response) => {
                    const { status, data } = response;
                    if (status === 200) {
                        const userssWithProfileUuid = data.filter((user) => user.profileUuid);
                        const userssWithProfileUuidAndNotAdmins = userssWithProfileUuid.filter((user) => (!currentAdminsProfileuuids.includes(user?.profileUuid)))
                        setCurrentPractitioners(userssWithProfileUuidAndNotAdmins);
                        setIsLoadingUsersToPromote(false);
                    } else {
                        console.log(FAILED_TO_FETCH_DATA);
                        setIsLoadingUsersToPromote(false);
                    }
                });
        } catch (error) {
            console.error(error);
        }
    };


    const handleSelectChange = (event) => {
        setSelectedValue(event.target.value); // Update the selected value when the user makes a selection
    };

    const handleSelectUser = () => {
        let payload = [
            institution?.guid
        ]
        IdentityService.AddUsersRole(selectedValue, payload)
            .then((response) => {
                const { status, data, message } = response;
                if (status === 200 || status === 201) {
                    showSuccessMessage("User successfully added as Institution Admin")
                    setIsLoading(true);
                    getAllInstitutionAdmin(0, institution?.guid, []);
                } else {
                    showErrorMessage(getMeaningFullErrorMessage(message));
                }
            })
        toggle(); // Close the modal if needed
    };


    const toggle = () => {
        if (modal === false)
            fetchAssociatedPractitioners(institution?.guid)
        setModal(!modal)
    };
    return (
        <>
            <a className='btn btn-white' onClick={toggle}>
                <div className="icon">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 1V15M1 8H15" stroke="#3EABCA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div> Add User</a>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Assign User</ModalHeader>
                {isLoadingUsersToPromote ?
                    <div className="text-center">
                        <CommonLoader />
                    </div> :
                    <>
                        {currentPractitioners.length > 0 ?
                            <>
                                <ModalBody>
                                    <FormGroup>
                                        <Label for="exampleSelect">Select User</Label>
                                        <Input
                                            id="user_Select_drops"
                                            name="select"
                                            type="select"
                                            onChange={handleSelectChange}
                                        >
                                            <option value={null}>{"Select a Practitioner"}</option>
                                            {currentPractitioners && currentPractitioners.length > 0 && currentPractitioners.map((pract, index) => (
                                                <option value={pract?.profileUuid} key={index}>{pract?.name}{" ("}{pract?.primarySpeciality}{")"}</option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </ModalBody>
                                <ModalFooter>
                                    <Button color="primary" onClick={handleSelectUser} className='nexogic_primary_button' disabled={(selectedValue === null || selectedValue === "Select a Practitioner") ? true : false}>
                                        Submit User
                                    </Button>{' '}
                                    <Button color="secondary" onClick={toggle} className='nexogic_primary_button_outline'>
                                        Cancel
                                    </Button>
                                </ModalFooter>
                            </>
                            :
                            <div className='p-3'>
                                <div className='nexogic-nodata-dotted'>
                                    <div className='no-suggestion-text text-center'>There is no user available to promote to institution admin.</div>
                                </div>
                            </div>
                        }
                    </>
                }
            </Modal >
        </>
    )
})