import { faArrowLeft, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Col, FormGroup, Row, List, Card, CardBody } from "reactstrap";
import { ConnectionServices } from "utils/ConnectionServices";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import small_marker from "assets/svgIcon/marker-small.svg";

const AboutGroupDetails = ({ groupId }) => {
  // const params = useParams();
  // const { groupId } = params;

  const [group, setGroup] = useState({});
  const [pannel_on, setPannel_on] = useState(true);

  const getGroup = () => {
    ConnectionServices.getGroupByID(groupId).then((response) => {
      setGroup(response.data);
    });
  };

  useEffect(() => {
    getGroup();
  }, []);

  let history = useHistory();
  return (
    <Card className={`nexogic-card-color-header ${pannel_on ? 'expanded' : ''}`} id="group-description" >
      <div className="card-header"><h2>Description</h2>
                            <button id="agd_btn_desc" className="collapse-btn" type="button" onClick={()=>setPannel_on(!pannel_on)}>
                              <img src={pannel_arrow} alt="" />
                          </button>
      </div>
      <div className={`collapse-div`}>
      <CardBody className="group-description-content">
        <div>
        <Row>
          <Col md={12} className="d-none">
            <FormGroup>
              <h3 className="grouptitle ">
                <FontAwesomeIcon
                  className="go-back "
                  onClick={() => history.goBack()}
                  icon={faArrowLeft}
                />
                {group.name}
              </h3>
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup>
              <label className="card-title">Description</label>
              <p className="card-text"><small className="text-muted">{group.description}</small></p>
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup>
              <label className="card-title">Rules</label>
              <p className="card-text"><small className="text-muted">{group.rules}</small></p>
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup>
              <label className="card-title">Searchable</label>
              <List className="rule-list">
                <li className="card-text">
                  {/*<FontAwesomeIcon className="icon-name" icon={faCheckCircle} />*/}
                  <div className="d-inner">
                    <p>{group.isVisible === true ? "LISTED" : "UNLISTED"}</p>
                    {group.isVisible === true ? (
                      <small className="text-muted">
                        Group appear in search results and is visible to others
                        on member's profile
                      </small>
                    ) : (
                      <small className="text-muted">
                        Unlisted Group does not appear in search results for
                        non-group members and is not visible to others on
                        members' profiles.
                      </small>
                    )}
                  </div>
                </li>
              </List>
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup>
              <label className="group-label">Group privacy</label>
              <List className="rule-list">
                <li className="card-text">
                  {/*<FontAwesomeIcon className="icon-name" icon={faCheckCircle} />*/}
                  <div className="d-inner">
                    <p>{group.privacy === "PRIVATE" ? "Private" : "PUBLIC"}</p>
                    {group.privacy === "PRIVATE" ? (
                      <small className="text-muted">
                        Allow members to invite their connections If disabled,
                        only admins will be able to invite 1st degree
                        connections to the group. All requests to join will
                        still require admin approval.
                      </small>
                    ) : (
                      <small className="text-muted">
                        Require new posts to be reviewed by admins If enabled,
                        members' posts will require admin approval within 14
                        days before they become visible to others.
                      </small>
                    )}
                  </div>
                </li>
              </List>
            </FormGroup>
          </Col>
        </Row>
        </div>
      </CardBody>
      </div>
    </Card>
  );
};

export default AboutGroupDetails;
