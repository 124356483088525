import React from "react"
import { Row, Col, Button, InputGroup, Spinner } from 'reactstrap'
import { Field } from 'formik';
import "react-phone-input-2/lib/style.css";

import FormikInputField from "components/forms/formikFields/FormikInputField"
import FormikCheckboxField from "components/forms/formikFields/FormikCheckboxField"

const EmailForm = ({ isSubmitting, handleSubmit, error }) => (
  <form onSubmit={handleSubmit}>
    <div>
      <InputGroup size="medium">
        <Field
          id="ef_email"
          name="email"
          type="email"
          label="Email address"
          component={FormikInputField}
          placeholder="Email address" />
      </InputGroup>
      {error ? <div className="otp-flow__error m-auto">{error}</div> : null}
    </div>
    <div className="mb-4 mt-2 align-items-center">
      {isSubmitting ?
        <div className="loading-spiner text-center"><Spinner /></div>
        :
        <Button type="submit" id="ef_btn_submit"  className="nexogic_primary_button full" color="primary" size="md">Receive OTP</Button>
      }
    </div>
  </form>
)

export default EmailForm