import React, { useState } from "react";
import { Button, CardBody, FormGroup } from "reactstrap";
import ReactQuill from "react-quill";
import { OpinionService } from "utils/OpinionService";
import { FAILTOSAVERECOED } from "utils/Constants";
import { useEffect } from "react";
import { withAlert } from "react-alert";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const MedicalHistoryForm3 = (props) => {
    const { opinionInfo, setOpinionInfo } = props;
    const [hospitalizations, setHospitalizations] = useState('');
    const [currentMedications, setCurrentMedications] = useState('');

    const handleNextButtonClick = async () => {
        let payload = {
            "speciality": opinionInfo.speciality,
            "title": opinionInfo.title,
            "status": opinionInfo.status,
            "requestedBy": opinionInfo.requestedBy,
            "requestedFor": opinionInfo.requestedFor,
            "symptoms": opinionInfo.symptoms,
            "allergies": opinionInfo.allergies,
            "surgeries": opinionInfo.surgeries,
            "familyHistory": opinionInfo.familyHistory,
            "currentMedications": currentMedications,
            "pastHospitalization": hospitalizations,
        }
        // console.log(payload);

        OpinionService.updateOpinion(opinionInfo?.id, payload)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    showSuccessMessage("Opinion updated successfully.");
                    setOpinionInfo(data);
                    props.setCurrentStepInMainForm('3')
                }
                else {
                    console.log(FAILTOSAVERECOED);
                }
            })
    };

    useEffect(() => {
        setHospitalizations(opinionInfo?.pastHospitalization);
        setCurrentMedications(opinionInfo?.currentMedications);
    }, [opinionInfo?.id])

    return (
        <CardBody className={`nex-form-step active`}>
            <div className="nex-form-field-container">
                <div className="nex-form-group">
                    <div className="nex-form-group-c mt-0">
                        <label htmlFor="" className="nex-big-label mb-3">Any past hospitalizations ?</label>
                        <FormGroup>
                            <ReactQuill
                                id="past_hospitalizations"
                                placeholder="Enter Your past hospitalizations"
                                value={hospitalizations}
                                onChange={setHospitalizations}
                                bounds={".app"}
                            />
                        </FormGroup>

                        <label htmlFor="" className="nex-big-label mb-3">Please brief your Current Medications ?</label>
                        <FormGroup>
                            <ReactQuill
                                id="current_medications"
                                placeholder="Enter Your Current Medications"
                                value={currentMedications}
                                onChange={setCurrentMedications}
                                bounds={".app"}
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="nex-form-group-c mt-0 mb-2">
                <div className="nex-form-action">
                    <Button
                        color="primary"
                        className="nexogic_primary_button_outline"
                        onClick={(e) => props.setCurrentStep('2')}
                    >
                        Back
                    </Button>
                    <Button
                        color="primary"
                        className="nexogic_primary_button"
                        onClick={handleNextButtonClick}
                    >
                        Next
                    </Button>
                </div>
            </div>
        </CardBody>
    )
}

export default withAlert()(MedicalHistoryForm3);
