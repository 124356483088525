import React from "react";
import { useState } from "react";
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, FormGroup, Input, ListGroup, ListGroupItem, Row } from "reactstrap";
import selected_profile_avatar from 'assets/images/user-profile-sample.png'
import check_icon from 'assets/svgIcon/check_icon.svg'
import ReactQuill from "react-quill";
import { OpinionService } from "utils/OpinionService";
import { useEffect } from "react";
import { PractitionerService } from "utils/PractitionerService";
import { FAILED_TO_FETCH_DATA, FAILTOSAVERECOED } from "utils/Constants";
import { createImageFromInitials, getRandomColor } from "utils/Utils";
import { encrypt } from "utils/EncryptDecrypt";
import CommonLoader from "common/CommonLoader";
import LoadMoreButton from "common/LoadMoreButton/LoadMoreButton";
import { withAlert } from "react-alert";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const PractitionerForm = (props) => {
    const { opinionId, forUpdate, selectedSpeciality } = props;
    const [opinionInfo, setOpinionInfo] = useState({});
    const [practitioners, setPractitioners] = useState([])
    const [hasMore, setHasMore] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [preSelectedPractitioner, setPreSelectedPractitioner] = useState({});

    const handleSubmit = (practitioner) => {
        // console.log("Selected Practitioner:", practitioner);

        let payload = {
            "speciality": opinionInfo.speciality,
            "title": opinionInfo.title,
            "status": "DRAFT",
            "requestedBy": opinionInfo.requestedBy,
            "requestedFor": opinionInfo.requestedFor,
            "symptoms": opinionInfo.symptoms,
            "allergies": opinionInfo.allergies,
            "surgeries": opinionInfo.surgeries,
            "familyHistory": opinionInfo.familyHistory,
            "currentMedications": opinionInfo.currentMedications,
            "pastHospitalization": opinionInfo.pastHospitalization,
            "practitionerAssignedTo": practitioner?.profileGuid
        }
        // console.log(payload);

        OpinionService.updateOpinion(opinionId, payload)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    showSuccessMessage("Opinion updated successfully.");
                    setOpinionInfo(data);
                    getPreSelectedPractitioner(practitioner?.profileGuid);
                    props.setCurrentStepInMainForm('4');
                }
                else {
                    console.log(FAILTOSAVERECOED);
                }
            })

    }

    // const getOpinionInfo = (id) => {
    //     OpinionService.getOpinion(id)
    //         .then((response) => {
    //             const { status, data } = response;
    //             if (status === 200) {
    //                 // console.log(data);
    //                 getPractitioners(data?.speciality);
    //                 setOpinionInfo(data);
    //             }
    //         })
    // }

    const getPractitioners = async (speciality, pageNo = 0) => {
        // console.log("Called1");
        let sq = `st=='Approved';ps=='${selectedSpeciality}';so==true`;
        await PractitionerService.getPractitionersForOpinion(sq, pageNo
        ).then((response) => {
            const { status, data } = response;
            if (status === 200) {
                setTotalPages(data?.totalPages);
                setTotalItems(data?.totalItems);
                setCurrentPage(data?.currentPage)
                setHasMore((data?.currentPage + 1) < data?.totalPages);
                setIsLoadingMore(false);

                // setPractitioners(() => data?.contents);
                setPractitioners((practitioners) => [...practitioners, ...data?.contents]);
            } else {
                setIsLoadingMore(false);
                console.log(FAILED_TO_FETCH_DATA);
            }
        });
    };

    const fetchData = () => {
        setIsLoadingMore(true);
        getPractitioners("", currentPage + 1);
    }

    useEffect(() => {
        getPractitioners("", 0);
    }, [selectedSpeciality])

    const getPreSelectedPractitioner = (id) => {
        PractitionerService.getPractitionerProfileById(id)
            .then((response) => {
                const { status, data } = response;
                if (status == 200) {
                    // console.log(data);
                    setPreSelectedPractitioner(data);
                }
            })
    }

    useEffect(() => {
        if (forUpdate && props?.opinionInfo && props?.opinionInfo?.practitionerAssignedTo) {
            getPreSelectedPractitioner(props?.opinionInfo?.practitionerAssignedTo);
        }
    }, [props?.opinionInfo?.id, forUpdate])

    return (
        <>
            <CardBody className={"nex-form-step active"}>
                <br />
                <div className="nex-form-field-container">
                    <div className="nex-form-group nex-content-center">
                        <div className="nex-form-group-c mt-0">
                            <label htmlFor="" className="nex-big-label mb-3">Please Select Practitioner from the Below Options </label>
                            {/* <FormGroup>
                                <Input className="nex-form-control-blue" placeholder="Filter Practitioner" />
                            </FormGroup> */}

                            {forUpdate && preSelectedPractitioner && preSelectedPractitioner?.profileGuid &&
                                <ul className="nex-practitioner-check-lists">
                                    <li key={preSelectedPractitioner.id} className="active">
                                        <i className="check">
                                            <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14 1L5.0625 10L1 5.90909" stroke="#2D4552" strokeOpacity="0.27" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>
                                        </i>
                                        <div className="graphic">
                                            {
                                                preSelectedPractitioner?.avatarId ?
                                                    <img
                                                        src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${preSelectedPractitioner?.profileGuid}/${preSelectedPractitioner?.avatarId}`}
                                                        className="card-img nex-avatar"
                                                        alt="#"
                                                    />
                                                    :
                                                    <>
                                                        <img
                                                            src={createImageFromInitials(
                                                                50,
                                                                `${preSelectedPractitioner?.name.replace("Dr. ", "")}`,
                                                                getRandomColor(
                                                                    encrypt(
                                                                        preSelectedPractitioner?.profileGuid
                                                                    ).toString()
                                                                )
                                                            )}
                                                            className="card-img nex-avatar"
                                                            alt="#"
                                                        />
                                                    </>
                                            }
                                        </div>
                                        <div className="info">
                                            <h3 className="card-title">
                                                <a href={`/profile/${preSelectedPractitioner?.profileId}`} target="_blank"> {preSelectedPractitioner?.name}</a>
                                                <span>{" "}{preSelectedPractitioner?.qualification}</span></h3>
                                            <div className="meta">
                                                <div className="card-text">
                                                    <span className="icon">
                                                        <svg width="15" height="22" viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M3.98071 12.7182L2.85714 21L7.5 18.2727L12.1429 21L11.0193 12.7091M14 7.36364C14 10.8782 11.0899 13.7273 7.5 13.7273C3.91015 13.7273 1 10.8782 1 7.36364C1 3.8491 3.91015 1 7.5 1C11.0899 1 14 3.8491 14 7.36364Z" stroke="#74C0D6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </span>
                                                    {preSelectedPractitioner?.primarySpeciality}
                                                </div>
                                                {/* <div className="card-text">
                                                    <span className="icon">
                                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10 0.666645L10.2987 0.0706444C10.206 0.024189 10.1037 0 10 0C9.89631 0 9.79404 0.024189 9.70133 0.0706444L10 0.666645ZM2 4.66665L1.70133 4.07065C1.59074 4.12607 1.49774 4.21117 1.43275 4.31642C1.36776 4.42168 1.33334 4.54294 1.33333 4.66665H2ZM18 4.66665H18.6667C18.6667 4.54294 18.6322 4.42168 18.5672 4.31642C18.5023 4.21117 18.4093 4.12607 18.2987 4.07065L18 4.66665ZM7.33333 14V13.3333H6.66667V14H7.33333ZM12.6667 14H13.3333V13.3333H12.6667V14ZM0 20H20V18.6667H0V20ZM9.70133 0.0706444L1.70133 4.07065L2.29867 5.26265L10.2987 1.26265L9.70133 0.0706444ZM18.2987 4.07065L10.2987 0.0706444L9.70133 1.26265L17.7013 5.26265L18.2987 4.07065ZM9.33333 3.99998V7.33332H10.6667V3.99998H9.33333ZM9.33333 7.33332V10.6667H10.6667V7.33332H9.33333ZM6.66667 7.99999H10V6.66665H6.66667V7.99999ZM10 7.99999H13.3333V6.66665H10V7.99999ZM1.33333 4.66665V19.3333H2.66667V4.66665H1.33333ZM17.3333 4.66665V19.3333H18.6667V4.66665H17.3333ZM8 19.3333V14H6.66667V19.3333H8ZM7.33333 14.6667H12.6667V13.3333H7.33333V14.6667ZM12 14V19.3333H13.3333V14H12Z" fill="#74C0D6" />
                                                        </svg>
                                                    </span>
                                                    Apollo Hospital[static]
                                                </div> */}
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            }

                            <div className="nexogic-custom-scroll">

                                <ul className="nex-practitioner-check-lists">

                                    {practitioners.length > 0 ? practitioners.map((practitioner) => (
                                        <>
                                            {(preSelectedPractitioner?.profileGuid !== practitioner?.profileGuid) &&
                                                <li key={practitioner.id}>
                                                    <i className="check" style={{ cursor: "pointer" }} onClick={() => handleSubmit(practitioner)}>
                                                        <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M14 1L5.0625 10L1 5.90909" stroke="#2D4552" strokeOpacity="0.27" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </i>
                                                    <div className="graphic">
                                                        {
                                                            practitioner?.avatarId ?
                                                                <img
                                                                    src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${practitioner?.profileGuid}/${practitioner?.avatarId}`}
                                                                    className="card-img nex-avatar"
                                                                    alt="#"
                                                                />
                                                                :
                                                                <>
                                                                    <img
                                                                        src={createImageFromInitials(
                                                                            50,
                                                                            `${practitioner?.name.replace("Dr. ", "")}`,
                                                                            getRandomColor(
                                                                                encrypt(
                                                                                    practitioner?.profileGuid
                                                                                ).toString()
                                                                            )
                                                                        )}
                                                                        className="card-img nex-avatar"
                                                                        alt="#"
                                                                    />
                                                                </>
                                                        }
                                                    </div>
                                                    <div className="info">
                                                        <h3 className="card-title">
                                                            <a href={`/profile/${practitioner?.profileId}`} target="_blank"> {practitioner?.name}</a>
                                                            <span>{" "}{practitioner?.qualification}</span></h3>
                                                        <div className="meta">
                                                            <div className="card-text">
                                                                <span className="icon">
                                                                    <svg width="15" height="22" viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M3.98071 12.7182L2.85714 21L7.5 18.2727L12.1429 21L11.0193 12.7091M14 7.36364C14 10.8782 11.0899 13.7273 7.5 13.7273C3.91015 13.7273 1 10.8782 1 7.36364C1 3.8491 3.91015 1 7.5 1C11.0899 1 14 3.8491 14 7.36364Z" stroke="#74C0D6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                    </svg>
                                                                </span>
                                                                {practitioner?.primarySpeciality}
                                                            </div>
                                                            {/* <div className="card-text">
                                                        <span className="icon">
                                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M10 0.666645L10.2987 0.0706444C10.206 0.024189 10.1037 0 10 0C9.89631 0 9.79404 0.024189 9.70133 0.0706444L10 0.666645ZM2 4.66665L1.70133 4.07065C1.59074 4.12607 1.49774 4.21117 1.43275 4.31642C1.36776 4.42168 1.33334 4.54294 1.33333 4.66665H2ZM18 4.66665H18.6667C18.6667 4.54294 18.6322 4.42168 18.5672 4.31642C18.5023 4.21117 18.4093 4.12607 18.2987 4.07065L18 4.66665ZM7.33333 14V13.3333H6.66667V14H7.33333ZM12.6667 14H13.3333V13.3333H12.6667V14ZM0 20H20V18.6667H0V20ZM9.70133 0.0706444L1.70133 4.07065L2.29867 5.26265L10.2987 1.26265L9.70133 0.0706444ZM18.2987 4.07065L10.2987 0.0706444L9.70133 1.26265L17.7013 5.26265L18.2987 4.07065ZM9.33333 3.99998V7.33332H10.6667V3.99998H9.33333ZM9.33333 7.33332V10.6667H10.6667V7.33332H9.33333ZM6.66667 7.99999H10V6.66665H6.66667V7.99999ZM10 7.99999H13.3333V6.66665H10V7.99999ZM1.33333 4.66665V19.3333H2.66667V4.66665H1.33333ZM17.3333 4.66665V19.3333H18.6667V4.66665H17.3333ZM8 19.3333V14H6.66667V19.3333H8ZM7.33333 14.6667H12.6667V13.3333H7.33333V14.6667ZM12 14V19.3333H13.3333V14H12Z" fill="#74C0D6" />
                                                            </svg>
                                                        </span>
                                                        Apollo Hospital[static]
                                                    </div> */}
                                                        </div>
                                                    </div>
                                                </li>
                                            }
                                        </>
                                    ))
                                        :
                                        <div className="nexogic-not-found-text">No Practitioner Available for selected Specialty</div>
                                    }
                                    {hasMore &&
                                        <div className="text-center mt-3">
                                            {isLoadingMore
                                                ?
                                                <CommonLoader style={{ width: "2rem", height: "2rem" }} />
                                                :
                                                <LoadMoreButton loadMore={fetchData} />
                                            }
                                        </div>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nex-form-group-c mb-2">
                    <div className="nex-form-action">
                        <Button
                            color="primary"
                            className="nexogic_primary_button_outline"
                            onClick={(e) => props.setCurrentStepInMainForm('2')}
                        >
                            Back
                        </Button>
                        <Button
                            color="primary"
                            className="nexogic_primary_button"
                            onClick={(e) => props.setCurrentStepInMainForm('4')}
                        >
                            Next
                        </Button>
                    </div>
                </div>
            </CardBody >
        </>
    )
}
export default withAlert()(PractitionerForm);