import { Card, CardBody, Col, Row, Button } from "reactstrap";
import { Link } from "react-router-dom";
import AdvancedSearch from "./AdvancedSearch";
import CommonLoader from "common/CommonLoader";
import { useEffect, useState } from "react";
import ProfileCardItem from "./ProfileCardItem";
import { PractitionerService } from "utils/PractitionerService";
import { FAILED_TO_FETCH_DATA } from "utils/Constants";
import profile_icon from "assets/images/profile-icon.png";
import LoadMoreButton from "common/LoadMoreButton/LoadMoreButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faListAlt } from "@fortawesome/free-solid-svg-icons";
import "./style.scss"
import ProfilesTabularView from "components/tabularView/ProfilesTabularView";

const PractitionerProfiles = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [contents, setContents] = useState([]);
    const [viewMode, setViewMode] = useState("card");
    const [initialValues, setInitialValues] = useState({
        name: "",
        city: [],
        primarySpeciality: [],
    });

    const [query, setQuery] = useState("");
    const [loadingMore, setLoadingMore] = useState(false);
    const [hasMore, setHasMore] = useState(false);

    const makeQuery = (name, city, primarySpeciality) => {
        let query = "";
        if (name !== "") {
            query = `(na=="*${encodeURIComponent(name)}*")`;
        }

        if (city !== "") {
            if (query === "") query = `(ci=="${encodeURIComponent(city)}")`;
            else query += `;(ci=="${encodeURIComponent(city)}")`;
        }

        if (primarySpeciality !== "" && primarySpeciality !== undefined) {
            if (query === "") query = `(ps=="${encodeURIComponent(primarySpeciality)}")`;
            else query += `;(ps=="${encodeURIComponent(primarySpeciality)}")`;
        }

        return query;
    }

    const fetchProfileData = (values) => {
        let sq = "";
        if (values !== "") {
            sq = makeQuery(
                values?.name ? values?.name : "",
                (values?.city && values?.city.length > 0) ? values?.city[0].value.split(", ")[0] : "",
                values?.primarySpeciality ? values?.primarySpeciality[0] : ""
            );
        }
        setQuery(sq);

        PractitionerService.getAllProfiles(sq, 0)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    setContents(data?.contents);
                    setTotalPages(data?.totalPages);
                    setTotalItems(data?.totalItems);
                    setCurrentPage(data?.currentPage)
                    setHasMore((data?.currentPage + 1) < data?.totalPages);
                    setIsLoading(false);
                } else {
                    console.log(FAILED_TO_FETCH_DATA)
                    setIsLoading(false);
                }
            })
    }

    const fetchMoreProfileData = () => {
        PractitionerService.getAllProfiles(query, currentPage + 1)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    setContents((contents) => [...contents, ...data?.contents]);
                    setTotalPages(data?.totalPages);
                    setTotalItems(data?.totalItems);
                    setCurrentPage(data?.currentPage)
                    setLoadingMore(false);
                } else {
                    setLoadingMore(true);
                    console.log(FAILED_TO_FETCH_DATA)
                }
            })
    }

    useEffect(() => {
        fetchProfileData("");
    }, [])

    useEffect(() => {
        document.body.classList.add('b-profile-page');
        document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "Profile";
        return () => {
            document.body.classList.remove('b-profile-page');
            document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "";
        }
    }, [])

    const loadMore = () => {
        setLoadingMore(true);
        fetchMoreProfileData();
    };

    const renderProfiles = () => {
        if (viewMode === 'card') {
            return (
                <>
                    {contents.map((node, index) => (
                        <ProfileCardItem {...node} key={`profile_${index}`} viewmode={viewMode} />
                    ))}
                </>
            );
        } else { // For table view
            return (
                <ProfilesTabularView theader={['Name', 'Qualification', 'Speciality', 'Experience', 'City']} tdata={contents} viewmode={viewMode} />
            );
        }
    };

    return (
        <>
            <div className="nex-profile-cont-wrap">
                <Card className="nexogic-card advanced-search-card">
                    <CardBody>
                        <AdvancedSearch
                            initialValues={initialValues}
                            setIsLoading={setIsLoading}
                            fetchProfileData={fetchProfileData}
                            setTotalItems={setTotalItems}
                        />
                    </CardBody>
                </Card>

                <Card className="profile-search-results">
                    <CardBody className="px-0">
                        <div className="search-results">
                            {/* {console.log("viewMode===============================================", viewMode)} */}
                            <Row>
                                <Col lg={12} className="column-left">
                                    <div className="prof-sign-hints h3" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        {totalItems > 0 &&
                                            <span>{`${totalItems} ${totalItems === 1 ? 'Practitioner profile' : 'Practitioner profiles'} found`}</span>
                                        }
                                        <div className="view-toggle nexogic-view-toggle mb-0">
                                            <div className="btns">
                                                <FontAwesomeIcon title="Card View" color={viewMode === "card" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faAddressCard} onClick={() => setViewMode("card")} /> | <FontAwesomeIcon title="Table View" color={viewMode === "table" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faListAlt} onClick={() => setViewMode("table")} />
                                            </div>
                                        </div>
                                    </div>
                                    {isLoading ?
                                        <div className="text-center">
                                            <CommonLoader />
                                        </div>
                                        :
                                        <>
                                            {contents && contents.length > 0 ? (
                                                <>
                                                    {renderProfiles()}
                                                    {/* {contents.map((node, index) => (
                                                        <ProfileCardItem {...node} key={`profile_${index}`} />
                                                    ))} */}
                                                    {hasMore &&
                                                        < div className="text-center mt-2">
                                                            {loadingMore ?
                                                                <CommonLoader style={{ width: "2rem", height: "2rem" }} />
                                                                :
                                                                <LoadMoreButton loadMore={loadMore} />
                                                            }
                                                        </div>
                                                    }
                                                </>
                                            ) : (
                                                <div className="nexogic-nodata-dotted">
                                                    <div className="no-suggestion-text text-center">
                                                        <img src={profile_icon} className="icon" />
                                                        {"No Profile Found!"}
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    }
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </div >
        </>
    );
}

export default PractitionerProfiles;
