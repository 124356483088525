import React from "react";
import { Col, FormGroup, Row } from "reactstrap";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import { createImageFromInitials, getFullAddress, getRandomColor } from "utils/Utils";
import { encrypt } from "utils/EncryptDecrypt";
import { Field, Form } from "formik";
import AddressInstitution from "pages/Profile/components/DoctorsDetails/components/AddressInstitution";


const ContactInfo = (props) => {
  const { formData, setFormData, handleSubmit, practitioner } = props;

  const {
    title,
    firstName,
    middleName,
    lastName,
    address,
    phones,
    emails,
    qualification,
    primarySpeciality,
    superSpeciality,
  } = practitioner;

  const getDefaultValue = (items) => {
    if (items && items.length > 0) {
      return {
        list: items.map((item) => { return item.value }),
        primayItem:
          items.findIndex((node) => node.primary === true) !== -1
            ? items.findIndex((node) => node.primary === true).toString()
            : "",
      };
    }
    return {
      list: [],
      primayItem: "",
    };
  };
  return (
    <Form className='form-container' onSubmit={handleSubmit}>
      <div className="nexogic-job-popup">
        <h4 className="form-title">Contact Info</h4>
        <div className="card border-0">
          <div className="card-body d-flex px-0">
          <div className="card-thumb">
          {(practitioner && practitioner?.avatarId) ?
            < img
              src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${practitioner.guid}/${practitioner.avatarId}`}
              className="card-img rounded-circle" alt="#" />
            :
            < img
              src={createImageFromInitials(50, `${practitioner?.name}`, getRandomColor(encrypt(practitioner?.guid).toString()))}
              className="card-img rounded-circle" alt="#" />
          }
          </div>
          <div className="info px-3">
            <h3 className="card-title">{title} {firstName ?? ""} {middleName ?? ""} {lastName ?? ""}, <span style={{ color: "#767878" }}>{qualification}</span></h3>
            <p className="card-text">{primarySpeciality ?? ""} , {superSpeciality ?? ""}</p>
            <p className="card-text">
              <AddressInstitution
                city={address[0]?.city ?? ""}
                state={address[0]?.state ?? ""}
                country={
                  address[0]?.country ?? ""
                }
              />
            </p>
          </div>
          </div>
        </div>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Field
                id="ci_phonenumber"
                name="phonenumber"
                type="text"
                label="Phone Number"
                component={FormikSelectField}
                value={formData.phonenumber}
                onChange={(event) =>
                  setFormData({ ...formData, phonenumber: event.target.value })
                }
                inputprops={{
                  name: `phonenumber`,
                  options: getDefaultValue(phones).list,
                }}
              />
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup>
              <Field
                id="ci_emailid"
                name="emailid"
                type="text"
                label="Email Address"
                component={FormikSelectField}
                value={formData.emailid}
                onChange={(event) =>
                  setFormData({ ...formData, emailid: event.target.value })
                }
                inputprops={{
                  name: `emailid`,
                  options: getDefaultValue(emails).list,
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default ContactInfo;
