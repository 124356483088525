import addIcon from "assets/images/svgs/add_icon.svg";
import plusIcon from "assets/images/add-icon.png";
import tickCheckmark from "assets/images/svgs/tick_checkmark_icon.svg";
import {faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "assets/styles/typeahead.css";
import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import { useFormik } from "formik";
import { findIndex } from "lodash";
import { memo, useEffect, useRef, useState, useMemo } from "react";
import { withAlert } from "react-alert";
import { Typeahead } from "react-bootstrap-typeahead";
import { Button, Col, FormGroup, Row, Spinner } from "reactstrap";
import { InstitutionServices } from "utils/InstitutionServices";
import { MetadataService } from "utils/MetadataService";
import * as Yup from "yup";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const validationSchema = Yup.object().shape({
  services: Yup.string().required("This field is required"),
});

const AmentitesForm = (props) => {
  const { toggle, institution, alert, getInstitutionProfile } = props;
  const [amenitiesData, setAmenitiesData] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [sugAmenities, setSugAmenities] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [loadMore,setLoadMore] = useState(10);
  const [isCompleted, setIsCompleted] = useState(false);
  const [errorMsg, setErrorMessage] = useState("");
  const servicesRef = useRef(null);

  useEffect(() => {
    setAmenities(institution.amenities);
  }, []);

  const formik = useFormik({
    initialValues: { amenities: amenities },
    validationSchema,
  });

  const updateItems = async (values) => {
    setLoading(true);
    let payload = [
      {
        op: "add",
        path: "/amenities",
        value: amenities,
      },
    ];
    InstitutionServices.patchInstitutionDetails(payload, institution.guid)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          toggle();
          getInstitutionProfile(institution.guid);
          showSuccessMessage("Institution details updated successfully");
        } else {
          showErrorMessage("Institution details failed to update");
        }
        setLoading(false);
      })
      .catch((err) => {
        showErrorMessage(err.message);
        setLoading(false);
      });
  };

  const handleChange = (selectedOptions) => {
    const preData = selectedOptions.map((item) => {
      let clone = item;
      let custom = item?.customOption ? true : false;
      if (custom) {
        clone = item?.Amenities;
      }
      return clone;
    });
    preData.length ? setAmenities(preData) : setAmenities([]);
  };

  const handleSuggestedAmenities = (amenity) => {
    setAmenities([...amenities, amenity]);
  };

  const handleLoadMore = () => {
    setLoadMore(loadMore + 15);
    if (loadMore >= amenitiesData.length) {
      setIsCompleted(true)
    } else {
      setIsCompleted(false)
    }
  }
  const suggestedAmmenities = useMemo(() => {
    if (sugAmenities && sugAmenities.length > 0) {
      const uniqueSugAmenities = sugAmenities.filter(
        (s) => findIndex(amenities, (o) => o === s) < 0
      );
      return uniqueSugAmenities?.slice(0, loadMore)?.map((data,index) => {
        return (
          <div key={index}
            id={`nex-sk-amf-suggest-${index}`}
            className="nexogic-suggested-skill d-flex align-items-center"
            onClick={() => handleSuggestedAmenities(data)}
          >
            <div className="card-text">{data}</div>
            <div className="add-icon ml-2">
              <img src={addIcon} alt="" />
            </div>
          </div>
        );
      });
    }
    return null;
  }, [amenities, sugAmenities,loadMore]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        MetadataService.getAmenity().then((response) => {
          const { status, data } = response;
          if (status === 200 || status === 201) {
            setLoading(false);
            setAmenitiesData(data);
            setSugAmenities(data);
          }
        });
      } catch (e) {
        setErrorMessage(e.message);
        setLoading(false);
      }
    })();
  }, []);
  
  return (
    <>
      <form onSubmit={formik.handleSubmit} id="nex-ip-amenities-modal-form">
        {errorMsg !== "" && <RenderErrorMessage errorMsg={errorMsg} />}
        <Row>
          <Col xs={12}>
            <FormGroup className="searchbox-input-chips mb-1" id="nex-ip-amenities-inp-w">
              <Typeahead
                ref={servicesRef}
                allowNew
                clearButton
                newSelectionPrefix="Add Amenities: "
                selected={amenities}
                id="typeahead"
                multiple
                options={amenitiesData}
                placeholder="Add your Amenities"
                labelKey={"Amenities"}
                onBlur={() => {
                  return servicesRef !== null
                    ? servicesRef.current.hideMenu()
                    : null;
                }}
                onChange={handleChange}
              />
            </FormGroup>
            <div className="card-text"><small>You can add upto 50 Amenities</small></div>
            <div className="nexogic-suggested-skills d-flex flex-wrap mt-3 mb-3">
              {suggestedAmmenities}
            </div>
            <div className="specialities d-flex flex-wrap my-2 justify-content-center">
              {!isCompleted && (
                <Button
                id="af_btn_load_more"
                  className="nexogic_primary_button_outline small mt-2"
                  color="link"
                  onClick={handleLoadMore}
                >
                  <img src={plusIcon} alt=""/> Load more
                </Button>
              )}
            </div>
          </Col>
        </Row>
        {/*<hr className="profile-info-modal-hr mt-0" />*/}
        <div className="justify-content-end  modal-footer ">
          {isLoading ? (
            <div className="loading-spiner">
              <Spinner style={{ width: "2rem", height: "2rem" }} />
            </div>
          ) : (
            <div className="action-group">
              
              <Button
                id="af_btn_submit"
                className="nexogic_primary_button"
                color="primary"
                type="submit"
                onClick={() => updateItems(amenities)}
              >
                Save
              </Button>
              <Button
                id="af_btn_cancel"
                color="link"
                className="nexogic_primary_button_outline"
                onClick={toggle}
              >
                Cancel
              </Button>
            </div>
          )}
        </div>
      </form>
    </>
  );
};
export default withAlert()(memo(AmentitesForm));
