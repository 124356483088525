import verifyMobileImg from "assets/images/otp-flow/verifyMobile.svg";
import CountDown from "components/CountDown";
import React, { useEffect, forwardRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ReactCodeInput from "react-verification-code-input";
import { Button, Col, Row } from "reactstrap";
import { cleanUpPhoneNumber } from "utils/Utils";
//import "./style.scss";
import logo from "assets/images/logo-white.png";

const VerifyOTP = forwardRef((props, otpRef) => {
  const {
    handleSubmit,
    resendOTPVerification,
    canResendOTP,
    setCanResendOTP,
    data,
    error,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const [verifyCode, setVerifyCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [resendLabel, setResendLabel] = useState(false);
  const [userProfileId, setUserProfileId] = useState("");

  const handleOTPVerification = (e) => {
    handleSubmit(e, verifyCode);
  };
  const handleResendOTPVerification = (e) => {
    setResendLabel(true);
    resendOTPVerification(e);
  };

  const gotoMobileNumber = () => {
    history.goBack();
  };

  const gotoEmailVerify = () => {
    data.phone = cleanUpPhoneNumber(data.phone);
    history.replace(`/register/otp-verification-email`, {
      data: { ...data, ...{ profileId: userProfileId } },
    });
  };

  useEffect(() => {
    const { state } = location;
    const { data } = state;
    const { profileId } = data;
    if (profileId) {
      setUserProfileId(profileId);
    }
  }, [location]);

  useEffect(() => {
    setErrorMessage(error);
    setTimeout(() => {
      setErrorMessage(null);
    }, 5000);
  }, [error]);

  useEffect(() => {
    document.body.classList.add('register-screen-page');
    document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "Verify your number";
    return () => {
      document.body.classList.remove('register-screen-page');
      document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "";
    }
}, [])

  return (
    <div className="nexogic-login-register-card card border-0 bg-transparent">
    <Row className="">
      <Col md={6} lg={7} xs={12} className="nexogic-form-side-content text-center">
        <div className="nexogic-form-info">
          <p><img src={logo} alt="" className="nexogic-filter-white" /></p>
          <p className="card-text text-white mb-5">Sign in or create an account</p>
          <p><img
                    src={verifyMobileImg}
                    className="img-fluid"
                    alt="nexogic"
                  />
          </p>
          {/** <div className="welcome-text">
            <p>Welcome to</p>
            <h3>nexogic</h3>
          </div>*/}
          
        </div>
      </Col>
      <Col md={6} lg={5} xs={12} className="nexogic-form-content card-body p-0">
        <div className="nexogic-form-container">
          <div>
            {data.phone ? (
              <>
                <h3 className="nexogic-form-title">
                  Verify your number
                </h3>
                <p className="nexogic-form-title-sub">
                  {`Verify your account by typing in the 6 digit code we sent via text.
                  We ${
                    resendLabel ? "resent" : "sent"
                  } a code to your mobile number`}{" "}
                  <span className="text-primary">{`${data.phone}`}</span>
                </p>
              </>
            ) : (
              <>
                <h3 className="nexogic-form-title">
                  Verify your email
                </h3>
                <p className="nexogic-form-title-sub">
                  {`Provide your email address to ${
                    resendLabel ? "resend" : "send"
                  } you a code for verification`}
                  <span className="text-primary">{` ${data.email}`}</span>
                </p>
              </>
            )}
            <form onSubmit={handleOTPVerification}>
              <div className="nexogic-otp-field-wrap">
              <ReactCodeInput
                id="vo_fields"
                ref={otpRef}
                type="number"
                placeholder={[" ", " ", " ", " ", " ", " "]}
                fields={6}
                fieldWidth={42}
                fieldHeight={38}
                onChange={(val) => setVerifyCode(val)}
                className="verification-code-masked-input mb-3"
              />
              </div>
              {!canResendOTP && (
                <div className="text-center nexogic-countdown">
                  <CountDown
                    time={60}
                    digital
                    onComplete={() => setCanResendOTP(true)}
                  />
                </div>
              )}
              {errorMessage ? (
                <div className="mt-4 card-text text-danger mb-2">{errorMessage}</div>
              ) : null}
              {canResendOTP && (
                <Button
                  id="vo_btn_resend"
                  className="nexogic_primary_button mb-3"
                  onClick={handleResendOTPVerification}
                  outline
                  style={{ width: "100%" }}
                  color="primary"
                  size="md"
                >
                  Resend OTP
                </Button>
              )}
              {!canResendOTP ? (
                <Button
                  id="vo_btn_submit"
                  type="submit"
                  className="nexogic_primary_button mb-3"
                  style={{ width: "100%" }}
                  color="primary"
                  size="md"
                >
                  Verify
                </Button>
              ) : null}

              {data.phone ? (
                <p className="card-text text-right mb-2">
                  <span className="btn btn-link" onClick={gotoMobileNumber}>
                    Entered a wrong number?
                  </span>
                </p>
              ) : (
                <p className="card-text text-right mb-2">
                  <span className="btn btn-link" onClick={gotoMobileNumber}>
                    Entered a wrong email?
                  </span>
                </p>
              )}
              {data.phone && (
                <p className="card-text text-right mb-2">
                  <span className="btn btn-link" onClick={gotoEmailVerify}>
                    Receive OTP via email
                  </span>
                </p>
              )}
            </form>
            <p className="card-text fw-semibold mt-4 nex-app-center">
              If you are having problem receiving OTP Please contact to{" "} 
              <a
                href={`${process.env.REACT_APP_WEBSITE_URL}/contact-us/`}
                target="_blank"
              >
                Support
              </a>{" "}
            </p>
          </div>
        </div>
      </Col>
    </Row>
    </div>
  );
});

export default VerifyOTP;
