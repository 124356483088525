import { useState } from "react";
import { PractitionerService } from "utils/PractitionerService";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FAILED_TO_DELETE_DATA } from "utils/Constants";
import CommonLoader from "common/CommonLoader";
import { Fade, Modal, ModalBody, ModalHeader, Toast, ToastBody, ToastHeader } from "reactstrap";
import { withAlert } from "react-alert";
import { useEffect } from "react";
import wordFileIcon from "assets/images/svgs/word-file-icon.svg";
import pdfFileIcon from "assets/images/svgs/pdf-file-icon.svg";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const ResumeInfo = (props) => {
    const { docId, doc, practitionerGuid, getProfileResumes } = props;
    const [isFileDownloading, setIsFileDownloading] = useState(false);
    const [viewDoc, setViewDoc] = useState("");

    const [isOpenResume, setisOpenResume] = useState(false);

    function ResumeFunc() {
        setisOpenResume(!isOpenResume);
    }

    const deleteResume = (id) => {
        swal("Are you sure you want to delete this Resume?", {
            buttons: ["cancel", "yes"],
        })
            .then((yesRemove) => {
                if (yesRemove) {
                    PractitionerService.deletePractitionerResume(practitionerGuid, id)
                        .then((response) => {
                            const { status } = response;
                            if (status === 204) {
                                showSuccessMessage("Resume deleted Succesdfully!");
                                getProfileResumes(practitionerGuid);
                            } else {
                                showErrorMessage(FAILED_TO_DELETE_DATA);
                            }
                        })
                }
            })
    }

    const downloadResume = async (docId) => {
        const viewDocument = `${process.env.REACT_APP_API_BASE_URL}practitioner-service/practitioners/${practitionerGuid}/resumes/${docId}`;
        setViewDoc(viewDocument);
    };

    useEffect(() => {
        downloadResume(docId);
    }, [docId])


    return (
        <>

            <li key={docId} >
                <div className="nexogic-document-item">
                    <img src={doc?.mimeType === 'application/pdf' ? pdfFileIcon : wordFileIcon} alt="" style={{ cursor: "pointer", height: '50px' }} onClick={() => ResumeFunc()} />
                    <div className="card-text" style={{ cursor: "pointer" }} onClick={() => ResumeFunc()}>{doc?.fileName}</div>

                    <div className="graphic" style={{ cursor: "pointer" }}>
                        <svg className='icon'
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="32"
                            fill="none"
                            viewBox="0 0 26 32"
                            onClick={() => deleteResume(doc?.id)}
                        >
                            <path
                                stroke="#EA4A4A"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M1 5.8H3.44444M3.44444 5.8H23M3.44444 5.8V22.6C3.44444 23.2365 3.70198 23.847 4.16041 24.2971C4.61883 24.7471 5.24058 25 5.88889 25H18.1111C18.7594 25 19.3812 24.7471 19.8396 24.2971C20.298 23.847 20.5556 23.2365 20.5556 22.6V5.8M7.11111 5.8V3.4C7.11111 2.76348 7.36865 2.15303 7.82707 1.70294C8.28549 1.25286 8.90725 1 9.55556 1H14.4444C15.0928 1 15.7145 1.25286 16.1729 1.70294C16.6313 2.15303 16.8889 2.76348 16.8889 3.4V5.8M9.55556 11.8V19M14.4444 11.8V19"
                            >
                            </path>
                        </svg>
                    </div>
                </div>
            </li>
            {
                isFileDownloading ?
                    <div className="" style={{ zIndex: "111", position: "absolute", top: "0", left: "0", backgroundColor: "#edeff0b0", width: "100%", height: "100%" }}>
                        <div className="component-wrapper"><CommonLoader /></div>
                    </div>
                    :
                    <div className={`cov-letter-preview ${isOpenResume ? 'show' : ''}`}>
                        <Fade className="mt-3">
                            <Toast isOpen={isOpenResume}>
                                <ToastHeader toggle={ResumeFunc}>View Resume</ToastHeader>
                                <ToastBody style={{ height: "1000px" }}>
                                    <embed
                                        src={`https://docs.google.com/viewer?url=${viewDoc}&embedded=true`}
                                        style={{ width: "100%", height: "100%" }}
                                    />
                                </ToastBody>
                            </Toast>
                        </Fade>
                    </div>
            }
        </>
    );
}

export default withAlert()(ResumeInfo);