import React, { useState, useEffect } from 'react'
import { Col, Row } from 'reactstrap'

import { SuggestedDoctors } from "./components/SuggestedDoctors"

const RightSidebar = (props) => {
    return (
        <>
            <SuggestedDoctors practitionerId={props.practitionerId}/> {/* Used existing user practitionerId for now */}
        </>
    )
}

export default RightSidebar