import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import parse from "html-react-parser";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { UserStatusConstants } from "utils/Constants";

import pencilIcon from "assets/images/pencil_icon.png";
import plusIcon from "assets/images/plus-icon-lg.png";
import edit_icon_card_head from "assets/images/edit-blue-icon.png";
import {
  profileAddEvent,
  updateProfileCompletness,
} from "redux-helpers/ActionCreator";
import { getEmptySectionShowHide } from "utils/Utils";
import { AboutModal } from "./components/AboutModal";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import LongText from "../LimitLongText";
import NoData from "../NoData";

const RenderProfileSummary = (props) => {
  const { profileSummary, isOpen, setIsOpen } = props;
  {/** 
  return (
    <>
    
  <div className="card-text">
              <LongText content={profileSummary ? profileSummary.replace(/<[^>]*>/g, '') : null} limit={440} />
            </div>
            
      </>
      )
  */}
  if (isOpen) {
    return (
      <>
        <div className="card-text">
          {profileSummary ? parse(profileSummary) : ""}

          <Button
            color="link"
            onClick={() => setIsOpen(false)}
            className="readmore"
          >
            Read less
          </Button>
        </div>
      </>
    );
  } else {
    const showLessSummary = profileSummary.slice(0, 500) + "...";

    if (profileSummary.length > 500) {
      return (
        <>
          <div className="card-text">
            {parse(showLessSummary)}

            <Button
              color="link"
              onClick={() => setIsOpen(true)}
              className="readmore"
            >
              Read more
            </Button>
          </div>
        </>
      );
    } else {
      return (
        <div className="mt-2 mb-1 f-14 about-text">{parse(profileSummary)}</div>
      );
    }
  }


};

export const About = (props) => {
  const [pannel_on, setPannel_on] = useState(true);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const eventName = useSelector((state) => state.profileComplete.eventName);
  const dispatch = useDispatch();
  const { practitioner, isLoggedinUser, setPractitioner, isAdmin, setShowAboutOnTabScroll } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const toggle = () => setIsEditMode(!isEditMode);
  const { summary, guid } = practitioner;
  const isNotEmpty = useMemo(() => {
    return summary !== "<p><br></p>" && summary !== "" && !!summary !== false;
  }, [summary]);

  useEffect(() => {
    dispatch(updateProfileCompletness(2, isNotEmpty ? true : false));
  }, [summary]);

  useEffect(() => {
    if (summary && summary.length > 0 ) {
      setShowAboutOnTabScroll(true);
    }else if(isLoggedinUser || isAdmin ){
      setShowAboutOnTabScroll(true);
    }
      else{
        setShowAboutOnTabScroll(false);
      }
     
    
    
  }, [isLoggedinUser, summary])


  useEffect(() => {
    if (eventName === "ADD_ABOUT") {
      toggle();
      // scrollToAbout();
      dispatch(profileAddEvent(null));
    }
  }, [eventName]);

  const scrollToAbout = () => {
    var element = document.getElementById("aboutSection");
    var headerOffset = 45;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  };
  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );

  if (
    getEmptySectionShowHide(
      summary ? [summary] : [],
      isAdmin,
      currentUser,
      globalProfileInfo,
      guid
    )
  )
    return null;

  return (
    <>
      <div className={`card nexogic-card-color-header ${pannel_on ? 'expanded' : ''}`} id="aboutSection">
        <div className="card-header">
          <h2>About</h2>
          {(isAdmin ||
            (isLoggedinUser &&
              currentUser.status === UserStatusConstants.APPROVED)) && (
              <Button
                id="nex-pa-about-edit-btn"
                color="link"
                className="add-button"
                onClick={toggle}
              >
                {isNotEmpty ? (
                  <>
                    {/** <img src={pencilIcon} alt="Edit" />*/}
                    <img src={edit_icon_card_head} alt="" />
                  </>
                ) : (
                  <>
                    <img src={plusIcon} alt="" />
                    {/*<FontAwesomeIcon icon={faPlus} />*/}
                  </>
                )}
              </Button>
            )}
          <button className="collapse-btn" type="button" onClick={() => setPannel_on(!pannel_on)}>
            <img src={pannel_arrow} alt="" />
          </button>
        </div>
        <div className={`collapse-div`}>
          <div className="card-body">
            {isNotEmpty ? (
              <RenderProfileSummary
                profileSummary={summary}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
              />
            ) : (
              <div className="mt-1">
                <Row>
                  <Col className="">
                    {(isLoggedinUser || isAdmin) && (
                      <div className="empty-about-box py-2">
                        <div className="about-placeholder">
                          <NoData>Showcase your work by featuring your best posts,
                          documents, media and websites.</NoData>

                    
                        </div>
                        {currentUser.status ===
                          UserStatusConstants.APPROVED && (
                            <div className="readmore" onClick={toggle}>
                              Add information
                            </div>
                          )}
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            )}
            <AboutModal
              isOpen={isEditMode}
              toggle={toggle}
              profileSummary={summary || ""}
              setPractitioner={setPractitioner}
              practitioner={practitioner}
              isAdmin={isAdmin}
            />
          </div>
        </div>
      </div>

      {/** 
      <Card id="aboutSection" className="doctors-details bg-white">
        <CardBody>
          <Row>
            <Col>
              <Row>
                <Col>
                  {" "}
                  <h3 className="section-title m-0">About</h3>
                </Col>
                {(isAdmin ||
                  (isLoggedinUser &&
                    currentUser.status === UserStatusConstants.APPROVED)) && (
                  <Col xs="auto">
                    <Button
                      color="link"
                      className="round-icon-btn rounded-circle"
                      onClick={toggle}
                    >
                      {isNotEmpty ? (
                        <img src={pencilIcon} alt="Edit" />
                      ) : (
                        <FontAwesomeIcon icon={faPlus} />
                      )}
                    </Button>
                  </Col>
                )}
              </Row>

              {isNotEmpty ? (
                <RenderProfileSummary
                  profileSummary={summary}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                />
              ) : (
                <div className="mt-1">
                  <Row>
                    <Col className="">
                      {(isLoggedinUser || isAdmin) && (
                        <div className="empty-about-box p-0">
                          <div className="about-placeholder">
                            Showcase your work by featuring your best posts,
                            documents, media and websites.
                          </div>
                          {currentUser.status ===
                            UserStatusConstants.APPROVED && (
                            <div className="add-infomation" onClick={toggle}>
                              Add information
                            </div>
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              )}
            </Col>

            <AboutModal
              isOpen={isEditMode}
              toggle={toggle}
              profileSummary={summary || ""}
              setPractitioner={setPractitioner}
              practitioner={practitioner}
              isAdmin={isAdmin}
            />
          </Row>
        </CardBody>
      </Card>
      */}
    </>
  );
};
