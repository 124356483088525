import React, { useState, memo, useEffect } from "react";
import { Button, Progress } from "reactstrap";
import { withAlert } from "react-alert";
import AdditionalInfo from "./Components/AdditionalInfo";
import ContactInfo from "./Components/ContactInfo";
import JobPreview from "./Components/JobPreview";
import Resume from "./Components/Resume";
import "./apply-jobs.css";
import * as Yup from "yup";

import { Formik, Form, ErrorMessage } from "formik";
import { PractitionerService } from "utils/PractitionerService";
import { TalentService } from "utils/TalentService";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const validationSchema = Yup.object().shape({
  hiddenFile: Yup.string()
    .required("Please select or upload Resume "),
  answers: Yup.array()
    .of(
      Yup.object().shape({
        answer: Yup.string().required("Please answer the question"),
      })
    ),
});

const ApplyJobs = (props) => {

  const { practitioner, jobDet, toggle, getJob } = props;
  const initialValue = {
    job_id: jobDet?.id,
    hiddenFile: '',
    hiddenFileExt: "",
    practitionerId: practitioner?.guid,
    phonenumber: practitioner?.phones[0]?.value,
    emailid: practitioner?.emails[0]?.value,
    resumeDetails: null,
    cvID: '',
    coverLetter: '',
    answers: [],
  }

  const [page, setPage] = useState(1);
  const [formData, setFormData] = useState(initialValue);
  const [loading, setloading] = useState(false);
  const [fetchLoading, setfetchLoading] = useState(false);
  const [listResumes, setListResumes] = useState();
  const { alert } = props;

  const [isResumePresent, setIsResumePresent] = useState(false);
  const [percentage, setPercentage] = useState("0");

  const getPercentage = () => {
    if (page === 1)
      setPercentage("0");
    else if (page === 2)
      setPercentage("50");
    else if (page === 3) {
      if (isResumePresent)
        setPercentage("100");
      else
        setPercentage("50");
    }

    else
      return "";
  }

  useEffect(() => {
    getPercentage();
  }, [page])

  function goNextPage() {
    setPage((page) => page + 1);
  }

  function goBackPage() {
    setPage((page) => page - 1);
  }

  const createPayloadMethod = (value, cvid) => {
    const {
      coverLetter,
      cvID,
      answers
    } = value;
    let payload = value;

    const createPayload = {
      contactDetails: {
        phoneNumber: payload?.phonenumber,
        emailAddress: payload?.emailid,
      },
      coverLetter: coverLetter,
      cvID: cvid,
      answers: answers
    };
    return createPayload;
  }
  const getProfileResumes = (guid) => {
    try {
      PractitionerService.getResumesProfile(guid).then((response) => {
        if (response.status === 200 || response.status === 201) {
          setListResumes(response.data);
        }
      });
    } catch (e) {
      console.log("Error while getting Practitioner getProfileResumes: ", e);
    }
  };
  const addResume = async (value, actions) => {
    setloading(true);
    let payload = { ...value };
    try {
      const request = PractitionerService.uploadResumePractitioner(payload.practitionerId, payload.resumeDetails);
      request
        .then(async (response) => {
          const { status, data } = response;
          if (status === 201) {
            actions.setFieldValue("cvID", data?.id);
            let payload = createPayloadMethod(value, data?.id);
            const req = TalentService.applyJob(jobDet.id, payload);
            req.then(async (resp) => {
              if (resp.status === 200 || resp.status === 201) {
                showSuccessMessage("Application submitted successfully");
                toggle();
              } else {
                let errmsg =
                  resp.data && !!resp.data.message
                    ? resp.data.message
                    : "Job Application already exists !!!";
                showErrorMessage(errmsg);
                toggle();
              }
            })
            setloading(false);
          } else {
            setloading(false);
            let errmsg =
              data && !!data.message
                ? data.message
                : "Something went wrong. Resume upload failed !!!";
            showErrorMessage(errmsg);
            toggle();
          }
        })
        .catch((error) => {
          setloading(false);
          console.log(error);
        });
    } catch (e) {
      setloading(false);
      console.log(e);
    }
  };

  const ApplyJob = async (value) => {
    setloading(true);
    let payload = createPayloadMethod(value, value.cvID);
    applyJobProcess(payload);

  };
  const applyJobProcess = (payload) => {
    try {
      const request = TalentService.applyJob(jobDet?.id, payload);
      request.then(
        async (response) => {
          const { status, data } = response;
          if (status === 200 || status === 201) {
            showSuccessMessage("Application submitted successfully");
            setloading(false);
            toggle();
          } else if (status === 409) {
            showSuccessMessage("Job Application already exists ");
            setloading(false);
            toggle();
          }
          else {
            let errmsg =
              response.data && !!response.data.message
                ? response.data.message
                : "Something went wrong....Job Application already exists !!!";
            showErrorMessage(errmsg);
            setloading(false);
            toggle();
          }
        })
        .catch((error) => {
          setloading(false);
          console.log(error);
        });
    } catch (e) {
      setloading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    setFormData(initialValue);
  }, []);
  useEffect(() => {
    getProfileResumes(practitioner?.guid);
  }, []);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={formData}
      onSubmit={(values, actions) => {
        if (formData.resumeDetails === null) {
          // setErrors({answers:'File not selected'});
          return false;
        } else {
          if (values.cvID) {
            ApplyJob(values);
          } else {
            addResume(values, actions);
          }
        }
        getJob(jobDet.profileId);
      }}
      enableReinitialize
      children={(formProps) => {
        return (
          <>
            <Form className='form-container'>
              <div className="custom-progress px-3">
                <Progress
                  value={` ${percentage} `}
                />
                <span className="value-view">
                  {` ${percentage} %`}
                </span>
              </div>
              <div className="parent-div p-3 row">
                <div className="component-div col p-0">
                  <div className="display">
                    {page === 1 && <ContactInfo formData={formData} setFormData={setFormData} goNextPage={goNextPage} practitionerResumes={listResumes} getProfileResumes={getProfileResumes} {...formProps} {...props} />}
                    {page === 2 && <Resume setIsResumePresent={setIsResumePresent} formData={formData} setFormData={setFormData} goBackPage={goBackPage} goNextPage={goNextPage} practitionerResumes={listResumes} getProfileResumes={getProfileResumes} {...formProps} {...props} />}
                    {page === 3 && <JobPreview formData={formData} setFormData={setFormData} goBackPage={goBackPage} {...formProps} {...props} />}
                  </div>
                  <div className="apply-btn-action">
                    {(page === 2 || page === 3) && <Button id="ah_btn_back" onClick={goBackPage} color="link" type="button" className="nexogic_primary_button btn-150">Back</Button>}
                    {(page === 1 || page === 2) && <Button id="ah_btn_next" color="primary" onClick={goNextPage} type="button" className="nexogic_primary_button btn-150">Next</Button>}
                    {page === 3 && <Button color="primary" id="ah_btn_submit" disabled={formProps.isSubmitting} type="submit" className="d-block d-none nexogic_primary_button btn-150">Submit</Button>}
                  </div>
                </div>
              </div>
            </Form>
          </>
        );
      }}
    />
  );
};

export default withAlert()(memo(ApplyJobs));
