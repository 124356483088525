import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import CommonLoader from "common/CommonLoader";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Button, Card, CardImg, Col, Label, Row } from "reactstrap";
import { authSetUser } from "redux-helpers/ActionCreator";
import styled from "styled-components";
import { listOfDocuments } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import { getUser, setUser } from "utils/UserHelper";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  documentType: Yup.array().of(Yup.string().required("This field is required")),
  documentName: Yup.array().of(Yup.string().required("This field is required")),
  files: Yup.array().of(
    Yup.array()
      .min(1, "Please upload documents for verification!")
      .required("Required")
  ),
});

const initialValues = {
  documentType: "",
  documentName: "",
  files: [],
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 10px;
  border-color: #e5f2f6;
  border-style: dashed;
  background-color: #ffffff;
  color: rgba(0, 18, 68, 0.5);
  outline: none;
  transition: border 0.24s ease-in-out;
  font-size: 14px;
  line-height: 21px;
  background-color: rgba(0, 159, 215, 0.02);
`;

const UploadLink = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #3EABCA;
  cursor: pointer;
`;

const DragNDrop = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #66718f;
`;

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
  background: "#FFFFFF",
  borderRadius: 10,
  border: "2px dashed #E5F2F6",
  padding: "16px",
  backgroundColor: "rgba(0, 159, 215, 0.02)",
};

const RenderDocumentFormik = (props) => {
  const { documentTypes, onAddDocument, onDelete } = props;
  const formRef = useRef();
  const lastDocumentObj = documentTypes[documentTypes.length - 1];
  const nodeRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
  const [isSkipButtonDisabled, setIsSkipButtonDisabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const findDuplicates = (arr) =>
    arr.filter((item, index) => arr.indexOf(item) !== index);

  const updateCurrentprofileStorage = (guid) => {
    setIsSkipButtonDisabled(true);
    PractitionerService.gePractitionersDetails(guid)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (response && response.data) {
            const data = response.data;
            setUser(data);
            dispatch(authSetUser(data));
            return response.data;
          }
        }
      })
      .then((res) => {
        if (res) {
          const { profileId } = res;
          history.push(`/profile/${profileId}`);
        }
      })
      .catch((error) => {
        setIsSkipButtonDisabled(false);
        console.log("PractitionerService.fetch error", error);
      });
  };

  const onSubmit = (values) => {
    setIsSubmitting(true);
    setIsNextButtonDisabled(true);
    const userGUID = getUser().guid;
    const payload = [];
    Object.values(values.documentName).forEach((data, index) => {
      payload.push({
        documentName: values.documentName[index],
        documentType: values.documentType[index],
        files: values.files[index],
      });
    });
    if (
      payload.some((person) => person.documentType === "ID_PROOF") &&
      payload.some((person) => person.documentType === "REGISTRATION_DOCUMENT")
    ) {
      if (
        findDuplicates(payload.map((node) => node?.files[0]?.path)).length === 0
      ) {
        PractitionerService.registerDocumentsDetails(payload, userGUID)
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              updateCurrentprofileStorage(userGUID);
              setIsSubmitting(false);
            } else {
              if (response.validationErrors) {
                response.validationErrors.map((err) => {
                  props.alert.error(`${err.field}: ${err.message}`);
                });
              } else if (response.message) {
                props.alert.error(response.message);
                setIsNextButtonDisabled(false);
              }
              setIsSubmitting(false);
            }
          })
          .catch((error) => {
            setIsSubmitting(false);
            console.log(
              "PractitionerService.registerDocumentsDetails error",
              error
            );
            props.alert.error("server facing problem while Adding Documents");
            setIsNextButtonDisabled(false);
          });
      } else {
        props.alert.error(
          "Please check the uploaded files. The uploaded File names must be unique."
        );
        setIsNextButtonDisabled(false);
      }
    } else {
      props.alert.error(
        "Please upload Medical Registration document and Government Issued Photo ID."
      );
      setIsNextButtonDisabled(false);
      setIsSubmitting(false);
    }
  };

  const handleAddDocument = (document) => {
    const newObj = {
      id: document.id + 1,
      type: "SECONDARY",
      formCount: document.formCount + 1,
    };
    onAddDocument(newObj);
  };

  const handleRemoveDocument = (document) => {
    const id = document.id;
    onDelete(id);
    window.scrollBy(0, -100);
  };

  return (
    <Row className="render-document-form">
      <Col xs={12}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={false}
          onSubmit={onSubmit}
          innerRef={formRef}
          children={(props) => {
            return (
              <Form className="add-document">
                <TransitionGroup className="todo-list">
                  {documentTypes.map((document, i) => {
                    return (
                      <CSSTransition
                        key={document.id}
                        nodeRef={nodeRef}
                        timeout={500}
                        classNames="item-trans"
                      >
                        <div ref={nodeRef}>
                          {document.formCount >= 2 && (
                            <div className="horizontal-line my-3"></div>
                          )}
                          <RenderForm documentId={document.id} {...props} />
                          {lastDocumentObj.formCount === document.formCount && (
                            <>
                              <Row className="mt-4 add-more-document">
                                <Col lg={6}>
                                  <Button
                                    id="df_add_more_doc"
                                    onClick={() => {
                                      props.validateForm().then(() => {
                                        if (props.isValid) {
                                          handleAddDocument(document);
                                        } else {
                                          console.log(props);
                                        }
                                      });
                                    }}
                                    className="d-flex flex-row align-items-center nexogic_primary_button_outline "
                                    type="submit"
                                  >
                                    + Add more documents

                                  </Button>
                                  <ToastContainer
                                    position="bottom-left"
                                    autoClose={5000}
                                    hideProgressBar
                                    newestOnTop={false}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                  />
                                </Col>
                                {document.type === "SECONDARY" && (
                                  <Col
                                    lg={6}
                                    className="d-flex justify-content-end align-items-center"
                                  >
                                    <div
                                      onClick={() =>
                                        handleRemoveDocument(document)
                                      }
                                      className="ml-auto nex-doc-remove-btn-w"
                                    >
                                      <p className="mb-0 card-text btn nexogic_primary_button_outline nex-doc-remove-btn nex-app-width-full">
                                        Remove this document
                                      </p>
                                    </div>
                                  </Col>
                                )}
                              </Row>
                            </>
                          )}
                        </div>
                      </CSSTransition>
                    );
                  })}
                </TransitionGroup>
                <Row className="mt-4 mb-4 nex-regi-actions-bot-w">
                  {/* <Col lg={6} xs={6}>
                    <Button
                      className="d-block ml-auto back-btn"
                      type="button"
                      onClick={onBack}
                    >
                      Back
                    </Button>
                  </Col> */}
                  <Col lg={12}>
                    {isSubmitting
                      ?
                      <div className="text-center">
                        <CommonLoader />
                      </div>
                      :
                      <div className="actions d-flex flex-wrap">
                        <Button onClick={() => updateCurrentprofileStorage(getUser().guid)} className=" next-btn nexogic_primary_button_outline df_btn_next" type="button" disabled={isSkipButtonDisabled}>
                          Skip and submit
                        </Button>
                        <Button className=" next-btn nexogic_primary_button df_btn_next" type="submit" disabled={isNextButtonDisabled}>
                          Submit
                        </Button>
                      </div>
                    }
                  </Col>
                </Row>
              </Form>
            );
          }}
        />
      </Col>
    </Row>
  );
};

const RenderForm = (props) => {
  const {
    values,
    errors,
    touched,
    documentId,
    setFieldValue,
    setErrors,
    setTouched,
  } = props;

  const fieldsCleanUp = () => {
    delete values.documentName[documentId];
    delete values.documentType[documentId];
    delete values.files[documentId];
    setFieldValue(`documentName`, [...values.documentName]);
    setFieldValue(`documentType`, [...values.documentType]);
    setFieldValue(`files`, [...values.files]);
    setTimeout(() => {
      setErrors({});
      setTouched({});
    }, 0);
  };

  useEffect(() => {
    setFieldValue(
      `documentName[${documentId}]`,
      values.documentName[documentId] ? values.documentName[documentId] : ""
    );
    setFieldValue(
      `documentType[${documentId}]`,
      values.documentType[documentId] ? values.documentType[documentId] : ""
    );
    return () => {
      fieldsCleanUp();
    };
  }, [documentId]);

  useEffect(() => {
    if (documentId <= 1) {
      setFieldValue(`documentType[0]`, "ID_PROOF");
      setFieldValue(`documentType[1]`, "REGISTRATION_DOCUMENT");
    }
    if (documentId !== 0 && documentId !== 1) {
      setFieldValue(`documentType[${documentId}]`, "ID_PROOF");
    }
  }, [documentId, setFieldValue]);

  const getDocumentName = (documentid) => {
    if (documentid) {
      const getLabel = listOfDocuments.filter(
        (item) => item.key === documentid
      );
      return getLabel[0]?.label;
    }
  };

  return (
    <>
      <Row className="mt-4">
        <Col lg={6}>
          <Field
            id={`documentType[${documentId}]`}
            name={`documentType[${documentId}]`}
            autoComplete={`documentType[${documentId}]`}
            type="text"
            label="Document Type"
            component={FormikSelectField}
            className={
              !values.documentType[documentId] &&
                props.errors?.documentType?.[documentId] &&
                touched?.documentType?.[documentId]
                ? "is-invalid"
                : ""
            }
            inputprops={{
              name: `documentType[${documentId}]`,
              options: listOfDocuments,
            }}
          />
        </Col>
        <Col lg={6}>
          <Field
            id={`documentName[${documentId}]`}
            name={`documentName[${documentId}]`}
            autoComplete={`documentName[${documentId}]`}
            type="text"
            className={
              !values.documentName[documentId] &&
                props.errors?.documentName?.[documentId] &&
                touched?.documentName?.[documentId]
                ? "is-invalid"
                : ""
            }
            label="Document Name"
            component={FormikInputField}
            value={values.documentName[documentId]}
            placeholder="Enter"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Label htmlFor="files" className="label-color">
            {getDocumentName(values.documentType[documentId])}
          </Label>
          <UploadDocument
            name={`files[${documentId}]`}
            id={`files[${documentId}]`}
            documentId={documentId}
            {...props}
          />
          {errors.files && touched.files && (
            <ErrorMessage
              name={`files[${documentId}]`}
              render={(msg) => (
                <span className="text-danger f-12 d-inline-block  mt-1 line-height-error">
                  {msg}
                </span>
              )}
            />
          )}
        </Col>
      </Row>
    </>
  );
};
const UploadDocument = (props) => {
  const { setFieldValue, values, errors, touched, documentId } = props;
  const [files, setFiles] = useState([]);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: "image/jpeg,image/jpg,image/png,application/pdf",
    onDrop: (acceptedFiles, fileRejections) => {
      const selectedFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
      setFiles([...files, ...selectedFiles]);
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === "file-invalid-type") {
            alert(`Only PDF & Images files are allowed.`);
          }
        });
      });
      // setFieldValue(name,[...files]);
    },
    maxFiles: 1,
  });

  useEffect(() => {
    setFieldValue(`files[${documentId}]`, files);
  }, [files]);

  const deleteFile = (deletedFile) => {
    const deleteIndex = files.findIndex(
      (file) => file.preview === deletedFile.preview
    );
    setFiles([...files.slice(0, deleteIndex), ...files.slice(deleteIndex + 1)]);
  };

  const thumbs = files.map((file, index) => (
    <Card
      key={`file-${index}`}
      className="mt-2 ml-2 hover-block overflow-hidden"
    >
      {file.type === "application/pdf" ? (
        <embed
          top="true"
          className="doc-image"
          src={file.preview}
          height="110"
        />
      ) : (
        <CardImg
          top={true}
          className="doc-image"
          src={file.preview}
          height="110"
          alt="Card image cap"
        ></CardImg>
      )}
      <div className="document-name d-flex align-items-center justify-content-center">
        <a href={file.preview} target="_blank" className="text-truncate">
          {file.name}
        </a>
      </div>
      <Button
        color="link"
        className="visible-hover py-0 position-absolute bg-black"
        onClick={() => deleteFile(file)}
      >
        <FontAwesomeIcon icon={faPlus} className="delete-icn" />
      </Button>
    </Card>
  ));

  return (
    <div className="nexogic-upload-button-w">
      {files.length === 1 ? (
        <aside style={thumbsContainer}>{thumbs}</aside>
      ) : (
        <Container
          {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
          className={classnames({
            "err-dropbox": errors.files && touched.files,
          })}
        >
          <input {...getInputProps()} />
          <p className="icon text-center mb-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={56}
              height={56}
              viewBox="0 0 16 16"
              fill="currentColor"
              className="bi bi-cloud-upload"
            >
              <path
                fillRule="evenodd"
                d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"
              />
              <path
                fillRule="evenodd"
                d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z"
              />
            </svg>
          </p>
          <p className="card-text text-center">
            Please upload a document for verification in “pdf”, “png”, “jpeg” format
          </p>
          <div className="card-text align-items-center d-flex flex-wrap">
            <UploadLink className="mr-2" onClick={open}>
              Select file
            </UploadLink>
            <DragNDrop className="mr-2 f-14 line-h-20">
              or Drag &amp; Drop here
            </DragNDrop>
          </div>
        </Container>
      )}
    </div>
  );
};
export default RenderDocumentFormik;
