import React, { useEffect } from "react";
import { createImageFromInitials, getFullAddress, getRandomColor } from "utils/Utils";
import { encrypt } from "utils/EncryptDecrypt";
import { Col, FormGroup, Input, Row, Card, CardBody, Button, FormFeedback } from "reactstrap";
import { Label } from "reactstrap/lib";
import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import { formatBytes, dateFormat } from "utils/Utils";
import AddressInstitution from "pages/Profile/components/DoctorsDetails/components/AddressInstitution";
import CommonLoader from "common/CommonLoader";
const JobPreview = (props) => {
  const { practitioner, formData, setFormData, values, handleSubmit, setFieldValue, touched, errors, loading, jobDet, goBackPage } = props;
  const {
    title,
    firstName,
    middleName,
    lastName,
    address,
    phones,
    emails,
    qualification,
    primarySpeciality,
    superSpeciality,
  } = practitioner;
  function getQuestionCodes() {
    jobDet?.screeningQuestions?.map((squestion, index) => {
      setFieldValue(`answers[${index}].questionCode`, squestion.questionCode)
      setFieldValue(`answers[${index}].question`, squestion.question)
    })
  }
  useEffect(() => {
    getQuestionCodes();
  }, [])

  return (
    <form onSubmit={handleSubmit}>
      <div className="pvw-aplication">
        <div className="job-pop-review-wrap">
          <h4 className="titles">Review Your Application</h4>
          <h6 className="hint-sub-txt">
            The employer will also receive a copy of your profile.
          </h6>
          <div className="card border-0 nexogic-job-popup">
            <div className="prev_head">
              <span className="prev-label">Contact Info</span>
            </div>
            <div className="card-body d-flex px-0">
              <div className="card-thumb">
                {(practitioner && practitioner?.avatarId) ?
                  < img
                    src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${practitioner.guid}/${practitioner.avatarId}`}
                    className="card-img rounded-circle" alt="#" />
                  :
                  < img
                    src={createImageFromInitials(50, `${practitioner?.name}`, getRandomColor(encrypt(practitioner?.guid).toString()))}
                    className="card-img rounded-circle" alt="#" />
                }
              </div>
              <div className="info px-3">
                <h3 className="card-title">{title} {firstName ?? ""} {middleName ?? ""} {lastName ?? ""}, <span style={{ color: "#767878" }}>{qualification}</span></h3>
                <p className="card-text">{primarySpeciality ?? ""} , {superSpeciality ?? ""}</p>
                <p className="card-text">
                  <AddressInstitution
                    city={address[0]?.city ?? ""}
                    state={address[0]?.state ?? ""}
                    country={
                      address[0]?.country ?? ""
                    }
                  />
                </p>
              </div>
            </div>
          </div>
          <hr className="mb-2" />
          <Row>
            <Col md={6} className="py-0">
              <FormGroup className="mb-2">
                <Label className="prev-label">Phone Number</Label>
                <p className="prev-val-txt py-2">{formData.phonenumber}</p>
              </FormGroup>
            </Col>
            <Col md={6} className="py-0">
              <FormGroup className="mb-2">
                <Label className="prev-label">Email Address</Label>
                <p className="prev-val-txt py-2">{formData.emailid}</p>
              </FormGroup>
            </Col>
          </Row>
          <hr className="mb-2" />
          <div className="prev-address">
            <div className="prev_head">
              <span className="prev-label">Cover Letter</span>
            </div>
            <Card className="bg-white mb-2 cov-letter-wrap">
              <CardBody>
                <div
                  className="list-para mt-1"
                  dangerouslySetInnerHTML={{
                    __html: formData.coverLetter,
                  }}
                />
              </CardBody>
            </Card>
            <div className="prev_head mt-3">
              <span className="prev-label">Resume</span>
            </div>
            <div className="resume-wrap">
              {(formData?.resumeDetails !== null) ? (!(formData?.resumeDetails?.id) ? (
                <div className="up-resme-wrp view">
                  <div className={formData?.resumeDetails?.type === 'application/pdf' ? "upl-icon text-white bg-danger" : "upl-icon bg-primary text-white"}>{formData?.resumeDetails?.type === 'application/pdf' ? "PDF" : "DOC"}</div>
                  <div className="uploadtxt-wrap  ">
                    <label>{formData?.resumeDetails?.name}</label>
                    <p>{formatBytes(formData?.resumeDetails?.size)}</p>
                    <p>{dateFormat(formData?.resumeDetails?.createdOn)}</p>
                  </div>
                  {/*<div className="cancel-icon"><span className="nexogic_primary_button">View</span></div>*/}
                </div>
              ) : (
                <div className="up-resme-wrp view">
                  <div className={formData?.resumeDetails?.mimeType === 'application/pdf' ? "upl-icon text-white bg-danger" : "upl-icon bg-primary text-white"}>{formData?.resumeDetails?.mimeType === 'application/pdf' ? "PDF" : "DOC"}</div>
                  <div className="uploadtxt-wrap  ">
                    <label>{formData?.resumeDetails?.fileName}</label>
                    <p>{formatBytes(formData?.resumeDetails?.size)}</p>
                  </div>
                </div>
              )) : (
                <Card className="extcard py-3" style={{ backgroundColor: "#fff4f4" }}><div className="text-danger f-14 d-inline-block  mt-1 line-height-error text-center"> No Resume Selected or uploaded </div></Card>
              )}
            </div>
          </div>
          {jobDet?.screeningQuestions && (jobDet?.screeningQuestions.length > 0) && (
            <fieldset className="u-flsd-wpr mt-4 mb-4">
              <legend>Screening Questions</legend>
              <div className="prev-address">
                {jobDet?.screeningQuestions?.map((squestion, index) => {
                  return (
                    <>
                      <div className="screening-question" key={index}>
                        <div className="screen-qhead">
                          <div className="q-txt"> {squestion?.question}</div>
                        </div>
                        <div className="screen-body">
                          <Row>
                            <Col md={12} className="d-flex align-items-start ">
                              {values?.answers[index]?.answer}
                            </Col>
                          </Row>
                        </div>
                      </div>
                      {errors?.answers && (
                        <p>
                          <RenderErrorMessage
                            errorMsg={errors?.answers[index]?.answer}
                          />
                        </p>
                      )}
                    </>
                  )
                })}
              </div>
            </fieldset>
          )}
          {/* <FormGroup check>
            <Input type="checkbox" />
            <Label check>
              Please Follow <b>Company</b> for latest updates.
            </Label>
          </FormGroup> */}
        </div>
      </div>
    </form>
  );
};

export default JobPreview;
