import React from 'react';
import { Button } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { useHistory, Link } from "react-router-dom";

const GroupRecord = ({ profileDetails, index, toggleAlert, setMemberIdToDelete, currentUser, currentUserRole }) => {
    let history = useHistory();
    return (
        <tr key={`profile_${index}`}>
            <td className="border font-weight-bold">
                <div className=' d-flex'>
                    <h4 className="link-text">
                        <Link className="group-link nex-gi-group-group-name" to={`/institution/${profileDetails?.institutionProfileInfo?.profileId}`}>
                            {profileDetails?.memberDetails?.name}
                        </Link>
                    </h4>
                </div>
            </td>
            <td className="border font-weight-bold">
                <div className=' d-flex'>
                    <h4 className="link-text">
                        {profileDetails?.memberDetails?.primarySpeciality}
                    </h4>
                </div>
            </td>

            <td className="border">
                {currentUser?.guid !== profileDetails?.memberId ? (
                    <Button className="msg-button" outline color="info"
                        onClick={() => { history.push('/messages') }}
                    >
                        <FontAwesomeIcon icon={faPaperPlane} />
                    </Button>
                ) : (
                    ""
                )}
            </td>

            <td>
                {currentUserRole === "ADMIN" && profileDetails.role != "ADMIN" ? (
                    <Button
                        className="ml-2 msg-button"
                        outline
                        color="danger"
                        onClick={() => {
                            setMemberIdToDelete(profileDetails.memberId);
                            toggleAlert();
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </Button>
                ) : (
                    ""
                )}
            </td>
        </tr >
    );
}

const GroupMembersTabularView = (props) => {
    const { theader, tdata, handleEditItem, setGroupIdToAct, toggleAlert, handleUnFollow, setMemberIdToDelete, currentUser, currentUserRole } = props;

    // useEffect(() => {
    //     console.log(tdata);
    // }, [tdata])

    return (
        <div className="profiles-table">
            <table className="table border" >
                <thead>
                    <tr>
                        {theader.map((columns, index) => (
                            <th className="theader" key={index}>{columns}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tdata.map((profileDetails, index) => (
                        <GroupRecord profileDetails={profileDetails} index={index} group={profileDetails} handleEditItem={handleEditItem}
                            toggleAlert={toggleAlert}
                            setGroupIdToAct={setGroupIdToAct}
                            handleUnFollow={handleUnFollow}
                            setMemberIdToDelete={setMemberIdToDelete}
                            currentUser={currentUser}
                            currentUserRole={currentUserRole}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default GroupMembersTabularView