import React from 'react'
import { useState } from 'react';
import { Card, CardBody, Collapse } from 'reactstrap';
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";

export default function CollapseablePannel(props) {
    const [pannel_on, setPannel_on] = useState(true);
  return (
    <Card className={`nexogic-card-color-header ${pannel_on ? 'expanded' : ''}`} id={props.id} >
        <div className="card-header"><h2>{props.title}</h2>
                            {props.headAction}
                            <button id="agd_btn_desc" className="collapse-btn" type="button" onClick={()=>setPannel_on(!pannel_on)}>
                              <img src={pannel_arrow} alt="" />
                          </button>
        </div>
        <Collapse isOpen={pannel_on} >
            <CardBody>
                {props.children}
            </CardBody>
        </Collapse>
    </Card>
  )
}
