import React, { useState } from "react"
import { Card, CardBody, CardText, CardTitle, Col, Row, Button } from "reactstrap"
import { createImageFromInitials, getFullAddressWithLocationCityAndState, getRandomColor } from "utils/Utils"
import { encrypt } from "utils/EncryptDecrypt"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faIdCard, faListAlt, faTable } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { CreateInstitutionModal } from "./CreateInstitutionModal"
import { InstitutionServices } from "utils/InstitutionServices"
import { useEffect } from "react"
import CommonLoader from "common/CommonLoader"
import PagesOwnedTabularView from "components/tabularView/PagesOwnedTabularView"
import { useSelector } from "react-redux"

const PagesOwned = (props) => {
    let { practitionerId } = props;
    const [isOpen, setIsOpen] = useState(false)
    const [institutions, setInstitutions] = useState([])
    const toggle = () => setIsOpen(!isOpen);
    const [isLoading, setIsLoading] = useState(true);
    const [viewMode, setViewMode] = useState("card");
    const currentUser = useSelector((state) => state.auth.currentUser);

    const handleAddInstitution = () => {
        setIsOpen(true);
    }

    const getAssociatedInstitutionsForCurrentUser = async (currentUser) => {
        const insts = currentUser.groups;

        // Create an array of promises for each institution ID
        const promises = insts.map(instId => InstitutionServices.getInstitutionById(instId));

        try {
            // Wait for all promises to resolve
            const responses = await Promise.all(promises);

            // Check each response status and collect the data
            const institutions = responses
                .filter(response => response.status === 200) // Filter successful responses
                .map(response => response.data); // Extract data from successful responses

            setInstitutions(institutions);
            setIsLoading(false);
        } catch (error) {
            console.error("Something went wrong", error);
        }
    }


    useEffect(() => {
        getAssociatedInstitutionsForCurrentUser(currentUser);
    }, [])

    if (isLoading) {
        return (
            <div className="loading-spiner text-center  mt-2">
                <CommonLoader style={{ width: "2rem", height: "2rem" }} />
            </div>
        );
    }

    const renderProfiles = () => {
        if (viewMode === 'card') {
            return (
                <>
                    {institutions.map((institution, ind) => {
                        return (
                            <Col lg={12} xs={12} className="mb-3 nexogic-connection-page" key={'institution-list-' + ind}>
                                <Card>
                                    <CardBody className="p-3">
                                        <div className="d-flex align-items-start">
                                            <div className="user-image- section mr-2">
                                                <Link to={`/institution/${institution?.profileId}`} >
                                                    {institution?.avatarId ?
                                                        <img
                                                            src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${institution?.guid}/${institution?.avatarId}`}
                                                            alt="institution profile pic" className="card-img" />
                                                        :
                                                        <img
                                                            src={createImageFromInitials(50, `${institution?.name}`, getRandomColor(encrypt(institution?.guid).toString()))}
                                                            alt="institution profile pic" className="card-img" />
                                                    }
                                                </Link>
                                            </div>
                                            <div className="w-100">
                                                <div className="mb-2 d-flex align-items-start">
                                                    <div className="mr-2">
                                                        <h3 className="card-title"><Link className="text-decoration-none" to={`/institution/${institution?.profileId}`}>{institution?.name}</Link></h3>
                                                        <div className="card-text"> {getFullAddressWithLocationCityAndState(institution?.address)}</div>
                                                    </div>
                                                    <div className="ml-auto ">
                                                        <Link to={`/institution/${institution?.profileId}`}>
                                                            <Button color="primary" className="nexogic_primary_button text-nowrap">Edit page</Button>
                                                        </Link>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        )
                    })}
                </>
            );
        } else { // For table view
            return (
                <Col lg={12} xs={12} className="mb-3 nexogic-connection-page">
                    <PagesOwnedTabularView
                        theader={['Name', 'Type', 'Action']}
                        tdata={institutions}
                        viewmode={viewMode}
                    />
                </Col>
            );
        }
    };

    return (
        <div className="position-relative">
            <div className="view-toggle nexogic-view-toggle">
                <div className="btns">
                    <FontAwesomeIcon title="Card View" color={viewMode === "card" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faAddressCard} onClick={() => setViewMode("card")} /> | <FontAwesomeIcon title="Table View" color={viewMode === "table" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faListAlt} onClick={() => setViewMode("table")} />
                </div>
            </div>

            <Row>
                {renderProfiles(0)}
            </Row>
            {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
                <div className="nex-app-need-pad">
                    <Button color="link" className="nexogic_primary_button_outline nex-app-width-full" onClick={() => handleAddInstitution()}>
                        <FontAwesomeIcon icon={faPlus} className="mr-2" /> Add
                    </Button>
                </div>
            }
            <CreateInstitutionModal
                isOpen={isOpen}
                toggle={toggle}
                practitionerId={practitionerId}
            />
        </div>
    )
}

export default PagesOwned