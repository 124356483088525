import verifyMobileImg from "assets/images/otp-flow/verifyMobile.svg";
import logo from "assets/images/logo-white.png";
import Header from "common/Header";
import { Formik } from "formik";
import { withAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { Col, Container } from "reactstrap";
import { authSetUser } from "redux-helpers/ActionCreator";
import { emailNonNumericRegEx } from "utils/Constants";
import { IdentityService } from "utils/IdentityService";
import { PractitionerService } from "utils/PractitionerService";
import {
  resetAuthSession,
  setLoggedInUserInstitutions,
  setOAuthTokens,
  setOAuthUser,
  setUser
} from "utils/UserHelper";
import * as Yup from "yup";
import LoginForm from "./components/LoginForm";
import { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { InstitutionServices } from "utils/InstitutionServices";
//import "./style.scss";

const validationSchema = Yup.object({
  email: Yup.string()
    .max(100)
    .matches(emailNonNumericRegEx, "Please Enter an valid Email")
    .required("This field is required"),
  password: Yup.string()
    .required("This field is required")
});

const LoginNew = (props) => {
  const dispatch = useDispatch();
  const rememberd = JSON.parse(secureLocalStorage.getItem("nexogic.rem_u_cred"));
  const history = useHistory();
  const initialValues = {
    email: rememberd ? rememberd.email : "",
    password: rememberd ? rememberd.password : "",
    rememberMe: rememberd ? true : false,
  };

  const getAndSetInstitution = () => {
    InstitutionServices.getAssociatedInstitutionsForCurrentUser()
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          // Extracting 'guid' from each object in 'data'
          const guidsArray = data.map((item) => item.guid);
          setLoggedInUserInstitutions(guidsArray); // Assigning to setLoggedInUserInstitutions
        } else {
          console.log("FAILED_TO_FETCH_DATA");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };



  const jobsDetailstUrl = new URLSearchParams(window.location.search).get('jobdetails');
  const onSubmit = (values, { setSubmitting }) => {
    const payload = {
      ...values,
      // rememberMe: false // this rememberMe key defines accessToken life, false = short life
    };
    if (values.rememberMe) {
      onRemeberMe(values);
    } else {
      secureLocalStorage.removeItem("nexogic.rem_u_cred");
    }
    IdentityService.Authentication(payload).then(
      (response) => {
        if (response.status === 200) {
          // Login successfully
          const { accessToken, refreshToken, guid, institutions } = response.data;
          // Step1: get user details(guid, authorities)
          IdentityService.GetUserDetails(accessToken, guid).then((userRes) => {
            if (userRes.status === 200) {
              const { authorities } = userRes.data;
              if (authorities.includes("ADMIN")) {
                setOAuthTokens(accessToken, refreshToken);
                setOAuthUser(userRes.data);
                dispatch(authSetUser(userRes.data));
                setUser(userRes.data);
                setSubmitting(false);
                return;
              }

              if (authorities.includes("INSTITUTION_ADMIN") && authorities.includes("PRACTITIONER")) {
                setOAuthTokens(accessToken, refreshToken);
                getAndSetInstitution();
                // setLoggedInUserInstitutions(institutions);
                PractitionerService.gePractitionersDetails(guid)
                  .then((practResp) => {
                    if (practResp && practResp.data) {
                      const data = practResp.data;
                      setOAuthTokens(accessToken, refreshToken);
                      setOAuthUser(userRes.data);
                      setUser(data);
                      dispatch(authSetUser(data));
                      if (jobsDetailstUrl !== null) {
                        history.push(`/jobs/${jobsDetailstUrl}`)
                      } else
                        history.push(`/home`);
                    }
                    setSubmitting(false);
                  })
                  .catch((error) => {
                    console.log(
                      "PractitionerService.gePractitionersDetails error",
                      error
                    );
                    props.alert.error(error.message);
                    resetAuthSession();
                    setSubmitting(false);
                  });
                return;
              }

              if (authorities.includes("INSTITUTION_ADMIN")) {
                getAndSetInstitution();
                // setLoggedInUserInstitutions(institutions);
                setOAuthTokens(accessToken, refreshToken);
                setOAuthUser(userRes.data);
                dispatch(authSetUser(userRes.data));
                setUser(userRes.data);
                history.push(`/home`);
                setSubmitting(false);
                return;
              }

              if (authorities.includes("PATIENT")) {
                setOAuthTokens(accessToken, refreshToken);
                setOAuthUser(userRes.data);
                dispatch(authSetUser(userRes.data));
                setUser(userRes.data);
                history.push(`/home`);
                setSubmitting(false);
                return;
              }

              setOAuthTokens(accessToken, refreshToken); // remove
              // step2: get practitioner by ID
              PractitionerService.gePractitionersDetails(guid)
                .then((practResp) => {
                  if (practResp && practResp.data) {
                    const data = practResp.data;
                    setOAuthTokens(accessToken, refreshToken);
                    setOAuthUser(userRes.data);
                    setUser(data);
                    dispatch(authSetUser(data));
                    if (jobsDetailstUrl !== null) {
                      history.push(`/jobs/${jobsDetailstUrl}`)
                    } else
                      history.push(`/home`);
                  }
                  setSubmitting(false);
                })
                .catch((error) => {
                  console.log(
                    "PractitionerService.gePractitionersDetails error",
                    error
                  );
                  props.alert.error(error.message);
                  resetAuthSession();
                  setSubmitting(false);
                });
            }
          });
        } else {
          if (response.validationErrors) {
            response.validationErrors.map((err) => {
              props.alert.error(err.message);
              return true;
            });
          } else if (response.message) {
            const { status } = response;
            if (status === 500) {
              props.alert.error("Invalid username and password");
            } else {
              props.alert.error(response.message);
            }
          }
          setSubmitting(false);
        }
      },
      (error) => {
        setSubmitting(false);
        console.log("Login error", error);
        props.alert.error(error.message);
      }
    );
  };

  const onRemeberMe = (values) => {
    const obj = JSON.stringify({
      email: values.email,
      password: values.password,
    });
    secureLocalStorage.setItem("nexogic.rem_u_cred", obj);
  };

  useEffect(() => {
    document.body.classList.add('sign-up-page');
    document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "Sign In";
    return () => {
      document.body.classList.remove('sign-up-page');
      document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "";
    }
  }, [])

  return (
    <section>
      <div className="nexogic-bg-light">
        <Header />
        <main className="theme-body-bg nexogic-dashboard-card">
          <Container className="nexogic-container-gap">
            <div className="nexogic-login-register-card card border-0 bg-transparent">
              <div className="row">
                <Col
                  lg={7}
                  md={6}
                  xs={12}
                  className="nexogic-form-side-content text-center"
                >
                  <div className="nexogic-form-info ">
                    <p className="hide-on-app"><img src={logo} alt="" className="nexogic-filter-white" /></p>
                    <p className="card-text text-white mb-5 hide-on-app">Sign in or create an account</p>
                    {/** 
                  <div className="welcome-text">
                    <p>Welcome to</p>
                    <h3>nexogic</h3>
                  </div>
                  */}
                    <p>
                      <img
                        src={verifyMobileImg}
                        className="img-fluid"
                        alt="nexogic"
                      />
                    </p>
                  </div>
                </Col>
                <Col lg={5} md={6} xs={12} className="nexogic-form-content card-body p-0">
                  <div className="nexogic-form-container">
                    <h3 className="nexogic-form-title hide-on-app">Sign in</h3>
                    <Formik
                      initialValues={initialValues}
                      onSubmit={onSubmit}
                      validationSchema={validationSchema}
                      children={(props) => {
                        return <LoginForm {...props} />;
                      }}
                    />
                    <p className="card-text text-center mt-4 fw-semibold">
                      Don't have an account?{" "}
                      <Link
                        to={`/register`}
                      >
                        Sign Up
                      </Link>{" "}
                    </p>
                  </div>
                </Col>
              </div>
            </div>
          </Container>
        </main>
      </div>
    </section>
  );
};
export default withAlert()(LoginNew);
