import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Row, Modal, ModalHeader, ModalBody } from 'reactstrap';
import facebookLogo from 'assets/images/social-facebook-icon.png';
import twitterLogo from 'assets/images/social-twitter-icon.png';
import instagramLogo from 'assets/images/social-insta-icon.png';
import linkedinLogo from 'assets/images/social-linkedin-icon.png';
import facebookGreyLogo from 'assets/images/social-facebook-grey.png';
import twitterGreyLogo from 'assets/images/social-twitter-grey.png';
import instagramGreyLogo from 'assets/images/social-insta-grey.png';
import linkedinGreyLogo from 'assets/images/social-linkedin-grey.png';
import pencilIcon from 'assets/images/pencil_icon.png';
import edit_blue_icon from "assets/images/edit-blue-icon.png";
import RenderSocialMedia from './RenderSocialMedia';
import { useSelector } from 'react-redux';

const SocialMedia = (props) => {
    const { isEditable, isAdmin, institution, getInstitutionProfile } = props
    const [modalisOpen, setmodalIsOpen] = useState(false)
    const modalToggle = () => setmodalIsOpen(!modalisOpen);

    const currentUser = useSelector(state => state.auth.currentUser)

    const getSocialName = (socialContacts) => {
        let linkedinHandle = socialContacts?.find(o => o.type === "LINKEDIN")?.value;
        let facebookHandle = socialContacts?.find(o => o.type === "FACEBOOK")?.value;
        let twitterHandle = socialContacts?.find(o => o.type === "TWITTER")?.value;
        let instaHandle = socialContacts?.find(o => o.type === "INSTAGRAM")?.value;
        return { facebookHandle, twitterHandle, linkedinHandle, instaHandle }
    }

    const initialValues = getSocialName(institution?.socialContacts);
    const [socialHandlers, setSocialHandlers] = useState(initialValues)

    useEffect(() => {
      if(institution?.guid)
        setSocialHandlers(getSocialName(institution?.socialContacts))
    }, [institution,getInstitutionProfile])

    return (
      <>
        {((institution?.socialContacts.length > 0 && !isAdmin) || isAdmin) && (
          <Card className="nexogic-social-card" id="socialSection">
            
            <CardBody>
              
                
                  <h5 className="card-title pl-0 pt-0">Social Connect
                  {(isEditable || isAdmin) && (
                  
                    <Button
                    id="nex-ip-social-section-edit-btn"
                      color="link"
                      className="card-edit-btn-round"
                      onClick={modalToggle}
                    >
                      <img src={edit_blue_icon} alt="" />
                    </Button>
                  
                )}
                  </h5>
                  <div className="social-links">
                      {
                        socialHandlers.instaHandle ? (
                          <a href={socialHandlers.instaHandle} target="_blank">
                            <img
                              src={instagramLogo}
                              alt={"insta logo"}
                              width="24"
                            />
                            {/* <FontAwesomeIcon icon={faInstagram} size="2x" color="#3EABCA" /> */}
                          </a>
                        ) : (
                          <img
                            className='inactive'
                            src={instagramLogo}
                            alt={"insta logo"}
                            width="24"
                          />
                        )
                        // <FontAwesomeIcon icon={faInstagram} size="2x" color="rgba(0, 18, 68, 0.6)" />
                      }
                    
                    
                      {
                        socialHandlers.linkedinHandle ? (
                          <a
                            href={socialHandlers.linkedinHandle}
                            target="_blank"
                          >
                            <img
                              src={linkedinLogo}
                              alt={"linkedIn logo"}
                              width="24"
                            />
                            {/* <FontAwesomeIcon icon={faLinkedin} size="2x" color="#3EABCA" /> */}
                          </a>
                        ) : (
                          <img
                            className='inactive'
                            src={linkedinLogo}
                            alt={"linkedIn logo"}
                            width="24"
                          />
                        )
                        // <FontAwesomeIcon icon={faLinkedin} size="2x" color="rgba(0, 18, 68, 0.6)" />
                      }
                    
                    
                      {
                        socialHandlers.facebookHandle ? (
                          <a
                            href={socialHandlers.facebookHandle}
                            target="_blank"
                          >
                            <img
                              src={facebookLogo}
                              alt={"fb logo"}
                              width="24"
                            />
                            {/* <FontAwesomeIcon icon={faFacebook} size="2x" color="#3EABCA" /> */}
                          </a>
                        ) : (
                          <img
                            className='inactive'
                            src={facebookLogo}
                            alt={"fb logo"}
                            width="24"
                          />
                        )
                        // <FontAwesomeIcon icon={faFacebook} size="2x" color="rgba(0, 18, 68, 0.6)" />
                      }
                    
                    
                      {
                        socialHandlers.twitterHandle ? (
                          <a
                            href={socialHandlers.twitterHandle}
                            target="_blank"
                          >
                            <img
                              src={twitterLogo}
                              alt={"twitter logo"}
                              width="24"
                            />
                            {/* <FontAwesomeIcon icon={faTwitter} size="2x" color="#3EABCA" /> */}
                          </a>
                        ) : (
                          <img
                            className='inactive'
                            src={twitterLogo}
                            alt={"twitter logo"}
                            width="24"
                          />
                        )
                        // <FontAwesomeIcon icon={faTwitter} size="2x" color="rgba(0, 18, 68, 0.6)" />
                      }
                    
                  </div>
                
                
              
            </CardBody>
          </Card>
        )}

        <Modal backdrop="static" isOpen={modalisOpen} toggle={modalToggle}>
          <ModalHeader toggle={modalToggle}>Social</ModalHeader>
          <ModalBody>
            <RenderSocialMedia
              toggle={modalToggle}
              isEditable={isEditable}
              isAdmin={isAdmin}
              institution={institution}
              data={socialHandlers}
              practitionerId={currentUser?.guid}
              institutionId={institution?.guid}
              getInstitutionProfile={getInstitutionProfile}
            />
          </ModalBody>
        </Modal>
      </>
    );

}

export default SocialMedia;