import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import RefaralAsideFilter from "../RefaralAsideFilter";
import { ReferralService } from "utils/ReferralService";
import { FAILED_TO_FETCH_DATA, FAILTOSAVERECOED } from "utils/Constants";
import CommonLoader from "common/CommonLoader";
import Referral from "../Referral";
import notfoundImg from 'assets/images/not-found-img.png'
import { MultiSelect } from "react-multi-select-component";
import { isInstitutionAdmin, isPractitioner } from "utils/UserHelper";
import { InstitutionServices } from "utils/InstitutionServices";
import { getFullAddress } from "utils/Utils";
import filter_icon from "assets/images/svgs/filter-blue-icon.svg";
import LoadMoreButton from "common/LoadMoreButton/LoadMoreButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faIdCard, faListAlt, faTable } from "@fortawesome/free-solid-svg-icons";
import ReferralsTabularView from "components/tabularView/ReferralsTabularView";
import { useSelector } from "react-redux";

const ReceivedReferals = (props) => {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState("");
    const [referrals, setReferrals] = useState([]);
    const [query, setQuery] = useState("");
    const [pageNo, setPageNo] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [associatedInstitutions, setAssociatedInstitutions] = useState([]);
    const [insSelected, setInsSelected] = useState(associatedInstitutions);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [viewMode, setViewMode] = useState("card");
    const currentUser = useSelector((state) => state.auth.currentUser);

    const getReceivedReferrals = (pageNo) => {
        let instIds = "";
        instIds = insSelected.map(item => item.value);
        const commaSeparatedString = instIds.join(',');

        // console.log(insSelected);
        // console.log(commaSeparatedString);

        return ReferralService.getReferrals("RECEIVED", query, pageNo, commaSeparatedString)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    if (data?.currentPage < (data?.totalPages - 1))
                        setHasMore(true);
                    else
                        setHasMore(false);
                    return data;
                } else {
                    console.log(FAILTOSAVERECOED);
                    return null;
                }
            })
            .catch((error) => {
                console.error("Error retrieving referrals:", error);
                return null;
            });
    };


    // Function to load more referrals on scroll
    const fetchMoreReferrals = () => {
        setIsLoadingMore(true);
        getReceivedReferrals(pageNo)
            .then((data) => {
                if (data && data.contents.length > 0) {
                    setReferrals((prevReferrals) => [...prevReferrals, ...data.contents]);
                    setPageNo(pageNo + 1);
                    setIsLoadingMore(false);
                }
            })
            .catch((error) => {
                console.error("Error fetching more referrals:", error);
                setIsLoadingMore(false);
            });
    };


    const getAssociatedInstitutions = () => {
        const insts = currentUser.groups;

        // Create an array of promises for each institution ID
        const promises = insts.map(instId => InstitutionServices.getInstitutionById(instId));

        // Use Promise.all to wait for all promises to resolve
        Promise.all(promises)
            .then((responses) => {
                // Filter successful responses
                const successfulResponses = responses.filter(response => response.status === 200);

                if (successfulResponses.length > 0) {
                    // Map successful responses to the required format
                    let inst = successfulResponses.map(response => {
                        const institution = response.data;
                        return {
                            value: institution.guid,
                            label: getFullAddress(institution)
                        };
                    });
                    setAssociatedInstitutions(inst);
                    setInsSelected(inst);
                } else {
                    console.log("No successful institution data found");
                }
            })
            .catch((err) => {
                console.log("Something went wrong", err);
            });
    };

    useEffect(() => {
        setIsLoading(true);
        // Fetch initial referrals when component mounts
        const fetchReferrals = async () => {
            const data = await getReceivedReferrals(0);
            console.log(data);
            if (data && data.contents.length > 0) {
                setReferrals(data.contents);
                setPageNo(pageNo + 1);
                setIsLoading(false);
            } else {
                setReferrals([]);
                setIsLoading(false);
            }
        };

        fetchReferrals();
    }, [query, insSelected]);


    useEffect(() => {
        if (location.pathname) {
            setActiveTab(location.pathname)
        }
        if (isInstitutionAdmin() && isPractitioner()) {
            getAssociatedInstitutions();
        }
    }, [location, query]);

    const customStrings = {
        selectSomeItems: "Select Institution(s)", // Customize this message
        allItemsAreSelected: "All Institutions are selected", // Customize this message
        selectAll: "Select All",
        search: "Search here",
    };

    const [isFilterOn, setIsFilterOn] = useState(false);


    const renderReferrals = () => {
        if (viewMode === 'card') {
            return (
                <>
                    {referrals.map((referral, index) => (
                        <Referral referral={referral} type={"RECEIVED"} key={`${referral?.id}-${index}`} />
                    ))}
                </>
            );
        } else { // For table view
            return (
                <ReferralsTabularView theader={['Case Id', 'Patient Name', 'Speciality', 'Referring Physician', 'Status']} tdata={referrals} viewmode={viewMode} type={"RECEIVED"} />
            );
        }
    };

    return (
        <>

            <Card className="bg-transparent border-0" id="nex-received-referals-page-w">
                <CardBody className="p-0">
                    <Row className="mb-5 nexogic-sidebar-cont-wrap">
                        <Col lg={3} md={4} sm={12} className="nexogic-sidebar-col- mb-3 nexogic-referrals-aside nex-mob-ref-sticky">
                            <div className="w-100 nexogic-sidebar-stiky nex-mob-fooler-sticky-actions">
                                <Card className="nexogic-filter-btn-card mb-3">
                                    <div className="btn-groups">
                                        <Link to="/referrals/add-patient" id="nex-rr-referals-add-patient-link"><Button outline className="nexogic_primary_button_outline">Add a Patient</Button></Link>
                                        <Link to="/referrals/refer-patient" id="nex-rr-referals-refer-patient-link"><Button outline className="nexogic_primary_button">Refer a Patient</Button></Link>
                                    </div>
                                </Card>

                                <RefaralAsideFilter calledBy={"ReceivedReferrals"} setQuery={setQuery} insSelected={insSelected} />
                            </div>
                        </Col>
                        <Col lg={9} md={8} sm={12} className="nexogic-content-col nexogic-refaral-section-wrap" id="nex-my-received-ref-page-cont">
                            <div className="view-toggle nexogic-view-toggle nex-refaral-view-toggle">
                                <div className="btns">
                                    <FontAwesomeIcon title="Card View" color={viewMode === "card" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faAddressCard} onClick={() => setViewMode("card")} /> | <FontAwesomeIcon title="Table View" color={viewMode === "table" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faListAlt} onClick={() => setViewMode("table")} />
                                </div>
                            </div>
                            {props.isTitle &&
                                <h1 className="nexogic-page-title nex-mob-box-title nex-filter-drop-w">My Received Referrals
                                    {(isInstitutionAdmin() && isPractitioner() && associatedInstitutions.length > 0) &&
                                        <span className="nex-filter-drop-btn" onClick={() => setIsFilterOn(!isFilterOn)}><img src={filter_icon} /></span>
                                    }
                                </h1>
                            }
                            {(isInstitutionAdmin() && isPractitioner() && associatedInstitutions.length > 0) &&
                                <>{isFilterOn &&
                                    <Card className="nex-card-top-filter">
                                        <CardBody className="nex-filter-col">

                                            <div className="action-group">
                                                <span className="card-text">Your Institutions</span>
                                                <div className="nex-filter-select">
                                                    <MultiSelect
                                                        options={associatedInstitutions}
                                                        value={insSelected}
                                                        onChange={(value) => setInsSelected(value)}
                                                        labelledBy="Select"
                                                        overrideStrings={customStrings}
                                                    />
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                }
                                </>
                            }
                            {/* I remove height and targetscroll as per below links
                            https://www.npmjs.com/package/react-infinite-scroll-component
                            */}
                            {
                                isLoading ?
                                    <div className="loading-spiner text-center  mt-2">
                                        <CommonLoader />
                                    </div>
                                    :
                                    <div>
                                        {referrals && referrals.length > 0 ?
                                            <>
                                                {renderReferrals()}
                                            </>
                                            :
                                            <div className="nexogic-not-found-card">
                                                <div className="nexogic-not-found-img-w">
                                                    <img src={notfoundImg} alt="" />
                                                </div>
                                                <h2 className="card-title">Referral Not Found</h2>
                                            </div>
                                        }
                                        {hasMore &&
                                            <div className="text-center mt-3">
                                                {isLoadingMore
                                                    ?
                                                    <CommonLoader style={{ width: "2rem", height: "2rem" }} />
                                                    :
                                                    <LoadMoreButton loadMore={fetchMoreReferrals} />
                                                }
                                            </div>
                                        }
                                    </div>
                            }
                        </Col>
                    </Row>

                </CardBody>
            </Card>
        </>
    )

}
ReceivedReferals.defaultProps = {
    isTitle: true
}
export default ReceivedReferals;
