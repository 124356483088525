import { Card } from "reactstrap";
import { encrypt } from "utils/EncryptDecrypt";
import { createImageFromInitials, getFullAddressWithLocationCityAndState, getRandomColor, getGenderDetails } from "utils/Utils";
import { Link, useRouteMatch } from "react-router-dom";
import RefaralComment from "./AddPatient/Components/RefaralComment";
import RefaralDocument from "./AddPatient/Components/RefaralDocument";
import response_icon from 'assets/images/svgs/response-icon.svg'
import { useEffect } from "react";
import { useState } from "react";



// import { useEffect } from "react";
// import edit_blue_icon from "assets/images/edit-blue-icon.png";

const Referral = (props) => {
    const { referral, type } = props;
    const [referral_ismounted, setReferral_ismounted] = useState(false);

    let match = useRouteMatch("/referals/received-referrals");
    const capitalizeFirstLetter = (string) => {
        if (string === null) {
            return null;
        } else if (string === undefined) {
            return "";
        } else {
            const firstLetter = string[0].toUpperCase();
            const restOfString = string.slice(1);
            return firstLetter + restOfString;
        }
    };

    useEffect(() => {
        setReferral_ismounted(true);
        return () => {
            setReferral_ismounted(false);
        }
    }, [referral.id])


    return (
        <Card className="nexogic-card-list-custom nexogic-reffer-card">
            <div className="card-body">
                <div className="row need-margin">
                    <div className="col-lg-12 col-status nexogic-status-right">

                        {/* {referral.status.toLowerCase() === 'draft' && (
                      <Link to={`/referrals/update/${referral?.referralCaseNo}`}>
                        <span className="status-pill cursor-pointer">
                                <img src={edit_blue_icon} alt="" width="18" className="mx-2"/>
                            </span>
                      </Link>
                    )} */}


                        {/* {referral.status.toLowerCase() === 'draft' ?
                            <span className="status-pill cursor-pointer">
                                <img src={edit_blue_icon} alt="" width="18" className="mx-2"/>
                            </span>
                            :
                            ''
                        } */}

                        <span className={`status-pill ${referral.status.toLowerCase() === 'closed' ? 'accepted ' : ''}            
                        ${referral.status.toLowerCase() === 'submitted' ? 'accepted ' : ''}`}>
                            {referral?.status
                                .toLowerCase()
                                .split("_")
                                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(" ")
                            }
                        </span>
                    </div>

                    {type === "RECEIVED" &&
                        <div className="col Referring-physician-col">
                            <h3 className=" card-title big"> Referring Physician</h3>
                            <div className="head">
                                {
                                    referral?.referredByPhysician?.avatarId ?
                                        <img
                                            src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.referredByPhysician?.profileGuid}/${referral?.referredByPhysician?.avatarId}`}
                                            className="card-img nex-avatar"
                                            alt="#"
                                        />
                                        :
                                        <>
                                            <img
                                                src={createImageFromInitials(
                                                    50,
                                                    `${referral?.referredByPhysician?.name.replace("Dr. ", "")}`,
                                                    getRandomColor(
                                                        encrypt(
                                                            referral?.referredByPhysician?.profileGuid
                                                        ).toString()
                                                    )
                                                )}
                                                className="card-img nex-avatar"
                                                alt="#"
                                            />
                                        </>
                                }
                                <div className="info">
                                    <p className="card-text nex-ref-physician-name"><b>Name  </b>  <Link to={`/profile/` + referral?.referredByPhysician?.profileId} >{referral?.referredByPhysician?.name}</Link></p>
                                    <p className="card-text"><b>Address  </b>
                                        {getFullAddressWithLocationCityAndState({ location: referral?.referredByPhysician?.location ?? "", city: referral?.referredByPhysician?.city ?? "", state: referral?.referredByPhysician?.state ?? "" })}
                                    </p>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="col patient-col-info">
                        <h3 className=" card-title big"> Patient</h3>
                        <div className="head">
                            {
                                referral?.patient?.avatarId ?
                                    <img
                                        src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.patient?.id}/${referral?.patient?.avatarId}`}
                                        className="card-img nex-avatar"
                                        alt="#"
                                    />
                                    :
                                    <>
                                        <img
                                            src={createImageFromInitials(
                                                50,
                                                `${(referral?.patient?.firstName + " " + referral?.patient?.lastName)}`,
                                                getRandomColor(
                                                    encrypt(
                                                        referral?.patient?.id
                                                    ).toString()
                                                )
                                            )}
                                            className="card-img nex-avatar"
                                            alt="#"
                                        />
                                    </>
                            }
                            <div className="info">
                                <p className="card-text nex-patient-name"><b>Name  </b>  <span><Link to={`/patient-profile/${referral?.patient?.id}`}>{referral?.patient?.firstName}{" "}{referral?.patient?.lastName}</Link></span></p>
                                <p className="card-text"><b>DOB  </b>{referral?.patient?.dateOfBirth ? referral?.patient?.dateOfBirth : 'NA'}</p>
                                <p className="card-text"><b>Gender  </b> {getGenderDetails(referral?.patient?.gender)}</p>
                                <p className="card-text"><b>Type  </b> {capitalizeFirstLetter(referral?.patientType.toLowerCase())}</p>
                            </div>
                        </div>
                    </div>
                    {type === "SENT" &&
                        <div className="col Referring-to-col">
                            <h3 className=" card-title big"> Receiving Physician </h3>
                            <div className="head">
                                <div className="card-graphic">
                                    {
                                        referral?.referredToPhysician?.avatarId ?
                                            <img
                                                src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${referral?.referredToPhysician?.profileGuid}/${referral?.referredToPhysician?.avatarId}`}
                                                className="card-img"
                                                alt="#"
                                            />
                                            :
                                            <>
                                                <img src={createImageFromInitials(50, `${referral?.referredToPhysician?.name.replace("Dr. ", "")}`, getRandomColor(encrypt(referral?.referredToPhysician?.profileGuid).toString()))}
                                                    className="card-img"
                                                    alt="#"
                                                />
                                            </>
                                    }

                                </div>
                                <div className="info">
                                    <p className="card-text"><b>Name  </b>
                                        {(referral?.referredToPhysician && referral?.referredToPhysician?.name) ?
                                            <Link to={`/profile/` + referral?.referredToPhysician?.profileId} >{referral?.referredToPhysician?.name}</Link>
                                            :
                                            <span>N/A</span>
                                        }
                                    </p>
                                    <p className="card-text"><b>Address  </b>
                                        {referral?.referredToPhysician ?
                                            <>
                                                {getFullAddressWithLocationCityAndState({ location: referral?.referredToPhysician?.location ?? "", city: referral?.referredToPhysician?.city ?? "", state: referral?.referredToPhysician?.state ?? "" })}
                                            </>
                                            :
                                            <span>N/A</span>
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                    {/* <div className="col-auto col-status">
                        <span className={`status-pill 
                        ${referral.status.toLowerCase() === 'closed' ? 'accepted ' : ''} 
                        ${referral.status.toLowerCase() === 'submitted' ? 'accepted ' : ''} 
                        `}>
                            {referral?.status
                                .toLowerCase()
                                .split("_")
                                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(" ")
                            }
                        </span>
                    </div> */}
                </div>
                <div className="row need-margin reason-sec">
                    <div className="col-lg-12">
                        {referral?.referralReason &&
                            <>
                                <hr />
                                <h3 className="card-title"> Reason:</h3>
                                <div className="card-text">
                                    <div dangerouslySetInnerHTML={{ __html: referral?.referralReason }}></div>
                                </div>
                            </>
                        }
                        {/* link class : nexogic_link */}
                        {type === "RECEIVED" ? (
                            <div className="card-text d-flex actions">
                                <div className="ml-auto mt-2">
                                    <Link
                                        to={{
                                            pathname: `${match?.url !== undefined ? match?.url : '/referrals/received-referrals'}/` + referral?.id,
                                            state: { type }, // Pass the 'type' as state
                                        }}
                                        className="nexogic_primary_button_outline "
                                    >
                                        Show Details
                                    </Link>
                                </div>
                            </div>
                        ) : (
                            <div className="card-text d-flex actions">
                                <div className="ml-auto mt-2">
                                    <Link
                                        to={{
                                            pathname: `${match?.url !== undefined ? match?.url : '/referrals/sent-referrals'}/` + referral?.id,
                                            state: { type }, // Pass the 'type' as state
                                        }}
                                        className="nexogic_primary_button_outline "
                                    >
                                        Show Details
                                    </Link>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
                {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
                    <div className="nexogic-meta-description">
                        <div className="row need-margin">
                            <div className={`col ${referral?.dateOfReferal ? '' : 'order-3 d-none'}`}><p className={`card-text ${referral?.dateOfReferal ? '' : 'd-none'}`}><span className="icon"><svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 8V17C1 19.2091 2.79086 21 5 21H15C17.2091 21 19 19.2091 19 17V8M1 8V6.5C1 4.29086 2.79086 2.5 5 2.5H15C17.2091 2.5 19 4.29086 19 6.5V8M1 8H19M14 1V4M6 1V4" stroke="#5EA9C6" strokeWidth="1.5" strokeLinecap="round"></path></svg></span>  <b>Date of refferal  </b>  {referral?.dateOfReferal}</p></div>
                            {type === "RECEIVED" &&
                                <div className="col">
                                    {referral && referral?.responseId ?
                                        <a href={`/referrals/sent-response/${referral?.id}`} className="nex-link no-underline"> <span className="icon"><img width={28} src={response_icon} />
                                        </span>View Response
                                        </a>
                                        :
                                        <span className="nex-link no-underline">
                                            <span className="icon" ><img width={28} src={response_icon} />
                                            </span>

                                            No Response
                                        </span>
                                    }
                                </div>
                            }

                            <div className="col"><RefaralComment id={'ref_connemt_' + referral.id} /></div>
                            <div className="col-auto ml-auto"><RefaralDocument id={'ref_document_' + referral.id} referral={referral} /></div>
                        </div>
                    </div>
                }
            </div>
        </Card>
    )
}

export default Referral;