import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import AlertDialog from "common/AlertDialog";
import CommonLoader from "common/CommonLoader";
import NoJobs from "pages/Jobs/NoJobs";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faIdCard, faListAlt, faTable } from "@fortawesome/free-solid-svg-icons";
import { withAlert } from "react-alert";
import { Card, CardBody } from "reactstrap";
import { FAILTOSAVERECOED } from "utils/Constants";
import { TalentService } from "utils/TalentService";
import JobListSingle from "../JobListSingle";
import LoadMoreButton from "common/LoadMoreButton/LoadMoreButton";
import PostedJobsTabularView from "components/tabularView/PostedJobsTabularView";

const JobListClosed = forwardRef((props, ref) => {
  const { alert } = props;

  const [list_of_data, setList_of_data] = useState(null);
  const [jobIdToDelete, setJobIdToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenAlert, setAlertModal] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [viewMode, setViewMode] = useState("card");

  const getData = async (pageNo, feedlist) => {
    const request = TalentService.listMyJobs(pageNo, "CLOSED");
    request
      .then(async (response) => {
        let { data } = response;
        if (data?.currentPage < (data?.totalPages - 1))
          setHasMore(true);
        else
          setHasMore(false);
        setIsLoadingMore(false);
        if (pageNo === 0) {
          const allData = { ...data };
          const resolved = await Promise.all(
            allData.contents.map(async (item) => {
              const each = { ...item };
              return each;
            })
          );
          allData.contents = resolved;
          setList_of_data(allData);
          setIsLoading(false);
        } else {
          const resolved = await Promise.all(
            data.contents.map(async (item) => {
              const each = { ...item };
              return each;
            })
          );
          feedlist = feedlist.concat(resolved);
          setList_of_data({ ...data, contents: feedlist });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("Error while getting job list: ", error);
        setIsLoadingMore(false);
      });
  };

  const fetchData = () => {
    setIsLoadingMore(true);
    getData(list_of_data.currentPage + 1, list_of_data.contents);
  };

  const toggleAlert = () => {
    setAlertModal(!isOpenAlert);
  };

  useImperativeHandle(ref, () => ({
    getJobListData() {
      getData(0, []);
    },
  }));

  const handleClickOnYes = () => {
    TalentService.deleteJob(jobIdToDelete)
      .then((response) => {
        const { status } = response;
        if (status === 204) {
          showSuccessMessage("Jobs has been deleted Successfully...");
          getData(0, []);
        }
        else {
          showErrorMessage(FAILTOSAVERECOED);
        }
      })
    toggleAlert();
  }

  useEffect(() => {
    getData(0, []);
  }, []);

  if (isLoading) {
    return (
      <div className="loading-spiner text-center  mt-2">
        <CommonLoader style={{ width: "2rem", height: "2rem" }} />
      </div>
    );
  }

  const renderProfiles = () => {
    if (viewMode === 'card') {
      return (
        <>
          {list_of_data.contents.map((e, idx) => (
            <JobListSingle key={idx} jobStatus="Closed" job={e} toggleAlert={toggleAlert} setJobIdToDelete={setJobIdToDelete} />
          ))}
        </>
      );
    } else { // For table view
      return (
        <PostedJobsTabularView theader={['Job Title', 'Institution Name', 'Speciality', 'Job Location']} tdata={list_of_data.contents} viewmode={viewMode} />
      );
    }
  };

  return (
    <>
      <div className="view-toggle nexogic-view-toggle mb-2">
        <div className="btns">
          <FontAwesomeIcon title="Card View" color={viewMode === "card" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faAddressCard} onClick={() => setViewMode("card")} /> | <FontAwesomeIcon title="Table View" color={viewMode === "table" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faListAlt} onClick={() => setViewMode("table")} />
        </div>
      </div>
      <Card className="bg-transparent border-0">
        <CardBody className="p-0">
          <div>
            {(list_of_data.contents && list_of_data.contents.length > 0) ?
              <>

                {renderProfiles()}
                {hasMore &&
                  <div className="text-center mt-3">
                    {isLoadingMore
                      ?
                      <CommonLoader style={{ width: "2rem", height: "2rem" }} />
                      :
                      <LoadMoreButton loadMore={fetchData} />
                    }
                  </div>
                }
              </>
              :
              <NoJobs content={"No Draft Job"} displayPostJob={true} />
            }
          </div>
        </CardBody>
      </Card>
      <AlertDialog
        bodyText={"Are you sure, You want to delete this job?"}
        toggle={toggleAlert}
        isOpen={isOpenAlert}
        handleClickOnYes={handleClickOnYes}
        yesLabel={"Yes"}
        noLabel={"No"}
      />
    </>
  );
});

export default withAlert()(JobListClosed);
