import React from "react";
import { useState } from "react";
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, FormGroup, Input, ListGroup, ListGroupItem, Row } from "reactstrap";
import symtoms_icon from 'assets/images/symtoms-icon.png'
import allergies_icon from 'assets/images/allergies-icon.png'
import surgeries_icon from 'assets/images/surgeries-icon.png'
import SymptomsModalForm from "./SymptomsModalForm";
import AllergiesModalForm from "./AllergiesModalForm";
import SurgeriesModalForm from "./SurgeriesModalForm";
import { useEffect } from "react";
import { OpinionService } from "utils/OpinionService";
import { FAILTOSAVERECOED } from "utils/Constants";
import { withAlert } from "react-alert";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const MedicalHistoryForm1 = (props) => {
    const { opinionInfo, setOpinionInfo, getOpinionInfo } = props;
    const [symptomsModal, setSymptomsModal] = useState(false);
    const [allergiesModal, setAllergiesModal] = useState(false);
    const [surgeriesModal, setSurgeriesModal] = useState(false);

    const [symptoms_selected, setSymptoms_selected] = useState([]);
    const [allergies_selected, setAllergies_selected] = useState([]);
    const [surgeries_selected, setSurgeries_selected] = useState([]);

    useEffect(() => {
        if (opinionInfo && opinionInfo?.symptoms && opinionInfo?.symptoms.length > 0) {
            let symptoms_selected1 = opinionInfo?.symptoms?.split(", ").map(item => ({ label: item, value: item }));
            setSymptoms_selected(symptoms_selected1);
        }

        if (opinionInfo && opinionInfo?.allergies && opinionInfo?.allergies.length > 0) {
            let allergies_selected1 = opinionInfo?.allergies?.split(", ").map(item => ({ label: item, value: item }));
            setAllergies_selected(allergies_selected1);
        }

        if (opinionInfo && opinionInfo?.surgeries && opinionInfo?.surgeries.length > 0) {
            let allergies_selected1 = opinionInfo?.surgeries?.split(", ").map(item => ({ label: item, value: item }));
            setSurgeries_selected(allergies_selected1);
        }
    }, [opinionInfo?.id])


    const handleSubmit = () => {
        let symptoms_selected1 = symptoms_selected.map(option => option.value);
        let allergies_selected1 = allergies_selected.map(option => option.value);
        let surgeries_selected1 = surgeries_selected.map(option => option.value);

        // console.log("symptoms_selected", symptoms_selected1);
        // console.log("allergies_selected", allergies_selected1);
        // console.log("surgeries_selected", surgeries_selected1);

        let payload = {
            "speciality": opinionInfo.speciality,
            "title": opinionInfo.title,
            "status": opinionInfo.status,
            "requestedBy": opinionInfo.requestedBy,
            "requestedFor": opinionInfo.requestedFor,
            "symptoms": symptoms_selected1.join(', '),
            "allergies": allergies_selected1.join(', '),
            "surgeries": surgeries_selected1.join(', '),
        }
        // console.log(payload);

        OpinionService.updateOpinion(opinionInfo?.id, payload)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    showSuccessMessage("Opinion updated successfully.");
                    setOpinionInfo(data);
                    props.setCurrentStep('2')
                }
                else {
                    console.log(FAILTOSAVERECOED);
                }
            })
    }

    return (
        <>
            <CardBody className={"nex-form-step active"}>
                <br />

                <div className="nex-form-field-container">
                    <div className="nex-form-group nex-content-center">
                        <div className="nex-form-group-c">
                            <label htmlFor="" className="nex-big-label mb-3">Please specify your medical Symptoms, Allergies, <br />
                                Surgeries which are applicable ? </label>
                            <div className="nex-symptoms-icon-box">
                                <ButtonGroup className="nex-radio-card">
                                    <Button
                                        onClick={() => setSymptomsModal(!symptomsModal)}
                                        color="primary"
                                        outline
                                        className={`nex-radio-card-item ${symptoms_selected.length > 0 ? 'active' : ''}`}
                                    >
                                        <div className="number">1</div>
                                        <div className="graphic">
                                            <img src={symtoms_icon} alt="" />
                                        </div>
                                        <div className="label">Symptoms</div>
                                        <div className="check">
                                            <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14 1L5.0625 10L1 5.90909" stroke="#2D4552" strokeOpacity="0.27" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>

                                        </div>

                                    </Button>
                                    <Button
                                        color="primary"
                                        outline
                                        className={`nex-radio-card-item ${allergies_selected.length > 0 ? 'active' : ''}`}
                                        onClick={() => setAllergiesModal(!allergiesModal)}
                                    >
                                        <div className="number">2</div>
                                        <div className="graphic"><img src={allergies_icon} alt="" /></div>
                                        <div className="label">Allergies</div>
                                        <div className="check">
                                            <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14 1L5.0625 10L1 5.90909" stroke="#2D4552" strokeOpacity="0.27" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>

                                        </div>

                                    </Button>
                                    <Button
                                        color="primary"
                                        outline
                                        className={`nex-radio-card-item ${surgeries_selected.length > 0 ? 'active' : ''}`}
                                        onClick={() => setSurgeriesModal(!surgeriesModal)}
                                    >
                                        <div className="number">3</div>
                                        <div className="graphic"><img src={surgeries_icon} alt="" /></div>
                                        <div className="label">Surgeries</div>
                                        <div className="check">
                                            <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M14 1L5.0625 10L1 5.90909" stroke="#2D4552" strokeOpacity="0.27" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>

                                        </div>

                                    </Button>

                                </ButtonGroup>
                                {/* modals */}

                                <SymptomsModalForm
                                    modal={symptomsModal}
                                    toggle={() => setSymptomsModal(!symptomsModal)}
                                    symptoms_selected={symptoms_selected}
                                    setSymptoms_selected={setSymptoms_selected}
                                />
                                <AllergiesModalForm
                                    modal={allergiesModal}
                                    toggle={() => setAllergiesModal(!allergiesModal)}
                                    allergies_selected={allergies_selected}
                                    setAllergies_selected={setAllergies_selected}
                                />
                                <SurgeriesModalForm
                                    modal={surgeriesModal}
                                    toggle={() => setSurgeriesModal(!surgeriesModal)}
                                    surgeries_selected={surgeries_selected}
                                    setSurgeries_selected={setSurgeries_selected}
                                />

                                <div className="nex-modal-actions mt-3 mb-2 d-flex justify-content-end">
                                    <a href="#" className="nexogic_primary_button_outline"
                                        onClick={(e) => { props.setCurrentStepInMainForm('1') }}
                                    >Back</a>
                                    <a href="#" className="nexogic_primary_button"
                                        onClick={(e) => { handleSubmit() }}
                                    >Next</a>
                                </div>

                                <div className="nex-tips-box-card mt-10">
                                    <div className="graphic">
                                        <div className="icon"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1 10H2M10 1V2M18 10H19M3.6 3.6L4.3 4.3M16.4 3.6L15.7 4.3M7.7 15H12.3M7 14C6.16047 13.3704 5.54033 12.4925 5.22743 11.4908C4.91453 10.4892 4.92473 9.41442 5.25658 8.41886C5.58844 7.4233 6.22512 6.55739 7.07645 5.94379C7.92778 5.33019 8.95059 5 10 5C11.0494 5 12.0722 5.33019 12.9236 5.94379C13.7749 6.55739 14.4116 7.4233 14.7434 8.41886C15.0753 9.41442 15.0855 10.4892 14.7726 11.4908C14.4597 12.4925 13.8395 13.3704 13 14C12.6096 14.3865 12.3156 14.8594 12.1419 15.3806C11.9681 15.9018 11.9195 16.4566 12 17C12 17.5304 11.7893 18.0391 11.4142 18.4142C11.0391 18.7893 10.5304 19 10 19C9.46957 19 8.96086 18.7893 8.58579 18.4142C8.21071 18.0391 8 17.5304 8 17C8.08046 16.4566 8.03185 15.9018 7.85813 15.3806C7.6844 14.8594 7.39043 14.3865 7 14Z" stroke="#3EABCA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                        </div>
                                        <div className="title">Tips</div>
                                    </div>
                                    <div className="info">Select the above  options.  Multi select Options to select symptoms and
                                        other options from the list.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CardBody>
        </>
    )
}
export default withAlert()(MedicalHistoryForm1);