import CollapseablePannel from 'common/CollapseablePannel'
import React,{ useState }  from 'react'
import { withAlert } from 'react-alert';
import ReactDOMServer from 'react-dom/server';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import ThemeOne from 'pages/UIDashboard/Components/CustomThemeDesign/ThemeOne';
import ThemeTwo from 'pages/UIDashboard/Components/CustomThemeDesign/ThemeTwo';
import ThemeThree from 'pages/UIDashboard/Components/CustomThemeDesign/ThemeThree';
import Swal from 'sweetalert2';
import { InstitutionServices } from 'utils/InstitutionServices';
import PreviewWindow from 'pages/UIDashboard/Components/CustomThemeDesign/PreviewWindow';
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const ConfigurePage = (props) => {
    const {institution,instituteThemeDetails,otherBranches, practitioners,awards,currentUserId} = props;
    const history = useHistory();
    const [Loader, setLoader] = useState(false);
    const [modal, setModal] = useState(false);
    const toggle = () => {
        setModal(!modal);
    };

    const passToPage = {
        institution: institution,
        currentUserId: currentUserId,
        otherBranches: otherBranches,
        awards:awards,
        practitioners: practitioners,
        eThemeDetails:instituteThemeDetails
    }

    const selectTheme = async (id) => {
        if (id === 'customtheme') {
            const content = (
                <div className='d-flex flex-column'>
                    <h2 className='text-dark'>Are you sure you want to update your current Microsite ?</h2>
                </div>
            );
            const { isConfirmed } = await Swal.fire({
                title: ' ',
                html: ReactDOMServer.renderToString(content),
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Yes',                
            });

            if (isConfirmed) {
                history.push("/create-webpage", { passToPage });
            }
        }
        if (id === 'themedefault') {
            const content = (
                <div className='d-flex flex-column'>
                    <h2 className='text-dark'>Are you sure you want to update your current Microsite ?</h2>
                </div>
            );
            const { isConfirmed } = await Swal.fire({
                title: ' ',
                html: ReactDOMServer.renderToString(content),
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Yes',
            });

            if (isConfirmed) {
                setLoader(true);
                InstitutionServices.updateDisplayPreferences(instituteThemeDetails?.inst, {})
                    .then((response) => {
                        const { status, data } = response;
                        if (status === 200) {
                            showSuccessMessage("Preferences updated successfully");
                        }
                        else {
                            showErrorMessage("Something went wrong, Please try again later");
                        }
                    })
                setLoader(false);
                history.push(`/institution/${institution?.profileId}`);
            }
        }
    }

    return (
        <div className='admin-users'>
            <CollapseablePannel title="Configure your Microsite">
                <div className="nexogic-customizer-card">
                    <div className="card">
                        <div className="card-body">
                            <h2 className="card-title pb-2">Your Current Microsite</h2>
                            <div className="nex-radio-image-options">
                                {instituteThemeDetails?.themeContainerType === 'layoutone' ?
                                    <div className="item active">
                                        <div className='radio-group justify-content-center'>
                                            <div className='preview' onClick={toggle} title="Preview">
                                                <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <line x1="5.5" y1="3" x2="5.5" y2="8" stroke="#3EABCA" />
                                                    <line x1="8" y1="5.5" x2="3" y2="5.5" stroke="#3EABCA" />
                                                    <path d="M11 11L8.58333 8.58333M9.88889 5.44444C9.88889 7.89904 7.89904 9.88889 5.44444 9.88889C2.98985 9.88889 1 7.89904 1 5.44444C1 2.98985 2.98985 1 5.44444 1C7.89904 1 9.88889 2.98985 9.88889 5.44444Z" stroke="#3EABCA" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                            <div className="graphic">
                                                <svg width="60" height="64" viewBox="0 0 60 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="17" y="17" width="43" height="47" rx="5" fill="#0087b9" fillOpacity="0.8" />
                                                    <rect width="60" height="13" rx="5" fill="#0087b9" />
                                                    <rect y="17" width="13" height="47" rx="5" fill="#0087b9" />
                                                </svg>
                                            </div></div>
                                        <div className="label text-info" title='Lightening with Left Sidebar'>Lightening(LS)</div>
                                    </div>
                                    : instituteThemeDetails?.themeContainerType === 'layouttwo' ?
                                        <div className="item active">
                                            <div className='radio-group justify-content-center'>
                                                <div className='preview' onClick={toggle} title="Preview">
                                                    <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <line x1="5.5" y1="3" x2="5.5" y2="8" stroke="#3EABCA" />
                                                        <line x1="8" y1="5.5" x2="3" y2="5.5" stroke="#3EABCA" />
                                                        <path d="M11 11L8.58333 8.58333M9.88889 5.44444C9.88889 7.89904 7.89904 9.88889 5.44444 9.88889C2.98985 9.88889 1 7.89904 1 5.44444C1 2.98985 2.98985 1 5.44444 1C7.89904 1 9.88889 2.98985 9.88889 5.44444Z" stroke="#3EABCA" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </div>
                                                <div className="graphic">
                                                    <svg width="60" height="64" viewBox="0 0 60 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect y="17" width="60" height="47" rx="5" fill="#0087b9" fillOpacity="0.8" />
                                                        <rect width="60" height="13" rx="5" fill="#0087b9" />
                                                    </svg>
                                                </div></div>
                                            <div className="label text-info" title='Lightening with No Sidebar'>Lightening</div>
                                        </div>
                                        : instituteThemeDetails?.themeContainerType === 'layoutthree' ?
                                            <div className="item active">
                                                <div className='radio-group justify-content-center'>
                                                    <div className='preview' onClick={toggle} title="Preview">
                                                        <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <line x1="5.5" y1="3" x2="5.5" y2="8" stroke="#3EABCA" />
                                                            <line x1="8" y1="5.5" x2="3" y2="5.5" stroke="#3EABCA" />
                                                            <path d="M11 11L8.58333 8.58333M9.88889 5.44444C9.88889 7.89904 7.89904 9.88889 5.44444 9.88889C2.98985 9.88889 1 7.89904 1 5.44444C1 2.98985 2.98985 1 5.44444 1C7.89904 1 9.88889 2.98985 9.88889 5.44444Z" stroke="#3EABCA" strokeLinecap="round" strokeLinejoin="round" />
                                                        </svg>
                                                    </div>
                                                    <div className="graphic"><svg width="60" height="64" viewBox="0 0 60 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect y="17" width="43" height="47" rx="5" fill="#0087b9" fillOpacity="0.8" />
                                                        <rect width="60" height="13" rx="5" fill="#0087b9" />
                                                        <rect x="47" y="17" width="13" height="47" rx="5" fill="#0087b9" />
                                                    </svg>
                                                    </div>
                                                </div>
                                                <div className="label text-info" title='Lightening with Right Sidebar'>Lightening(RS)</div>
                                            </div>
                                            :
                                            <div className="item active">
                                                <div className='radio-group justify-content-center'>

                                                    <div className="graphic">
                                                        <svg width="60" height="64" viewBox="0 0 60 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect y="20" width="43" height="47" rx="2" fill="#0087b9" fillOpacity="0.8" />
                                                            <rect width="60" height="10" rx="2" fill="#0087b9" />
                                                            <rect y="12" width="60" height="5" rx="2" fill="#0087b9" />
                                                            <rect x="47" y="20" width="13" height="47" rx="2" fill="#0087b9" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div className="label text-info">Standard</div>
                                            </div>
                                }
                                {/* <div>{instituteThemeDetails.themeContainerType}</div> */}
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <h2 className="card-title pb-2">Available Themes</h2>
                            <div className="nex-radio-image-options">
                                <div className="item active">
                                    <div className='radio-group'>
                                        <input type="radio" name="radAvailTheme" id="customtheme" onClick={() => selectTheme('customtheme')} />
                                        <div className="graphic">
                                            <svg width="60" height="64" viewBox="0 0 60 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="17" y="17" width="43" height="47" rx="5" fill="#AEC3CE" fillOpacity="0.27" />
                                                <rect width="60" height="13" rx="5" fill="#E9EFF2" />
                                                <rect y="17" width="13" height="47" rx="5" fill="#E9EFF2" />
                                            </svg>
                                        </div></div>
                                    <div className="label">Lightening</div>
                                </div>
                                <div className="item active">
                                    <div className='radio-group'>
                                        <input type="radio" name="radAvailTheme" id="themedefault" onClick={() => selectTheme('themedefault')} />
                                        <div className="graphic">
                                            <svg width="60" height="64" viewBox="0 0 60 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect y="20" width="43" height="47" rx="2" fill="#E9EFF2" fillOpacity="0.8" />
                                                <rect width="60" height="10" rx="2" fill="#E9EFF2" />
                                                <rect y="12" width="60" height="5" rx="2" fill="#E9EFF2" />
                                                <rect x="47" y="20" width="13" height="47" rx="2" fill="#E9EFF2" />
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="label">Standard (Default)</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CollapseablePannel>
            {/* <Modal isOpen={modal} toggle={toggle} backdrop="static" scrollable centered style={{ maxWidth: '1200px' }}> */}
            <Modal isOpen={modal} toggle={toggle} backdrop="static" scrollable centered style={{ maxWidth: '1200px' }}>
                <ModalHeader toggle={toggle}><span style={{ color: "gray" }}>Preview</span> </ModalHeader>
                <ModalBody style={instituteThemeDetails && Object.keys(instituteThemeDetails).length > 0 ? { backgroundColor: instituteThemeDetails?.themeColor[0] }:{}}>
                <PreviewWindow themeDetails={instituteThemeDetails?.themeColor} content={instituteThemeDetails} institution={institution} otherBranches={otherBranches} practitioners={practitioners} awards={awards} previewFlag={true} />
                </ModalBody>
            </Modal>
        </div>
    )
}

export default withAlert()(ConfigurePage);
