import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { Row, Col } from 'reactstrap'
import { getAge, getGenderDetails, capitalizeFirstLetter } from 'utils/Utils';

export default function PatientDetailsInfo(props) {
  const { patient, patientType } = props;

  return (
    <>
      <Row className='nexogic-reffer-card need-margin'>

        <Col lg={6}>
          <div className="head">
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAAXNSR0IArs4c6QAABDJJREFUaEPtWO9vFGUQfvbuens/dluoWFGPUmkwpKFYhKMFCjGS+MnEGFNjTGMMKqCJfNDwVxA1ATUxMSEh/WLQGMUEQwkxsV5LFCFSSiltCVSMpUK527u93d7+IPMeW7e9o7u9sFdCdr5ccu/uzDzzvPPM3HEnR2QTj4BxPpCHjEWfkYeMEPiM+Ix4VAH/anlU2Ird+oxUXDqPXvQZ8aiwFbv1Gam4dB696DPiUWErduuakUOpDIZuFhYMFK3h8IQQRGdTBO2JMGqCXNnnFc3EzyN5pK6rkFSDPdMQp/d4bF8dwY+XZOxqjuDxeNA1MNdAyCMF/XJAwtVpDbV8AAd21s4Gy82Y+OOGihOX87ijGAzQBx0i+7Qb+fhiQMIt2UDX+hg2J3johomLNwv4blDGVE4v8e0GzaKAkMPe0TwLOB+IFeyfjI7D/RlM5w2sqQ9h/7ZaREL/M9NzPoczEyp2bxKw8alwCchDKQlpxZhTJE+A9F1T0XMuu2DVLLB0tfYkBbSuLCZMrH3Sl0ZWNe+b6OgtDUfPZfHhVtG7q0XJuAFCvfTVGQmqbqJ7o4DO1TwDQiwd/DWNO3kDbz0voGNV8Xu7qZqJI2ezeG19bOmBDEyoOPpnluX39iYBWxLFhDXDxOf9EoanCuCDHLpaY9jeFMF8STh+SUZHI7+0QAq6icP9Ekb+K+CxWAAfddaxT8sGJwv4+ncJpFwcBzTXh9DdJmCl6F6hyvXMopvduloiH8D+bSJW1YWYXwJAavbDkIzxaQ3REId3kyJaGmpK4qauqfjmrxy7emTBAND2ZBhvbIiD/FZiFQNZKBjp/94tAhL3QJZ79l9JR8/5LMZuazDv/ddJ6vbyuhhebI4gUH4E3TdsxUDmyy81KTX5iZE8JtIaC5hM8Hjzufgc+bVnQvlfnJzBsQsyJrP67NGzK2rwXlJYFDsPDIiVBSX37YUcTo8rrNKbnw7jnaRY0tB2QIYJnB5T8NOwzHqHjFh5vTXu+pY9cCAUmWT2075McUpHAvi4s5ZNeJojx4dlvNoSA28bkla2xAqpGr1XHwvgwI46LI+66xlPgFBi1m5GMru3vdj0BOTIWQldrfGS1cUCY0m3EJ67AjlR4wkQSviz3zL4O62VMELfb23k2VJYzqxhGuc5bxlx2rUouV/GFRwbzEE35vaIBTA3Y+D9dhGNy4rSbbeTV/L4fkhmW3B3m0c9Yt9+Se/3tYtsMSSlpBadyuroHVWQuq4wEPPVx84USe1La6PY0cQzdZILJnqv5HFqTMEzy0PeqZab3yM0qWkQNghB7GyKsDXDPg8ICP3WeKUlhhsZDadGFVyeKsxO+fpoALuao3hhTRXmiFPTLdX5opt9qRJ1iusDcapQtc99Rqpdcad4PiNOFar2uc9ItSvuFM9nxKlC1T73Gal2xZ3iPTKM3AW1wIYTHJm9eAAAAABJRU5ErkJggg==" className='card-img' alt="" />
            <div className="info">
              <div className="card-text"><b>Name</b>
                <Link to={`/patient-profile/${patient?.id}`}>
                  <span>
                    {patient?.firstName}{" "}{patient?.lastName}
                  </span>
                </Link>
              </div>
              <div className="card-text"><b>Age </b>{patient?.dateOfBirth ? getAge(patient?.dateOfBirth) : 'N/A'}</div>
              {patient?.cellularPhoneNumber &&
                <div className="card-text"><b>Phone </b>{patient?.cellularPhoneNumber}</div>
              }
            </div>
          </div>
        </Col>
        <Col lg={6}>
          <div className="head">
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAAXNSR0IArs4c6QAABDJJREFUaEPtWO9vFGUQfvbuens/dluoWFGPUmkwpKFYhKMFCjGS+MnEGFNjTGMMKqCJfNDwVxA1ATUxMSEh/WLQGMUEQwkxsV5LFCFSSiltCVSMpUK527u93d7+IPMeW7e9o7u9sFdCdr5ccu/uzDzzvPPM3HEnR2QTj4BxPpCHjEWfkYeMEPiM+Ix4VAH/anlU2Ird+oxUXDqPXvQZ8aiwFbv1Gam4dB696DPiUWErduuakUOpDIZuFhYMFK3h8IQQRGdTBO2JMGqCXNnnFc3EzyN5pK6rkFSDPdMQp/d4bF8dwY+XZOxqjuDxeNA1MNdAyCMF/XJAwtVpDbV8AAd21s4Gy82Y+OOGihOX87ijGAzQBx0i+7Qb+fhiQMIt2UDX+hg2J3johomLNwv4blDGVE4v8e0GzaKAkMPe0TwLOB+IFeyfjI7D/RlM5w2sqQ9h/7ZaREL/M9NzPoczEyp2bxKw8alwCchDKQlpxZhTJE+A9F1T0XMuu2DVLLB0tfYkBbSuLCZMrH3Sl0ZWNe+b6OgtDUfPZfHhVtG7q0XJuAFCvfTVGQmqbqJ7o4DO1TwDQiwd/DWNO3kDbz0voGNV8Xu7qZqJI2ezeG19bOmBDEyoOPpnluX39iYBWxLFhDXDxOf9EoanCuCDHLpaY9jeFMF8STh+SUZHI7+0QAq6icP9Ekb+K+CxWAAfddaxT8sGJwv4+ncJpFwcBzTXh9DdJmCl6F6hyvXMopvduloiH8D+bSJW1YWYXwJAavbDkIzxaQ3REId3kyJaGmpK4qauqfjmrxy7emTBAND2ZBhvbIiD/FZiFQNZKBjp/94tAhL3QJZ79l9JR8/5LMZuazDv/ddJ6vbyuhhebI4gUH4E3TdsxUDmyy81KTX5iZE8JtIaC5hM8Hjzufgc+bVnQvlfnJzBsQsyJrP67NGzK2rwXlJYFDsPDIiVBSX37YUcTo8rrNKbnw7jnaRY0tB2QIYJnB5T8NOwzHqHjFh5vTXu+pY9cCAUmWT2075McUpHAvi4s5ZNeJojx4dlvNoSA28bkla2xAqpGr1XHwvgwI46LI+66xlPgFBi1m5GMru3vdj0BOTIWQldrfGS1cUCY0m3EJ67AjlR4wkQSviz3zL4O62VMELfb23k2VJYzqxhGuc5bxlx2rUouV/GFRwbzEE35vaIBTA3Y+D9dhGNy4rSbbeTV/L4fkhmW3B3m0c9Yt9+Se/3tYtsMSSlpBadyuroHVWQuq4wEPPVx84USe1La6PY0cQzdZILJnqv5HFqTMEzy0PeqZab3yM0qWkQNghB7GyKsDXDPg8ICP3WeKUlhhsZDadGFVyeKsxO+fpoALuao3hhTRXmiFPTLdX5opt9qRJ1iusDcapQtc99Rqpdcad4PiNOFar2uc9ItSvuFM9nxKlC1T73Gal2xZ3iPTKM3AW1wIYTHJm9eAAAAABJRU5ErkJggg==" className='card-img invisible' alt="" />
            <div className="info">
              <div className="card-text"><b>Genders </b>{getGenderDetails(patient?.gender)}</div>
              <div className="card-text"><b>Type </b>{capitalizeFirstLetter(patientType)}</div>
              {patient?.emailAddress &&
                <div className="card-text nexogic-word-break"><b>Email </b>{patient?.emailAddress}</div>
              }
            </div>
          </div>
        </Col>
      </Row >
    </>
  )
}
