import React from "react";

import { Button, Col, Label, Row, Spinner } from "reactstrap";
import { Field, Formik } from "formik";
import ReactDOM from "react-dom";
import * as Yup from "yup";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import { useState } from "react";
import FormikDatePicker from "components/forms/formikFields/FormikDatePicker";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import { Link, useHistory } from "react-router-dom";
import { PatientService } from "utils/PatientService";
import { withAlert } from "react-alert";
import { FAILTOSAVERECOED } from "utils/Constants";
import swal from "sweetalert";
import FormikMobileField from "components/forms/formikFields/FormikMobileField";
import PatientDetConfirmBox from "./PatientDetConfirmBox";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  gender: Yup.string().required("Gender is required"),
  phoneNumber: Yup.string()
    // .matches(/^.{4}\d{10}$/, 'Invalid phone number')
    .nullable(true)
    .test("phoneRequired", "Invalid phone number", function (value) {
      const mobileNumberRegex = /^(\+91\s?)?\d{10}$/;
      return (
        value === "+91" || value === undefined || mobileNumberRegex.test(value)
      );
    }),

  emailAddress: Yup.string()
    .email("Invalid email id")
    .nullable(true)
    .test(
      "phoneOrEmailRequired",
      "Either a valid phone number or email id is required",
      function (value) {
        const phoneNumber = this.parent.phoneNumber;
        return (
          (!!phoneNumber && !value) ||
          (!!value && !phoneNumber) ||
          (!!phoneNumber && !!value)
        );
      }
    ),
});

const RenderForm = (props) => {
  const { handleSubmit, initialValues } = props;
  const { gender } = props.values;

  const genders = [
    {
      label: "Male",
      value: "M",
    },
    {
      label: "Female",
      value: "F",
    },
    {
      label: "Prefer not to answer",
      value: "U",
    },
  ];

  return (
    <form
      className="my-form"
      onSubmit={handleSubmit}
      onMouseDown={(e) => {
        document.body.classList.remove("datepicker-on");
      }}
    >
      <div>
        <Row>
          <Col lg={6} md={12} sm={12}>
            <div className="form-group">
              <Field
                id="firstName"
                name="firstName"
                label="First Name"
                type="text"
                component={FormikInputField}
                placeholder="Enter First Name"
                autoComplete="off"
                value={initialValues.firstName}
                spellCheck={false}
              />
            </div>
          </Col>
          <Col lg={6} md={12} sm={12}>
            <div className="form-group">
              <Field
                id="lastName"
                name="lastName"
                label="Last Name"
                type="text"
                component={FormikInputField}
                placeholder="Enter Last Name"
                autoComplete="off"
                value={initialValues.lastName}
                spellCheck={false}
              />
            </div>
          </Col>
          <Col lg={4}>
            <Field
              id="gender"
              name="gender"
              type="text"
              label="Gender"
              value={gender}
              component={FormikSelectField}
              inputprops={{
                name: `duration`,
                options: genders,
                defaultOption: "Select",
                keys: {
                  id: "value",
                  label: "label",
                },
              }}
            />
          </Col>
          <Col
            lg={4}
            onMouseDown={(e) => {
              e.stopPropagation();
              document.body.classList.add("datepicker-on");
            }}
          >
            <Field
              id="dateOfBirth"
              name="dateOfBirth"
              type="text"
              component={FormikDatePicker}
              inputprops={{
                dateFormat: "yyyy-MM-dd",
                showYearDropdown: true,
                showMonthDropdown: true,
                scrollableYearDropdown: true,
                yearDropdownItemNumber: 10,
                maxDate: new Date(),
                autoComplete: "off",
                style: { height: "300px" },
              }}
              label="Date of Birth"
              placeholder="YYYY-MM-DD"
            />
          </Col>
          <Col lg={4}>
            {/*<Field
                            id="phoneNumber"
                            name="phoneNumber"
                            type="text"
                            label="Phone Number"
                            value={initialValues.phoneNumber}
                            component={FormikInputField}
                            placeholder="Enter Phone Number(91xxxxx12345)"
                            autoComplete="off"
                            spellCheck={false}
                        />
                        */}
            <Field
              id="nex-apf-phone-number"
              name="phoneNumber"
              type="text"
              label="Phone Number"
              // value={(e)=>getPhoneNumber(e.target.value)}
              component={FormikMobileField}
              placeholder="10 digit Mobile Number"
              autocomplete="off"
            />
          </Col>
          <Col lg={12}>
            <Field
              id="emailAddress"
              name="emailAddress"
              type="text"
              label="Email Address"
              value={initialValues.emailAddress}
              component={FormikInputField}
              placeholder="Enter Email Address"
              autoComplete="off"
              spellCheck={false}
            />
          </Col>
        </Row>
        <Row
          form
          className="justify-content-end  modal-footer mx-0 px-0 pt-2 border-0 nex-app-ref-actions-w"
        >
          <Col xs="auto action-group px-0 m-0">
            <Button
              id="cf-btn-submit"
              className="nexogic_primary_button"
              //   disabled={!isValid || !dirty}
              color="primary"
              type="submit"
            >
              Save
            </Button>
          </Col>
        </Row>
      </div>
    </form>
  );
};

const AddPatientForm = (props) => {
  const [errorMsg, setErrorMessage] = useState("");
  const history = useHistory();
  const initialValues = {
    firstName: "",
    lastName: "",
    gender: "",
    phoneNumber: "",
    emailAddress: "",
  };

  const createPatient = (values, resetForm) => {
    let payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      gender: values.gender,
      emailAddress: values.emailAddress ? values.emailAddress : null,
      dateOfBirth: values.dateOfBirth,
      phoneNumber:
        values.phoneNumber && values.phoneNumber.length > 10
          ? values.phoneNumber
          : null,
    };
    PatientService.createPatient(payload).then((response) => {
      const { status } = response;
      if (status === 201) {
        showSuccessMessage("Patient record created succesfully");
        resetForm();
        if (props.setAddPIsOpen) {
          props.setAddPIsOpen(false);
        } else {
          history.push("/connections/my-patients");
        }
      } else if (status === 409) {
        var query = "";
        if (payload.emailAddress) {
          query = `ea==${payload.emailAddress}`;
        }
        if (payload.cellularPhoneNumber) {
          if (query === "") {
            query = `pn=="${payload.cellularPhoneNumber.replace(
              /^\+/,
              "%2B"
            )}"`;
          } else {
            query =
              query +
              `,pn=="${payload.cellularPhoneNumber.replace(/^\+/, "%2B")}"`;
          }
        }

        let existingPatient = {};
        PatientService.getPatients(`sq=${query}`).then((response) => {
          const { status, data } = response;
          if (status === 200) {
            existingPatient = data.contents[0];
            const modalContainer = document.createElement("div"); // Create a new DOM element
            ReactDOM.render(
              <PatientDetConfirmBox pdetails={existingPatient} />,
              modalContainer
            );
            swal({
              content: modalContainer,
              buttons: {
                cancel: true,
                confirm: {
                  text: "Add",
                  value: true,
                  visible: true,
                  className: "custom-confirm-button",
                },
              },
            }).then((yes) => {
              let payload = {
                id: existingPatient.id,
                source: "REFERRAL",
              };
              if (yes)
                PatientService.addExisitingPatientAsMyPatient(payload).then(
                  (response) => {
                    const { status } = response;
                    if (status === 201) {
                      showSuccessMessage(
                        "Patient added as my patient successfully"
                      );
                      resetForm();
                      if (props.setAddPIsOpen) {
                        props.setAddPIsOpen(false);
                      } else {
                        history.push("/connections/my-patients");
                      }
                    } else {
                      showErrorMessage(FAILTOSAVERECOED);
                    }
                  }
                );
            });
          } else {
            showErrorMessage(FAILTOSAVERECOED);
          }
        });
      } else {
        showErrorMessage(FAILTOSAVERECOED);
      }
    });
  };

  return (
    <>
      {errorMsg !== "" && <RenderErrorMessage errorMsg={errorMsg} />}
      <Formik
        enableReinitialize={true}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          createPatient(values, resetForm); // Pass the resetForm function to createPatient
        }}
        children={(props) => {
          return (
            <>
              <RenderForm {...props} />
            </>
          );
        }}
      ></Formik>
    </>
  );
};

export default withAlert()(AddPatientForm);
