import { Link } from "react-router-dom/cjs/react-router-dom"
import CommonLoader from 'common/CommonLoader';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { withAlert } from 'react-alert';
import { FAILED_TO_DELETE_DATA, FAILED_TO_FETCH_DATA, FAILTOSAVERECOED } from 'utils/Constants';
import { MedialService } from 'utils/MediaService';
import swal from "sweetalert";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import { toast } from "react-toastify";
export default withAlert()(function DocumentForm(props) {
  const { opinionId, forUpdate, opinionInfo } = props;
  const [docs, setDocs] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  // const [selectedFile, setSelectedFile] = useState(null);

  const getAllDocs = (opinionId) => {
    MedialService.getMedias("SECOND_OPINION", opinionId)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          // console.log(data);
          setDocs(data);
        }
        else {
          console.log(FAILED_TO_FETCH_DATA);
        }
      })
  }

  useEffect(() => {
    getAllDocs(opinionId);
  }, [opinionId])

  useEffect(() => {
    getAllDocs(opinionInfo?.id);
  }, [forUpdate, opinionInfo])

  const handleFileDelete = (docId) => {

    swal("Are you sure you want to delete this File?", {
      buttons: ["cancel", "yes"],
    })
      .then((yesRemove) => {
        if (yesRemove)
          MedialService.deleteMedia(docId)
            .then((response) => {
              const { status } = response;
              if (status === 204) {
                showSuccessMessage("File deleted Successfully.");
                getAllDocs(opinionId);
              } else {
                showErrorMessage(FAILED_TO_DELETE_DATA);
              }
            })
      })
  }

  const handleFileChange = (e) => {
    setIsUploading(true);
    handleUpload(e.target.files[0]); // Call handleUpload when a file is selected
  };

  const handleUpload = (sF) => {
    // You can use the 'selectedFile' state to send the file to your API
    if (sF) {
      const formData = new FormData();
      formData.append('files', sF);
      // console.log('Uploading file:', sF);

      MedialService.uploadMedia("SECOND_OPINION", opinionId, formData)
        .then((response) => {
          const { status } = response;
          if (status === 200) {
            showSuccessMessage("Document uploaded Successfully");
            getAllDocs(opinionId);
            setIsUploading(false);
          } else if (status === 409) {
            toast.info("Can't upload the document with the same name again");
            setIsUploading(false);
          }
          else {
            showErrorMessage(FAILTOSAVERECOED);
            setIsUploading(false);
          }
        })
    } else {
      console.error('No file selected');
    }
  };

  return (
    <div className="row ">

      <div className="col-md-12">
        <div className="row">
          <div className="col-lg-12">
            <div className="form-group">
              <h3 className="card-title big">Upload documents</h3>
            </div>
          </div>
          {isUploading ?
            <div className="loading-spiner text-center">
              <CommonLoader />
            </div>
            :
            <div className="col-lg-12">
              <p className='card-text text-muted mb-2'>Please upload any medical notes, results from diagnostic studies etc</p>
              <div className="nexogic-upload-card-v2">
                <div className="icon"><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.1663 22.3335V7.39183L8.39967 12.1585L5.83301 9.50016L14.9997 0.333496L24.1663 9.50016L21.5997 12.1585L16.833 7.39183V22.3335H13.1663ZM0.333008 29.6668V20.5002H3.99967V26.0002H25.9997V20.5002H29.6663V29.6668H0.333008Z" fill="black" />
                </svg>
                </div>
                <p className='card-text'>
                  <label>
                    Drag & Drop or
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />
                    <a href='#' onClick={() => document.querySelector('input[type="file"]').click()}>
                      Choose file
                    </a>
                  </label>
                  to upload
                </p>
                <p className='card-text'>PDF, PNG, JPG</p>
                <p className='card-text'>50mb file limit</p>
              </div>
            </div>
          }

          {/* --------------Uploaded docs----start------------------------------------------ */}
          {docs.length > 0 &&
            <>
              <hr />
              <div className="col-lg-12 mt-2">

                <h2 className='card-title big mb-3'>Uploaded Documents</h2>

                {docs.map((doc) => (
                  <div className="nexogic-upload-lists-card">
                    <div className="item">
                      <div className="card-body">
                        <div className="card-img">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="38"
                            height="46"
                            fill="none"
                            viewBox="0 0 38 46"
                            className='icon'
                          >
                            <path
                              fill="#000"
                              stroke="#fff"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M23.334 1.333H6a4.333 4.333 0 00-4.333 4.334v34.667A4.333 4.333 0 006 44.666h26a4.333 4.333 0 004.334-4.334v-26l-13-13zm8.666 39H6V5.668h15.167V16.5H32v23.834z"
                            ></path>
                          </svg>
                        </div>
                        <div className="card-info">
                          <h3 className="card-title">{doc?.name}</h3>
                          <p className='card-text text-muted small'>{doc?.size}{" KB"}</p>
                        </div>
                        <div className="actions">
                          <div className="btns">
                            <span className='btn' onClick={() => {
                              handleFileDelete(doc?.id);
                            }}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="18"
                                fill="none"
                                viewBox="0 0 16 18"
                              >
                                <path
                                  fill="#001244"
                                  d="M3 18c-.55 0-1.021-.196-1.413-.588A1.922 1.922 0 011 16V3H0V1h5V0h6v1h5v2h-1v13c0 .55-.196 1.021-.588 1.413A1.922 1.922 0 0113 18H3zM13 3H3v13h10V3zM5 14h2V5H5v9zm4 0h2V5H9v9z"
                                ></path>
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          }
          {/* --------------Uploaded docs-----end---------------------------------------- */}


          <div className="col-lg-12">
            <div className="form-group nex-form-action">
              <input type="button" disabled={isUploading} name='so-upload-back' value="Back" className='nexogic_primary_button_outline' onClick={(e) => { props.setCurrentStepInMainForm(3) }} />
              <Link
                color="primary"
                className="nexogic_primary_button"
                to={`/u/medicalcases/my-opinions/${props.opinionId}`}
              >
                Save in Draft
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
);