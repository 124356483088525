import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { Button, PopoverBody, UncontrolledPopover } from 'reactstrap'
import { FAILED_TO_FETCH_DATA } from 'utils/Constants';
import { MedialService } from 'utils/MediaService';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import CommonLoader from "common/CommonLoader";

export default function OpinionDocument(props) {
    const [documents, setDocuments] = useState([]);
    const [documentType, setDocumentType] = useState(null);
    const [documentData, setDocumentData] = useState(null);
    const [dataLoading, setDataLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [modal, setModal] = useState(false);

    const toggleForm = () => setModal(!modal);

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = documentData;
        let ext = documentType[1].split('/').pop();
        link.download = 'reports.' + ext;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        link.click();
    };

    const getDocsInfo = (id) => {
        MedialService.getMedias("SECOND_OPINION", id)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    setDocuments(data);
                } else {
                    console.log(FAILED_TO_FETCH_DATA);
                }
            })
    }

    const getReferralDoc = async (id) => {
        try {
            setDataLoading(true);
            const { data, headers } = await MedialService.getMediaFile(id);
            const createContentType = headers["content-type"] === "application/octet-stream"
                ? "application/pdf"
                : headers["content-type"];
            let generateURL = `data:${createContentType};base64,${Buffer.from(data).toString("base64")}`;
            setDocumentData(generateURL);
            let docArray = [data, createContentType];
            setDocumentType(docArray);
            if (createContentType === "application/pdf") {
                setDataLoading(false);
                onOpenBase64(generateURL);
            }
            else {
                setDataLoading(false);
                toggleForm();
            }
        } catch (error) {
            console.error("Error retrieving referral document:", error);
        }
    };

    const onOpenBase64 = (base64URL) => {
        // const onOpenBase64 = () => {
        try {
            var win = window.open();
            win.document.write(`
            <iframe class="nexogic-iframe" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" 
              src="${base64URL}" frameborder="0" allowfullscreen></iframe>
          `);
        } catch (error) {
            console.error("Error opening base64 URL:", error);
            // Handle error, show error message, etc.
        }
    };


    useEffect(() => {
        getDocsInfo(props?.opinionId);
    }, [props?.opinionId])


    return (
        <>
            {documents.length === 0
                ?
                <>
                    <div className="card-text"><span className="icon"><svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="22"
                        fill="none"
                        viewBox="0 0 18 22"
                    >
                        <path
                            stroke="#3EABCA"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M11 1H3a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V7m-6-6l6 6m-6-6v6h6m-4 5H5m8 4H5m2-8H5"
                        ></path>
                    </svg></span><b className='no-colon text-nowrap'>No Document </b></div>
                </>
                :
                <>
                    <Button className='nexogic-btn-link'
                        id={props.id}
                        type="button" onClick={() => setIsOpen(!isOpen)}
                    >
                        <div className="card-text">
                            <span className="icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="22"
                                    fill="none"
                                    viewBox="0 0 18 22">
                                    <path
                                        stroke="#3EABCA"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M11 1H3a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V7m-6-6l6 6m-6-6v6h6m-4 5H5m8 4H5m2-8H5"
                                    >
                                    </path>
                                </svg>
                            </span>
                            <b className='no-colon'>Documents</b>({documents.length})
                        </div>
                    </Button>
                    <UncontrolledPopover
                        isOpen={isOpen}
                        placement="bottom"
                        target={props.id}
                        trigger="click"
                        className='nexogic-document-popover'
                    >
                        <PopoverBody className='nexogic-popover'>
                            <h3 className="card-title popover-title">Documents <span className='popover-close-btn' onClick={() => setIsOpen(false)}>&times;</span></h3>
                            <div className="nexogic-custom-scroll">
                                <div className="nexogic-document-lists">
                                    <ul>
                                        {documents.map((doc, ind) => (
                                            <li key={'ref-documents-' + ind}>
                                                <div className="nexogic-document-item" style={{ cursor: "pointer" }} onClick={() => { getReferralDoc(doc?.id); setIsOpen(false) }}>
                                                    <div className="graphic">
                                                        <svg className='icon'
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="26"
                                                            height="32"
                                                            fill="none"
                                                            viewBox="0 0 26 32"
                                                        >
                                                            <path
                                                                stroke="#3EABCA"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeWidth="2"
                                                                d="M16 1H4a3 3 0 00-3 3v24a3 3 0 003 3h18a3 3 0 003-3V10m-9-9l9 9m-9-9v9h9m-6 7.5H7m12 6H7m3-12H7"
                                                            ></path>
                                                        </svg>
                                                    </div>
                                                    <div className="info">
                                                        <div className="document-text">
                                                            {/* <h3 className="card-title">Prescription <span className='text-muted'>2h ago</span></h3> */}
                                                            <p className='card-text'>{doc?.name}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </PopoverBody>
                    </UncontrolledPopover>
                    {
                        dataLoading ?
                            <div className="" style={{ zIndex: "111", position: "absolute", top: "0", left: "0", backgroundColor: "#edeff0b0", width: "100%", height: "100%" }}>
                                <div className="component-wrapper"><CommonLoader /></div>
                            </div>
                            :
                            <Modal isOpen={modal} toggle={toggleForm} backdrop="static" scrollable centered >
                                <ModalHeader toggle={toggleForm}><span style={{ color: "gray" }}>Document Viewer</span></ModalHeader>
                                <ModalBody>
                                    <div className="modalbody">
                                        <img src={documentData} alt="document preview" />
                                    </div>
                                    <div className="downloadbtn">
                                        <FontAwesomeIcon color="#0087b9" size="xl" style={{ cursor: "pointer" }} icon={faDownload} onClick={handleDownload} />
                                    </div>
                                </ModalBody>
                            </Modal>
                    }
                </>
            }
        </>
    )

}
