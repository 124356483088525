import React from 'react'
import { useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import * as Yup from "yup";
import SecondOpinionImg from "assets/images/second-opinion.png";
import ReactQuill from 'react-quill';
import FormikInputField from "components/forms/formikFields/FormikInputField";
import { Field, Formik } from 'formik';
import { ReferralResponseService } from 'utils/ReferralResponseService';
import { useParams, useHistory } from "react-router-dom";
import { withAlert } from 'react-alert';
import { FAILTOSAVERECOED, Treatment_Type } from 'utils/Constants';
import swal from "sweetalert";
import FormikSelectField from 'components/forms/formikFields/FormikSelectField';
import { useEffect } from 'react';
import { showErrorMessage, showSuccessMessage } from 'common/AlertContainer';

const validationSchema = Yup.object().shape({
    treatmentType: Yup.string().required("This field is required"),
    treatmentDuration: Yup.number()
        .required("This field is required")
        .positive("Duration must be a positive number")
        .integer("Duration must be an integer")
        .max(999999, "Duration must be less than or equal to 999999"),
    treatmentCost: Yup.number()
        .required("This field is required")
        .positive("Cost must be a positive number")
        .max(999999, "Cost must be less than or equal to 999999"),
});


export const RenderTreatmentForm = (props) => {
    const { setFieldValue, initialValues, values, handleSubmit, selectedDuration, setSelectedDuration } = props;
    const [durationDropOn, setDurationDropOn] = useState(false);

    return (
        <form className="my-form" onSubmit={handleSubmit}>
            <div className="nexogic-form-content">
                <div className="w-100">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group">
                                <Field
                                    id="treatmentType"
                                    name="treatmentType"
                                    type="text"
                                    label="Type"
                                    component={FormikSelectField}
                                    inputprops={{
                                        name: `patientType`,
                                        options: Treatment_Type,
                                        defaultOption: "Select",
                                        keys: {
                                            id: "value",
                                            label: "label",
                                        },
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group nexogic-relative">
                                <div className='nexogic-float-dropdown selected-off'>
                                    <div className="dropdown-w small-down-arrow" onClick={() => { setDurationDropOn(!durationDropOn) }}>
                                        <div className='selected-label' >{selectedDuration}</div>
                                        <div className={`drops ${durationDropOn ? 'on' : ''}`}>
                                            <div className={`item ${selectedDuration === "Days" ? 'selected' : ''}`}
                                                onClick={() => { setDurationDropOn(false); setSelectedDuration('Days'); }}
                                            >Days</div>
                                            <div className={`item ${selectedDuration === "Weeks" ? 'selected' : ''}`}
                                                onClick={() => { setDurationDropOn(false); setSelectedDuration('Weeks'); }}
                                            >Weeks</div>
                                        </div>
                                    </div>
                                    <Field
                                        id="treatmentDuration"
                                        name="treatmentDuration"
                                        autoComplete="off"
                                        type="number"
                                        label="Duration"
                                        component={FormikInputField}
                                        value={initialValues.treatmentDuration}
                                        placeholder={"Duration"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className='nexogic-float-dropdown selected-off nexogic-cost-dropdown'>
                                <div className="dropdown-w">
                                    <div className='selected-label'>&#8377;</div>
                                    <div className='drops'>
                                        <div className='item'>&#8377;</div>
                                    </div>
                                </div>
                                <Field
                                    id="treatmentCost"
                                    name="treatmentCost"
                                    value={initialValues.treatmentCost}
                                    autoComplete="off"
                                    type="number"
                                    label="Estimated Discharge Amount"
                                    component={FormikInputField}
                                    placeholder={"Amount"}
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group">
                                <Field
                                    id="treatmentSummary"
                                    name="treatmentSummary"
                                    value={initialValues.treatmentSummary}
                                    autoComplete="off"
                                    type="text"
                                    label="Summary"
                                    component={FormikInputField}
                                    placeholder={"Reason For Treatment"}
                                />
                            </div>
                        </div>
                        <Col lg={12} md={12} sm={12} className="mb-3">
                            <label htmlFor="procedures">Procedures</label>
                            <ReactQuill
                                theme="snow"
                                id="refp_treatment_procedures"
                                onChange={(html) => {
                                    setFieldValue("procedures", html, true);
                                }}
                                name="procedures"
                                value={values.procedures || ""}
                                placeholder="Enter Indication or Diagnosis ..."
                            />
                        </Col>
                        <Col lg={12} md={12} sm={12} className="mb-3">
                            <label htmlFor="prescriptions">Prescriptions</label>
                            <ReactQuill
                                theme="snow"
                                id="refp_treatment_prescriptions"
                                onChange={(html) => {
                                    setFieldValue("prescriptions", html, true);
                                }}
                                name="prescriptions"
                                value={values.prescriptions || ""}
                                placeholder="Enter prescriptions ..."
                            />
                        </Col>
                        <Col lg={12} md={12} sm={12} className="mb-3">
                            <label htmlFor="postTreatment">Post Treatment</label>
                            <ReactQuill
                                theme="snow"
                                id="refp_treatment_postTreatment"
                                onChange={(html) => {
                                    setFieldValue("postTreatment", html, true);
                                }}
                                name="postTreatment"
                                value={values.postTreatment || ""}
                                placeholder="Enter post treatment ..."
                            />
                        </Col>
                    </div>
                    <div className="form-group mt-3">
                        <input type="submit" value="Save" className='nexogic_primary_button btn' />
                    </div>
                </div>
            </div>

        </form>
    );
}

export default withAlert()(function Treatment(props) {

    const [selectedDuration, setSelectedDuration] = useState('Days');
    const [responseId, setResponseId] = useState();
    let { id } = useParams();
    const history = useHistory();

    const getReferralResponse = (id) => {
        ReferralResponseService.getReferralResponse(id, "RECEIVED")
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    setResponseId(data.id);
                }
                else {
                    console.log(FAILTOSAVERECOED);
                }
            })
    }

    useEffect(() => {
        getReferralResponse(id);
    }, [id])

    const createDuration = (duration) => {
        if (selectedDuration === 'Days') {
            if (duration <= 1) {
                return duration + ' Day';
            }
            else {
                return (duration + ' Days');
            }
        } else if (selectedDuration === 'Weeks') {
            if (duration <= 1) {
                return duration + ' Week';
            }
            else {
                return (duration + ' Weeks');
            }
        } else {
            return duration;
        }
    }

    const handleSubmit = (values) => {

        swal("Are you sure you want to submit the Treatment Details? Once submitted, you won't be able to modify them later.", {
            buttons: ["Cancel", "Yes"],
        })
            .then((yes) => {
                if (yes) {
                    if (responseId) {

                        let payload = {
                            "type": values.treatmentType,
                            "duration": createDuration(values.treatmentDuration),
                            "cost": values.treatmentCost,
                            "summary": values.treatmentSummary,
                            "procedures": values.procedures,
                            "prescriptions": values.prescriptions,
                            "postTreatment": values.postTreatment,
                        }

                        ReferralResponseService.addTreatment(id, responseId, payload)
                            .then((response) => {
                                const { status } = response;
                                if (status === 201 || status === 200) {
                                    showSuccessMessage("Response added Successfully");
                                    history.push(`/referrals/sent-response/${id}`);
                                } else {
                                    showErrorMessage(FAILTOSAVERECOED);
                                }
                            })
                    }
                    else {
                        showErrorMessage("Provide PatienStatus first, before adding treatment details");
                    }
                }
            })
    }


    const initialValues = {
        treatmentType: '',
        treatmentDuration: '',
        treatmentCost: '',
        treatmentSummary: '',
        procedures: '',
        prescriptions: '',
        postTreatment: '',
    };


    return (
        <Card className="bg-transparent border-0">
            <CardBody className="p-0">
                <Row className="mb-5 nexogic-sidebar-cont-wrap" >
                    <Col lg={9} md={8} sm={12}>
                        <div className="position-relative mb-3">
                            <Card className={'nexogic-card-color-header expanded'}>
                                <div className="card-header">
                                    <h2>Treatment</h2>
                                </div>
                                <div className="collapse-div">
                                    <CardBody>
                                        <Formik
                                            enableReinitialize={true}
                                            validationSchema={validationSchema}
                                            initialValues={initialValues}
                                            onSubmit={handleSubmit}
                                            children={(props) => {
                                                return (
                                                    <>
                                                        <RenderTreatmentForm
                                                            {...props}
                                                            selectedDuration={selectedDuration}
                                                            setSelectedDuration={setSelectedDuration}
                                                        />
                                                    </>
                                                );
                                            }}
                                        >
                                        </Formik>
                                    </CardBody>
                                </div>
                            </Card>
                        </div>

                    </Col>
                    <Col lg={3} md={4} sm={12} className="nexogic-sidebar-col ">
                        <div className="nexogic-widget-sidebar-card card border-0 mb-0">
                            <h3 className="card-title h2">Second Opinion</h3>
                            <div className="card-body">
                                <ul className="nexogic-lists nexogic-custom-scroll">
                                    <li>
                                        <img src={SecondOpinionImg} alt="" className="img-fluid" />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );

})
