import React, { useState } from "react";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import PagesOwned from "./components/PagesOwned";
import PagesFollowed from "./components/pagesFollowed";
import { ConnectionServices } from "utils/ConnectionServices";
import { isInstitutionAdmin, isPractitioner } from "utils/UserHelper";
import { useEffect } from "react";

const Pages = (props) => {
  const [activeTab, setActiveTab] = useState(isPractitioner() ? "Pages followed" : "Pages owned");
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [isLoading, setIsLoading] = useState(true);
  let practitionerId = currentUser.practitionerId;
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    if (tab === "Pages followed") {
      getFollowedPages(0);
    }
  };

  const getFollowedPages = (pageNo) => {
    ConnectionServices.getPagesIFollow(currentUser.guid, "INSTITUTION", pageNo).then(
      (response) => {
        if (response.status === 200) {
          const { data } = response;
          if (data?.currentPage < (data?.totalPages - 1))
            setHasMore(true);
          else
            setHasMore(false);
          setCurrentPage(data?.currentPage);

          if (pageNo === 0)
            setPages(data.contents);
          else
            setPages([...pages, ...data.contents]);
          setIsLoadingMore(false)

        } else {
          setIsLoadingMore(false)
          showErrorMessage("Something went wrong");
        }
        setIsLoadingMore(false)
        setIsLoading(false);
      }
    );
  };


  useEffect(() => {
    setIsLoading(true);
    if (isPractitioner()) {
      toggle("Pages followed");
    }
    else if (isInstitutionAdmin()) {
      toggle("Pages owned");
    }
  }, [])

  return (
    <>
      <Card className="border-0 bg-transparent nexogic-network-pages">
        <CardBody className="p-0">
          <Nav tabs className="nexogic-tabs">
            {isPractitioner() &&
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "Pages followed",
                  })}
                  onClick={() => {
                    toggle("Pages followed");
                  }}
                >
                  Pages followed
                </NavLink>
              </NavItem>
            }
            {isInstitutionAdmin() &&
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "Pages owned" })}
                  onClick={() => {
                    toggle("Pages owned");
                  }}
                >
                  Pages owned
                </NavLink>
              </NavItem>
            }

          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="Pages owned">
              <PagesOwned practitionerId={practitionerId} />
            </TabPane>
            <TabPane tabId="Pages followed">
              <PagesFollowed
                practitionerId={practitionerId}
                pages={pages}
                isLoading={isLoading}
                hasMore={hasMore}
                currentPage={currentPage}
                isLoadingMore={isLoadingMore}
                setIsLoadingMore={setIsLoadingMore}
                getFollowedPages={getFollowedPages}
              />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </>
  );
};

export default Pages;
