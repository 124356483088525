import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import LinesEllipsis from 'react-lines-ellipsis';
import { TalentService } from "utils/TalentService";
import { ShowExperience, getPosition } from 'utils/Utils';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { isPractitioner } from "utils/UserHelper";
// import ApplyJobs from 'pages/FeedHomePage/components/ApplyJobs';
import Apply from 'pages/FeedHomePage/components/ApplyJobs/Apply';
import { FAILED_TO_FETCH_DATA } from 'utils/Constants';
// import './style.scss'

const JobTabularView = ({ JobDetails, index, currentUser, alert }) => {
    const [applicantStatus, setApplicantStatus] = useState(null);
    const [modal, setModal] = useState(false);
    const toggleForm = () => setModal(!modal);
    const [jobFullDetails, setJobFullDetails] = useState({});

    // useEffect(() => {
    //     console.log(JobDetails.title);
    //     console.log(JobDetails);
    // }, [JobDetails.id])

    const getJobDetails = (id) => {
        TalentService.getJob(id)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    // console.log(data);
                    setJobFullDetails(data);
                } else {
                    alert.error(FAILED_TO_FETCH_DATA);
                }
            })
    }

    const getApplicantStatus = (jobId) => {
        TalentService.getJobApplicantStatus(jobId)
            .then((response) => {
                let { data } = response;
                // console.log("applicantStatus-------------------------------------------response-----------", jobId, response);
                if (response.status === 200 || response.status === 201) {
                    setApplicantStatus(data?.status);
                } else {
                    alert.error(FAILED_TO_FETCH_DATA);
                }
            })
            .catch((error) => {
                console.log("Error while getting connection status: ", error);
            });

    };

    const getJobApplyAction = (applicantStatus) => {
        let possibleApplicationStatus = ["APPLIED", "SHORTLISTED", "INTERVIEW", "OFFERED", "ACCEPTED", "ON_HOLD", "HIRED", "REJECTED", "CLOSED"];

        if (isPractitioner()) {
            if (JobDetails?.creatorProfile === null) {
                return (
                    <>
                        {
                            possibleApplicationStatus.includes(applicantStatus)
                                ?
                                <div className="action job-apply-btns">
                                    <span className="btn applied-btn">
                                        {"Applied"}</span>
                                </div>
                                :
                                <div className="action job-apply-btns">
                                    <span className="btn btn-link nex-apply-btn font-weight-bold"
                                        onClick={() => {
                                            if (jobFullDetails?.applyMode === 'EMAIL') {
                                                toggleForm();
                                            } else {
                                                window.open(jobFullDetails?.linkToApply, "_blank");
                                            }
                                        }}
                                    >Apply</span>
                                </div>
                        }
                    </>
                );
            }

            else {
                if (JobDetails?.creatorProfile?.profileGuid !== currentUser?.guid) {
                    return (
                        <>
                            {
                                possibleApplicationStatus.includes(applicantStatus)
                                    ?
                                    <div className="action job-apply-btns">
                                        <span className="btn applied-btn">
                                            {"Applied"}</span>
                                    </div>
                                    :
                                    <div className="action job-apply-btns">
                                        <span className="btn btn-link nex-apply-btn font-weight-bold"
                                            onClick={() => {
                                                if (jobFullDetails?.applyMode === 'EMAIL') {
                                                    toggleForm();
                                                } else {
                                                    window.open(jobFullDetails?.linkToApply, "_blank");
                                                }
                                            }}
                                        >Apply</span>
                                    </div>
                            }
                        </>
                    );
                }
            }
        }
    }

    useEffect(() => {
        getJobDetails(JobDetails?.id);
        getApplicantStatus(JobDetails?.id);
    }, [JobDetails?.id])

    return (
        <>
            <tr key={index}>
                <td>
                    <h4 className="link-text">
                        <Link
                            to={`/jobs/${encodeURIComponent(JobDetails?.profileId)}`}
                            onClick={() => window.scrollTo(0, 0)}
                            className="title-txt"
                        >
                            {JobDetails?.title}

                        </Link>
                    </h4>
                </td>
                <td>
                    <LinesEllipsis
                        className="card-text"
                        text={JobDetails?.primarySpeciality}
                        maxLine="1"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                    />
                </td>
                <td>
                    <LinesEllipsis
                        className="card-text"
                        text={JobDetails?.institutionDetails?.name}
                        maxLine="1"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                    />
                </td>
                <td>
                    <LinesEllipsis
                        className="card-text"
                        text={<ShowExperience min={JobDetails?.minExperience} max={JobDetails?.maxExperience} />}
                        maxLine="1"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                    />
                </td>
                <td>
                    <LinesEllipsis
                        className="card-text"
                        text={JobDetails?.city}
                        maxLine="1"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                    />
                </td>
                <td>
                    <LinesEllipsis
                        className="card-text"
                        text={getPosition(JobDetails?.position)}
                        maxLine="1"
                        ellipsis="..."
                        trimRight
                        basedOn="letters"
                    />
                </td>

                <td>
                    {jobFullDetails.id && getJobApplyAction(applicantStatus)}
                </td>
            </tr>

            <Modal
                isOpen={modal}
                toggle={toggleForm}
                backdrop="static"
                scrollable
                centered
            >
                <ModalHeader toggle={toggleForm}><span style={{ color: "gray" }}>Apply to</span> <span style={{ fontWeight: "400" }}>{JobDetails?.title} , {JobDetails?.institutionDetails?.name}</span></ModalHeader>
                <ModalBody>
                    <Apply practitioner={currentUser}
                        jobDet={jobFullDetails}
                        getJob={getApplicantStatus}
                        toggle={toggleForm}
                        getApplicantStatus={getApplicantStatus}
                    />
                </ModalBody>
            </Modal>
        </>
    )

}


const AllJobsTabularView = (props) => {
    const currentUser = useSelector((state) => state.auth.currentUser);
    const { theader, tdata } = props;
    return (
        <div className="jobs-table">
            <table className="table border" >
                <thead>
                    <tr>
                        {theader.map((columns, index) => (
                            <th className="theader" key={index}>{columns}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tdata.map((JobDetails, index) => (
                        <JobTabularView JobDetails={JobDetails} index={JobDetails.id} currentUser={currentUser} />
                    ))
                    }
                </tbody>
            </table>
        </div>
    )
}

export default AllJobsTabularView