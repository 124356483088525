import React from "react"
import { Card} from "reactstrap"
import { createImageFromInitials, getAge, getFullNameOfPerson, getRandomColor } from "utils/Utils"
import { encrypt } from "utils/EncryptDecrypt"

export const RelationInfo = (props) => {
    const { relation } = props;    

    return (
        <>
            <Card className="nexogic-card-list-custom nexogic-so-list-card nexogic-patient-card">
                <div className="card-body">
                    <div className="row need-margin">
                        <div className="col patient-info">
                            <div className="head">
                                <div className="card-graphic"><img src={createImageFromInitials(100, getFullNameOfPerson(relation?.firstName, relation?.middleName, relation?.lastName), getRandomColor(encrypt(21).toString()))} className="rounded-circle" width="70" height="70" alt="" /></div>
                                <div className="info">

                                    <p className="card-text"><b>Name  </b>
                                        {getFullNameOfPerson(relation?.firstName, relation?.middleName, relation?.lastName)}
                                    </p>

                                    <p className="card-text"><b>Phone  </b> {relation.cellularPhoneNumber ? relation.cellularPhoneNumber : 'Not Available'}</p>

                                </div>
                            </div>
                        </div>
                        <div className="col patient-info">

                            <div className="head">
                                <div className="info">
                                    {relation && relation?.dateOfBirth &&
                                        <p className="card-text"><b>Age  </b>   {relation?.dateOfBirth && getAge(relation?.dateOfBirth)}</p>
                                    }
                                    <p className="card-text"><b>Gender  </b> {relation.gender === 'M' ? 'Male' : ''} {relation.gender === 'F' ? 'Female' : ''}</p>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </Card >
        </>
    )
}

export default RelationInfo