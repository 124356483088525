import React, { useState, useEffect } from "react"
import { Card, Row, Col,  CardBody } from 'reactstrap'
import Rating from 'react-rating'
import { Link } from 'react-router-dom'
import API from "utils/API"
import API_New from "utils/API_New"
import { shuffle, remove } from "lodash"
import dropdownIcon from 'assets/images/dropdown_icon.png';
import AddUserMale from "assets/images/svgs/add-user-male.svg"
import {  encrypt } from "utils/EncryptDecrypt"
import { getProfilePicUrl, createImageFromInitials, getRandomColor } from "utils/Utils";

export const  DoctorsCard = (props) => {
    const { item } = props 
    const { title, profilePicUrl, firstName, lastName, primarySpeciality, overallRatings, city } = item
    const username = `${firstName} ${lastName}`
    let practitionerId = encrypt(item.practitionerId)
    return (
        <div className="suggested-profiles d-flex">
          <div className="sugg-user-image p-1">
            <img className="profile-pic" src={profilePicUrl?getProfilePicUrl(profilePicUrl):createImageFromInitials(50, username, getRandomColor(encrypt(practitionerId).toString()))} />
          </div>
          <div className="ml-2 w-100">
            <div className="d-flex align-items-center">
              <div className="user-detail">
                <div className="user-name">{`${!title?"":title} ${username}`}</div>
                <div className="user-hospital mt-1">{primarySpeciality && primarySpeciality.length > 0 ? primarySpeciality[0] + ' - Penn Hospital' : ''}</div>
                <div className="user-city">{city !== null ? city : ''}</div>
              </div>
              <div className="ml-auto">
                <Link to={`/profile/${practitionerId}`} className="color-link round-icon-btn rounded-circle">
                  <img src={AddUserMale} />
                </Link>
              </div>
            </div>
            <div className="divider my-3"></div>    
          </div>
        </div>
    )    
}

export const SuggestedDoctors = (props) => {
  const [doctorsList, setDoctorsList ] = useState([]);
  const [allDoctorsList, setAllDoctorsList ] = useState([]);
  const { practitionerId } = props;
  const [showMore, setShowMore] = useState(false);
  const HandleShowMore = () => {
    if(doctorsList.length < allDoctorsList.length) {
      setDoctorsList(allDoctorsList.slice(0, doctorsList.length+3))
    }
    // setShowMore(!showMore);
  } 
  useEffect(() => {
    const getDoctorsList = async () => {
        try {
            const response = await API_New.get(`/practitioner-service/practitioner/${practitionerId}/suggested/connections`);
            const { data } = response
            if (data && data.data.suggestions.length>0) {
              const { suggestions } = data.data
              setDoctorsList(suggestions.slice(0, 6))
              setAllDoctorsList(suggestions)
            }
        }
        catch (e) {
            console.log(e.message)
        }
    }

    getDoctorsList()
}, [practitionerId]);

  const RenderDoctorsCard = () => {
     let rendomList = shuffle(doctorsList)
     rendomList = remove(rendomList, (obj) => {
      return obj.id !== props.practitionerId
    });
    return (
      <>
        { 
          showMore ? 
          rendomList.map((item, index)=>{
            return <DoctorsCard key={`suggected_doc_${item.practitionerId}`} item={item}/>
          }) : rendomList.slice(0,8).map((item, index)=>{
            return <DoctorsCard key={`suggected_doc_${item.practitionerId}`} item={item}/>
          })
        }
      </>
    )
     
  }

    if(doctorsList.length){
    return (
      <>
        <Card className="doctors-details bg-white practitioner-suggestion-box">
          <CardBody>
            <div className="section-title m-0">Invite Followers</div>
            <div className="suggested-users mt-3">
              <RenderDoctorsCard doctorsList={doctorsList} practitionerId={practitionerId} />
            </div>
            <hr className="mt-2 suggestion-divider" />
            
            {(allDoctorsList.length > doctorsList.length) ?
                <div className="d-flex justify-content-center align-items-center pt-2" onClick={() => HandleShowMore()}>
                {
                    (!showMore) ?
                    <>
                        <div className="show-more-txt mr-2">Show more</div>
                        <div className=""><img src={dropdownIcon} /></div>
                    </>  :
                    null
                }
                </div>
                : null
            }
          </CardBody>
        </Card>
      </>    
      )
    }

  return null

}