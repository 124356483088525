import React from "react";
import { useState } from "react";
import { Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Row } from "reactstrap";
import left_video_graphic from 'assets/images/appointment-booking-undraw.png'
import PersonalDetailsForm from "./PersonalDetailsForm";
import MedicalHistoryForm from "./MedicalHistoryForm";
import DocumentForm from "./DocumentForm";
import PractitionerForm from "./PractitionerForm";
import PaymentForm from "./PaymentForm";
import { useEffect } from "react";
import { OpinionService } from "utils/OpinionService";

const SecondOpinionCreation = (props) => {
    const { opinionId } = props.match.params;
    const [forUpdate, setForUpdate] = useState(false);
    const [isSelf, setIsSelf] = useState(null);
    const [opinionInfo, setOpinionInfo] = useState({});
    const [selectedSpeciality, setSelectedSpeciality] = useState(null);

    const [opinionId1, setOpinionId1] = useState(null);
    const [currentStepInMainForm, setCurrentStepInMainForm] = useState('1');

    useEffect(() => {
        if (opinionId && opinionId.length > 10) {
            setForUpdate(true);
            setOpinionId1(opinionId);
            getOpinionInfo(opinionId);
        }
    }, [opinionId])

    const getOpinionInfo = (id) => {
        OpinionService.getOpinion(id)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    setOpinionInfo(data);
                    setForUpdate(true);
                    if (data?.speciality && data?.speciality !== "")
                        setSelectedSpeciality(data?.speciality);

                    if (data?.requestedBy === data?.requestedFor)
                        setIsSelf(true);
                    else
                        setIsSelf(false);
                }
            })
    }


    return (
        <div className="nex-second-opinion-wrap">
            <Row>
                <Col className="nex-left-sidebar">
                    <Card className="nex-steps-lists-card">
                        <CardBody>
                            {/* indicator lists */}
                            <ListGroup className="nex-steps-lists">
                                <ListGroupItem className={`${currentStepInMainForm === '1' ? 'current' : ''} disabled`} ><span className="number">1</span>Personal Details</ListGroupItem>
                                <ListGroupItem className={`${currentStepInMainForm === '2' ? 'current' : ''} disabled`} ><span className="number">2</span>Medical History</ListGroupItem>
                                <ListGroupItem className={`${currentStepInMainForm === '3' ? 'current' : ''} disabled`} ><span className="number">3</span>Select Practitioner</ListGroupItem>
                                <ListGroupItem className={`${currentStepInMainForm === '4' ? 'current' : ''} disabled`} ><span className="number">4</span>Upload Documents</ListGroupItem>
                                {/* <ListGroupItem className={`${currentStepInMainForm === '5' ? 'current' : ''} disabled`} ><span className="number">5</span>Payments</ListGroupItem> */}
                            </ListGroup>
                        </CardBody>
                    </Card>
                    <Card className="nex-tips-card visible-in-desktop">
                        <CardHeader>
                            <div className="icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="none"
                                    viewBox="0 0 20 20"
                                >
                                    <path
                                        stroke="#3EABCA"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M1 10h1m8-9v1m8 8h1M3.6 3.6l.7.7m12.1-.7l-.7.7M7.7 15h4.6M7 14a5 5 0 116 0 3.5 3.5 0 00-1 3 2 2 0 01-4 0 3.5 3.5 0 00-1-3z"
                                    ></path>
                                </svg>
                            </div>
                            Video Guide</CardHeader>
                        <CardBody>
                            <img src={left_video_graphic} alt="" />

                        </CardBody>
                    </Card>
                </Col>
                <Col className="nex-main-content-area">
                    {/** form wrap */}
                    <Card className="nex-second-opt-form mb-4">

                        {/* step 1 */}
                        <CardBody className={`nex-form-step ${currentStepInMainForm == 1 ? 'active' : ''}`}>
                            <PersonalDetailsForm
                                forUpdate={forUpdate}
                                setForUpdate={setForUpdate}
                                opinionInfo={opinionInfo}
                                isSelf={isSelf}
                                setIsSelf={setIsSelf}
                                opinionId={opinionId1}
                                setOpinionId={setOpinionId1}
                                currentStepInMainForm={currentStepInMainForm}
                                setCurrentStepInMainForm={setCurrentStepInMainForm}
                                setSelectedSpeciality={setSelectedSpeciality}
                                getOpinionInfo={getOpinionInfo}
                            />
                        </CardBody>

                        {/* step 2 */}
                        <CardBody className={`nex-form-step ${currentStepInMainForm == 2 ? 'active' : ''}`}>
                            <MedicalHistoryForm
                                forUpdate={forUpdate}
                                opinionInfo={opinionInfo}
                                opinionId={opinionId1}
                                currentStepInMainForm={currentStepInMainForm}
                                setCurrentStepInMainForm={setCurrentStepInMainForm}
                                getOpinionInfo={getOpinionInfo}
                            />
                        </CardBody>

                        {/* step 3 */}
                        <CardBody className={`nex-form-step ${currentStepInMainForm == 3 ? 'active' : ''}`}>
                            <PractitionerForm
                                forUpdate={forUpdate}
                                opinionInfo={opinionInfo}
                                opinionId={opinionId1}
                                currentStepInMainForm={currentStepInMainForm}
                                setCurrentStepInMainForm={setCurrentStepInMainForm}
                                selectedSpeciality={selectedSpeciality}
                                getOpinionInfo={getOpinionInfo}
                            />
                        </CardBody>

                        {/* step 4 */}
                        <CardBody className={`nex-form-step ${currentStepInMainForm == 4 ? 'active' : ''}`}>
                            <DocumentForm
                                forUpdate={forUpdate}
                                opinionInfo={opinionInfo}
                                opinionId={opinionId1}
                                currentStepInMainForm={currentStepInMainForm}
                                setCurrentStepInMainForm={setCurrentStepInMainForm}
                                getOpinionInfo={getOpinionInfo}
                            />
                        </CardBody>

                        {/* step 5 */}
                        {/* <CardBody className={`nex-form-step ${currentStepInMainForm == 5 ? 'active' : ''}`}>
                            <PaymentForm
                                opinionId={opinionId1}
                                currentStepInMainForm={currentStepInMainForm}
                                setCurrentStepInMainForm={setCurrentStepInMainForm}
                                getOpinionInfo={getOpinionInfo}
                            />
                        </CardBody> */}

                    </Card>
                    {/* video guid */}
                    <Card className="nex-tips-card visible-in-mobile">
                        <CardHeader>
                            <div className="icon">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    fill="none"
                                    viewBox="0 0 20 20"
                                >
                                    <path
                                        stroke="#3EABCA"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M1 10h1m8-9v1m8 8h1M3.6 3.6l.7.7m12.1-.7l-.7.7M7.7 15h4.6M7 14a5 5 0 116 0 3.5 3.5 0 00-1 3 2 2 0 01-4 0 3.5 3.5 0 00-1-3z"
                                    ></path>
                                </svg>
                            </div>
                            Video Guide</CardHeader>
                        <CardBody>
                            <img src={left_video_graphic} alt="" />

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}
export default SecondOpinionCreation;