import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const SymptomsModalForm = (props) => {
    const multiSelectRef = useRef(null);

    const symptoms_options = [
        { "label": "Fever", "value": "Fever" },
        { "label": "Cough", "value": "Cough" },
        { "label": "Shortness of breath", "value": "Shortness of breath" },
        { "label": "Fatigue", "value": "Fatigue" },
        { "label": "Headache", "value": "Headache" },
        { "label": "Muscle aches", "value": "Muscle aches" },
        { "label": "Joint pain", "value": "Joint pain" },
        { "label": "Nausea", "value": "Nausea" },
        { "label": "Vomiting", "value": "Vomiting" },
        { "label": "Diarrhea", "value": "Diarrhea" },
        { "label": "Chest pain", "value": "Chest pain" },
        { "label": "Abdominal pain", "value": "Abdominal pain" },
        { "label": "Dizziness", "value": "Dizziness" },
        { "label": "Rash", "value": "Rash" },
        { "label": "Swelling", "value": "Swelling" },
        { "label": "Difficulty swallowing", "value": "Difficulty swallowing" },
        { "label": "Vision changes", "value": "Vision changes" },
        { "label": "Bleeding", "value": "Bleeding" },
        { "label": "Bruising", "value": "Bruising" },
        { "label": "Memory loss", "value": "Memory loss" },
        { "label": "Anxiety", "value": "Anxiety" },
        { "label": "Depression", "value": "Depression" },
        { "label": "Sleep disturbances", "value": "Sleep disturbances" },
        { "label": "Chest tightness", "value": "Chest tightness" },
        { "label": "Sore throat", "value": "Sore throat" },
        { "label": "Nasal congestion", "value": "Nasal congestion" },
        { "label": "Watery eyes", "value": "Watery eyes" },
        { "label": "Sneezing", "value": "Sneezing" },
        { "label": "Difficulty breathing", "value": "Difficulty breathing" },
        { "label": "Wheezing", "value": "Wheezing" },
        { "label": "Loss of taste or smell", "value": "Loss of taste or smell" },
        { "label": "Chest pressure", "value": "Chest pressure" },
        { "label": "Heart palpitations", "value": "Heart palpitations" },
        { "label": "Difficulty concentrating", "value": "Difficulty concentrating" },
        { "label": "Speech difficulties", "value": "Speech difficulties" },
        { "label": "Tremors", "value": "Tremors" },
        { "label": "Balance problems", "value": "Balance problems" },
        { "label": "Muscle weakness", "value": "Muscle weakness" },
    ];



    return (
        <Modal isOpen={props.modal} toggle={props.toggle} {...props} className="so_mh_modal_popup">
            <ModalHeader toggle={props.toggle}>Symptoms</ModalHeader>
            <ModalBody>
                <div className="nex-modal-select-drops-wrap nex-symptoms-sdrops-w" ref={multiSelectRef} >

                    <MultiSelect

                        options={symptoms_options}
                        value={props.symptoms_selected}
                        onChange={props.setSymptoms_selected}
                        labelledBy="Select"
                    />
                </div>
                <div className="nex-modal-actions mt-3 mb-2 d-flex justify-content-end">
                    <a href="#" className="nexogic_primary_button" onClick={(e) => {
                        props.toggle();
                    }}>Close</a>
                </div>
            </ModalBody>

        </Modal>
    )
}
export default SymptomsModalForm;