import React from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import LinesEllipsis from 'react-lines-ellipsis';
import ConnectButton from 'pages/FeedHomePage/components/PractitionerProfiles/ConnectButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';


const PostedJobsTabularView = (props) => {
    const currentUser = useSelector((state) => state.auth.currentUser);
    const { theader, tdata, viewmode } = props;
    return (
        <div className="profiles-table">
            <table className="table border" >
                <thead>
                    <tr>
                        {theader.map((columns, index) => (
                            <th className="theader" key={index}>{columns}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tdata.map((profileDetails, index) => (
                        <tr key={`profile_${index}`}>

                            <td className="border font-weight-bold">
                                <div className=' d-flex'>
                                    <h4 className="link-text">
                                        <Link to={`/jobs/${profileDetails?.profileId}`} style={{ color: "#2eabca" }} onClick={() => window.scrollTo(0, 0)}>
                                            {profileDetails.title}
                                        </Link>
                                    </h4>
                                </div>
                            </td>

                            <td className="border font-weight-bold">
                                <div className=' d-flex'>
                                    <h4 className="link-text">
                                        <Link to={`/institution/${profileDetails?.institutionDetails?.profileId}`} style={{ color: "#2eabca" }} onClick={() => window.scrollTo(0, 0)}>
                                            {profileDetails?.institutionDetails?.name}
                                        </Link>
                                    </h4>
                                </div>
                            </td>

                            <td className="border">
                                {typeof profileDetails.primarySpeciality != "undefined" &&
                                    <LinesEllipsis
                                        className="card-text"
                                        text={profileDetails.primarySpeciality}
                                        maxLine="1"
                                        ellipsis="..."
                                        trimRight
                                        basedOn="letters"
                                    />
                                }
                            </td>
                            <td className="border">
                                <LinesEllipsis
                                    className="card-text"
                                    text={profileDetails.city}
                                    maxLine="1"
                                    ellipsis="..."
                                    trimRight
                                    basedOn="letters"
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default PostedJobsTabularView