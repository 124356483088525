import moment from "moment";
import { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import "../style.scss";
import clock_icon from "assets/images/small_clock_icon.png";

const SingleTimeSlot = (props) => {
  const { schedules } = props;
  const [showAll, setShowAll] = useState(false);

  function sortItByTiming(timePeriods) {
    if (timePeriods.length > 1) {
      timePeriods.sort((a, b) => {
        const timeA = new Date(`1970-01-01 ${a.startTime}`);
        const timeB = new Date(`1970-01-01 ${b.startTime}`);
        return timeA - timeB;
      });
      return timePeriods;
    }
    else {
      return timePeriods;
    }
  }

  return (
    <>
      <h3 className="card-title">
        <img className="icon" src={clock_icon} /> &nbsp;Consulting Hours  <a className="readmore" onClick={() => setShowAll(!showAll)}> {showAll ? 'Show Less' : 'Show More'} </a>
      </h3>
      {showAll ?
        <div>
          <div className="item card-text">
            <small className="date text-muted">MON</small>
            <small className="times text-muted">
              {schedules?.MONDAY
                ?
                sortItByTiming(schedules?.MONDAY).map((e,index) => (
                  <div className="time" key={index}>
                    {moment(e?.startTime, "HHmmss").format(
                      "hh:mm A"
                    )}{" "}
                    -{" "}
                    {moment(e?.endTime, "HHmmss").format(
                      "hh:mm A"
                    )}
                  </div>
                ))
                : "Close"
              }
            </small>
          </div>
          <div className="item card-text">
            <small className="date text-muted">TUE</small>
            <small className="times text-muted">
              {schedules?.TUESDAY
                ?
                sortItByTiming(schedules?.TUESDAY).map((e,ind) => (
                  <div className="time" key={ind}>
                    {moment(e?.startTime, "HHmmss").format(
                      "hh:mm A"
                    )}{" "}
                    -{" "}
                    {moment(e?.endTime, "HHmmss").format(
                      "hh:mm A"
                    )}
                  </div>
                ))
                : "Close"
              }
            </small>
          </div>
          <div className="item card-text">
            <small className="date text-muted">WED</small>
            <small className="times text-muted">
              {schedules?.WEDNESDAY
                ?
                sortItByTiming(schedules?.WEDNESDAY).map((e,ind) => (
                  <div className="time" key={ind}>
                    {moment(e?.startTime, "HHmmss").format(
                      "hh:mm A"
                    )}{" "}
                    -{" "}
                    {moment(e?.endTime, "HHmmss").format(
                      "hh:mm A"
                    )}
                  </div>
                ))
                : "Close"
              }
            </small>
          </div>
          <div className="item card-text">
            <small className="date text-muted">THU</small>
            <small className="times text-muted">
              {schedules?.THURSDAY
                ?
                sortItByTiming(schedules?.THURSDAY).map((e,ind) => (
                  <div className="time" key={ind}>
                    {moment(e?.startTime, "HHmmss").format(
                      "hh:mm A"
                    )}{" "}
                    -{" "}
                    {moment(e?.endTime, "HHmmss").format(
                      "hh:mm A"
                    )}
                  </div>
                ))
                : "Close"
              }
            </small>
          </div>
          <div className="item card-text">
            <small className="date text-muted">FRI</small>
            <small className="times text-muted">
              {schedules?.FRIDAY
                ?
                sortItByTiming(schedules?.FRIDAY).map((e,ind) => (
                  <div className="time" key={ind}>
                    {moment(e?.startTime, "HHmmss").format(
                      "hh:mm A"
                    )}{" "}
                    -{" "}
                    {moment(e?.endTime, "HHmmss").format(
                      "hh:mm A"
                    )}
                  </div>
                ))
                : "Close"
              }
            </small>
          </div>
          <div className="item card-text">
            <small className="date text-muted">SAT</small>
            <small className="times text-muted">
              {schedules?.SATURDAY
                ?
                sortItByTiming(schedules?.SATURDAY).map((e,ind) => (
                  <div className="time" key={ind}>
                    {moment(e?.startTime, "HHmmss").format(
                      "hh:mm A"
                    )}{" "}
                    -{" "}
                    {moment(e?.endTime, "HHmmss").format(
                      "hh:mm A"
                    )}
                  </div>
                ))
                : "Close"
              }
            </small>
          </div>
          <div className="item card-text">
            <small className="date text-muted">SUN</small>
            <small className="times text-muted">
              {schedules?.SUNDAY
                ?
                sortItByTiming(schedules?.SUNDAY).map((e,ind) => (
                  <div className="time" key={ind}>
                    {moment(e?.startTime, "HHmmss").format(
                      "hh:mm A"
                    )}{" "}
                    -{" "}
                    {moment(e?.endTime, "HHmmss").format(
                      "hh:mm A"
                    )}
                  </div>
                ))
                : "Close"
              }
            </small>
          </div>
        </div>
        :
        <div>
          <div>
            {(new Date().getDay() === 0) &&
              <div className="item card-text">
                <small className="date text-muted">SUN</small>
                <small className="times text-muted">
                  {schedules?.SUNDAY
                    ?
                    sortItByTiming(schedules?.SUNDAY).map((e,ind) => (
                      <div className="time" key={ind}>
                        {moment(e?.startTime, "HHmmss").format(
                          "hh:mm A"
                        )}{" "}
                        -{" "}
                        {moment(e?.endTime, "HHmmss").format(
                          "hh:mm A"
                        )}
                      </div>
                    ))
                    : "Close"
                  }
                </small>
              </div>
            }
            {(new Date().getDay() === 1) &&
              <div className="item card-text">
                <small className="date text-muted">MON</small>
                <small className="times text-muted">
                  {schedules?.MONDAY
                    ?
                    sortItByTiming(schedules?.MONDAY).map((e,ind) => (
                      <div className="time" key={ind}>
                        {moment(e?.startTime, "HHmmss").format(
                          "hh:mm A"
                        )}{" "}
                        -{" "}
                        {moment(e?.endTime, "HHmmss").format(
                          "hh:mm A"
                        )}
                      </div>
                    ))
                    : "Close"
                  }
                </small>
              </div>
            }
            {(new Date().getDay() === 2) &&
              <div className="item card-text">
                <small className="date text-muted">TUE</small>
                <small className="times text-muted">
                  {schedules?.TUESDAY
                    ?
                    sortItByTiming(schedules?.TUESDAY).map((e,ind) => (
                      <div className="time" key={ind}>
                        {moment(e?.startTime, "HHmmss").format(
                          "hh:mm A"
                        )}{" "}
                        -{" "}
                        {moment(e?.endTime, "HHmmss").format(
                          "hh:mm A"
                        )}
                      </div>
                    ))
                    : "Close"
                  }
                </small>
              </div>
            }
            {(new Date().getDay() === 3) &&
              <div className="item card-text">
                <small className="date text-muted">WED</small>
                <small className="times text-muted">
                  {schedules?.WEDNESDAY
                    ?
                    sortItByTiming(schedules?.WEDNESDAY).map((e,ind) => (
                      <div className="time" key={ind}>
                        {moment(e?.startTime, "HHmmss").format(
                          "hh:mm A"
                        )}{" "}
                        -{" "}
                        {moment(e?.endTime, "HHmmss").format(
                          "hh:mm A"
                        )}
                      </div>
                    ))
                    : "Close"
                  }
                </small>
              </div>
            }
            {(new Date().getDay() === 4) &&
              <div className="item card-text">
                <small className="date text-muted">THU</small>
                <small className="times text-muted">
                  {schedules?.THURSDAY
                    ?
                    sortItByTiming(schedules?.THURSDAY).map((e,ind) => (
                      <div className="time" key={ind}>
                        {moment(e?.startTime, "HHmmss").format(
                          "hh:mm A"
                        )}{" "}
                        -{" "}
                        {moment(e?.endTime, "HHmmss").format(
                          "hh:mm A"
                        )}
                      </div>
                    ))
                    : "Close"
                  }
                </small>
              </div>
            }
            {(new Date().getDay() === 5) &&
              <div className="item card-text">
                <small className="date text-muted">FRI</small>
                <small className="times text-muted">
                  {schedules?.FRIDAY
                    ?
                    sortItByTiming(schedules?.FRIDAY).map((e,ind) => (
                      <div className="time" key={ind}>
                        {moment(e?.startTime, "HHmmss").format(
                          "hh:mm A"
                        )}{" "}
                        -{" "}
                        {moment(e?.endTime, "HHmmss").format(
                          "hh:mm A"
                        )}
                      </div>
                    ))
                    : "Close"
                  }
                </small>
              </div>
            }
            {(new Date().getDay() === 6) &&
              <div className="item card-text">
                <small className="date text-muted">SAT</small>
                <small className="times text-muted">
                  {schedules?.SATURDAY
                    ?
                    sortItByTiming(schedules?.SATURDAY).map((e,ind) => (
                      <div className="time" key={ind}>
                        {moment(e?.startTime, "HHmmss").format(
                          "hh:mm A"
                        )}{" "}
                        -{" "}
                        {moment(e?.endTime, "HHmmss").format(
                          "hh:mm A"
                        )}
                      </div>
                    ))
                    : "Close"
                  }
                </small>
              </div>
            }
          </div>
        </div>
      }
    </>
  )
};

export default withAlert()(SingleTimeSlot);
