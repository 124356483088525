import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faListAlt } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Card, Col } from 'reactstrap';
import { useState } from 'react';
import { useEffect } from 'react';
import MyOpinionInfo from './MyOpinionInfo';
import { OpinionService } from 'utils/OpinionService';
import { FAILED_TO_FETCH_DATA } from 'utils/Constants';
import { useSelector } from 'react-redux';
import CommonLoader from 'common/CommonLoader';
import { withAlert } from 'react-alert';
import LoadMoreButton from 'common/LoadMoreButton/LoadMoreButton';
import MySecondOpinionsTabularView from 'components/tabularView/MySecondOpinionsTabularView';
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import { toast } from 'react-toastify';

export default withAlert()(function MySecondOpinionLists(props) {
    const currentUser = useSelector((state) => state.auth.currentUser);
    const [opinionsList, setOpinionsList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedStatusFilters, setSelectedStatusFilters] = useState([
        'DRAFT', 'SUBMITTED', 'REJECTED', 'ASSIGNED', 'RESPONDED', 'CLOSED'
    ]);
    const [showCreateButton, setShowCreateButton] = useState(false);

    const [viewMode, setViewMode] = useState("card");
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        getOpinions(selectedStatusFilters, 0);
        checkOpinionCountInDraft();
    }, [selectedStatusFilters])

    const loadMore = () => {
        setLoadingMore(true);
        getOpinions(selectedStatusFilters, currentPage + 1)
    };

    const handleStatusFilterChange = (status) => {
        setSelectedStatusFilters((prevFilters) => {
            if (prevFilters.includes(status)) {
                return prevFilters.filter((filter) => filter !== status);
            } else {
                return [...prevFilters, status];
            }
        });
    };

    function createQuery(filters) {
        let sq = "";
        filters.map((e) => {
            if (sq === "") {
                sq = "st=='" + e + "'";
            } else {
                sq += ",st=='" + e + "'";
            }
        })
        return sq;
    }

    const checkOpinionCountInDraft = (data) => {
        OpinionService.getOpinions('st=="DRAFT"', 0)
            .then((response) => {
                const { status, data } = response;
                if (data.totalItems > 0) {
                    setShowCreateButton(false);
                } else {
                    setShowCreateButton(true);
                }
                console.log(data);
            })
    }

    const getOpinions = (filters, cp = 0) => {
        let fsq = createQuery(filters);
        let sq = `(rb==${currentUser?.profileId})`;
        if (fsq !== "")
            sq += `;(${fsq})`;

        OpinionService.getOpinions(sq, cp)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    checkOpinionCountInDraft(data);
                    setTotalPages(data?.totalPages);
                    setTotalItems(data?.totalItems);
                    setCurrentPage(data?.currentPage);
                    setHasMore((data?.currentPage + 1) < data?.totalPages);
                    setLoadingMore(false)
                    if (cp === 0)
                        setOpinionsList(data?.contents);
                    else
                        setOpinionsList((contents) => [...contents, ...data?.contents]);
                    setIsLoading(false);
                } else {
                    showErrorMessage(FAILED_TO_FETCH_DATA)
                    setIsLoading(false);
                    setLoadingMore(false)
                }
            })
    }

    const renderProfiles = () => {
        if (viewMode === 'card') {
            return (
                <>
                    {(opinionsList && opinionsList.length > 0) ? opinionsList.map((e) => (
                        <MyOpinionInfo opinion={e} status={e?.status} />
                    ))

                        :
                        <div className="text-center">You have no Opinion</div>
                    }
                </>
            );
        } else { // For table view
            return (
                <MySecondOpinionsTabularView theader={['Case Id', 'Patient Name', 'Gender', 'Speciality', 'Status']} tdata={opinionsList} viewmode={viewMode} />
            );
        }
    };

    return (
        <div className="nexogic-second-option container-fluid">
            <div className="view-toggle nexogic-view-toggle mb-1">
                <div className="btns mb-2">
                    <FontAwesomeIcon title="Card View" color={viewMode === "card" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faAddressCard} onClick={() => setViewMode("card")} /> | <FontAwesomeIcon title="Table View" color={viewMode === "table" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faListAlt} onClick={() => setViewMode("table")} />
                </div>
            </div>
            <div className="row">
                <Col lg={12} className='visible-in-mobile mb-4 px-0'>
                    <Card>
                        {showCreateButton ?
                            <Link to={"/u/medicalcases/second-opinion/create"} className="nexogic_primary_button_outline d-block align-items-center px-2">
                                Create Second Opinion Request
                            </Link>
                            :
                            <span className="nexogic_primary_button_outline d-block align-items-center px-2"
                                onClick={() => {
                                    toast.info("Oops! Free plan users are limited to having only one request in DRAFT status at a time");
                                }}
                            >
                                Create Second Opinion Request
                            </span>
                        }
                    </Card>
                </Col>
                <Col lg={3} className='px-mobile-0'>
                    <div className="nexogic-sidebar-stiky">
                        <div className="nexogic-widget-sidebar-card card border-0 nex-second-opinion ">
                            <h3 className="card-title h2">Second Opinion</h3>
                            <div className="card-body p-0">
                                <ul className="nexogic-lists nexogic-custom-scroll">
                                    <li>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={selectedStatusFilters.length === 6} // All selected
                                                onChange={() =>
                                                    setSelectedStatusFilters(
                                                        selectedStatusFilters.length === 6 ? [] : ['DRAFT', 'SUBMITTED', 'REJECTED', 'ASSIGNED', 'RESPONDED', 'CLOSED']
                                                    )
                                                }
                                            />
                                            All
                                        </label>
                                    </li>
                                    {['DRAFT', 'SUBMITTED', 'REJECTED', 'ASSIGNED', 'RESPONDED', 'CLOSED'].map((status) => (
                                        <li key={status}>
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedStatusFilters.includes(status)}
                                                    onChange={() => handleStatusFilterChange(status)}
                                                />
                                                {status}
                                            </label>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <Card className='visible-in-desktop'>
                            {showCreateButton ?
                                <Link to={"/u/medicalcases/second-opinion/create"} className="nexogic_primary_button_outline d-block align-items-center px-2">
                                    Create Second Opinion Request
                                </Link>
                                :
                                <span className="nexogic_primary_button_outline d-block align-items-center px-2"
                                    onClick={() => {
                                        toast.info("Oops! Free plan users are limited to having only one request in DRAFT status at a time");
                                    }}
                                >
                                    Create Second Opinion Request
                                </span>
                            }
                        </Card>
                    </div>
                </Col>

                {isLoading ?
                    <Col lg={9} className="nexogic-second-option-left">
                        <div className="loading-spiner text-center">
                            <CommonLoader />
                        </div>
                    </Col>
                    :
                    <Col lg={9} className="nexogic-second-option-left">
                        {renderProfiles()}

                        {hasMore &&
                            < div className="text-center mt-2">
                                {loadingMore ?
                                    <CommonLoader style={{ width: "2rem", height: "2rem" }} />
                                    :
                                    <LoadMoreButton loadMore={loadMore} />
                                }
                            </div>
                        }
                    </Col>
                }
            </div>
        </div>
    )
}
)
