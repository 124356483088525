import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import AppliedJobsContent from "./AppliedJobsContent";
import AllJobs from "./Components/AllJobs";
import CreateJobContainer from "./CreateJobContainer";
import PostedJobsContent from "./PostedJobsContent";

const JobsNavContent = () => {
  let { path, url } = useRouteMatch();
  return (
    <>
      <Switch>
        <Route path={`${url}/all-jobs`} component={AllJobs} />
        <Route path={`${url}/posted-jobs`} component={PostedJobsContent} />
        <Route path={`${url}/applied-jobs`} component={AppliedJobsContent} />
        <Route path={`${url}/post-a-job`} component={CreateJobContainer} />
        <Route path={`${url}/update-a-job/:jobId`} component={CreateJobContainer} />
        <Redirect exact to={`${url}/all-jobs`} /> 
      </Switch>
    </>
  );
};
export default JobsNavContent;
