import AlertDialog from "common/AlertDialog";
import { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { useSelector } from "react-redux";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { InstitutionServices } from "utils/InstitutionServices";
import { renderDateRange } from "utils/Utils";
import { RenderInstituteAwardModal } from "./components/RenderInstituteAwardModal";
import edit_blue_icon from "assets/images/edit-blue-icon.png";
import del_red_icon from "assets/images/del-red-icon.png";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import awards_icon from "assets/svgIcon/award-icon.svg";
import plusIcon from "assets/images/plus-icon-lg.png";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
const RenderAwards = (props) => {
  const { awards, handleEditItem, handleRemoveItem, isEditable, isAdmin } =
    props;
  if (awards && awards.length > 0) {
    return awards.map((item, index) => {
      const { issuedYear, issuedMonth } = item;
      const timeDuration =
        issuedMonth && issuedYear
          ? renderDateRange(issuedMonth + "-" + issuedYear, "", false, "", true)
          : "";
      return (
        <li
          className="list-group-item bg-color"
          key={`award_${index}`}
        >
          <div className="row g-0 bg-color">
            <div className="col-auto">
              <img src={awards_icon} width="20" alt="#" />
            </div>
            <div className="col">
              <h3 className="card-text fw-semibold text-left mb-0">{item.awardName}</h3>
              <p className="card-text text-left mb-0">
                <small>{item.issuingAuthority ? item.issuingAuthority : null}</small>
              </p>
              <p className="card-text text-left mb-0">
                <small>{item.description ? item.description : null}</small>
              </p>
              <p className="card-text text-left mb-0">
                <small> Issued On: {timeDuration}</small>
              </p>
            </div>

            <div className="col-auto d-flex align-items-start col">
              <div className="delete-action">
                {isEditable &&
                  isAdmin && (
                    <div className="px-0">
                      <Button
                        color="link"
                        className=""
                        onClick={() => {
                          const {
                            issuedMonth,
                            issuedYear,
                            description,
                            awardName,
                            issuingAuthority,
                            guid,
                          } = item;
                          handleEditItem({
                            guid: guid ?? "",
                            awardName: awardName ?? "",
                            issuingAuthority: issuingAuthority ?? "",
                            description: description ?? "",
                            issuedMonthYear: issuedMonth + "-" + issuedYear ?? "",
                          });
                        }}
                      >
                        <img src={edit_blue_icon} alt="" />
                      </Button>

                      <Button
                        color="link-danger"
                        className="visible-hover py-0 text-danger"
                        onClick={() => handleRemoveItem(item)}
                      >
                        <img src={del_red_icon} alt="" />
                      </Button>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </li>
      );
    });
  }
  return "";
};

const InstitutionsAwardsUI = (props) => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  // const { alert, isEditable, isAdmin, institution, getInstitutionProfile, setShowAwardsOnTabScroll } =props;
  const { institution, getInstitutionProfile, themeDetails, previewFlag, alert, isEditable, isAdmin } = props;
  const [awards, setAwards] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [isOpen, setModal] = useState(false);
  const toggle = () => setModal(!isOpen);
  const [isOpenAlert, setAlertModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [pannel_on, setPannel_on] = useState(true);

  const sectionColors = (sThemeColor) => {
    // const frmDoc = document.documentElement.style.setProperty;
    document.documentElement.style.setProperty('--section-bg-color', sThemeColor[3]);
    document.documentElement.style.setProperty('--section-headerbg-color', sThemeColor[4]);
    document.documentElement.style.setProperty('--section-headerfont-color', sThemeColor[5]);
    document.documentElement.style.setProperty('--section-font-color', sThemeColor[2]);
    document.documentElement.style.setProperty('--section-heading-color', sThemeColor[1]);
  }
  
  useEffect(() => {
    if (themeDetails.length > 0) {
      sectionColors(themeDetails)
    }
  }, [themeDetails])

  const getlistOfawards = async () => {
    setLoading(true);
    const response = await InstitutionServices.awardsInstitution(
      institution?.guid
    );
    if (response.status === 200) {
      setAwards(response.data);
    }
    setLoading(false);
  };

  const toggleAlert = () => {
    setLoading(false);
    setAlertModal(!isOpenAlert);
  };
  const initialValue = {
    awardName: "",
    issuingAuthority: "",
    description: "",
    issuedMonthYear: "",
    guid: "",
  };

  const handleEditItem = (selectedItem) => {
    setSelectedItem(selectedItem);
    toggle();
  };

  const handleRemoveItem = (selectedItem) => {
    setSelectedItem(selectedItem);
    toggleAlert();
  };

  const handleClickOnYes = async () => {
    setLoading(true);
    try {
      const request = InstitutionServices.deleteAwardInstitution(
        institution.guid,
        selectedItem.guid
      );
      request.then(
        async (response) => {
          const { data, status } = response;
          if (status === 204) {
            getInstitutionProfile(institution.guid);
            setLoading(false);
            toggleAlert();
            showSuccessMessage("Award deleted successfully");
          } else {
            showErrorMessage(data.status?.message);
            setLoading(false);
            toggleAlert();
          }
        },
        (e) => {
          setLoading(false);
          let errmsg = e.message;
          if (e.response) {
            errmsg =
              e.response.data && !!e.response.data?.message
                ? e.response.data?.message
                : errmsg;
          }
          showErrorMessage(errmsg);
        }
      );
    } catch (e) {
      showErrorMessage(e.message);
      setLoading(false);
      toggleAlert();
    }
  };

  useEffect(() => {
    if (institution?.guid) {
      getlistOfawards();
    }
  }, [getInstitutionProfile, institution]);

  return (
    <Row>
      <Col lg={12} className="text-center px-3">
        <div id="section-services" className="m-v1">
          {((awards?.length > 0 && !isAdmin) || isAdmin) && (
            <Card className={`card nexogic-card-color-header ${pannel_on ? 'expanded' : ''} micro-section-card-vs1`}>
              <div className="card-header section-header"><h2>Awards</h2>
                <button className="collapse-btn" type="button" onClick={() => setPannel_on(!pannel_on)}>
                  <img src={pannel_arrow} alt="" />
                </button>
                {isEditable &&
                  isAdmin && (
                    <Button
                      id="nex-ip-awwords-plus-btn"
                      color="link"
                      className="add-button"
                      onClick={() => handleEditItem(initialValue)}
                    >
                      {/*<FontAwesomeIcon icon={faPlus} />*/}
                      <img src={plusIcon} alt="" />
                    </Button>

                  )}
              </div>
              <div className={`collapse-div`}>
                <CardBody className="bg-color">
                  {awards && awards?.length
                    ? ""
                    : isEditable &&
                    isAdmin && (
                      <p className="card-text">
                        <Button
                          id="ia_add_awords"
                          className="readmore"
                          color="link"
                          onClick={() => handleEditItem(initialValue)}
                        >
                          {"Add your awards"}
                        </Button>
                      </p>
                    )}
                  <ul className="list-group list-group-flush">
                    <RenderAwards
                      awards={awards}
                      handleEditItem={handleEditItem}
                      handleRemoveItem={handleRemoveItem}
                      isEditable={isEditable}
                      isAdmin={isAdmin}
                    />
                  </ul>
                  <RenderInstituteAwardModal
                    selectedItem={selectedItem}
                    toggle={toggle}
                    isOpen={isOpen}
                    getInstitutionProfile={getInstitutionProfile}
                    isAdmin={isAdmin}
                    institution={institution}
                    practitionerId={currentUser?.guid}
                    institutionId={institution?.guid}
                  />
                  <AlertDialog
                    toggle={toggleAlert}
                    isOpen={isOpenAlert}
                    isLoading={isLoading}
                    handleClickOnYes={handleClickOnYes}
                    yesLabel={"Yes"}
                    noLabel={"No"}
                  />
                </CardBody>
              </div>
            </Card>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default withAlert()(InstitutionsAwardsUI);
