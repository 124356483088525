import PeopleKnowWidget from "common/PeopleKnowWidget";
import { useSelector } from "react-redux";
import { Card, CardBody, Col, Row } from "reactstrap";
import PractitionerProfiles from "../PractitionerProfiles";
import { isPractitioner } from "utils/UserHelper";
import JobWidget from "common/JobWidget";
import referals_icon from "assets/images/svgs/referals-icon.svg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const PractitionerProfilesPage = () => {
    const currentUser = useSelector((state) => state.auth.currentUser);
    const isPractitionerEligibleForReferrals = useSelector(state => state.PreferenceFlags?.referalStatusFlag);
    const { primarySpeciality } = currentUser;

    return (
        <>
            <Row>
                <Col lg={isPractitioner() ? 9 : 12} md={isPractitioner() ? 9 : 12}>
                    <PractitionerProfiles />
                </Col>

                {isPractitioner() && isPractitionerEligibleForReferrals &&
                    <Col lg={3} md={3}>
                        <Card className="nexogic-widget-sidebar-card card nexogic-refaral-widget-card">
                            <h2 className="card-title h2">Refer a Patient</h2>
                            <CardBody>
                                <div className="graphic nex-avatar xl mx-auto">
                                    <img src={referals_icon} alt="" width={90} />
                                </div>
                                <h3 className="nex-sub-aside-title">Want to Refer a Patient ?</h3>
                                <p className="card-text">Ensure smooth care transitions. Provide medical records, reason for referral. Collaborate with colleagues for better patient outcomes. Let's enhance patient care together!.</p>
                                <p className="action pt-3 pb-3">
                                    <Link to="/referrals/refer-patient" href="" className="nexogic_primary_button_outline">Refer a Patient</Link>
                                </p>
                            </CardBody>
                        </Card>
                        {primarySpeciality &&
                            <>
                                <PeopleKnowWidget speciality={primarySpeciality} />
                                <JobWidget widgetTitle="Recommended Jobs" primarySpeciality={primarySpeciality} />
                            </>
                        }
                    </Col>
                }
            </Row>
        </>
    )
}

export default PractitionerProfilesPage;