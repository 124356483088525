import React,{useRef} from "react";
import {
  Button,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const AlertDialog = (props) => {
  const { isLoading, isOpen, toggle, yesLabel, noLabel, handleClickOnYes } =props;
    const modalRef = useRef();
  const noBtnProps = props.noProperties || {};
  const yesBtnProps = props.yesProperties || {};
  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} className="modal-sm"  innerRef={modalRef}>
        {props.headerTitle && (
          <ModalHeader toggle={toggle}>{props.headerTitle}</ModalHeader>
        )}
        <ModalBody>
          <h5 className="card-title nexogic-popup-big-txt">{props.bodyText || "Are you sure, you want to remove this?"}</h5>
        </ModalBody>
        <ModalFooter className="justify-content-end  modal-footer">
          <div className="action-group">
          <Button
            outline
            className="nexogic_primary_button_outline"
            color="primary"
            onClick={toggle}
            {...noBtnProps}
          >
            {noLabel}
          </Button>{" "}
          {isLoading ? (
            <div className="profile-loader">
              <Spinner color="success" />
            </div>
          ) : (
            <Button
              className="nexogic_primary_button "
              color="info"
              onClick={handleClickOnYes}
              {...yesBtnProps}
            >
              {yesLabel}
            </Button>
          )}
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AlertDialog;
