import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import RenderServicesModal from "./ServicesModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import AmentitesForm from "./AmentitesForm";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import blue_bullets from "assets/images/blue-bullets.png";
import plusIcon from "assets/images/plus-icon-lg.png";
import AmentitesFormBulkEdit from "./AmentitesFormBulkEdit";

export const Services = (props) => {
  const history = useHistory();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const dispatch = useDispatch();
  const { institution, isEditable, isAdmin, getInstitutionProfile, setShowServicesOnTabScroll } = props;
  const { id } = props.institution;

  const [count, setCount] = useState(12);
  const [counta, setCounta] = useState(12);
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [services, setServices] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const toggle = () => setIsOpen(!isOpen);
  const modalToggle = () => setIsModalOpen(!isModalOpen);
  const [pannel_on, setPannel_on] = useState(true);
  const [pannel_on_2, setPannel_on_2] = useState(true);
  const [addInBulk, setAddInBulk] = useState(false);
  const handleClick = () => {
    setAddInBulk(!addInBulk);
  };

  useEffect(() => {
    if (institution?.guid) {
      getInstitutionServices();
    }
    if(institution?.services && institution?.services.length){
      setShowServicesOnTabScroll(true);
    }else{
      setShowServicesOnTabScroll(false);
    }
  }, [institution]);


  const getInstitutionServices = () => {
    setServices(institution?.services);
    setAmenities(institution?.amenities);
  };

  const RenderServices = (props) => {
    const { services, count } = props;
    if (services && services.length) {
      return services?.slice(0, count).map((service, index) => {
        if (service.length > 0) {
          return (
            <li className="list-group-item" key={`${service}_${index}`}>
              <div className="row g-0">
                <div className="col-auto">
                  <img src={blue_bullets} alt="" />
                </div>
                <div className="col">
                  <h3 className="card-title fw-semibold">{service}{" "}</h3>
                </div>
              </div>

              {/**  <FontAwesomeIcon icon={faCheckCircle} /> {service}{" "}*/}
            </li>
          );
        }
      });
    }
    return "";
  };

  const RenderAmentities = (props) => {
    const { amenities, count } = props;
    if (amenities && amenities.length) {
      return amenities?.slice(0, count)?.map((ament, index) => {
        if (ament.length > 0) {
          return (
            <li className="list-group-item" key={`${ament}_${index}`}>
              <div className="row g-0">
                <div className="col-auto">
                  <img src={blue_bullets} alt="" />
                </div>
                <div className="col"><h3 className="card-title fw-semibold">{ament}{" "}</h3></div>
              </div>
              {/*<FontAwesomeIcon icon={faCheckCircle} /> */}
            </li>
          );
        }
      });
    }
    return "";
  };

  const ShowMoreServices = (props) => {
    const { services, count, setCount } = props;
    if (services && services.length > count) {
      const remainingCount = services.length - count;
      return (
        <Button
          id="nex-ip-service-more-btn"
          color="link"
          onClick={() => setCount(services.length)}
          className="d-inline-block f-14"
        >
          {`+ ${remainingCount} more`}
        </Button>
      );
    }
    return null;
  };

  return (
    <>
      {((services.length > 0 && !isAdmin) || isAdmin) && (
        <Card className={`card nexogic-card-color-header ${pannel_on ? 'expanded' : ''}`} id="services">
          <div className="card-header"><h2>Services</h2>
            <button className="collapse-btn" type="button" onClick={() => setPannel_on(!pannel_on)}>
              <img src={pannel_arrow} alt="" />
            </button>
            {(isEditable || isAdmin) && (

              <Button
                id="nex-ip-service-plus-btn"
                color="link"
                className="add-button"
                onClick={toggle}
              >
                <img src={plusIcon} alt="" />
                {/*<FontAwesomeIcon icon={faPlus} />*/}
              </Button>

            )}


          </div>
          <div className={`collapse-div`}>
            <CardBody>
              {services && services.length
                ? ""
                : (isEditable || isAdmin) && (
                  <p className="card-text">
                    <Button
                      id="nex-ip-service-add-info-btn"
                      className="readmore p-0"
                      color="link"
                      onClick={toggle}
                    >
                      {"Add information"}
                    </Button>
                  </p>
                )}

              <div className="nexogic-card-list">
                <ul className="list-group list-group-flush">
                  <RenderServices services={services} count={count} />
                </ul>
                <ShowMoreServices
                  services={services}
                  count={count}
                  setCount={setCount}
                />

              </div>


              {!isEditable && isEmpty(currentUser) && (
                <Row className="py-5 my-4">
                  <Col xs="12">
                    <p className="text-primary font-weight-bold font-italic">
                      {" "}
                      Please Sign In to access the full profile.
                    </p>
                    <Button
                      id="nex-ip-service-login-btn"
                      color="primary"
                      onClick={() => history.push("/login")}
                    >
                      {"Login"}
                    </Button>
                  </Col>
                </Row>
              )}
            </CardBody>
          </div>
          <RenderServicesModal
            isOpen={isOpen}
            toggle={toggle}
            services={services}
            isAdmin={isAdmin}
            institution={institution}
            practitionerId={currentUser?.guid}
            institutionId={institution?.guid}
            getInstitutionProfile={getInstitutionProfile}
          />
        </Card>
      )}
      {((amenities?.length > 0 && !isAdmin) || isAdmin) && (
        <Card className={`card nexogic-card-color-header ${pannel_on_2 ? 'expanded' : ''}`}>
          <div className="card-header"><h2>Amenities</h2>
            <button className="collapse-btn" type="button" onClick={() => setPannel_on_2(!pannel_on_2)}>
              <img src={pannel_arrow} alt="" />
            </button>
            {(isEditable || isAdmin) && (

              <Button
              id="nex-ip-service-add-btn2"
                color="link"
                className="add-button"
                onClick={modalToggle}
              >
                {/*<FontAwesomeIcon icon={faPlus} />*/}
                <img src={plusIcon} alt="" />
              </Button>

            )}
          </div>
          <div className={`collapse-div`}>
            <CardBody>


              {amenities && amenities?.length
                ? ""
                : (isEditable || isAdmin) && (
                  <p className="card-text">
                    <Button
                      id="nex-ip-service-add-btn3"
                      className="readmore"
                      color="link"
                      onClick={modalToggle}
                    >
                      {"Add information"}
                    </Button>
                  </p>
                )}


              <div className="nexogic-card-list">
                <ul className="list-group list-group-flush">
                  <RenderAmentities amenities={amenities} count={counta} />
                </ul>
                <ShowMoreServices
                  services={amenities}
                  count={counta}
                  setCount={setCounta}
                />
              </div>

              {!isEditable && isEmpty(currentUser) && (
                <Row className="py-5 my-4">
                  <Col xs="12">
                    <p className="text-primary font-weight-bold font-italic">
                      {" "}
                      Please Sign In to access the full profile.
                    </p>
                    <Button
                      id="nex-ip-service-login-btn2"
                      color="primary"
                      onClick={() => history.push("/login")}
                    >
                      {"Login"}
                    </Button>
                  </Col>
                </Row>
              )}
            </CardBody>
          </div>
          {/*  <RenderServicesModal
                    isOpen={isOpen}
                    toggle={toggle}
                    services={services}
                    practitionerId={practitionerId}
                    isAdmin={isAdmin}
                    institutionId={institution.guid}
                    getInstitutionProfile={getInstitutionProfile}
                /> */}
          <Modal
            isOpen={isModalOpen}
            toggle={modalToggle}
            id="profile-modal"
            centered={true}
            scrollable={true}
            backdrop="static"
            className="skills-modal skills-expertise-modal"
          >
            <ModalHeader toggle={modalToggle}>
            <div className='modal-filter-header'>{`Amenities`}
              <span className='ml-auto'>
                <label className='mb-0 mr-2'>Add in Bulk</label>
                <input id="add_in_bulk" type="checkbox" checked={addInBulk} onClick={() => handleClick()} />
              </span>
              </div>
            </ModalHeader>
            <ModalBody>
              {addInBulk
                ?
                <AmentitesFormBulkEdit
                  amenities={amenities}
                  toggle={modalToggle}
                  isAdmin={isAdmin}
                  institution={institution}
                  practitionerId={currentUser?.guid}
                  institutionId={institution?.guid}
                  getInstitutionProfile={getInstitutionProfile}
                />
                :
                <AmentitesForm
                  amenities={amenities}
                  toggle={modalToggle}
                  isAdmin={isAdmin}
                  institution={institution}
                  practitionerId={currentUser?.guid}
                  institutionId={institution?.guid}
                  getInstitutionProfile={getInstitutionProfile}
                />
              }
            </ModalBody>
          </Modal>
        </Card>
      )}
    </>
  );
};
