import moment from "moment";

import articleIcon from "assets/images/svgs/article_icon.svg";
import open_book_icon from "assets/images/svgs/open_book_icon.svg";
import presentation_icon from "assets/images/svgs/presentation_icon.svg";
import press_mention_icon from "assets/images/svgs/press_mention_icon.svg";

const YEARS = () => {
  const years = [];
  const dateStart = moment();
  const dateEnd = moment().subtract(70, "y");
  while (dateEnd.diff(dateStart, "years") <= 0) {
    years.push(dateStart.format("YYYY"));
    dateStart.subtract(1, "year");
  }
  return years;
};

const FUTURE_YEARS = () => {
  const years = [];
  const dateStart = moment();
  const dateEnd = moment().add(10, "y");
  while (dateEnd.diff(dateStart, "years") >= 0) {
    years.push(dateStart.format("YYYY"));
    dateStart.add(1, "year");
  }
  return years;
};

const ALL_YEARS = () => {
  const years = [];
  const startYear = 1950;
  for (let index = startYear; index <= 2030; index++) {
    years.push(index);
  }
  return years.reverse();
};

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const DOCTORS = [
  "Dr. Ted Russell",
  "Dr. Angela Yu",
  "Dr. Steve Boult",
  "Dr. Elena Luke",
  "Dr. Rajiv Singh",
  "Dr. Drivendra Das",
  "Dr. Smruti Rani",
  "Dr. Dinesh Pal",
  "Dr. Jyoti Singh",
  "Dr. Niharika Sahoo",
];

export const titles = [
  {
    label: "Dr.",
    value: "Dr.",
  },
  {
    label: "Mr.",
    value: "Mr.",
  },
  {
    label: "Mrs.",
    value: "Mrs.",
  },
  {
    label: "Ms.",
    value: "Ms.",
  },
  {
    label: "Prof.",
    value: "Prof.",
  },
];


const HOSPITALS = [
  "SCB Medical college",
  "AIIMS",
  "AMRI",
  "MKCG",
  "VSSUT",
  "City Hospital",
  "MGC",
  "GCE",
  "DMC",
  "BGSM",
];

const GENDERS = [
  { id: "M", label: "Male" },
  { id: "F", label: "Female" },
  { id: "T", label: "Transgender" },
];
const DAYS_OF_WEEK = {
  SUNDAY: "Su",
  MONDAY: "Mo",
  TUESDAY: "Tu",
  WEDNESDAY: "We",
  THURSDAY: "Th",
  FRIDAY: "Fr",
  SATURDAY: "Sa",
};
const LANGUAGES = [
  "Amharic",
  "Arabic",
  "Assamese",
  "Basque",
  "Bengali",
  "Bulgarian",
  "Chinese (PRC)",
  "Chinese (Taiwan)",
  "Catalan",
  "Cherokee",
  "Croatian",
  "Czech",
  "Danish",
  "Dutch",
  "English (UK)",
  "English (US)",
  "Estonian",
  "Filipino",
  "Finnish",
  "French",
  "German",
  "Greek",
  "Gujarati",
  "Hebrew",
  "Hindi",
  "Hungarian",
  "Icelandic",
  "Indonesian",
  "Italian",
  "Japanese",
  "Kannada",
  "Korean",
  "Latvian",
  "Lithuanian",
  "Maithili",
  "Malay",
  "Malayalam",
  "Marathi",
  "Norwegian",
  "Odia",
  "Polish",
  "Portuguese (Brazil)",
  "Portuguese (Portugal)",
  "Punjabi",
  "Romanian",
  "Russian",
  "Serbian",
  "Slovak",
  "Slovenian",
  "Spanish",
  "Swahili",
  "Swedish",
  "Tamil",
  "Telugu",
  "Thai",
  "Turkish",
  "Urdu",
  "Ukrainian",
  "Vietnamese",
  "Welsh",
];
export const TIME_CONSTANTS = { YEARS, FUTURE_YEARS, DAYS_OF_WEEK, ALL_YEARS };
export const FORM_CONSTANTS = {
  GENDERS,
  LANGUAGES,
  MONTHS,
  DOCTORS,
  HOSPITALS,
};

export const emailNonNumericRegEx =
  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const onlyAlphabets = /^[a-zA-Z. ]*$/;

export const zipCodeRegEx = /^\s*?\d{5,6}(?:[-\s]\d{4})?\s*?$/;

export const phoneRegExp =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;

export const doctorQualifications = [
  "AuD",
  "BDent",
  "BDS",
  "BDSc",
  "BEMS",
  "BMBS",
  "CCDS",
  "CEPS",
  "ChM",
  "CM",
  "DC",
  "DClinSurg",
  "DCM",
  "DDent",
  "DDS",
  "DDSc",
  "DMD",
  "DMedSc",
  "DMSc",
  "DO",
  "DPM",
  "DPT",
  "DS",
  "DScD",
  "DScPT",
  "DSN",
  "DSurg",
  "DVM",
  "ENT",
  "FAAD",
  "FAAEM",
  "FAAFP",
  "FACC",
  "FACCP",
  "FACE",
  "FACEP",
  "FACFAS",
  "FACG",
  "FACOG",
  "FACOS",
  "FACP",
  "FACS",
  "FAHA",
  "FASPS",
  "FFR",
  "FHM",
  "FHRS",
  "FICS",
  "FRCPSC",
  "FSCAI",
  "FSTS",
  "GP",
  "GYN",
  "MBA",
  "MBBCh",
  "MBBS",
  "MBChB",
  "MCh",
  "MChir",
  "MCM",
  "MD",
  "MD(Res)",
  "MDent",
  "MDS",
  "MM",
  "MMSc",
  "MPH",
  "MPhil",
  "MRCOG",
  "MRCS",
  "MS",
  "MSc",
  "MSD",
  "MSurg",
  "NP",
  "OB/GYN",
  "OD",
  "PA",
  "PharmD",
  "PhD",
  "PsyD",
  "SMF",
];

// F() for converting time from pubnub
export const getTime = (timeToken) => {
  let time = Math.round(Number(timeToken) / 10000);
  let date = new Date(time).toLocaleTimeString("en-US");
  date = date.slice(0, 4);
  // if (date.charAt(date.length - 1) == ":") {
  //   return (date = date.replace(date.charAt(date.length - 1), ""));
  // }
  return date;
};

export const userRoles = { ADMIN: "1", PRACTITIONER: "2" };

// export const ProfileStatuses = ['New', 'Reviewed', 'Claimed', 'Pending_Verification', 'Approved', 'Inactive']
export const ProfileStatuses = [
  { key: "All", label: "All" },
  { key: "New", label: "New" },
  { key: "Reviewed", label: "Reviewed" },
  { key: "Pending_Verification", label: "Pending Verification" },
  { key: "Approved", label: "Approved" },
  { key: "Inactive", label: "Inactive" },
];

//Not in use remove this ProfileStatusConstants
export const ProfileStatusConstants = {
  NEW: "New",
  REVIEWED: "Reviewed",
  PENDING_VERIFICATION: "Pending_Verification",
  APPROVED: "APPROVED",
  INACTIVE: "Inactive",
};

export const AppointmentStatus = {
  AVAILABLE: "AVAILABLE",
  BOOKED: "BOOKED",
  BLOCKED: "BLOCKED",
  CONFIRMED: "CONFIRMED",
  CANCELLED: "CANCELLED",
  COMPLETED: "COMPLETED",
  REJECTED: "REJECTED",
};

export const AppointmentType = {
  PHYSICAL: "PHYSICAL",
  VIDEO: "VIDEO",
  LAB: "LAB",
};

export const countryCodes = [
  {
    label: "+91 (IND)",
    value: "+91",
  },
  // {
  //   label: "+61 (AUS)",
  //   value: "+61"
  // },
  // {
  //     label: "+44 (UK)",
  //     value: "+44"
  // },
  // {
  //     label: "+1 (US & CA)",
  //     value: "+1"
  // }
];

export const LookupTypes = {
  COUNTRY: "COUNTRY",
  STATE: "STATE",
  CITY: "CITY",
  LOCATION: "LOCATION",
  SPECIALITY: "SPECIALITY",
  LANGUAGE: "LANGUAGE",
  SKILL_SET: "SKILL_SET",
  DEGREE: "DEGREE",
  COURSE: "COURSE",
  DEGREE_COURSE: "DEGREE-COURSE",
  SPECIALITY_SKILL_SET: "SPECIALITY-SKILL_SET",
  SERVICE_OFFERED: "SERVICE_OFFERED",
  AMENITY: "AMENITY",
};

export const InstitutionTypes = {
  BOARD_OR_REGISTRATION_COUNCIL: "BOARD_OR_REGISTRATION_COUNCIL", // "Board / Registration Council"
  CLINICAL_RESEARCH_ORGANIZATIONS: "CLINICAL_RESEARCH_ORGANIZATIONS", // Clinical Research Organizations
  COLLEGE_OR_UNIVERSITY: "COLLEGE_OR_UNIVERSITY", // College / University
  MEDICAL_ASSOCIATION: "MEDICAL_ASSOCIATION", // Medical Association
  MEDICAL_COLLEGE_OR_HOSPITAL: "MEDICAL_COLLEGE_OR_HOSPITAL", // Medical College / Hospital
  HOSPITAL: "HOSPITAL", // Hospital
  NURSING_HOMES: "NURSING_HOMES", // Nursing Homes
  INDIVIDUAL_CLINICS: "INDIVIDUAL_CLINICS", // Individual Clinics
  DIAGNOSTIC_CENTERS: "DIAGNOSTIC_CENTERS", // Diagnostic Centers
  MEDICAL_LABORATORY_OR_DIALYSIS_CENTERS:
    "MEDICAL_LABORATORY_OR_DIALYSIS_CENTERS", // Medical Laboratory / Dialysis Centers
  MEDICINE_STORES: "MEDICINE_STORES", // Medicine Stores
  OTHER: "OTHER", // Other
};

export const ExperienceInstitutionTypesList = [
  {
    id: InstitutionTypes.CLINICAL_RESEARCH_ORGANIZATIONS,
    label: "Clinical Research Organizations",
  },
  {
    id: InstitutionTypes.COLLEGE_OR_UNIVERSITY,
    label: "College / University",
  },
  {
    id: InstitutionTypes.MEDICAL_ASSOCIATION,
    label: "Medical Association",
  },
  {
    id: InstitutionTypes.MEDICAL_COLLEGE_OR_HOSPITAL,
    label: "Medical College / Hospital",
  },
  {
    id: InstitutionTypes.HOSPITAL,
    label: "Hospital",
  },
  {
    id: InstitutionTypes.NURSING_HOMES,
    label: "Nursing Homes",
  },
  {
    id: InstitutionTypes.INDIVIDUAL_CLINICS,
    label: "Individual Clinics",
  },
  {
    id: InstitutionTypes.DIAGNOSTIC_CENTERS,
    label: "Diagnostic Centers",
  },
  {
    id: InstitutionTypes.MEDICAL_LABORATORY_OR_DIALYSIS_CENTERS,
    label: "Medical Laboratory / Dialysis Centers",
  },
  {
    id: InstitutionTypes.MEDICINE_STORES,
    label: "Medicine Stores",
  },
  {
    id: InstitutionTypes.OTHER,
    label: "Other",
  },
];

export const EducationInstitutionTypesList = [
  {
    id: InstitutionTypes.COLLEGE_OR_UNIVERSITY,
    label: "College / University",
  },
  {
    id: InstitutionTypes.MEDICAL_COLLEGE_OR_HOSPITAL,
    label: "Medical College / Hospital",
  },
  {
    id: InstitutionTypes.OTHER,
    label: "Other",
  },
];

export const AffiliationInstitutionTypesList = [
  {
    id: InstitutionTypes.CLINICAL_RESEARCH_ORGANIZATIONS,
    label: "Clinical Research Organizations",
  },
  {
    id: InstitutionTypes.COLLEGE_OR_UNIVERSITY,
    label: "College / University",
  },
  {
    id: InstitutionTypes.MEDICAL_ASSOCIATION,
    label: "Medical Association",
  },
  {
    id: InstitutionTypes.MEDICAL_COLLEGE_OR_HOSPITAL,
    label: "Medical College / Hospital",
  },
  {
    id: InstitutionTypes.HOSPITAL,
    label: "Hospital",
  },
  {
    id: InstitutionTypes.NURSING_HOMES,
    label: "Nursing Homes",
  },
  {
    id: InstitutionTypes.INDIVIDUAL_CLINICS,
    label: "Individual Clinics",
  },
  {
    id: InstitutionTypes.DIAGNOSTIC_CENTERS,
    label: "Diagnostic Centers",
  },
  {
    id: InstitutionTypes.MEDICAL_LABORATORY_OR_DIALYSIS_CENTERS,
    label: "Medical Laboratory / Dialysis Centers",
  },
  {
    id: InstitutionTypes.MEDICINE_STORES,
    label: "Medicine Stores",
  },
  {
    id: InstitutionTypes.OTHER,
    label: "Other",
  },
];

export const AllInstitutionTypesList = [
  {
    id: InstitutionTypes.BOARD_OR_REGISTRATION_COUNCIL,
    label: "Board / Registration Council",
  },
  {
    id: InstitutionTypes.CLINICAL_RESEARCH_ORGANIZATIONS,
    label: "Clinical Research Organizations",
  },
  {
    id: InstitutionTypes.COLLEGE_OR_UNIVERSITY,
    label: "College / University",
  },
  {
    id: InstitutionTypes.MEDICAL_ASSOCIATION,
    label: "Medical Association",
  },
  {
    id: InstitutionTypes.MEDICAL_COLLEGE_OR_HOSPITAL,
    label: "Medical College / Hospital",
  },
  {
    id: InstitutionTypes.HOSPITAL,
    label: "Hospital",
  },
  {
    id: InstitutionTypes.NURSING_HOMES,
    label: "Nursing Homes",
  },
  {
    id: InstitutionTypes.INDIVIDUAL_CLINICS,
    label: "Individual Clinics",
  },
  {
    id: InstitutionTypes.DIAGNOSTIC_CENTERS,
    label: "Diagnostic Centers",
  },
  {
    id: InstitutionTypes.MEDICAL_LABORATORY_OR_DIALYSIS_CENTERS,
    label: "Medical Laboratory / Dialysis Centers",
  },
  {
    id: InstitutionTypes.MEDICINE_STORES,
    label: "Medicine Stores",
  },
  {
    id: InstitutionTypes.OTHER,
    label: "Other",
  },
];

export const AwardsTypesList = [
  {
    id: InstitutionTypes.CLINICAL_RESEARCH_ORGANIZATIONS,
    label: "Clinical Research Organizations",
  },
  {
    id: InstitutionTypes.COLLEGE_OR_UNIVERSITY,
    label: "College / University",
  },
  {
    id: InstitutionTypes.MEDICAL_ASSOCIATION,
    label: "Medical Association",
  },
  {
    id: InstitutionTypes.MEDICAL_COLLEGE_OR_HOSPITAL,
    label: "Medical College / Hospital",
  },
  {
    id: InstitutionTypes.HOSPITAL,
    label: "Hospital",
  },
  {
    id: InstitutionTypes.NURSING_HOMES,
    label: "Nursing Homes",
  },
  {
    id: InstitutionTypes.INDIVIDUAL_CLINICS,
    label: "Individual Clinics",
  },
  {
    id: InstitutionTypes.DIAGNOSTIC_CENTERS,
    label: "Diagnostic Centers",
  },
  {
    id: InstitutionTypes.MEDICAL_LABORATORY_OR_DIALYSIS_CENTERS,
    label: "Medical Laboratory / Dialysis Centers",
  },
  {
    id: InstitutionTypes.OTHER,
    label: "Other",
  },
];

export const UserStatusConstants = {
  NEW: "NEW",
  BASIC_INFO_PROVIDED: "BASIC_INFO_PROVIDED",
  REGISTRATION_INFO_PROVIDED: "REGISTRATION_INFO_PROVIDED",
  EDUCATION_INFO_PROVIDED: "EDUCATION_INFO_PROVIDED",
  AFFILIATIONS_INFO_PROVIDED: "AFFILIATIONS_INFO_PROVIDED",
  READY_TO_CLAIM: "READY_TO_CLAIM",
  PROFILE_CLAIMED: "PROFILE_CLAIMED",
  DOC_UPLOADED: "DOC_UPLOADED",
  CANCELED: "CANCELED",
  ADDITIONAL_INFO_REQUESTED: "ADDITIONAL_INFO_REQUESTED",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};

export const listOfDocuments = [
  { key: "ID_PROOF", value: "ID_PROOF", label: "Government Issued Photo ID" },
  {
    key: "REGISTRATION_DOCUMENT",
    value: "REGISTRATION_DOCUMENT",
    label: "Medical Registration",
  },
  { key: "OTHER", value: "OTHER", label: "Other" },
];

export const list_Of_Publication_Type = [
  {
    key: "ARTICLE",
    value: "ARTICLE",
    label: "Article",
    icon: <img src={articleIcon} width="16" alt="" />,
  },
  {
    key: "BOOK",
    value: "BOOK",
    label: "Book",
    icon: <img src={open_book_icon} width="16" alt="" />,
  },
  {
    key: "BOOK_SECTION",
    value: "BOOK_SECTION",
    label: "Book Section",
    icon: <img src={open_book_icon} width="16" alt="" />,
  },
  {
    key: "PRESENTATION",
    value: "PRESENTATION",
    label: "Presentation",
    icon: <img src={presentation_icon} width="16" alt="" />,
  },
  {
    key: "PRESS_MENTION",
    value: "PRESS_MENTION",
    label: "Press Mention",
    icon: <img src={press_mention_icon} width="16" alt="" />,
  },
  {
    key: "OTHERS",
    value: "OTHERS",
    label: "Others",
    icon: <img src={articleIcon} width="16" alt="" />,
  },
];

export const list_Of_Awards_Type = [
  {
    key: "AWARD_AND_RECOGNITION",
    value: "AWARD_AND_RECOGNITION",
    label: "Award and Recognition",
  },
  { key: "HONORS", value: "HONORS", label: "Honors" },
  { key: "FELLOWSHIP", value: "FELLOWSHIP", label: "Fellowship" },
];

export const CertificationInstitutionTypesList = [
  {
    id: InstitutionTypes.BOARD_OR_REGISTRATION_COUNCIL,
    label: "Board / Registration Council",
  },
  {
    id: InstitutionTypes.CLINICAL_RESEARCH_ORGANIZATIONS,
    label: "Clinical Research Organizations",
  },
  {
    id: InstitutionTypes.MEDICAL_ASSOCIATION,
    label: "Medical Association",
  },
  {
    id: InstitutionTypes.MEDICAL_COLLEGE_OR_HOSPITAL,
    label: "Medical College / Hospital",
  },
  {
    id: InstitutionTypes.HOSPITAL,
    label: "Hospital",
  },
  {
    id: InstitutionTypes.NURSING_HOMES,
    label: "Nursing Homes",
  },
  {
    id: InstitutionTypes.INDIVIDUAL_CLINICS,
    label: "Individual Clinics",
  },
  {
    id: InstitutionTypes.DIAGNOSTIC_CENTERS,
    label: "Diagnostic Centers",
  },
  {
    id: InstitutionTypes.MEDICAL_LABORATORY_OR_DIALYSIS_CENTERS,
    label: "Medical Laboratory / Dialysis Centers",
  },
  {
    id: InstitutionTypes.COLLEGE_OR_UNIVERSITY,
    label: "College or University",
  },
  {
    id: InstitutionTypes.MEDICINE_STORES,
    label: "Medicine Stores",
  },
  {
    id: InstitutionTypes.OTHER,
    label: "Other",
  },
];

export const PositionTypesList = [
  {
    id: "Lifetime Member",
    key: "Lifetime Member",
    value: "Lifetime Member",
    label: "Lifetime Member",
  },
  {
    id: "Member",
    key: "Member",
    value: "Member",
    label: "Member",
  },
  {
    id: "Founder",
    key: "Founder",
    value: "Founder",
    label: "Founder",
  },
  {
    id: "President",
    key: "President",
    value: "President",
    label: "President",
  },
  {
    id: "Other",
    key: "Other",
    value: "Other",
    label: "Other",
  },
];

export const InstituationphonesType = [
  {
    label: "Appointment",
    value: "APPOINTMENT",
  },
  {
    label: "Billing",
    value: "BILLING",
  },
  {
    label: "Emergency",
    value: "EMERGENCY",
  },
  {
    label: "Enquiry",
    value: "ENQUIRY",
  },
  {
    label: "Other",
    value: "OTHER",
  },
  {
    label: "Path Lab",
    value: "PATH_LAB",
  },
];

export const InstituationEmailType = [
  {
    label: "Appointment",
    value: "APPOINTMENT",
  },
  {
    label: "Billing",
    value: "BILLING",
  },
  {
    label: "Emergency",
    value: "EMERGENCY",
  },
  {
    label: "Enquiry",
    value: "ENQUIRY",
  },
  {
    label: "Other",
    value: "OTHER",
  },
  {
    label: "Path Lab",
    value: "PATH_LAB",
  },
];

export const Opportunity_Type = [
  {
    label: "Job",
    value: "JOB",
    id: "JOB",
    key: "JOB",
  },
  {
    label: "Mentorship",
    value: "MENTORSHIP",
    id: "MENTORSHIP",
    key: "MENTORSHIP",
  },
  {
    label: "Fellowship",
    value: "FELLOWSHIP",
    id: "FELLOWSHIP",
    key: "FELLOWSHIP",
  },
  {
    label: "Volunteer",
    value: "VOLUNTEER",
    id: "VOLUNTEER",
    key: "VOLUNTEER",
  },
];

export const JOB_PositionTypesList = [
  {
    id: "FULL_TIME",
    key: "FULL_TIME",
    value: "FULL_TIME",
    label: "Full-time",
  },
  {
    id: "PART_TIME",
    key: "PART_TIME",
    value: "PART_TIME",
    label: "Part-time",
  },
  {
    id: "FLEXIBLE_TIME",
    key: "FLEXIBLE_TIME",
    value: "FLEXIBLE_TIME",
    label: "Flexible-time",
  },
  {
    id: "CONSULTANT",
    key: "CONSULTANT",
    value: "CONSULTANT",
    label: "Consultant",
  },
];

export const JOB_Work_Shift_TypesList = [
  {
    id: "FIRST_SHIFT",
    key: "FIRST_SHIFT",
    value: "FIRST_SHIFT",
    label: "First Shift",
  },
  {
    id: "SECOND_SHIFT",
    key: "SECOND_SHIFT",
    value: "SECOND_SHIFT",
    label: "Second Shift",
  },
  {
    id: "THIRD_SHIFT",
    key: "THIRD_SHIFT",
    value: "THIRD_SHIFT",
    label: "Third Shift",
  },
  {
    id: "GENERAL_SHIFT",
    key: "GENERAL_SHIFT",
    value: "GENERAL_SHIFT",
    label: "General Shift",
  },
  {
    id: "ON_CALL_SHIFT",
    key: "ON_CALL_SHIFT",
    value: "ON_CALL_SHIFT",
    label: "On Call Shift",
  },
  {
    id: "FIXED_SHIFT",
    key: "FIXED_SHIFT",
    value: "FIXED_SHIFT",
    label: "Fixed Shift",
  },
  {
    id: "WEEKEND_SHIFT",
    key: "WEEKEND_SHIFT",
    value: "WEEKEND_SHIFT",
    label: "Weekend Shift",
  },
  {
    id: "SPLIT_SHIFT",
    key: "SPLIT_SHIFT",
    value: "SPLIT_SHIFT",
    label: "Split Shift",
  },
  {
    id: "ROTATING_SHIFT",
    key: "ROTATING_SHIFT",
    value: "ROTATING_SHIFT",
    label: "Rotating Shift",
  },
];

export const FAILTOSAVERECOED =
  "Something went wrong. We are working on getting this fixed as soon as we can.";

export const FAILED_TO_FETCH_DATA =
  "Something went wrong. Please try again later.";

export const FAILTOGETRECOED =
  "Not valid information, redirecting you to the dashboard";

export const FAILED_TO_DELETE_DATA =
  "Something went wrong. Please try again later.";

export const APPLY_MODE_LIST = [
  {
    id: "EMAIL",
    key: "EMAIL",
    value: "EMAIL",
    label: "Email",
  },
  {
    id: "LINK",
    key: "LINK",
    value: "LINK",
    label: "External Link",
  },
];

export const User_Types = [
  {
    label: "PRACTITIONER",
    value: "PRACTITIONER",
    id: "PRACTITIONER",
    key: "PRACTITIONER",
  },
  {
    label: "PATIENT",
    value: "PATIENT",
    id: "PATIENT",
    key: "PATIENT",
  },
  {
    label: "ADMIN",
    value: "ADMIN",
    id: "ADMIN",
    key: "ADMIN",
  }
];

export const Patient_Type = [
  {
    label: "Domestic",
    value: "DOMESTIC",
  },
  {
    label: "International",
    value: "INTERNATIONAL",
  },
];

export const Treatment_Type = [
  {
    label: "In Patient",
    value: "IN_PATIENT",
  },
  {
    label: "Out Patient",
    value: "OUT_PATIENT",
  },
];

