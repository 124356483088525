import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import AllPractitioners from "./AllPractitioners";

export const RenderAllPractitionersModal = (props) => {
  const { isOpen, toggle, institutionGuid } = props;

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        id="profile-modal"
        className="modal-dialog-scrollable modal-dialog-centered pratitionar-profile-modal section"
        backdrop="static"
        size={"lg"}
      >
         <ModalHeader toggle={toggle}>
        <p className="text-dark">Practitioners Who Work Here</p>
        </ModalHeader>
        <ModalBody id="practitioners-slider-modal" className="bg-page">
          <div className="practitioners-slider-card ">
          <AllPractitioners institutionGuid={institutionGuid}/>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
