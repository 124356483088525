import { useRouteMatch } from "react-router-dom";
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent
} from "reactstrap";
import classnames from "classnames";
import { useState } from "react";
import JobListDraft from "./Components/JobListDraft";
import JobListPublished from "./Components/JobListPublished";
import JobListClosed from "./Components/JobListClosed";
import JobListSubmitted from "./Components/JobListSubmitted";

const PostedJobsContent = () => {
  let { path, url } = useRouteMatch();
  const [currentActiveTab, setCurrentActiveTab] = useState("Draft");

  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };
  // console.log(currentActiveTab);
  return (
    <>
    <Nav tabs className="all-jobs">
            <NavItem>
              <NavLink
                className={classnames({
                  active: currentActiveTab === "Draft",
                })}
                onClick={() => {
                  toggle("Draft");
                }}
              >
                Draft
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: currentActiveTab === "Submitted",
                })}
                onClick={() => {
                  toggle("Submitted");
                }}
              >
                Submitted
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: currentActiveTab === "Published",
                })}
                onClick={() => {
                  toggle("Published");
                }}
              >
                Published
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: currentActiveTab === "Closed",
                })}
                onClick={() => {
                  toggle("Closed");
                }}
              >
                Closed
              </NavLink>
            </NavItem>
          </Nav>
      <Card className="tabs-border-radius bg-transparent border-0">
        <CardBody className="p-0">
         {/* <h1 className="job-type-header">My Posted Jobs</h1> */}
          <TabContent>
            {currentActiveTab === "Draft" && <JobListDraft />}
            {currentActiveTab === "Submitted" && <JobListSubmitted />}
            {currentActiveTab === "Published" && <JobListPublished />}
            {currentActiveTab === "Closed" && <JobListClosed />}
          </TabContent>
        </CardBody>
      </Card>
    </>
  );
};
export default PostedJobsContent;
