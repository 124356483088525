import React, { useEffect } from "react";
import { Card, Nav, NavItem } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ConnectionService } from "utils/ConnectionService";
import { setConnectionInfo } from "redux-helpers/ActionCreator";
import { isInstitutionAdmin, isPractitioner } from "utils/UserHelper";
import { useState } from "react";

const ConnectionSidebar = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const connections = useSelector((state) => state.connectionsInfo.connections);
  const counts = connections.count;
  const currentUser = useSelector((state) => state.auth.currentUser);
  let guid = currentUser.guid;
  const [mobileSidebarOn, setMobileSidebarOn] = useState(false);

  function get_active_sidebar_name(){
      if(location.pathname === "/connections/all-connections"){
        return 'All Connection';
      } else if (location.pathname === "/connections/alumni"){
        return 'Alumni';
      } else if (location.pathname === "/connections/my-colleagues"){
        return 'My colleagues';
      } else if(location.pathname === "/connections/my-patients"){
        return 'My patients';
      } else if(location.pathname === "/connections/groups"){
        return 'Groups';
      } else if(location.pathname === "/connections/pages"){
        return 'Pages';
      } else {
        return '';
      }
  }

  useEffect(() => {
    getConnectedCount();
  }, []);

  const getConnectedCount = () => {
    ConnectionService.getConnectionsCount(guid)
      .then((response) => {
        let { data } = response;
        if (response.status === 200 || response.status === 201) {
          dispatch(setConnectionInfo({ ...connections, count: { all: data } }));
        }
      })
      .catch((error) => {
        console.log("Error while getting connection status: ", error);
      });
  };

  return (
    <>
    {mobileSidebarOn && 
        <div className='nex-mob-backdrops' onClick={()=>setMobileSidebarOn(false)}></div>
    }
    <div className='nex-mob-sticky-box cursor-pointer mt-2' onClick={()=>setMobileSidebarOn(!mobileSidebarOn)} >
                <span className='label'>{`${get_active_sidebar_name()}`}</span>
                <span><svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 10C2.55228 10 3 9.55228 3 9C3 8.44772 2.55228 8 2 8C1.44772 8 1 8.44772 1 9C1 9.55228 1.44772 10 2 10Z" stroke="#3EABCA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M2 3C2.55228 3 3 2.55228 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2C1 2.55228 1.44772 3 2 3Z" stroke="#3EABCA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M2 17C2.55228 17 3 16.5523 3 16C3 15.4477 2.55228 15 2 15C1.44772 15 1 15.4477 1 16C1 16.5523 1.44772 17 2 17Z" stroke="#3EABCA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10 10C10.5523 10 11 9.55228 11 9C11 8.44772 10.5523 8 10 8C9.44772 8 9 8.44772 9 9C9 9.55228 9.44772 10 10 10Z" stroke="#3EABCA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10 3C10.5523 3 11 2.55228 11 2C11 1.44772 10.5523 1 10 1C9.44772 1 9 1.44772 9 2C9 2.55228 9.44772 3 10 3Z" stroke="#3EABCA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10 17C10.5523 17 11 16.5523 11 16C11 15.4477 10.5523 15 10 15C9.44772 15 9 15.4477 9 16C9 16.5523 9.44772 17 10 17Z" stroke="#3EABCA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                </span>
      </div>
    <Card className={`tabs-border-radius nexogic-side-tab-mobile-visibility ${mobileSidebarOn ? 'mobile-on' : ''}`}>
      <Nav className="nav-list w-100 text-center">
        <div className="w-100 d-flex flex-column justify-content-between">
          <ul className="top-links nexogic-aside-menu">
            {isPractitioner() &&
              <NavItem
                title="All-Connection"
                active={
                  location.pathname === "/connections/all-connections"
                    ? true
                    : false
                }
                className=""
              >
                <Link
                  className="nav-link"
                  to="/connections/all-connections"
                >
                  <div className="d-flex align-items-center">
                    All Connections
                    <div className="badge">{counts.all}</div>
                  </div>

                </Link>
              </NavItem>
            }
            {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && isPractitioner() && (
              <NavItem
                title="Alumni"
                active={
                  location.pathname === "/connections/alumni" ? true : false
                }
                className="w-100 text-center"
              >
                <Link className="nav-link" to="/connections/alumni">
                  <div className="d-flex align-items-center">
                    Alumni
                    <div className="badge">{counts.alumni}</div>
                  </div>

                </Link>
              </NavItem>
            )}
            {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && isPractitioner() && (
              <NavItem
                title="My-colleagues"
                active={
                  location.pathname === "/connections/my-colleagues"
                    ? true
                    : false
                }
                className="w-100 text-center"
              >
                <Link
                  className="nav-link"
                  to="/connections/my-colleagues"
                >
                  <div className="d-flex align-items-center">
                    <div className="menu-text">My colleagues</div>
                    <div className="ml-auto mr-2">{counts.colleagues}</div>
                  </div>

                </Link>
              </NavItem>
            )}
            {isPractitioner() &&
              <NavItem title="My-patients" active={
                location.pathname === "/connections/my-patients"
                  ? true
                  : false
              } className="w-100 text-center">
                <Link className="nav-link" to="/connections/my-patients">
                  <div className="d-flex align-items-center">
                    <div className="menu-text">My patients</div>
                    <div className="ml-auto mr-2">{counts.patients}</div>
                  </div>

                </Link>
              </NavItem>
            }
            {/* <NavItem title="People-I-follow" active={location.pathname==='/connections/people-I-follow'?true:false} className="w-100 text-center">
                            <Link className="nav-link" to="/connections/people-I-follow">
                                <div className="d-flex align-items-center">
                                    <div className="menu-text">People I follow</div>
                                    <div className="ml-auto mr-2">{counts.peopleIFollow}</div>
                                </div>
                                
                            </Link>
                        </NavItem> */}
            {isPractitioner() &&
              <NavItem
                title="Groups"
                active={
                  location.pathname === "/connections/groups" ? true : false
                }
                className="w-100 text-center"
              >
                <Link className="nav-link" to="/connections/groups">
                  <div className="d-flex align-items-center">
                    Groups
                    <div className="badge">{counts.groups}</div>
                  </div>

                </Link>
              </NavItem>
            }
            <NavItem
              title="Pages"
              active={
                location.pathname === "/connections/pages" ? true : false
              }
              className="w-100 text-center"
            >
              <Link className="nav-link" to="/connections/pages">
                <div className="d-flex align-items-center">
                  Pages
                  <div className="badge">{counts.pages}</div>
                </div>
              </Link>
            </NavItem>
          </ul>
        </div>
      </Nav>
    </Card>
    </>
  );
};
export default ConnectionSidebar;
