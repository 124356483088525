import tickCheckmark from "assets/images/svgs/tick_checkmark_icon.svg";
import {faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "assets/styles/typeahead.css";
import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import { useFormik } from "formik";
import { findIndex } from "lodash";
import { useEffect, useRef, useState, useMemo } from "react";
import { withAlert } from "react-alert";
import { Typeahead } from "react-bootstrap-typeahead";
import { Button, Col, FormGroup, Row, Spinner } from "reactstrap";
import { InstitutionServices } from "utils/InstitutionServices";
import { MetadataService } from "utils/MetadataService";
import * as Yup from "yup";
import plusIcon from "assets/images/add-icon.png";
import addIcon from "assets/images/svgs/add_icon.svg";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const validationSchema = Yup.object().shape({
  services: Yup.string().required("This field is required"),
});

const ServicesForm = (props) => {
  const { toggle, institution, alert, getInstitutionProfile } = props;
  const [servicesData, setServicesData] = useState([]);
  const [services, setServices] = useState([]);
  const [sugServices, setSugServices] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [loadMore,setLoadMore] = useState(10);
  const [isCompleted, setIsCompleted] = useState(false);
  const [errorMsg, setErrorMessage] = useState("");
  const servicesRef = useRef(null);

  useEffect(() => {
    setServices(institution.services);
  }, []);

  const formik = useFormik({
    initialValues: { services: services },
    validationSchema,
  });

  const updateItems = async (values) => {
    setLoading(true);
    let payload = [
      {
        op: "add",
        path: "/services",
        value: services,
      },
    ];
    InstitutionServices.patchInstitutionDetails(payload, institution.guid)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          toggle();
          getInstitutionProfile(institution.guid);
          showSuccessMessage("Institution details updated successfully");
        } else {
          showErrorMessage("Institution details failed to update");
        }
        setLoading(false);
      })
      .catch((err) => {
        showErrorMessage(err.message);
        setLoading(false);
      });
  };

  const handleChange = (selectedOptions) => {
    const preData = selectedOptions.map((item) => {
      let clone = item;
      let custom = item?.customOption ? true : false;
      if (custom) {
        clone = item?.services;
      }
      return clone;
    });
    preData.length ? setServices(preData) : setServices([]);
  };

  const handleSuggestedSkills = (sservice) => {
    setServices([...services, sservice]);
  };

  const handleLoadMore = () => {
    setLoadMore(loadMore + 15);
    if (loadMore >= servicesData.length) {
      setIsCompleted(true)
    } else {
      setIsCompleted(false)
    }
  }
  const servicesSuggestions = useMemo(() => {
    if (sugServices && sugServices.length > 0) {
      const uniqueSugServices = sugServices.filter(
        (s) => findIndex(services, (o) => o === s) < 0
      );
      return uniqueSugServices?.slice(0, loadMore)?.map((data,index) => {
        return (
          <div key={index}
            id={`nex-sk-ssf-suggest-${index}`}
            className="nexogic-suggested-skill d-flex align-items-center"
            onClick={() => handleSuggestedSkills(data)}
          >
            <div className="card-text">{data}</div>
            <div className="add-icon ml-1">
              <img src={addIcon} alt="" />
            </div>
          </div>
        );
      });
    }
    return null;
  }, [services, sugServices,loadMore]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        MetadataService.getServices().then((response) => {
          const { status, data } = response;
          if (status === 200 || status === 201) {
            setLoading(false);
            setServicesData(data);
            setSugServices(data);
          }
        });
      } catch (e) {
        setErrorMessage(e.message);
        setLoading(false);
      }
    })();
  }, []);
  return (
    <>
      <form onSubmit={formik.handleSubmit} id="nex-ip-add-service-form">
        {errorMsg !== "" && <RenderErrorMessage errorMsg={errorMsg} />}
        <Row>
          <Col xs={12}>
            <FormGroup className="searchbox-input-chips mb-1" id="nex-ip-add-service-inp-w">
              <Typeahead
                ref={servicesRef}
                allowNew
                clearButton
                newSelectionPrefix="Add Services: "
                selected={services}
                id="services"
                multiple
                options={servicesData}
                placeholder="Add your services"
                labelKey={"services"}
                onBlur={() => {
                  return servicesRef !== null
                    ? servicesRef.current.hideMenu()
                    : null;
                }}
                onChange={handleChange}
              />
            </FormGroup>
            <div className="mb-2 card-text"><small>You can add upto 50 services</small></div>
            <div className="nexogic-suggested-skills d-flex flex-wrap mt-3 mb-3" id="nex-ip-add-service-suggestions">
              {servicesSuggestions}
            </div>
            <div className="specialities  d-flex flex-wrap my-2 justify-content-center">
            {!isCompleted && (
              <Button
                id="nex-ip-service-load-more-btn"
                className="nexogic_primary_button_outline"
                color="link"
                onClick={handleLoadMore}
              >
                <img src={plusIcon} alt=""/> Load more
              </Button>
            )}
            </div>
          </Col>
        </Row>
        {/*<hr className="profile-info-modal-hr mt-0" />*/}
        <div className="justify-content-end  modal-footer">
          {isLoading ? (
            <div className="loading-spiner">
              <Spinner style={{ width: "2rem", height: "2rem" }} />
            </div>
          ) : (
            <div className="action-group">
              
              <Button
              id="sf_btn_submit"
                className="nexogic_primary_button"
                color="primary"
                type="submit"
                onClick={() => updateItems(services)}
              >
                Save
              </Button>
              <Button
              id="sf_btn_cancel"
                color="primary"
                outline
                className="nexogic_primary_button_outline"
                onClick={toggle}
              >
                Cancel
              </Button>
            </div>
          )}
        </div>
      </form>
    </>
  );
};
export default withAlert()(ServicesForm);
