import CoverImage from "assets/images/defaultBannerPage.png";
import unsplashIcon from "assets/images/svgs/unsplash.svg";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikRadioField from "components/forms/formikFields/FormikRadioField";
import { Field, Formik } from "formik";
import { RenderDialog } from "pages/Profile/components/DoctorsProfile/components/RenderCoverImage";
import { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { Button, Col, FormGroup, Label, Row, Spinner } from "reactstrap";
import API from "utils/API";
import { ConnectionServices } from "utils/ConnectionServices";
import { FAILTOSAVERECOED } from "utils/Constants";
import { UserService } from "utils/UserService";
import { validateImageSize, validateImageType } from "utils/Utils";
import * as Yup from "yup";

import camera_icon from "assets/images/camera-icon.png";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const validationSchema = Yup.object().shape({
  groupName: Yup.string().min(3).max(100).required("This field is required"),
  privacy: Yup.string().required("This field is required"),
  status: Yup.string().required("This field is required"),
  groupDescription: Yup.string().required("This field is required").min(10).max(1000),
  rules: Yup.string().max(1000)
});

const RenderCoverGroupImage = (props) => {
  const { selectedItem, handleEditItem, alert } = props;
  const guid = selectedItem?.id;

  const [uploadImageDetails, setImageDetails] = useState({
    name: "",
    type: "",
  });
  const [uploadImage, setImage] = useState("");
  const [showSpinner, setSpinner] = useState(false);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const handleImageUpload = (event) => {
    let file = event.target.files[0];

    // fileType validation
    if (validateImageType(file)) {
      // fileSize validation
      const maxSize = 1000 * 1000 * 10; //10MB
      const validSize = validateImageSize(file, maxSize);
      if (validSize === 1) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const imageBase64 = reader.result;
          setImageDetails({ name: file.name, type: file.type });
          setImage(imageBase64);
          toggle();
        };
      } else if (validSize === -1) {
        showErrorMessage("Invalid File!");
      } else {
        showErrorMessage("File must be less than 10MB");
      }
    } else {
      showErrorMessage("Invalid File!");
    }
  };

  const getSignUrl = async (file) => {
    try {
      setSpinner(true);
      const response = await UserService.getSignUrl(file);
      if (response && response.data) {
        const { signedUrl } = response.data.data;
        uploadFileURL(signedUrl, file);
      }
    } catch (e) {
      console.log(e);
      setSpinner(false);
    }
  };

  const uploadFileURL = (signedUrl, file) => {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", signedUrl, true);
    xhr.onload = () => {
      const status = xhr.status;
      if (status === 200) {
        generateImageUrl(file);
      } else {
        alert("Something went wrong ");
      }
    };
    xhr.setRequestHeader("Content-Type", file.type);
    xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
    xhr.setRequestHeader("Access-Control-Allow-Headers", "Origin");
    xhr.setRequestHeader("Access-Control-Allow-Headers", "Content-Type");
    xhr.setRequestHeader("Access-Control-Allow-Headers", "X-Auth-Token");
    xhr.setRequestHeader("Access-Control-Allow-Methods", "PUT");
    xhr.send(file);
  };

  const generateImageUrl = async (file) => {
    try {
      const response = await API.get(
        "/signed-url?uploadPath=profile-pics/" + file.name + "&action=download"
      );
      if (response && response.data) {
        const { signedUrl } = response.data.data;
        const profilePicUrl = signedUrl.split("?")[0];
        //updateProfile(profilePicUrl)
      }
    } catch (e) {
      setSpinner(false);
    }
  };

  const onShowDefaultImage = (guidId, imageId) => {
    let imageSet = CoverImage;
    if (guidId && imageId) {
      imageSet = `${process.env.REACT_APP_IMAGE_BASE_URL}/${guidId}/${imageId}`;
    }
    return imageSet;
  };

  const onImageChange = (file) => {
    if (file) {
      setSpinner(true);
      ConnectionServices.uploadGroupImage(file, guid, "BANNER").then(
        (response) => {
          if (response.status === 200 || response.status === 201) {
            setImage(onShowDefaultImage(guid, response.data));
            const updatedData = {
              ...selectedItem,
              bannerId: response.data,
              bannerUrl: onShowDefaultImage(selectedItem?.id, response.data),
            };
            handleEditItem(updatedData);
            setSpinner(false);
            showSuccessMessage("Banner Image updated successfully.")
          } else {
            showSuccessMessage("Something went wrong, Please try again later.")
            setSpinner(false);
          }
        },
        (err) => {
          setSpinner(false);
          setImage(onShowDefaultImage(guid, ""));
          console.log("failed banner upload", err.message);
        }
      );
    } else {
      setSpinner(false);
      if (selectedItem?.bannerId) {
        setImage(onShowDefaultImage(selectedItem?.id, selectedItem?.bannerId));
      } else {
        setImage(onShowDefaultImage(selectedItem?.id, ""));
      }
    }
  };

  useEffect(() => {
    setImage(onShowDefaultImage(selectedItem?.id, selectedItem?.bannerId));
  }, [selectedItem]);

  return (
    <>
      <RenderDialog
        modal={modal}
        toggle={toggle}
        profilePicUrl={uploadImage}
        uploadImageDetails={uploadImageDetails}
        getSignUrl={getSignUrl}
        onSave={onImageChange}
        alert={alert}
      />
      <div className="nexogic-modal-card-cover position-relative mt-3 mb-3">
        {/* <Button
          className="change-image-btn-remove rounded-circle d-block"
          onClick={() => {}}
        >
          <FontAwesomeIcon icon={faTrash} className="fa-fw f-12" />
        </Button> */}
        <Button className="edit-cover-btn" id="nex-edit-cover-btn">
          <img src={camera_icon} alt="" className="card-img" />
          <input
            name="coverImage"
            className="fileUploadForm"
            type="file"
            onChange={(event) => {
              handleImageUpload(event)
              event.target.value = null;
            }}
            id="coverImage"
            accept="image/*, png, jpg, jpeg"
            title=""
          />
          <span>Edit Cover Photo</span>
        </Button>

        {showSpinner ? (
          <div className="position-absolute profile-loader">
            <Spinner color="secondary" size="sm" />
          </div>
        ) : (
          <img src={uploadImage} className="card-img" alt="" />
        )}
      </div>
    </>
  );
};

const RenderCreateGroupForm = (props) => {
  const { handleSubmit, isLoading, values, handleEditItem, alert, setGroupHeaderText } = props;

  // useEffect(() => {
  //   console.log(props);
  // }, [props])

  useEffect(() => {
    values.id ? setGroupHeaderText("Update Group") : setGroupHeaderText("Create Group");
  }, [])

  return (
    <>
      {values.id &&
        <RenderCoverGroupImage
          selectedItem={values}
          handleEditItem={handleEditItem}
          alert={alert}
        />}
      <div className="">
        <form className="my-form" onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Field
                id="cgf_groupName"
                name="groupName"
                type="text"
                label="Group name"
                component={FormikInputField}
                placeholder="Enter group name"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Field
                id="cgf_groupDescription"
                name="groupDescription"
                type="textarea"
                label="Description"
                component={FormikInputField}
                rows={5}
                cols={5}
                placeholder="Type something about your group..."
              />
            </Col>
          </Row>
          <Row>
            <Col >
              <Field
                id="cgf_rules"
                name="rules"
                type="textarea"
                label="Rules"
                component={FormikInputField}
                rows={4}
                cols={5}
                placeholder="Enter group rules..."
              />
            </Col>
          </Row>
          <Row>
            <Col >
              <div className="form-group">
                <Label className="d-block"> Searchable</Label>
                <FormGroup check className="nexogic-check-w extra-space">
                  <Field
                    id="status"
                    name="status"
                    type="radio"
                    component={FormikRadioField}
                    value="LISTED"
                    label="Listed"
                  />
                  <p className="card-text mb-3"><small className="text-muted">
                    Group appear in search results and is visible to others on
                    member's profile</small>
                  </p>
                </FormGroup>
                <FormGroup check className="nexogic-check-w extra-space">
                  <Field
                    id="Unlisted"
                    name="status"
                    type="radio"
                    component={FormikRadioField}
                    value="UNLISTED"
                    label="Unlisted"
                  />
                  <p className="card-text mb-3"><small className="text-muted">
                    Unlisted Group does not appear in search results for
                    non-group members and is not visible to others on members'
                    profiles.</small>
                  </p>
                </FormGroup>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="form-group">
                <Label className="d-block">Group privacy</Label>
                <FormGroup check className="nexogic-check-w extra-space">
                  <Field
                    id="private"
                    name="privacy"
                    type="radio"
                    component={FormikRadioField}
                    value="PRIVATE"
                    label="Private"
                  />
                  <p className="card-text mb-3"><small className="text-muted">
                    Allow members to invite their connections If disabled, only
                    admins will be able to invite 1st degree connections to the
                    group. All requests to join will still require admin
                    approval.</small>
                  </p>
                </FormGroup>
                <FormGroup check className="nexogic-check-w extra-space">
                  <Field
                    id="public"
                    name="privacy"
                    type="radio"
                    component={FormikRadioField}
                    value="PUBLIC"
                    label="Public"
                  />
                  <p className="card-text"><small className="text-muted">
                    Require new posts to be reviewed by admins If enabled,
                    members' posts will require admin approval within 14 days
                    before they become visible to others.</small>
                  </p>
                </FormGroup>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="">
              <div className="justify-content-end  modal-footer">
                <div className="action-group">
                  {isLoading ? (
                    <div className="loading-spiner">
                      <Spinner />
                    </div>
                  ) : (
                    <Button id="cgf_btn_group"
                      className="nexogic_primary_button"
                      color="primary"
                      type="submit"
                    >
                      {values?.id ? "Update" : "Create"} Group
                    </Button>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    </>
  );
};

const CreateGroupForm = (props) => {
  const { toggle, alert, selectedItem, handleEditItem, setGroupsList, getData, setGroupHeaderText, onDetailPage } = props;
  const [isLoading, setLoading] = useState(false);
  // useEffect(() => {
  //   console.log(props);
  // }, [props])
  const addItem = (values) => {
    setLoading(true);
    const formData = {
      name: values?.groupName ?? "",
      rules: values?.rules ?? "",
      privacy: values?.privacy,
      description: values?.groupDescription ?? "",
      bannerId: values?.bannerId ?? "",
      isVisible: values?.status === "LISTED",
    };
    ConnectionServices.addGroup(formData).then((response) => {
      const { status, validationErrors } = response;
      setLoading(false);
      if (status === 201) {
        showSuccessMessage("Group created successfully");
        toggle();
        getData(0, []);
      } else {
        if (status === 409) {
          showErrorMessage("Group already exists with this name.");
        }
        else
          showErrorMessage(FAILTOSAVERECOED);
      }
    });
  };

  const updateItem = (values) => {
    setLoading(true);
    const formData = {
      name: values?.groupName ?? "",
      rules: values?.rules ?? "",
      privacy: values?.privacy,
      description: values?.groupDescription ?? "",
      bannerId: values?.bannerId ?? "",
      isVisible: values?.status === "LISTED",
    };
    ConnectionServices.updateGroup(values?.id, formData).then((response) => {
      const { status, validationErrors } = response;
      setLoading(false);
      if (status === 200) {
        if (!onDetailPage)
          getData(0, []);
        if (!onDetailPage)
          setGroupsList((prevState) => {
            const newState = prevState.map((obj) => {
              if (obj.id === values?.id) {
                return { ...response.data };
              }
              return obj;
            });
            return newState;
          });
        showSuccessMessage("Group updated successfully");
        toggle();
      } else {
        showErrorMessage("Somethong is wrong. ", validationErrors);
      }
    });
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        validationSchema={validationSchema}
        initialValues={selectedItem}
        onSubmit={(values) => {
          if (selectedItem.id) {
            updateItem(values);
          } else {
            addItem(values);
          }
        }}
        children={(props) => {
          return (
            <>
              <RenderCreateGroupForm
                toggle={toggle}
                {...props}
                isLoading={isLoading}
                handleEditItem={handleEditItem}
                alert={alert}
                setGroupHeaderText={setGroupHeaderText}
              />
            </>
          );
        }}
      ></Formik>
    </>
  );
};

export default withAlert()(CreateGroupForm);
