import { faArrowLeft, faPaperPlane, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faIdCard, faListAlt, faTable } from "@fortawesome/free-solid-svg-icons";
import AlertDialog from "common/AlertDialog";
import CommonLoader from "common/CommonLoader";
import LoadMoreButton from "common/LoadMoreButton/LoadMoreButton";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { withAlert } from "react-alert";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { useHistory, useParams, Link } from "react-router-dom";
import { Button, Card, CardBody, Spinner } from "reactstrap";
import { ConnectionServices } from "utils/ConnectionServices";
import { FAILED_TO_FETCH_DATA, FAILTOSAVERECOED } from "utils/Constants";
import { encrypt } from "utils/EncryptDecrypt";
import { createImageFromInitials, getRandomColor } from "utils/Utils";
import GroupMembersTabularView from "components/tabularView/GroupMembersTabularView";

const GroupMembersListDetails = forwardRef((props, ref) => {
  const params = useParams();
  const { groupId } = params;
  const { alert } = props;

  const [list_of_data, setList_of_data] = useState([]);
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const [memberIdToDelete, setMemberIdToDelete] = useState(null);
  const [role, setRole] = useState("All members");
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [viewMode, setViewMode] = useState("card");

  const [isOpenAlert, setAlertModal] = useState(false);

  const currentUser = useSelector((state) => state.auth.currentUser);

  const getCurrentUserRole = () => {
    let url = window.location.pathname;
    const myArray = url.split("/");
    let groupId = myArray[myArray.length - 1];

    let userId = currentUser.guid;
    ConnectionServices.hasMembership(userId, groupId).then((response) => {
      if (response.status === 200) {
        setCurrentUserRole(response.data.role);
      } else {
        showErrorMessage(FAILED_TO_FETCH_DATA);
      }
    });
  };


  const getData = async (pageNo, feedlist) => {
    let url = window.location.pathname;
    const myArray = url.split("/");
    let role = myArray[myArray.length - 2];
    if (role === "group-admins")
      setRole("All Admins");
    else
      setRole("All Members");

    ConnectionServices.getGroupMembers(groupId, "ACTIVE", role === 'group-admins' ? "ADMIN" : "MEMBER", pageNo)
      .then((response) => {
        const { status, data } = response;

        if (data?.currentPage < (data?.totalPages - 1))
          setHasMore(true);
        else
          setHasMore(false);

        if (status === 200) {
          // console.log(data);
          setCurrentPage(data?.currentPage)
          setTotalItems(data?.totalItems)
          setTotalPages(data?.totalPages);
          const newData = pageNo === 0 ? data.contents : [...list_of_data, ...data.contents];
          setList_of_data(newData);

          setIsLoadingMore(false);
        }
      })

  };

  const fetchData = () => {
    setIsLoadingMore(true);
    getData(currentPage + 1, list_of_data);
  };

  const toggleAlert = () => {
    setAlertModal(!isOpenAlert);
  };

  useImperativeHandle(ref, () => ({
    getJobListData() {
      getData(0, []);
    },
  }));

  const deleteTheMember = () => {
    ConnectionServices.removeGroupMember(groupId, memberIdToDelete).then(
      (response) => {
        if (response.status === 204) {
          toggleAlert();
          showSuccessMessage("Member removed successfully...");
          getData(0, []);
        } else {
          showErrorMessage("Something went wrong...");
        }
      }
    );
    toggleAlert();
  };

  const handleClickOnYes = () => {
    deleteTheMember();
  };

  useEffect(() => {
    getData(0, []);
    getCurrentUserRole();
  }, []);

  // useEffect(() => {
  //   // This code will run whenever list_of_data changes
  //   console.log("Updated list_of_data:", list_of_data);
  // }, [list_of_data]);


  let history = useHistory();

  const renderProfiles = () => {
    if (viewMode === 'card') {
      return (
        <>
          {list_of_data &&
            list_of_data.map((e, idx) => (
              <CardBody style={{ width: "100%" }}>
                <div key={idx} className="row no-gutters">
                  <Card>
                    <div className="col-auto col-img">
                      {e.memberDetails?.avatarId ? (
                        <img
                          className="card-img rounded card-img-md"
                          src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${e.memberId}/${e.memberDetails.avatarId}`}
                          alt="#"
                        />
                      ) : (
                        <img
                          className="card-img rounded card-img-md"
                          src={createImageFromInitials(
                            64,
                            e.memberDetails?.name,
                            getRandomColor(null)
                          )}
                          alt="profilePic"
                          width={64}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = createImageFromInitials(
                              132,
                              e?.memberDetails?.name,
                              getRandomColor(null)
                            );
                          }}
                        />
                      )}
                    </div>

                    <div className="col col-content-right-btn">
                      <div className="nexogic-content-details">
                        <h3 className="card-title">
                          <Link to={`/profile/${e?.memberDetails?.profileId}`} className="practice-name" >
                            {e?.memberDetails?.name}
                          </Link>
                        </h3>
                        <p className="card-text mb-0">
                          {e?.memberDetails?.primarySpeciality}{" "}
                        </p>
                        <p className="card-text mb-0">
                          {" "}
                          {e?.memberDetails?.location}{e?.memberDetails?.city ? ',' + e?.memberDetails?.city : ''}
                          {e?.memberDetails?.state ? ',' + e?.memberDetails?.state : ''}
                        </p>
                        <p className="card-text">
                          <small className="text-muted">
                            {e.role === "ADMIN" ? "Admin" : "Member"}
                          </small>
                        </p>
                      </div>

                      <div className="nexogic-action-part">
                        {currentUser?.guid !== e?.memberId ? (
                          <Button className="msg-button" outline color="info"
                            onClick={() => { history.push('/messages') }}
                          >
                            <FontAwesomeIcon icon={faPaperPlane} />
                          </Button>
                        ) : (
                          ""
                        )}
                        {currentUserRole === "ADMIN" && e.role != "ADMIN" ? (
                          <Button
                            className="ml-2 msg-button"
                            outline
                            color="danger"
                            onClick={() => {
                              setMemberIdToDelete(e.memberId);
                              toggleAlert();
                            }}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </Button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Card>
                </div>
              </CardBody>
            ))}
        </>
      );
    } else { // For table view
      return (
        <GroupMembersTabularView theader={['Name', 'Speciality', 'Message', 'Remove']} tdata={list_of_data} viewmode={viewMode}
          setMemberIdToDelete={setMemberIdToDelete}
          currentUser={currentUser}
          currentUserRole={currentUserRole}
          toggleAlert={toggleAlert}
        />
      );
    }
  };


  return list_of_data && list_of_data.length > 0 ? (
    <>
      <div className="card mb-3">
        <div className="nexogic-card-heading between " >
          <h3 className="card-title mb-0">

            {role}
          </h3>
          <span className="readmore" onClick={() => history.goBack()}><FontAwesomeIcon
            className="nexogic-go-back"
            icon={faArrowLeft}
          /> Back</span>
        </div>
      </div>

      <div className="view-toggle">
        <h1 style={{ color: "#9c9f9f80" }}>
          <FontAwesomeIcon title="Card View" color={viewMode === "card" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faAddressCard} onClick={() => setViewMode("card")} /> | <FontAwesomeIcon title="Table View" color={viewMode === "table" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faListAlt} onClick={() => setViewMode("table")} />
        </h1>
      </div>

      <Card id="all_admin_lists" className="card-group nexogic-card-group-list card nexogic-connection-card nexogic-custom-scroll">
        <>
          {renderProfiles()}
        </>

      </Card>
      {hasMore &&
        <div className="text-center mt-3">
          {isLoadingMore
            ?
            <CommonLoader style={{ width: "2rem", height: "2rem" }} />
            :
            <LoadMoreButton loadMore={fetchData} />
          }
        </div>
      }

      <AlertDialog
        bodyText={"Are you sure, You want to remove this member?"}
        toggle={toggleAlert}
        isOpen={isOpenAlert}
        // isLoading={isLoading}
        handleClickOnYes={handleClickOnYes}
        yesLabel={"Yes"}
        noLabel={"No"}
      />
    </>

  ) : (
    <div className="loading-spiner text-center mt-5">
      <p>No Member</p>
    </div>
  );
});

export default withAlert()(GroupMembersListDetails);
