import React from 'react';
import { Link, useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import LinesEllipsis from 'react-lines-ellipsis';
import { getFullNameOfPerson } from 'utils/Utils';


const ReferralsTabularView = (props) => {
    const { theader, tdata, viewmode, type } = props;
    let match = useRouteMatch("/referals/received-referrals");

    return (
        <div className="profiles-table">
            <table className="table border" >
                <thead>
                    <tr>
                        {theader.map((columns, index) => (
                            <th className="theader" key={index}>{columns}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tdata.map((profileDetails, index) => (
                        <tr key={`profile_${index}`}>

                            <td className="border">
                                <Link
                                    to={{
                                        pathname: `${match?.url !== undefined ? match?.url : '/referrals/received-referrals'}/` + profileDetails?.id,
                                        state: { type },
                                    }}
                                >
                                    {profileDetails?.referralCaseNo}
                                </Link>
                            </td>

                            <td className="border font-weight-bold">
                                <div className=' d-flex'>
                                    <h4 className="link-text">
                                        <Link to={`/patient-profile/${profileDetails?.patient?.id}`} style={{ color: "#2eabca" }} onClick={() => window.scrollTo(0, 0)}>
                                            {getFullNameOfPerson(profileDetails?.patient?.firstName, profileDetails?.patient?.middleName, profileDetails?.patient?.lastName)}
                                        </Link>
                                    </h4>
                                </div>
                            </td>

                            <td className="border">
                                {typeof profileDetails.specialityRequested != "undefined" &&
                                    <LinesEllipsis
                                        className="card-text"
                                        text={profileDetails.specialityRequested}
                                        maxLine="1"
                                        ellipsis="..."
                                        trimRight
                                        basedOn="letters"
                                    />
                                }
                            </td>
                            <td className="border font-weight-bold">
                                <div className=' d-flex'>
                                    <h4 className="link-text">
                                        {type === "RECEIVED" ?
                                            <Link to={`/profile/` + profileDetails?.referredByPhysician?.profileId} >{profileDetails?.referredByPhysician?.name}</Link>
                                            :
                                            <Link to={`/profile/` + profileDetails?.referredToPhysician?.profileId} >{profileDetails?.referredToPhysician?.name}</Link>
                                        }
                                    </h4>
                                </div>
                            </td>

                            <td className="border">
                                <LinesEllipsis
                                    className="card-text"
                                    text={profileDetails.status}
                                    maxLine="1"
                                    ellipsis="..."
                                    trimRight
                                    basedOn="letters"
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default ReferralsTabularView