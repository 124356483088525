import React from 'react';
import { Link } from "react-router-dom";
import {
    Button,
    ButtonDropdown,
    Card,
    CardImg,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
} from "reactstrap";
import LinesEllipsis from 'react-lines-ellipsis';
import ConnectButton from 'pages/FeedHomePage/components/PractitionerProfiles/ConnectButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from 'react';
import { getAge, getFullNameOfPerson, getGenderDetails } from 'utils/Utils';
import { useState } from 'react';

const GroupRecord = ({ profileDetails, index, e, toggleAlert, setGroupIdToAct, handleResponse }) => {
    const statusArray = ["Accept", "Reject"];
    // const [dropdownOpen, seDropdownOpen] = useState(false);
    // const toggle = () => {
    //     seDropdownOpen(!dropdownOpen);
    // };

    return (
        <tr key={`profile_${index}`}>
            <td className="border font-weight-bold">
                <div className=' d-flex'>
                    <h4 className="link-text">
                        <Link className="group-link nex-gi-group-group-name" to={`/connections/groups/${profileDetails.id}`}>
                            {profileDetails.groupName}
                        </Link>
                    </h4>
                </div>
            </td>
            <td className="border">
                {profileDetails.groupType}
            </td>
            <td className="border">
                {profileDetails.memberDetails.name}
            </td>
            <td className="border">
                {profileDetails.memberDetails.primarySpeciality}
            </td>
            <td>
                <div className="nexogic-action-part">
                    <UncontrolledDropdown >
                        <DropdownToggle
                            size="lg"
                            color="info"
                            outline
                            className="nexogic_primary_button_outline"
                        >
                            {e.status ? e.status : "--Status--"}
                            <FontAwesomeIcon className="ml-2" icon={faChevronDown} />
                        </DropdownToggle>
                        <DropdownMenu className="border-0 px-0">
                            {statusArray.map((item) => (
                                <DropdownItem
                                    tag={"button"}
                                    key={item.name}
                                    onClick={() => {
                                        // console.log("prin1");
                                        handleResponse(
                                            item,
                                            e?.groupId,
                                            e?.memberId
                                        )
                                    }}
                                    className={`nexogic_primary_button_outline mb-1 ${item === "Accept"
                                        ? "text-success"
                                        : null || item === "Reject"
                                            ? "text-danger"
                                            : null
                                        } `}
                                >
                                    {item}
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            </td>
        </tr >
    );
}

const GroupInvitationReceivedTabularView = (props) => {
    // const currentUser = useSelector((state) => state.auth.currentUser);
    const { theader, tdata, activeTab, handleEditItem, reportClick, setGroupIdToAct, toggleAlert, handleResponse } = props;

    // useEffect(() => {
    //     console.log(tdata);
    // }, [tdata])

    return (
        <div className="profiles-table">
            <table className="table border" >
                <thead>
                    <tr>
                        {theader.map((columns, index) => (
                            <th className="theader" key={index}>{columns}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tdata.map((profileDetails, index) => (
                        <GroupRecord
                            profileDetails={profileDetails} index={index} e={profileDetails} handleEditItem={handleEditItem}
                            toggleAlert={toggleAlert}
                            setGroupIdToAct={setGroupIdToAct}
                            handleResponse={handleResponse}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default GroupInvitationReceivedTabularView;