import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CoverImage from "assets/images/defaultBannerNew.png";
import unsplashIcon from "assets/images/svgs/unsplash.svg";
import camera_icon from "assets/images/camera-icon.png";
import delIcon from "assets/images/del-red-icon.png";
import moment from "moment";
import React, { PureComponent, useEffect, useRef, useState } from "react";
import { withAlert } from "react-alert";
import { Cropper } from "react-image-cropper";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
  Progress
} from "reactstrap";
import { authSetUser } from "redux-helpers/ActionCreator";
import swal from "sweetalert";
import { PractitionerService } from "utils/PractitionerService";
import { setUser } from "utils/UserHelper";
import { validateImageSize, validateImageType } from "utils/Utils";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import { toast } from "react-toastify";

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const useHasChanged = (val) => {
  const prevVal = usePrevious(val);
  return prevVal !== val;
};

export class RenderDialog extends PureComponent {
  handleCroppImage = async () => {
    const { uploadImageDetails, onSave, toggle } = this.props;
    const cropImageBase64 = this.cropper.crop();
    const result = await this.resizeBase64Img(cropImageBase64);
    const unixTime = moment().unix();
    const res = await fetch(result);
    const blob = await res.blob();
    const file = new File([blob], `${unixTime}_${uploadImageDetails.name}`, {
      type: uploadImageDetails.type,
    });
    const validSize = validateImageSize(file, 1048576);
    toggle();
    if (validSize === 1) {
      onSave(file);
    } else {
      this.handleCancel();
      showErrorMessage("Image size should be less than 1 MB");
    }
  };

  resizeBase64Img = async (base64Str, MAX_WIDTH = 1450, MAX_HEIGHT = 290) => {
    return await new Promise((resolve) => {
      let img = new Image();
      img.src = base64Str;
      img.onload = () => {
        let canvas = document.createElement("canvas");
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL()); // this will return base64 image results after resize
      };
    });
  };

  handleCancel = () => {
    const { onSave, toggle } = this.props;
    toggle();
    onSave("");
  };

  render() {
    const { modal, toggle, profilePicUrl } = this.props;
    return (
      <div>
        <Modal
          isOpen={modal}
          toggle={toggle}
          className="modal-dialog-scrollable modal-dialog-centered modal-crop"
        >
          <ModalHeader toggle={toggle}>Banner Image</ModalHeader>
          <ModalBody className="px-1 px-sm-4">
            <div
              className="nexogic-cover-cropper crop-disabled d-flex align-items-center justify-content-center mx-auto"
            >
              <Cropper
                src={profilePicUrl}
                ref={(ref) => {
                  this.cropper = ref;
                }}
                ratio={5 / 1}
              />
            </div>
          </ModalBody>
          <ModalFooter className="justify-content-end  modal-footer extra-x-padd">
            <div className="action-group">
              <div className="banner-image-caption"><p>The image size must be less than 5 mb and it should be 1000 px wide and 200 px tall. </p></div>
              <Button className="nexogic_primary_button" color="primary" onClick={(e) => this.handleCroppImage(e)}>
                Save
              </Button>{" "}
              <Button
                color="link"
                className="nexogic_primary_button_outline"
                onClick={(e) => this.handleCancel(e)}
              >
                Cancel
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const RenderCoverImage = (props) => {
  const { practitioner, isLoggedinUser, isAdmin, alert } = props;
  const [modal, setModal] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [uploadImageDetails, setImageDetails] = useState({
    name: "",
    type: "",
  });
  const [uploadImage, setImage] = useState("");
  const [sourceImage, setSourceImage] = useState("");
  const [uProgress, setUProgress] = useState(0);
  const toggle = () => setModal(!modal);
  const hasVal1Changed = useHasChanged(practitioner?.profileId);
  const dispatch = useDispatch();
  const currentUser = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );
  const [showDeleteButton, setShowDeleteButton] = useState(false);

  const handleImageUpload = (event) => {
    let file = event.target.files[0];
    if (validateImageType(file)) {
      // fileSize validation
      const maxSize = 1000 * 1000 * 10; //10MB
      const validSize = validateImageSize(file, maxSize);
      if (validSize === 1) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const imageBase64 = reader.result;
          setImageDetails({ name: file.name, type: file.type });
          setImage(imageBase64);
          toggle();
        };
      } else if (validSize === -1) {
        showErrorMessage("Invalid File!");
      } else {
        showErrorMessage("File must be less than 1MB");
      }
    } else {
      showErrorMessage("Invalid File!");
    }
  };

  const onLoadingDefaultImage = async (guidId, imageId) => {
    let imageSet = "";
    if (guidId && imageId) {
      imageSet = `${process.env.REACT_APP_IMAGE_BASE_URL}/${guidId}/${imageId}`;
      setShowDeleteButton(true);
    }
    setImage(imageSet);
    setSourceImage(imageSet);
  };

  const onImageChange = (file) => {
    alert.removeAll();
    if (file) {
      setSpinner(true);
      const options = (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent <= 100) {
          setUProgress(percent);
        }
      }
      PractitionerService.new_fileUpload(file, currentUser.guid, "banner", options).then(
        (response) => {
          if (response.status === 200 || response.status === 201) {
            onLoadingDefaultImage(practitioner?.guid, response.data);
            const updatedUserDetails = {
              ...currentUser,
              bannerId: response.data,
            };
            if (!isAdmin) {
              dispatch(authSetUser(updatedUserDetails));
              setUser(updatedUserDetails);
            }
            setShowDeleteButton(true);
            showSuccessMessage("Banner Image uploaded successfully");
            if (uProgress <= 100) {
              setTimeout(() => {
                setUProgress(0)
              }, 1000);
            }
          } else {
            showErrorMessage("Something went wrong while uploading your profile picture");
            setSpinner(false);
          }
        },
        (err) => {
          setSpinner(false);
          onLoadingDefaultImage(practitioner?.guid, "");
          console.log("failed banner upload", err.message);
          showErrorMessage(err.message);
        }
      );
    }
  };

  const removeCoverImage = () => {
    toast.removeAll();
    swal("Are you sure you want to remove banner image?", {
      buttons: ["cancel", "yes"],
    })
      .then((yesRemove) => {
        if (yesRemove) {
          setSpinner(true);
          PractitionerService.new_deletePractitionerImage(
            currentUser.guid,
            "banner"
          ).then(
            (response) => {
              setSpinner(false);
              const { status } = response;
              if (status === 204) {
                setShowDeleteButton(false);
                const updatedUserDetails = {
                  ...currentUser,
                  bannerId: "",
                };
                if (!isAdmin) {
                  dispatch(authSetUser(updatedUserDetails));
                  setUser(updatedUserDetails);
                  onLoadingDefaultImage(practitioner?.guid, "");
                }
                showSuccessMessage("Cover image removed successfully");
                setImage("");
                setSourceImage("")
              } else {
                showErrorMessage("Error removing banner");
                setSpinner(false);
              }
            },
            (err) => {
              setSpinner(false);
              let errmsg = err.message || "Error removing banner";
              if (err.response) {
                errmsg =
                  err.response.data && !!err.response.data.message
                    ? err.response.data.message
                    : errmsg;
              }
              showErrorMessage(errmsg);
            }
          );
        }
      })
      .catch((err) => {
        showErrorMessage("Error removing banner");
        setSpinner(false);
      });
  };

  useEffect(() => {
    if (hasVal1Changed) {
      onLoadingDefaultImage(practitioner?.guid, practitioner?.bannerId);
    }
  });


  return (
    <div className="cover-bg position-relative">
      <RenderDialog
        modal={modal}
        toggle={toggle}
        profilePicUrl={uploadImage}
        uploadImageDetails={uploadImageDetails}
        onSave={onImageChange}
        alert={alert}
      />
      {uProgress > 0 ? (
        <div className="nexogic-banner-progress nexogic-profile-pbar">
          <Progress value={uProgress} max="100">{uProgress}</Progress>
        </div>
      ) : ""}
      <div className="actions">
        {showDeleteButton && (isLoggedinUser || isAdmin) && (
          <Button
            id="nex-rc-del-cover-btn"
            className="delete-cover-btn"
            onClick={removeCoverImage}
          >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M7.24994 2.75C7.24994 1.23122 8.48116 0 9.99994 0C11.5187 0 12.7499 1.23122 12.7499 2.75V2.91565C15.1349 3.2059 17.363 3.86798 19.3285 4.8258C19.7008 5.00725 19.8556 5.4562 19.6741 5.82855C19.4927 6.20091 19.0437 6.35566 18.6714 6.1742C16.1797 4.95998 13.2042 4.25 9.99993 4.25C6.79567 4.25 3.82012 4.95998 1.32848 6.1742C0.956128 6.35566 0.507177 6.20091 0.325721 5.82855C0.144266 5.4562 0.299018 5.00725 0.671371 4.8258C2.63686 3.86797 4.86496 3.2059 7.24994 2.91565V2.75ZM16.2653 16.283C16.1158 18.3763 14.374 19.998 12.2755 19.998H7.72445C5.62589 19.998 3.88413 18.3763 3.73461 16.283L3.04639 6.6479C5.16371 5.90959 7.5187 5.49805 9.99997 5.49805C12.4812 5.49805 14.8362 5.90959 16.9536 6.6479L16.2653 16.283ZM8.74997 8.99805C8.74997 8.58383 8.41418 8.24805 7.99997 8.24805C7.58576 8.24805 7.24997 8.58383 7.24997 8.99805V14.998C7.24997 15.4123 7.58576 15.748 7.99997 15.748C8.41418 15.748 8.74997 15.4123 8.74997 14.998V8.99805ZM12 8.24805C12.4142 8.24805 12.75 8.58383 12.75 8.99805V14.998C12.75 15.4123 12.4142 15.748 12 15.748C11.5858 15.748 11.25 15.4123 11.25 14.998V8.99805C11.25 8.58383 11.5858 8.24805 12 8.24805Z" fill="#C93B3C" />
            </svg>

          </Button>
        )}
        {(isLoggedinUser || isAdmin) && (
          <Button className="edit-cover-btn" id="nex-edit-cover-btn">
            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon">
              <path fillRule="evenodd" clipRule="evenodd" d="M6.40627 0.890599L5 3H4C1.79086 3 0 4.79086 0 7V14C0 16.2091 1.79086 18 4 18H16C18.2091 18 20 16.2091 20 14V7C20 4.79086 18.2091 3 16 3H15L13.5937 0.8906C13.2228 0.334202 12.5983 0 11.9296 0H8.07037C7.40166 0 6.7772 0.334202 6.40627 0.890599ZM13 10.5C13 12.1569 11.6569 13.5 10 13.5C8.34315 13.5 7 12.1569 7 10.5C7 8.84315 8.34315 7.5 10 7.5C11.6569 7.5 13 8.84315 13 10.5ZM10 4C10.5523 4 11 3.55228 11 3C11 2.44772 10.5523 2 10 2C9.44771 2 9 2.44772 9 3C9 3.55228 9.44771 4 10 4Z" fill="#28303F" />
            </svg>
            <input
              name="coverImage"
              className=""
              type="file"
              onChange={(event) => {
                handleImageUpload(event)
                event.target.value = null;
              }}
              id="coverImage"
              accept="image/*, png, jpg, jpeg"
              title=""
            />
            <span>Edit cover photo</span>
          </Button>
        )}

      </div>
      <img
        src={sourceImage ? sourceImage : CoverImage}
        onLoadStart={() => setSpinner(true)}
        onLoad={() => setSpinner(false)}
        className="card-img"
        alt="#"
      />

    </div>
  );
};

export default withAlert()(RenderCoverImage);
