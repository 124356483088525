import { faMedrt } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AlertDialog from "common/AlertDialog";
import { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import {
  profileAddEvent,
  updateProfileCompletness
} from "redux-helpers/ActionCreator";
import { UserStatusConstants } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import {
  convertToMMMYYYYFormat,
  dateDifferenceInYearsAndMonths,
  getEmptySectionShowHide,
  getFullAddress,
  makeFullNameWithAddressWhenAddressIsNested,
  renderDateRange
} from "utils/Utils";
import { RenderProMembershipModal } from "./component/RenderProMembershipModal";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import certifications_icon from "assets/svgIcon/certifications_icon.svg";
import plusIcon from "assets/images/plus-icon-lg.png";
import edit_blue_icon from "assets/images/edit-blue-icon.png";
import del_red_icon from "assets/images/del-red-icon.png";
import NoData from "../NoData";

const RenderProMember = (props) => {
  const {
    isAdmin,
    proMembers,
    handleEditItem,
    handleRemoveItem,
    isLoggedinUser,
  } = props;
  const currentUser = useSelector((state) => state.auth.currentUser);
  if (proMembers && proMembers.length > 0) {
    return proMembers.map((item) => {
      const { doesNotExpire, memberSince, expiredOn } = item;
      const profileIdLink = item?.institutionDetail?.profileId
        ? item?.institutionDetail?.profileId
        : item?.institutionDetail?.guid;
      const institutionName = getFullAddress({
        name: item?.institutionDetail?.name ?? "",
        abbreviation: item?.institutionDetail?.abbreviation ?? "",
        location: item?.institutionDetail?.address?.location ?? "",
        city: item?.institutionDetail?.address?.city ?? "",
        state: item?.institutionDetail?.address?.state ?? "",
      });
      item.memberSince = item?.memberSince ?? "";
      item.expiredOn = item?.expiredOn ?? "";
      item.doesNotExpire = item?.doesNotExpire ?? false;
      item.currentMember = item?.doesNotExpire ?? false;
      item.memberShipName = item?.name ?? "";
      item.startYear = item?.memberSince ?? "";
      item.endYear = item?.expiredOn ?? "";
      if (item?.institutionDetail?.guid) {
        item.institutionDetail = {
          guid: item?.institutionDetail?.guid,
          name: item?.institutionDetail ? makeFullNameWithAddressWhenAddressIsNested(item?.institutionDetail) : "",
          institutionName: item?.institutionDetail?.name,
          onSelectNewInstitutionCollege: 1,
        };
      }

      return (
        <li
          className="list-group-item"
          key={`promembership_${item.guid}`}
        >
          <div className="row g-0">

            <div className="col-auto">
              <img src={certifications_icon} alt="" />
            </div>
            <div className="col">
              <h3 className="card-title">{item.memberShipName}</h3>
              <p className="card-title mb-0"><small className="text-muted fw-semibold"><Link
                to={`/institution/${profileIdLink}`}
                className="mb-2 color-accent"
              >{institutionName}</Link></small></p>
              <p className="card-text"><small className="text-muted">{item?.membershipNumber &&
                <>
                  <FontAwesomeIcon
                    icon={faMedrt}
                    title="Membership Details"
                  />{" "}
                  {item.membershipNumber}
                </>
              }</small></p>
              {item?.memberSince && <p className="card-text"><small>
                Issued On: {convertToMMMYYYYFormat(item?.memberSince)} {" - "}
                {item?.doesNotExpire ? <span><span>{"Present | "}</span><span>{dateDifferenceInYearsAndMonths(item?.memberSince)}</span></span> : <span>{convertToMMMYYYYFormat(item?.expiredOn)}</span>}
              </small></p>}
            </div>

            {/** 
            <div className="flex-grow-1">
              <div className="d-flex">
                <div className="img-32px">
                  <FontAwesomeIcon icon={faUniversity} size="2x" />
                </div>
                <div className="ml-0">
                  <h6 className="section-item-title f-14">
                    {item.memberShipName}
                  </h6>
                  <h6 className="f-14 m-0 text-primary">
                    <Link
                      to={`/institution/${profileIdLink}`}
                      className="mb-2 color-accent"
                    >
                      {institutionName}
                    </Link>
                  </h6>
                  <div
                    className="section-item-shortdes f-14 mt-2 text-capitalize"
                    
                  >
                    {item?.membershipNumber &&
                      <>
                        <FontAwesomeIcon
                          icon={faMedrt}
                          
                          title="Membership Details"
                        />{" "}
                        {item.membershipNumber}
                      </>
                    }
                  </div>
                  {item?.memberSince && (
                    <div
                      className="section-item-shortdes mt-2 text-capitalize"
                      
                    >
                      Issued On: {timeStamp}
                    </div>
                  )}
                </div>
              </div>
            </div>

*/}

            <div className="col-auto d-flex align-items-start">
              {(isAdmin ||
                (isLoggedinUser &&
                  currentUser.status === UserStatusConstants.APPROVED)) && (
                  <div className="delete-action">
                    <Button
                      color="link"
                      className="visible-hover py-0 nex-pm-professional-edit-btn"
                      onClick={() => handleEditItem(item)}
                    >
                      {/* <img src={grayPencilIcon} /> */}
                      <img src={edit_blue_icon} alt="" />
                    </Button>

                    <Button
                      color="link"
                      className="visible-hover delete py-0 nex-pm-professional-del-btn"
                      onClick={() => handleRemoveItem(item)}
                    >
                      {/* <img src={grayTrashIcon} /> */}
                      <img src={del_red_icon} alt="" />
                    </Button>
                  </div>
                )}
            </div>
          </div>
        </li>
      );
    });
  }
  return null;
};

const ProfessionalMemberships = (props) => {
  const [pannel_on, setPannel_on] = useState(true);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const eventName = useSelector((state) => state.profileComplete.eventName);
  const dispatch = useDispatch();
  const { alert, isLoggedinUser, isAdmin, setShowMembershipOnTabScroll } = props;
  const { guid } = props.practitioner;
  const [proMembers, setProMembers] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [isOpen, setModal] = useState(false);
  const toggle = () => setModal(!isOpen);
  const initialValue = {
    institutionDetail: {},
    memberShipName: "",
    positionHeld: "",
    memberSince: "",
    startYear: "",
    endYear: "",
    membershipNumber: "",
    expiredOn: "",
    doesNotExpire: false,
    currentMember: false,
    addressLine1: "",
    addressLine2: "",
    city: [],
    country: [],
    location: [],
    postalCode: "",
    state: [],
    category: "",
  };
  const [isOpenAlert, setAlertModal] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(updateProfileCompletness(7, !!proMembers.length));
    if (proMembers.length > 0 || isLoggedinUser || isAdmin) {
      setShowMembershipOnTabScroll(true);
    }else{
      setShowMembershipOnTabScroll(false);
    }
  }, [proMembers]);

  useEffect(() => {
    if (eventName === "ADD_MEMBERSHIPS") {
      handleEditItem(initialValue);
      dispatch(profileAddEvent(null));
    }
  }, [eventName]);

  const toggleAlert = () => {
    setLoading(false);
    setAlertModal(!isOpenAlert);
  };

  useEffect(() => {
    getData();
  }, [guid]);

  const handleEditItem = (selectedItem) => {
    setSelectedItem(selectedItem);
    toggle();
  };

  const handleRemoveItem = (selectedItem) => {
    setSelectedItem(selectedItem);
    toggleAlert();
  };

  const handleClickOnYes = async () => {
    setLoading(true);
    try {
      const request = PractitionerService.deleteProMembership(
        guid,
        selectedItem.guid
      );
      request.then((response) => {
        const { status } = response;
        if (status === 204) {
          setTimeout(() => {
            showSuccessMessage("Membership deleted successfully");
            getData();
            setLoading(false);
            toggleAlert();
          }, 1000);
        } else {
          showErrorMessage("Error deleting membership");
          setLoading(false);
          toggleAlert();
        }
      });
    } catch (e) {
      showErrorMessage(e.message);
      setLoading(false);
      toggleAlert();
    }
  };

  const getData = () => {
    const response = PractitionerService.getProMembership(guid);
    response.then((response) => {
      let { status, data } = response;
      if (status === 200 || status === 201) {
        if (data) {
          setProMembers(data);
        }
      } else {
        setProMembers([]);
      }
    });
  };

  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );

  if (
    getEmptySectionShowHide(
      proMembers ?? [],
      isAdmin,
      currentUser,
      globalProfileInfo,
      guid
    )
  )
    return null;

  return (
    <>
      <Card className={`nexogic-card-color-header ${pannel_on ? 'expanded' : ''}`} id="professionalSection">
        <div className="card-header"><h2>Professional Memberships</h2>


          {(isAdmin ||
            (isLoggedinUser &&
              currentUser.status === UserStatusConstants.APPROVED)) && (
              <Button
                id="nex-pm-professional-add-btn"
                color="link"
                className="add-button"
                onClick={() => handleEditItem(initialValue)}
              >
                <img src={plusIcon} alt="" />
              </Button>
            )}
          <button className="collapse-btn" type="button" onClick={() => setPannel_on(!pannel_on)}>
            <img src={pannel_arrow} alt="" />
          </button>
        </div>
        <div className={`collapse-div`}>
          <CardBody className={
            proMembers && proMembers.length > 0 ? "" : "align-items-center"
          }>
            {proMembers && proMembers.length
              ? ""
              : (isLoggedinUser || isAdmin) &&
              currentUser.status === UserStatusConstants.APPROVED && (
                <Button
                  id="nex-pm-professional-add-btn2"
                  className="readmore"
                  color="link"
                  onClick={() => handleEditItem(initialValue)}
                >
                  {"Add your membership"}
                </Button>
              )}
            {/** 
          <Row
            
          >
            <Col>
              <h3 className="section-title m-0 mb-3">
                Professional Memberships
              </h3>
              {proMembers && proMembers.length
                ? ""
                : (isLoggedinUser || isAdmin) &&
                currentUser.status === UserStatusConstants.APPROVED && (
                  <div className="mt-3">
                    <Row className="align-items-center">
                      <Col className="add-infomation-part">
                        <Button
                          className="add-infomation p-0"
                          color="link"
                          onClick={() => handleEditItem(initialValue)}
                        >
                          {"Add your membership"}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                )}
            </Col>
            {(isAdmin ||
              (isLoggedinUser &&
                currentUser.status === UserStatusConstants.APPROVED)) && (
                <Col xs="auto">
                  <Button
                    color="link"
                    className="round-icon-btn rounded-circle"
                    onClick={() => handleEditItem(initialValue)}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </Button>
                </Col>
              )}
          </Row>
*/}

            <ul className="list-group list-group-flush" id="nex-pm-professional-lists">
              <RenderProMember
                proMembers={proMembers}
                handleEditItem={handleEditItem}
                handleRemoveItem={handleRemoveItem}
                isLoggedinUser={isLoggedinUser}
                isAdmin={isAdmin}
              />
            </ul>
            {proMembers.length < 1 && 
            <NoData>No Data</NoData>
            }
            <RenderProMembershipModal
              selectedItem={selectedItem}
              toggle={toggle}
              isOpen={isOpen}
              practitionerId={guid}
              getData={getData}
              isAdmin={isAdmin}
            />
            <AlertDialog
              toggle={toggleAlert}
              isOpen={isOpenAlert}
              isLoading={isLoading}
              handleClickOnYes={handleClickOnYes}
              yesLabel={"Yes"}
              noLabel={"No"}
            />
          </CardBody>
        </div>
      </Card>
    </>
  );
};

export default withAlert()(ProfessionalMemberships);
