import React, { useState } from "react";
// import  from "";
import { Nav, NavItem, NavLink } from "reactstrap";
// import {  } from "react-router-dom";
import GroupFollow from "./GroupFollow";
import Invitation from "./Invitation";
import Pending from "./Pending";
const GroupInvitation = () => {
  const [activeTab, setActiveTab] = useState("0");
  const handleToggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <div>
      <Nav tabs className="nexogic-tabs">
        <NavItem>
          <NavLink
            id="nex-gi-invitation-link"
            className={{
              active: activeTab === "0",
            }}
            onClick={() => {
              handleToggle("0");
            }}
          >
            Invitation
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            id="nex-gi-Pending-link"
            className={{
              active: activeTab === "1",
            }}
            onClick={() => {
              handleToggle("1");
            }}
          >
            Pending
          </NavLink>
        </NavItem>
      </Nav>
      {activeTab === "0" && <Invitation activeTab={activeTab} />}
      {activeTab === "1" && <Pending activeTab={activeTab} />}
    </div>
  );
};

export default GroupInvitation;
