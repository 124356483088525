import profile_filter_icon from "../../../../assets/images/filter_icon.png"
import CityAutocompleteTypeaheadField from "components/forms/formikFields/CityAutocompleteTypeaheadField";
import PrimarySpecialityTypeaheadField from "components/forms/formikFields/PrimarySpecialityTypeaheadField";
import { Field, Formik } from "formik";
import React, { memo, useEffect, useState } from "react";
import { Button, Col, Collapse, FormGroup, Row } from "reactstrap";
import { MetadataService } from "utils/MetadataService";
import * as Yup from "yup";

const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string(),
    city: Yup.array().nullable(),
    primarySpeciality: Yup.array().nullable(),
  });

const AdvancedSearch = (props) => {
  const { initialValues, setIsLoading, fetchProfileData, setTotalItems } = props;
  const [primarySpecialityList, setPrimarySpecialityList] = useState([]);
  const [showAdvanceFields, setShowAdvanceFields] = useState(false);

  const getprimarySpeciality = () => {
    MetadataService.getPrimarySpecialities()
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          // console.log(response.data);
          setPrimarySpecialityList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getprimarySpeciality error", error);
      });
  };

  const handleFilter = (values) => {
    setTotalItems(0);
    setIsLoading(true);
    fetchProfileData(values);
  };

  useEffect(() => {
    getprimarySpeciality();
  }, []);

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleFilter}
      children={(props) => {
        const { values, handleSubmit } = props;
        return (
          <form onSubmit={handleSubmit}>
            <div className={`profile-search-wrap nexogit-filter-options ${showAdvanceFields ? 'profile-search-expand' : ''}`}>
              <Row>
                <Col md={5}>
                  <FormGroup>
                    <Field
                      name="name"
                      id="name"
                      autoComplete="off"
                      className="search-icon form-control"
                      placeholder="Search by name"
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <Field
                    name="city"
                    id="city"
                    autoComplete="off"
                    type="text"
                    component={CityAutocompleteTypeaheadField}
                    placeholder="Select Location"
                    className="form-control"
                  />
                </Col>
                <Col className="col d-flex btn-groups">
                  <Button
                    color="primary"
                    className="nexogic_primary_button"
                    type="submit"
                  >
                    Search
                  </Button>
                  <i className="more-filter-btn" style={{ cursor: 'pointer' }} onClick={() => setShowAdvanceFields(!showAdvanceFields)}><img src={profile_filter_icon} alt="profile_filter_icon" /></i>
                </Col>
                {showAdvanceFields &&
                  <Col className="specialities-col form-group" md={12}>
                    <Field
                      id="primarySpeciality"
                      name="primarySpeciality"
                      autoComplete="off"
                      className="primary-specialities"
                      type="text"
                      component={PrimarySpecialityTypeaheadField}
                      placeholder="Select Speciality"
                      inputprops={{
                        autoComplete: "off",
                      }}
                      value={values.primarySpeciality}
                      spellCheck={false}
                      specialit={primarySpecialityList}
                    />
                  </Col>
                }
              </Row>
              <Row>
              </Row>
            </div>
          </form>
        );
      }}
    />
  );
};

export default memo(AdvancedSearch);
