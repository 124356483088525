import React, { useRef, useCallback } from "react";
import { useState } from "react";
import AlertDialog from "common/AlertDialog";
import { withAlert } from "react-alert";
import { Field, FieldArray } from "formik";
import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import ReactQuill from "react-quill";
import { FAILTOSAVERECOED } from "utils/Constants";
import { useDispatch } from "react-redux";
import { Col, FormGroup, Card, CardImg, Label, Row, Button, FormFeedback, } from "reactstrap";
import "../apply-jobs.css";
import { formatBytes, dateFormat } from "utils/Utils";
import { useEffect } from "react";
import { PractitionerService } from "utils/PractitionerService";
import { faAccessibleIcon, faTrashCan } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import wordFileIcon from "assets/images/svgs/word-file-icon.svg";
import pdfFileIcon from "assets/images/svgs/pdf-file-icon.svg";

const removeSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    className="bi bi-trash"
    viewBox="0 0 16 16"
  >
    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
    <path
      fillrule="evenodd"
      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
    />
  </svg>
);
const Resume = (props) => {
  const { setIsResumePresent, formData, setFormData, values, handleSubmit, setValue, setFieldValue, touched, errors, setErrors, loading, alert, practitioner, practitionerResumes, getProfileResumes, jobDet } = props;
  const [file, setFile] = useState(null);
  const [fileType, setFileType] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [resumes, setResumes] = useState(practitionerResumes);
  const [fileNm, setFileNm] = useState();
  const [selectFlag, setSelectFlag] = useState({});
  const [isOpenAlert, setAlertModal] = useState(false);
  let btnFlag = null;

  const handleFileUpload = (e) => {
    const tempFile = e.target.files[0];
    if (tempFile.type === "application/msword" || tempFile.type === "application/pdf" || tempFile.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      handleChange(tempFile);
      setIsResumePresent(true);
    } else {
      setErrors({ hiddenFile: 'Please upload only doc/docx/pdf type resume' })
      return false;
    }
  }
  const handleChange = (fileDetails) => {
    setFormData({ ...formData, resumeDetails: fileDetails, hiddenFile: fileDetails?.name?.split('.').pop(), cvID: "" });
    var ext = fileDetails?.name?.split('.').pop();
    var fileExt = ext?.toUpperCase();
    setFileType(fileExt);
    setFileNm(fileDetails?.name);
    setIsResumePresent(true);
  }

  const onClose = (e) => {
    setIsLoading(true);
    setFormData({ ...formData, resumeDetails: null });
    setIsResumePresent(false);
    if (selectFlag !== null)
      selectFlag?.classList.remove('active');
  }

  const onDelete = (e) => {
    setIsLoading(true);
    toggleAlert();
    setIsLoading(false);;
    setIsResumePresent(false);
  }

  const toggleAlert = () => {
    setAlertModal(!isOpenAlert);
  };

  const handleClickOnYes = () => {
    PractitionerService.deletePractitionerResume(practitioner.guid, formData.cvID)
      .then((response) => {
        const { status } = response;
        if (status === 204) {
          alert.success("Resume Removed Successfully...");
          setFormData({ ...formData, resumeDetails: null })
          setFile(null);
          setResumes(getProfileResumes(practitioner.guid));
          setIsResumePresent(false);
        }
        else {
          alert.error(FAILTOSAVERECOED);
        }
      })
    toggleAlert();
  }
  const selectResume = (resume, evt) => {
    setSelectFlag(evt.target);
    var header = document.getElementById("docBtn");
    var btns = header.getElementsByClassName("btnResume");
    for (var i = 0; i < btns.length; i++) {
      if (evt.target.id === btns[i].id) {
        evt.target.classList.add("active");
      }
      else {
        btns[i].classList.remove('active');
      }
    }
    setFormData({ ...formData, resumeDetails: resume, hiddenFile: resume?.fileName?.split('.').pop(), cvID: resume?.id });
    const ext = resume?.fileName?.split('.').pop();
    const fileExt = ext?.toUpperCase();
    setFileType(fileExt);
    setFileNm(resume?.fileName);
  }

  return (
    <>
      <div className="nexogic-job-popup">
        <h4 className="card-title mb-2">Resume</h4>
        <div className="resume-btn-wrap">
          {practitionerResumes?.length > 0 ? (
            <div className="d-flex flex-wrap pb-3" id="docBtn">
              {practitionerResumes.map((resume, index) => {
                return (
                  <Button name={`btn${index}`} key={index} id={`btn${index}`} className="btnResume" onClick={(evt) => selectResume(resume, evt)}>
                    <div key={index} style={{ pointerEvents: "none" }}>
                      <div className="card-thumb">{resume.mimeType === 'application/pdf' ? <img src={pdfFileIcon} /> : <img src={wordFileIcon} />}</div>
                      <div className="text-truncate card-text filename">{resume.fileName}</div>
                      <div><small>{formatBytes(resume.size)}</small></div>
                      <div className="card-text"><small>{dateFormat(resume.createdOn)}</small></div>
                    </div>
                  </Button>
                )
              })}
            </div>
          ) : ""}
          <div className="up-resme-wrp file-upl w-100">
            {(formData.resumeDetails === null) ? (
              <Card className="w-100 p-3">
                <div className="file-upload">
                  Upload Resume
                  <Field type="file" name="file" id="resume_file"
                    onChange={handleFileUpload}
                    accept=".pdf,.doc,.docx" />
                </div>
                <p className="f-10 line-h-20 text-center" >
                  Please upload resume in “pdf” ,“doc” , “docx” type
                </p>
                <Field type="hidden" className="form-control" name="hiddenFile" value="" id="resume_hiddenFile" />
              </Card>
            ) : (
              <div className="selected-resume-w">
                <Card >
                  <div className="d-flex align-items-center">
                    <div>
                      {(formData.cvID === "" || formData.cvID === undefined) ? (
                        <div className="rounded">
                          <div className={formData?.resumeDetails?.type === 'application/pdf' ? "filetype text-white bg-danger" : "filetype bg-primary text-white"}>
                            {formData?.resumeDetails?.type === "application/pdf" ? "PDF" : "DOC"}

                            {/* <Button type="button" className="deletefile mt-4 text-danger" onClick={onDelete}>{removeSVG()}</Button> */}
                          </div>
                          <div className="filedet"> {formData?.resumeDetails?.name} </div>
                          <div className="actions">
                            <Button id="resume_btn_closefile" type="button" className="closefile" onClick={onClose}>x</Button>
                          </div>
                        </div>
                      ) : (
                        <div className="rounded ">
                          <div className={formData?.resumeDetails?.mimeType === 'application/pdf' ? "filetype  text-white bg-danger" : "filetype bg-primary text-white"}>
                            {formData?.resumeDetails?.mimeType === "application/pdf" ? "PDF" : "DOC"}

                          </div>
                          <div className="filedet"> {formData?.resumeDetails?.fileName} </div>
                          <div className="actions">
                            <Button id="resume_btn_closefile" type="button" className="closefile" onClick={onClose}>&times;</Button>
                            <Button id="resume_btn_deletefile" type="button" className="deletefile mt-4 text-danger" onClick={onDelete}>{removeSVG()}</Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Card>
              </div>
            )}
          </div>
          {touched && errors && (
            <FormFeedback className="d-block mb-3 text-center w-100" invalid={!!errors?.hiddenFile}>
              {errors.hiddenFile}
            </FormFeedback>
          )
          }
          <div className="coverletter-wrap">
            <h4 className="titles">Cover Letter</h4>
            <div className="covUpload">
              <FormGroup>
                <ReactQuill
                  onChange={(event) => {
                    setFormData({ ...formData, coverLetter: event })
                  }}
                  value={formData.coverLetter}
                  name="coverLetter"
                  className="coverletter-quill"
                />
              </FormGroup>
            </div>
          </div>
          <Row>
            <Col lg={12} className="d-flex">
              <FieldArray name="answers" render={(arrayHelpers) => (
                <div className="contact-card border-0 p-0">
                  {jobDet?.screeningQuestions !== null && (jobDet?.screeningQuestions?.length > 0) && (
                    <fieldset className="u-flsd-wpr mt-1 mb-4">
                      <legend>Screening Questions</legend>
                      <div className="prev-address">
                        {jobDet?.screeningQuestions?.map((squestion, index) => {
                          return (
                            <FormGroup key={index}>
                              <p className="card-text mb-2">{squestion.question}</p>
                              {(() => {
                                if (squestion?.renderType === "TEXT") {
                                  return (
                                    <>
                                      <Field
                                        id={`answers[${index}].answer`}
                                        name={`answers[${index}].answer`}
                                        type="text"
                                        component={FormikInputField}
                                        value={values?.answers[index]?.answer}
                                        placeholder="Type your answer"
                                      />
                                    </>
                                  )
                                } else if (squestion?.renderType === "NUMBER") {
                                  return (
                                    <>
                                      <Field
                                        id={`answers[${index}].answer`}
                                        name={`answers[${index}].answer`}
                                        type="number"
                                        className="form-control"
                                        value={values?.answers[index]?.answer}
                                        min="0"
                                        placeholder="Enter a number"
                                      />
                                    </>
                                  )
                                } else {
                                  return (
                                    <>
                                      <Field
                                        id={`answers[${index}].answer`}
                                        name={`answers[${index}].answer`}
                                        type="select"
                                        component={FormikSelectField}
                                        value={values?.answers[index]?.answer}
                                        inputprops={{
                                          name: `answers[${index}].answer`,
                                          defaultOption: "Select",
                                          options: squestion.options,
                                        }}
                                      />
                                    </>
                                  )
                                }
                              })()}
                            </FormGroup>
                          )
                        })}
                      </div>
                    </fieldset>
                  )}
                </div>
              )}
              />
            </Col>
          </Row>
        </div>
      </div>
      <AlertDialog
        bodyText={"Are you sure to delete this Resume ? "}
        toggle={toggleAlert}
        isOpen={isOpenAlert}
        handleClickOnYes={handleClickOnYes}
        yesLabel={"Yes"}
        noLabel={"Cancel"}
      />
    </>
  );
};

export default withAlert()(Resume);
