
import { Field, Formik } from "formik";
import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import BackIcon from "assets/images/backIcon.png";
import AddPatientForm from "./Components/AddPatientForm";
import { Card, CardBody, Col, Row } from "reactstrap";
import SecondOpinionImg from "assets/images/second-opinion.png";
import formHeaderImg from "assets/images/second-opi-circle-img.png";



const PatientForm = () => {
    const [errorMsg, setErrorMessage] = useState("");
    const history = useHistory();
    const handleBack = () => {
        history.goBack()
    }
    const initialValues = {
        firstName: "",
        lastName: "",
        gender: "",
        phoneNumber: "",
        emailAddress: ""
    }


    return (
        <>
            <div className="d-flex justify-content-between align-items-center mb-3 nex-app-patient-ref-title">
                <div className="nexogic-top-section-title ">
                                <span><Link to="/" onClick={handleBack}><img src={BackIcon} alt="back" /></Link></span>
                                <h2 className="h2 mb-3"><Link to="/" onClick={handleBack}>Patient Referral </Link></h2>
                </div>
            </div>
            <Row>
                <Col lg={9} md={8} className="nex-app-no-pad">
                    <Card className="nexogic-card-list-custom col-12 nexogic-form-header-top-gap">
                        <div className="row nexogic-form-header">
                            <div className="col-lg-8">
                                <h2 className="nexogic-form-title mb-0">Add Patient</h2>
                                <p className="nexogic-form-title-sub">Please fill out below form details to add Patient.</p>
                            </div>
                            <div className="col-lg-4 graphic">
                                <img src={formHeaderImg} alt="Image" width="100%" />
                            </div>
                        </div>
                    
                    
                        <CardBody>
                                
                            {errorMsg !== "" && <RenderErrorMessage errorMsg={errorMsg} />}
                            <AddPatientForm />
                        </CardBody>
                    </Card>
                    
                </Col>
                <Col lg={3} md={4} className="nex-app-no-pad">
                        <div className="nexogic-widget-sidebar-card card border-0 nex-app-block">
                            <h3 className="card-title h2">Second Opinion</h3>
                            <div className="card-body">
                                <ul className="nexogic-lists nexogic-custom-scroll">
                                    <li>
                                        <img src={SecondOpinionImg} alt="" className="img-fluid" />
                                    </li>
                                </ul>
                            </div>
                        </div>

                    
                    </Col>
            </Row>
        </>
    );
}

export default PatientForm;