import React, { useState, useRef, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import SectionBlank from '../../Sections/SectionBlank';
import { AboutUI } from 'pages/Institutions/components/InstitutionsDetails/components/AboutUI';
import GalleryUI from 'pages/Institutions/components/InstitutionsDetails/components/GalleryUI';
import HospitalTimingsUI from 'pages/Institutions/components/InstitutionsDetails/components/HospitalTimingsUI';
import SpecialitiesUI from 'pages/Institutions/components/InstitutionsDetails/components/SpecialitiesUI';
import { ServicesUI } from 'pages/Institutions/components/InstitutionsDetails/components/ServicesUI';
import { AmenityUI } from 'pages/Institutions/components/InstitutionsDetails/components/AmenityUI';
import BranchesUI from 'pages/Institutions/components/InstitutionsDetails/components/BranchesUI';
import InstitutionsAwardsUI from 'pages/Institutions/components/InstitutionsDetails/components/InstitutionsAwardsUI';
import PractitionersUI from 'pages/Institutions/components/InstitutionsDetails/components/PractitionersUI';
import AdsComponent from '../../Themes/Components/ThemeOne/AdsComponent';
import JobWidgetUI from '../CustomForms/JobWidgetUI';

const PreviewWindow = (props) => {
    const { content, themeDetails, institution, otherBranches, practitioners, awards, previewFlag } = props;
    const { themeContainerType, sectionContent } = content;
    const [winLen, setWinLen] = useState([]);

    const updateWinLen = () => {
        if (themeContainerType === 'layoutone')
            setWinLen(prevWinLen => {
                const divWidth1 = document.getElementById("winl01");
                const divWidth2 = document.getElementById("winl02");
                const uwinlen = [...prevWinLen];
                uwinlen[0] = divWidth1.clientWidth;
                uwinlen[1] = divWidth2.clientWidth;
                return uwinlen;
            });
        if (themeContainerType === 'layouttwo')
            setWinLen(prevWinLen => {
                const divWidth1 = document.getElementById("winl03");
                const uwinlen = [...prevWinLen];
                uwinlen[2] = divWidth1.clientWidth;
                return uwinlen;
            });
        if (themeContainerType === 'layoutthree')
            setWinLen(prevWinLen => {
                const divWidth1 = document.getElementById("winl04");
                const divWidth2 = document.getElementById("winl05");
                const uwinlen = [...prevWinLen];
                uwinlen[3] = divWidth1.clientWidth;
                uwinlen[4] = divWidth2.clientWidth;
                return uwinlen;
            });
    };

    useEffect(() => {
        if (themeContainerType)
            updateWinLen()
    }, [])

    return (
        <div className="preview-window" style={{ fontSize: '40px', minHeight: '300px', backgroundColor: '#f8f9fa' }}>
            <Card className="micro-site-wrap micro-site-theme-v1 p-0 border-0" style={{ backgroundColor: themeDetails[0] }}>
                <CardBody className="micro-main-content-wrap">
                    <Row>
                        <Col lg={12} className="p-0">
                            <SectionBlank themeDetails={themeDetails} institution={institution} isEditable={false} isAdmin={false} />
                        </Col>
                    </Row>
                    {themeContainerType === 'layoutone' ?
                        <Row>
                            <Col lg={4} id="winl01">
                                <Row>
                                    <Col lg={11}>
                                        {sectionContent.filter(section => section.targetArea === "btnCol01").map((section, index) => (
                                            section.droppedItem.length > 0 ?
                                                <div key={index}>
                                                    {section.droppedItem[2][0] === "AboutUI" ? <div className='mt-3 mb-4'><AboutUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                        section.droppedItem[2][0] === "GalleryUI" ? <div className='mt-3 mb-4'><GalleryUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                            section.droppedItem[2][0] === "BusinessHrUI" && institution?.schedules.length > 0 ? <div className='mt-3 mb-4'><HospitalTimingsUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                                section.droppedItem[2][0] === "SpecialitiesUI" && institution?.specialities.length > 0 ? <div className='mt-3 mb-4'><SpecialitiesUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                                    section.droppedItem[2][0] === "ServicesUI" && institution?.services.length > 0 ? <div className='mt-3 mb-4'><ServicesUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                                        section.droppedItem[2][0] === "AmenityUI" && institution?.amenities.length > 0 ? <div className='mt-3 mb-4'><AmenityUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                                            section.droppedItem[2][0] === "BranchesUI" && otherBranches?.length > 1 ? <div className='mt-3 mb-4'><BranchesUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} winwidth={winLen[0]} /></div> :
                                                                                section.droppedItem[2][0] === "AwardUI" && awards?.length > 0 ? <div className='mt-3 mb-4'><InstitutionsAwardsUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} awards={awards} /></div> :
                                                                                    section.droppedItem[2][0] === "DoctorsUI" && practitioners?.length > 0 ? <div className='mt-3 mb-4'><PractitionersUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} practitioners={practitioners} winwidth={winLen[0]} /></div> :
                                                                                        section.droppedItem[2][0] === "JobsUI" ? <div className='mt-3 mb-4'><JobWidgetUI widgetTitle="Jobs" {...props} /></div> :
                                                                                            section.droppedItem[2][0] === "AdsUI" ? <div className='mt-3 mb-4'><AdsComponent imgdet={section.droppedItem[2][1]} institution={institution} /></div> : ''
                                                    }
                                                </div> : ''
                                        ))}
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={8} id="winl02">
                                <Row>
                                    <Col lg={12}>
                                        {sectionContent.filter(section => section.targetArea === "btnCol02").map((section, index) => (
                                            section.droppedItem.length > 0 ?
                                                <div key={index}>
                                                    {section.droppedItem[2][0] === "AboutUI" ? <div className='mt-3 mb-4'><AboutUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                        section.droppedItem[2][0] === "GalleryUI" ? <div className='mt-3 mb-4'><GalleryUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                            section.droppedItem[2][0] === "BusinessHrUI" && institution?.schedules.length > 0 ? <div className='mt-3 mb-4'><HospitalTimingsUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                                section.droppedItem[2][0] === "SpecialitiesUI" && institution?.specialities.length > 0 ? <div className='mt-3 mb-4'><SpecialitiesUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                                    section.droppedItem[2][0] === "ServicesUI" && institution?.services.length > 0 ? <div className='mt-3 mb-4'><ServicesUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                                        section.droppedItem[2][0] === "AmenityUI" && institution?.amenities.length > 0 ? <div className='mt-3 mb-4'><AmenityUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                                            section.droppedItem[2][0] === "BranchesUI" && otherBranches?.length > 1 ? <div className='mt-3 mb-4'><BranchesUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} winwidth={winLen[1]} /></div> :
                                                                                section.droppedItem[2][0] === "AwardUI" ? <div className='mt-3 mb-4'><InstitutionsAwardsUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} awards={awards} /></div> :
                                                                                    section.droppedItem[2][0] === "DoctorsUI" && practitioners?.length > 0 ? <div className='mt-3 mb-4'><PractitionersUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} practitioners={practitioners} winwidth={winLen[1]} /></div> :
                                                                                        section.droppedItem[2][0] === "JobsUI" ? <div className='mt-3 mb-4'><JobWidgetUI widgetTitle="Jobs" {...props} /></div> :
                                                                                            section.droppedItem[2][0] === "AdsUI" ? <div className='mt-3 mb-4'><AdsComponent imgdet={section.droppedItem[2][1]} institution={institution} /></div> : ''
                                                    }
                                                </div> : ''
                                        ))}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        : themeContainerType === 'layouttwo' ?
                            <Row>
                                <Col lg={12} id="winl03">
                                    {sectionContent.map((section, index) => (
                                        section.droppedItem.length > 0 ?
                                            <div key={index}>
                                                {section.droppedItem[2][0] === "AboutUI" ? <div className='mt-3 mb-4'><AboutUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                    section.droppedItem[2][0] === "GalleryUI" ? <div className='mt-3 mb-4'><GalleryUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                        section.droppedItem[2][0] === "BusinessHrUI" && institution?.schedules.length > 0 ? <div className='mt-3 mb-4'><HospitalTimingsUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                            section.droppedItem[2][0] === "SpecialitiesUI" && institution?.specialities.length > 0 ? <div className='mt-3 mb-4'><SpecialitiesUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                                section.droppedItem[2][0] === "ServicesUI" && institution?.services.length > 0 ? <div className='mt-3 mb-4'><ServicesUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                                    section.droppedItem[2][0] === "AmenityUI" && institution?.amenities.length > 0 ? <div className='mt-3 mb-4'><AmenityUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                                        section.droppedItem[2][0] === "BranchesUI" && otherBranches?.length > 1 ? <div className='mt-3 mb-4'><BranchesUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} winwidth={winLen[2]} /></div> :
                                                                            section.droppedItem[2][0] === "AwardUI" ? <div className='mt-3 mb-4'><InstitutionsAwardsUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} awards={awards} /></div> :
                                                                                section.droppedItem[2][0] === "DoctorsUI" && practitioners?.length > 0 ? <div className='mt-3 mb-4'><PractitionersUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} practitioners={practitioners} winwidth={winLen[2]} /></div> :
                                                                                    section.droppedItem[2][0] === "JobsUI" ? <div className='mt-3 mb-4'><JobWidgetUI widgetTitle="Jobs" {...props} /></div> :
                                                                                        section.droppedItem[2][0] === "AdsUI" ? <div className='mt-3 mb-4'><AdsComponent imgdet={section.droppedItem[2][1]} institution={institution} /></div> : ''
                                                }
                                            </div> : ''
                                    ))}
                                </Col>
                            </Row>
                            : themeContainerType === 'layoutthree' ?
                                <Row>
                                    <Col lg={8} id="winl04">
                                        {sectionContent.filter(section => section.targetArea === "btnCol01").map((section, index) => (
                                            section.droppedItem.length > 0 ?
                                                <div key={index}>
                                                    {section.droppedItem[2][0] === "AboutUI" ? <div className='mt-3 mb-4'><AboutUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                        section.droppedItem[2][0] === "GalleryUI" ? <div className='mt-3 mb-4'><GalleryUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                            section.droppedItem[2][0] === "BusinessHrUI" && institution?.schedules.length > 0 ? <div className='mt-3 mb-4'><HospitalTimingsUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                                section.droppedItem[2][0] === "SpecialitiesUI" && institution?.specialities.length > 0 ? <div className='mt-3 mb-4'><SpecialitiesUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                                    section.droppedItem[2][0] === "ServicesUI" && institution?.services.length > 0 ? <div className='mt-3 mb-4'><ServicesUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                                        section.droppedItem[2][0] === "AmenityUI" && institution?.amenities.length > 0 ? <div className='mt-3 mb-4'><AmenityUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                                            section.droppedItem[2][0] === "BranchesUI" && otherBranches?.length > 1 ? <div className='mt-3 mb-4'><BranchesUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} winwidth={winLen[3]} /></div> :
                                                                                section.droppedItem[2][0] === "AwardUI" ? <div className='mt-3 mb-4'><InstitutionsAwardsUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} awards={awards} /></div> :
                                                                                    section.droppedItem[2][0] === "DoctorsUI" && practitioners?.length > 0 ? <div className='mt-3 mb-4'><PractitionersUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} practitioners={practitioners} winwidth={winLen[3]} /></div> :
                                                                                        section.droppedItem[2][0] === "JobsUI" ? <div className='mt-3 mb-4'><JobWidgetUI widgetTitle="Jobs" {...props} /></div> :
                                                                                            section.droppedItem[2][0] === "AdsUI" ? <div className='mt-3 mb-4'><AdsComponent imgdet={section.droppedItem[2][1]} institution={institution} /></div> : ''
                                                    }
                                                </div> : ''
                                        ))}
                                    </Col>
                                    <Col lg={4} id="winl05">
                                        {sectionContent.filter(section => section.targetArea === "btnCol02").map((section, index) => (
                                            section.droppedItem.length > 0 ?
                                                <div key={index}>
                                                    {section.droppedItem[2][0] === "AboutUI" ? <div className='mt-3 mb-4'><AboutUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                        section.droppedItem[2][0] === "GalleryUI" ? <div className='mt-3 mb-4'><GalleryUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                            section.droppedItem[2][0] === "BusinessHrUI" && institution?.schedules.length > 0 ? <div className='mt-3 mb-4'><HospitalTimingsUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                                section.droppedItem[2][0] === "SpecialitiesUI" && institution?.specialities.length > 0 ? <div className='mt-3 mb-4'><SpecialitiesUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                                    section.droppedItem[2][0] === "ServicesUI" && institution?.services.length > 0 ? <div className='mt-3 mb-4'><ServicesUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                                        section.droppedItem[2][0] === "AmenityUI" && institution?.amenities.length > 0 ? <div className='mt-3 mb-4'><AmenityUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} /></div> :
                                                                            section.droppedItem[2][0] === "BranchesUI" && otherBranches?.length > 1 ? <div className='mt-3 mb-4'><BranchesUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} winwidth={winLen[4]} /></div> :
                                                                                section.droppedItem[2][0] === "AwardUI" ? <div className='mt-3 mb-4'><InstitutionsAwardsUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} awards={awards} /></div> :
                                                                                    section.droppedItem[2][0] === "DoctorsUI" && practitioners?.length > 0 ? <div className='mt-3 mb-4'><PractitionersUI {...props} institution={institution} getInstitutionProfile themeDetails={themeDetails} practitioners={practitioners} winwidth={winLen[4]} /></div> :
                                                                                        section.droppedItem[2][0] === "JobsUI" ? <div className='mt-3 mb-4'><JobWidgetUI widgetTitle="Jobs" {...props} /></div> :
                                                                                            section.droppedItem[2][0] === "AdsUI" ? <div className='mt-3 mb-4'><AdsComponent imgdet={section.droppedItem[2][1]} institution={institution} /></div> : ''
                                                    }
                                                </div> : ''
                                        ))}
                                    </Col>
                                </Row>
                                :
                                ''
                    }
                </CardBody>
            </Card>
        </div>
    );
};

export default PreviewWindow;