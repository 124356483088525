import { faPlus, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty } from "lodash";
import { useCallback } from "react";
import { memo, useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { InstitutionServices } from "utils/InstitutionServices";
import GallerySlider from "./GallerySlider";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import plusIcon from "assets/images/plus-icon-lg.png";

const Gallery = (props) => {
  const [modalisOpen, setmodalIsOpen] = useState(false);
  const modalToggle = () => setmodalIsOpen(!modalisOpen);
  const [filename, setFilename] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const history = useHistory();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { isEditable, isAdmin, institution, alert, setShowGalleryOnTabScroll } = props;
  const [galleryImages, setGalleryImages] = useState([]);
  const [Loader, setLoader] = useState(false);
  const [all_images_loader, setAll_images_loader] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pannel_on, setPannel_on] = useState(true);

  const handleselectedFile = (e) => {
    setUploadFiles(e.target.files);
    let tempFilename = [];
    for (let i = 0; i < e.target.files.length; i++) {
      tempFilename.push(e.target.files[i].name)
    }
    setFilename(tempFilename);
  };

  const handleUpload = () => {
    uploadImage(uploadFiles);
  };

  const handleCancel = () => {
    modalToggle()
    clearFileName();
  }

  const uploadImage = (file) => {
    setLoader(true);
    InstitutionServices.addGalleryInstitution(file, institution.guid)
      .then((response) => {
        setLoader(false);
        const { status } = response;
        if (status === 201) {
          modalToggle();
          getGalleryImages(0);
          showSuccessMessage("Institution image added successfully");
          clearFileName();
        } else {
          showErrorMessage("Institution image not added");
        }
      })
      .catch((err) => {
        setLoader(false);
        showErrorMessage(err.message);
      });
  };
  useEffect(() => {
    if (institution.guid) {
      getGalleryImages(0);
    }
  }, [institution]);

  useEffect(()=>{
    if((galleryImages && galleryImages.length > 0 && !isAdmin) || isAdmin){
      setShowGalleryOnTabScroll(true);
    }else{
      setShowGalleryOnTabScroll(false);
    }
  }, [galleryImages]);

  const clearFileName = () => {
    setFilename([]);
    setUploadFiles([]);
  };

  const getGalleryImages = useCallback((cp) => {
    setAll_images_loader(true);
    InstitutionServices.galleryInstitution(
      institution.guid, cp
    ).then(response => {
      if (response.status === 200) {
        setTotalPages(response.data.totalPages)
        setCurrentPage(response.data.currentPage)

        let filesNew = response.data.contents;
        if (cp === 0)
          setGalleryImages(filesNew);
        else {
          let filesOld = galleryImages;
          setGalleryImages(filesOld.concat(filesNew));
        }

      } else {
        setAll_images_loader(false);
      }
      setAll_images_loader(false);
    }).catch(err => {
      setAll_images_loader(false);
      showErrorMessage(err.message);
    });
  }, [institution, galleryImages]);

  const deleteGalleryImage = async (image) => {
    const response = await InstitutionServices.deleteGalleryInstitution(
      institution.guid,
      image.id
    );
    if (response.status === 204) {
      showSuccessMessage("Institution image deleted successfully");
      getGalleryImages(0);
    } else {
      showErrorMessage("Something went wrong, Please try again later.");
    }
  };
  return (
    <>
      {((galleryImages && galleryImages.length > 0 && !isAdmin) || isAdmin) && (
        <Card className={`card nexogic-card-color-header ${pannel_on ? 'expanded' : ''}`} id="gallery">
          <div className="card-header"><h2>Gallery</h2>
            <button className="collapse-btn" type="button" onClick={()=>setPannel_on(!pannel_on)}>
              <img src={pannel_arrow} alt="" />
            </button>
            {(isEditable || isAdmin) && (
                
                  <Button
                    id="nex-ids-gallery-add-btn"
                    color="link"
                    className="add-button"
                    onClick={modalToggle}
                  >
                    <img src={plusIcon} alt="" />
                    {/*<FontAwesomeIcon icon={faPlus} />*/}
                  </Button>
              
              )}
            
          </div>
          <div className={`collapse-div`}>
          
          <CardBody>
          {galleryImages && galleryImages.length > 0
                  ? ""
                  : (isEditable || isAdmin) && (
                    <p><Button
                        id="nex-ids-gallery-add-btn2"
                            className="readmore"
                            color="link"
                            onClick={modalToggle}
                          >
                            {"Add images"}
                          </Button></p>
                  )}
            <GallerySlider
              items={galleryImages}
              loading={all_images_loader}
              isEditable={isEditable}
              isAdmin={isAdmin}
              deleteGalleryImage={deleteGalleryImage}
              institutionId={institution.guid}
              getGalleryImages={getGalleryImages}
              totalPages={totalPages}
              currentPage={currentPage}
            />
            {!isEditable && isEmpty(currentUser) && (
              <Row className="py-5 my-4">
                <Col xs="12">
                  <p className="text-primary font-weight-bold font-italic">
                    Please Sign In to access the full profile.
                  </p>
                  <Button
                    id="nex-ids-gallery-login-btn"
                    color="primary"
                    onClick={() => history.push("/login")}
                  >
                    Login
                  </Button>
                </Col>
              </Row>
            )}
          </CardBody>
          </div>
        </Card>
      )}
      <Modal
        isOpen={modalisOpen}
        toggle={modalToggle}
        className="institute-modal"
        backdrop="static"
        transition="false"
      >
        <ModalHeader
          close={
            <button className="close" onClick={() => handleCancel()}>
              ×
            </button>
          }
          toggle={modalToggle}
        >
          Gallery
        </ModalHeader>
        <ModalBody>
          <div className="nexogic-custom-img-upload cursor-pointer" id="nex-ids-gallery-file-upload">
            <input
              type="file"
              multiple
              hidden
              id="file-upload"
              onChange={handleselectedFile}
            />
            <label className="image-upload text-center" htmlFor="file-upload">
              <div>
                <FontAwesomeIcon icon={faUpload} />
              </div>
              Upload image
            </label>
            {filename?.length > 0 ?
              filename.map((filenamedet, index) => {
                return <div className="image-name" key={index}>{filenamedet}</div>

              }) : ""
            }

          </div>
          {Loader ? (
            <div className="d-flex justify-content-center mt-3">
              <div className="loading-spiner">
                <Spinner style={{ width: "2rem", height: "2rem" }} />
              </div>
            </div>
          ) : (
            <div className="justify-content-end  modal-footer">
              <div className="action-group">  
              <Button
                id="nex-ids-gallery-upload-btn"
                className="nexogic_primary_button"
                disabled={filename.length > 0 ? false : true}
                color="primary"
                onClick={() => handleUpload()}
              >
                Upload
              </Button>
              <Button
                id="nex-ids-gallery-cancel-btn"
                className="nexogic_primary_button_outline"
                outline
                color="primary"
                onClick={() => handleCancel()}
              >
                Cancel
              </Button>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default withAlert()(memo(Gallery));
