import CityAutocompleteTypeaheadField from "components/forms/formikFields/CityAutocompleteTypeaheadField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import PrimarySpecialityTypeaheadField from "components/forms/formikFields/PrimarySpecialityTypeaheadField";
import { Field, Formik } from "formik";
import React, { memo, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import { Opportunity_Type } from "utils/Constants";
import { MetadataService } from "utils/MetadataService";
import * as Yup from "yup";

const initValues = {
    opportunity: "",
    primarySpeciality: [],
    city: [],
};

const validationSchema = Yup.object().shape({
    opportunity: Yup.string(),
    primarySpeciality: Yup.array().nullable(),
    city: Yup.array().nullable(),
});

const FilterForAllJobs = (props) => {
    const { setPrimarySpeciality, setCity, setOpportunityType, getData } = props;
    const [primarySpecialityList, setPrimarySpecialityList] = useState([]);

    function makeQuery(sqOpportunity, sqCity, sqPrimary) {
        let query = "";
        if (sqOpportunity !== "") {
            query = `(op=="${sqOpportunity}")`;
        }

        if (sqCity !== "") {
            if (query === "")
                query = `(cy=="${encodeURIComponent(sqCity)}")`;
            else
                query += `;(cy=="${encodeURIComponent(sqCity)}")`;
        }

        if (sqPrimary !== "") {
            if (query === "")
                query = `(ps=="${encodeURIComponent(sqPrimary)}")`;
            else
                query += `;(ps=="${encodeURIComponent(sqPrimary)}")`;
        }

        return query;
    }

    const getprimarySpeciality = () => {
        MetadataService.getPrimarySpecialities()
            .then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setPrimarySpecialityList(response.data);
                }
            })
            .catch((error) => {
                console.log("IdentityService.getprimarySpeciality error", error);
            });
    };

    const handleFilter = (values) => {
        setOpportunityType(values.opportunity);
        setCity(((values.city)[0]?.value)?.split(",")[0])
        setPrimarySpeciality((values.primarySpeciality)[0]);
        getData(0, [], makeQuery(values?.opportunity ?? "", ((values?.city)[0]?.value)?.split(",")[0] ?? "", (values?.primarySpeciality)[0] ?? ""));
    };

    useEffect(() => {
        getprimarySpeciality();
    }, []);

    return (
        <Formik
            enableReinitialize={true}
            validationSchema={validationSchema}
            initialValues={initValues}
            onSubmit={handleFilter}
            children={(props) => {
                const { values, handleSubmit } = props;
                return (
                    <form onSubmit={handleSubmit} id="nex-faj-job-form-filter">
                        <div className="profile-search-wrap nexogit-filter-options">
                            <Row>
                                <Col md={6} lg={4} id="nex-jfa-primary-speciality">
                                    <Field
                                        id="primarySpeciality"
                                        name="primarySpeciality"
                                        autoComplete="primarySpeciality"
                                        label="Primary Speciality"
                                        className="primary-specialities"
                                        type="text"
                                        component={PrimarySpecialityTypeaheadField}
                                        placeholder="Select Speciality" 
                                        inputprops={{
                                            autoComplete: "off"
                                        }}
                                        value={values.primarySpeciality}
                                        spellCheck={false}
                                        specialit={primarySpecialityList}
                                    />
                                </Col>
                                <Col md={6} lg={3} id="nex-jfa-city">
                                    <Field
                                        name="city"
                                        id="city"
                                        autocomplete="city"
                                        type="text"
                                        label="City"
                                        component={CityAutocompleteTypeaheadField}
                                        placeholder="City"
                                    />
                                </Col>
                                <Col md={6} lg={3}>
                                    <Field
                                        id="opportunity"
                                        name="opportunity"
                                        type="text"
                                        label="Opportunity Type"
                                        component={FormikSelectField}
                                        inputprops={{
                                            name: `opportunity`,
                                            options: Opportunity_Type,
                                            defaultOption: "Select",
                                            keys: {
                                                id: "value",
                                                label: "label",
                                            },
                                        }}
                                    />
                                </Col>
                                <Col md={6} lg={2} className="action-wrap">
                                    
                                    <div className="action job-apply-btns filter-job-action">
                                        <span className="btn nexogic_primary_button" id="nex-faj-job-filter-search-btn" style={{ color: "white" }} onClick={handleSubmit}>Search</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </form>
                );
            }}
        />
    );
};

export default memo(FilterForAllJobs);
