import clock_icon from "assets/images/small_clock_icon.png";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import hospital_icon from "assets/svgIcon/hospital_icon.svg";
import plusIcon from "assets/images/plus-icon-lg.png";
import edit_blue_icon from "assets/images/edit-blue-icon.png";
import del_red_icon from "assets/images/del-red-icon.png";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import AlertDialog from "common/AlertDialog";
import { groupBy, mapValues, omit, uniqBy } from "lodash";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import {
  profileAddEvent,
  updateProfileCompletness,
} from "redux-helpers/ActionCreator";
import { UserStatusConstants } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import {
  getEmptySectionShowHide,
  getFullAddress,
  makeFullNameWithAddressWhenAddressIsNested,
  sortByDay,
  sortByTime,
} from "utils/Utils";
import AddressInstitution from "../AddressInstitution";
import RenderHospitalAffiliationsForm from "./components/RenderHospitalAffiliationsForm";
import SingleTimeSlot from "./components/SingleTimeSlot";
import "./style.scss";
import NoData from "../NoData";

const initialValues = {
  hospitalDetail: {
    hospitalName: "",
    guid: "",
    onSelectNewHospital: 0,
  },
  addressType: "BILLING_ADDRESS",
  addressLine1: "",
  addressLine2: "",
  city: [],
  country: [],
  location: [],
  state: [],
  postalCode: "",
  schedulesHospital: [],
  currency: "INR",
  charge: "",
  duration: "",
  websiteAddress: "",
  mainItemName: "",
  category: "",
  opdLocation: "",
};

const RenderCard = (props) => {
  const {
    facilities,
    handleEditItem,
    handleRemoveItem,
    isLoggedinUser,
    isAdmin,
  } = props;
  const currentUser = useSelector((state) => state.auth.currentUser);
  return (
    <Fragment >
      {facilities.map((facility,index) => (
        <li key={index} className="list-group-item">
          <Row>
            <Col lg={5} className="col-left">
              <div className="nexogic-hospital-card">
                {facility?.institutionDetail?.avatarId
                  ?
                  <img
                    src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${facility?.institutionDetail?.guid}/${facility?.institutionDetail?.avatarId}`}
                    className="icon"
                    alt="#"
                  /> :
                  <img
                    src={hospital_icon}
                    className="icon"
                    alt="#"
                  />
                }
                <div>
                  <h3 className="card-title">
                    <Link
                      to={`/institution/${facility?.institutionDetail?.profileId}`}
                      className=""
                    >
                      {facility?.institutionDetail?.name}
                    </Link>
                  </h3>
                  {facility?.institutionDetail?.address && (
                    <AddressInstitution
                      location={
                        facility?.institutionDetail?.address?.location ?? ""
                      }
                      city={facility?.institutionDetail?.address?.city ?? ""}
                      state={facility?.institutionDetail?.address?.state ?? ""}
                      country={
                        facility?.institutionDetail?.address?.country ?? ""
                      }
                    />
                  )}
                  <div className="d-flex align-items-start card-text phone-contacts-w">
                    {facility?.phoneContacts && facility?.phoneContacts.length > 0 ? (
                      <span className="icon mr-1 d-inline-block">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#3eabca" className="bi bi-telephone" viewBox="0 0 16 16">
                          <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                        </svg>
                      </span>
                    ) : (
                      ""
                    )}
                    <small className="phone-contacts">
                      {facility?.phoneContacts.map((phoneContact, index) => (
                        <div key={index}>
                          <a
                            href={`tel:${phoneContact?.value}`}
                            key={index}
                          >
                            {phoneContact.value}
                          </a>
                          {facility?.phoneContacts.length - 1 !== index
                            ? ", "
                            : ""}
                        </div>
                      ))}
                    </small>
                  </div>
                </div>
              </div>

            </Col>
            <Col lg={5} className="col-right">
              <div className="nexogic-opening-times">
                {facility?.schedules && facility?.schedules.length > 0 && (
                  <SingleTimeSlot
                    schedules={facility?.groupedSchedules}
                  />
                )}
              </div>
            </Col>
            <div lg={2} className="col-action d-flex nexogic-hospital-times-card">
              {(isAdmin ||
                (isLoggedinUser &&
                  currentUser.status ===
                  UserStatusConstants.APPROVED)) && (
                  <div className="delete-action">
                    <Button
                      color="link"
                      className="edit py-0"
                      onClick={() => handleEditItem(facility, "edit")}
                    >
                      <img src={edit_blue_icon} alt="" />
                    </Button>
                    <Button
                      color="link"
                      className="delete py-0"
                      onClick={() => handleRemoveItem(facility)}
                    >
                      <img src={del_red_icon} alt="" />
                    </Button>
                  </div>
                )}
            </div>
          </Row>
        </li>
      ))}
    </Fragment>
  );
};

const HospitalAffiliations = (props) => {
  const [pannel_on, setPannel_on] = useState(true);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const eventName = useSelector((state) => state.profileComplete.eventName);
  const dispatch = useDispatch();
  const { guid } = props.practitioner;
  const userGUID = props.practitioner.guid;
  const { alert, isLoggedinUser, isAdmin, setShowAffiliationsOnTabScroll } = props;
  const [facilities, setFacilities] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);
  const [limit, setLimit] = useState(1);
  const [isOpen, setModal] = useState(false);
  const [formType, setFormType] = useState("add");
  const [isOpenAlert, setAlertModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );

  useEffect(() => {
    dispatch(updateProfileCompletness(4, !!facilities.length));
    if (facilities.length > 0 || isLoggedinUser || isAdmin) {
      setShowAffiliationsOnTabScroll(true);
    }
  }, [facilities]);

  useEffect(() => {
    if (eventName === "ADD_Affiliation") {
      handleEditItem(initialValues, "add");
      dispatch(profileAddEvent(null));
    }
  }, [eventName]);

  const toggleAlert = () => {
    setLoading(false);
    setAlertModal(!isOpenAlert);
  };

  const handleRemoveItem = (selectedItem) => {
    setSelectedItem(selectedItem);
    toggleAlert();
  };

  const formatEditItemData = (item) => {
    let finalSchedules = [];
    if (item?.schedules && item?.schedules.length > 0) {
      const newSchedules = [];
      let newTimings = [];
      item?.schedules.map((schedule) => {
        const { dayOfWeek, startTime, endTime } = schedule;
        const newItem = {
          // key:ind,
          startTime,
          endTime,
          mon: dayOfWeek === "MONDAY" ? true : false,
          tue: dayOfWeek === "TUESDAY" ? true : false,
          wed: dayOfWeek === "WEDNESDAY" ? true : false,
          thu: dayOfWeek === "THURSDAY" ? true : false,
          fri: dayOfWeek === "FRIDAY" ? true : false,
          sat: dayOfWeek === "SATURDAY" ? true : false,
          sun: dayOfWeek === "SUNDAY" ? true : false,
        };
        newTimings.push({ startTime, endTime });
        newSchedules.push(newItem);
        return schedule;
      });
      uniqBy(newTimings, "startTime", "endTime").map((time) => {
        let { startTime, endTime } = time;
        let newItem = {
          startTime: moment(startTime, "HH:mm:ss").toDate(),
          endTime: moment(endTime, "HH:mm:ss").toDate(),
          mon: false,
          tue: false,
          wed: false,
          thu: false,
          fri: false,
          sat: false,
          sun: false,
        };
        newSchedules.map((obj) => {
          if (startTime === obj.startTime && endTime === obj.endTime) {
            if (obj.mon === true) {
              newItem = { ...newItem, mon: true };
            }
            if (obj.tue === true) {
              newItem = { ...newItem, tue: true };
            }
            if (obj.wed === true) {
              newItem = { ...newItem, wed: true };
            }
            if (obj.thu === true) {
              newItem = { ...newItem, thu: true };
            }
            if (obj.fri === true) {
              newItem = { ...newItem, fri: true };
            }
            if (obj.sat === true) {
              newItem = { ...newItem, sat: true };
            }
            if (obj.sun === true) {
              newItem = { ...newItem, sun: true };
            }
          }
          return obj;
        });
        finalSchedules.push(newItem);
        return time;
      });
    }
    return {
      hospitalClinicFormData: [
        {
          hospitalDetail: {
            hospitalName: item?.institutionDetail ? makeFullNameWithAddressWhenAddressIsNested(item?.institutionDetail) : "",
            guid: item?.institutionDetail?.guid ?? "",
            onSelectNewHospital: item?.institutionDetail?.guid ? 1 : 0,
          },
          addressType: "BILLING_ADDRESS",
          addressLine1: "",
          addressLine2: "",
          city: [],
          country: [],
          location: [],
          state: [],
          postalCode: "",
          schedulesHospital: finalSchedules,
          currency: item?.currency ?? "INR",
          charge: item?.charge ?? "",
          duration: item?.duration ? item?.duration + " min" : "",
          websiteAddress: "",
          mainItemName: item?.institutionDetail?.name ?? "",
          category: "",
          id: item?.id,
          opdLocation: item?.opdLocation ?? "",
        },
      ],
    };
  };

  const handleEditItem = (selectedItem, type) => {
    setSelectedItem(formatEditItemData(selectedItem));
    toggle();
    setFormType(type);
  };

  const toggle = () => setModal(!isOpen);

  useEffect(() => {
    if (userGUID) {
      getData();
    }
  }, [userGUID]);

  const getData = async () => {
    const request = PractitionerService.getAffiliations(userGUID);
    request.then((response) => {
      let { data } = response;
      if (data && data.length > 0) {
        setFacilities(
          [...data].map((item) => {
            const { institutionDetail, schedules } = item;
            item.phoneContacts = institutionDetail?.phoneContacts ?? [];
            item.institutionNameFullAddress = getFullAddress({
              name: institutionDetail?.name ?? "",
              abbreviation: institutionDetail?.abbreviation ?? "",
              location: institutionDetail?.address?.location ?? "",
              city: institutionDetail?.address?.city ?? "",
              state: institutionDetail?.address?.state ?? "",
            });
            item["schedulesFullList"] = false;
            item.groupedSchedules = mapValues(
              groupBy(schedules, "dayOfWeek"),
              (clist) => clist.map((schedule) => omit(schedule, "dayOfWeek"))
            );
            return item;
          })

        );
      } else {
        setFacilities([]);
      }
    });
  };

  const handleClickOnYes = async () => {
    const userGUID = globalProfileInfo.guid;
    setLoading(true);
    try {
      const request = PractitionerService.deleteAffiliations(
        userGUID,
        selectedItem.id
      );
      request.then(
        async (response) => {
          const { data, status } = response;
          if (status === 204) {
            await getData();
            setLoading(false);
            toggleAlert();
          } else {
            showErrorMessage(data.status.message);
            setLoading(false);
            toggleAlert();
          }
        },
        (e) => {
          setLoading(false);
          let errmsg = e.message;
          if (e.response) {
            errmsg =
              e.response.data && !!e.response.data.message
                ? e.response.data.message
                : errmsg;
          }
          showErrorMessage(errmsg);
        }
      );
    } catch (e) {
      showErrorMessage(e.message);
      setLoading(false);
      toggleAlert();
    }
  };

  if (
    getEmptySectionShowHide(
      facilities ?? [],
      isAdmin,
      currentUser,
      globalProfileInfo,
      guid
    )
  )
    return null;

  return (
    <>
      <Card
        className={`card nexogic-card-color-header ${pannel_on ? 'expanded' : ''}`}
        id="affiliationSection"
      >
        <div className="card-header"><h2>Hospital/Clinic Affiliations</h2>
          {(isAdmin ||
            (isLoggedinUser &&
              currentUser.status === UserStatusConstants.APPROVED)) && (

              <Button
                id="nex-ha-add-btn"
                color="link"
                className="add-button"
                onClick={() =>
                  handleEditItem(
                    {
                      hospitalClinicFormData: [{ ...initialValues }],
                    },
                    "add"
                  )
                }
              >
                <img src={plusIcon} alt="" />
              </Button>

            )}

          <button className="collapse-btn" type="button" onClick={() => setPannel_on(!pannel_on)}>
            <img src={pannel_arrow} alt="" />
          </button>
        </div>
        <div className={`collapse-div`}>
          <CardBody>
            {facilities && facilities.length
              ? ""
              : (isLoggedinUser || isAdmin) &&
              currentUser.status === UserStatusConstants.APPROVED && (
                <Button
                  id="nex-ha-add-btn-2"
                  className="readmore"
                  color="link"
                  onClick={() => handleEditItem(initialValues, "add")}
                >
                  {"Add your affiliations"}
                </Button>
              )}

            <ul className="list-group list-group-flush" id="nex-ha-institution-list">
              <RenderCard
                handleEditItem={handleEditItem}
                facilities={facilities}
                handleRemoveItem={handleRemoveItem}
                isLoggedinUser={isLoggedinUser}
                isAdmin={isAdmin}
              />
            </ul>

            {facilities.length < 1 && 
            <NoData>No Data</NoData>
            }
          </CardBody>
        </div>
      </Card>
      <RenderHospitalAffiliationsForm
        toggle={toggle}
        isOpen={isOpen}
        facilitie={selectedItem}
        getData={getData}
        guid={guid}
        formType={formType}
        isAdmin={isAdmin}
      />
      <AlertDialog
        toggle={toggleAlert}
        isOpen={isOpenAlert}
        isLoading={isLoading}
        handleClickOnYes={handleClickOnYes}
        yesLabel={"Yes"}
        noLabel={"No"}
      />
    </>
  );
};

const mapStateToProps = ({ ProfileData }) => ({
  ...ProfileData,
});

export default connect(
  mapStateToProps,
  null
)(withAlert()(HospitalAffiliations));
