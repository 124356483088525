import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import AlertDialog from "common/AlertDialog";
import LongText from "components/LimitLongText";
import { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import {
  profileAddEvent,
  updateProfileCompletness,
} from "redux-helpers/ActionCreator";
import { list_Of_Publication_Type, UserStatusConstants } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import { getUser } from "utils/UserHelper";
import {
  getEmptySectionShowHide,
  renderDateRange,
  sortArrayByString,
} from "utils/Utils";
import { RenderPublicatinModule } from "./componenets/RenderPublicatinModule";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import publication_icon from "assets/svgIcon/publication_icon.svg";
import author_presentation_icon from "assets/images/author_presentation_icon.png";
import publish_small_icon from "assets/images/publish_small_icon.png";
import plusIcon from "assets/images/plus-icon-lg.png";
import edit_blue_icon from "assets/images/edit-blue-icon.png";
import del_red_icon from "assets/images/del-red-icon.png";
import NoData from "../NoData";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const pubTypes = list_Of_Publication_Type;

const RenderPublicationList = (props) => {
  const {
    isAdmin,
    publications,
    handleEditItem,
    isLoggedinUser,
    handleRemoveItem,
  } = props;
  const currentUser = useSelector((state) => state.auth.currentUser);
  if (publications && publications.length) {
    const sortedPublications = sortArrayByString(publications, "type");
    sortedPublications.sort((a, b) => a?.type && a.type.localeCompare(b.type));
    const repeatTitle = [];
    return sortedPublications.map((item, index) => {
      const checkType = repeatTitle.find((o) => o.type === item.type);
      if (!checkType) {
        repeatTitle.push({ type: item.type, index: index });
      }
      const checkIndex = repeatTitle.find((o) => o.index === index);
      const pubTypeList = pubTypes.find(
        (typ) =>
          typ?.key &&
          typ.key.toLowerCase() === (item?.type && item.type.toLowerCase())
      );
      const renderPubIcon = !!pubTypeList ? pubTypeList.icon : "";
      const renderPubType = !!pubTypeList ? pubTypeList.label : "";
      let timeStamp = renderDateRange(item?.publishedOn, "", false, "", true);

      return (
        <li
          className="list-group-item"
          key={`pub_${item.id}`}
        >
          <div className="row g-0">
            <div className="col-auto">
              <img src={publication_icon} alt="" />
            </div>
            <div className="col">
              <h3 className="card-title">{renderPubType}</h3>
              <p className="card-title"><small className="text-muted fw-semibold">{item.url ? (
                <a
                  href={`${item.url}`}
                  target="_blank"
                  className="section-item-title item-blue-title m-0"
                  rel="noopener noreferrer"
                >
                  {item.title}
                </a>
              ) : (
                <span className="section-item-title item-blue-title m-0">
                  {item.title}
                </span>
              )}</small></p>

              {item.author && (
                <p className="card-text"><small className="text-muted"><img src={author_presentation_icon} alt="" /> Author: {item.author}</small>
                </p>
              )}
              {timeStamp && (
                <p className="card-text"><small className="text-muted"><img src={publish_small_icon} alt="" />
                  Published On : {timeStamp}
                </small>
                </p>
              )}
              <div className="card-text">
                <small className="text-muted">
                  <LongText
                    content={item.description ? item.description : null}
                    limit={220}
                  />
                </small>
              </div>

            </div>


            {/** 
            <div className="flex-grow-1">
              <div>
                {checkIndex && (
                  <div className="d-flex mb-1">
                    {renderPubIcon}
                    <div className="category-text ml-2">{renderPubType}</div>
                  </div>
                )}
                <div className="article-content">
                  <div className="mb-1">
                    {item.url ? (
                      <a
                        href={`${item.url}`}
                        target="_blank"
                        className="section-item-title item-blue-title m-0"
                        rel="noopener noreferrer"
                      >
                        {item.title}
                      </a>
                    ) : (
                      <p className="section-item-title item-blue-title m-0">
                        {item.title}
                      </p>
                    )}
                  </div>
                  {timeStamp && (
                    <div className="mb-2">
                      <span className="pub-date">
                        Published On : {timeStamp}
                      </span>
                    </div>
                  )}
                  {item.author && (
                    <div className="autor-name">Author: {item.author}</div>
                  )}
                  <div className="description">
                    <span className="font-weight-500"> Abstract: </span>
                    <LongText
                      content={item.description ? item.description : null}
                      limit={220}
                    />
                  </div>
                </div>
              </div>
            </div>
*/}


            <div className="col-auto d-flex align-items-start">
              {(isAdmin ||
                (isLoggedinUser &&
                  currentUser.status === UserStatusConstants.APPROVED)) && (
                  <div className="delete-action">
                    <Button
                      color="link"
                      className="visible-hover py-0 nex-ps-publications-edit-btn"
                      onClick={() => handleEditItem(item)}
                    >
                      <img src={edit_blue_icon} alt="" />
                    </Button>

                    <Button
                      color="link"
                      className="visible-hover py-0 delete nex-ps-publications-del-btn"
                      onClick={() => handleRemoveItem(item)}
                    >
                      <img src={del_red_icon} alt="" />
                    </Button>
                  </div>
                )}
            </div>
          </div>
        </li>
      );
    });
  }
  return null;
};

const Publications = (props) => {
  const [pannel_on, setPannel_on] = useState(true);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const eventName = useSelector((state) => state.profileComplete.eventName);
  const dispatch = useDispatch();
  const { guid } = props.practitioner;
  const { isLoggedinUser, alert, isAdmin, setShowPresentationOnTabScroll } = props;
  const [publications, setPublications] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [isOpen, setModal] = useState(false);
  const toggle = () => setModal(!isOpen);
  const [isOpenAlert, setAlertModal] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(updateProfileCompletness(8, !!publications.length));
    if (publications.length > 0 || isLoggedinUser || isAdmin) {
      setShowPresentationOnTabScroll(true);
    }else{
      setShowPresentationOnTabScroll(false);
    }
  }, [publications]);

  useEffect(() => {
    if (eventName === "ADD_PUB") {
      handleEditItem(initialValue);
      dispatch(profileAddEvent(null));
    }
  }, [eventName]);

  const toggleAlert = () => {
    setLoading(false);
    setAlertModal(!isOpenAlert);
  };
  const initialValue = {
    url: "",
    title: "",
    description: "",
    author: "",
    publishedOn: "",
    publicationType: "",
  };

  useEffect(() => {
    if (guid) {
      getData();
    }
  }, [guid]);

  const handleEditItem = (selectedItem) => {
    const formData = {
      ...selectedItem,
      ...{ publicationType: selectedItem?.type ?? "" },
    };
    setSelectedItem(formData);
    toggle();
  };

  const handleRemoveItem = (selectedItem) => {
    setSelectedItem(selectedItem);
    toggleAlert();
  };

  const handleClickOnYes = async () => {
    const userGUID = globalProfileInfo.guid;
    setLoading(true);
    try {
      const request = PractitionerService.deletePublications(
        userGUID,
        selectedItem.id
      );
      request.then(
        async (response) => {
          const { data, status } = response;
          if (status === 204) {
            await getData();
            setLoading(false);
            toggleAlert();
            showSuccessMessage("Profile updated successfully");
          } else {
            showErrorMessage(data.status.message);
            setLoading(false);
            toggleAlert();
          }
        },
        (e) => {
          setLoading(false);
          let errmsg = e.message;
          if (e.response) {
            errmsg =
              e.response.data && !!e.response.data.message
                ? e.response.data.message
                : errmsg;
          }
          showErrorMessage(errmsg);
        }
      );
    } catch (e) {
      showErrorMessage(e.message);
      setLoading(false);
      toggleAlert();
    }
  };

  const getData = () => {
    const request = PractitionerService.getPublications(guid);
    request.then((response) => {
      let { data } = response;
      if (data && data.length > 0) {
        setPublications(data);
      } else {
        setPublications([]);
      }
    });
  };

  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );

  if (
    getEmptySectionShowHide(
      publications ?? [],
      isAdmin,
      currentUser,
      globalProfileInfo,
      guid
    )
  )
    return null;

  return (
    <>
      <Card className={`nexogic-card-color-header ${pannel_on ? 'expanded' : ''}`} id="publicationsSection">
        <div className="card-header"><h2>Publications & Presentations</h2>
          {(isAdmin ||
            (isLoggedinUser &&
              currentUser.status === UserStatusConstants.APPROVED)) && (

              <Button
                id="nex-ps-publications-add-btn"
                color="link"
                className="add-button"
                onClick={() => handleEditItem(initialValue)}
              >
                <img src={plusIcon} alt="" />
              </Button>

            )}
          <button className="collapse-btn" type="button" onClick={() => setPannel_on(!pannel_on)}>
            <img src={pannel_arrow} alt="" />
          </button>
        </div>
        <div className={`collapse-div`}>
          <CardBody className={publications && publications.length > 0
            ? ""
            : "align-items-center"
          }>
            {publications && publications.length
              ? ""
              : (isLoggedinUser || isAdmin) &&
              currentUser.status === UserStatusConstants.APPROVED && (
                <Button
                id="nex-ps-publications-add-btn2"
                  className="readmore"
                  color="link"
                  onClick={() => handleEditItem(initialValue)}
                >
                  {"Add your first publication"}
                </Button>
              )}
            {/** 
          <Row
            
          >
            <Col>
              <h3 className="section-title m-0 mb-3">
                Publications & Presentations
              </h3>
              {publications && publications.length
                ? ""
                : (isLoggedinUser || isAdmin) &&
                  currentUser.status === UserStatusConstants.APPROVED && (
                    <div className="mt-3">
                      <Row className="align-items-center">
                        <Col className="add-infomation-part">
                          <Button
                            className="add-infomation p-0"
                            color="link"
                            onClick={() => handleEditItem(initialValue)}
                          >
                            {"Add your first publication"}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  )}
            </Col>
            {(isAdmin ||
              (isLoggedinUser &&
                currentUser.status === UserStatusConstants.APPROVED)) && (
              <Col xs="auto">
                <Button
                  color="link"
                  className="round-icon-btn rounded-circle"
                  onClick={() => handleEditItem(initialValue)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </Col>
            )}
          </Row>
*/}
            <ul className="list-group list-group-flush" id="nex-ps-publications-lists">
              <RenderPublicationList
                publications={publications}
                handleEditItem={handleEditItem}
                isLoggedinUser={isLoggedinUser}
                handleRemoveItem={handleRemoveItem}
                isAdmin={isAdmin}
              />
            </ul>
            {publications.length < 1 && 
            <NoData>No Data</NoData>
            }
            <RenderPublicatinModule
              selectedItem={selectedItem}
              toggle={toggle}
              isOpen={isOpen}
              guid={guid}
              getData={getData}
              pubTypes={pubTypes}
              isAdmin={isAdmin}
            />
            <AlertDialog
              toggle={toggleAlert}
              isOpen={isOpenAlert}
              isLoading={isLoading}
              handleClickOnYes={handleClickOnYes}
              yesLabel={"Yes"}
              noLabel={"No"}
            />
          </CardBody>
        </div>
      </Card>
    </>
  );
};

export default withAlert()(Publications);
