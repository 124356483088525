import React from "react";
import {
  Button,
  InputGroupAddon,
  InputGroup,
  InputGroupText,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { Field } from "formik";
import FormikInputField from "components/forms/formikFields/FormikInputField";
const ForgetPassForm = (props) => {
  const { values, handleSubmit, dirty, isLoading, errors } = props;
  return (
    <>
      <form onSubmit={handleSubmit}>
        <InputGroup className="mt-4">
          {/** 
          <InputGroupAddon
            addonType="prepend"
            className={classnames({
              "mb-4 pb-3": errors.email,
              "mb-3": !errors.email,
            })}
          >
            <InputGroupText className="px-3 bg-white text-muted">
              <FontAwesomeIcon icon={faUser} className="mx-1" />
            </InputGroupText>
          </InputGroupAddon>
          */}
          <Field
            id="email"
            name="email"
            type="email"
            component={FormikInputField}
            value={values.email}
            placeholder="Email Address"
          />
        </InputGroup>

        {isLoading ? (
          <div className="loading-spiner text-center">
            <Spinner />
          </div>
        ) : (
          <Button
            id="fp_btn_submit"
            disabled={!dirty}
            className="nexogic_primary_button"
            color="primary"
            size="lg"
            block
          >
            Continue
          </Button>
        )}
      </form>
    </>
  );
};
export default ForgetPassForm;
