import CommonLoader from 'common/CommonLoader';
import NoData from 'pages/Profile/components/DoctorsDetails/components/NoData';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap'
import { PatientService } from 'utils/PatientService';

export default function PatientLifestyle(props) {
  const { patient } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [llfestyles, setLifestyles] = useState([]);

  const getPatientLifestyle = (id) => {
    PatientService.getPatientLifestyle(id)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          // console.log("data", data);
          setIsLoading(false);
          setLifestyles(data);
        } else {
          alert('Something went wrong!');
        }
      })
  }

  useEffect(() => {
    getPatientLifestyle(patient?.id);
  }, [patient])

  const createQuestion = (code) => {
    switch (code) {
      case "SMOKING": return "Do you smoke?"; break;
      case "DRINKING": return "Do you consume Alcohol?"; break;
      case "FOOD_PREFERENCE": return "What are your Food Preferences?"; break;
      case "PHYSICAL_ACTIVITY": return "Do you do Exercise?"; break;
      default: return "";
    }
  }

  if (isLoading)
    return (
      <div className="text-center">
        <CommonLoader />
      </div>
    )

  return (
    <>
      {/*<h1 className='nexogic-page-title'>Lifestyle </h1>*/}
      <Card className='nexogic-key-val-column'>
        <CardBody>
          {(llfestyles && llfestyles.length > 0) ?
            <ul className='val-bot mt-0'>
              {llfestyles.map((llfestyle, index) => (
                <li key={index}>
                  <span>{createQuestion(llfestyle?.code)}</span>
                  {llfestyle?.answer === 'YES' ? 'yes' : "No"}
                  {llfestyle?.answer === 'YES' ? `(${llfestyle?.otherAnswer})` : ""}
                </li>
              ))}
            </ul>
            :
            <NoData>Not Available</NoData>
            // <>
            //   Lifestyle Details not Available!
            // </>
          }
        </CardBody>
      </Card>
    </>
  )
}
