import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddressTypeaheadField from "components/forms/formikFields/AddressTypeaheadField";
import FormikCheckboxField from "components/forms/formikFields/FormikCheckboxField";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikMobileField from "components/forms/formikFields/FormikMobileField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import LocationTypeaheadField from "components/forms/formikFields/LocationTypeaheadField";
import PrimarySpecialityTypeaheadField from "components/forms/formikFields/PrimarySpecialityTypeaheadField";
import YearTypeaheadField from "components/forms/formikFields/YearTypeaheadField";
import { Field, Formik } from "formik";
import { isEmpty } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { withAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import { Button, Col, InputGroup, Row } from "reactstrap";
import { authSetUser } from "redux-helpers/ActionCreator";
import {
  emailNonNumericRegEx,
  onlyAlphabets,
  TIME_CONSTANTS,
} from "utils/Constants";
import { IdentityService } from "utils/IdentityService";
import { MetadataService } from "utils/MetadataService";
import { PractitionerService } from "utils/PractitionerService";
import {
  setOAuthTokens,
  setOAuthUser,
  setUser,
  getUser,
  updateAccessToken,
} from "utils/UserHelper";
import { UserService } from "utils/UserService";
import { cleanUpPhoneNumber } from "utils/Utils";
import * as Yup from "yup";
import Swal from "sweetalert2";
import ReactDOMServer from 'react-dom/server';

const years = TIME_CONSTANTS.YEARS();

const titles = [
  {
    label: "Dr.",
    value: "Dr.",
  },
  {
    label: "Mr.",
    value: "Mr.",
  },
  {
    label: "Mrs.",
    value: "Mrs.",
  },
  {
    label: "Ms.",
    value: "Ms.",
  },
  {
    label: "Prof.",
    value: "Prof.",
  },
];

const phoneRegExp =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{5})(( x| ext)\d{1,5}){0,1}$/;
const validationSchema = Yup.object().shape({
  title: Yup.string().required("This field is required"),
  firstName: Yup.string().when("claimsProfileId", {
    is: (val) => val === undefined,
    then: Yup.string()
      .min(2)
      .max(30)
      .matches(onlyAlphabets, "Only Alphabets")
      .required("This field is required"),
    otherwise: Yup.string(),
  }),
  lastName: Yup.string().when("claimsProfileId", {
    is: (val) => val === undefined,
    then: Yup.string()
      .min(2)
      .max(30)
      .matches(onlyAlphabets, "Only Alphabets")
      .required("This field is required"),
    otherwise: Yup.string(),
  }),
  primarySpeciality: Yup.array().when("claimsProfileId", {
    is: (val) => val === undefined,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required")
      .nullable(),
    otherwise: Yup.array().nullable(),
  }),
  practicingSince: Yup.string().when("claimsProfileId", {
    is: (val) => val === undefined,
    then: Yup.string().required("This field is required"),
    otherwise: Yup.string(),
  }),
  streetAddress1: Yup.string(),
  streetAddress2: Yup.string(),
  country: Yup.array().when("claimsProfileId", {
    is: (val) => val === undefined,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required")
      .nullable(),
    otherwise: Yup.array().nullable(),
  }),
  state: Yup.array().when("claimsProfileId", {
    is: (val) => val === undefined,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required")
      .nullable(),
    otherwise: Yup.array().nullable(),
  }),
  city: Yup.array().when("claimsProfileId", {
    is: (val) => val === undefined,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required")
      .nullable(),
    otherwise: Yup.array().nullable(),
  }),
  location: Yup.array().when("claimsProfileId", {
    is: (val) => val === undefined,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required")
      .nullable(),
    otherwise: Yup.array().nullable(),
  }),
  zipCode: Yup.string().when("claimsProfileId", {
    is: (val) => val === undefined,
    then: Yup.string().max(10).required("This field is required").nullable(),
    otherwise: Yup.string(),
  }),
  rememberMe: Yup.boolean().oneOf(
    [true],
    "Accept Terms & Conditions is required "
  ),
  phone: Yup.string()
    .matches(phoneRegExp, "Mobile number is not valid")
    .required("This field is required"),
  email: Yup.string()
    .max(50)
    .matches(emailNonNumericRegEx, "Please enter an valid email")
    .required("This field is required"),
  claimsProfileId: Yup.string(),
});

const PersonalDetailsForm = (props, onNext) => {
  const [guid, setGuid] = useState("");
  const { state } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedAddress, setSelectedAddress] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const [userProfileId, setUserProfileId] = useState("");
  const [existFlag, setExistFlag] = useState(false)
  const initValues = useMemo(() => {
    return {
      title: titles[0].value,
      firstName: state?.firstName ?? "",
      lastName: state?.lastName ?? "",
      primarySpeciality: "",
      practicingSince: "",
      streetAddress1: "",
      streetAddress2: "",
      country: "",
      state: "",
      city: "",
      zipCode: "",
      location: "",
      phone: state?.phoneNumber ?? "",
      email: state?.email ?? "",
      // password: "",
      // confirmPassword: "",
      rememberMe: false,
      claimsProfileId: "",
    };
  }, []);

  const [initialValues, setInitialValues] = useState(initValues);
  useEffect(() => {
    if (state !== undefined) {
      if (state && state?.profileId) {
        setUserProfileId(state?.profileId);
        getClaimsProfile(state?.profileId);
      } else {

      }
      // setGuid(state.guid);
      // getVerificationMode(state.guid, props);
    }
  }, [state]);

  const getClaimsProfile = useCallback((id) => {
    PractitionerService.getClaimsProfile(id)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (response?.data) {
            const {
              title,
              firstName,
              lastName,
              primarySpeciality,
              practicingSince,
              skills,
              address
            } = response?.data;
            const { addressType, city, state, country, postalCode, location } = address[0];
            setInitialValues((preState) => {
              return {
                ...preState,
                title: title ?? "",
                firstName: firstName ?? "",
                lastName: lastName ?? "",
                primarySpeciality: primarySpeciality && primarySpeciality.trim() !== "" ? [primarySpeciality] : [],
                // primarySpeciality: [primarySpeciality] ?? [],
                practicingSince: practicingSince ?? "",
                claimsProfileId: id ?? "",
                // streetAddress1: addressLine1 ?? "",
                // streetAddress2: addressLine2 ?? "",
                country: [country] ?? [],
                state: [state] ?? [],
                city: [city] ?? [],
                zipCode: postalCode ?? "",
                location: 'location' in address[0] ? [location] : [],
              };
            });
          }
        }
      })
      .catch((error) =>
        console.log("PractitionerService.getClaimsProfile error", error)
      );
  }, []);

  const onSubmit = (values) => {
    const { token, refreshToken, utype, claim, profileId, provider } = state || {}
    let selectedLocation = values.location[0];
    if (values.location[0] != undefined) {
      selectedLocation = values.location[0];
      if (values.location[0].customOption) {
        selectedLocation = values.location[0].value;
      }
    }
    const payload = {
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: cleanUpPhoneNumber(values.phone),
      salutationCode: values.title,
      type: utype ? 'PATIENT' : 'PRACTITIONER',
      claim,
      claimProfileId: profileId
    };
    const payload_refrshToken = {
      refresh_token: refreshToken,
      grant_type: "refresh_token"
    }

    const payload_provider = {
      title: values.title,
      firstName: values.firstName,
      lastName: values.lastName,
      practicingSince: 2015,
      primarySpeciality: values?.primarySpeciality[0] ?? "",
      emails: [
        {
          type: "WORK",
          value: values.email,
          primary: true
        }
      ],
      phones: [
        {
          type: "HOME",
          value: cleanUpPhoneNumber(values.phone),
          primary: true
        }
      ],
      address: [
        {
          addressType: "HOME_ADDRESS",
          addressLine1: values?.streetAddress1 ?? "",
          addressLine2: values?.streetAddress2 ?? "",
          city: values?.city[0]?.value ?? "",
          state: values?.state[0]?.value ?? "",
          country: values?.country[0]?.value ?? "",
          postalCode: values?.zipCode ?? "",
          location: selectedLocation
        }
      ],
    }

    let payload_practitonerdetails = [
      {
        op: "add",
        path: "/primarySpeciality",
        value: values?.primarySpeciality[0] ?? "",
      },
      {
        op: "add",
        path: "/practicingSince",
        value: values?.practicingSince ?? "",
      },
      {
        op: "add",
        path: "/address",
        value: [
          {
            addressLine1: values?.streetAddress1 ?? "",
            addressLine2: values?.streetAddress2 ?? "",
            city: values?.city[0]?.value ?? "",
            country: values?.country[0]?.value ?? "",
            location: selectedLocation ?? "",
            postalCode: values?.zipCode ?? "",
            state: values?.state[0]?.value ?? "",
          },
        ],
      },
    ];

    // setSubmitting(true);
    try {
      if (provider === 'phone') {
        IdentityService.AddUserDetails(token, payload)
          .then(async response => {
            if (response.status === 200 || response.status === 201) {
              const content = (
                <div className='d-flex flex-column'>
                  <h2 className='text-success'>Profile Created Successfully..</h2>
                  <p>Please login using your mobile number to continue.</p> 
                </div>
              );
              const { isConfirmed } = await Swal.fire({
                title: ' ',
                html: ReactDOMServer.renderToString(content),
                icon: 'success',
                showCancelButton: false,
                // cancelButtonText: 'Cancel',
                confirmButtonText: 'Login Now',
              });

              if (isConfirmed) {
                setSubmitting(false);
                UserService.logout();
                window.location.href = '/login?mobile_number=true';
              }
            } else if (response.status === 409) {
              setExistFlag(true);
              // props.alert.error("User Already Exists..");
              showErrorMessage('User Already Exists');
              return false
            } else if (response.message) {
              throw new Error(response.message);
            } else {
              throw new Error('Unhandled API response status');
            }
          })
      } else {
        IdentityService.AddUserDetails(token, payload)
          .then(async response => {
            if (await response.status === 200 || await response.status === 201) {
              return await IdentityService.RefreshUserToken(payload_refrshToken).then(secondApiResponse => {
                return { response, secondApiResponse };
              });
            } else if (response.status === 409) {
              setExistFlag(true);
              // props.alert.error("User Already Exists..");
              showErrorMessage('User Already Exists');
              return false
            } else if (response.message) {
              throw new Error(response.message);
            } else {
              throw new Error('Unhandled API response status');
            }
          })
          .then(refreshResponse => {
            if (refreshResponse.secondApiResponse.status === 200 || refreshResponse.secondApiResponse.status === 201) {
              const { response, secondApiResponse } = refreshResponse;
              updateAccessToken(secondApiResponse.data?.access_token);
              setOAuthTokens(secondApiResponse.data?.access_token, secondApiResponse.data?.refresh_token);
              setOAuthUser(response.data);

              const curuser = {
                uuid: response?.data?.id,
                type: response?.data?.types,
                guid: response?.data?.profileId,
              };

              if (claim) {
                PractitionerService.updatePractitionersPersonalDetails(payload_provider, response.data.profileId)
                  .then(responsepdetails => {
                    if (responsepdetails) {
                      dispatch(authSetUser(curuser));
                      setUser(curuser);
                      // setOAuthUser(curuser);
                      props.onNext(responsepdetails);
                    } else {
                      if (responsepdetails.validationErrors) {
                        responsepdetails.validationErrors.map((err) => {
                          props.alert.error(`${err.field}: ${err.message}`);
                        });
                      } else if (responsepdetails.message) {
                        props.alert.error(responsepdetails.message);
                      }
                    }
                  })
                  .catch((error) => {
                    console.log("IdentityService.RegisterNewUser error", error);
                    props.alert.error("Server facing problem. Please try after some time.");
                    setSubmitting(false);
                  })
              } else {
                PractitionerService.updatePractitionersDetails(payload_practitonerdetails, response.data.profileId)
                  .then(responsepdetails => {
                    if (responsepdetails) {
                      dispatch(authSetUser(curuser));
                      setUser(curuser);
                      // setOAuthUser(curuser);
                      props.onNext(responsepdetails);
                    } else {
                      if (responsepdetails.validationErrors) {
                        responsepdetails.validationErrors.map((err) => {
                          props.alert.error(`${err.field}: ${err.message}`);
                        });
                      } else if (responsepdetails.message) {
                        props.alert.error(responsepdetails.message);
                      }
                    }
                  })
                  .catch((error) => {
                    console.log("IdentityService.RegisterNewUser error", error);
                    props.alert.error("Server facing problem. Please try after some time.");
                    setSubmitting(false);
                  })
              }
            } else {
              props.alert.error("Failed to refresh Token")
              throw new Error('Failed to refresh Token');
            }
          })
          .catch(error => {
            console.error('API call error: Something went wrong.....!', error);
            // props.alert.error("User Already Exists..")
            // showErrorMessage("User Already Exists...")
            // setErrorMessage(error.message || 'Something went wrong.....!');
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    } catch (error) {
      console.log("IdentityService.RegisterNewUser error", error);
      props.alert.error("Something went wrong. Please try after some time.");
    }


    // IdentityService.AddPersonalDetails(payload, guid)
    //   .then((response) => {
    //     if (response.status === 200 || response.status === 201) {
    //       const data = response.data;
    //       updateAccessToken(data.accessToken);
    //       setOAuthTokens(data.accessToken, data.refreshToken);
    //       const user = {
    //         uuid: data.uuid,
    //         type: data.type,
    //         guid: data.guid,
    //       };

    // IdentityService.GetUserDetails(data.accessToken, data.guid)
    //         .then((userRes) => {
    //           if (userRes.status === 200) {
    //             // const { authorities } = userRes.data;
    //             setOAuthUser(userRes.data);
    //           } else {
    //             setOAuthUser({ guid: data.guid });
    //           }
    //         })


    //       dispatch(authSetUser(user));
    //       setUser(user);
    //       // gePractitionersDetails(data.guid);
    //       props.onNext();
    //     } else if (
    //       response.status === 404 &&
    //       response.message.includes("No resource profile found with ID")
    //     ) {
    //       props.alert.error(
    //         "Session expired. Please validate OTP for registration."
    //       );
    //       history.push(`/register`);
    //     } else {
    //       if (response.validationErrors) {
    //         response.validationErrors.map((err) => {
    //           props.alert.error(`${err.field}: ${err.message}`);
    //         });
    //       } else if (response.message) {
    //         props.alert.error(response.message);
    //       }
    //     }
    //     setSubmitting(false);
    //   })
    //   .catch((error) => {
    //     console.log("IdentityService.RegisterNewUser error", error);
    //     props.alert.error("Server facing problem. Please try after some time.");
    //     setSubmitting(false);
    //   })
    //   .then(() => {
    //     setSubmitting(false);
    //   });

  };

  const getAddressDetails = (address) => {
    setSelectedAddress(address);
  };

  const gePractitionersDetails = (guid) => {
    PractitionerService.gePractitionersDetails(guid)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(authSetUser(response.data));
          setUser(response.data);
          // props.onNext(response.data);
        }
      })
      .catch((error) => {
        console.log("PractitionerService.gePractitionersDetails error", error);
        props.alert.error(error.message);
      });
  };

  const logout = () => {
    UserService.logout();
    history.replace('/login');
  };

  return (
    <>
      {/* <p className="nexogic-register-timer ">
        Please submit this form in <Timer /> to avoid session expire.
      </p> */}
      <div className="nexogic-onboarding-form nexogic-form-content">
        {existFlag ?
          <div className="d-flex justify-content-end">
            <Button
              className="btn nexogic_primary_button_outline  ml-auto save-exit"
              disabled={isSubmitting}
              onClick={logout}
            >
              Exit
            </Button>
          </div>
          : ""}
        <div className="d-none">
          <h3 className="nexogic-form-title mb-1">
            {userProfileId ? "Create your user" : "Personal information"}
          </h3>
          <p className="nexogic-form-title-sub mb-2">
            {userProfileId
              ? "Register your user information"
              : "Provide your personal information to start your registration"}
          </p>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={onSubmit}
          children={(props) => {
            return (
              <>
                <RenderForm
                  {...props}
                  isSubmitting={isSubmitting}
                  addressDetails={getAddressDetails}
                  userProfileId={userProfileId}
                  setExistFlag={setExistFlag}
                  claim={state?.claim}
                />
              </>
            );
          }}
        />
      </div>
    </>
  );
};

export default withAlert()(PersonalDetailsForm);

// const Timer = () => {
//   const [minutes, setMinutes] = useState(9);
//   const [seconds, setSeconds] = useState(59);
//   const [counter, setCounter] = useState("");
//   const [stopTimer, setStopTimer] = useState(false);
//   const history = useHistory();
//   useEffect(() => {
//     TimerCountdown(counter, minutes, seconds);
//   }, [counter, minutes, seconds]);

//   const TimerCountdown = (counter, minutes, seconds) => {
//     !stopTimer &&
//       setTimeout(() => {
//         if (seconds === 0) {
//           setSeconds(59);
//           setMinutes(minutes - 1);
//         } else {
//           setSeconds(seconds - 1);
//         }
//         setCounter(minutes + " min  " + seconds + " sec");
//         if (minutes === 0 && seconds === 1) {
//           setStopTimer(true);
//           history.push("/register");
//         }
//       }, 1000);
//   };
//   return <span className="counter-span">{counter}</span>;
// };

const RenderForm = (props) => {
  const {
    values,
    errors,
    isSubmitting,
    handleSubmit,
    addressDetails,
    userProfileId,
    claim,
    setExistFlag
  } = props;
  const [showPassword, onShowPassword] = useState(false);
  const toggleShowPassword = () => onShowPassword(!showPassword);
  const [countryList, setCountyList] = useState([]);
  const [statesList, setSatesList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [primarySpecialityList, setPrimarySpecialityList] = useState([]);
  const [iseditable, setisEditable] = useState(true);
  const stateRef = useRef();
  const cityRef = useRef();
  const countryRef = useRef();
  const locRef = useRef();

  useEffect(() => {
    getCountries();
    getprimarySpeciality();
  }, []);

  const getCountries = () => {
    setSatesList([]);
    setCityList([]);
    setLocationList([]);
    MetadataService.getCountry()
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setCountyList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getCountries error", error);
      });
  };

  const getStates = (countryId) => {
    setSatesList([]);
    setCityList([]);
    setLocationList([]);
    getselectedAddress(countryList, countryId, "COUNTRY");
    if (countryId == "") {
      return false;
    }

    MetadataService.getStates(countryId)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setSatesList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getStates error", error);
      });
  };

  const getCities = (stateId) => {
    setCityList([]);
    setLocationList([]);
    getselectedAddress(statesList, stateId, "STATE");
    if (stateId == "") {
      return false;
    }
    MetadataService.getCities(stateId)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setCityList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getCities error", error);
      });
  };

  const getLocations = (cityId) => {
    setLocationList([]);
    getselectedAddress(cityList, cityId, "CITY");
    if (cityId == "") {
      return false;
    }
    MetadataService.getLocations(cityId)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setLocationList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getCities error", error);
      });
  };

  const getprimarySpeciality = () => {
    MetadataService.getPrimarySpecialities()
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setPrimarySpecialityList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getprimarySpeciality error", error);
      });
  };
  const [address, setAddress] = useState({});
  const getselectedAddress = (array, id, type) => {
    let filterValue = "";
    if (!isEmpty(values.country)) {
      array.map((obj) => {
        if (obj.id == id) {
          filterValue = obj.value;
        }
      });
    }
    if (type == "COUNTRY") {
      setAddress({
        ...address,
        country: filterValue,
        state: "",
        city: "",
        location: "",
      });
      values.state = [];
      values.city = [];
      values.location = [];
    } else if (type == "STATE") {
      setAddress({
        ...address,
        state: filterValue,
        city: "",
        location: "",
      });
      values.city = [];
      values.location = [];
    } else if (type == "CITY") {
      setAddress({
        ...address,
        city: filterValue,
        location: "",
      });
      values.location = [];
    }
  };

  const selectdLocation = (locationName) => {
    setAddress({
      ...address,
      location: locationName,
    });
  };

  // const copyDisable = (fieldName) => {
  //   const passwordInput = document.getElementById(fieldName);
  //   passwordInput.onpaste = (e) => {
  //     e.preventDefault();
  //     return false;
  //   };
  //   passwordInput.oncut = (e) => {
  //     e.preventDefault();
  //     return false;
  //   };
  //   passwordInput.oncopy = (e) => {
  //     e.preventDefault();
  //     return false;
  //   };
  // };

  // useEffect(() => {
  //   copyDisable("password");
  //   copyDisable("confirmPassword");
  // }, []);

  useEffect(() => {
    if (claim) {
      setisEditable(false);
    }
  }, [claim]);

  return (
    <div>
      <form className="personal-details-form- nex-form-container" onSubmit={handleSubmit}>
        {/* {userProfileId === "" && ( */}
        <>
          <div className="nex-form-section" data-aos="fade-up" data-aos-duration="1600" data-aos-delay="500">
            <div className="space-bottom-lg">
              <h2 className="nex-text-xxl text-center text-dark">Personal Information</h2>
              <p className="text-center nex-text-xxl-sub">Provide your personal information to start your registration</p>
            </div>
            {/* <Row className="">
              <Col md={'auto'} className="ml-auto nex-fieldset-field-floated-r">
                <Button
                  className="btn nexogic_primary_button_outline  ml-auto save-exit"
                  disabled={isSubmitting}
                  onClick={logout}
                >
                  Exit
                </Button>
              </Col>
            </Row> */}
            <Row>
              <Col md={6} className="w-40 nex-marger-title-field">
                <div className="nex-input-prefix">
                  <Field
                    id="firstName"
                    name="firstName"
                    autoComplete="firstName"
                    type="text"
                    component={FormikInputField}
                    label="First Name"
                    placeholder="Your first name"
                    disabled={!iseditable}
                  />
                  <div className="nex-prefix-wrap">
                    <Field
                      id="title"
                      name="title"
                      type="text"
                      label=""
                      component={FormikSelectField}
                      inputprops={{
                        name: "title",
                        options: titles,
                        keys: {
                          id: "value",
                          label: "label",
                        },
                      }}
                      disabled={!iseditable}
                      autoComplete="title"
                    />

                  </div>
                </div>
              </Col>
              <Col md={6} className="w-40">
                <Field
                  id="lastName"
                  name="lastName"
                  autoComplete="lastName"
                  type="text"
                  component={FormikInputField}
                  label="Last Name"
                  placeholder="Your last name"
                  disabled={!iseditable}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="nexogic-phone-field-wrap">
                  <Field
                    id="phone"
                    name="phone"
                    autoComplete="phone"
                    type="text"
                    component={FormikMobileField}
                    label="Mobile Number"
                    placeholder="Enter number"
                    value={props.initialValues.phone}
                    disabled={props.initialValues.phone === "" ? false : true}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <Field
                  id="email"
                  name="email"
                  autoComplete="email"
                  type="text"
                  component={FormikInputField}
                  label="Email Address"
                  placeholder="Enter email"
                  disabled={props.initialValues.email === "" ? false : true}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="form-group" id="nex-pdf-primary-speciality">
                  <Field
                    id="primarySpeciality"
                    name="primarySpeciality"
                    autoComplete="primarySpeciality"
                    label="Primary Speciality"
                    className="primary-specialities"
                    type="text"
                    component={PrimarySpecialityTypeaheadField}
                    placeholder="Select Speciality"
                    inputprops={{
                      autoComplete: "off",
                    }}
                    value={values.primarySpeciality}
                    spellCheck={false}
                    specialit={primarySpecialityList}
                    disabled={!iseditable}
                  // disabled={props.initialValues.primarySpeciality === [] ? true : false}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="form-group" id="nex-pdf-practicing-since">
                  <Field
                    name="practicingSince"
                    autoComplete="practicingSince"
                    id="practicingSince"
                    type="text"
                    label="Practicing Since"
                    component={YearTypeaheadField}
                    placeholder="YYYY"
                    inputprops={{
                      autoComplete: "off",
                    }}
                    value={values.practicingSince}
                    spellCheck={false}
                    years={years}
                    disabled={!iseditable}
                  // disabled={props.initialValues.practicingSince === 0 ? true : false}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="nex-form-section address-form-sec" data-aos="fade-up" data-aos-duration="1600" data-aos-delay="500">
            <div className="mb-3 mt-3">
              <h2 className="nex-text-xxl text-center text-dark">Address</h2>
              <p className="nex-text-xxl-sub text-center">Provide your Address information</p>
            </div>
            <Row>
              <Col lg={12}>
                <Field
                  id="streetAddress1"
                  name="streetAddress1"
                  autoComplete="streetAddress1"
                  type="text"
                  component={FormikInputField}
                  label="Street Address"
                  placeholder="Current address"
                  inputprops={{
                    autoComplete: "off",
                  }}
                  disabled={props.initialValues.streetAddress1 === "" ? false : true}
                />
              </Col>
              <Col lg={12}>
                <Field
                  id="streetAddress2"
                  name="streetAddress2"
                  autoComplete="streetAddress2"
                  type="text"
                  component={FormikInputField}
                  label="Street Address 2"
                  placeholder="Other Address"
                  disabled={props.initialValues.streetAddress2 === "" ? false : true}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="form-group" id="nex-pdf-country">
                  <Field
                    id="country"
                    name="country"
                    autoComplete="country"
                    type="text"
                    component={AddressTypeaheadField}
                    label="Country"
                    value={values.country}
                    placeholder="Select country"
                    spellCheck={false}
                    inputList={countryList}
                    getAddressById={(contryId) => getStates(contryId)}
                    locationRef={countryRef}
                    inputprops={{
                      autoComplete: "off",
                    }}
                    disabled={!iseditable}
                  // disabled={props.initialValues.country === [] ? true : false}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="form-group" id="nex-pdf-state">
                  <Field
                    id="state"
                    name="state"
                    type="text"
                    autoComplete="state"
                    component={AddressTypeaheadField}
                    label="State"
                    inputprops={{
                      autoComplete: "off",
                    }}
                    value={values.state}
                    placeholder="Select state"
                    spellCheck={false}
                    inputList={statesList}
                    getAddressById={(stateId) => getCities(stateId)}
                    locationRef={stateRef}
                    disabled={!iseditable}
                  // disabled={props.initialValues.state === [] ? true : false}
                  />
                </div>
              </Col>

            </Row>
            <Row>
              <Col lg={6}>
                <div className="form-group" id="nex-pdf-city">
                  <Field
                    id="city"
                    name="city"
                    type="text"
                    component={AddressTypeaheadField}
                    label="City"
                    inputprops={{
                      autoComplete: "off",
                    }}
                    value={values.city}
                    placeholder="Select city"
                    spellCheck={false}
                    inputList={cityList}
                    getAddressById={(cityId) => getLocations(cityId)}
                    locationRef={cityRef}
                    disabled={!iseditable}
                  // disabled={props.initialValues.city === [] ? true : false}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <Field
                  id="zipCode"
                  name="zipCode"
                  autoComplete="zipCode"
                  type="text"
                  component={FormikInputField}
                  label="Zip"
                  placeholder="Enter code"
                  disabled={!iseditable}
                // disabled={props.initialValues.zipCode === "" ? false : true}
                />
              </Col>
              <Col lg={6}>
                <div className="form-group" id="nex-pdf-location">
                  <Field
                    id="location"
                    name="location"
                    type="text"
                    component={LocationTypeaheadField}
                    label="Location"
                    inputprops={{
                      autoComplete: "off",
                    }}
                    value={values.location}
                    placeholder="Select Location"
                    spellCheck={false}
                    locations={locationList}
                    selectdLocation={selectdLocation}
                    locationRef={locRef}
                    disabled={!iseditable}
                  // disabled={props.initialValues.location === [] || props.initialValues.location.length === 0 ? false : true}
                  />
                </div>
              </Col>
            </Row>
          </div>
          {/** <fieldset className="u-flsd-wpr mb-3 nex-address-fieldset-sec nex-fieldset-field-sec">
            <legend>Address Information</legend>
            <Row>
              <Col lg={6}>
                <Field
                  id="streetAddress1"
                  name="streetAddress1"
                  autoComplete="streetAddress1"
                  type="text"
                  component={FormikInputField}
                  label="Street Address"
                  placeholder="Current address"
                  inputprops={{
                    autoComplete: "off",
                  }}
                  disabled={props.initialValues.streetAddress1 === "" ? false : true}
                />
              </Col>
              <Col lg={6}>
                <Field
                  id="streetAddress2"
                  name="streetAddress2"
                  autoComplete="streetAddress2"
                  type="text"
                  component={FormikInputField}
                  label="Street Address 2"
                  placeholder="Other Address"
                  disabled={props.initialValues.streetAddress2 === "" ? false : true}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="form-group" id="nex-pdf-country">
                  <Field
                    id="country"
                    name="country"
                    autoComplete="country"
                    type="text"
                    component={AddressTypeaheadField}
                    label="Country"
                    value={values.country}
                    placeholder="Select country"
                    spellCheck={false}
                    inputList={countryList}
                    getAddressById={(contryId) => getStates(contryId)}
                    locationRef={countryRef}
                    inputprops={{
                      autoComplete: "off",
                    }}
                    disabled={props.initialValues.country === [] ? true : false}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="form-group" id="nex-pdf-state">
                  <Field
                    id="state"
                    name="state"
                    type="text"
                    autoComplete="state"
                    component={AddressTypeaheadField}
                    label="State"
                    inputprops={{
                      autoComplete: "off",
                    }}
                    value={values.state}
                    placeholder="Select state"
                    spellCheck={false}
                    inputList={statesList}
                    getAddressById={(stateId) => getCities(stateId)}
                    locationRef={stateRef}
                    disabled={props.initialValues.state === [] ? true : false}
                  />
                </div>
              </Col>

            </Row>
            <Row>
              <Col lg={6}>
                <div className="form-group" id="nex-pdf-city">
                  <Field
                    id="city"
                    name="city"
                    type="text"
                    component={AddressTypeaheadField}
                    label="City"
                    inputprops={{
                      autoComplete: "off",
                    }}
                    value={values.city}
                    placeholder="Select city"
                    spellCheck={false}
                    inputList={cityList}
                    getAddressById={(cityId) => getLocations(cityId)}
                    locationRef={cityRef}
                    disabled={props.initialValues.city === [] ? true : false}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <Field
                  id="zipCode"
                  name="zipCode"
                  autoComplete="zipCode"
                  type="text"
                  component={FormikInputField}
                  label="Zip"
                  placeholder="Enter code"
                  disabled={props.initialValues.zipCode === "" ? false : true}
                />
              </Col>
              <Col lg={6}>
                <div className="form-group" id="nex-pdf-location">
                  <Field
                    id="location"
                    name="location"
                    type="text"
                    component={LocationTypeaheadField}
                    label="Location"
                    inputprops={{
                      autoComplete: "off",
                    }}
                    value={values.location}
                    placeholder="Select Location"
                    spellCheck={false}
                    locations={locationList}
                    selectdLocation={selectdLocation}
                    locationRef={locRef}
                    disabled={props.initialValues.location === [] || props.initialValues.location.length === 0 ? false : true}
                  />
                </div>
              </Col>
            </Row>
          </fieldset>*/}
        </>
        {/* )} */}
        {/* <fieldset className="u-flsd-wpr mb-3 nex-login-info-field-sec nex-fieldset-field-sec">
          <legend>Login Information</legend>
          <Row>
            <Col lg={6}>
              <div className="nexogic-phone-field-wrap">
                <Field
                  id="phone"
                  name="phone"
                  autoComplete="phone"
                  type="text"
                  component={FormikMobileField}
                  label="Mobile Number"
                  placeholder="Enter number"
                  value={props.initialValues.phone}
                  disabled={props.initialValues.phone === "" ? false : true}
                />
              </div>
            </Col>
            <Col lg={6}>
              <Field
                id="email"
                name="email"
                autoComplete="email"
                type="text"
                component={FormikInputField}
                label="Email Address"
                placeholder="Enter email"
                disabled={props.initialValues.email === "" ? false : true}
              />
              <div className="nex-field-info">** This is your  username</div>
            </Col>
          </Row> */}
        {/* <Row>
            <Col lg={6}>
              <div className="nexogic-password-field-wrap">
                <div className="password-eye">
                  <Field
                    id="password"
                    name="password"
                    autoComplete="password"
                    type={showPassword ? "text " : "password"}
                    component={FormikInputField}
                    label="Create Password"
                    placeholder="Password"
                  />
                  {!!errors.password === false && (
                    <FontAwesomeIcon
                      onClick={toggleShowPassword}
                      className="show-password"
                      color="#ccc"
                      icon={!showPassword ? faEyeSlash : faEye}
                    />
                  )}
                </div>
              </div>
            </Col>
            <Col>
              <div className="nexogic-password-field-wrap">
                <div className="password-eye">
                  <Field
                    id="confirmPassword"
                    name="confirmPassword"
                    autoComplete="confirmPassword"
                    type={showPassword ? "text " : "password"}
                    component={FormikInputField}
                    label="Confirm Password"
                    placeholder="Password"
                  />
                  {!!errors.password === false && (
                    <FontAwesomeIcon
                      onClick={toggleShowPassword}
                      className="show-password"
                      color="#ccc"
                      icon={!showPassword ? faEyeSlash : faEye}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row> */}
        {/* </fieldset> */}
        {/*<fieldset className="u-flsd-wpr mb-3">*/}
        {/*<legend>Set Password</legend>*/}

        {/*</fieldset>*/}
        <div className="nex-form-section nex-check-center-w">
          <div className="form-group">
            <InputGroup className="mt-3 justify-items-center terms-condition-block fw-semibold rememberMe-wrap">
              <Field
                id="rememberMe"
                name="rememberMe"
                type="checkbox"
                component={FormikCheckboxField}
              />

              <label
                htmlFor="rememberMe"
                className="form-check-label  text-center"
              >
                I agree to the
              </label>
              <span className="font-weight-bold text-primary policy">
                <a
                  href="https://nexogic.com/terms-and-conditions/"
                  target="_blank"
                  rel="noreferrer"
                >
                  &nbsp;Terms and Conditions&nbsp;
                </a>
              </span>
              <span className="form-check-label">
                {" "}
                and{" "}
              </span>
              <span className="font-weight-bold text-primary policy">
                <a
                  href="https://nexogic.com/privacy-policy/"
                  target="_blank"
                  rel="noreferrer"
                >
                  &nbsp; Privacy Policy&nbsp;
                </a>
              </span>
              <span className="form-check-label">
                {" "}
                of Nexogic
              </span>
            </InputGroup>
          </div>
        </div>
        <div className="form-group">
          <Button
            id="nex-pdf-form-next"
            className="w-100 nex-max-sm mx-auto next-btn"
            type="submit"
            disabled={isSubmitting}
            onClick={() => addressDetails(address)}
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  );
};
