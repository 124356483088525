import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const SurgeriesModalForm = (props) => {
    const multiSelectRef = useRef(null);

    const surgeries_options = [
        { "label": "Appendectomy", "value": "Appendectomy" },
        { "label": "Cataract surgery", "value": "Cataract surgery" },
        { "label": "Coronary artery bypass graft (CABG)", "value": "CABG" },
        { "label": "Cholecystectomy (gallbladder removal)", "value": "Cholecystectomy" },
        { "label": "Hernia repair", "value": "Hernia repair" },
        { "label": "Total knee replacement", "value": "Total knee replacement" },
        { "label": "Total hip replacement", "value": "Total hip replacement" },
        { "label": "Laminectomy (spinal surgery)", "value": "Laminectomy" },
        { "label": "Mastectomy", "value": "Mastectomy" },
        { "label": "Prostatectomy", "value": "Prostatectomy" },
        { "label": "Colon resection", "value": "Colon resection" },
        { "label": "Gastric bypass surgery", "value": "Gastric bypass" },
        { "label": "Tonsillectomy", "value": "Tonsillectomy" },
        { "label": "Hip arthroscopy", "value": "Hip arthroscopy" },
        { "label": "Lung resection", "value": "Lung resection" },
        { "label": "Heart valve replacement", "value": "Heart valve replacement" },
        { "label": "Cesarean section (C-section)", "value": "C-section" },
        { "label": "Craniotomy (brain surgery)", "value": "Craniotomy" },
        { "label": "Appendix surgery", "value": "Appendix surgery" },
        { "label": "Gastric sleeve surgery", "value": "Gastric sleeve" },
        { "label": "Coronary angioplasty", "value": "Coronary angioplasty" },
        { "label": "ACL reconstruction", "value": "ACL reconstruction" },
        { "label": "Liver transplant", "value": "Liver transplant" },
        { "label": "Kidney transplant", "value": "Kidney transplant" },
        { "label": "Hysterectomy", "value": "Hysterectomy" },
        { "label": "Bone fracture repair", "value": "Bone fracture repair" },
        { "label": "Thyroidectomy", "value": "Thyroidectomy" },
        { "label": "Gallbladder surgery", "value": "Gallbladder surgery" },
        { "label": "Spleen removal", "value": "Spleen removal" },
        { "label": "Pancreatic surgery", "value": "Pancreatic surgery" },
        { "label": "Tumor removal surgery", "value": "Tumor removal surgery" },
        { "label": "Facelift surgery", "value": "Facelift surgery" },
        { "label": "Joint fusion surgery", "value": "Joint fusion surgery" },
        { "label": "Bladder surgery", "value": "Bladder surgery" }
    ];
    



    return (
        <Modal isOpen={props.modal} toggle={props.toggle} {...props} className="so_mh_modal_popup">
            <ModalHeader toggle={props.toggle}>Surgeries</ModalHeader>
            <ModalBody>
                <div className="nex-modal-select-drops-wrap nex-symptoms-sdrops-w" ref={multiSelectRef} >

                    <MultiSelect

                        options={surgeries_options}
                        value={props.surgeries_selected}
                        onChange={props.setSurgeries_selected}
                        labelledBy="Select"
                    />
                </div>
                <div className="nex-modal-actions mt-3 mb-2 d-flex justify-content-end">
                    <a href="#" className="nexogic_primary_button" onClick={(e) => {
                        props.toggle();
                    }}>Close</a>
                </div>
            </ModalBody>

        </Modal>
    )
}
export default SurgeriesModalForm;