import CityAutocompleteTypeaheadField from "components/forms/formikFields/CityAutocompleteTypeaheadField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import PrimarySpecialityTypeaheadField from "components/forms/formikFields/PrimarySpecialityTypeaheadField";
import {
  getJobData,
  updateFilterData,
  useJobFilterDispatch,
  useJobFilterState,
} from "context/JobFilterContext";
import { Field, Form, Formik } from "formik";
import { memo, useEffect, useState } from "react";
import { Button, PopoverBody, UncontrolledPopover } from "reactstrap";
import { Opportunity_Type } from "utils/Constants";
import { MetadataService } from "utils/MetadataService";
import * as Yup from "yup";
import filter_icon from "../../../../../assets/images/svgs/filter-blue-icon.svg";

const initValues = {
  opportunity: "",
  primarySpeciality: [],
  city: [],
};

const validationSchema = Yup.object().shape({
  opportunity: Yup.string(),
  primarySpeciality: Yup.array().nullable(),
  city: Yup.array().nullable(),
});

const JobFilter = (props) => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setPopoverOpen(!popoverOpen);
  const [selectedFrom, setSelectedFrom] = useState(initValues);
  const { setPrimarySpeciality, setCity, setOpportunityType, getData } = props;
  const [primarySpecialityList, setPrimarySpecialityList] = useState([]);


  function makeQuery(sqOpportunity, sqCity, sqPrimary) {
    let query = "";
    if (sqOpportunity !== "") {
      query = `(op=="${encodeURIComponent(sqOpportunity)}")`;
    }

    if (sqCity !== "") {
      if (query === "")
        query = `(cy=="${encodeURIComponent(sqCity)}")`;
      else
        query += `;(cy=="${encodeURIComponent(sqCity)}")`;
    }

    if (sqPrimary !== "") {
      if (query === "")
        query = `(ps=="${encodeURIComponent(sqPrimary)}")`;
      else
        query += `;(ps=="${encodeURIComponent(sqPrimary)}")`;
    }

    return query;
  }


  const getprimarySpeciality = () => {
    MetadataService.getPrimarySpecialities()
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setPrimarySpecialityList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getprimarySpeciality error", error);
      });
  };

  const handleFilter = (values) => {
    setOpportunityType(values.opportunity);
    setCity(((values.city)[0]?.value)?.split(",")[0])
    setPrimarySpeciality((values.primarySpeciality)[0]);
    getData(0, [], makeQuery(values?.opportunity ?? "", ((values?.city)[0]?.value)?.split(",")[0] ?? "", (values?.primarySpeciality)[0] ?? ""));

    setSelectedFrom({
      opportunity: values?.opportunity || "",
      primarySpeciality:
        values?.primarySpeciality && values?.primarySpeciality.length > 0
          ? values?.primarySpeciality
          : [],
      city: values?.city && values?.city.length > 0 ? values?.city : [],
    });

    toggle();

  };

  useEffect(() => {
    getprimarySpeciality();
  }, []);

  return (
    <>
      <Button
        id="PopoverFocus"
        type="button"
        className="filter-btn-wrp"
        onClick={toggle}
      >
        <img src={filter_icon} alt="#" className="ml-2" />
      </Button>
      <UncontrolledPopover
        placement="bottom"
        target="PopoverFocus"
        trigger="legacy"
        isOpen={popoverOpen}
        toggle={toggle}
      >
        <PopoverBody>
          <Formik
            initialValues={selectedFrom}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={handleFilter}
            children={(props) => {
              const { values, handleSubmit } = props;
              return (
                <Form onSubmit={handleSubmit}>
                  <div className="filter-item-check">
                    <div className="form-group" id="nex-jf-primary-speciality">
                      <Field
                        id="primarySpeciality"
                        name="primarySpeciality"
                        autoComplete="primarySpeciality"
                        label="Primary Speciality"
                        className="primary-specialities"
                        type="text"
                        component={PrimarySpecialityTypeaheadField}
                        placeholder="Select Speciality"
                        inputprops={{
                          autoComplete: "off",
                        }}
                        value={values.primarySpeciality}
                        spellCheck={false}
                        specialit={primarySpecialityList}
                      />
                    </div>
                    <div className="form-group" id="nex-jf-city">
                      <Field
                        name="city"
                        id="city"
                        autocomplete="city"
                        type="text"
                        label="City"
                        component={CityAutocompleteTypeaheadField}
                        placeholder="City"
                      />
                    </div>
                    <div className="form-group">
                      <Field
                        id="job_filter_opportunity"
                        name="opportunity"
                        type="text"
                        label="Opportunity Type"
                        component={FormikSelectField}
                        inputprops={{
                          name: `opportunity`,
                          options: Opportunity_Type,
                          defaultOption: "Select",
                          keys: {
                            id: "value",
                            label: "label",
                          },
                        }}
                      />
                    </div>
                    <div className="text-center">
                      <Button id="job_filter_apply" color="info" type="submit" outline block>
                        Apply
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          />
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

export default memo(JobFilter);
