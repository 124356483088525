import { withAlert } from "react-alert";
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, FormGroup, Input, ListGroup, ListGroupItem, Row } from "reactstrap";
import { FAILED_TO_FETCH_DATA } from "utils/Constants";
import { PatientService } from "utils/PatientService";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
const PersonalDetailsForm1 = ({ forUpdate, setCurrentStep, isSelf, setIsSelf, currentUser, setCurrentUserDetails, alert }) => {

    const updateUserDetails = () => {
        PatientService.getPatientById(currentUser?.profileId)
            .then((response) => {
                const { status, data } = response;
                if (status == 200) {
                    setCurrentUserDetails(data);
                    setIsSelf(true);
                    setCurrentStep('2')
                }
                else {
                    showErrorMessage(FAILED_TO_FETCH_DATA);
                }
            })
    }
    return (
        <div className="nex-form-field-container">
            <div className="nex-form-group nex-content-center">
                <div className="nex-form-group-c">
                    <label htmlFor="" className="nex-big-label">{`${forUpdate ? "Update" : "Create"} Second Opinion Request for`}</label>
                    <ButtonGroup className="nex-radio-card">
                        <Button
                            color="primary"
                            outline
                            className={`nex-radio-card-item ${isSelf === true ? 'active' : ''}`}
                            onClick={(e) => {
                                updateUserDetails();
                            }}
                            disabled={forUpdate}
                        >
                            <div className="graphic">
                                <svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="112" height="112" rx="56" fill="white" />
                                    <rect x="2.5" y="2.5" width="107" height="107" rx="53.5" stroke="white" strokePpacity="0.5" strokeWidth="5" />
                                    <path d="M56 51.5C60.6981 51.5 65.2038 49.6299 68.5259 46.3011C71.848 42.9724 73.7143 38.4576 73.7143 33.75C73.7143 29.0424 71.848 24.5276 68.5259 21.1989C65.2038 17.8701 60.6981 16 56 16C51.3019 16 46.7962 17.8701 43.4741 21.1989C40.152 24.5276 38.2857 29.0424 38.2857 33.75C38.2857 38.4576 40.152 42.9724 43.4741 46.3011C46.7962 49.6299 51.3019 51.5 56 51.5ZM49.6754 58.1562C36.0437 58.1562 25 69.2223 25 82.8814C25 85.1557 26.8406 87 29.1103 87H82.8897C85.1594 87 87 85.1557 87 82.8814C87 69.2223 75.9562 58.1562 62.3246 58.1562H49.6754Z" fill="#D7D7D7" />
                                </svg>

                            </div>
                            <div className="check">
                                <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14 1L5.0625 10L1 5.90909" stroke="#2D4552" strokeOpacity="0.27" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                            </div>
                            <div className="label">Yourself</div>
                        </Button>
                        <Button
                            color="primary"
                            outline
                            className={`nex-radio-card-item ${isSelf === false ? 'active' : ''}`}
                            onClick={(e) => {
                                setIsSelf(false);
                                setCurrentStep('2b')
                            }}
                            disabled={forUpdate}
                        >
                            <div className="graphic">
                                <svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="112" height="112" rx="56" fill="white" />
                                    <rect x="2.5" y="2.5" width="107" height="107" rx="53.5" stroke="white" strokePpacity="0.5" strokeWidth="5" />
                                    <path d="M56 51.5C60.6981 51.5 65.2038 49.6299 68.5259 46.3011C71.848 42.9724 73.7143 38.4576 73.7143 33.75C73.7143 29.0424 71.848 24.5276 68.5259 21.1989C65.2038 17.8701 60.6981 16 56 16C51.3019 16 46.7962 17.8701 43.4741 21.1989C40.152 24.5276 38.2857 29.0424 38.2857 33.75C38.2857 38.4576 40.152 42.9724 43.4741 46.3011C46.7962 49.6299 51.3019 51.5 56 51.5ZM49.6754 58.1562C36.0437 58.1562 25 69.2223 25 82.8814C25 85.1557 26.8406 87 29.1103 87H82.8897C85.1594 87 87 85.1557 87 82.8814C87 69.2223 75.9562 58.1562 62.3246 58.1562H49.6754Z" fill="#D7D7D7" />
                                </svg>

                            </div>
                            <div className="check">
                                <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14 1L5.0625 10L1 5.90909" stroke="#2D4552" strokeOpacity="0.27" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                            </div>
                            <div className="label">Someone Else</div>
                        </Button>

                    </ButtonGroup>

                    {(isSelf != null) &&
                        <div className="nex-form-group-c mt-10">
                            <div className="nex-form-action">
                                <Button
                                    color="primary"
                                    className="nexogic_primary_button_outline"
                                    onClick={(e) => setCurrentStep('2')}
                                >
                                    Next
                                </Button>
                            </div>
                        </div>
                    }

                    <div className="nex-tips-box-card mt-10">
                        <div className="graphic">
                            <div className="icon"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 10H2M10 1V2M18 10H19M3.6 3.6L4.3 4.3M16.4 3.6L15.7 4.3M7.7 15H12.3M7 14C6.16047 13.3704 5.54033 12.4925 5.22743 11.4908C4.91453 10.4892 4.92473 9.41442 5.25658 8.41886C5.58844 7.4233 6.22512 6.55739 7.07645 5.94379C7.92778 5.33019 8.95059 5 10 5C11.0494 5 12.0722 5.33019 12.9236 5.94379C13.7749 6.55739 14.4116 7.4233 14.7434 8.41886C15.0753 9.41442 15.0855 10.4892 14.7726 11.4908C14.4597 12.4925 13.8395 13.3704 13 14C12.6096 14.3865 12.3156 14.8594 12.1419 15.3806C11.9681 15.9018 11.9195 16.4566 12 17C12 17.5304 11.7893 18.0391 11.4142 18.4142C11.0391 18.7893 10.5304 19 10 19C9.46957 19 8.96086 18.7893 8.58579 18.4142C8.21071 18.0391 8 17.5304 8 17C8.08046 16.4566 8.03185 15.9018 7.85813 15.3806C7.6844 14.8594 7.39043 14.3865 7 14Z" stroke="#3EABCA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            </div>
                            <div className="title">Tips</div>
                        </div>
                        <div className="info">Select <span className="highlited">“Yourself”</span> if you want to create second opinion for you.
                            If you want to add second opinion for other then choose <span className="highlited">“Someone Else”</span>.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withAlert()(PersonalDetailsForm1);