import React, { useState } from "react"
import { Card, CardBody, Col, Row, Button } from "reactstrap"
import { createImageFromInitials, getRandomColor } from "utils/Utils"
import { encrypt } from "utils/EncryptDecrypt"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faIdCard, faListAlt, faTable } from "@fortawesome/free-solid-svg-icons";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import CommonLoader from "common/CommonLoader"
import NoInvitations from "../../Groups/components/NoInvitations"
import { withAlert } from "react-alert"
import { ConnectionService } from "utils/ConnectionService"
import LoadMoreButton from "common/LoadMoreButton/LoadMoreButton"
import PagesFollowedTabularView from "components/tabularView/PagesFollowedTabularView"

const PagesFollowed = (props) => {
    const { pages, isLoading, getFollowedPages, alert, currentPage, hasMore, isLoadingMore, setIsLoadingMore } = props;
    const [viewMode, setViewMode] = useState("card");

    const handleUnFollow = (id) => {
        ConnectionService.UnFollowing(id)
            .then((response) => {
                if (response.status === 204) {
                    getFollowedPages(0);
                    showSuccessMessage("Page unfollowed successfully..");
                }
                else {
                    showErrorMessage("Something went wrong...");
                }
            })
    }

    const fetchData = () => {
        setIsLoadingMore(true);
        getFollowedPages(currentPage + 1);
    };

    const renderProfiles = () => {
        if (viewMode === 'card') {
            return (
                <>
                    {pages.map((e, ind) => (
                        <Card className="nexogic-list-item-card bg-white">
                            <CardBody key={'follow-page-' + ind}>
                                <div className="nexogic-graphic">
                                    <img src={createImageFromInitials(50, e?.institutionProfileInfo?.name, getRandomColor(encrypt(e?.institutionProfileInfo?.guid).toString()))} className="card-img rounded card-img-md" />
                                </div>
                                <div className="nexogic-info">
                                    <div className="nexogic-content-details">
                                        <h3 className="card-title"><Link className="text-decoration-none" to={`/institution/${e.institutionProfileInfo?.profileId}`}>{e.institutionProfileInfo?.name}</Link></h3>
                                    </div>
                                </div>
                                <div className="action"><Button color="primary" className="nexogic_primary_button_outline" onClick={() => handleUnFollow(e.id)}>Unfollow page</Button></div>

                            </CardBody>
                        </Card>

                    ))}
                </>
            );
        } else { // For table view
            return (
                <PagesFollowedTabularView
                    theader={['Name', 'Type', 'Unfollow']} tdata={pages} viewmode={viewMode}
                    handleUnFollow={handleUnFollow}
                />
            );
        }
    };

    return (
        <>
            <div className="view-toggle nexogic-view-toggle">
                <div className="btns">
                    <FontAwesomeIcon title="Card View" color={viewMode === "card" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faAddressCard} onClick={() => setViewMode("card")} /> | <FontAwesomeIcon title="Table View" color={viewMode === "table" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faListAlt} onClick={() => setViewMode("table")} />
                </div>
            </div>

            <div className="nexogic-custom-scroll" style={{ '--mx-height': '650px' }}>
                {
                    isLoading ?
                        <Col><div className="text-center"><CommonLoader /></div></Col>
                        :
                        (
                            pages.length > 0
                                ?
                                <>{renderProfiles()}</>
                                :
                                <div className="nexogic-nodata-dotted"><div className="no-suggestion-text text-center"><NoInvitations content={"No Pages"} /></div></div>
                        )
                }
                {hasMore &&
                    <div className="text-center mt-3">
                        {isLoadingMore
                            ?
                            <CommonLoader style={{ width: "2rem", height: "2rem" }} />
                            :
                            <LoadMoreButton loadMore={fetchData} />
                        }
                    </div>
                }
            </div>
        </>
    )
}

export default withAlert()(PagesFollowed)