import React from 'react'
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { Row, Col, Button, CardBody, Card, Modal, ModalHeader, ModalBody } from "reactstrap";
import SecondOpinionImg from "assets/images/second-opinion.png";
import BackIcon from "assets/images/backIcon.png";
import { useHistory } from "react-router-dom";
import { MedialService } from 'utils/MediaService';
import { FAILTOSAVERECOED } from 'utils/Constants';
import { useEffect } from 'react';
import CommonLoader from 'common/CommonLoader';
import { useRef } from 'react';
import { withAlert } from 'react-alert';
import { isPractitioner } from 'utils/UserHelper';
import { getFileIcon } from 'utils/Utils';

export default withAlert()(function TreatmentDetails(props) {
    const [docsInfo, setDocsInfo] = useState([]);
    const [isFileUploaing, setIsFileUploaing] = useState(false);
    const fileInputRef = useRef(null);
    const [dataLoading, setDataLoading] = useState(false);
    const [documentType, setDocumentType] = useState(null);
    const [documentData, setDocumentData] = useState(null)

    const [modal, setModal] = useState(false);
    const toggleForm = () => setModal(!modal);

    const getDocsInfo = (id) => {
        MedialService.getMedias("REFERRAL", id)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    // console.log(data);
                    setDocsInfo(data);
                } else {
                    console.log(FAILTOSAVERECOED);
                }
            })
    }

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = documentData;
        let ext = documentType[1].split('/').pop();
        link.download = 'reports.' + ext;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        link.click();
    };


    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            // Perform any necessary validation or processing of the selected file here
            setIsFileUploaing(true);
            // Create a FormData object to send the file data
            const formData = new FormData();
            formData.append('files', selectedFile);

            // Submit the file to the API using your preferred method (e.g., fetch, axios)
            MedialService.uploadMedia("REFERRAL", treatment?.id, formData)
                .then((response) => {
                    const { status } = response;
                    if (status === 200) {
                        showSuccessMessage("Document uploaded Successfully");
                        getDocsInfo(treatment?.id);
                        setIsFileUploaing(false);
                    } else if (status === 409) {
                        props.alert.info("Can't upload the document with the same name again");
                        setIsFileUploaing(false);
                    }
                    else {
                        showErrorMessage(FAILTOSAVERECOED);
                        setIsFileUploaing(false);
                    }
                })
        }
    };

    const getReferralDoc = async (id) => {
        try {
            setDataLoading(true);
            const { data, headers } = await MedialService.getMediaFile(id);
            const createContentType = headers["content-type"] === "application/octet-stream"
                ? "application/pdf"
                : headers["content-type"];
            let generateURL = `data:${createContentType};base64,${Buffer.from(data).toString("base64")}`;
            setDocumentData(generateURL);
            let docArray = [data, createContentType];
            setDocumentType(docArray);
            if (createContentType === "application/pdf") {
                setDataLoading(false);
                onOpenBase64(generateURL);
            }
            else {
                setDataLoading(false);
                toggleForm();
            }
        } catch (error) {
            console.error("Error retrieving referral document:", error);
        }
    };

    const onOpenBase64 = (base64URL) => {
        // const onOpenBase64 = () => {
        try {
            var win = window.open();
            win.document.write(`
            <iframe class="nexogic-iframe" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" 
              src="${base64URL}" frameborder="0" allowfullscreen></iframe>
          `);
        } catch (error) {
            console.error("Error opening base64 URL:", error);
            // Handle error, show error message, etc.
        }
    };


    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const location = useLocation();
    const { treatment, isResponseSent, isEditable } = location.state;

    const history = useHistory();
    const handleBack = () => {
        history.goBack()
    }

    useEffect(() => {
        getDocsInfo(treatment?.id);
    }, [treatment])


    const createTreatmentType = (type) => {
        if (type === 'IN_PATIENT')
            return 'In Patient';
        else if (type === 'OUT_PATIENT')
            return 'Out Patient';
        else
            return type;
    }

    return (
        <Card className="bg-transparent border-0">
            <CardBody className="p-0">
                <Row className="mb-5 nexogic-treatment-details-wrap" >
                    <Col lg={12}>
                        <div className='nexogic-action nexogic-top-section-title '>
                            <h2>
                                <span onClick={handleBack} style={{ cursor: 'pointer' }}>
                                    <img src={BackIcon} alt="back" /> Back
                                </span>
                            </h2>
                        </div>
                    </Col>
                    <Col lg={9} md={8} sm={12}>

                        {/* treatment details */}

                        <div className="position-relative mb-3">
                            <Card className={'nexogic-card-color-header expanded'}>
                                <div className="card-header">
                                    <h2>Treatment</h2>

                                </div>
                                <div className="collapse-div">
                                    <CardBody>
                                        <Row className='need-margin'>
                                            <Col sm={3} className='mb-3 col-6'>
                                                <h3 className="card-title">Type</h3>
                                                <p className="card-text">{createTreatmentType(treatment?.type)}</p>
                                            </Col>
                                            <Col sm={3} className='mb-3 col-6'>
                                                <h3 className="card-title">Duration</h3>
                                                <p className="card-text">{treatment?.duration}</p>
                                            </Col>
                                            <Col sm={6} className='mb-3 col-6'>
                                                <h3 className="card-title">Estimated Discharge Amount</h3>
                                                <p className="card-text">{" ₹ "}{treatment?.cost}</p>
                                            </Col>
                                            <Col lg={12} className='mb-4 '>
                                                <h3 className="card-title">Summary</h3>
                                                <p className="card-text">
                                                    {treatment?.summary
                                                        ?
                                                        <>
                                                            {treatment?.summary}
                                                        </>
                                                        :
                                                        "N/A"
                                                    }
                                                </p>
                                            </Col>
                                            <Col lg={12} className='mb-4'>
                                                <h3 className="card-title">Procedures</h3>
                                                <p className="card-text">
                                                    {treatment?.procedures
                                                        ?
                                                        <>
                                                            <span dangerouslySetInnerHTML={{ __html: treatment?.procedures }}>
                                                            </span>
                                                        </>
                                                        :
                                                        "N/A"
                                                    }
                                                </p>
                                            </Col>
                                            <Col lg={12} className='mb-4'>
                                                <h3 className="card-title">Prescriptions</h3>
                                                <p className="card-text">
                                                    {treatment?.prescriptions
                                                        ?
                                                        <>
                                                            <span dangerouslySetInnerHTML={{ __html: treatment?.prescriptions }}>
                                                            </span>
                                                        </>
                                                        :
                                                        "N/A"
                                                    }
                                                </p>
                                            </Col>
                                            <Col lg={12} className='mb-4'>
                                                <h3 className="card-title">Post Treatment / Medications</h3>
                                                <p className="card-text">
                                                    {treatment?.postTreatment
                                                        ?
                                                        <>
                                                            <span dangerouslySetInnerHTML={{ __html: treatment?.postTreatment }}>
                                                            </span>
                                                        </>
                                                        :
                                                        "N/A"
                                                    }
                                                </p>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </div>
                            </Card>
                        </div>
                    </Col>
                    <Col lg={3} md={4} sm={12} className="nexogic-sidebar-col ">
                        <Row>
                            <Card className="nexogic-widget-sidebar-card document-sidebar-widget">
                                <h2 className="card-title h2 d-flex flex-wrap">Treatment Documents
                                    {isResponseSent && isEditable &&
                                        <>
                                            {isFileUploaing ?
                                                <div className="text-center mb-5" style={{ height: "10px" }} >
                                                    <CommonLoader />
                                                </div>
                                                :
                                                <button className="btn nexogic-download-btn nexogic_primary_button" onClick={handleUploadClick}>
                                                    <span className="icon"><svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="none"
                                                        viewBox="0 0 16 16"
                                                    >
                                                        <path
                                                            stroke="#fff"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth="2"
                                                            d="M15 10.333v3.111A1.556 1.556 0 0113.444 15H2.556A1.556 1.556 0 011 13.444v-3.11m10.889-5.445L8 1m0 0L4.111 4.889M8 1v9.333"
                                                        ></path>
                                                    </svg></span> Uploads
                                                </button>
                                            }
                                        </>
                                    }
                                    <input
                                        name="uploadDocument"
                                        type="file"
                                        id="uploadDocument"
                                        accept="image/jpeg,image/jpg,image/png,application/pdf"
                                        title="Upload Document"
                                        className="nexogic-file-upload-input"
                                        style={{ display: 'none' }}
                                        ref={fileInputRef}
                                        onChange={handleFileChange}
                                    />
                                </h2>
                                <CardBody>
                                    {(docsInfo && docsInfo.length > 0) ?
                                        <div className="nexogic-custom-scroll">
                                            <div className="nexogic-document-lists">
                                                <ul>
                                                    {docsInfo.map((doc, index) => (
                                                        <li key={index} >
                                                            <div className="nexogic-document-item" style={{ cursor: "pointer" }}>
                                                                <img src={getFileIcon(doc?.type)} style={{ cursor: "pointer", height: '50px' }} />
                                                                <div className="card-text">{doc?.name}</div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>

                                            </div>
                                        </div>
                                        :
                                        <div className="nexogic-not-found-text">No Documents</div>
                                    }
                                </CardBody>
                            </Card>
                        </Row>
                        <Row>
                            <div className="nexogic-widget-sidebar-card card border-0 mb-0">
                                <h3 className="card-title h2">Second Opinion</h3>
                                <div className="card-body">
                                    <ul className="nexogic-lists nexogic-custom-scroll">
                                        <li>
                                            <img src={SecondOpinionImg} alt="" className="img-fluid" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Row>
                    </Col>
                </Row>
                {
                    dataLoading ?
                        <div className="" style={{ zIndex: "111", position: "absolute", top: "0", left: "0", backgroundColor: "#edeff0b0", width: "100%", height: "100%" }}>
                            <div className="component-wrapper"><CommonLoader /></div>
                        </div>
                        :
                        <Modal isOpen={modal} toggle={toggleForm} backdrop="static" scrollable centered >
                            <ModalHeader toggle={toggleForm}><span style={{ color: "gray" }}>Document Viewer</span></ModalHeader>
                            <ModalBody>
                                <div className="modalbody">
                                    <img src={documentData} alt="document preview" />
                                </div>
                                <div className="downloadbtn">
                                    <FontAwesomeIcon color="#0087b9" size="xl" style={{ cursor: "pointer" }} icon={faDownload} onClick={handleDownload} />
                                </div>
                            </ModalBody>
                        </Modal>
                }
            </CardBody>
        </Card>
    )
})
