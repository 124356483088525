import React, { useState } from "react"
import { Field, Formik } from 'formik';
import { Row, Col, Button, Spinner } from 'reactstrap'
import { withAlert } from "react-alert"
import * as Yup from 'yup';
import FormikInputField from "components/forms/formikFields/FormikInputField"
import { InstitutionServices } from "utils/InstitutionServices"
import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage"
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const socialHandlerSchema = Yup.object().shape({
    facebookHandle: Yup.string().url('Not a valid url').matches(/^https?:\/\/(?:www.)?facebook\.com.*\/.+$/, "Url must be from facebook.com(https://facebook.com/xyz)").nullable(),
    twitterHandle: Yup.string().url('Not a valid url').matches(/^https?:\/\/(?:www.)?twitter\.com.*\/.+$/, "Url must be from twitter.com(https://twitter.com/xyz)").nullable(),
    linkedinHandle: Yup.string().url('Not a valid url').matches(/^https?:\/\/(?:www.)?linkedin\.com.*\/.+$/, "Url must be from linkedin.com(https://linkedin.com/xyz)").nullable(),
    instaHandle: Yup.string().url('Not a valid url').matches(/^https?:\/\/(?:www.)?instagram\.com.*\/.+$/, "Url must be from instagram.com(https://instagram.com/xyz)").nullable(),
});

const RenderForm = (props) => {
    const { values, toggle, isValid, dirty, handleSubmit, isLoading } = props
    // console.log(values, props);
    return (
        <form className="my-form" onSubmit={handleSubmit}>
            <div >
                <Row form>
                    <Col xs={12}>
                        <Field
                            id="rsm_instaHandle"
                            name="instaHandle"
                            type="text"
                            label="Instagram"
                            component={FormikInputField}
                            placeholder="Instagram link"
                        />
                    </Col>
                </Row>
                <Row form>
                    <Col xs={12}>
                        <Field
                            id="rsm_linkedinHandle"
                            name="linkedinHandle"
                            type="text"
                            label="LinkedIn"
                            component={FormikInputField}
                            placeholder="LinkedIn link"
                        />
                    </Col>
                </Row>
                <Row form>
                    <Col xs={12}>
                        <Field
                            id="rsm_facebookHandle"
                            name="facebookHandle"
                            type="text"
                            label="Facebook"
                            component={FormikInputField}
                            placeholder="Facebook link"
                        />
                    </Col>
                </Row>
                <Row form>
                    <Col xs={12}>
                        <Field
                             id="rsm_twitterHandle"
                            name="twitterHandle"
                            type="text"
                            label="Twitter"
                            component={FormikInputField}
                            placeholder="Twitter link"
                        />
                    </Col>
                </Row>

                {/*<hr className="profile-info-modal-hr mt-0 mb-3" />*/}
                <div className="justify-content-end  modal-footer  " id="profile-modal">
                    {/* <Col xs="auto">
                        <Button color="link" className="cancel-btn" onClick={() => toggle()}>Cancel</Button>
                    </Col> */}
                    <div className="action-group">
                        {/* <Button disabled={!isValid || !dirty} color="primary" type="submit">Save</Button> */}
                        {isLoading ?
                            <div className="loading-spiner"><Spinner style={{ width: '2rem', height: '2rem' }} /></div>
                            :
                            <>
                                <Button id="rsm_btn_submit" className="nexogic_primary_button" disabled={!isValid || !dirty} color="primary" type="submit">Save</Button>
                                <Button id="rsm_btn_cancel" className="nexogic_primary_button_outline " color="primary" type="button" onClick={toggle}>Cancel</Button>
                            </>

                        }
                    </div>
                </div>
            </div>
        </form>
    )
}



const RenderSocialMedia = (props) => {
    const { data, practitionerId, practitioner, onSave, toggle, alert, institution, getInstitutionProfile } = props
    // console.log(data);
    const [isLoading, setLoading] = useState(false)
    const [errorMsg, setErrorMessage] = useState("")
    const saveSocialHandles = async (values) => {
        setLoading(true)
        let payload = generatePayload({ practitionerId, ...values });
        alert.removeAll()
        InstitutionServices.patchInstitutionDetails(payload, institution?.guid)
            .then(response => {
                // console.log("response", response)
                const { status } = response
                if (status === 200) {
                    showSuccessMessage('Succesfully update social handles')
                    setLoading(false)
                    toggle();
                    getInstitutionProfile(institution?.guid);
                }
                else {
                    showErrorMessage('Failed to update social handles')
                    setLoading(false)
                }

            }, e => {
                setLoading(false)
                console.log("error saveSocialHandles", e)
                let errmsg = e.message
                if (e.response) {
                    errmsg = (e.response.data && !!e.response.data.message) ? e.response.data.message : errmsg
                }
                showErrorMessage(errmsg)
            })
    }

    const generatePayload = (requestPayload) => {
        const valuesList = [];
        Object.entries(requestPayload).forEach((data) => {
            switch (data[0]) {
                case 'facebookHandle':
                    if (data[1]) {
                        valuesList.push({
                            "type": "FACEBOOK",
                            "value": data[1]
                        })
                    }
                    break;
                case 'linkedinHandle':
                    if (data[1]) {
                        valuesList.push({
                            "type": "LINKEDIN",
                            "value": data[1]
                        })
                    }
                    break;
                case 'twitterHandle':
                    if (data[1]) {
                        valuesList.push({
                            "type": "TWITTER",
                            "value": data[1]
                        })
                    }
                    break;
                case 'instaHandle':
                    if (data[1]) {
                        valuesList.push({
                            "type": "INSTAGRAM",
                            "value": data[1]
                        })
                    }
                    break;
            }
        });
        const returnPayload = [
            {
                "op": "add",
                "path": "/socialContacts",
                "value": valuesList
            }
        ];
        return returnPayload;
    }

    return (
        <>
            {errorMsg !== "" && <RenderErrorMessage errorMsg={errorMsg} />}
            <Formik
                enableReinitialize={true}
                validationSchema={socialHandlerSchema}
                initialValues={data}
                onSubmit={(values) => {
                    saveSocialHandles(values)
                }}

                children={(props) => {
                    return <RenderForm toggle={toggle} {...props} isLoading={isLoading} />
                }}
            />
        </>
    )
}
export default withAlert()(RenderSocialMedia);