import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import medicalHistoryIcon from "assets/images/svgs/medical_history_icon.svg";
import classnames from "classnames";
import AlertDialog from "common/AlertDialog";
import { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import {
  profileAddEvent,
  updateProfileCompletness,
} from "redux-helpers/ActionCreator";
import { UserStatusConstants } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import { getEmptySectionShowHide, getFullAddress, makeFullNameWithAddressWhenAddressIsNested } from "utils/Utils";
import { RenderClinicalTrialsModal } from "./component/RenderClinicalTrialsModal";
import small_marker from "assets/svgIcon/marker-small.svg";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import clinical_trials_icon from "assets/svgIcon/clinical_trials_icon.svg";
import plusIcon from "assets/images/plus-icon-lg.png";
import edit_blue_icon from "assets/images/edit-blue-icon.png";
import del_red_icon from "assets/images/del-red-icon.png";
import NoData from "../NoData";

const initialValue = {
  title: "",
  issuedBy: {},
  url: "",
  issuedOn: "",
  institutionDetail: {},
  addressLine1: "",
  addressLine2: "",
  city: [],
  country: [],
  location: [],
  postalCode: "",
  state: [],
  category: "",
};

const RenderClinicalTrials = (props) => {
  // useEffect(() => {
  //   console.log(props);
  // })
  const {
    clinicalTrials,
    handleEditItem,
    handleRemoveItem,
    isLoggedinUser,
    isAdmin,
  } = props;
  const currentUser = useSelector((state) => state.auth.currentUser);
  if (clinicalTrials && clinicalTrials.length > 0) {
    return clinicalTrials.map((item, index) => {
      const institutionName = getFullAddress({
        name: item?.institutionDetail?.name ?? "",
        abbreviation: item?.institutionDetail?.abbreviation ?? "",
        location: item?.institutionDetail?.address?.location ?? "",
        city: item?.institutionDetail?.address?.city ?? "",
        state: item?.institutionDetail?.address?.state ?? "",
      });
      const issueDate = item?.issuedOn;
      const profileIdLink = item?.institutionDetail?.profileId
        ? item?.institutionDetail?.profileId
        : item?.institutionDetail?.guid;
      if (item?.institutionDetail?.guid) {
        item.institutionDetail = {
          guid: item?.institutionDetail?.guid,
          name: item?.institutionDetail?.name,
          institutionName: item?.institutionDetail ? makeFullNameWithAddressWhenAddressIsNested(item?.institutionDetail) : "",
          onSelectNewInstitutionCollege: 1,
          ...(Object.keys(item?.institutionDetail?.address).length > 0 && {
            address: item?.institutionDetail?.address,
          }),
          ...(item?.institutionDetail?.abbreviation && {
            abbreviation: item?.institutionDetail?.abbreviation,
          }),
        };
      }




      return (
        <li
          className="list-group-item"
          key={`clinicalTrials_${item.guid}`}
        >
          <div className="row g-0">
            <div className="col-auto">
              <img src={clinical_trials_icon} alt="" />
            </div>
            <div className="col">
              <h3 className="card-title"><a
                href={`${item.url}`}
                target="_blank"
                className="section-item-title item-blue-title m-0"
                rel="noreferrer"
              >{item.title}</a></h3>
              <p className="card-text">
                <span className=""><img src={small_marker} alt="" /></span>
                <small className="text-muted"><Link
                  to={`/institution/${profileIdLink}`}
                  className=""
                >{" "}{institutionName}</Link></small></p>
              <p><small className="text-muted">{"Issued On: "}{issueDate}</small></p>
            </div>

            {/** 
            <div className="flex-grow-1">
              <div className="d-flex">
                <div className="prize-icon badgeshape">
                  <img src={medicalHistoryIcon} width="16" alt="" />
                </div>
                <div className="ml-2">
                  <a
                    href={`${item.url}`}
                    target="_blank"
                    className="section-item-title item-blue-title m-0"
                    rel="noreferrer"
                  >
                    {item.title}
                  </a>
                  <div className="section-item-shortdes mt-2">
                    <Link
                      to={`/institution/${profileIdLink}`}
                      className="mb-2 color-accent"
                    >
                      {institutionName}
                    </Link>
                  </div>
                </div>
              </div>
            </div>

*/}

            <div className="col-auto d-flex align-items-start">
              {(isAdmin ||
                (isLoggedinUser &&
                  currentUser.status === UserStatusConstants.APPROVED)) && (
                  <div className="delete-action">
                    <Button
                      
                      color="link"
                      className="visible-hover py-0 nex-ct-clinical-trials-edit-btn"
                      onClick={() => handleEditItem(item)}
                    >

                      <img src={edit_blue_icon} alt="" />
                    </Button>
                    <Button
                      color="link"
                      className="visible-hover delete py-0 nex-ct-clinical-trials-del-btn"
                      onClick={() => handleRemoveItem(item)}
                    >
                      <img src={del_red_icon} alt="" />
                    </Button>
                  </div>
                )}
            </div>
          </div>
        </li>
      );
    });
  }
  return "";
};

const ClinicalTrials = (props) => {
  const [pannel_on, setPannel_on] = useState(true);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const eventName = useSelector((state) => state.profileComplete.eventName);
  const dispatch = useDispatch();
  const { guid } = props.practitioner;
  const { alert, isLoggedinUser, isAdmin, setShowTrialsOnTabScroll } = props;
  const [clinicalTrials, setClinicalTrials] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [isOpen, setModal] = useState(false);
  const toggle = () => setModal(!isOpen);
  const [isOpenAlert, setAlertModal] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(updateProfileCompletness(10, !!clinicalTrials.length));

    if (clinicalTrials.length > 0 || isLoggedinUser || isAdmin) {
      setShowTrialsOnTabScroll(true);
    }else{
      setShowTrialsOnTabScroll(false);
    }

  }, [clinicalTrials]);

  useEffect(() => {
    if (eventName === "ADD_CLINICAL") {
      handleEditItem(initialValue);
      dispatch(profileAddEvent(null));
    }
  }, [eventName]);

  const toggleAlert = () => {
    setLoading(false);
    setAlertModal(!isOpenAlert);
  };

  useEffect(() => {
    getData();
  }, [guid]);

  const handleEditItem = (selectedItem) => {
    setSelectedItem(selectedItem);
    toggle();
  };

  const handleRemoveItem = (selectedItem) => {
    setSelectedItem(selectedItem);
    toggleAlert();
  };

  const handleClickOnYes = async () => {
    setLoading(true);
    try {
      const request = PractitionerService.deleteClinicalTrial(
        guid,
        selectedItem.guid
      );
      request.then(
        (response) => {
          const { status } = response;
          if (status === 204) {
            setTimeout(() => {
              showSuccessMessage("Clinical trial deleted successfully");
              getData();
              setLoading(false);
              toggleAlert();
            }, 1000);
          } else {
            showErrorMessage("Error while deleting clinical trial");
            setLoading(false);
            toggleAlert();
          }
        },
        (e) => {
          setLoading(false);
          console.log("error", e.message);
          let errmsg = e.message;
          if (e.response) {
            errmsg =
              e.response.data && !!e.response.data.message
                ? e.response.data.message
                : errmsg;
          }
          showErrorMessage(errmsg);
        }
      );
    } catch (e) {
      showErrorMessage(e.message);
      setLoading(false);
      toggleAlert();
    }
  };

  const getData = async () => {
    const request = PractitionerService.getClinicalTrial(guid);
    request.then((response) => {
      let { data } = response;
      if (data && data.length > 0) {
        setClinicalTrials(data);
      } else {
        setClinicalTrials([]);
      }
    });
  };

  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );

  if (
    getEmptySectionShowHide(
      clinicalTrials ?? [],
      isAdmin,
      currentUser,
      globalProfileInfo,
      guid
    )
  )
    return null;

  return (
    <>
      <Card className={`card nexogic-card-color-header ${pannel_on ? 'expanded' : ''}`} id="clinicalTrialsSection">
        <div className="card-header"><h2>Clinical Trials</h2>



          {(isAdmin ||
            (isLoggedinUser &&
              currentUser.status === UserStatusConstants.APPROVED)) && (
              <Button
                id="nex-ct-clinical-trials-add-btn"
                color="link"
                className="add-button"
                onClick={() => handleEditItem(initialValue)}
              >
                <img src={plusIcon} alt="" />
              </Button>
            )}


          <button className="collapse-btn" type="button" onClick={() => setPannel_on(!pannel_on)}>
            <img src={pannel_arrow} alt="" />
          </button>
        </div>
        <div className={`collapse-div`}>
          <CardBody className={
            clinicalTrials && clinicalTrials.length > 0
              ? ""
              : "align-items-center "
          }>
            {clinicalTrials && clinicalTrials.length
              ? ""
              : (isLoggedinUser || isAdmin) &&
              currentUser.status === UserStatusConstants.APPROVED && (
                <Button
                  id="nex-ct-clinical-trials-add-btn2"
                  className="readmore"
                  color="link"
                  onClick={() => handleEditItem(initialValue)}
                >
                  {"Add your clinical trials"}
                </Button>
              )}
            {/** 
          <Row
            className={
              clinicalTrials && clinicalTrials.length > 0
                ? "mb-3"
                : "align-items-center mb-3"
            }
          >
            <Col>
              <h3 className="section-title m-0 mb-3">Clinical Trials</h3>
              {clinicalTrials && clinicalTrials.length
                ? ""
                : (isLoggedinUser || isAdmin) &&
                  currentUser.status === UserStatusConstants.APPROVED && (
                    <div className="mt-3">
                      <Row className="align-items-center">
                        <Col className="add-infomation-part">
                          <Button
                            className="add-infomation p-0"
                            color="link"
                            onClick={() => handleEditItem(initialValue)}
                          >
                            {"Add your clinical trials"}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  )}
            </Col>
            {(isAdmin ||
              (isLoggedinUser &&
                currentUser.status === UserStatusConstants.APPROVED)) && (
              <Col xs="auto">
                <Button
                  color="link"
                  className="round-icon-btn rounded-circle"
                  onClick={() => handleEditItem(initialValue)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </Col>
            )}
          </Row>
*/}
            <ul className="list-group list-group-flush" id="nex-ct-clinical-trials-lists">
              <RenderClinicalTrials
                clinicalTrials={clinicalTrials}
                handleEditItem={handleEditItem}
                handleRemoveItem={handleRemoveItem}
                isLoggedinUser={isLoggedinUser}
                isAdmin={isAdmin}
              />
            </ul>
            {clinicalTrials.length < 1 && 
            <NoData>No Data</NoData>
            }
            <RenderClinicalTrialsModal
              selectedItem={selectedItem}
              toggle={toggle}
              isOpen={isOpen}
              practitionerId={guid}
              getData={getData}
              isAdmin={isAdmin}
            />
            <AlertDialog
              toggle={toggleAlert}
              isOpen={isOpenAlert}
              isLoading={isLoading}
              handleClickOnYes={handleClickOnYes}
              yesLabel={"Yes"}
              noLabel={"No"}
            />
          </CardBody>
        </div>
      </Card>
    </>
  );
};

export default withAlert()(ClinicalTrials);
