import React, { useContext, useCallback, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import SectionBlank from 'pages/UIDashboard/Components/Sections/SectionBlank';
import { CardContext } from 'pages/UIDashboard/ThemeDesign';
import { useDrop } from 'react-dnd';
import { Button, Card, CardBody, CardFooter, CardHeader, Col, DropdownItem, DropdownMenu, Modal, Row } from 'reactstrap';
import itemType from '../../ThemeContents/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import './style.scss';
import ChooseColorTheme from './ChooseColorTheme';
import { AboutUI } from 'pages/Institutions/components/InstitutionsDetails/components/AboutUI';
import { makeFullNameWithAddressWhenAddressIsNested } from "utils/Utils";
import { InstitutionServices } from 'utils/InstitutionServices';
import { useDispatch } from 'react-redux';
import { setGlobalInstituteProfile } from 'redux-helpers/ActionCreator';
import GalleryUI from 'pages/Institutions/components/InstitutionsDetails/components/GalleryUI';
import HospitalTimingsUI from 'pages/Institutions/components/InstitutionsDetails/components/HospitalTimingsUI';
import InstitutionsAwardsUI from 'pages/Institutions/components/InstitutionsDetails/components/InstitutionsAwardsUI';
import PractitionersUI from 'pages/Institutions/components/InstitutionsDetails/components/PractitionersUI';
import { ServicesUI } from 'pages/Institutions/components/InstitutionsDetails/components/ServicesUI';
import SpecialitiesUI from 'pages/Institutions/components/InstitutionsDetails/components/SpecialitiesUI';
import { AmenityUI } from 'pages/Institutions/components/InstitutionsDetails/components/AmenityUI';
import BranchesUI from 'pages/Institutions/components/InstitutionsDetails/components/BranchesUI';
import { getLoggedInUserInstitutions } from 'utils/UserHelper';
import JobWidgetUI from 'pages/UIDashboard/Components/CustomThemeDesign/CustomForms/JobWidgetUI';
import AdsComponent from './AdsComponent';

const Droppable = ({ id, onDrop, setDropId, hasItem, children }) => {
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: itemType.CARD,
        drop: (item, monitor, event) => {
            setDropId(monitor.targetId);
            onDrop(item, monitor, event)
        },
        collect: monitor => ({
            isOver: monitor.isOver(),
        }),
        canDrop: (item, monitor) => {
            if (hasItem.length === 0) {
                return true
            }
            const hasFlag = hasItem.filter(item => item === monitor.targetId);
            if (hasFlag.length === 0) {
                return true
            }
        },
    });

    return (
        <>
            <div ref={drop} style={{ backgroundColor: isOver ? 'lightblue' : 'lightgray', width: "100%", minHeight: "80px", padding: "0" }}>
                {children}
            </div>
        </>
    );
}

const ThemeCustomTemplate = (props) => {
    const { themeDetails, setThemePColors, setDraggableItems, setDraggableWidgets, allDraggable, droppedItem, layouttype, setPageContent, setInstituteDetails, oBranchFlag, otherBranches, practitioners, draggableWidgets, draggableItems, modalIndex } = props;

    const [themeColors, setThemeColors] = useState(themeDetails);
    const [sections, setSections] = useState([]);
    const [dropIds, setDropIds] = useState([]);
    const [dropID, setDropId] = useState('');
    const [hasItem, setHasItem] = useState([]);

    const dispatch = useDispatch();
    const { markAsDragged } = useContext(CardContext);
    const [themeBgColor, setThemeBgColor] = useState(themeDetails[0]);
    const [sectionBG, setSectionBG] = useState(themeDetails[1]);
    const [sectionHeaderBg, setSectionHeaderBg] = useState(themeDetails[3]);
    const [sectionHeaderFontColor, setSectionHeaderFontColor] = useState(themeDetails[2]);
    const [sectionHeadingFontColor, setSectionHeadingFontColor] = useState(themeDetails[4]);
    const [sectionFontColor, setSectionFontColor] = useState(themeDetails[5]);
    const [selectedOption, setSelectedOption] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isCurrentUserInstitutionAdmin, setIsCurrentUserInstitutionAdmin] = useState(false);
    const [institution, setInstitution] = useState(props.institution);
    const history = useHistory();
    const loggedInUserInstitutions = getLoggedInUserInstitutions();
    const [draggablePreListItems, setDraggablePreListItems] = useState(0);

    

    const getInstitutionProfileInfo = useCallback(
        (instguid) => {
            setIsLoading(true);
            try {
                InstitutionServices.getInstitutionById(instguid).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        setIsLoading(true);
                        dispatch(setGlobalInstituteProfile(response.data));
                        setInstitution(response.data);
                        setInstituteDetails(response.data);

                        if (response.data?.parent) {
                            InstitutionServices.getInstitutionById(response.data?.parent)
                                .then((response1) => {
                                    const { status } = response1;
                                    if (status === 200) {
                                        let d = {
                                            "guid": response1?.data?.guid,
                                            "institutionName": makeFullNameWithAddressWhenAddressIsNested(response1?.data)
                                        }
                                        response.data.parent = d;
                                    }
                                    else {
                                        history.push(`/404`);
                                        console.log("Something Went Wrong...");
                                    }
                                })
                        }
                        checkIsCurrentUserInstitutionAdmin(response.data?.guid);
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                        history.push(`/404`);
                        setInstitution(null);
                    }
                });
            } catch (e) {
                setIsLoading(false);
                console.log("Error while getting Institution getProfile: ", e);
            }
        },
        [dispatch]
    );

    const checkIsCurrentUserInstitutionAdmin = (instId) => {
        if (loggedInUserInstitutions && loggedInUserInstitutions.includes(instId)) {
            setIsCurrentUserInstitutionAdmin(true);
        }
    }

    const handleDrop1 = (menuItems, monitor) => {
        const dropAreaId = monitor.targetId;
        const dropIdIndex = parseInt(dropAreaId.substring(1), 10) - (allDraggable - 1);
        setDropIds((prevDropIds) => {
            const valDropid = prevDropIds.map((drop) => {
                if (drop.id === dropIdIndex) {
                    return { ...drop, droppedItem: [menuItems.id, menuItems.text, menuItems.content, dropAreaId] };
                }
                return drop;
            });
            droppedItem(valDropid[dropIdIndex - 1].droppedItem);
            return valDropid;
        });
        setHasItem((preHasItem) => [...preHasItem, dropAreaId]);
    };

    const handleRemoveFromDroppedIds = (dId, menuItem) => {
        const dropIdIndex = parseInt(menuItem[3].substring(1), 10) - (allDraggable - 1); //dropIdIndex - (Total Draggable COmponents-1)
        setDropIds(prevDropIds =>
            prevDropIds.map(drop => {
                if (drop.id === dropIdIndex) {
                    return { ...drop, droppedItem: [] };
                }
                return drop;
            })
        );
        const updatedHasItems = hasItem.filter(item => item !== menuItem[3]);
        setHasItem(updatedHasItems);
        if (menuItem[2].length < 2)
            setDraggableItems(items => [...items, { id: menuItem[0], text: menuItem[1], content: menuItem[2] }]);
        if (menuItem[2].length > 1)
            setDraggableWidgets(items => [...items, { id: menuItem[0], text: menuItem[1], content: menuItem[2] }]);
    }

    useEffect(() => {
        selectThemeContainerType(allDraggable);
    }, [allDraggable, modalIndex])

    useEffect(() => {
        setThemeBgColor(themeDetails[0]);
        setSectionBG(themeDetails[1]);
        setSectionHeaderBg(themeDetails[3]);
        setSectionHeaderFontColor(themeDetails[2]);
        setSectionHeadingFontColor(themeDetails[4]);
        setSectionFontColor(themeDetails[5]);
        setThemeColors(themeDetails)
    }, [themeDetails, themeColors])

    useEffect(() => {
        setPageContent(prePageContent => ({ ...prePageContent, inst: institution.guid, themeColor: themeDetails, sectionContent: dropIds, themeContainerType: selectedOption, theme: "custom" }))
    }, [dropIds, sections, themeDetails, selectedOption])

    const selectThemeContainerType = (dropAreas) => {
        setSections([]); setDropIds([]);
        setDraggablePreListItems(dropAreas);
        let draggableItem = dropAreas;
        setSelectedOption(layouttype);
        if (layouttype === 'layoutone') {
            for (let i = 1; draggablePreListItems < 1 ? i < draggableItem : i <= draggableItem; i++) {
                addThemeLayout('btnCol01', i);
            }
            for (let i = draggableItem; i < draggableItem * 2; i++) {
                addThemeLayout('btnCol02', i);
            }
        }
        if (layouttype === 'layouttwo') {
            for (let i = 1; i <= draggableItem; i++) {
                addThemeLayout('btnCol01', i);
            }
        }
        if (layouttype === 'layoutthree') {
            for (let i = 1; i <= draggableItem; i++) {
                addThemeLayout('btnCol01', i);
            }
            for (let i = draggableItem + 1; draggablePreListItems < 1 ? i < draggableItem * 2 : i <= draggableItem * 2; i++) {
                addThemeLayout('btnCol02', i);
            }
        }
    }

    const addThemeLayout = (sectiontype, secId) => {
        const sectionId = secId;
        const newSection = {
            id: sectionId,
            dropperArea: sectiontype,
        };
        setSections(prevSections => [...prevSections, newSection]);
        setDropIds(prevDropIds => [...prevDropIds, { id: sectionId, targetArea: sectiontype, droppedItem: [] }]);
    }

    return (
        <>
            <Card className="micro-site-wrap micro-site-theme-v1 p-0 border-0" style={{ backgroundColor: themeDetails[0] }}>
                
                <div className='color-card-box'>
                    <ChooseColorTheme themeColors={themeDetails} setThemePColors={setThemePColors} setThemeBgColor={setThemeBgColor} setSectionBG={setSectionBG} setSectionHeaderBg={setSectionHeaderBg} setSectionHeaderFontColor={setSectionHeaderFontColor} setSectionHeadingFontColor={setSectionHeadingFontColor} setSectionFontColor={setSectionFontColor} />
                </div>
                <CardBody className="micro-main-content-wrap">
                    <Row>
                        <Col lg={12} className="p-0">
                            <SectionBlank themeDetails={themeDetails} institution={institution} isEditable={true} isAdmin={true} />
                        </Col>
                    </Row>
                    {selectedOption === 'layoutone' ?
                        <Row>
                            <Col lg={4} id="winl01">
                                <Row>
                                    <Col lg={12}>
                                        {sections.filter(section => section.dropperArea === "btnCol01").map((section, index) => (
                                            <Row key={index}>
                                                <Col lg={12} className="p-3" style={{ minHeight: "60px" }}>
                                                    <Droppable id={`droppable-${section.id}`} onDrop={(id, monitor) => handleDrop1(id, monitor)} setDropId={setDropId} hasItem={hasItem} >
                                                        {dropIds[section.id - 1]?.droppedItem.length > 0 ? <div className='delete-btn-round'> <FontAwesomeIcon icon={faTrashAlt} className='btn-delete' onClick={() => handleRemoveFromDroppedIds(dropID, dropIds[section.id - 1]?.droppedItem)} /></div> : ''}
                                                        {dropIds[section.id - 1]?.droppedItem.length > 0
                                                            ? dropIds[section.id - 1].droppedItem[2][0] === "AboutUI" ? <AboutUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                dropIds[section.id - 1].droppedItem[2][0] === "GalleryUI" ? <GalleryUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                    dropIds[section.id - 1].droppedItem[2][0] === "BusinessHrUI" ? <HospitalTimingsUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                        dropIds[section.id - 1].droppedItem[2][0] === "SpecialitiesUI" ? <SpecialitiesUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                            dropIds[section.id - 1].droppedItem[2][0] === "ServicesUI" ? <ServicesUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                                dropIds[section.id - 1].droppedItem[2][0] === "AmenityUI" ? <AmenityUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                                    dropIds[section.id - 1].droppedItem[2][0] === "BranchesUI" ? <BranchesUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} oBranchFlag={oBranchFlag} otherBranches={otherBranches} winwidth={document.getElementById("winl01").clientWidth} /> :
                                                                                        dropIds[section.id - 1].droppedItem[2][0] === "AwardUI" ? <InstitutionsAwardsUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                                            dropIds[section.id - 1].droppedItem[2][0] === "DoctorsUI" ? <PractitionersUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} practitioners={practitioners} winwidth={document.getElementById("winl01").clientWidth} /> :
                                                                                                dropIds[section.id - 1].droppedItem[2][0] === "JobsUI" ? <JobWidgetUI widgetTitle="Jobs" {...props} /> :
                                                                                                    dropIds[section.id - 1].droppedItem[2][0] === "AdsUI" ? <AdsComponent imgdet={dropIds[section.id - 1].droppedItem[2][1]} institution={institution} /> :
                                                                                                        '' : <p className='droparea'>Drop Here</p>}
                                                    </Droppable>
                                                </Col>
                                            </Row>
                                        ))}
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={8} id="winl02">
                                <Row>
                                    <Col lg={12}>
                                        {sections.filter(section => section.dropperArea === "btnCol02").map((section, index) => (
                                            <Row key={index}>
                                                <Col lg={12} className="text-center p-3" style={{ minHeight: "60px" }}>
                                                    <Droppable id={`droppable-${section.id}`} onDrop={(id, monitor) => handleDrop1(id, monitor)} setDropId={setDropId} hasItem={hasItem}>
                                                        {dropIds[section.id - 1]?.droppedItem.length > 0 ? <div className='delete-btn-round'> <FontAwesomeIcon icon={faTrashAlt} className='btn-delete' onClick={() => handleRemoveFromDroppedIds(dropID, dropIds[section.id - 1]?.droppedItem)} /></div> : ''}
                                                        {dropIds[section.id - 1]?.droppedItem.length > 0
                                                            ? dropIds[section.id - 1].droppedItem[2][0] === "AboutUI" ? <AboutUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                dropIds[section.id - 1].droppedItem[2][0] === "GalleryUI" ? <GalleryUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                    dropIds[section.id - 1].droppedItem[2][0] === "BusinessHrUI" ? <HospitalTimingsUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                        dropIds[section.id - 1].droppedItem[2][0] === "SpecialitiesUI" ? <SpecialitiesUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                            dropIds[section.id - 1].droppedItem[2][0] === "ServicesUI" ? <ServicesUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                                dropIds[section.id - 1].droppedItem[2][0] === "AmenityUI" ? <AmenityUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                                    dropIds[section.id - 1].droppedItem[2][0] === "BranchesUI" ? <BranchesUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} winwidth={document.getElementById("winl02").clientWidth} /> :
                                                                                        dropIds[section.id - 1].droppedItem[2][0] === "AwardUI" ? <InstitutionsAwardsUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                                            dropIds[section.id - 1].droppedItem[2][0] === "DoctorsUI" ? <PractitionersUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} winwidth={document.getElementById("winl02").clientWidth} /> :
                                                                                                dropIds[section.id - 1].droppedItem[2][0] === "JobsUI" ? <JobWidgetUI widgetTitle="Jobs" {...props} /> :
                                                                                                    dropIds[section.id - 1].droppedItem[2][0] === "AdsUI" ? <AdsComponent imgdet={dropIds[section.id - 1].droppedItem[2][1]} institution={institution} /> :
                                                                                                        '' : <p className='droparea'>Drop Here</p>}
                                                    </Droppable>
                                                </Col>
                                            </Row>
                                        ))}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        : selectedOption === 'layouttwo' ?
                            <Row>
                                <Col lg={12}>
                                    <Row>
                                        <Col lg={12} id="winl03">
                                            {sections.map((section, index) => (
                                                <Row key={index}>
                                                    <Col lg={12} className="mb-3 text-center p-3" style={{ minHeight: "60px" }}>
                                                        <Droppable id={`droppable-${section.id}`} onDrop={(id, monitor) => handleDrop1(id, monitor)} setDropId={setDropId} hasItem={hasItem}>
                                                            <div id={`sectionid-${section.id - 1}`}>
                                                                {dropIds[section.id - 1]?.droppedItem.length > 0 ? <div className='delete-btn-round'> <FontAwesomeIcon icon={faTrashAlt} className='btn-delete' onClick={() => handleRemoveFromDroppedIds(dropID, dropIds[section.id - 1]?.droppedItem)} /></div> : ''}
                                                                {dropIds[section.id - 1]?.droppedItem.length > 0
                                                                    ? dropIds[section.id - 1].droppedItem[2][0] === "AboutUI" ? <AboutUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                        dropIds[section.id - 1].droppedItem[2][0] === "GalleryUI" ? <GalleryUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                            dropIds[section.id - 1].droppedItem[2][0] === "BusinessHrUI" ? <HospitalTimingsUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                                dropIds[section.id - 1].droppedItem[2][0] === "SpecialitiesUI" ? <SpecialitiesUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                                    dropIds[section.id - 1].droppedItem[2][0] === "ServicesUI" ? <ServicesUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                                        dropIds[section.id - 1].droppedItem[2][0] === "AmenityUI" ? <AmenityUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                                            dropIds[section.id - 1].droppedItem[2][0] === "BranchesUI" ? <BranchesUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} winwidth={document.getElementById("winl03").clientWidth} /> :
                                                                                                dropIds[section.id - 1].droppedItem[2][0] === "AwardUI" ? <InstitutionsAwardsUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                                                    dropIds[section.id - 1].droppedItem[2][0] === "DoctorsUI" ? <PractitionersUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} practitioners={practitioners} winwidth={document.getElementById("winl03").clientWidth} /> :
                                                                                                        dropIds[section.id - 1].droppedItem[2][0] === "JobsUI" ? <JobWidgetUI widgetTitle="Jobs" {...props} /> :
                                                                                                            dropIds[section.id - 1].droppedItem[2][0] === "AdsUI" ?
                                                                                                                <AdsComponent imgdet={dropIds[section.id - 1].droppedItem[2][1]} institution={institution} /> :
                                                                                                                '' : <p className='droparea'>Drop Here </p>}
                                                            </div>
                                                        </Droppable>
                                                    </Col>
                                                </Row>
                                            ))}
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            : selectedOption === 'layoutthree' ?
                                <Row>
                                    <Col lg={8} id="winl04">
                                        <Row>
                                            <Col lg={12}>
                                                {sections.filter(section => section.dropperArea === "btnCol01").map((section, index) => (
                                                    <Row key={index}>
                                                        <Col lg={12} className="mb-3 text-center p-3" style={{ minHeight: "60px" }}>
                                                            <Droppable id={`droppable-${section.id}`} onDrop={(id, monitor) => handleDrop1(id, monitor)} setDropId={setDropId} hasItem={hasItem}>
                                                                {dropIds[section.id - 1]?.droppedItem.length > 0 ? <div className='delete-btn-round'> <FontAwesomeIcon icon={faTrashAlt} className='btn-delete' onClick={() => handleRemoveFromDroppedIds(dropID, dropIds[section.id - 1]?.droppedItem)} /></div> : ''}
                                                                {dropIds[section.id - 1]?.droppedItem.length > 0
                                                                    ? dropIds[section.id - 1].droppedItem[2][0] === "AboutUI" ? <AboutUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                        dropIds[section.id - 1].droppedItem[2][0] === "GalleryUI" ? <GalleryUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                            dropIds[section.id - 1].droppedItem[2][0] === "BusinessHrUI" ? <HospitalTimingsUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                                dropIds[section.id - 1].droppedItem[2][0] === "SpecialitiesUI" ? <SpecialitiesUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                                    dropIds[section.id - 1].droppedItem[2][0] === "ServicesUI" ? <ServicesUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                                        dropIds[section.id - 1].droppedItem[2][0] === "AmenityUI" ? <AmenityUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                                            dropIds[section.id - 1].droppedItem[2][0] === "BranchesUI" ? <BranchesUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} winwidth={document.getElementById("winl04").clientWidth} /> :
                                                                                                dropIds[section.id - 1].droppedItem[2][0] === "AwardUI" ? <InstitutionsAwardsUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                                                    dropIds[section.id - 1].droppedItem[2][0] === "DoctorsUI" ? <PractitionersUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} practitioners={practitioners} winwidth={document.getElementById("winl04").clientWidth} /> :
                                                                                                        dropIds[section.id - 1].droppedItem[2][0] === "JobsUI" ? <JobWidgetUI widgetTitle="Jobs" {...props} /> :
                                                                                                            dropIds[section.id - 1].droppedItem[2][0] === "AdsUI" ? <AdsComponent imgdet={dropIds[section.id - 1].droppedItem[2][1]} institution={institution} /> :
                                                                                                                '' : <p className='droparea'>Drop Here</p>}
                                                            </Droppable>
                                                        </Col>
                                                    </Row>
                                                ))}
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg={4} id="winl05">
                                        <Row>
                                            <Col lg={12}>
                                                {sections.filter(section => section.dropperArea === "btnCol02").map((section, index) => (
                                                    <Row key={index}>
                                                        <Col lg={12} className="mb-3 text-center p-3" style={{ minHeight: "60px" }}>
                                                            <Droppable id={`droppable-${section.id}`} onDrop={(id, monitor) => handleDrop1(id, monitor)} setDropId={setDropId} hasItem={hasItem}>
                                                                {dropIds[section.id - 1]?.droppedItem.length > 0 ? <div className='delete-btn-round'> <FontAwesomeIcon icon={faTrashAlt} className='btn-delete' onClick={() => handleRemoveFromDroppedIds(dropID, dropIds[section.id - 1]?.droppedItem)} /></div> : ''}
                                                                {dropIds[section.id - 1]?.droppedItem.length > 0
                                                                    ? dropIds[section.id - 1].droppedItem[2][0] === "AboutUI" ? <AboutUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                        dropIds[section.id - 1].droppedItem[2][0] === "GalleryUI" ? <GalleryUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                            dropIds[section.id - 1].droppedItem[2][0] === "BusinessHrUI" ? <HospitalTimingsUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                                dropIds[section.id - 1].droppedItem[2][0] === "SpecialitiesUI" ? <SpecialitiesUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                                    dropIds[section.id - 1].droppedItem[2][0] === "ServicesUI" ? <ServicesUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                                        dropIds[section.id - 1].droppedItem[2][0] === "AmenityUI" ? <AmenityUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                                            dropIds[section.id - 1].droppedItem[2][0] === "BranchesUI" ? <BranchesUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} winwidth={document.getElementById("winl05").clientWidth} /> :
                                                                                                dropIds[section.id - 1].droppedItem[2][0] === "AwardUI" ? <InstitutionsAwardsUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} /> :
                                                                                                    dropIds[section.id - 1].droppedItem[2][0] === "DoctorsUI" ? <PractitionersUI {...props} institution={institution} getInstitutionProfile={getInstitutionProfileInfo} themeDetails={themeColors} previewFlag={false} practitioners={practitioners} winwidth={document.getElementById("winl05").clientWidth} /> :
                                                                                                        dropIds[section.id - 1].droppedItem[2][0] === "JobsUI" ? <JobWidgetUI widgetTitle="Jobs" {...props} /> :
                                                                                                            dropIds[section.id - 1].droppedItem[2][0] === "AdsUI" ? <AdsComponent imgdet={dropIds[section.id - 1].droppedItem[2][1]} institution={institution} /> :
                                                                                                                '' : <p className='droparea'>Drop Here</p>}
                                                            </Droppable>
                                                        </Col>
                                                    </Row>
                                                ))}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                :
                                <Row>
                                    <Col lg={12} className="mb-3 text-center p-3" style={{ minHeight: "60px" }}></Col>
                                </Row>
                    }

                </CardBody>
            </Card>
        </>
    )
}

export default ThemeCustomTemplate