import locationIcon from "../../../../assets/images/svgs/location-orange-icon.svg";
import dateCalenderIcon from "../../../../assets/images/svgs/calender-green-icon.svg";
import graduateSmallIcon from "../../../../assets/images/svgs/graduate-small-icon.svg";
import { faAddressCard, faEllipsisH, faListAlt, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Badge, Button, Card, CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import comment_icon from "../../../../assets/images/review-comment-icon.png";
import review_active_icon from "../../../../assets/images/review_active_icon.png";
import share_icon from "../../../../assets/images/share-icon.png";
import { createImageFromInitials, getRandomColor } from "utils/Utils";
import { encrypt } from "utils/EncryptDecrypt";
import "./jobsFeed.scss";
import { JOB_PositionTypesList } from "utils/Constants";
import DisplayCreatedTime from "../DisplayCreatedTime";
import { isInstitutionAdmin, isPractitioner } from "utils/UserHelper";
import ApplyJobs from "../ApplyJobs";
import { useState } from "react";
import { TalentService } from "utils/TalentService";
import { useEffect } from "react";
import { InstitutionServices } from "utils/InstitutionServices";
import Description from "./Description";

const FeedItem = (props) => {
  const {
    id,
    creatorProfile,
    institutionDetails,
    title,
    linkToApply,
    description,
    editJobItem,
    createdOn,
    city,
    profileId,
    status,
    location,
    primarySpeciality,
    opportunity,
    position,
    maxExperience,
    minExperience
  } = props;
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };
  const toggleForm = () => setModal(!modal);
  const [applicantStatus, setApplicantStatus] = useState(null);
  const [jobInfo, setJobInfo] = useState(null);

  function getPosition(positionType) {
    if (positionType === "FULL_TIME")
      return "Full Time";
    else if (positionType === "PART_TIME")
      return "Part Time";
    else if (positionType === "FLEXIBLE_TIME")
      return "Flexible Time";
    else
      return "Consultant"
  }

  const getApplicantStatus = (jobId) => {
    // setLoading(true);
    TalentService.getJobApplicantStatus(jobId)
      .then((response) => {
        let { data } = response;
        if (response.status === 200 || response.status === 201) {
          setApplicantStatus(data?.status);
          // setLoading(false);
        }
      })
      .catch((error) => {
        // setLoading(false);
        console.log("Error while getting connection status: ", error);
      });

  };

  const getJob = (jobId) => {
    if (jobId) {
      (async () => {
        TalentService.getJob(jobId)
          .then((response) => {
            let { data } = response;
            if (response.status === 200 || response.status === 201) {
              if (isPractitioner())
                getApplicantStatus(data?.id);
              setJobInfo(data);
            }
          })
          .catch((error) => {
            // setLoading(false);
            console.log("Error while getting connection status: ", error);
          });
      })();
    }
  };

  useEffect(() => {
    getJob(id);
  }, [id]);

  const showActionButtonOnJob = (jobInfo) => {
    let possibleApplicationStatus = ["APPLIED", "SHORTLISTED", "INTERVIEW", "OFFERED", "ACCEPTED", "ON_HOLD", "HIRED", "REJECTED", "CLOSED"];
    if (isPractitioner()) {
      if (jobInfo?.creatorProfile === null) {
        return (
          <>
            {
              possibleApplicationStatus.includes(applicantStatus)
                ?
                <div className="action job-apply-btns">
                  <span className="btn applied-btn">
                    <span className="icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                      <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                      <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                    </svg></span>
                    {"Already Applied"}</span>
                </div>
                :
                <div className="action job-apply-btns">
                  <span className="btn nexogic_primary_button nex-apply-btn" style={{ color: "white" }}
                    onClick={() => {
                      // Check the apply mode
                      if (jobInfo?.applyMode === 'EMAIL') {
                        // Handle the email application logic
                        toggleForm();
                      } else {
                        // Redirect to the specified link for other modes
                        window.open(jobInfo?.linkToApply, "_blank");
                      }
                    }}

                  >Apply</span>
                </div>
            }
          </>
        );
      }

      else {
        if (jobInfo?.creatorProfile?.profileGuid !== currentUser?.guid) {
          return (
            <>
              {
                possibleApplicationStatus.includes(applicantStatus)
                  ?
                  <div className="action job-apply-btns">
                    <span className="btn applied-btn">
                      <span className="icon"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z" />
                        <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z" />
                      </svg></span>
                      {"Already Applied"}</span>
                  </div>
                  :
                  <div className="action job-apply-btns">
                    <span className="btn nexogic_primary_button nex-apply-btn" style={{ color: "white" }}
                      onClick={() => {
                        // Check the apply mode
                        if (jobInfo?.applyMode === 'EMAIL') {
                          // Handle the email application logic
                          toggleForm();
                        } else {
                          // Redirect to the specified link for other modes
                          window.open(jobInfo?.linkToApply, "_blank");
                        }
                      }}
                    >Apply</span>
                  </div>
              }
            </>
          );
        }
      }
    }
  }

  const ShowExperience = ({ min, max }) => {
    if ((!min && !max) || min === '0' && max === '0')
      return (
        <span>Any Experience</span>
      )
    else if (min && !max) {
      return (
        <span>{min || 0} Years</span>
      );
    }
    else if (!min && max) {
      return (
        <span>{max || 0} Years</span>
      );
    }
    else {
      return (
        <span>{min || 0} -{" "}
          {max || 0} Years</span>
      );
    }
  }

  return (
    <>
      {/*----------------------------------------------------------------------------------------------------------*/}
      <Card className="nexogic-job-list-card card">
        <div className="card-head">
          <div className="card-thumb">
            {institutionDetails && (
              <Link to={`/institution/${institutionDetails.profileId}`}>
                {institutionDetails.avatarId ? (
                  <img
                    src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${institutionDetails.guid}/${institutionDetails.avatarId}`}
                    alt="institution profile pic"
                    className="card-img"
                  />
                ) : (
                  <img
                    src={createImageFromInitials(
                      50,
                      `${institutionDetails?.name}`,
                      getRandomColor(
                        encrypt(institutionDetails?.guid).toString()
                      )
                    )}
                    alt="institution profile pic"
                    className="card-img"
                  />
                )}
              </Link>
            )}
          </div>
          <div className="info">
            <h3 className="card-title">
              <Link
                to={`/jobs/${encodeURIComponent(profileId)}`}
                onClick={() => window.scrollTo(0, 0)}
                className="title-txt"
              >
                {title}

              </Link>
              <Badge className="ml-2 mr-2" color="primary" pill>
                {opportunity || "Job"}
              </Badge>
            </h3>
            <p className="card-text" style={{ fontWeight: "600" }}>{institutionDetails?.name}</p>
            <p className="card-text">{primarySpeciality}</p>
            <p className="card-text">Created <DisplayCreatedTime createdOn={createdOn} /></p>
          </div>

          {jobInfo && showActionButtonOnJob(jobInfo)}

        </div>
        <CardBody>
          <div className="description"><Description description={description} /> </div>
          <div className="nexogic-meta-description">
            <p><span className="icon"><svg
              width={20}
              height={22}
              viewBox="0 0 20 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 8V17C1 19.2091 2.79086 21 5 21H15C17.2091 21 19 19.2091 19 17V8M1 8V6.5C1 4.29086 2.79086 2.5 5 2.5H15C17.2091 2.5 19 4.29086 19 6.5V8M1 8H19M14 1V4M6 1V4"
                stroke="#5EA9C6"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg></span> {getPosition(position)}</p>
            <p><span className="icon">
              <svg
                width={22}
                height={22}
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11 8V10.382C11 10.7607 11.214 11.107 11.5528 11.2764L13 12M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11ZM18 11C18 14.866 14.866 18 11 18C7.13401 18 4 14.866 4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11Z"
                  stroke="#5EA9C6"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </span> <ShowExperience min={jobInfo?.minExperience} max={jobInfo?.maxExperience} /></p>

            <p><span className="icon"><svg
              width={20}
              height={22}
              viewBox="0 0 20 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 9.88889C19 14.7981 13.375 21 10 21C6.625 21 1 14.7981 1 9.88889C1 4.97969 5.02944 1 10 1C14.9706 1 19 4.97969 19 9.88889Z"
                stroke="#5EA9C6"
                strokeWidth="1.5"
              />
              <path
                d="M13 10C13 11.6569 11.6569 13 10 13C8.34315 13 7 11.6569 7 10C7 8.34315 8.34315 7 10 7C11.6569 7 13 8.34315 13 10Z"
                stroke="#5EA9C6"
                strokeWidth="1.5"
              />
            </svg></span> {location && <span>{location}, </span>}
              {city && <span>{city} </span>}</p>
          </div>
          {/* <div
            className="comment-texts d-none"
            dangerouslySetInnerHTML={{ __html: description }}
          /> */}
          {/* <div className="review-action d-none">
            <div className="review-item">
              <img src={review_active_icon} className="like-icon" alt="#" />
              <span className="active-text">Like</span>
              <span className="total-count">1230</span>
            </div>
            <div className="review-item">
              <img src={comment_icon} className="like-icon" alt="#" />
              <span className="active-text">Comment 40</span>
            </div>
            <div className="review-item">
              <img src={share_icon} className="like-icon" alt="#" />
              <span className="total-count">Share</span>
              <span className="total-count">100</span>
            </div>
          </div> */}
          <div className="visible-in-mobile action-btns">
            {jobInfo && showActionButtonOnJob(jobInfo)}
          </div>
        </CardBody>
        {/* <Button className="dot-button d-none">
          <FontAwesomeIcon icon={faEllipsisH} />
        </Button> */}
      </Card>
      {/*----------------------------------------------------------------------------------------------------------*/}
      <Modal
        isOpen={modal}
        toggle={toggleForm}
        backdrop="static"
        scrollable
        centered
      >
        <ModalHeader toggle={toggleForm}><span style={{ color: "gray" }}>Apply to</span> <span style={{ fontWeight: "400" }}>{jobInfo?.title} , {jobInfo?.institutionDetails?.name}</span></ModalHeader>
        <ModalBody>
          <ApplyJobs practitioner={currentUser} jobDet={jobInfo} getJob={getJob} {...props} toggle={toggle} />
        </ModalBody>
      </Modal>
    </>
  );
};

export default memo(FeedItem);
