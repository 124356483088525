import AlertDialog from "common/AlertDialog";
import moment from "moment";
import { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import {
  profileAddEvent,
  updateProfileCompletness,
} from "redux-helpers/ActionCreator";
import { UserStatusConstants } from "utils/Constants";
import { PractitionerService } from "utils/PractitionerService";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";
import {
  convertToMMMYYYYFormat,
  dateDifferenceInYearsAndMonths,
  getEmptySectionShowHide,
  getFullAddress,
  makeFullNameWithAddressWhenAddressIsNested,
  renderDateRange,
} from "utils/Utils";
import { RenderCertificationModal } from "./components/RenderCertificationModal";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import certifications_icon from "assets/svgIcon/certifications_icon.svg";
import plusIcon from "assets/images/plus-icon-lg.png";
import edit_blue_icon from "assets/images/edit-blue-icon.png";
import del_red_icon from "assets/images/del-red-icon.png";
import NoData from "../NoData";

const initialValue = {
  id: "",
  name: "",
  institutionDetail: {
    institutionName: "",
    guid: "",
    onSelectNewinstitution: 0,
  },
  issDate: "",
  expDate: "",
  expires: false,
  mainItemName: "",
  city: "",
  state: "",
  country: "",
  location: "",
  phone: "",
  postalCode: "",
  addressLine1: "",
  websiteAddress: "",
  category: "",
};

const conversionMonthYearToDate = (parameterDate) => {
  if (parameterDate) {
    const changeToString = parameterDate.toString();
    if (changeToString?.includes("-")) {
      const splitedMonthYear = parameterDate?.split("-");
      if (splitedMonthYear.length === 2) {
        return new Date(splitedMonthYear[1], splitedMonthYear[0], -1);
      }
    }
  }
};

const RenderCertification = (props) => {
  const {
    certifications,
    handleEditItem,
    handleRemoveItem,
    isLoggedinUser,
    isAdmin,
  } = props;
  const currentUser = useSelector((state) => state.auth.currentUser);
  if (certifications.length > 0) {
    certifications.map((certification) => {
      certification.issuedDateYear = !certification?.issuedOn
        ? moment().format("YYYY")
        : moment(conversionMonthYearToDate(certification.issuedOn)).format(
          "YYYY"
        );
      certification.issuedDateMonth = !certification?.issuedOn
        ? moment().format("MMM")
        : moment(conversionMonthYearToDate(certification.issuedOn)).format(
          "MMM"
        );
      certification.issuedDateMonthYearText =
        certification.issuedDateMonth + "-" + certification.issuedDateYear;
      certification.expiryDateYear = !certification?.expiredOn
        ? moment().format("YYYY")
        : moment(conversionMonthYearToDate(certification.expiredOn)).format(
          "YYYY"
        );
      certification.expiryDateMonth = !certification?.expiredOn
        ? moment().format("MMM")
        : moment(conversionMonthYearToDate(certification.expiredOn)).format(
          "MMM"
        );
      certification.expiryDateMonthYearText =
        certification.expiryDateMonth + "-" + certification.expiryDateYear;
      return certification;
    });
    // let sortCertifications = certifications
    //   .sort(function (a, b) {
    //     a = a.expiryDateMonth.split("-");
    //     b = b.expiryDateMonth.split("-");
    //     return new Date(a[1], a[0], 1) - new Date(b[1], b[0], 1);
    //   })
    //   .reverse();
    return certifications.map((item, index) => {
      const { institutionDetail } = item;
      const profileIdLink = item?.institutionDetail?.profileId
        ? item?.institutionDetail?.profileId
        : item?.institutionDetail?.guid;
      const institutionName = getFullAddress({
        name: institutionDetail?.name ?? "",
        abbreviation: institutionDetail?.abbreviation ?? "",
        location: institutionDetail?.address?.location ?? "",
        city: institutionDetail?.address?.city ?? "",
        state: institutionDetail?.address?.state ?? "",
      });
      return (
        <li
          className="list-group-item"
          key={`certi_${item.guid}`}
        >
          <div className="row g-0">
            <div className="col-auto">
              <img src={certifications_icon} alt="" />
            </div>
            <div className="col">
              <h3 className="card-title">{item.name}</h3>
              <p className="card-title mb-0"><small className="text-muted fw-semibold"><Link
                to={`/institution/${profileIdLink}`}
                className="mb-2 color-accent"
              >{institutionName}</Link></small></p>

              {item?.issuedOn &&
                <p className="card-text"> <small className="text-muted">{convertToMMMYYYYFormat(item?.issuedOn)}
                  {" - "}
                  {item?.doesNotExpire ? <span><span>{"No Expiry | "}</span><span>{dateDifferenceInYearsAndMonths(item?.issuedOn)}</span></span> : convertToMMMYYYYFormat(item?.expiredOn)}
                </small></p>
              }
            </div>


            <div className="col-auto d-flex align-items-start">
              {(isAdmin ||
                (isLoggedinUser &&
                  currentUser.status === UserStatusConstants.APPROVED)) && (
                  <div className="delete-action">
                    <Button
                      
                      color="link"
                      className="visible-hover py-0 nex-cs-certification-loop-edit-btn"
                      onClick={() => handleEditItem(item)}
                    >
                      <img src={edit_blue_icon} alt="" />
                    </Button>
                    <Button
                      color="link"
                      className="visible-hover delete py-0 nex-cs-certification-loop-del-btn"
                      onClick={() => handleRemoveItem(item)}
                    >
                      <img src={del_red_icon} alt="" />
                    </Button>
                  </div>
                )}
            </div>
          </div>
        </li>
      );
    });
  }
  return null;
};

const Certifications = (props) => {
  const [pannel_on, setPannel_on] = useState(true);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const eventName = useSelector((state) => state.profileComplete.eventName);
  const dispatch = useDispatch();
  const { guid } = props.practitioner;
  const { alert, isLoggedinUser, isAdmin, setShowCertificationsOnTabScroll } = props;
  const [certifications, setCertification] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [isOpen, setModal] = useState(false);
  const toggle = () => setModal(!isOpen);
  const [isOpenAlert, setAlertModal] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );

  useEffect(() => {
    dispatch(updateProfileCompletness(6, !!certifications.length));
    if (certifications.length > 0 || isLoggedinUser || isAdmin) {
      setShowCertificationsOnTabScroll(true);
    }else{
      setShowCertificationsOnTabScroll(false);
    }

  }, [certifications]);

  useEffect(() => {
    if (eventName === "ADD_CERTIFICATIONS") {
      handleEditItem(initialValue);
      dispatch(profileAddEvent(null));
    }
  }, [eventName]);

  const toggleAlert = () => {
    setLoading(false);
    setAlertModal(!isOpenAlert);
  };

  const handleEditItem = (selectedItem) => {
    const formData = {
      institutionDetail: {
        institutionName: selectedItem?.institutionDetail?.name ? makeFullNameWithAddressWhenAddressIsNested(selectedItem?.institutionDetail) : "",
        guid: selectedItem?.institutionDetail?.guid ?? "",
        onSelectNewinstitution: selectedItem?.institutionDetail?.guid ? 1 : 0,
      },
      issDate: selectedItem?.issuedOn ?? "",
      expDate: selectedItem?.expiredOn ?? "",
      expires: selectedItem?.doesNotExpire ?? false,
      mainItemName: selectedItem?.institutionDetail?.name ?? "",
      id: selectedItem?.guid ?? "",
      name: selectedItem?.name ?? "",
      city: "",
      state: "",
      country: "",
      location: "",
      phone: "",
      postalCode: "",
      addressLine1: "",
      websiteAddress: "",
      category: "",
      certificationNumber: selectedItem?.certificationNumber ?? "",
    };
    setSelectedItem(formData);
    toggle();
  };

  const handleRemoveItem = (selectedItem) => {
    const formData = {
      institutionDetail: {
        institutionName: selectedItem?.institutionDetail?.name ?? "",
        guid: selectedItem?.institutionDetail?.guid ?? "",
        onSelectNewinstitution: selectedItem?.institutionDetail?.guid ? 1 : 0,
      },
      issDate: selectedItem?.issuedOn ?? "",
      expDate: selectedItem?.expiredOn ?? "",
      expires: selectedItem?.expires ?? false,
      mainItemName: selectedItem?.institutionDetail?.name ?? "",
      id: selectedItem?.guid ?? "",
      name: selectedItem?.name ?? "",
      city: "",
      state: "",
      country: "",
      location: "",
      phone: "",
      postalCode: "",
      addressLine1: "",
      websiteAddress: "",
      category: "",
      certificationNumber: selectedItem?.certificationNumber ?? "",
    };
    setSelectedItem(formData);
    toggleAlert();
  };

  const handleClickOnYes = async () => {
    const userGUID = globalProfileInfo.guid;
    setLoading(true);
    try {
      const request = PractitionerService.deleteCertification(
        userGUID,
        selectedItem?.id
      );
      request
        .then(async (response) => {
          const { status } = response;
          if (status === 204) {
            await getData();
          } else {
            setLoading(false);
            let errmsg =
              response.data && !!response.data.message
                ? response.data.message
                : "Failed to save. Please try again";
            showErrorMessage(errmsg);
          }
        })
        .then(() => {
          setLoading(false);
          toggleAlert();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getData = async () => {
    const request = PractitionerService.getCertifications(guid);
    request.then((response) => {
      let { data } = response;
      if (data && data?.length > 0) {
        setCertification(data);
      } else {
        setCertification([]);
      }
    });
  };

  useEffect(() => {
    if (guid) {
      getData();
    }
  }, []);

  if (
    getEmptySectionShowHide(
      certifications ?? [],
      isAdmin,
      currentUser,
      globalProfileInfo,
      guid
    )
  )
    return null;

  return (
    <Card className={`card nexogic-card-color-header ${pannel_on ? 'expanded' : ''}`} id="certificationsSection">
      <div className="card-header"><h2>Certifications & Licensure</h2>


        {(isAdmin ||
          (isLoggedinUser &&
            currentUser.status === UserStatusConstants.APPROVED)) && (
            <Button
              id="nex-cs-certification-add-btn"
              color="link"
              className="add-button"
              onClick={() => handleEditItem(initialValue)}
            >
              <img src={plusIcon} alt="" />
            </Button>
          )}
        <button className="collapse-btn" type="button" onClick={() => setPannel_on(!pannel_on)}>
          <img src={pannel_arrow} alt="" />
        </button>
      </div>
      <div className={`collapse-div`}>
        <CardBody className={`${certifications && certifications.length > 0
          ? ""
          : "align-items-center"
          }`}>

          {certifications && certifications.length
            ? ""
            : (isLoggedinUser || isAdmin) &&
            currentUser.status === UserStatusConstants.APPROVED && (
              <Button
                id="nex-cs-certification-add-btn2"
                className="readmore"
                color="link"
                onClick={() => handleEditItem(initialValue)}
              >
                {"Add your certificate"}
              </Button>
            )}

          <ul className="list-group list-group-flush" id="nex-cs-certification-lists">
            <RenderCertification
              certifications={certifications}
              handleEditItem={handleEditItem}
              handleRemoveItem={handleRemoveItem}
              isLoggedinUser={isLoggedinUser}
              isAdmin={isAdmin}
            />
          </ul>
          {certifications.length < 1 && 
            <NoData>No Data</NoData>
            }
          <RenderCertificationModal
            selectedItem={selectedItem}
            toggle={toggle}
            isOpen={isOpen}
            practitionerId={guid}
            getData={getData}
            isAdmin={isAdmin}
          />
          <AlertDialog
            toggle={toggleAlert}
            isOpen={isOpenAlert}
            isLoading={isLoading}
            handleClickOnYes={handleClickOnYes}
            yesLabel={"Yes"}
            noLabel={"No"}
          />
        </CardBody>
      </div>
    </Card>
  );
};

export default withAlert()(Certifications);
