import { Button, Card, Col, Nav, NavItem } from 'reactstrap';
import SoComment from './SoComment';
import SoDocument from './SoDocument';
import { Link } from "react-router-dom";
import { FAILED_TO_FETCH_DATA } from 'utils/Constants';
import { OpinionService } from 'utils/OpinionService';
import { useEffect, useState } from 'react';
import { createImageFromInitials, dateFormat, getAge, getGenderDetails, getRandomColor } from 'utils/Utils';
import moment from 'moment';
import { encrypt } from 'utils/EncryptDecrypt';
import { PatientService } from 'utils/PatientService';
import OpinionDocument from './OpinionDocument';

const MyOpinionInfo = ({ opinion }) => {
    const [patientInfo, setPatientInfo] = useState({});

    const getPatientInfo = () => {
        if (opinion?.requestedBy === opinion?.requestedFor) {
            PatientService.getPatientById(opinion?.requestedBy)
                .then((response) => {
                    const { status, data } = response;
                    if (status === 200) {
                        setPatientInfo(data);
                        // console.log(data);
                    } else {
                        console.log(FAILED_TO_FETCH_DATA);
                    }
                })
        } else {
            PatientService.getRelatedPersonById(opinion?.requestedBy, opinion?.requestedFor)
                .then((response) => {
                    const { status, data } = response;
                    if (status === 200) {
                        setPatientInfo(data);
                        // console.log(data);
                    } else {
                        console.log(FAILED_TO_FETCH_DATA);
                    }
                })
        }
    }

    useEffect(() => {
        getPatientInfo();
    }, [opinion?.id])

    return (
        <Card className="nexogic-card-list-custom nexogic-so-list-card" >
            <div className="card-body">
                <div className="row need-margin">
                    <div className="col patient-info">
                        <h3 className=" card-title big">Patient Info</h3>
                        <div className="head">
                            <div className="graphic">
                                <div className="card-graphic"><img src={createImageFromInitials(100, patientInfo?.firstName + " " + patientInfo?.lastName, getRandomColor(encrypt(21).toString()))} className="rounded-circle" width="70" height="70" /></div>
                            </div>
                            <div className="info">
                                {patientInfo?.id ?
                                    <p className="card-text"><b>Name  </b>  <Link to={`/patient-profile/${patientInfo?.id}`}>{patientInfo?.firstName}{" "}{patientInfo?.lastName}</Link></p>
                                    :
                                    <p className="card-text"><b>Name  </b>  <span>{patientInfo?.firstName}{" "}{patientInfo?.lastName}</span></p>
                                }
                                <p className="card-text"><b>Phone  </b> {patientInfo?.cellularPhoneNumber}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col patient-info">
                        <h3 className=" card-title big">&nbsp;</h3>
                        <div className="head">
                            <div className="info">
                                <p className="card-text"><b>Age  </b>   {getAge(patientInfo?.dateOfBirth)}</p>
                                <p className="card-text"><b>Gender  </b> {getGenderDetails(patientInfo?.gender)}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col patient-info">
                        <h3 className=" card-title big">&nbsp;</h3>
                        <div className="head">
                            <div className="info">
                                <p className="card-text"><b>Specialty  </b>  {opinion?.speciality}</p>
                            </div>
                        </div>
                    </div>
                    <div className="status-col position-top"><span className="status-pill pending">{opinion?.status}</span></div>

                </div>
                <div className="row need-margin reason-sec">
                    <div className="col-lg-12">
                        <hr />
                        <h3 className="card-title"> Description:</h3>
                        <div className="card-text">
                            <p style={{ overflow: "hidden" }}>
                                <div dangerouslySetInnerHTML={{ __html: opinion?.description }}></div>
                            </p>
                        </div>
                        <div className="card-text d-flex"><div className="mr-auto"><Link to={`/u/medicalcases/my-opinions/${opinion?.id}`} className="nexogic_primary_button_outline ">More Details</Link></div></div>
                    </div>
                </div>

                <div className="nexogic-meta-description">
                    <div className="row need-margin">
                        <div className="col"><p className="card-text"><span className="icon"><svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 8V17C1 19.2091 2.79086 21 5 21H15C17.2091 21 19 19.2091 19 17V8M1 8V6.5C1 4.29086 2.79086 2.5 5 2.5H15C17.2091 2.5 19 4.29086 19 6.5V8M1 8H19M14 1V4M6 1V4" stroke="#5EA9C6" strokeWidth="1.5" strokeLinecap="round"></path></svg></span>  <b>Date  </b>
                            {`${moment(opinion?.createdOn).format(
                                "Do MMM, YYYY"
                            )}`}</p></div>
                        {/* <div className="col"><SoComment id={'so_connemt_1'} /></div> */}
                        <div className="col-auto"><OpinionDocument id={'so_document_1'} opinionId={opinion.id} /></div>
                    </div>
                </div>
            </div>
        </Card>
    )
}

export default MyOpinionInfo;