

import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { RenderInstituteFormFields } from "./RenderInstituteFormFields"
import API from 'utils/API'
import { InstitutionServices } from "utils/InstitutionServices"
import { withAlert } from "react-alert"
import pencilIcon from 'assets/images/pencil_icon.png';
import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage"
import { omit } from "lodash"
import RenderInstitutionCoverImage from "./RenderInstitutionImage"
import InstitutionImageUploader from "./InstitutionImageUploader"
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const validationSchema = Yup.object().shape({
    institutionName: Yup.string().min(3).max(50).required("This field is required"),
    heading: Yup.string().min(3).max(100).required("This field is required"),
    address: Yup.string().min(3).max(100).required('This field is required').nullable(),
});

const RenderEditInstituteForm = (props) => {
    const [modal, setModal] = useState(false);
    const [errorMsg, setErrorMessage] = useState("")
    const toggle = () => {
        setModal(!modal)
        setErrorMessage("")
    };
    const { institution, isAdmin, alert, isEditable, getInstitutionProfile, practitionerId } = props
    const { name, bannerUrl, profilePicUrl, heading, address, guid } = institution;
    const initialValues = { name, heading, address }
    return (
        <>
            {/*  { (isEditable || isAdmin) && 
            <Button color="link" className="round-icon-btn rounded-circle" onClick={toggle}>
            <img src={pencilIcon} />
            </Button>
        }  */}
            <Modal id="profile-modal" isOpen={modal} toggle={toggle} className="nexogic-institute-wrapper institute-modal modal-dialog-scrollable modal-dialog-centered">
                <ModalHeader toggle={toggle}>Institute Details</ModalHeader>
                <div className="bg-white mb-3 position-relative profile_main_section">
                    <RenderInstitutionCoverImage
                        bannerUrl={bannerUrl}
                        isEditable={isEditable}
                        isAdmin={isAdmin}
                        practitionerId={practitionerId}
                    />
                    <div className="institution-image">
                        <InstitutionImageUploader
                            profilePicUrl={null}
                            practitionerId={practitionerId}
                            isEditable={isEditable}
                            isAdmin={isAdmin}
                            institutionName={name}
                        />
                    </div>
                </div>
                <ModalBody className="mt-4">
                    {errorMsg !== "" && <p><RenderErrorMessage errorMsg={errorMsg} /> </p>}
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { setSubmitting }) => {
                            setTimeout(async () => {
                                let { name, address, heading } = values;
                                setSubmitting(true)
                                const payload = { "institutionName": name, "heading": heading, createdBy: practitionerId }
                                InstitutionServices.updateInstitution(payload, guid)
                                    .then(response => {
                                        const { data } = response;
                                        getInstitutionProfile(guid);
                                        showSuccessMessage("Institution updated successfully");
                                        toggle()
                                        setSubmitting(false)
                                    }).catch(err => {
                                        showErrorMessage(err.message)
                                        toggle()
                                        setSubmitting(false)
                                    });
                            }, 400);
                        }}
                        children={props => {
                            return <RenderInstituteFormFields {...props} toggle={toggle} />
                        }}
                    />
                </ModalBody>
            </Modal>
        </>
    );
}


export default withAlert()(RenderEditInstituteForm);



