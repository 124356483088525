import parse from "html-react-parser";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import RenderAboutForm from "./RenderAboutForm";
import pencilIcon from "assets/images/pencil_icon.png";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import edit_icon_card_head from "assets/images/edit-blue-icon.png";
import { useEffect } from "react";

const RenderinstituteSummary = (props) => {
  const { instituteSummary, isOpen, setIsOpen } = props;
  if (instituteSummary && instituteSummary !== "<p><br></p>") {
    if (isOpen) {
      return (
        <>
          <div className="card-text">
            {parse(instituteSummary)}
            <Button
              color="link"
              onClick={() => setIsOpen(false)}
              className="readmore "
            >
              Read less
            </Button>
          </div>
        </>
      );
    } else {
      const showLessSummary = instituteSummary.slice(0, 500) + "...";
      if (instituteSummary.length > 500) {
        return (
          <>
            <div className="card-text">
              {parse(showLessSummary)}
              <Button
                color="link"
                onClick={() => setIsOpen(true)}
                className="readmore "
              >
                Read more
              </Button>
            </div>
          </>
        );
      } else {
        return (
          <div className="about-text">{parse(instituteSummary)}</div>
        );
      }
    }
  } else {
    return "";
  }
};

export const About = (props) => {
  const [modalisOpen, setmodalIsOpen] = useState(false);
  const modalToggle = () => setmodalIsOpen(!modalisOpen);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const { isEditable, isAdmin, institution, getInstitutionProfile, setShowAboutOnTabScroll } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const toggle = () => setIsEditMode(!isEditMode);
  let instituteSummary = institution?.aboutUs;
  const [pannel_on, setPannel_on] = useState(true);


  useEffect(() => {
    if (instituteSummary && instituteSummary.length > 0 || isAdmin) {
      setShowAboutOnTabScroll(true)
    }else{
      setShowAboutOnTabScroll(false);
    }
  }, [instituteSummary, isAdmin])

  return (
    <>
      {((institution?.aboutUs && !isAdmin) || isAdmin) && (
        <Card className={`nexogic-card-color-header ${pannel_on ? 'expanded' : ''}`} id="about">
          <div className="card-header"><h2>About</h2>
                            <button className="collapse-btn" type="button" onClick={()=>setPannel_on(!pannel_on)}>
                              <img src={pannel_arrow} alt="" />
                          </button>
                          {(isEditable || isAdmin) && (
                        
                          <Button
                            id="nex-ids-about-edit-btn"
                            color="link"
                            className="add-button"
                            onClick={modalToggle}
                          >
                            <img src={edit_icon_card_head} alt="" />
                          </Button>
                        
                      )}
          </div>
          <div className={`collapse-div`}>
          <CardBody>
            <div>
              {isEditMode ? (
                <RenderAboutForm
                  toggle={toggle}
                  instituteSummary={instituteSummary}
                  isEditable={isEditable}
                  isAdmin={isAdmin}
                  practitionerId={currentUser?.practitionerId}
                  institutionId={institution?.id}
                  getInstitutionProfile={getInstitutionProfile}
                />
              ) : instituteSummary !== "" ? (
                <RenderinstituteSummary
                  instituteSummary={instituteSummary}
                  isOpen={isOpen}
                  isEditable={isEditable}
                  setIsOpen={setIsOpen}
                  practitionerId={currentUser?.practitionerId}
                />
              ) : (
                // <div className="mt-3">
                //   <Row>
                //     <Col className="">
                //         <div className="empty-about-box">
                //             <div className="about-placeholder">Showcase your work by featuring your best posts, documents, media and websites.</div>
                //             <div className="add-infomation">Add information</div>
                //         </div>
                //     </Col>
                //   </Row>
                // </div>
                ""
              )}
            </div>
          </CardBody>
          </div>
        </Card>
      )}
      {/* about modal */}
      <Modal
        isOpen={modalisOpen}
        toggle={modalToggle}
        className="institute-modal"
        backdrop="static"
      >
        <ModalHeader
          close={
            <button className="close" onClick={modalToggle}>
              ×
            </button>
          }
          toggle={modalToggle}
        >
          About
        </ModalHeader>
        <ModalBody>
          <RenderAboutForm
            toggle={modalToggle}
            instituteSummary={instituteSummary}
            isEditable={isEditable}
            isAdmin={isAdmin}
            institution={institution}
            practitionerId={currentUser?.guid}
            institutionId={institution?.guid}
            getInstitutionProfile={getInstitutionProfile}
          />
        </ModalBody>
      </Modal>
    </>
  );
};
