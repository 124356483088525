import React from 'react';
import { Link } from 'react-router-dom'
import page_img_404 from "assets/images/404.jpg";

const Error404 = () => (
  <div className="card nexogic-404-card">
    <div className="err">
      <div className="graphic">
        <img src={page_img_404} alt="" />
      </div>
      <h1 className='card-title'>Sorry, page not found</h1>
      <div className="actions">
      <Link to="/" className="btn nexogic_primary_button ">
        <span className='icon'><svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.33333 26V13.5H15.6667V26M1 9.75L12 1L23 9.75V23.5C23 24.163 22.7425 24.7989 22.284 25.2678C21.8256 25.7366 21.2039 26 20.5556 26H3.44444C2.79614 26 2.17438 25.7366 1.71596 25.2678C1.25754 24.7989 1 24.163 1 23.5V9.75Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
</span>
        Go Back to Home Page
      </Link>
    </div>
    </div>

    
  </div>
);

const Page = () => (
  <div className="page-err">
    <div key="1">
      <Error404 />
    </div>
  </div>
);

export default  Page;
