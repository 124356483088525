import { Field, Formik } from "formik";
import { useMemo, useState, useEffect, Fragment } from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import * as Yup from "yup";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import { withAlert } from "react-alert";
import { removeComma } from "utils/Utils";

const validationSchema = Yup.object().shape({
    pskills: Yup.string().required("This field is required"),
    // pskills: Yup.string()
    //     .matches(/^[^,].*/, "Cannot start with a comma")
    //     .matches(/.*[^,]$/, "Cannot end with a comma").required("This field is required"),
});

const SkillsBulkEntry = (props) => {
    const { toggle, alert, values, handleSubmit, allskillsList, primarySpeciality, setSkillsList, setFieldValue } = props;
    const initValues = useMemo(() => {
        return {
            pskills: '',
        };
    }, []);

    const [initialValues, setInitialValues] = useState(initValues);
    const addSelectedSkills = (values) => {
        let skillsList = removeComma(values.trim()).split(",");
        skillsList = skillsList.map(skills => skills.trim());
        setFieldValue("skills", skillsList, true);
        toggle();
    }
    useEffect(() => {
        let str = values?.skills.join(",");
        setInitialValues({ pskills: str });
    }, [values?.skills?.length])

    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                children={(props) => {
                    const { values, isLoading, setFieldValue } = props;
                    return (
                        <>
                            <form>
                                <Fragment>
                                    <fieldset className="u-flsd-wpr mb-3">
                                        <legend>Enter values in comma seperated format</legend>
                                        <div className="form-group">
                                            <Field
                                                id="pskills"
                                                name="pskills"
                                                type="textarea"
                                                component={FormikInputField}
                                                placeholder="Addiction, Accupuncturist, Acute Care,..."
                                                autoComplete="off"
                                                value={values.pskills}
                                                spellCheck={false}
                                                style={{ height: 240, width: "100%" }}
                                            />
                                        </div>
                                    </fieldset>
                                </Fragment>
                                <Row className="modal-save-main form-row">
                                    <Col lg={12} md={12} sm={12} className="justify-content-end  modal-footer mx-0">
                                        
                                        {isLoading ? (
                                            <div className="loading-spiner">
                                                <Spinner style={{ width: "2rem", height: "2rem" }} />
                                            </div>
                                        ) : (
                                            <>
                                                <div className="action-group col-auto">
                                                    <Button
                                                        id="sbf_btn_cancel"
                                                        onClick={toggle}
                                                        className="nexogic_primary_button_outline"
                                                        color="primary"
                                                        type="button"
                                                        
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        id="sbf_btn_submit"
                                                        className="nexogic_primary_button modal-save-btn mr-1"
                                                        color="primary"
                                                        type="button"
                                                        onClick={() => addSelectedSkills(values.pskills)}
                                                        // onClick={() => addSelectedSkills(values.pskills)}
                                                        >
                                                        Save
                                                    </Button>
                                                </div>
                                            </>
                                        )}
                                       
                                    </Col>
                                </Row>
                            </form>
                        </>
                    );
                }}
            />
        </div>
    )
}

export default withAlert()(SkillsBulkEntry);