import { Field } from "formik";
import "react-phone-input-2/lib/style.css";
import { Button, Col, InputGroup, Row, Spinner } from "reactstrap";
import FormikMobileField from "components/forms/formikFields/FormikMobileField";
import FormikCheckboxField from "components/forms/formikFields/FormikCheckboxField"


const MobileNumberForm = ({ isSubmitting, handleSubmit, error }) => (
  <div>
    <form onSubmit={handleSubmit}>
      <Row>
        <Col xs={12}>
          <div className="mb-4">
            <InputGroup className="d-flex justify-content-end mt-3" size="medium">

              {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&
                <>
                  <label htmlFor="chkPatient" className="form-check-label mr-3 conf-text">Are you a Patient</label>
                  <div className="apptfor">
                    <Field
                      id="chkPatient"
                      name="chkPatient"
                      type="checkbox"
                      component={FormikCheckboxField}
                      className="mt-m1"
                    />
                  </div>
                </>
              }
              {/* <Label check className="conf-text mr-4">
                  Are you a Patient 
                </Label>  */}
              {/* <Field
                name="usertype"
                type="text"
                label="Login as"
                component={FormikSelectField}
                inputprops={{
                  name: `usertype`,
                  options: User_Types,
                //   defaultOption: "PRACTITIONER",
                  keys: {
                    id: "value",
                    label: "label",
                  },
                }}
              /> */}
            </InputGroup>
          </div>
          <div className="nexogic-phone-field-wrap">
            <InputGroup size="medium">
              <Field
                id="mnf_phone"
                name="phone"
                type="text"
                label=""
                component={FormikMobileField}
                placeholder="Mobile Number"
                autocomplete="phone"
              />
            </InputGroup>
          </div>
        </Col>

        {error ? <div className="card-text text-danger fw-semibold m-auto col-lg-12">{error}</div> : null}
      </Row>

      <Row className="mb-4 mt-2 align-items-center">
        <Col xs={12}>
          {isSubmitting ? (
            <div className="loading-spiner text-center">
              <Spinner />
            </div>
          ) : (
            <Button
              id="mnf_btn_submit"
              type="submit"
              className="nexogic_primary_button"
              color="primary"
              size="md"
              block
            >
              Receive OTP
            </Button>
          )}
        </Col>
      </Row>
    </form>
  </div>
);

export default MobileNumberForm;
