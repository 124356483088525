// import React, { forwardRef, useEffect, useState } from "react";
// import InfiniteScroll from "react-infinite-scroll-component";
// import { PractitionerService } from "utils/PractitionerService";

// import { createImageFromInitials, getFullAddressWithLocationCityAndState, getRandomColor } from "utils/Utils";
// import { encrypt } from "utils/EncryptDecrypt";
// // import CommonLoader from "pages/Search/components/CommonLoader";
// import './style.css'
// import CommonLoader from "common/CommonLoader";

// const AllPractitioners = forwardRef((props, ref) => {
//   const { institutionGuid } = props;
//   const [list_of_data, setList_of_data] = useState(null);
//   const [isLoading, setIsLoading] = useState(true);

//   const getData = async (pageNo, feedlist) => {
//     const request = PractitionerService.getPractitionersAssociatedtoInstitutionWithPagination(
//       institutionGuid,
//       pageNo
//     );
//     request
//       .then(async (response) => {
//         let { data } = response;
//         if (pageNo === 0) {
//           const allData = { ...data };
//           const resolved = await Promise.all(
//             allData.contents.map(async (item) => {
//               const each = { ...item };
//               return each;
//             })
//           );
//           allData.contents = resolved;
//           setList_of_data(allData);
//           setIsLoading(false);
//         } else {
//           const resolved = await Promise.all(
//             data.contents.map(async (item) => {
//               const each = { ...item };
//               return each;
//             })
//           );
//           feedlist = feedlist.concat(resolved);
//           setList_of_data({ ...data, contents: feedlist });
//           setIsLoading(false);
//         }
//       })
//       .catch((error) => {
//         console.log("Error while getting practitioners' list: ", error);
//       });
//   };

//   const fetchData = () => {
//     getData(list_of_data.currentPage + 1, list_of_data.contents);
//   };

//   useEffect(() => {
//     getData(0, []);
//   }, []);

//   if (isLoading) {
//     return (
//       <div className="loading-spiner text-center  mt-2">
//         <CommonLoader />
//       </div>
//     );
//   }

//   return list_of_data && list_of_data.contents.length > 0 ? (
//     <>
//     <div className="practitioners-slider-scroll bg-color" id="practitioners-slider-scroll">    
//       <InfiniteScroll
//         dataLength={list_of_data.contents.length}
//         next={() => fetchData()}
//         hasMore={list_of_data.currentPage + 1 < list_of_data.totalPages}
//         loader={
//           <div className="loading-spiner text-center  mt-2">
//             <CommonLoader />
//           </div>
//         }
//         scrollableTarget="practitioners-slider-scroll"
//         className="nexogic-custom-scroll"
//         height={340}
//       >
        
//         {list_of_data &&
//           list_of_data.contents.map((practitioner, id) => (
//             <>
//               <div className="slider-item bg-transparent">
//                 <div className="card-img">
//                   {practitioner?.avatarId ? (
//                     <img
//                       src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${practitioner?.profileGuid}/${practitioner?.avatarId}`}
//                       alt="#"
//                     />
//                   ) : (
//                     <img
//                       src={createImageFromInitials(
//                         100,
//                         practitioner?.name.replace("Dr. ", ""),
//                         getRandomColor(
//                           encrypt(practitioner?.profileGuid).toString()
//                         )
//                       )}
//                       alt="#"
//                     />
//                   )}
//                 </div>
//                 <a href={`../profile/${practitioner?.profileId}`} target="_blank" rel="noopener noreferrer" className="heading font-weight-bold">{practitioner?.name}</a>
//                 <p className="text-truncate m-0 page-text fw-400">
//                 {practitioner?.primarySpeciality} {practitioner?.superSpeciality ? ` | ${practitioner?.superSpeciality}`:''}
//                 </p>
//                 <p className="card-text page-text fw-400">
//                   <strong>
//                     {new Date().getFullYear() - practitioner?.practicingSince >
//                       1 ? (
//                       <>
//                         {" "}
//                         {new Date().getFullYear() -
//                           practitioner?.practicingSince}
//                         {"+ years experience"}
//                       </>
//                     ) : (
//                       "1 year experience"
//                     )}
//                   </strong>
//                 </p>
//                 {/* <p className="card-text">
//                   {getFullAddressWithLocationCityAndState({ location: practitioner?.location ?? "", city: practitioner?.city ?? "", state: practitioner?.state ?? "" })}
//                 </p> */}
//               </div>
//             </>
//           ))}
//       </InfiniteScroll>
//       </div>
//     </>
//   ) : (
//     <h1>No Data</h1>
//   );
// });

// export default AllPractitioners;

import React, { forwardRef, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { PractitionerService } from "utils/PractitionerService";

import { createImageFromInitials, getFullAddressWithLocationCityAndState, getRandomColor } from "utils/Utils";
import { encrypt } from "utils/EncryptDecrypt";
// import CommonLoader from "pages/Search/components/CommonLoader";
import CommonLoader from "common/CommonLoader";
import './style.css'

const AllPractitioners = (props) => {
  const { institutionGuid } = props;
  const [list_of_data, setList_of_data] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getData = async (pageNo, feedlist) => {
    const request = PractitionerService.getPractitionersAssociatedtoInstitutionWithPagination(
      institutionGuid,
      pageNo
    );
    request
      .then(async (response) => {
        let { data } = response;
        if (pageNo === 0) {
          const allData = { ...data };
          const resolved = await Promise.all(
            allData.contents.map(async (item) => {
              const each = { ...item };
              return each;
            })
          );
          allData.contents = resolved;
          setList_of_data(allData);
          setIsLoading(false);
        } else {
          const resolved = await Promise.all(
            data.contents.map(async (item) => {
              const each = { ...item };
              return each;
            })
          );
          feedlist = feedlist.concat(resolved);
          setList_of_data({ ...data, contents: feedlist });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("Error while getting practitioners' list: ", error);
      });
  };

  const fetchData = () => {
    getData(list_of_data.currentPage + 1, list_of_data.contents);
  };

  useEffect(() => {
    getData(0, []);
  }, []);

  if (isLoading) {
    return (
      <div className="loading-spiner text-center  mt-2">
        <CommonLoader />
      </div>
    );
  }

  return list_of_data && list_of_data.contents.length > 0 ? (
    <>
    <div className="nex-practitioners-list-view nexogic-custom-scroll bg-color" id="practitioners-slider-scroll">    
      <InfiniteScroll
        dataLength={list_of_data.contents.length}
        next={() => fetchData()}
        hasMore={list_of_data.currentPage + 1 < list_of_data.totalPages}
        loader={
          <div className="loading-spiner text-center  mt-2">
            <CommonLoader />
          </div>
        }
        scrollableTarget="practitioners-slider-scroll"
        className="nexogic-custom-scroll"
        height={340}
      >
        
        {list_of_data &&
          list_of_data.contents.map((practitioner, id) => (
              <div className="slider-item bg-transparent" key={id}>
                <div className="card-img">
                  {practitioner?.avatarId ? (
                    <img
                      src={`${process.env.REACT_APP_IMAGE_BASE_URL}/${practitioner?.profileGuid}/${practitioner?.avatarId}`}
                      alt="#"
                    />
                  ) : (
                    <img
                      src={createImageFromInitials(
                        100,
                        practitioner?.name.replace("Dr. ", ""),
                        getRandomColor(
                          encrypt(practitioner?.profileGuid).toString()
                        )
                      )}
                      alt="#"
                    />
                  )}
                </div>
                <div className="info">
                {/* <h3 className="heading"> */}
                  <a href={`../profile/${practitioner?.profileId}`} target="_blank" rel="noopener noreferrer" className="heading font-weight-bold text-decoration-none"><h3 className="text-truncate fw-bold mb-1">{practitioner?.name}</h3></a>
                {/* </h3> */}

                <p className="text-truncate m-0 page-text fw-400">
                      {practitioner?.primarySpeciality} {practitioner?.superSpeciality ? ` | ${practitioner?.superSpeciality}`:''}
                    </p>
                {/* <p className="card-text">
                  {practitioner?.primarySpeciality} |{" "}
                  {practitioner?.superSpeciality}
                </p> */}
                <p className="card-text m-0 page-text fw-400">
                    {new Date().getFullYear() - practitioner?.practicingSince >
                      1 ? (
                      <>
                        {" "}
                        {new Date().getFullYear() -
                          practitioner?.practicingSince}
                        {"+ years experience"}
                      </>
                    ) : (
                      "1 year experience"
                    )}
                </p>
                {/* <p className="card-text">
                  {getFullAddressWithLocationCityAndState({ location: practitioner?.location ?? "", city: practitioner?.city ?? "", state: practitioner?.state ?? "" })}
                </p> */}
                </div>
              </div>
          ))}
      </InfiniteScroll>
      </div>
    </>
  ) : (
    <h1>No Data</h1>
  );
};

export default AllPractitioners;

