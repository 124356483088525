import React from "react";
import { Container, Row, Col } from "reactstrap";
import { useLocation } from "react-router-dom";

//import "./connection.scss";
import ConnectionsContent from "./ConnectionContent";
import ConnectionSidebar from "./ConnectionSidebar";
import SuggestedConnections from "./components/SuggestedConnections";
import Filters from "./components/Filters";
import GroupView from "./components/Groups/components/GroupView";
import contactsIcon from "../../assets/images/svgs/connections-icon.svg";
import { useState } from "react";
import { useEffect } from "react";
import PeopleKnowWidget from "common/PeopleKnowWidget";
import { useSelector } from "react-redux";
import JobWidget from "common/JobWidget";
import { isPractitioner } from "utils/UserHelper";

const Connections = (props) => {
  let location = useLocation();
  let groupView = location.pathname.includes("/connections/groups/");

  const currentUser = useSelector((state) => state.auth.currentUser);
  const { primarySpeciality } = currentUser;

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const breakpoint = 620;
  useEffect(() => {
    window.addEventListener("resize", () => setScreenWidth(window.innerWidth));
  }, []);


  return (
    <>
      {groupView ? (
        <section className="nexogic-connections-managements pb-4 pt-4">
          <GroupView />
        </section>
      ) : (
        <section className="nexogic-connections-managements pb-4 ">
          {/** 
          <div className="nexogic-heading-sec nexogic-card">
            <h2 className="nexogix-section-title"><img src={contactsIcon} className="icon" />Connection</h2>
            {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && (
              <Filters />
            )}
          </div>
          */}
          <main>
            <Row className="nexogic-connections-container px-0" id="nex-connection-page">
              <Col md={4} lg={3} className={`${screenWidth < breakpoint ? 'mb-3' : ''} nex-app-mb-0`}>
                <div className="nexogic-sidebar-stiky">
                  <ConnectionSidebar />
                  {/*screenWidth > breakpoint && (<>
                    {isPractitioner() && primarySpeciality &&
                      <>
                        <div className="mt-3 mb-3">
                          <PeopleKnowWidget speciality={primarySpeciality} />
                        </div>
                        <JobWidget widgetTitle="Recommended Jobs" primarySpeciality={primarySpeciality} />
                      </>
                    }
                  </>
                  )
                  */}
                  {isPractitioner() && primarySpeciality &&
                      <>
                        <div className="mt-3 mb-3">
                          <PeopleKnowWidget speciality={primarySpeciality} />
                        </div>
                        <JobWidget widgetTitle="Recommended Jobs" primarySpeciality={primarySpeciality} />
                      </>
                    }
                </div>
              </Col>
              <Col md={8} lg={9}>
                <ConnectionsContent />
                {screenWidth < breakpoint && (<>
                  {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" && (
                    <div className="mt-3 mb-3">
                      <SuggestedConnections />
                    </div>
                  )}
                </>
                )
                }
              </Col>
            </Row>
          </main>
        </section>
      )}
    </>
  );
};

export default Connections;
