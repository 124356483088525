import React, { PureComponent } from "react";
import { Container, Row, Col } from "reactstrap";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import Sidebar from "./components/Sidebar";
import withAuth from "hoc/withAuth";
import Content from "./components/Content";
import { createBrowserHistory } from "history";
import SideMenu from "pages/PatientProfile/ProfileDetails/components/SideMenu";
import { isPatient } from "utils/UserHelper";
class App extends PureComponent {
  state = { isActive: false };
  handleToggle = () => {
    this.setState({ isActive: !this.state.isActive });
  };
  render() {
    const isActive = this.state.isActive;
    const location = createBrowserHistory();
    const urlLocation = location.location.pathname;
    // console.log("new path", urlLocation);
    return (
      <section>
        <Header />
         {/** ${isPatient() ? 'v2' : ''} */}
        <main
         
          className={`theme-body-bg nexogic-dashboard-card  ${urlLocation === "/messages" ? "message-layout" : ""
            }`}  
        >
          <Container fluid className="container-fluid card-body">
            <div className="row nexogic-content-area">
              <div className="nexogic-admin-content col">
                <Content />
              </div>
            </div>
          </Container>
        </main>
        <Footer />
      </section>
    );
  }
}

export default withAuth(App);
