import CommonLoader from "common/CommonLoader";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ConnectionService } from "utils/ConnectionService";
import ConnectionCard from "./ConnectionCard";
import NoConnection from "./NoConnection";

const Invitations = () => {
  const currentUser = useSelector((state) => state.auth.currentUser);
  const guid = currentUser.guid;
  const [invitationsConnections, setInvitationsConnections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  useEffect(() => {
    getInvitationsConnections(0, []);
  }, [guid]);

  useEffect(() => {
    document.body.classList.add('body-network-page');
    document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "NetWork";
    return () => {
      document.body.classList.remove('body-network-page');
      document.querySelectorAll('.nex-app-top-header .nex-page-name')[0].innerHTML = "";
    }
}, [])

  const getInvitationsConnections = (pageNo, connections) => {
    ConnectionService.getConnectionsInfo(guid, "RECEIVED", pageNo)
      .then((response) => {
        let { data } = response;
        if (data?.currentPage < (data?.totalPages - 1))
          setHasMore(true);
        else
          setHasMore(false);
        setIsLoadingMore(false);
        if (response.status === 200 || response.status === 201) {
          if (pageNo === 0) {
            setInvitationsConnections(data);
            setIsLoading(false);
          } else {
            connections = connections.concat(data.contents);
            setInvitationsConnections({ ...data, contents: connections });
            setIsLoading(false);
          }
        }
      })
      .catch((error) => {
        setIsLoadingMore(false);
        console.log("Error while getting connection: ", error);
      });
  };

  if (isLoading) {
    return (
      <div className="loading-spiner text-center  mt-2">
        <CommonLoader style={{ width: "2rem", height: "2rem" }} />
      </div>
    );
  }

  return (
    <>
      {invitationsConnections.totalItems ? (
        <ConnectionCard
          connectionsDetails={invitationsConnections}
          getConnections={getInvitationsConnections}
          setConnectionsDetails={setInvitationsConnections}
          isLoadingMore={isLoadingMore}
          setIsLoadingMore={setIsLoadingMore}
          hasMore={hasMore}
        />
      ) : (
        <div className="nex-app-need-pad">
          <NoConnection content={"No Invitation"} />
        </div>
      )}
    </>
  );
};

export default Invitations;
