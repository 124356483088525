import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";

import { ConnectionServices } from "utils/ConnectionServices";
import { encrypt } from "utils/EncryptDecrypt";
import {
  createImageFromInitials,
  getProfilePicUrl,
  getRandomColor,
} from "utils/Utils";
import NoConnection from "./NoConnection";

import profilePicUrl from "assets/images/profile-p.jpg";
import messageIcon from "assets/images/svgs/message_icon.svg";
import videoIcon from "assets/images/svgs/video_icon.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const Colleagues = (props) => {
  const [colleagues, setColleagues] = useState([]);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const practitionerId = currentUser.practitionerId;
  const practitionerIdEncrypt = encrypt(currentUser.practitionerId);

  useEffect(() => {
    ConnectionServices.getColleaguesByPractionerId(practitionerId).then(
      (response) => {
        // console.log(response)
        const { data } = response;
        if (data.status === "SUCCESS") {
          if (data.data.connectionCount > 0) {
            setColleagues(data.data.connection);
          } else {
            setColleagues([]);
          }
        } else {
          setColleagues([]);
        }
      },
      (err) => {
        console.log("Alumni err", err);
        setColleagues([]);
      }
    );
  }, []);

  return (
    <>
      <Card className="border-0 bg-transparent">
        <CardBody className="p-0">
          <div className="card-group nexogic-card-group-list card mb-4">
            <div className="card-body">
              <div className="row no-gutters">
              <div className="col-img col-auto">
                <img className="card-img card-img-lg small-rounded" src={profilePicUrl} alt="#" />
              </div>
              <div className="col">
                <h2 className="card-title">
                <a
                  href
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  
                >
                  Jessica Ford
                </a>
                </h2>
                <div className="card-text">Paramedic </div>
                <div className="card-text"> Penn Hospital, Los Angeles</div>
              </div>
              <div className="nexogic-action-part vertical-align">
                <Button color="info" outline className="msg-button">
                <svg
                className="icon"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      fill="none"
      viewBox="0 0 20 18"
    >
      <path
        fill="#5EA9C6"
        fillRule="evenodd"
        d="M8 0h1.143c3.235 0 5.947 2.287 6.669 5.364A7.16 7.16 0 0116 7c0 3.866-3.07 7-6.857 7H2.286C1.023 14 0 12.955 0 11.667v-3.5C0 3.657 3.582 0 8 0zm1.429 16h-3.37a6.764 6.764 0 004.798 2h6.857C18.977 18 20 16.955 20 15.667v-3.5a8.233 8.233 0 00-2.003-5.406L18 7c0 4.97-3.838 9-8.571 9z"
        clipRule="evenodd"
      ></path>
    </svg>
                </Button>
                <Button color="danger" outline className="ml-3 msg-button">
                <svg className="icon"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#C93B3C"
        fillRule="evenodd"
        d="M7.25 2.75a2.75 2.75 0 015.5 0v.166c2.385.29 4.613.952 6.578 1.91a.75.75 0 01-.657 1.348C16.18 4.96 13.204 4.25 10 4.25c-3.204 0-6.18.71-8.672 1.924a.75.75 0 11-.657-1.348c1.966-.958 4.194-1.62 6.579-1.91V2.75zm9.015 13.533a4 4 0 01-3.99 3.715h-4.55a4 4 0 01-3.99-3.715l-.689-9.635C5.164 5.91 7.52 5.498 10 5.498s4.836.412 6.954 1.15l-.689 9.635zM8.75 8.998a.75.75 0 10-1.5 0v6a.75.75 0 101.5 0v-6zm3.25-.75a.75.75 0 01.75.75v6a.75.75 0 11-1.5 0v-6a.75.75 0 01.75-.75z"
        clipRule="evenodd"
      ></path>
    </svg>
                </Button>
              </div>
              </div>
            </div>
            
            
          </div>
          <Row>
            {colleagues.map((item, index) => {
              const username = `${item.firstName} ${item.lastName}`;
              const primarySpeciality = item.primarySpeciality
                ? item.primarySpeciality.join()
                : "";
              const location = item.city
                ? item.city
                : item.state
                ? item.state
                : "";
              const mutualConnections = item.mutualConnections || 0;
              const profilePicUrl = item.profilePicUrl;
              return (
                <Col lg={6} xs={12} className="mb-3">
                  <Card>
                    <CardBody className="p-3">
                      <div className="d-flex align-items-center">
                        <div className="user-image-section p-1">
                          <img
                            src={
                              profilePicUrl
                                ? getProfilePicUrl(profilePicUrl)
                                : createImageFromInitials(
                                    50,
                                    username,
                                    getRandomColor(encrypt(21).toString())
                                  )
                            }
                            className="user-image"
                          />
                        </div>
                        <div className="w-100">
                          <Link to={`/profile/${practitionerIdEncrypt}`}>
                            <div className="black-color-accent f-16 line-h-16 font-weight-bold">
                              {username}
                            </div>
                          </Link>
                          <div className="my-2 d-flex align-items-center">
                            <div className="">
                              <div className="text-complimentary f-12 line-h-14">
                                {primarySpeciality}
                              </div>
                              <div className="text-complimentary f-12">
                                {location}
                              </div>
                            </div>
                            <div className="ml-auto">
                              <Button
                                color="primary"
                                className="rounded-circle round-icon-btn mr-2"
                              >
                                <img src={messageIcon} />
                              </Button>
                              <Button
                                color="primary"
                                className="rounded-circle round-icon-btn"
                              >
                                <img src={videoIcon} />
                              </Button>
                            </div>
                          </div>
                          <div className="text-complimentary f-12 font-weight-600">
                            {mutualConnections > 1
                              ? `${mutualConnections} mutual connections`
                              : `${mutualConnections} mutual connection`}
                          </div>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
            {colleagues.length === 0 && (
              <Col className="px-0 nex-app-need-pad">
                <NoConnection content="No Colleagues connection" />
              </Col>
            )}
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default Colleagues;
