import React from "react";
import { useState } from "react";
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, FormGroup, Input, ListGroup, ListGroupItem, Row } from "reactstrap";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { PatientService } from "utils/PatientService";
import CommonLoader from "common/CommonLoader";
import { useEffect } from "react";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import CommonMultipleSelectTypeaheadField from "components/forms/formikFields/CommonMultipleSelectTypeaheadField";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikDatePicker from "components/forms/formikFields/FormikDatePicker";
import { MetadataService } from "utils/MetadataService";
import { getFullNameOfPerson } from "utils/Utils";


const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    firstName: Yup.string().required('First Name is required'),
    middleName: Yup.string(),
    lastName: Yup.string().required('Last Name is required'),
    gender: Yup.string().required('gender is required'),
    relationshipType: Yup.string().required('relationshipType is required'),
});


const CreateNewRelatedPerson = ({ currentUserId, setCurrentStep, setCurrentUserDetails, setRequestedForId }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [relatedPeople, setRelatedPeople] = useState([]);
    const [initialValues, setInitialValues] = useState({
        title: '',
        firstName: '',
        middleName: '',
        lastName: '',
        dateOfBirth: '',
        gender: '',
        emailAddress: '',
        cellularPhoneNumber: '',
        relationshipType: ''
    });

    const relationshipTypes = [
        {
            label: "Mother",
            value: "MOTHER",
        },
        {
            label: "Father",
            value: "FATHER",
        },
        {
            label: "Sibling",
            value: "SIBLING",
        },
        {
            label: "Spouse",
            value: "SPOUSE",
        },
        {
            label: "Child",
            value: "CHILD",
        },
        {
            label: "Other",
            value: "OTHER",
        }
    ];


    const genders = [
        {
            label: "Male",
            value: "M",
        },
        {
            label: "Female",
            value: "F",
        },
        {
            label: "Prefer not to answer",
            value: "U",
        },
    ];

    const titles = [
        {
            label: "Dr.",
            value: "Dr.",
        },
        {
            label: "Mr.",
            value: "Mr.",
        },
        {
            label: "Mrs.",
            value: "Mrs.",
        },
        {
            label: "Ms.",
            value: "Ms.",
        },
        {
            label: "Prof.",
            value: "Prof.",
        },
    ];

    const getRelatedPeople = (id) => {
        PatientService.getRelatedPeople(id)
            .then((response => {
                const { status, data } = response;
                if (status == 200) {
                    setRelatedPeople(data);
                }
            }))
    }

    const handleSelectionFromList = (selectedItem) => {
        const data = JSON.parse(selectedItem);
        // console.log("Selected item:", data);
        setRequestedForId(data?.id)
        setCurrentUserDetails({
            "id": data?.id,
            "title": data?.title,
            "firstName": data?.firstName,
            "middleName": data?.middleName,
            "lastName": data?.lastName,
            "dateOfBirth": data?.dateOfBirth,
            "gender": data?.gender,
            "emailAddress": data?.emailAddress,
            "cellularPhoneNumber": data?.cellularPhoneNumber,
            "relationshipType": data?.relationshipType,
        })

        setCurrentStep('2');
    }

    const handleSubmit = (values) => {
        setIsLoading(true);
        let payload = {
            "title": values?.title,
            "firstName": values?.firstName,
            "middleName": values?.middleName,
            "lastName": values?.lastName,
            "dateOfBirth": values?.dateOfBirth
                ? new Date(values.dateOfBirth).toISOString().split("T")[0]
                : "",
            "gender": values?.gender,
            "emailAddress": values?.emailAddress,
            "cellularPhoneNumber": values?.cellularPhoneNumber,
            "relationshipType": values?.relationshipType,
        }
        PatientService.createRelatedPerson(currentUserId, payload)
            .then((response) => {
                const { status, data } = response;
                if (status === 201) {
                    setRequestedForId(data?.id)
                    setCurrentUserDetails({
                        "id": data?.id,
                        "title": data?.title,
                        "firstName": data?.firstName,
                        "middleName": data?.middleName,
                        "lastName": data?.lastName,
                        "dateOfBirth": data?.dateOfBirth,
                        "gender": data?.gender,
                        "emailAddress": data?.emailAddress,
                        "cellularPhoneNumber": data?.cellularPhoneNumber,
                        "relationshipType": values?.relationshipType,
                    })
                    setIsLoading(false);
                    setCurrentStep('2');
                } else {
                    setIsLoading(false);
                }
            })
    }

    useEffect(() => {
        getRelatedPeople(currentUserId);
    }, [currentUserId])


    return (
        <>
            <CardBody className={`nex-form-step active`}>
                {isLoading ?
                    <div className="loading-spiner text-center  mt-2">
                        <CommonLoader style={{ width: "2rem", height: "2rem" }} />
                    </div>
                    :
                    <>
                        <div className="nex-form-field-container">
                            <div className="nex-form-group nex-content-center">
                                <div className="nex-form-group-c mb-0">
                                    <label htmlFor="" className="nex-big-label">Choose new patient or select from Below list</label>

                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={handleSubmit}
                                        enableReinitialize={true}
                                    >
                                        <Form className='nex-patient-info-form'>
                                            <>
                                                <Row>
                                                    <Col lg={2}>
                                                        <Field
                                                            id="title"
                                                            name="title"
                                                            type="text"
                                                            label="Title"
                                                            component={FormikSelectField}
                                                            // value={initialValues?.title}
                                                            inputprops={{
                                                                name: "title",
                                                                options: titles,
                                                                defaultOption: "Select",
                                                                keys: {
                                                                    id: "value",
                                                                    label: "label",
                                                                },
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Field
                                                            title="firstName"
                                                            label="First Name"
                                                            name="firstName"
                                                            value={initialValues.firstName}
                                                            id="firstName"
                                                            type="text"
                                                            component={FormikInputField}
                                                            placeholder="First Name"
                                                        />
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Field
                                                            title="middleName"
                                                            label="Middle Name"
                                                            name="middleName"
                                                            value={initialValues.middleName}
                                                            id="middleName"
                                                            type="text"
                                                            component={FormikInputField}
                                                            placeholder="Middle Name"
                                                        />
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Field
                                                            title="lastName"
                                                            label="Last Name"
                                                            name="lastName"
                                                            id="lastName"
                                                            value={initialValues.lastName}
                                                            type="text"
                                                            component={FormikInputField}
                                                            placeholder="Last Name"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={3}>
                                                        <Field
                                                            id="relationshipType"
                                                            name="relationshipType"
                                                            type="text"
                                                            label="Relation Type"
                                                            component={FormikSelectField}
                                                            // value={initialValues?.title}
                                                            inputprops={{
                                                                name: "relationshipType",
                                                                options: relationshipTypes,
                                                                defaultOption: "Select",
                                                                keys: {
                                                                    id: "value",
                                                                    label: "label",
                                                                },
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Field
                                                            title="Abha Number"
                                                            label="Abha Number"
                                                            name="abhaNumber"
                                                            value={initialValues.abhaNumber}
                                                            id="abhaNumber"
                                                            type="text"
                                                            component={FormikInputField}
                                                            placeholder="Abha Number"
                                                        />
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Field
                                                            id="dateOfBirth"
                                                            name="dateOfBirth"
                                                            type="text"
                                                            component={FormikDatePicker}
                                                            inputprops={{
                                                                dateFormat: "yyyy-MM-dd",
                                                                showYearDropdown: true,
                                                                showMonthDropdown: true,
                                                                scrollableYearDropdown: true,
                                                                yearDropdownItemNumber: 10,
                                                                maxDate: new Date(),
                                                                autoComplete: "off",
                                                            }}
                                                            label="Date of Birth"
                                                            placeholder="YYYY-MM-DD"
                                                        />
                                                    </Col>
                                                    <Col lg={3}>
                                                        <Field
                                                            id="gender"
                                                            name="gender"
                                                            type="text"
                                                            label="Gender"
                                                            // value={initialValues.gender}
                                                            component={FormikSelectField}
                                                            inputprops={{
                                                                name: `gender`,
                                                                options: genders,
                                                                defaultOption: "Select",
                                                                keys: {
                                                                    id: "value",
                                                                    label: "label",
                                                                },
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </>
                                            <Row>
                                                <Col lg={12} className="text-center mb-0 mt-2 d-flex nex-form-action justify-content-end">
                                                <Button
                                    color="primary"
                                    className="nexogic_primary_button_outline"
                                    onClick={(e) => setCurrentStep('1')}
                                >
                                    Back
                                </Button>
                                                    <button type="submit" className="btn nexogic_primary_button">
                                                        Submit
                                                    </button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Formik>
                                    {relatedPeople && relatedPeople.length > 0 &&
                                        <>
                                            <div className="nex-or-txt">
                                                Or
                                            </div>

                                            <Row>
                                                <Col lg={12}>
                                                    <FormGroup>
                                                        <Input type="select" onChange={(e) => handleSelectionFromList(e.target.value)}>
                                                            <option>
                                                                Select from the list
                                                            </option>
                                                            {relatedPeople.map((person, index) => (
                                                                <option key={index} value={JSON.stringify(person)}>{getFullNameOfPerson(person.firstName, person.middleName, person.lastName)}</option>
                                                            ))}
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </>

                                    }
                                    
                                </div>
                            </div>
                        </div>
                        {/*
                        <div className="nex-form-group-c mb-2">
                            <div className="nex-form-action">
                                <Button
                                    color="primary"
                                    className="nexogic_primary_button_outline"
                                    onClick={(e) => setCurrentStep('1')}
                                >
                                    Back
                                </Button>
                            </div>
                                </div> */}
                    </>
                }
            </CardBody>
        </>
    )
}
export default CreateNewRelatedPerson;