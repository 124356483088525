import React from 'react'

export default function FormSteps (props) {

  return (
    <div className='row'>
        <div className="col-md-12 nexogic-form-tabs nexogic-sp-tabs">
            <ul className='list-group'>
                {props.data.map((item, ind) => (
                    <li className={`list-group-item ${item.active ? 'active' : ''} 
                    ${item.status == 'completed' ? 'completed' : ''} ${item.current ? 'current' : ''} `} key={'form_steps_'+ind}>
                    <a href='#' onClick={(e)=>{e.preventDefault(); props.stepSwitch(ind);}}>
                        {/*<span className='number'>{ind + 1}</span>*/}
                        <span className='info'>
                            <span className={`icon ${item.icon}`}></span>
                            <span className='card-title'>{item.name}</span>
                            {/*item.status != '' && 
                                <span className='card-text'><small>{item.status}</small></span>
                */}
                            <span className="line"><span className="arrow-start"></span><span className="arrow-end"></span></span>
                        </span>
                    </a>
                    </li>
                ))}
                
            </ul>
        </div>
    </div>
  )
}
