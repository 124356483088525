import React from "react"
import { Link } from "react-router-dom";
import { Card, Button, CardBody } from "reactstrap"
import { createImageFromInitials, getAge, getFullNameOfPerson, getGenderDetails, getRandomColor } from "utils/Utils"
import { encrypt } from "utils/EncryptDecrypt"
import { useHistory } from "react-router-dom"

// import messageIcon from 'assets/images/svgs/message_icon.svg';
// import videoIcon from 'assets/images/svgs/video_icon.svg';
// import videoCallIcon from 'assets/images/svgs/videocall_icon.svg';
// import playIcon from 'assets/images/svgs/play_icon.svg';
// import docPlaceholder from 'assets/images/doc_placeholder.png';
// import videoPlaceholder from 'assets/images/video_placeholder.png';
// import { useEffect } from "react"

export const PatientInfo = (props) => {
    const { patient, toggleAlert, setPatientIdToDelete } = props;
    const history = useHistory();

    const goToAddReferral = (patientDetails) => {
        history.push("/referrals/refer-patient", patientDetails);
    }

    const removePatientFromMyList = (patientDetails) => {
        // console.log("Patient Details................", patientDetails.id);
        if (patientDetails.id) {
            setPatientIdToDelete(patientDetails.id);
            // removePatientonConfirm();
            toggleAlert();
        }
    }

    return (
        <>
            <Card className="nexogic-list-item-card">
                <div className="card-body">
                    <div className="nexogic-graphic">
                        <img src={createImageFromInitials(100, patient.firstName + " " + patient.lastName, getRandomColor(encrypt(21).toString()))} className="nexogic-avatar lg border-0" alt="" />
                    </div>
                    <div className="nexogic-info">
                        <Card className="nexogic-key-val-card border-0 m-0">
                            <CardBody className="nexogic-key-val-col">
                                <div className="nexogic-key-val-group">
                                    <div className="key card-title">Name</div>
                                    <div className="val card-text"><a href={`/patient-profile/${patient.id}`}>{getFullNameOfPerson(patient.firstName, "", patient.lastName)}</a></div>
                                </div>
                                <div className="nexogic-key-val-group">
                                    <div className="key card-title">Phone No.</div>
                                    <div className="val card-text">{patient.cellularPhoneNumber ? patient.cellularPhoneNumber : 'Not Available'}</div>
                                </div>

                            </CardBody>
                            <CardBody className="nexogic-key-val-col">
                                {patient && patient?.dateOfBirth &&
                                    <div className="nexogic-key-val-group">
                                        <div className="key card-title">Age</div>
                                        <div className="val card-text">{patient?.dateOfBirth ? getAge(patient?.dateOfBirth) : 'Not Available'}</div>
                                    </div>
                                }
                                <div className="nexogic-key-val-group">
                                    <div className="key card-title">Gender</div>
                                    <div className="val card-text">{patient?.gender ? getGenderDetails(patient?.gender) : 'Not Available'}</div>
                                </div>

                            </CardBody>
                        </Card>

                    </div>
                    <div className="action">
                        {/* {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&                                */}
                        <Button outline className="nexogic_primary_button_outline" onClick={() => goToAddReferral(patient)}>Add Referral</Button>
                        {/* } */}
                        <Button className="nexogic_primary_button_red_light_outline btn" onClick={() => removePatientFromMyList(patient)}>Remove Patient</Button>
                    </div>
                    {/*  
                    <div className="row need-margin">
                        <div className="col patient-info">
                            <div className="head">
                                <div className="card-graphic"><img src={createImageFromInitials(100, patient.firstName + " " + patient.lastName, getRandomColor(encrypt(21).toString()))} className="rounded-circle" width="70" height="70" alt="" /></div>
                                <div className="info">

                                    <p className="card-text"><b>Name  </b>
                                        <Link to={`/patient-profile/${patient?.id}`}>
                                            {patient.firstName + " " + patient.lastName}
                                        </Link>
                                    </p>

                                    <p className="card-text"><b>Phone  </b> {patient.cellularPhoneNumber ? patient.cellularPhoneNumber : 'Not Available'}</p>

                                </div>
                            </div>
                        </div>
                        <div className="col patient-info">

                            <div className="head">
                                <div className="info">
                                    {patient && patient?.dateOfBirth &&
                                        <p className="card-text"><b>Age  </b>   {patient?.dateOfBirth && getAge(patient?.dateOfBirth)}</p>
                                    }
                                    <p className="card-text"><b>Gender  </b> {patient.gender === 'M' ? 'Male' : ''} {patient.gender === 'F' ? 'Female' : ''}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col btn-groups-w text-right">
                            {process.env.REACT_APP_HIDE_STATIC_CONTENT === "false" &&                               
                            <Button outline className="nexogic_primary_button_outline" onClick={() => goToAddReferral(patient)}>Add Referral</Button>
                             } 
                            <Button className="nexogic_primary_button_red_light_outline btn" onClick={() => removePatientFromMyList(patient)}>Remove Patient</Button>
                        </div>

                    </div>
                            */}
                </div>
            </Card >
        </>
    )
}

export default PatientInfo