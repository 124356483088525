import React from 'react';
import { useEffect } from 'react';
import { OpinionService } from 'utils/OpinionService';
import { useState } from 'react';
import OpinionInfo from './OpinionInfo';
import { FAILED_TO_FETCH_DATA } from 'utils/Constants';
import LoadMoreButton from 'common/LoadMoreButton/LoadMoreButton';
import CommonLoader from 'common/CommonLoader';
import { Col } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faIdCard, faListAlt, faTable } from "@fortawesome/free-solid-svg-icons";
import ReceivedSecondOpinionsTabularView from 'components/tabularView/ReceivedSecondOpinionsTabularView';

export default function ReceivedSecondOpinionLists(props) {
    const [opinions, setOpinions] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [filters, setFilters] = useState([]);
    const [viewMode, setViewMode] = useState("card");

    function createQuery(filters) {
        // setIsLoading(true);
        let sq = '';
        filters.map((e) => {
            if (sq === '') {
                sq = "st=='" + e + "'";
            } else {
                sq += ",st=='" + e + "'";
            }
        });
        return sq;
    }

    const getOpinions = (pageNo, filters) => {
        let fsq = createQuery(filters);
        OpinionService.getOpinions(fsq, pageNo)
            .then((response) => {
                const { data, status } = response;
                if (data?.currentPage < (data?.totalPages - 1))
                    setHasMore(true);
                else
                    setHasMore(false);
                setIsLoadingMore(false);
                if (status === 200) {
                    if (pageNo === 0)
                        setOpinions(data.contents);
                    else
                        setOpinions([...opinions, ...data.contents]);

                    setCurrentPage(data?.currentPage);
                    setIsLoading(false);
                } else {
                    setIsLoadingMore(false);
                    console.log(FAILED_TO_FETCH_DATA);
                    setIsLoading(false);
                }
            });
    };

    useEffect(() => {
        getOpinions(0, filters);
    }, [filters]);

    const fetchMoreData = () => {
        setIsLoadingMore(true);
        getOpinions(currentPage + 1, filters);
    };

    const renderOpinions = () => {
        if (viewMode === 'card') {
            return (
                <>
                    {opinions.map((opinion, index) => (
                        <OpinionInfo key={opinion.id} opinion={opinion} />
                    ))}
                </>
            );
        } else { // For table view
            return (
                <ReceivedSecondOpinionsTabularView theader={['Case Id', 'Patient Name', 'Gender', 'Speciality', 'Status']} tdata={opinions} viewmode={viewMode} type={"RECEIVED"} />
            );
        }
    };

    if (isLoading) {
        return (
            <div className="text-center mt-3">
                <CommonLoader style={{ width: "2rem", height: "2rem" }} />
            </div>
        );
    }


    return (
        <>
            <div className="nexogic-second-option container-fluid">
                <div className="row">
                    <Col lg={12} xl={12} sm={12} className="nexogic-second-option-left pt-mobile-0">
                        <h1 className='nexogic-page-title mt-mobile-0'>Received Second Opinion Requests </h1>
                        <div className="view-toggle nexogic-view-toggle nex-second-opt-view-toggle">
                            <div className='btns'>
                                <FontAwesomeIcon title="Card View" color={viewMode === "card" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faAddressCard} onClick={() => setViewMode("card")} /> | <FontAwesomeIcon title="Table View" color={viewMode === "table" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faListAlt} onClick={() => setViewMode("table")} />
                            </div>
                        </div>
                        {opinions.length > 0 ? <>
                            {renderOpinions()}
                        </> : (
                            <div className="nexogic-not-found-text">No Second Opinion Request</div>
                        )}
                        {hasMore && (
                            <div className="text-center mt-3">
                                {isLoadingMore ? (
                                    <CommonLoader style={{ width: "2rem", height: "2rem" }} />
                                ) : (
                                    <LoadMoreButton loadMore={fetchMoreData} />
                                )}
                            </div>
                        )}
                    </Col>
                </div>
            </div>
        </>
    );
}
