import CommonLoader from "common/CommonLoader";
import { useEffect, useState } from "react";
import { withAlert } from "react-alert";
import { useSelector } from "react-redux";
import { Row, Button } from "reactstrap";
import { ConnectionServices } from "utils/ConnectionServices";
import NoConnection from "../../NoConnection";
import { CreateGroupModal } from "./CreateGroupModal";
import GroupItem from "./GroupItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CoverImage from "assets/images/defaultBannerPage.png";
import LoadMoreButton from "common/LoadMoreButton/LoadMoreButton";
import { faAddressCard, faIdCard, faListAlt, faTable } from "@fortawesome/free-solid-svg-icons";
import GroupsOwnedTabularView from "components/tabularView/GroupsOwnedTabularView";

const GroupOwned = (props) => {
  const { activeTab, toggleCGModal, isCGOpen } = props;
  const [groupsList, setGroupsList] = useState([]);
  const { alert } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [viewMode, setViewMode] = useState("card");

  const [list_of_data, setList_of_data] = useState(null);
  const onShowDefaultImage = (guidId, imageId) => {
    let imageSet = CoverImage;
    if (guidId && imageId) {
      imageSet = `${process.env.REACT_APP_IMAGE_BASE_URL}/${guidId}/${imageId}`;
    }
    return imageSet;
  };

  const currentUser = useSelector((state) => state.auth.currentUser);
  const [selectedItem, setSelectedItem] = useState({
    groupName: "",
    privacy: "PRIVATE",
    status: "LISTED",
    groupDescription: "",
    rules: "",
    bannerId: "",
  });
  const initialVal = {
    groupName: "",
    privacy: "PRIVATE",
    status: "LISTED",
    groupDescription: "",
    rules: "",
    bannerId: "",
  };
  const getData = async (pageNo, feedlist) => {
    const request = ConnectionServices.getGroups(
      currentUser.guid,
      "ADMIN",
      pageNo
    );
    request
      .then(async (response) => {
        // console.log(response);
        let { data } = response;
        // console.log(data);
        if (data?.currentPage < (data?.totalPages - 1))
          setHasMore(true);
        else
          setHasMore(false);
        setIsLoadingMore(false);
        if (pageNo === 0) {
          const allData = { ...data };
          // console.log(allData);
          setList_of_data(allData);
          setIsLoading(false);
        } else {
          const resolved = await Promise.all(
            data.contents.map(async (item) => {
              const each = { ...item };
              return each;
            })
          );
          feedlist = feedlist.concat(resolved);
          setList_of_data({ ...data, contents: feedlist });
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoadingMore(false);
        console.log("Error while getting groups list: ", error);
      });
  };

  const fetchData = () => {
    setIsLoadingMore(true);
    getData(list_of_data.currentPage + 1, list_of_data.contents);
  };

  const handleEditItem = (item) => {
    setSelectedItem({
      id: item?.id ?? "",
      groupName: item?.name ?? "",
      privacy: item?.privacy ?? "PRIVATE",
      status: item?.isVisible ? "LISTED" : "UNLISTED",
      groupDescription: item?.description ?? "",
      rules: item?.rules ?? "",
      bannerId: item?.bannerId ?? "",
      bannerUrl: onShowDefaultImage(item?.id, item?.bannerId),
    });
    toggleCGModal();
  };

  useEffect(() => {
    getData(0, []);
  }, []);

  if (isLoading) {
    return (
      <div className="loading-spiner text-center  mt-2">
        <CommonLoader style={{ width: "2rem", height: "2rem" }} />
      </div>
    );
  }

  const renderProfiles = () => {
    if (viewMode === 'card') {
      return (
        <>
        <Row>
          {list_of_data &&
            list_of_data.contents.map((e, i) => (
              <GroupItem
                key={i}
                group={e}
                activeTab={activeTab}
                handleEditItem={handleEditItem}
              />
            ))}
            </Row>
        </>
      );
    } else { // For table view
      return (
        <GroupsOwnedTabularView theader={['Name', 'Description', 'Edit']} tdata={list_of_data.contents} viewmode={viewMode} handleEditItem={handleEditItem} />
      );
    }
  };

  return (
    <>
      

      <div className="nexogic-groups-top-action">
      <div className="view-toggle nexogic-view-toggle">
        <div className="btns mr-3">
          <FontAwesomeIcon title="Card View" color={viewMode === "card" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faAddressCard} onClick={() => setViewMode("card")} /> | <FontAwesomeIcon title="Table View" color={viewMode === "table" ? "#0087b9" : "#9c9f9f80"} style={{ cursor: "pointer" }} icon={faListAlt} onClick={() => setViewMode("table")} />
        </div>
        <Button
          id="nex-go-connect-add-btn"
          color="link"
          className="nexogic_primary_button "
          onClick={() => handleEditItem(initialVal)}
        >
          <FontAwesomeIcon icon={faPlus} /> Add
        </Button>
      </div>
        
      </div>
      <div className="card-group nexogic-card-group-list nexogic-group-own-col card nexogic-connection-card nexogic-custom-scroll">
        {
          list_of_data && list_of_data.contents.length > 0 ? (
            renderProfiles()
          ) : (
            <div className="loading-spiner text-center mt-5">
              <NoConnection content={"You don't own any group"} />
            </div>)
        }
        {hasMore &&
          <div className="text-center mt-3">
            {isLoadingMore
              ?
              <CommonLoader style={{ width: "2rem", height: "2rem" }} />
              :
              <LoadMoreButton loadMore={fetchData} />
            }
          </div>
        }
      </div>
      <CreateGroupModal
        isOpen={isCGOpen}
        toggle={toggleCGModal}
        selectedItem={selectedItem}
        handleEditItem={setSelectedItem}
        setGroupsList={setGroupsList}
        getData={getData}
      />
    </>);
};

GroupOwned.defaultProps = {
  toggleCGModal: () => { },
  isCGOpen: false,
};

export default withAlert()(GroupOwned);
