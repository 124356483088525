//ThemeMenus.js

import React,{useRef} from 'react';
import { Card, Col, Row } from 'reactstrap';
import ContentsCard from './ContentsCard';
import { useDrag } from 'react-dnd';
import './style.css';

const ThemeMenus = ({sectionDetails}) => {    
    const itemTypes = "ContentsCard"

    const [{ isDragging }, drag] = useDrag({
        type:itemTypes,
		item: {id: sectionDetails.id},
		collect: monitor => ({
			isDragging: monitor.isDragging(),
		}),
	});

    // const renderImage = (image, index) => {
    //     console.log("render image:", {image})
    //   return image ? (
    //     <Image
    //       image={image}
    //       index={index}
    //       key={`${image.id}-image`}
    //       moveImage={moveImage}
    //     />
    //   ): null;
    // };


    // return <section className="file-list">{images.map(renderImage)}</section>;

    return (
        <Row className="">
            <Col md={12} lg={12} className='h-100'>
                <Card className='vheight'>
                    {sectionDetails.map((sElements, index) => {
                       return <ContentsCard key={sElements.id} sectionDetails={sElements} opacity={isDragging ? '0.5' : '1'}/>
                    })}
                </Card>
            </Col>
        </Row>
    )
}

export default ThemeMenus