import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddressTypeaheadField from "components/forms/formikFields/AddressTypeaheadField";
import FormikCheckboxField from "components/forms/formikFields/FormikCheckboxField";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikMobileField from "components/forms/formikFields/FormikMobileField";
import FormikSelectField from "components/forms/formikFields/FormikSelectField";
import LocationTypeaheadField from "components/forms/formikFields/LocationTypeaheadField";
import PrimarySpecialityTypeaheadField from "components/forms/formikFields/PrimarySpecialityTypeaheadField";
import YearTypeaheadField from "components/forms/formikFields/YearTypeaheadField";
import { Field, Formik } from "formik";
import { isEmpty } from "lodash";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { withAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Col, InputGroup, Row } from "reactstrap";
import { authSetUser } from "redux-helpers/ActionCreator";
import {
  emailNonNumericRegEx,
  onlyAlphabets,
  TIME_CONSTANTS,
} from "utils/Constants";
import { IdentityService } from "utils/IdentityService";
import { MetadataService } from "utils/MetadataService";
import { PractitionerService } from "utils/PractitionerService";
import {
  setOAuthTokens,
  setOAuthUser,
  setUser,
  updateAccessToken,
} from "utils/UserHelper";
import { cleanUpPhoneNumber } from "utils/Utils";
import * as Yup from "yup";

const years = TIME_CONSTANTS.YEARS();

const titles = [
  {
    label: "Dr.",
    value: "Dr.",
  },
  {
    label: "Mr.",
    value: "Mr.",
  },
  {
    label: "Mrs.",
    value: "Mrs.",
  },
  {
    label: "Ms.",
    value: "Ms.",
  },
  {
    label: "Prof.",
    value: "Prof.",
  },
];

const phoneRegExp =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{5})(( x| ext)\d{1,5}){0,1}$/;
const validationSchema = Yup.object().shape({
  title: Yup.string().required("This field is required"),
  firstName: Yup.string().when("claimsProfileId", {
    is: (val) => val === undefined,
    then: Yup.string()
      .min(2)
      .max(30)
      .matches(onlyAlphabets, "Only Alphabets")
      .required("This field is required"),
    otherwise: Yup.string(),
  }),
  lastName: Yup.string().when("claimsProfileId", {
    is: (val) => val === undefined,
    then: Yup.string()
      .min(2)
      .max(30)
      .matches(onlyAlphabets, "Only Alphabets")
      .required("This field is required"),
    otherwise: Yup.string(),
  }),
  primarySpeciality: Yup.array().when("claimsProfileId", {
    is: (val) => val === undefined,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required")
      .nullable(),
    otherwise: Yup.array().nullable(),
  }),
  practicingSince: Yup.string().when("claimsProfileId", {
    is: (val) => val === undefined,
    then: Yup.string().required("This field is required"),
    otherwise: Yup.string(),
  }),
  streetAddress1: Yup.string(),
  streetAddress2: Yup.string(),
  country: Yup.array().when("claimsProfileId", {
    is: (val) => val === undefined,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required")
      .nullable(),
    otherwise: Yup.array().nullable(),
  }),
  state: Yup.array().when("claimsProfileId", {
    is: (val) => val === undefined,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required")
      .nullable(),
    otherwise: Yup.array().nullable(),
  }),
  city: Yup.array().when("claimsProfileId", {
    is: (val) => val === undefined,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required")
      .nullable(),
    otherwise: Yup.array().nullable(),
  }),
  location: Yup.array().when("claimsProfileId", {
    is: (val) => val === undefined,
    then: Yup.array()
      .min(1, "This field is required")
      .required("This field is required")
      .nullable(),
    otherwise: Yup.array().nullable(),
  }),
  zipCode: Yup.string().when("claimsProfileId", {
    is: (val) => val === undefined,
    then: Yup.string().max(10).required("This field is required").nullable(),
    otherwise: Yup.string(),
  }),
  rememberMe: Yup.boolean().oneOf(
    [true],
    "Accept Terms & Conditions is required "
  ),
  phone: Yup.string()
    .matches(phoneRegExp, "Mobile number is not valid")
    .required("This field is required"),
  email: Yup.string()
    .max(50)
    .matches(emailNonNumericRegEx, "Please Enter an valid Email")
    .required("This field is required"),
  password: Yup.string()
    .min(8)
    .max(32)
    .required("This field is required")
    .matches(
      /^(?=(.*[a-z]){3,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,}).{8,}$/,
      "Too weak. Try to add lower-case, upper-case, numbers, special characters, 8 or more characters"
    )
    .matches(
      /(?=(.*[!@#$%^&*()\-__+.]){1,})/,
      "Moderate. Try to add aleast 1 special characters or symbols"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  claimsProfileId: Yup.string(),
});

const PersonalDetailsForm = (props, onNext) => {
  const [guid, setGuid] = useState("");
  const { state } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedAddress, setSelectedAddress] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const [userProfileId, setUserProfileId] = useState("");
  const initValues = useMemo(() => {
    return {
      title: titles[0].value,
      firstName: "",
      lastName: "",
      primarySpeciality: "",
      practicingSince: "",
      streetAddress1: "",
      streetAddress2: "",
      country: "",
      state: "",
      city: "",
      zipCode: "",
      location: "",
      phone: "",
      email: "",
      password: "",
      confirmPassword: "",
      rememberMe: false,
      claimsProfileId: "",
    };
  }, []);

  const [initialValues, setInitialValues] = useState(initValues);

  useEffect(() => {
    if (state !== undefined) {
      if (state && state?.profileId) {
        setUserProfileId(state?.profileId);
      }
      setGuid(state.guid);
      getVerificationMode(state.guid, props);
    }
  }, [state]);

  const getClaimsProfile = useCallback((id) => {
    PractitionerService.getClaimsProfile(id)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          if (response?.data) {
            const {
              title,
              firstName,
              lastName,
              primarySpeciality,
              practicingSince,
            } = response?.data;
            setInitialValues((preState) => {
              return {
                ...preState,
                title: title ?? "",
                firstName: firstName ?? "",
                lastName: lastName ?? "",
                primarySpeciality: primarySpeciality ? [primarySpeciality] : [],
                practicingSince: practicingSince ?? "",
                claimsProfileId: id ?? "",
              };
            });
          }
        }
      })
      .catch((error) =>
        console.log("PractitionerService.getClaimsProfile error", error)
      );
  }, []);

  const getVerificationMode = useCallback(
    (guid, props) => {
      IdentityService.GetVerificationMode(guid, props)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            if (response.data.verificationMode === "PHONE") {
              setInitialValues((preState) => {
                return {
                  ...preState,
                  phone: response.data.phoneNumber,
                };
              });
            }
            if (response.data.verificationMode === "EMAIL") {
              setInitialValues((preState) => {
                return {
                  ...preState,
                  email: response.data.emailAddress,
                };
              });
            }
          }
          if (response.status === 404) {
            // history.push("/login");
          }
        })
        .catch((error) => {
          console.log("IdentityService.GetVerificationMode error", error);
        });
      if (state && state?.profileId) {
        getClaimsProfile(state?.profileId);
      }
    },
    [getClaimsProfile, state, history]
  );

  const onSubmit = (values) => {
    let selectedLocation = values.location[0];
    if (values.location[0] != undefined) {
      selectedLocation = values.location[0];
      if (values.location[0].customOption) {
        selectedLocation = values.location[0].value;
      }
    }
    const payload = {
      address: {
        addressLine1: values.streetAddress1,
        addressLine2: values.streetAddress2,
        city: selectedAddress.city,
        country: selectedAddress.country,
        postalCode: values.zipCode,
        state: selectedAddress.state,
        location: selectedLocation,
      },
      confirmPassword: values.confirmPassword,
      emailAddress: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      practicingSince: values.practicingSince,
      password: values.password,
      phoneNumber: cleanUpPhoneNumber(values.phone),
      primarySpeciality: values.primarySpeciality[0],
      title: values.title,
      profileId: userProfileId ?? "",
    };
    setSubmitting(true);
    IdentityService.AddPersonalDetails(payload, guid)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          const data = response.data;
          updateAccessToken(data.accessToken);
          setOAuthTokens(data.accessToken, data.refreshToken);
          const user = {
            uuid: data.uuid,
            type: data.type,
            guid: data.guid,
          };

          IdentityService.GetUserDetails(data.accessToken, data.guid)
            .then((userRes) => {
              if (userRes.status === 200) {
                // const { authorities } = userRes.data;
                setOAuthUser(userRes.data);
              } else {
                setOAuthUser({ guid: data.guid });
              }
            })


          dispatch(authSetUser(user));
          setUser(user);
          // gePractitionersDetails(data.guid);
          props.onNext();
        } else if (
          response.status === 404 &&
          response.message.includes("No resource profile found with ID")
        ) {
          props.alert.error(
            "Session expired. Please validate OTP for registration."
          );
          history.push(`/register`);
        } else {
          if (response.validationErrors) {
            response.validationErrors.map((err) => {
              props.alert.error(`${err.field}: ${err.message}`);
            });
          } else if (response.message) {
            props.alert.error(response.message);
          }
        }
        setSubmitting(false);
      })
      .catch((error) => {
        console.log("IdentityService.RegisterNewUser error", error);
        props.alert.error("Server facing problem. Please try after some time.");
        setSubmitting(false);
      })
      .then(() => {
        setSubmitting(false);
      });
  };

  const getAddressDetails = (address) => {
    setSelectedAddress(address);
  };

  const gePractitionersDetails = (guid) => {
    PractitionerService.gePractitionersDetails(guid)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          dispatch(authSetUser(response.data));
          setUser(response.data);
          // props.onNext(response.data);
        }
      })
      .catch((error) => {
        console.log("PractitionerService.gePractitionersDetails error", error);
        props.alert.error(error.message);
      });
  };

  return (
    <>
      <p className="nexogic-register-timer ">
        Please submit this form in <Timer /> to avoid session expire.
      </p>
      <div className="nexogic-onboarding-form nexogic-form-content">

        <div className="d-none">
          <h3 className="nexogic-form-title mb-1">
            {userProfileId ? "Create your user" : "Personal information"}
          </h3>
          <p className="nexogic-form-title-sub mb-2">
            {userProfileId
              ? "Register your user information"
              : "Provide your personal information to start your registration"}
          </p>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={onSubmit}
          children={(props) => {
            return (
              <>
                <RenderForm
                  {...props}
                  isSubmitting={isSubmitting}
                  addressDetails={getAddressDetails}
                  userProfileId={userProfileId}
                />
              </>
            );
          }}
        />
      </div>
    </>
  );
};

export default withAlert()(PersonalDetailsForm);

const Timer = () => {
  const [minutes, setMinutes] = useState(9);
  const [seconds, setSeconds] = useState(59);
  const [counter, setCounter] = useState("");
  const [stopTimer, setStopTimer] = useState(false);
  const history = useHistory();
  useEffect(() => {
    TimerCountdown(counter, minutes, seconds);
  }, [counter, minutes, seconds]);

  const TimerCountdown = (counter, minutes, seconds) => {
    !stopTimer &&
      setTimeout(() => {
        if (seconds === 0) {
          setSeconds(59);
          setMinutes(minutes - 1);
        } else {
          setSeconds(seconds - 1);
        }
        setCounter(minutes + " min  " + seconds + " sec");
        if (minutes === 0 && seconds === 1) {
          setStopTimer(true);
          history.push("/register");
        }
      }, 1000);
  };
  return <span className="counter-span">{counter}</span>;
};

const RenderForm = (props) => {
  const {
    values,
    errors,
    isSubmitting,
    handleSubmit,
    addressDetails,
    userProfileId,
  } = props;

  const [showPassword, onShowPassword] = useState(false);
  const toggleShowPassword = () => onShowPassword(!showPassword);
  const [countryList, setCountyList] = useState([]);
  const [statesList, setSatesList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [primarySpecialityList, setPrimarySpecialityList] = useState([]);
  const [iseditable, setisEditable] = useState(true);
  const stateRef = useRef();
  const cityRef = useRef();
  const countryRef = useRef();
  const locRef = useRef();

  useEffect(() => {
    getCountries();
    getprimarySpeciality();
  }, []);

  const getCountries = () => {
    setSatesList([]);
    setCityList([]);
    setLocationList([]);
    MetadataService.getCountry()
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setCountyList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getCountries error", error);
      });
  };

  const getStates = (countryId) => {
    setSatesList([]);
    setCityList([]);
    setLocationList([]);
    getselectedAddress(countryList, countryId, "COUNTRY");
    if (countryId == "") {
      return false;
    }

    MetadataService.getStates(countryId)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setSatesList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getStates error", error);
      });
  };

  const getCities = (stateId) => {
    setCityList([]);
    setLocationList([]);
    getselectedAddress(statesList, stateId, "STATE");
    if (stateId == "") {
      return false;
    }
    MetadataService.getCities(stateId)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setCityList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getCities error", error);
      });
  };

  const getLocations = (cityId) => {
    setLocationList([]);
    getselectedAddress(cityList, cityId, "CITY");
    if (cityId == "") {
      return false;
    }
    MetadataService.getLocations(cityId)
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setLocationList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getCities error", error);
      });
  };

  const getprimarySpeciality = () => {
    MetadataService.getPrimarySpecialities()
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          setPrimarySpecialityList(response.data);
        }
      })
      .catch((error) => {
        console.log("IdentityService.getprimarySpeciality error", error);
      });
  };

  const [address, setAddress] = useState({});
  const getselectedAddress = (array, id, type) => {
    let filterValue = "";
    if (!isEmpty(values.country)) {
      array.map((obj) => {
        if (obj.id == id) {
          filterValue = obj.value;
        }
      });
    }
    if (type == "COUNTRY") {
      setAddress({
        ...address,
        country: filterValue,
        state: "",
        city: "",
        location: "",
      });
      values.state = [];
      values.city = [];
      values.location = [];
    } else if (type == "STATE") {
      setAddress({
        ...address,
        state: filterValue,
        city: "",
        location: "",
      });
      values.city = [];
      values.location = [];
    } else if (type == "CITY") {
      setAddress({
        ...address,
        city: filterValue,
        location: "",
      });
      values.location = [];
    }
  };

  const selectdLocation = (locationName) => {
    setAddress({
      ...address,
      location: locationName,
    });
  };

  const copyDisable = (fieldName) => {
    const passwordInput = document.getElementById(fieldName);
    passwordInput.onpaste = (e) => {
      e.preventDefault();
      return false;
    };
    passwordInput.oncut = (e) => {
      e.preventDefault();
      return false;
    };
    passwordInput.oncopy = (e) => {
      e.preventDefault();
      return false;
    };
  };

  useEffect(() => {
    copyDisable("password");
    copyDisable("confirmPassword");
  }, []);

  useEffect(() => {
    if (userProfileId) {
      setisEditable(false);
    }
  }, [userProfileId]);

  return (
    <div>
      <form className="personal-details-form" onSubmit={handleSubmit}>
        {userProfileId === "" && (
          <>
            <fieldset className="u-flsd-wpr mb-3 nex-fieldset-field-sec">
              <legend className="nex-legend-personal-details">Personal informations</legend>
              <p className="nexogic-form-title-sub mb-2">Provide your personal information to start your registration</p>
              <Row>
                
                <Col md={6} className="w-40 nex-marger-title-field">
                <Field
                    id="title"
                    name="title"
                    type="text"
                    label=""
                    component={FormikSelectField}
                    inputprops={{
                      name: "title",
                      options: titles,
                      keys: {
                        id: "value",
                        label: "label",
                      },
                    }}
                    disabled={!iseditable}
                    autoComplete="phone"
                  />
                  <Field
                    id="firstName"
                    name="firstName"
                    autoComplete="firstName"
                    type="text"
                    component={FormikInputField}
                    label="First Name"
                    placeholder="Your first name"
                    disabled={!iseditable}
                  />
                </Col>
                <Col md={6} className="w-40">
                  <Field
                    id="lastName"
                    name="lastName"
                    autoComplete="lastName"
                    type="text"
                    component={FormikInputField}
                    label="Last Name"
                    placeholder="Your last name"
                    disabled={!iseditable}
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="form-group" id="nex-pdf-primary-speciality">
                    <Field
                      id="primarySpeciality"
                      name="primarySpeciality"
                      autoComplete="primarySpeciality"
                      label="Primary Speciality"
                      className="primary-specialities"
                      type="text"
                      component={PrimarySpecialityTypeaheadField}
                      placeholder="Select Speciality"
                      inputprops={{
                        autoComplete: "off",
                      }}
                      value={values.primarySpeciality}
                      spellCheck={false}
                      specialit={primarySpecialityList}
                      disabled={!iseditable}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-group" id="nex-pdf-practicing-since">
                    <Field
                      name="practicingSince"
                      autoComplete="practicingSince"
                      id="practicingSince"
                      type="text"
                      label="Practicing Since"
                      component={YearTypeaheadField}
                      placeholder="YYYY"
                      inputprops={{
                        autoComplete: "off",
                      }}
                      value={values.practicingSince}
                      spellCheck={false}
                      years={years}
                      disabled={!iseditable}
                    />
                  </div>
                </Col>
              </Row>
            </fieldset>
            <fieldset className="u-flsd-wpr mb-3 nex-address-fieldset-sec nex-fieldset-field-sec">
              <legend>Address Information</legend>
              <Row>
                <Col lg={6}>
                  <Field
                    id="streetAddress1"
                    name="streetAddress1"
                    autoComplete="streetAddress1"
                    type="text"
                    component={FormikInputField}
                    label="Street Address"
                    placeholder="Current address"
                    inputprops={{
                      autoComplete: "off",
                    }}
                  />
                </Col>
                <Col lg={6}>
                  <Field
                    id="streetAddress2"
                    name="streetAddress2"
                    autoComplete="streetAddress2"
                    type="text"
                    component={FormikInputField}
                    label="Street Address 2"
                    placeholder="Other Address"
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <div className="form-group" id="nex-pdf-country">
                    <Field
                      id="country"
                      name="country"
                      autoComplete="country"
                      type="text"
                      component={AddressTypeaheadField}
                      label="Country"
                      value={values.country}
                      placeholder="Select country"
                      spellCheck={false}
                      inputList={countryList}
                      getAddressById={(contryId) => getStates(contryId)}
                      locationRef={countryRef}
                      inputprops={{
                        autoComplete: "off",
                      }}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form-group" id="nex-pdf-state">
                    <Field
                      id="state"
                      name="state"
                      type="text"
                      autoComplete="state"
                      component={AddressTypeaheadField}
                      label="State"
                      inputprops={{
                        autoComplete: "off",
                      }}
                      value={values.state}
                      placeholder="Select state"
                      spellCheck={false}
                      inputList={statesList}
                      getAddressById={(stateId) => getCities(stateId)}
                      locationRef={stateRef}
                    />
                  </div>
                </Col>
                
              </Row>
              <Row>
              <Col lg={6}>
                  <div className="form-group" id="nex-pdf-city">
                    <Field
                      id="city"
                      name="city"
                      type="text"
                      component={AddressTypeaheadField}
                      label="City"
                      inputprops={{
                        autoComplete: "off",
                      }}
                      value={values.city}
                      placeholder="Select city"
                      spellCheck={false}
                      inputList={cityList}
                      getAddressById={(cityId) => getLocations(cityId)}
                      locationRef={cityRef}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <Field
                    id="zipCode"
                    name="zipCode"
                    autoComplete="zipCode"
                    type="text"
                    component={FormikInputField}
                    label="Zip"
                    placeholder="Enter code"
                  />
                </Col>
                <Col lg={6}>
                  <div className="form-group" id="nex-pdf-location">
                    <Field
                      id="location"
                      name="location"
                      type="text"
                      component={LocationTypeaheadField}
                      label="Location"
                      inputprops={{
                        autoComplete: "off",
                      }}
                      value={values.location}
                      placeholder="Select Location"
                      spellCheck={false}
                      locations={locationList}
                      selectdLocation={selectdLocation}
                      locationRef={locRef}
                    />
                  </div>
                </Col>
              </Row>
            </fieldset>
          </>
        )}
        <fieldset className="u-flsd-wpr mb-3 nex-login-info-field-sec nex-fieldset-field-sec">
          <legend>Login Information</legend>
          <Row>
            <Col lg={6}>
              <div className="nexogic-phone-field-wrap">
                <Field
                  id="phone"
                  name="phone"
                  autoComplete="phone"
                  type="text"
                  component={FormikMobileField}
                  label="Mobile Number"
                  placeholder="Enter number"
                  value={props.initialValues.phone}
                  disabled={props.initialValues.phone === "" ? false : true}
                />
              </div>
            </Col>
            <Col lg={6}>
              <Field
                id="email"
                name="email"
                autoComplete="email"
                type="text"
                component={FormikInputField}
                label="Email Address"
                placeholder="Enter email"
                disabled={props.initialValues.email === "" ? false : true}
              />
              <div className="nex-field-info">** This is your  username</div>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <div className="nexogic-password-field-wrap">
                <div className="password-eye">
                  <Field
                    id="password"
                    name="password"
                    autoComplete="password"
                    type={showPassword ? "text " : "password"}
                    component={FormikInputField}
                    label="Create Password"
                    placeholder="Password"
                  />
                  {!!errors.password === false && (
                    <FontAwesomeIcon
                      onClick={toggleShowPassword}
                      className="show-password"
                      color="#ccc"
                      icon={!showPassword ? faEyeSlash : faEye}
                    />
                  )}
                </div>
              </div>
            </Col>
            <Col>
              <div className="nexogic-password-field-wrap">
                <div className="password-eye">
                  <Field
                    id="confirmPassword"
                    name="confirmPassword"
                    autoComplete="confirmPassword"
                    type={showPassword ? "text " : "password"}
                    component={FormikInputField}
                    label="Confirm Password"
                    placeholder="Password"
                  />
                  {!!errors.password === false && (
                    <FontAwesomeIcon
                      onClick={toggleShowPassword}
                      className="show-password"
                      color="#ccc"
                      icon={!showPassword ? faEyeSlash : faEye}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </fieldset>
        {/*<fieldset className="u-flsd-wpr mb-3">*/}
          {/*<legend>Set Password</legend>*/}
          
        {/*</fieldset>*/}
        <InputGroup className="mt-3 align-items-center terms-condition-block card-text fw-semibold rememberMe-wrap">
          <Field
            id="rememberMe"
            name="rememberMe"
            type="checkbox"
            component={FormikCheckboxField}
          />

          <label
            htmlFor="rememberMe"
            className="form-check-label f-14 line-h-24 text-center"
          >
            I agree to the
          </label>
          <span className="font-weight-bold text-primary f-14 policy">
            <a
              href="https://nexogic.com/terms-and-conditions/"
              target="_blank"
              rel="noreferrer"
            >
              &nbsp;Terms and Conditions&nbsp;
            </a>
          </span>
          <span className="form-check-label f-14 line-h-24 text-center">
            {" "}
            and{" "}
          </span>
          <span className="font-weight-bold text-primary f-14 policy">
            <a
              href="https://nexogic.com/privacy-policy/"
              target="_blank"
              rel="noreferrer"
            >
              &nbsp; Privacy Policy&nbsp;
            </a>
          </span>
          <span className="form-check-label f-14 line-h-24 text-center">
            {" "}
            of Nexogic
          </span>
        </InputGroup>

        <Button
          id="nex-pdf-form-next"
          className="mt-4 d-block ml-auto nexogic_primary_button next-btn"
          type="submit"
          disabled={isSubmitting}
          onClick={() => addressDetails(address)}
        >
          Next
        </Button>
      </form>
    </div>
  );
};
