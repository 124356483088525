import React, { useState, useEffect } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Button, Nav, NavItem } from 'reactstrap';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { ReferralService } from 'utils/ReferralService';
import { FAILTOSAVERECOED } from 'utils/Constants';

export default function RefaralAsideFilter(props) {
    const location = useLocation();
    const [currentRouteUrl, setCurrentRouteUrl] = useState("");

    const { setQuery, insSelected } = props;

    const [checkAllInReceived, setCheckAllInReceived] = useState(false);
    const [checkPendingInReceived, setCheckPendingInReceived] = useState(false);
    const [checkAcceptedInReceived, setCheckAcceptedInReceived] = useState(false);
    const [checkRejectedInReceived, setCheckRejectedInReceived] = useState(false);

    const [checkAllInSent, setCheckAllInSent] = useState(false);
    const [checkPendingInSent, setCheckPendingInSent] = useState(false);
    const [checkAcceptedInSent, setCheckAcceptedInSent] = useState(false);
    const [checkRejectedInSent, setCheckRejectedInSent] = useState(false);

    const [sentReferralsCount, setSentReferralsCount] = useState([]);
    const [receivedReferralsCount, setReceivedReferralsCount] = useState([]);
    const [mobileSidebarOn, setMobileSidebarOn] = useState(false);

    useEffect(() => {
        getReferralsCount(props.calledBy === "ReceivedReferrals" ? "RECEIVED" : "SENT");
    }, [props.calledBy, insSelected]);

    {/** 
        1. This section use to check the route and set current route url.  Which check in jsx template NavItem component.
        2. There has a condition for disable check if route not match.
    */}
    useEffect(() => {
        setCurrentRouteUrl(location.pathname);
        setTimeout(() => {
            if (location.pathname === '/referrals/received-referrals') {
                if (!checkAllInReceived) {
                    handleReceivedCheckboxChange('All');
                }
                if (checkAllInSent) {
                    handleSentCheckboxChange('All');
                }

            } else {
                if (!checkAllInSent) {

                    handleSentCheckboxChange('All');
                }
                if (checkAllInReceived) {
                    handleReceivedCheckboxChange('All');
                }
            }
        }, 500);

    }, [location]);

    useEffect(() => {
        createQuery("SENT");
    }, [
        checkAllInSent,
        checkPendingInSent,
        checkAcceptedInSent,
        checkRejectedInSent,
    ]);

    useEffect(() => {
        createQuery("RECEIVED");
    }, [
        checkAllInReceived,
        checkPendingInReceived,
        checkAcceptedInReceived,
        checkRejectedInReceived,
    ]);

    const getReferralsCount = (type) => {
        let instIds = "";
        instIds = insSelected.map(item => item.value);
        const commaSeparatedString = instIds.join(',');

        // console.log(insSelected);
        // console.log(commaSeparatedString);
        if (type === "SENT")
            ReferralService.getReferralsCount("SENT", commaSeparatedString)
                .then((response) => {
                    const { status, data } = response;
                    if (status === 200) {
                        // console.log("data", data);
                        setSentReferralsCount(data);
                    }
                    else {
                        console.log(FAILTOSAVERECOED);
                    }
                })
        else
            ReferralService.getReferralsCount("RECEIVED", commaSeparatedString)
                .then((response) => {
                    const { status, data } = response;
                    if (status === 200) {
                        // console.log("data", data);
                        setReceivedReferralsCount(data);
                    }
                    else {
                        console.log(FAILTOSAVERECOED);
                    }
                })

    }

    function createQuery(type) {
        if (type === "SENT") {
            var query = "";
            if (checkAllInSent) {
                setQuery("");
                return;
            }
            if (checkPendingInSent) {
                query = "st=='SUBMITTED',st=='IN_REVIEW',st=='DRAFT'";
            }
            if (checkAcceptedInSent) {
                if (query === "") query = "st=='ACCEPTED',st=='IN_PROGRESS',st=='CLOSED'";
                else query = query + ",st=='ACCEPTED',st=='IN_PROGRESS',st=='CLOSED'";
            }
            if (checkRejectedInSent) {
                if (query === "") query = "st=='REJECTED',st=='CANCELED'";
                else query = query + ",st=='REJECTED',st=='CANCELED'";
            }
            setQuery(query);
        } else if (type === "RECEIVED") {
            var query = "";
            if (checkAllInReceived) {
                setQuery("");
                return;
            }
            if (checkPendingInReceived) {
                query = "st=='SUBMITTED',st=='IN_REVIEW'";
            }
            if (checkAcceptedInReceived) {
                if (query === "") query = "st=='ACCEPTED',st=='IN_PROGRESS',st=='CLOSED'";
                else query = query + ",st=='ACCEPTED',st=='IN_PROGRESS',st=='CLOSED'";
            }
            if (checkRejectedInReceived) {
                if (query === "") query = "st=='REJECTED',st=='CANCELED'";
                else query = query + ",st=='REJECTED',st=='CANCELED'";
            }
            setQuery(query);
        }
        else {
            setQuery("");
        }
    }

    const handleReceivedCheckboxChange = (checkboxName) => {
        switch (checkboxName) {
            case 'All':
                setCheckAllInReceived(!checkAllInReceived);
                setCheckPendingInReceived(!checkAllInReceived);
                setCheckAcceptedInReceived(!checkAllInReceived);
                setCheckRejectedInReceived(!checkAllInReceived);
                break;
            case 'Pending':
                if (checkAllInReceived) {
                    setCheckAllInReceived(false);
                    setCheckPendingInReceived(false);
                } else {
                    setCheckPendingInReceived(!checkPendingInReceived);
                }
                break;
            case 'Accepted':
                if (checkAllInReceived) {
                    setCheckAllInReceived(false);
                    setCheckAcceptedInReceived(false);
                } else {
                    setCheckAcceptedInReceived(!checkAcceptedInReceived);
                }
                break;
            case 'Rejected':
                if (checkAllInReceived) {
                    setCheckAllInReceived(false);
                    setCheckRejectedInReceived(false);
                } else {
                    setCheckRejectedInReceived(!checkRejectedInReceived);
                }
                break;
            default:
                break;
        }
    };

    const handleSentCheckboxChange = (checkboxName) => {
        switch (checkboxName) {
            case 'All':
                setCheckAllInSent(!checkAllInSent);
                setCheckPendingInSent(!checkAllInSent);
                setCheckAcceptedInSent(!checkAllInSent);
                setCheckRejectedInSent(!checkAllInSent);
                break;
            case 'Pending':
                if (checkAllInSent) {
                    setCheckAllInSent(false);
                    setCheckPendingInSent(false);
                } else {
                    setCheckPendingInSent(!checkPendingInSent);
                }
                break;
            case 'Accepted':
                if (checkAllInSent) {
                    setCheckAllInSent(false);
                    setCheckAcceptedInSent(false);
                } else {
                    setCheckAcceptedInSent(!checkAcceptedInSent);
                }
                break;
            case 'Rejected':
                if (checkAllInSent) {
                    setCheckAllInSent(false);
                    setCheckRejectedInSent(false);
                } else {
                    setCheckRejectedInSent(!checkRejectedInSent);
                }
                break;
            default:
                break;
        }
    };

    return (
        <>
            {mobileSidebarOn && 
                <div className='nex-mob-backdrops' onClick={()=>setMobileSidebarOn(false)}></div>
            }
            <div className='nex-mob-sticky-box cursor-pointer' onClick={()=>setMobileSidebarOn(!mobileSidebarOn)} >
                <span className='label'>{`${currentRouteUrl === '/referrals/received-referrals' ? 'Received Referrals' : 'Sent Referrals'}`}</span>
                <span><svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 10C2.55228 10 3 9.55228 3 9C3 8.44772 2.55228 8 2 8C1.44772 8 1 8.44772 1 9C1 9.55228 1.44772 10 2 10Z" stroke="#3EABCA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M2 3C2.55228 3 3 2.55228 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2C1 2.55228 1.44772 3 2 3Z" stroke="#3EABCA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M2 17C2.55228 17 3 16.5523 3 16C3 15.4477 2.55228 15 2 15C1.44772 15 1 15.4477 1 16C1 16.5523 1.44772 17 2 17Z" stroke="#3EABCA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10 10C10.5523 10 11 9.55228 11 9C11 8.44772 10.5523 8 10 8C9.44772 8 9 8.44772 9 9C9 9.55228 9.44772 10 10 10Z" stroke="#3EABCA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10 3C10.5523 3 11 2.55228 11 2C11 1.44772 10.5523 1 10 1C9.44772 1 9 1.44772 9 2C9 2.55228 9.44772 3 10 3Z" stroke="#3EABCA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M10 17C10.5523 17 11 16.5523 11 16C11 15.4477 10.5523 15 10 15C9.44772 15 9 15.4477 9 16C9 16.5523 9.44772 17 10 17Z" stroke="#3EABCA" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                </span>
            </div>
            <div className={`nexogic-side-tab-wrap nexogic-side-tab-referrals ${mobileSidebarOn ? 'mobile-on' : ''}`}>
                <h2 className='nexogic-side-heading'>My Referrals</h2>
                <div className='nexogic-side-tab-scroller'>
                    <Nav vertical className={`nexogic-side-tab-card check-box-card ${currentRouteUrl === '/referrals/received-referrals' ? 'active-ul' : ''}`}>
                        <NavItem className={`${currentRouteUrl === '/referrals/received-referrals' ? 'active' : ''}`}>
                            <LinkContainer to="/referrals/received-referrals">
                                <Button>Received Referrals</Button>
                            </LinkContainer>
                            <span className="nexogic-drop-arrow"></span>
                            <Nav>
                                <NavItem>
                                    <input
                                        type="checkbox"
                                        id="received-all"
                                        className="form-check-input"
                                        onChange={() => {
                                            handleReceivedCheckboxChange('All')
                                            createQuery("RECEIVED");
                                        }
                                        }
                                        checked={
                                            checkAllInReceived ||
                                            (checkPendingInReceived &&
                                                checkAcceptedInReceived &&
                                                checkRejectedInReceived)
                                        }
                                    />
                                    <label htmlFor="received-all">
                                        All <span className="number">
                                            {(receivedReferralsCount?.SUBMITTED ?? 0)
                                                + (receivedReferralsCount?.CANCELED ?? 0)
                                                + (receivedReferralsCount?.IN_REVIEW ?? 0)
                                                + (receivedReferralsCount?.ACCEPTED ?? 0)
                                                + (receivedReferralsCount?.REJECTED ?? 0)
                                                + (receivedReferralsCount?.IN_PROGRESS ?? 0)
                                                + (receivedReferralsCount?.CLOSED ?? 0)
                                            }
                                        </span>
                                    </label>
                                </NavItem>
                            </Nav>
                            <Nav>
                                <NavItem >
                                    <input
                                        type="checkbox"
                                        id="received-pending"
                                        className="form-check-input"
                                        onChange={() => {
                                            handleReceivedCheckboxChange('Pending')
                                            createQuery("RECEIVED");
                                        }
                                        }
                                        checked={checkPendingInReceived || checkAllInReceived}
                                    />
                                    <label htmlFor="received-pending">
                                        Pending <span className="number">
                                            {(receivedReferralsCount?.SUBMITTED ?? 0)
                                                + (receivedReferralsCount?.IN_REVIEW ?? 0)
                                            }
                                        </span>
                                    </label>
                                </NavItem>
                            </Nav>
                            <Nav>
                                <NavItem className="">
                                    <input
                                        type="checkbox"
                                        id="received-accepted"
                                        className="form-check-input"
                                        onChange={() => {
                                            handleReceivedCheckboxChange('Accepted')
                                            createQuery("RECEIVED");
                                        }
                                        }
                                        checked={checkAcceptedInReceived || checkAllInReceived}
                                    />
                                    <label htmlFor="received-accepted">
                                        Accepted <span className="number">
                                            {(receivedReferralsCount?.ACCEPTED ?? 0)
                                                + (receivedReferralsCount?.IN_PROGRESS ?? 0)
                                                + (receivedReferralsCount?.CLOSED ?? 0)
                                            }
                                        </span>
                                    </label>
                                </NavItem>
                            </Nav>
                            <Nav>
                                <NavItem className="">
                                    <input
                                        type="checkbox"
                                        id="received-rejected"
                                        className="form-check-input"
                                        onChange={() => {
                                            handleReceivedCheckboxChange('Rejected')
                                            createQuery("RECEIVED");
                                        }
                                        }
                                        checked={checkRejectedInReceived}
                                    />
                                    <label htmlFor="received-rejected">
                                        Rejected <span className="number">
                                            {(receivedReferralsCount?.REJECTED ?? 0)}
                                        </span>
                                    </label>
                                </NavItem>
                            </Nav>
                        </NavItem>
                    </Nav>

                    <Nav vertical className={`nexogic-side-tab-card check-box-card ${currentRouteUrl === '/referrals/sent-referrals' ? 'active-ul' : ''}`}>
                        <NavItem className={`${currentRouteUrl === '/referrals/sent-referrals' ? 'active' : ''}`}>
                            <LinkContainer to="/referrals/sent-referrals">
                                <Button outline>Sent Referrals</Button>
                            </LinkContainer>
                            <span className="nexogic-drop-arrow"></span>
                            <Nav>
                                <NavItem className="">
                                    <input
                                        type="checkbox"
                                        id="sent-all"
                                        className="form-check-input"
                                        onChange={() => {
                                            handleSentCheckboxChange('All');
                                            createQuery("SENT");
                                        }}
                                        checked={
                                            checkAllInSent ||
                                            (checkPendingInSent &&
                                                checkAcceptedInSent &&
                                                checkRejectedInSent)
                                        }
                                    />
                                    <label htmlFor="sent-all">
                                        All <span className="number">
                                            {(sentReferralsCount?.DRAFT ?? 0)
                                                + (sentReferralsCount?.SUBMITTED ?? 0)
                                                + (sentReferralsCount?.CANCELED ?? 0)
                                                + (sentReferralsCount?.IN_REVIEW ?? 0)
                                                + (sentReferralsCount?.ACCEPTED ?? 0)
                                                + (sentReferralsCount?.REJECTED ?? 0)
                                                + (sentReferralsCount?.IN_PROGRESS ?? 0)
                                                + (sentReferralsCount?.CLOSED ?? 0)
                                            }
                                        </span>
                                    </label>
                                </NavItem>
                            </Nav>
                            <Nav>
                                <NavItem className="">
                                    <input
                                        type="checkbox"
                                        id="sent-pending"
                                        className="form-check-input"
                                        onChange={() => {
                                            handleSentCheckboxChange('Pending');
                                            createQuery("SENT");
                                        }}
                                        checked={checkPendingInSent}
                                    />
                                    <label htmlFor="sent-pending">
                                        Pending <span className="number">
                                            {(sentReferralsCount?.SUBMITTED ?? 0)
                                                + (sentReferralsCount?.IN_REVIEW ?? 0)
                                                + (sentReferralsCount?.DRAFT ?? 0)
                                            }
                                        </span>
                                    </label>
                                </NavItem>
                            </Nav>
                            <Nav>
                                <NavItem className="">
                                    <input
                                        type="checkbox"
                                        id="sent-accepted"
                                        className="form-check-input"
                                        onChange={() => {
                                            handleSentCheckboxChange('Accepted');
                                            createQuery("SENT");
                                        }}
                                        checked={checkAcceptedInSent}
                                    />
                                    <label htmlFor="sent-accepted">
                                        Accepted <span className="number">
                                            {(sentReferralsCount?.ACCEPTED ?? 0)
                                                + (sentReferralsCount?.IN_PROGRESS ?? 0)
                                                + (sentReferralsCount?.CLOSED ?? 0)
                                            }
                                        </span>
                                    </label>
                                </NavItem>
                            </Nav>
                            <Nav>
                                <NavItem className="">
                                    <input
                                        type="checkbox"
                                        id="sent-rejected"
                                        className="form-check-input"
                                        onChange={() => {
                                            handleSentCheckboxChange('Rejected');
                                            createQuery("SENT");
                                        }}
                                        checked={checkRejectedInSent}
                                    />
                                    <label htmlFor="sent-rejected">
                                        Rejected <span className="number">
                                            {(sentReferralsCount?.REJECTED ?? 0)}
                                        </span>
                                    </label>
                                </NavItem>
                            </Nav>
                        </NavItem>
                    </Nav>
                </div>


            </div>
        </>
    );
}
