import { Link, useParams, useHistory } from "react-router-dom";
import { Row, Col, Button, CardBody, Card, Modal, ModalHeader, ModalBody } from "reactstrap";
import BackIcon from "assets/images/backIcon.png";
import pannel_arrow from "assets/svgIcon/down-arrow-white-icon.svg";
import referals_icon from "assets/images/svgs/referals-icon.svg";
import { useState } from "react";
import { useEffect } from "react";
import profile_sample from "assets/images/user-profile-sample.png";
import plus_icon from "assets/images/plus-icon-lg.png";
import { ReferralResponseService } from "utils/ReferralResponseService";
import { ReferralService } from "utils/ReferralService";
import { FAILTOSAVERECOED } from "utils/Constants";
import { MedialService } from "utils/MediaService";
import { useRef } from "react";
import { withAlert } from "react-alert";
import CommonLoader from "common/CommonLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PatientDetailsInfo from "pages/Referals/Components/CompleteForm/Components/PatientDetailsInfo";
import PatientStatus from "pages/Referals/Components/CompleteForm/Components/PatientStatus";
import TreatmentInfo from "pages/Referals/Components/CompleteForm/Components/TreatmentInfo";
import MyTreatmentInfo from "./MyTreatmentInfo";
import { getFileIcon } from "utils/Utils";



const MyReferralResponse = () => {
    const [isResponseLoading, setIsResponseLoading] = useState(true);
    const [pannel_one_on, setPannel_one_on] = useState(true);
    const [pannel_two_on, setPannel_two_on] = useState(true);
    const [pannel_three_on, setPannel_three_on] = useState(true);
    const [pannel_four_on, setPannel_four_on] = useState(true);
    const [referral, setReferral] = useState({});
    const [referralResponse, setReferralResponse] = useState();
    const [docsInfo, setDocsInfo] = useState([]);

    const fileInputRef = useRef(null);
    const [dataLoading, setDataLoading] = useState(false);
    const [documentType, setDocumentType] = useState(null);
    const [documentData, setDocumentData] = useState(null);

    const [statusModal, setStatusModal] = useState(false);

    const [modal, setModal] = useState(false);

    let { id } = useParams();
    const history = useHistory();
    const handleBack = () => {
        history.goBack()
    }

    const getDocsInfo = (id) => {
        MedialService.getMedias("REFERRAL", id)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    // console.log(data);
                    setDocsInfo(data);
                } else {
                    console.log(FAILTOSAVERECOED);
                }
            })
    }

    const getReferralById = (id) => {
        ReferralService.getMyReferralById(id)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    setReferral(data);
                    setIsResponseLoading(false);
                } else {
                    console.log(FAILTOSAVERECOED);
                }
            })
    }

    const getReferralResponse = (id) => {
        ReferralResponseService.getMyReferralResponse(id)
            .then((response) => {
                const { status, data } = response;
                if (status === 200) {
                    setReferralResponse(data);
                    getDocsInfo(data?.id)
                }
                else {
                    console.log(FAILTOSAVERECOED);
                }
            })
    }

    useEffect(() => {
        getReferralById(id);
        getReferralResponse(id);
    }, [id])

    const toggleForm = () => setModal(!modal);

    const getReferralDoc = async (id) => {
        try {
            setDataLoading(true);
            const { data, headers } = await MedialService.getMediaFile(id);
            const createContentType = headers["content-type"] === "application/octet-stream"
                ? "application/pdf"
                : headers["content-type"];
            let generateURL = `data:${createContentType};base64,${Buffer.from(data).toString("base64")}`;
            setDocumentData(generateURL);
            let docArray = [data, createContentType];
            setDocumentType(docArray);
            if (createContentType === "application/pdf") {
                setDataLoading(false);
                onOpenBase64(generateURL);
            }
            else {
                setDataLoading(false);
                toggleForm();
            }
        } catch (error) {
            console.error("Error retrieving referral document:", error);
        }
    };
    const onOpenBase64 = (base64URL) => {
        // const onOpenBase64 = () => {
        try {
            var win = window.open();
            win.document.write(`
            <iframe class="nexogic-iframe" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" 
              src="${base64URL}" frameborder="0" allowfullscreen></iframe>
          `);
        } catch (error) {
            console.error("Error opening base64 URL:", error);
            // Handle error, show error message, etc.
        }
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = documentData;
        let ext = documentType[1].split('/').pop();
        link.download = 'reports.' + ext;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        link.click();
    };    

    return (
        <div className="complete-form">
            <div className="routing-header d-flex justify-content-between align-items-center mb-3">
                <div className="nexogic-top-section-title">
                    <span><Link to="" onClick={handleBack}><img src={BackIcon} alt="back" /></Link></span>
                    <h2 className="h2">Referral Response <span>{!isResponseLoading && `(${referral?.patient?.firstName} ${referral?.patient?.lastName})`}</span></h2>
                </div>
            </div>
            {isResponseLoading ?
                <div className="loading-spiner text-center  mt-2">
                    <CommonLoader />
                </div>
                :
                <Card className="nexogic-form-text-display-w border-0 bg-transparent">
                    <CardBody className="px-0">
                        <Row className="need-margin">
                            <Col xl={9}>
                                <div className="position-relative mb-3">
                                    <Card className={`nexogic-card-color-header ${pannel_one_on ? 'expanded' : ''}`}>
                                        <div className="card-header">
                                            <h2>Patient Details</h2>
                                            <button className="collapse-btn" type="button" onClick={() => setPannel_one_on(!pannel_one_on)}>
                                                <img src={pannel_arrow} alt="" />
                                            </button>
                                        </div>
                                        <div className="collapse-div">
                                            <CardBody>
                                                {referral &&
                                                    <PatientDetailsInfo
                                                        patient={referral?.patient}
                                                        patientType={referral?.patientType}
                                                    />
                                                }
                                            </CardBody>
                                        </div>
                                    </Card>
                                </div>

                                {/** Patient  Status */}
                                <div className="position-relative mb-3">
                                    <Card className={`nexogic-card-color-header ${pannel_two_on ? 'expanded' : ''}`}>
                                        <div className="card-header">
                                            <h2>Patient Status</h2>
                                            <button className="collapse-btn" type="button" onClick={() => setPannel_two_on(!pannel_two_on)}>
                                                <img src={pannel_arrow} alt="" />
                                            </button>
                                        </div>
                                        <div className="collapse-div">
                                            <CardBody>
                                                {(referralResponse && referralResponse?.patientStatuses && referralResponse?.patientStatuses.length > 0)
                                                    ?
                                                    <PatientStatus
                                                        referral={referral}
                                                        referralResponse={referralResponse}
                                                        getReferralResponse={getReferralResponse}
                                                    // isResponseSent={isResponseSent}
                                                    />
                                                    :
                                                    <div className="nexogic-not-found-text">Not Available</div>
                                                }
                                            </CardBody>
                                        </div>
                                    </Card>
                                </div>


                                {/** Treatment Details  */}
                                <div className="position-relative mb-3">
                                    <Card className={`nexogic-card-color-header ${pannel_three_on ? 'expanded' : ''}`}>
                                        <div className="card-header">
                                            <h2>Treatment Detail</h2>
                                            {/* {isResponseSent && (referral?.status === "ACCEPTED" || referral?.status === "IN_PROGRESS") &&

                                                <Link to={`/referrals/${referral?.id}/treatment`} className="add-button btn btn-link"><img src={plus_icon} alt="" /></Link>

                                            } */}
                                            <button className="collapse-btn" type="button" onClick={() => setPannel_three_on(!pannel_three_on)} >
                                                <img src={pannel_arrow} alt="" />
                                            </button>
                                        </div>
                                        <div className="collapse-div">
                                            <CardBody>
                                                {(referralResponse && referralResponse?.treatments && referralResponse?.treatments.length > 0)
                                                    ?
                                                    <MyTreatmentInfo treatments={referralResponse?.treatments} />
                                                    :
                                                    <div className="nexogic-not-found-text">Not Available</div>
                                                }
                                            </CardBody>
                                        </div>
                                    </Card>
                                </div>
                            </Col>
                            <Col xl={3} className="position-relative ">
                                <Card className="nexogic-widget-sidebar-card document-sidebar-widget">
                                    <h2 className="card-title h2 d-flex flex-wrap">
                                        Response Documents
                                    </h2>
                                    <CardBody>
                                        {docsInfo && docsInfo.length > 0 ?
                                            <div className="nexogic-custom-scroll">
                                                <div className="nexogic-document-lists">
                                                    <ul>
                                                        {docsInfo.map((doc, index) => (
                                                            <li key={index} >
                                                                <div className="nexogic-document-item" style={{ cursor: "pointer" }} onClick={() => getReferralDoc(doc?.id)}>
                                                                    <img src={getFileIcon(doc?.type)} style={{ cursor: "pointer", height: '50px' }} />
                                                                    <div className="card-text">{doc?.name}</div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>

                                                </div>
                                            </div>
                                            :
                                            <>
                                                <div className="nexogic-not-found-text">No Documents</div>
                                            </>
                                        }
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            }
        </div>
    )
}

export default MyReferralResponse;