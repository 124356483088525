import FormikInputField from "components/forms/formikFields/FormikInputField";
import FormikInputMonthYearFormatField from "components/forms/formikFields/FormikInputMonthYearFormatField";
import { Field, Formik } from "formik";
import { useState } from "react";
import { withAlert } from "react-alert";
import { Button, Col, Label, Row, Spinner } from "reactstrap";
import { FAILTOSAVERECOED } from "utils/Constants";
import { InstitutionServices } from "utils/InstitutionServices";
import * as Yup from "yup";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const validationSchema = Yup.object().shape({
  awardName: Yup.string().min(3).max(50).required("This field is required"),
  issuingAuthority: Yup.string()
    .min(3)
    .max(50)
    .required("This field is required"),
  description: Yup.string().min(3).max(250),
  issuedMonthYear: Yup.string().required("This field is required"),
  guid: Yup.string(),
});

const RenderForm = (props) => {
  const { handleSubmit, isLoading, toggle } = props;
  return (
    <form className="my-form" onSubmit={handleSubmit}>
      <div>
        <Row form>
          <Col sm={12} xs={12}>
            <Field
              id="ia_awardName"
              name="awardName"
              type="text"
              label="Award Name"
              component={FormikInputField}
              placeholder="Award Name"
              autoComplete="off"
            />
          </Col>
          <Col sm={8} xs={12}>
            <Field
              id="ia_issuingAuthority"
              name="issuingAuthority"
              type="text"
              label="Awarded by"
              component={FormikInputField}
              placeholder="Awarded by"
              autoComplete="off"
            />
          </Col>
          <Col sm={4} xs={12}>
            <Label>Issue Year</Label>
            <Field
              name={`issuedMonthYear`}
              id={`issuedMonthYear`}
              type="text"
              component={FormikInputMonthYearFormatField}
              placeholder="MM-YYYY"
              autoComplete="off"
            />
          </Col>
        </Row>
        <Row form>
          <Col sm={12} xs={12}>
            <Field
              id="ia_description"
              name="description"
              type="textarea"
              rows={4}
              cols={5}
              label="Description"
              component={FormikInputField}
            />
          </Col>
        </Row>
      </div>
      {/*<hr className="profile-info-modal-hr mt-0" />*/}
      <div className="justify-content-end  modal-footer">
        <div className="action-group">
          {isLoading ? (
            <div className="loading-spiner">
              <Spinner style={{ width: "2rem", height: "2rem" }} />
            </div>
          ) : (
            <>
              
              <Button id="ia_btn_submit" className="nexogic_primary_button" color="primary" type="submit">
                Save
              </Button>
              <Button
                id="ia_btn_cancel"
                className="nexogic_primary_button_outline"
                outline
                color="primary"
                onClick={toggle}
                type="button"
              >
                Cancel
              </Button>
            </>
          )}
        </div>
      </div>
    </form>
  );
};

const AwardInstituteForm = (props) => {
  const { selectedItem, toggle, alert, institution, getInstitutionProfile } =
    props;
  const [isLoading, setLoading] = useState(false);

  const addItem = async (value) => {
    setLoading(true);
    try {
      const {
        awardName,
        issuingAuthority,
        description,
        issuedMonthYear,
        awardCategory,
      } = value;
      const [issuedMonth, issuedYear] = issuedMonthYear
        ? issuedMonthYear.split("-")
        : "";
      const request = InstitutionServices.addAwardInstitution(
        {
          awardCategory: awardCategory ?? "",
          awardName: awardName ?? "",
          description: description ?? "",
          issuedMonth: issuedMonth ?? 0,
          issuedYear: issuedYear ?? 0,
          issuingAuthority: issuingAuthority ?? "",
        },
        institution.guid
      );
      request.then(
        (response) => {
          const { data } = response;
          if (data?.guid) {
            setTimeout(() => {
              showSuccessMessage("Award added successfully");
              getInstitutionProfile(institution.guid);
              setLoading(false);
              toggle();
            }, 1000);
          } else {
            setLoading(false);
            let errmsg =
              response.data && !!response.data.message
                ? response.data.message
                : FAILTOSAVERECOED;
            showErrorMessage(errmsg);
          }
        },
        (e) => {
          setLoading(false);
          console.log("error", e.message);
          let errmsg = e.message;
          if (e.response) {
            errmsg =
              e.response.data && !!e.response.data.message
                ? e.response.data.message
                : errmsg;
          }
          showErrorMessage(errmsg);
        }
      );
    } catch (e) {
      setLoading(false);
    }
  };

  const updateItem = async (value) => {
    setLoading(true);
    const {
      awardName,
      issuingAuthority,
      description,
      issuedMonthYear,
      awardCategory,
    } = value;
    try {
      const [issuedMonth, issuedYear] = issuedMonthYear
        ? issuedMonthYear.split("-")
        : "";
      const request = InstitutionServices.updateAwardInstitution(
        {
          awardCategory: awardCategory ?? "",
          awardName: awardName ?? "",
          description: description ?? "",
          issuedMonth: issuedMonth ?? 0,
          issuedYear: issuedYear ?? 0,
          issuingAuthority: issuingAuthority ?? "",
        },
        institution.guid,
        value.guid
      );
      request.then(
        (response) => {
          const { status } = response;
          if (status === 200) {
            setTimeout(() => {
              showSuccessMessage("Award updated successfully");
              getInstitutionProfile(institution.guid);
              setLoading(false);
              toggle();
            }, 1000);
          } else {
            setLoading(false);
            let errmsg =
              response.data && !!response.data.message
                ? response.data.message
                : FAILTOSAVERECOED;
            showErrorMessage(errmsg);
          }
        },
        (e) => {
          console.log(e.message);
          setLoading(false);
          let errmsg = e.message;
          if (e.response) {
            errmsg =
              e.response.data && !!e.response.data.message
                ? e.response.data.message
                : errmsg;
          }
          showErrorMessage(errmsg);
        }
      );
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={validationSchema}
      initialValues={selectedItem}
      onSubmit={(values) => {
        if (values.guid) {
          updateItem(values);
        } else {
          addItem(values);
        }
      }}
      children={(props) => {
        return (
          <>
            <RenderForm toggle={toggle} {...props} isLoading={isLoading} />
          </>
        );
      }}
    />
  );
};
export default withAlert()(AwardInstituteForm);
