import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Link } from "react-router-dom";
import {dateFormatter} from 'utils/Utils'
import treatment_list_icon from 'assets/svgIcon/treatment-list-icon.svg';

export default function MyTreatmentInfo(props) {
    const { treatments } = props;
    const [sortedTreatments, setSortedTreatments] = useState([]);

    const sortTreatments = (treatments) => {
        const sorted = treatments.slice().sort((a, b) => new Date(b.updatedOn) - new Date(a.updatedOn));
        setSortedTreatments(sorted);
    };

    useEffect(() => {
        treatments && sortTreatments(treatments);
    }, [treatments]);

    const createTreatmentType = (type) => {
        if (type === 'IN_PATIENT')
            return 'In Patient';
        else if (type === 'OUT_PATIENT')
            return 'Out Patient';
        else
            return type;
    }

    return (
        <>
            {sortedTreatments && (
                <Card className='nexogic-treatment-card border-0'>
                    <CardBody className='px-0 nexogic-custom-scroll'>
                        {sortedTreatments.map((treatment) => (
                            <Row key={treatment.id}>
                                <Col>
                                    <div className='nexogic-treatment-item'>
                                        <div className='nexogic-date-w'>
                                            <div className='nexogic-date'>
                                                {dateFormatter(treatment?.updatedOn)}
                                            </div>
                                        </div>

                                        <div className='icon'>
                                            <img src={treatment_list_icon} alt='' />
                                        </div>
                                        <div className='info'>
                                            <Row>
                                                <Col lg={3}>
                                                    <div className='card-text lh-lg'>
                                                    <div><b>Type </b></div>
                                                    {createTreatmentType(treatment?.type)}
                                                    </div>
                                                </Col>
                                                <Col lg={3}>
                                                    <div className='card-text lh-lg'>
                                                        <div><b>Duration</b> </div> 
                                                        {treatment?.duration}
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className='card-text lh-lg'>
                                                    <div><b>Estimated Discharge Amount </b></div>
                                                    {" ₹ "}{treatment?.cost}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <span className='nexogic_primary_button_outline show-more-link'>
                                            <Link to={{ pathname: `/referrals/${treatment?.id}/treatment-details`, state: { treatment } }}>
                                                View Details
                                            </Link>
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        ))}
                    </CardBody>
                </Card>
            )}
        </>
    );
}
