import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import groupPlaceholder1 from "assets/images/groups_placeholder1.png";
import { memo, useState } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import { Link } from "react-router-dom";
import {
  Button,
  ButtonDropdown,
  Card,
  CardImg,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

const GroupItem = ({ group, activeTab, handleEditItem, reportClick, setGroupIdToAct, toggleAlert }) => {
  const [dropdownOpen, seDropdownOpen] = useState(false);
  const toggle = () => {
    seDropdownOpen(!dropdownOpen);
  };

  return (
    <Col md={12} lg={12} className="nexogic-grid-col">
      <Card className="mb-0">
        <div className="card-body mb-0">
          <div className="row no-gutters align-items-center">
            
            <div className="col-auto col-img">
              <CardImg
                className="card-img card-img-md nexogic-grid-col-image"
                width="100%"
                src={
                  group.bannerId
                    ? `${process.env.REACT_APP_IMAGE_BASE_URL}/${group.id}/${group.bannerId}`
                    : groupPlaceholder1
                }
                alt="Card image cap"
              />
              
            </div>
            <div className="col col-content-right-btn position-relative">
              <div className="nexogic-content-details">
              <h3 className="card-title">
              {activeTab === "1" && (
                <div className="">
                  <ButtonDropdown
                    className="nexogic-float-drops-btn nex-gi-float-drops-btn"
                    isOpen={dropdownOpen}
                    toggle={toggle}
                  >
                    <DropdownToggle
                      color="link"
                      className="min-w-0 group-follow-item nex-gi-group-follow-item-link"
                    >
                      <FontAwesomeIcon
                        color="#2D4552"
                        className="font-weight-500"
                        size="sm"
                        icon={faEllipsisH}
                      />
                    </DropdownToggle>
                    <DropdownMenu className="filter-menu" right>
                      <DropdownItem
                        className="nex-gi-group-unfollow-link"
                        onClick={
                          () => {
                            setGroupIdToAct(group.id)
                            toggleAlert();
                          }}>
                        Unfollow
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </div>
              )}
                <Link className="group-link nex-gi-group-group-name" to={`/connections/groups/${group.id}`}>
                  {group.name}
                </Link>
              </h3>
              <div className="card-text">
                {group.members}
              </div>
              <LinesEllipsis
            className="card-text"
            text={group.description}
            maxLine="2"
            ellipsis="..."
            trimRight
            basedOn="letters"
          />


              </div>
              <div className="nexogic-action-part">
              {activeTab === "0" && (
                <div className="actions">
                  <Button
                    color="primary"
                    className="nexogic_primary_button_outline nex-gi-group-edit-btn"
                    onClick={() => handleEditItem(group)}
                  >
                    Edit
                  </Button>
                </div>
              )}
              </div>

            </div>
          </div>




          
          
          
          
        </div>
      </Card>
    </Col>
  );
};

GroupItem.defaultProps = {
  handleEditItem: () => { },
  reportClick: () => { },
};

export default memo(GroupItem);
