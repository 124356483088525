import React, { useState } from "react";
import { Field, Formik } from "formik";
import { Row, Col, Button, Spinner } from "reactstrap";
import { withAlert } from "react-alert";
import * as Yup from "yup";
import FormikInputField from "components/forms/formikFields/FormikInputField";
import { PractitionerService } from "utils/PractitionerService";
import { RenderErrorMessage } from "common/RenderErrorMessage/ErrorMessage";
import { useSelector } from "react-redux";
import { FAILTOSAVERECOED } from "utils/Constants";
import { showErrorMessage, showSuccessMessage } from "common/AlertContainer";

const RenderForm = (props) => {
  const { toggle, isValid, dirty, handleSubmit, isLoading } = props;
  return (
    <form className="my-form" onSubmit={handleSubmit}>
      <div>
        <Row form>
          <Col xs={12}>
            <Field
              name="instaHandle"
              id="instaHandle"
              type="text"
              label="Instagram"
              component={FormikInputField}
              placeholder="Instagram link"
            />
          </Col>
        </Row>
        <Row form>
          <Col xs={12}>
            <Field
              name="linkedinHandle"
              id="linkedinHandle"
              type="text"
              label="LinkedIn"
              component={FormikInputField}
              placeholder="LinkedIn link"
            />
          </Col>
        </Row>
        <Row form>
          <Col xs={12}>
            <Field
              name="facebookHandle"
              id="facebookHandle"
              type="text"
              label="Facebook"
              component={FormikInputField}
              placeholder="Facebook link"
            />
          </Col>
        </Row>
        <Row form>
          <Col xs={12}>
            <Field
              name="twitterHandle"
              id="twitterHandle"
              type="text"
              label="Twitter"
              component={FormikInputField}
              placeholder="Twitter link"
            />
          </Col>
        </Row>
        {/** <hr className="profile-info-modal-hr" />*/}
        <div className="justify-content-end  modal-footer mx-0">
          <Col lg="auto" className="action-group">
            {isLoading ? (
              <div className="loading-spiner">
                <Spinner />
              </div>
            ) : (
              <>

                <Button
                  id="shf_btn_submit"
                  className="nexogic_primary_button"
                  disabled={!isValid || !dirty}
                  color="primary"
                  type="submit"
                >
                  Save
                </Button>
                <Button
                  id="shf_btn_cancel"
                  className="nexogic_primary_button_outline" onClick={toggle}>
                  Cancel
                </Button>
              </>
            )}
          </Col>
        </div>
      </div>
    </form>
  );
};

const socialHandlerSchema = Yup.object().shape({
  facebookHandle: Yup.string()
    .url("Not a valid url")
    .matches(
      /^https?:\/\/(?:www.)?facebook\.com.*\/.+$/,
      "Url must be from facebook.com(https://facebook.com/xyz)"
    )
    .nullable(),
  twitterHandle: Yup.string()
    .url("Not a valid url")
    .matches(
      /^https?:\/\/(?:www.)?twitter\.com.*\/.+$/,
      "Url must be from twitter.com(https://twitter.com/xyz)"
    )
    .nullable(),
  linkedinHandle: Yup.string()
    .url("Not a valid url")
    .matches(
      /^https?:\/\/(?:www.)?linkedin\.com.*\/.+$/,
      "Url must be from linkedin.com(https://linkedin.com/xyz)"
    )
    .nullable(),
  instaHandle: Yup.string()
    .url("Not a valid url")
    .matches(
      /^https?:\/\/(?:www.)?instagram\.com.*\/.+$/,
      "Url must be from instagram.com(https://instagram.com/xyz)"
    )
    .nullable(),
});

const SocialHandlerForm = (props) => {
  const { data, onSave, toggle, alert } = props;
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMessage] = useState("");
  const globalProfileInfo = useSelector(
    (state) => state.GlobalProfileReducer.globalProfileInfo
  );

  const saveSocialHandles = async (values) => {
    setLoading(true);
    const id = globalProfileInfo?.guid;
    const updatedPayload = generatePayload({ practitionerId: id, ...values });
    alert.removeAll();
    PractitionerService.updatePractitioner(updatedPayload, id).then(
      (response) => {
        const { guid, status } = response;
        if (status === 200) {
          showSuccessMessage("Succesfully updated social media.");
          onSave(values);
        } else {
          showErrorMessage(FAILTOSAVERECOED);
        }
        setLoading(false);
      },
      (e) => {
        setLoading(false);
        console.log("error saveSocialHandles", e);
        showErrorMessage(FAILTOSAVERECOED);
      }
    );
  };

  const generatePayload = (requestPayload) => {
    const valuesList = [];
    Object.entries(requestPayload).forEach((data) => {
      switch (data[0]) {
        case "facebookHandle":
          if (data[1]) {
            valuesList.push({
              type: "FACEBOOK",
              value: data[1],
            });
          }
          break;
        case "linkedinHandle":
          if (data[1]) {
            valuesList.push({
              type: "LINKEDIN",
              value: data[1],
            });
          }
          break;
        case "twitterHandle":
          if (data[1]) {
            valuesList.push({
              type: "TWITTER",
              value: data[1],
            });
          }
          break;
        case "instaHandle":
          if (data[1]) {
            valuesList.push({
              type: "INSTAGRAM",
              value: data[1],
            });
          }
          break;
      }
    });
    const returnPayload = [
      {
        op: "add",
        path: "/socialContacts",
        value: valuesList,
      },
    ];
    return returnPayload;
  };

  return (
    <>
      {errorMsg !== "" && <RenderErrorMessage errorMsg={errorMsg} />}
      <Formik
        enableReinitialize={true}
        validationSchema={socialHandlerSchema}
        initialValues={data}
        onSubmit={(values) => {
          saveSocialHandles(values);
        }}
        children={(props) => {
          return (
            <RenderForm toggle={toggle} {...props} isLoading={isLoading} />
          );
        }}
      />
    </>
  );
};
export default withAlert()(SocialHandlerForm);
